@charset "UTF-8";
:root {
  --bs-blue: #0d6efd;
  --bs-indigo: #6610f2;
  --bs-purple: #6f42c1;
  --bs-pink: #d63384;
  --bs-red: #dc3545;
  --bs-orange: #fd7e14;
  --bs-yellow: #ffc107;
  --bs-green: #198754;
  --bs-teal: #20c997;
  --bs-cyan: #0dcaf0;
  --bs-gray: #7E8299;
  --bs-gray-dark: #3F4254;
  --bs-white: #ffffff;
  --bs-light: #F5F8FA;
  --bs-primary: #009EF7;
  --bs-secondary: #E4E6EF;
  --bs-success: #50CD89;
  --bs-info: #7239EA;
  --bs-warning: #FFC700;
  --bs-danger: #F1416C;
  --bs-dark: #181C32;
  --bs-font-sans-serif: Poppins, Helvetica, "sans-serif";
  --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  --bs-gradient: linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0));
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: var(--bs-font-sans-serif);
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #181C32;
  background-color: #ffffff;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

hr {
  margin: 1rem 0;
  color: inherit;
  background-color: currentColor;
  border: 0;
  opacity: 0.25;
}

hr:not([size]) {
  height: 1px;
}

h6, .h6, h5, .h5, h4, .h4, h3, .h3, h2, .h2, h1, .h1 {
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-weight: 600;
  line-height: 1.2;
}

h1, .h1 {
  font-size: calc(1.3rem + 0.6vw);
}
@media (min-width: 1200px) {
  h1, .h1 {
    font-size: 1.75rem;
  }
}

h2, .h2 {
  font-size: calc(1.275rem + 0.3vw);
}
@media (min-width: 1200px) {
  h2, .h2 {
    font-size: 1.5rem;
  }
}

h3, .h3 {
  font-size: calc(1.26rem + 0.12vw);
}
@media (min-width: 1200px) {
  h3, .h3 {
    font-size: 1.35rem;
  }
}

h4, .h4 {
  font-size: 1.25rem;
}

h5, .h5 {
  font-size: 1.15rem;
}

h6, .h6 {
  font-size: 1.075rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title],
abbr[data-bs-original-title] {
  text-decoration: underline dotted;
  cursor: help;
  text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul {
  padding-left: 2rem;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 500;
}

dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: 600;
}

small, .small {
  font-size: 0.875em;
}

mark, .mark {
  padding: 0.2em;
  background-color: #fcf8e3;
}

sub,
sup {
  position: relative;
  font-size: 0.75em;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

a {
  color: #009EF7;
  text-decoration: none;
}
a:hover {
  color: #006dab;
  text-decoration: underline;
}

a:not([href]):not([class]), a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

pre,
code,
kbd,
samp {
  font-family: var(--bs-font-monospace);
  font-size: 1em;
  direction: ltr /* rtl:ignore */;
  unicode-bidi: bidi-override;
}

pre {
  display: block;
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  font-size: 0.875em;
}
pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

code {
  font-size: 0.875em;
  color: #b93993;
  word-wrap: break-word;
}
a > code {
  color: inherit;
}

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 0.875em;
  color: #ffffff;
  background-color: #181C32;
  border-radius: 0.275rem;
}
kbd kbd {
  padding: 0;
  font-size: 1em;
  font-weight: 500;
}

figure {
  margin: 0 0 1rem;
}

img,
svg {
  vertical-align: middle;
}

table {
  caption-side: bottom;
  border-collapse: collapse;
}

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #A1A5B7;
  text-align: left;
}

th {
  text-align: inherit;
  text-align: -webkit-match-parent;
}

thead,
tbody,
tfoot,
tr,
td,
th {
  border-color: inherit;
  border-style: solid;
  border-width: 0;
}

label {
  display: inline-block;
}

button {
  border-radius: 0;
}

button:focus:not(:focus-visible) {
  outline: 0;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
select {
  text-transform: none;
}

[role=button] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}
select:disabled {
  opacity: 1;
}

[list]::-webkit-calendar-picker-indicator {
  display: none;
}

button,
[type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
}
button:not(:disabled),
[type=button]:not(:disabled),
[type=reset]:not(:disabled),
[type=submit]:not(:disabled) {
  cursor: pointer;
}

::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

textarea {
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  float: left;
  width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: calc(1.275rem + 0.3vw);
  line-height: inherit;
}
@media (min-width: 1200px) {
  legend {
    font-size: 1.5rem;
  }
}
legend + * {
  clear: left;
}

::-webkit-datetime-edit-fields-wrapper,
::-webkit-datetime-edit-text,
::-webkit-datetime-edit-minute,
::-webkit-datetime-edit-hour-field,
::-webkit-datetime-edit-day-field,
::-webkit-datetime-edit-month-field,
::-webkit-datetime-edit-year-field {
  padding: 0;
}

::-webkit-inner-spin-button {
  height: auto;
}

[type=search] {
  outline-offset: -2px;
  -webkit-appearance: textfield;
}

/* rtl:raw:
[type="tel"],
[type="url"],
[type="email"],
[type="number"] {
  direction: ltr;
}
*/
::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-color-swatch-wrapper {
  padding: 0;
}

::file-selector-button {
  font: inherit;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

iframe {
  border: 0;
}

summary {
  display: list-item;
  cursor: pointer;
}

progress {
  vertical-align: baseline;
}

[hidden] {
  display: none !important;
}

.lead {
  font-size: 1.25rem;
  font-weight: 300;
}

.display-1 {
  font-size: calc(1.625rem + 4.5vw);
  font-weight: 600;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-1 {
    font-size: 5rem;
  }
}

.display-2 {
  font-size: calc(1.575rem + 3.9vw);
  font-weight: 600;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-2 {
    font-size: 4.5rem;
  }
}

.display-3 {
  font-size: calc(1.525rem + 3.3vw);
  font-weight: 600;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-3 {
    font-size: 4rem;
  }
}

.display-4 {
  font-size: calc(1.475rem + 2.7vw);
  font-weight: 600;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-4 {
    font-size: 3.5rem;
  }
}

.display-5 {
  font-size: calc(1.425rem + 2.1vw);
  font-weight: 600;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-5 {
    font-size: 3rem;
  }
}

.display-6 {
  font-size: calc(1.375rem + 1.5vw);
  font-weight: 600;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-6 {
    font-size: 2.5rem;
  }
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}
.list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}

.initialism {
  font-size: 0.875em;
  text-transform: uppercase;
}

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem;
}
.blockquote > :last-child {
  margin-bottom: 0;
}

.blockquote-footer {
  margin-top: -1rem;
  margin-bottom: 1rem;
  font-size: 0.875em;
  color: #7E8299;
}
.blockquote-footer::before {
  content: "— ";
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.img-thumbnail {
  padding: 0.25rem;
  background-color: #ffffff;
  border: 1px solid #E4E6EF;
  border-radius: 0.475rem;
  box-shadow: 0 0.1rem 1rem 0.25rem rgba(0, 0, 0, 0.05);
  max-width: 100%;
  height: auto;
}

.figure {
  display: inline-block;
}

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1;
}

.figure-caption {
  font-size: 0.875em;
  color: #7E8299;
}

.container,
.container-fluid,
.container-xxl,
.container-xl,
.container-lg,
.container-md,
.container-sm {
  width: 100%;
  padding-right: var(--bs-gutter-x, 1.5rem);
  padding-left: var(--bs-gutter-x, 1.5rem);
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container-sm, .container {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .container-md, .container-sm, .container {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .container-lg, .container-md, .container-sm, .container {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1140px;
  }
}
@media (min-width: 1400px) {
  .container-xxl, .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1320px;
  }
}
.row {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(var(--bs-gutter-y) * -1);
  margin-right: calc(var(--bs-gutter-x) / -2);
  margin-left: calc(var(--bs-gutter-x) / -2);
}
.row > * {
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
  padding-right: calc(var(--bs-gutter-x) / 2);
  padding-left: calc(var(--bs-gutter-x) / 2);
  margin-top: var(--bs-gutter-y);
}

.col {
  flex: 1 0 0%;
}

.row-cols-auto > * {
  flex: 0 0 auto;
  width: auto;
}

.row-cols-1 > * {
  flex: 0 0 auto;
  width: 100%;
}

.row-cols-2 > * {
  flex: 0 0 auto;
  width: 50%;
}

.row-cols-3 > * {
  flex: 0 0 auto;
  width: 33.3333333333%;
}

.row-cols-4 > * {
  flex: 0 0 auto;
  width: 25%;
}

.row-cols-5 > * {
  flex: 0 0 auto;
  width: 20%;
}

.row-cols-6 > * {
  flex: 0 0 auto;
  width: 16.6666666667%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
}

.col-1 {
  flex: 0 0 auto;
  width: 8.3333333333%;
}

.col-2 {
  flex: 0 0 auto;
  width: 16.6666666667%;
}

.col-3 {
  flex: 0 0 auto;
  width: 25%;
}

.col-4 {
  flex: 0 0 auto;
  width: 33.3333333333%;
}

.col-5 {
  flex: 0 0 auto;
  width: 41.6666666667%;
}

.col-6 {
  flex: 0 0 auto;
  width: 50%;
}

.col-7 {
  flex: 0 0 auto;
  width: 58.3333333333%;
}

.col-8 {
  flex: 0 0 auto;
  width: 66.6666666667%;
}

.col-9 {
  flex: 0 0 auto;
  width: 75%;
}

.col-10 {
  flex: 0 0 auto;
  width: 83.3333333333%;
}

.col-11 {
  flex: 0 0 auto;
  width: 91.6666666667%;
}

.col-12 {
  flex: 0 0 auto;
  width: 100%;
}

.offset-1 {
  margin-left: 8.3333333333%;
}

.offset-2 {
  margin-left: 16.6666666667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.3333333333%;
}

.offset-5 {
  margin-left: 41.6666666667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.3333333333%;
}

.offset-8 {
  margin-left: 66.6666666667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.3333333333%;
}

.offset-11 {
  margin-left: 91.6666666667%;
}

.g-0,
.gx-0 {
  --bs-gutter-x: 0;
}

.g-0,
.gy-0 {
  --bs-gutter-y: 0;
}

.g-1,
.gx-1 {
  --bs-gutter-x: 0.25rem;
}

.g-1,
.gy-1 {
  --bs-gutter-y: 0.25rem;
}

.g-2,
.gx-2 {
  --bs-gutter-x: 0.5rem;
}

.g-2,
.gy-2 {
  --bs-gutter-y: 0.5rem;
}

.g-3,
.gx-3 {
  --bs-gutter-x: 0.75rem;
}

.g-3,
.gy-3 {
  --bs-gutter-y: 0.75rem;
}

.g-4,
.gx-4 {
  --bs-gutter-x: 1rem;
}

.g-4,
.gy-4 {
  --bs-gutter-y: 1rem;
}

.g-5,
.gx-5 {
  --bs-gutter-x: 1.25rem;
}

.g-5,
.gy-5 {
  --bs-gutter-y: 1.25rem;
}

.g-6,
.gx-6 {
  --bs-gutter-x: 1.5rem;
}

.g-6,
.gy-6 {
  --bs-gutter-y: 1.5rem;
}

.g-7,
.gx-7 {
  --bs-gutter-x: 1.75rem;
}

.g-7,
.gy-7 {
  --bs-gutter-y: 1.75rem;
}

.g-8,
.gx-8 {
  --bs-gutter-x: 2rem;
}

.g-8,
.gy-8 {
  --bs-gutter-y: 2rem;
}

.g-9,
.gx-9 {
  --bs-gutter-x: 2.25rem;
}

.g-9,
.gy-9 {
  --bs-gutter-y: 2.25rem;
}

.g-10,
.gx-10 {
  --bs-gutter-x: 2.5rem;
}

.g-10,
.gy-10 {
  --bs-gutter-y: 2.5rem;
}

@media (min-width: 576px) {
  .col-sm {
    flex: 1 0 0%;
  }

  .row-cols-sm-auto > * {
    flex: 0 0 auto;
    width: auto;
  }

  .row-cols-sm-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }

  .row-cols-sm-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }

  .row-cols-sm-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }

  .row-cols-sm-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }

  .row-cols-sm-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }

  .row-cols-sm-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }

  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
  }

  .col-sm-1 {
    flex: 0 0 auto;
    width: 8.3333333333%;
  }

  .col-sm-2 {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }

  .col-sm-3 {
    flex: 0 0 auto;
    width: 25%;
  }

  .col-sm-4 {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }

  .col-sm-5 {
    flex: 0 0 auto;
    width: 41.6666666667%;
  }

  .col-sm-6 {
    flex: 0 0 auto;
    width: 50%;
  }

  .col-sm-7 {
    flex: 0 0 auto;
    width: 58.3333333333%;
  }

  .col-sm-8 {
    flex: 0 0 auto;
    width: 66.6666666667%;
  }

  .col-sm-9 {
    flex: 0 0 auto;
    width: 75%;
  }

  .col-sm-10 {
    flex: 0 0 auto;
    width: 83.3333333333%;
  }

  .col-sm-11 {
    flex: 0 0 auto;
    width: 91.6666666667%;
  }

  .col-sm-12 {
    flex: 0 0 auto;
    width: 100%;
  }

  .offset-sm-0 {
    margin-left: 0;
  }

  .offset-sm-1 {
    margin-left: 8.3333333333%;
  }

  .offset-sm-2 {
    margin-left: 16.6666666667%;
  }

  .offset-sm-3 {
    margin-left: 25%;
  }

  .offset-sm-4 {
    margin-left: 33.3333333333%;
  }

  .offset-sm-5 {
    margin-left: 41.6666666667%;
  }

  .offset-sm-6 {
    margin-left: 50%;
  }

  .offset-sm-7 {
    margin-left: 58.3333333333%;
  }

  .offset-sm-8 {
    margin-left: 66.6666666667%;
  }

  .offset-sm-9 {
    margin-left: 75%;
  }

  .offset-sm-10 {
    margin-left: 83.3333333333%;
  }

  .offset-sm-11 {
    margin-left: 91.6666666667%;
  }

  .g-sm-0,
.gx-sm-0 {
    --bs-gutter-x: 0;
  }

  .g-sm-0,
.gy-sm-0 {
    --bs-gutter-y: 0;
  }

  .g-sm-1,
.gx-sm-1 {
    --bs-gutter-x: 0.25rem;
  }

  .g-sm-1,
.gy-sm-1 {
    --bs-gutter-y: 0.25rem;
  }

  .g-sm-2,
.gx-sm-2 {
    --bs-gutter-x: 0.5rem;
  }

  .g-sm-2,
.gy-sm-2 {
    --bs-gutter-y: 0.5rem;
  }

  .g-sm-3,
.gx-sm-3 {
    --bs-gutter-x: 0.75rem;
  }

  .g-sm-3,
.gy-sm-3 {
    --bs-gutter-y: 0.75rem;
  }

  .g-sm-4,
.gx-sm-4 {
    --bs-gutter-x: 1rem;
  }

  .g-sm-4,
.gy-sm-4 {
    --bs-gutter-y: 1rem;
  }

  .g-sm-5,
.gx-sm-5 {
    --bs-gutter-x: 1.25rem;
  }

  .g-sm-5,
.gy-sm-5 {
    --bs-gutter-y: 1.25rem;
  }

  .g-sm-6,
.gx-sm-6 {
    --bs-gutter-x: 1.5rem;
  }

  .g-sm-6,
.gy-sm-6 {
    --bs-gutter-y: 1.5rem;
  }

  .g-sm-7,
.gx-sm-7 {
    --bs-gutter-x: 1.75rem;
  }

  .g-sm-7,
.gy-sm-7 {
    --bs-gutter-y: 1.75rem;
  }

  .g-sm-8,
.gx-sm-8 {
    --bs-gutter-x: 2rem;
  }

  .g-sm-8,
.gy-sm-8 {
    --bs-gutter-y: 2rem;
  }

  .g-sm-9,
.gx-sm-9 {
    --bs-gutter-x: 2.25rem;
  }

  .g-sm-9,
.gy-sm-9 {
    --bs-gutter-y: 2.25rem;
  }

  .g-sm-10,
.gx-sm-10 {
    --bs-gutter-x: 2.5rem;
  }

  .g-sm-10,
.gy-sm-10 {
    --bs-gutter-y: 2.5rem;
  }
}
@media (min-width: 768px) {
  .col-md {
    flex: 1 0 0%;
  }

  .row-cols-md-auto > * {
    flex: 0 0 auto;
    width: auto;
  }

  .row-cols-md-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }

  .row-cols-md-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }

  .row-cols-md-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }

  .row-cols-md-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }

  .row-cols-md-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }

  .row-cols-md-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }

  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
  }

  .col-md-1 {
    flex: 0 0 auto;
    width: 8.3333333333%;
  }

  .col-md-2 {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }

  .col-md-3 {
    flex: 0 0 auto;
    width: 25%;
  }

  .col-md-4 {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }

  .col-md-5 {
    flex: 0 0 auto;
    width: 41.6666666667%;
  }

  .col-md-6 {
    flex: 0 0 auto;
    width: 50%;
  }

  .col-md-7 {
    flex: 0 0 auto;
    width: 58.3333333333%;
  }

  .col-md-8 {
    flex: 0 0 auto;
    width: 66.6666666667%;
  }

  .col-md-9 {
    flex: 0 0 auto;
    width: 75%;
  }

  .col-md-10 {
    flex: 0 0 auto;
    width: 83.3333333333%;
  }

  .col-md-11 {
    flex: 0 0 auto;
    width: 91.6666666667%;
  }

  .col-md-12 {
    flex: 0 0 auto;
    width: 100%;
  }

  .offset-md-0 {
    margin-left: 0;
  }

  .offset-md-1 {
    margin-left: 8.3333333333%;
  }

  .offset-md-2 {
    margin-left: 16.6666666667%;
  }

  .offset-md-3 {
    margin-left: 25%;
  }

  .offset-md-4 {
    margin-left: 33.3333333333%;
  }

  .offset-md-5 {
    margin-left: 41.6666666667%;
  }

  .offset-md-6 {
    margin-left: 50%;
  }

  .offset-md-7 {
    margin-left: 58.3333333333%;
  }

  .offset-md-8 {
    margin-left: 66.6666666667%;
  }

  .offset-md-9 {
    margin-left: 75%;
  }

  .offset-md-10 {
    margin-left: 83.3333333333%;
  }

  .offset-md-11 {
    margin-left: 91.6666666667%;
  }

  .g-md-0,
.gx-md-0 {
    --bs-gutter-x: 0;
  }

  .g-md-0,
.gy-md-0 {
    --bs-gutter-y: 0;
  }

  .g-md-1,
.gx-md-1 {
    --bs-gutter-x: 0.25rem;
  }

  .g-md-1,
.gy-md-1 {
    --bs-gutter-y: 0.25rem;
  }

  .g-md-2,
.gx-md-2 {
    --bs-gutter-x: 0.5rem;
  }

  .g-md-2,
.gy-md-2 {
    --bs-gutter-y: 0.5rem;
  }

  .g-md-3,
.gx-md-3 {
    --bs-gutter-x: 0.75rem;
  }

  .g-md-3,
.gy-md-3 {
    --bs-gutter-y: 0.75rem;
  }

  .g-md-4,
.gx-md-4 {
    --bs-gutter-x: 1rem;
  }

  .g-md-4,
.gy-md-4 {
    --bs-gutter-y: 1rem;
  }

  .g-md-5,
.gx-md-5 {
    --bs-gutter-x: 1.25rem;
  }

  .g-md-5,
.gy-md-5 {
    --bs-gutter-y: 1.25rem;
  }

  .g-md-6,
.gx-md-6 {
    --bs-gutter-x: 1.5rem;
  }

  .g-md-6,
.gy-md-6 {
    --bs-gutter-y: 1.5rem;
  }

  .g-md-7,
.gx-md-7 {
    --bs-gutter-x: 1.75rem;
  }

  .g-md-7,
.gy-md-7 {
    --bs-gutter-y: 1.75rem;
  }

  .g-md-8,
.gx-md-8 {
    --bs-gutter-x: 2rem;
  }

  .g-md-8,
.gy-md-8 {
    --bs-gutter-y: 2rem;
  }

  .g-md-9,
.gx-md-9 {
    --bs-gutter-x: 2.25rem;
  }

  .g-md-9,
.gy-md-9 {
    --bs-gutter-y: 2.25rem;
  }

  .g-md-10,
.gx-md-10 {
    --bs-gutter-x: 2.5rem;
  }

  .g-md-10,
.gy-md-10 {
    --bs-gutter-y: 2.5rem;
  }
}
@media (min-width: 992px) {
  .col-lg {
    flex: 1 0 0%;
  }

  .row-cols-lg-auto > * {
    flex: 0 0 auto;
    width: auto;
  }

  .row-cols-lg-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }

  .row-cols-lg-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }

  .row-cols-lg-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }

  .row-cols-lg-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }

  .row-cols-lg-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }

  .row-cols-lg-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }

  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
  }

  .col-lg-1 {
    flex: 0 0 auto;
    width: 8.3333333333%;
  }

  .col-lg-2 {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }

  .col-lg-3 {
    flex: 0 0 auto;
    width: 25%;
  }

  .col-lg-4 {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }

  .col-lg-5 {
    flex: 0 0 auto;
    width: 41.6666666667%;
  }

  .col-lg-6 {
    flex: 0 0 auto;
    width: 50%;
  }

  .col-lg-7 {
    flex: 0 0 auto;
    width: 58.3333333333%;
  }

  .col-lg-8 {
    flex: 0 0 auto;
    width: 66.6666666667%;
  }

  .col-lg-9 {
    flex: 0 0 auto;
    width: 75%;
  }

  .col-lg-10 {
    flex: 0 0 auto;
    width: 83.3333333333%;
  }

  .col-lg-11 {
    flex: 0 0 auto;
    width: 91.6666666667%;
  }

  .col-lg-12 {
    flex: 0 0 auto;
    width: 100%;
  }

  .offset-lg-0 {
    margin-left: 0;
  }

  .offset-lg-1 {
    margin-left: 8.3333333333%;
  }

  .offset-lg-2 {
    margin-left: 16.6666666667%;
  }

  .offset-lg-3 {
    margin-left: 25%;
  }

  .offset-lg-4 {
    margin-left: 33.3333333333%;
  }

  .offset-lg-5 {
    margin-left: 41.6666666667%;
  }

  .offset-lg-6 {
    margin-left: 50%;
  }

  .offset-lg-7 {
    margin-left: 58.3333333333%;
  }

  .offset-lg-8 {
    margin-left: 66.6666666667%;
  }

  .offset-lg-9 {
    margin-left: 75%;
  }

  .offset-lg-10 {
    margin-left: 83.3333333333%;
  }

  .offset-lg-11 {
    margin-left: 91.6666666667%;
  }

  .g-lg-0,
.gx-lg-0 {
    --bs-gutter-x: 0;
  }

  .g-lg-0,
.gy-lg-0 {
    --bs-gutter-y: 0;
  }

  .g-lg-1,
.gx-lg-1 {
    --bs-gutter-x: 0.25rem;
  }

  .g-lg-1,
.gy-lg-1 {
    --bs-gutter-y: 0.25rem;
  }

  .g-lg-2,
.gx-lg-2 {
    --bs-gutter-x: 0.5rem;
  }

  .g-lg-2,
.gy-lg-2 {
    --bs-gutter-y: 0.5rem;
  }

  .g-lg-3,
.gx-lg-3 {
    --bs-gutter-x: 0.75rem;
  }

  .g-lg-3,
.gy-lg-3 {
    --bs-gutter-y: 0.75rem;
  }

  .g-lg-4,
.gx-lg-4 {
    --bs-gutter-x: 1rem;
  }

  .g-lg-4,
.gy-lg-4 {
    --bs-gutter-y: 1rem;
  }

  .g-lg-5,
.gx-lg-5 {
    --bs-gutter-x: 1.25rem;
  }

  .g-lg-5,
.gy-lg-5 {
    --bs-gutter-y: 1.25rem;
  }

  .g-lg-6,
.gx-lg-6 {
    --bs-gutter-x: 1.5rem;
  }

  .g-lg-6,
.gy-lg-6 {
    --bs-gutter-y: 1.5rem;
  }

  .g-lg-7,
.gx-lg-7 {
    --bs-gutter-x: 1.75rem;
  }

  .g-lg-7,
.gy-lg-7 {
    --bs-gutter-y: 1.75rem;
  }

  .g-lg-8,
.gx-lg-8 {
    --bs-gutter-x: 2rem;
  }

  .g-lg-8,
.gy-lg-8 {
    --bs-gutter-y: 2rem;
  }

  .g-lg-9,
.gx-lg-9 {
    --bs-gutter-x: 2.25rem;
  }

  .g-lg-9,
.gy-lg-9 {
    --bs-gutter-y: 2.25rem;
  }

  .g-lg-10,
.gx-lg-10 {
    --bs-gutter-x: 2.5rem;
  }

  .g-lg-10,
.gy-lg-10 {
    --bs-gutter-y: 2.5rem;
  }
}
@media (min-width: 1200px) {
  .col-xl {
    flex: 1 0 0%;
  }

  .row-cols-xl-auto > * {
    flex: 0 0 auto;
    width: auto;
  }

  .row-cols-xl-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }

  .row-cols-xl-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }

  .row-cols-xl-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }

  .row-cols-xl-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }

  .row-cols-xl-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }

  .row-cols-xl-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }

  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
  }

  .col-xl-1 {
    flex: 0 0 auto;
    width: 8.3333333333%;
  }

  .col-xl-2 {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }

  .col-xl-3 {
    flex: 0 0 auto;
    width: 25%;
  }

  .col-xl-4 {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }

  .col-xl-5 {
    flex: 0 0 auto;
    width: 41.6666666667%;
  }

  .col-xl-6 {
    flex: 0 0 auto;
    width: 50%;
  }

  .col-xl-7 {
    flex: 0 0 auto;
    width: 58.3333333333%;
  }

  .col-xl-8 {
    flex: 0 0 auto;
    width: 66.6666666667%;
  }

  .col-xl-9 {
    flex: 0 0 auto;
    width: 75%;
  }

  .col-xl-10 {
    flex: 0 0 auto;
    width: 83.3333333333%;
  }

  .col-xl-11 {
    flex: 0 0 auto;
    width: 91.6666666667%;
  }

  .col-xl-12 {
    flex: 0 0 auto;
    width: 100%;
  }

  .offset-xl-0 {
    margin-left: 0;
  }

  .offset-xl-1 {
    margin-left: 8.3333333333%;
  }

  .offset-xl-2 {
    margin-left: 16.6666666667%;
  }

  .offset-xl-3 {
    margin-left: 25%;
  }

  .offset-xl-4 {
    margin-left: 33.3333333333%;
  }

  .offset-xl-5 {
    margin-left: 41.6666666667%;
  }

  .offset-xl-6 {
    margin-left: 50%;
  }

  .offset-xl-7 {
    margin-left: 58.3333333333%;
  }

  .offset-xl-8 {
    margin-left: 66.6666666667%;
  }

  .offset-xl-9 {
    margin-left: 75%;
  }

  .offset-xl-10 {
    margin-left: 83.3333333333%;
  }

  .offset-xl-11 {
    margin-left: 91.6666666667%;
  }

  .g-xl-0,
.gx-xl-0 {
    --bs-gutter-x: 0;
  }

  .g-xl-0,
.gy-xl-0 {
    --bs-gutter-y: 0;
  }

  .g-xl-1,
.gx-xl-1 {
    --bs-gutter-x: 0.25rem;
  }

  .g-xl-1,
.gy-xl-1 {
    --bs-gutter-y: 0.25rem;
  }

  .g-xl-2,
.gx-xl-2 {
    --bs-gutter-x: 0.5rem;
  }

  .g-xl-2,
.gy-xl-2 {
    --bs-gutter-y: 0.5rem;
  }

  .g-xl-3,
.gx-xl-3 {
    --bs-gutter-x: 0.75rem;
  }

  .g-xl-3,
.gy-xl-3 {
    --bs-gutter-y: 0.75rem;
  }

  .g-xl-4,
.gx-xl-4 {
    --bs-gutter-x: 1rem;
  }

  .g-xl-4,
.gy-xl-4 {
    --bs-gutter-y: 1rem;
  }

  .g-xl-5,
.gx-xl-5 {
    --bs-gutter-x: 1.25rem;
  }

  .g-xl-5,
.gy-xl-5 {
    --bs-gutter-y: 1.25rem;
  }

  .g-xl-6,
.gx-xl-6 {
    --bs-gutter-x: 1.5rem;
  }

  .g-xl-6,
.gy-xl-6 {
    --bs-gutter-y: 1.5rem;
  }

  .g-xl-7,
.gx-xl-7 {
    --bs-gutter-x: 1.75rem;
  }

  .g-xl-7,
.gy-xl-7 {
    --bs-gutter-y: 1.75rem;
  }

  .g-xl-8,
.gx-xl-8 {
    --bs-gutter-x: 2rem;
  }

  .g-xl-8,
.gy-xl-8 {
    --bs-gutter-y: 2rem;
  }

  .g-xl-9,
.gx-xl-9 {
    --bs-gutter-x: 2.25rem;
  }

  .g-xl-9,
.gy-xl-9 {
    --bs-gutter-y: 2.25rem;
  }

  .g-xl-10,
.gx-xl-10 {
    --bs-gutter-x: 2.5rem;
  }

  .g-xl-10,
.gy-xl-10 {
    --bs-gutter-y: 2.5rem;
  }
}
@media (min-width: 1400px) {
  .col-xxl {
    flex: 1 0 0%;
  }

  .row-cols-xxl-auto > * {
    flex: 0 0 auto;
    width: auto;
  }

  .row-cols-xxl-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }

  .row-cols-xxl-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }

  .row-cols-xxl-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }

  .row-cols-xxl-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }

  .row-cols-xxl-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }

  .row-cols-xxl-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }

  .col-xxl-auto {
    flex: 0 0 auto;
    width: auto;
  }

  .col-xxl-1 {
    flex: 0 0 auto;
    width: 8.3333333333%;
  }

  .col-xxl-2 {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }

  .col-xxl-3 {
    flex: 0 0 auto;
    width: 25%;
  }

  .col-xxl-4 {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }

  .col-xxl-5 {
    flex: 0 0 auto;
    width: 41.6666666667%;
  }

  .col-xxl-6 {
    flex: 0 0 auto;
    width: 50%;
  }

  .col-xxl-7 {
    flex: 0 0 auto;
    width: 58.3333333333%;
  }

  .col-xxl-8 {
    flex: 0 0 auto;
    width: 66.6666666667%;
  }

  .col-xxl-9 {
    flex: 0 0 auto;
    width: 75%;
  }

  .col-xxl-10 {
    flex: 0 0 auto;
    width: 83.3333333333%;
  }

  .col-xxl-11 {
    flex: 0 0 auto;
    width: 91.6666666667%;
  }

  .col-xxl-12 {
    flex: 0 0 auto;
    width: 100%;
  }

  .offset-xxl-0 {
    margin-left: 0;
  }

  .offset-xxl-1 {
    margin-left: 8.3333333333%;
  }

  .offset-xxl-2 {
    margin-left: 16.6666666667%;
  }

  .offset-xxl-3 {
    margin-left: 25%;
  }

  .offset-xxl-4 {
    margin-left: 33.3333333333%;
  }

  .offset-xxl-5 {
    margin-left: 41.6666666667%;
  }

  .offset-xxl-6 {
    margin-left: 50%;
  }

  .offset-xxl-7 {
    margin-left: 58.3333333333%;
  }

  .offset-xxl-8 {
    margin-left: 66.6666666667%;
  }

  .offset-xxl-9 {
    margin-left: 75%;
  }

  .offset-xxl-10 {
    margin-left: 83.3333333333%;
  }

  .offset-xxl-11 {
    margin-left: 91.6666666667%;
  }

  .g-xxl-0,
.gx-xxl-0 {
    --bs-gutter-x: 0;
  }

  .g-xxl-0,
.gy-xxl-0 {
    --bs-gutter-y: 0;
  }

  .g-xxl-1,
.gx-xxl-1 {
    --bs-gutter-x: 0.25rem;
  }

  .g-xxl-1,
.gy-xxl-1 {
    --bs-gutter-y: 0.25rem;
  }

  .g-xxl-2,
.gx-xxl-2 {
    --bs-gutter-x: 0.5rem;
  }

  .g-xxl-2,
.gy-xxl-2 {
    --bs-gutter-y: 0.5rem;
  }

  .g-xxl-3,
.gx-xxl-3 {
    --bs-gutter-x: 0.75rem;
  }

  .g-xxl-3,
.gy-xxl-3 {
    --bs-gutter-y: 0.75rem;
  }

  .g-xxl-4,
.gx-xxl-4 {
    --bs-gutter-x: 1rem;
  }

  .g-xxl-4,
.gy-xxl-4 {
    --bs-gutter-y: 1rem;
  }

  .g-xxl-5,
.gx-xxl-5 {
    --bs-gutter-x: 1.25rem;
  }

  .g-xxl-5,
.gy-xxl-5 {
    --bs-gutter-y: 1.25rem;
  }

  .g-xxl-6,
.gx-xxl-6 {
    --bs-gutter-x: 1.5rem;
  }

  .g-xxl-6,
.gy-xxl-6 {
    --bs-gutter-y: 1.5rem;
  }

  .g-xxl-7,
.gx-xxl-7 {
    --bs-gutter-x: 1.75rem;
  }

  .g-xxl-7,
.gy-xxl-7 {
    --bs-gutter-y: 1.75rem;
  }

  .g-xxl-8,
.gx-xxl-8 {
    --bs-gutter-x: 2rem;
  }

  .g-xxl-8,
.gy-xxl-8 {
    --bs-gutter-y: 2rem;
  }

  .g-xxl-9,
.gx-xxl-9 {
    --bs-gutter-x: 2.25rem;
  }

  .g-xxl-9,
.gy-xxl-9 {
    --bs-gutter-y: 2.25rem;
  }

  .g-xxl-10,
.gx-xxl-10 {
    --bs-gutter-x: 2.5rem;
  }

  .g-xxl-10,
.gy-xxl-10 {
    --bs-gutter-y: 2.5rem;
  }
}
.table {
  --bs-table-bg: transparent;
  --bs-table-striped-color: #181C32;
  --bs-table-striped-bg: rgba(245, 248, 250, 0.75);
  --bs-table-active-color: #181C32;
  --bs-table-active-bg: #F5F8FA;
  --bs-table-hover-color: #181C32;
  --bs-table-hover-bg: #F5F8FA;
  width: 100%;
  margin-bottom: 1rem;
  color: #181C32;
  vertical-align: top;
  border-color: #EFF2F5;
}
.table > :not(caption) > * > * {
  padding: 0.75rem 0.75rem;
  background-color: var(--bs-table-bg);
  border-bottom-width: 1px;
  box-shadow: inset 0 0 0 9999px var(--bs-table-accent-bg);
}
.table > tbody {
  vertical-align: inherit;
}
.table > thead {
  vertical-align: bottom;
}
.table > :not(:last-child) > :last-child > * {
  border-bottom-color: currentColor;
}

.caption-top {
  caption-side: top;
}

.table-sm > :not(caption) > * > * {
  padding: 0.5rem 0.5rem;
}

.table-bordered > :not(caption) > * {
  border-width: 1px 0;
}
.table-bordered > :not(caption) > * > * {
  border-width: 0 1px;
}

.table-borderless > :not(caption) > * > * {
  border-bottom-width: 0;
}

.table-striped > tbody > tr:nth-of-type(odd) {
  --bs-table-accent-bg: var(--bs-table-striped-bg);
  color: var(--bs-table-striped-color);
}

.table-active {
  --bs-table-accent-bg: var(--bs-table-active-bg);
  color: var(--bs-table-active-color);
}

.table-hover > tbody > tr:hover {
  --bs-table-accent-bg: var(--bs-table-hover-bg);
  color: var(--bs-table-hover-color);
}

.table-primary {
  --bs-table-bg: #ccecfd;
  --bs-table-striped-bg: #c2e0f0;
  --bs-table-striped-color: #000000;
  --bs-table-active-bg: #b8d4e4;
  --bs-table-active-color: #000000;
  --bs-table-hover-bg: #bddaea;
  --bs-table-hover-color: #000000;
  color: #000000;
  border-color: #b8d4e4;
}

.table-secondary {
  --bs-table-bg: #fafafc;
  --bs-table-striped-bg: #eeeeef;
  --bs-table-striped-color: #000000;
  --bs-table-active-bg: #e1e1e3;
  --bs-table-active-color: #000000;
  --bs-table-hover-bg: #e7e7e9;
  --bs-table-hover-color: #000000;
  color: #000000;
  border-color: #e1e1e3;
}

.table-success {
  --bs-table-bg: #dcf5e7;
  --bs-table-striped-bg: #d1e9db;
  --bs-table-striped-color: #000000;
  --bs-table-active-bg: #c6ddd0;
  --bs-table-active-color: #000000;
  --bs-table-hover-bg: #cce3d6;
  --bs-table-hover-color: #000000;
  color: #000000;
  border-color: #c6ddd0;
}

.table-info {
  --bs-table-bg: #e3d7fb;
  --bs-table-striped-bg: #d8ccee;
  --bs-table-striped-color: #000000;
  --bs-table-active-bg: #ccc2e2;
  --bs-table-active-color: #000000;
  --bs-table-hover-bg: #d2c7e8;
  --bs-table-hover-color: #000000;
  color: #000000;
  border-color: #ccc2e2;
}

.table-warning {
  --bs-table-bg: #fff4cc;
  --bs-table-striped-bg: #f2e8c2;
  --bs-table-striped-color: #000000;
  --bs-table-active-bg: #e6dcb8;
  --bs-table-active-color: #000000;
  --bs-table-hover-bg: #ece2bd;
  --bs-table-hover-color: #000000;
  color: #000000;
  border-color: #e6dcb8;
}

.table-danger {
  --bs-table-bg: #fcd9e2;
  --bs-table-striped-bg: #efced7;
  --bs-table-striped-color: #000000;
  --bs-table-active-bg: #e3c3cb;
  --bs-table-active-color: #000000;
  --bs-table-hover-bg: #e9c9d1;
  --bs-table-hover-color: #000000;
  color: #000000;
  border-color: #e3c3cb;
}

.table-light {
  --bs-table-bg: #F5F8FA;
  --bs-table-striped-bg: #e9ecee;
  --bs-table-striped-color: #000000;
  --bs-table-active-bg: #dddfe1;
  --bs-table-active-color: #000000;
  --bs-table-hover-bg: #e3e5e7;
  --bs-table-hover-color: #000000;
  color: #000000;
  border-color: #dddfe1;
}

.table-dark {
  --bs-table-bg: #181C32;
  --bs-table-striped-bg: #24273c;
  --bs-table-striped-color: #ffffff;
  --bs-table-active-bg: #2f3347;
  --bs-table-active-color: #ffffff;
  --bs-table-hover-bg: #292d41;
  --bs-table-hover-color: #ffffff;
  color: #ffffff;
  border-color: #2f3347;
}

.table-responsive {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

@media (max-width: 575.98px) {
  .table-responsive-sm {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 767.98px) {
  .table-responsive-md {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 991.98px) {
  .table-responsive-lg {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 1199.98px) {
  .table-responsive-xl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 1399.98px) {
  .table-responsive-xxl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
.form-label {
  margin-bottom: 0.5rem;
  font-size: 1.05rem;
  font-weight: 500;
}

.col-form-label {
  padding-top: calc(0.75rem + 1px);
  padding-bottom: calc(0.75rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  font-weight: 500;
  line-height: 1.5;
}

.col-form-label-lg {
  padding-top: calc(0.825rem + 1px);
  padding-bottom: calc(0.825rem + 1px);
  font-size: 1.15rem;
}

.col-form-label-sm {
  padding-top: calc(0.55rem + 1px);
  padding-bottom: calc(0.55rem + 1px);
  font-size: 0.925rem;
}

.form-text {
  margin-top: 0.5rem;
  font-size: 0.925rem;
  color: #A1A5B7;
}

.form-control {
  display: block;
  width: 100%;
  padding: 0.75rem 1rem;
  font-size: 1.1rem;
  font-weight: 500;
  line-height: 1.5;
  color: #181C32;
  background-color: #ffffff;
  background-clip: padding-box;
  border: 1px solid #E4E6EF;
  appearance: none;
  border-radius: 0.475rem;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075);
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
}
.form-control[type=file] {
  overflow: hidden;
}
.form-control[type=file]:not(:disabled):not([readonly]) {
  cursor: pointer;
}
.form-control:focus {
  color: #181C32;
  background-color: #ffffff;
  border-color: #B5B5C3;
  outline: 0;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075), 0 0 0 0.25rem rgba(0, 158, 247, 0.25);
}
.form-control::-webkit-date-and-time-value {
  height: 1.5em;
}
.form-control::placeholder {
  color: #A1A5B7;
  opacity: 1;
}
.form-control:disabled, .form-control[readonly] {
  background-color: #EFF2F5;
  opacity: 1;
}
.form-control::file-selector-button {
  padding: 0.75rem 1rem;
  margin: -0.75rem -1rem;
  margin-inline-end: 1rem;
  color: #181C32;
  background-color: #F5F8FA;
  pointer-events: none;
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  border-inline-end-width: 1px;
  border-radius: 0;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control::file-selector-button {
    transition: none;
  }
}
.form-control:hover:not(:disabled):not([readonly])::file-selector-button {
  background-color: #e9ecee;
}
.form-control::-webkit-file-upload-button {
  padding: 0.75rem 1rem;
  margin: -0.75rem -1rem;
  margin-inline-end: 1rem;
  color: #181C32;
  background-color: #F5F8FA;
  pointer-events: none;
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  border-inline-end-width: 1px;
  border-radius: 0;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control::-webkit-file-upload-button {
    transition: none;
  }
}
.form-control:hover:not(:disabled):not([readonly])::-webkit-file-upload-button {
  background-color: #e9ecee;
}

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.75rem 0;
  margin-bottom: 0;
  line-height: 1.5;
  color: #181C32;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0;
}
.form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
  padding-right: 0;
  padding-left: 0;
}

.form-control-sm {
  min-height: calc(1.5em + 1.1rem + 2px);
  padding: 0.55rem 0.75rem;
  font-size: 0.925rem;
  border-radius: 0.475rem;
}
.form-control-sm::file-selector-button {
  padding: 0.55rem 0.75rem;
  margin: -0.55rem -0.75rem;
  margin-inline-end: 0.75rem;
}
.form-control-sm::-webkit-file-upload-button {
  padding: 0.55rem 0.75rem;
  margin: -0.55rem -0.75rem;
  margin-inline-end: 0.75rem;
}

.form-control-lg {
  min-height: calc(1.5em + 1.65rem + 2px);
  padding: 0.825rem 1.5rem;
  font-size: 1.15rem;
  border-radius: 0.475rem;
}
.form-control-lg::file-selector-button {
  padding: 0.825rem 1.5rem;
  margin: -0.825rem -1.5rem;
  margin-inline-end: 1.5rem;
}
.form-control-lg::-webkit-file-upload-button {
  padding: 0.825rem 1.5rem;
  margin: -0.825rem -1.5rem;
  margin-inline-end: 1.5rem;
}

textarea.form-control {
  min-height: calc(1.5em + 1.5rem + 2px);
}
textarea.form-control-sm {
  min-height: calc(1.5em + 1.1rem + 2px);
}
textarea.form-control-lg {
  min-height: calc(1.5em + 1.65rem + 2px);
}

.form-control-color {
  max-width: 3rem;
  height: auto;
  padding: 0.75rem;
}
.form-control-color:not(:disabled):not([readonly]) {
  cursor: pointer;
}
.form-control-color::-moz-color-swatch {
  height: 1.5em;
  border-radius: 0.475rem;
}
.form-control-color::-webkit-color-swatch {
  height: 1.5em;
  border-radius: 0.475rem;
}

.form-select {
  display: block;
  width: 100%;
  padding: 0.75rem 3rem 0.75rem 1rem;
  font-size: 1.1rem;
  font-weight: 500;
  line-height: 1.5;
  color: #181C32;
  background-color: #ffffff;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%237E8299' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 1rem center;
  background-size: 16px 12px;
  border: 1px solid #E4E6EF;
  border-radius: 0.475rem;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075);
  appearance: none;
}
.form-select:focus {
  border-color: #B5B5C3;
  outline: 0;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075), 0 0 0 0.25rem rgba(0, 158, 247, 0.25);
}
.form-select[multiple], .form-select[size]:not([size="1"]) {
  padding-right: 1rem;
  background-image: none;
}
.form-select:disabled {
  background-color: #EFF2F5;
}
.form-select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #181C32;
}

.form-select-sm {
  padding-top: 0.55rem;
  padding-bottom: 0.55rem;
  padding-left: 0.75rem;
  font-size: 0.925rem;
}

.form-select-lg {
  padding-top: 0.825rem;
  padding-bottom: 0.825rem;
  padding-left: 1.5rem;
  font-size: 1.15rem;
}

.form-check {
  display: block;
  min-height: 1.5rem;
  padding-left: 2.25rem;
  margin-bottom: 0.125rem;
}
.form-check .form-check-input {
  float: left;
  margin-left: -2.25rem;
}

.form-check-input {
  width: 1.75rem;
  height: 1.75rem;
  margin-top: -0.125rem;
  vertical-align: top;
  background-color: #ffffff;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  border: 1px solid rgba(0, 0, 0, 0.25);
  appearance: none;
  color-adjust: exact;
  transition: background-color 0.15s ease-in-out, background-position 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-check-input {
    transition: none;
  }
}
.form-check-input[type=checkbox] {
  border-radius: 0.45em;
}
.form-check-input[type=radio] {
  border-radius: 50%;
}
.form-check-input:active {
  filter: brightness(90%);
}
.form-check-input:focus {
  border-color: #B5B5C3;
  outline: 0;
  box-shadow: none;
}
.form-check-input:checked {
  background-color: #009EF7;
  border-color: #009EF7;
}
.form-check-input:checked[type=checkbox] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 13 11' width='13' height='11' fill='none'%3e%3cpath d='M11.0426 1.02893C11.3258 0.695792 11.8254 0.655283 12.1585 0.938451C12.4917 1.22162 12.5322 1.72124 12.249 2.05437L5.51985 9.97104C5.23224 10.3094 4.72261 10.3451 4.3907 10.05L0.828197 6.88335C0.50141 6.59288 0.471975 6.09249 0.762452 5.7657C1.05293 5.43891 1.55332 5.40948 1.88011 5.69995L4.83765 8.32889L11.0426 1.02893Z' fill='%23FFFFFF'/%3e%3c/svg%3e");
}
.form-check-input:checked[type=radio] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23FFFFFF'/%3e%3c/svg%3e");
}
.form-check-input[type=checkbox]:indeterminate {
  background-color: #009EF7;
  border-color: #009EF7;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23FFFFFF' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10h8'/%3e%3c/svg%3e");
}
.form-check-input:disabled {
  pointer-events: none;
  filter: none;
  opacity: 0.5;
}
.form-check-input[disabled] ~ .form-check-label, .form-check-input:disabled ~ .form-check-label {
  opacity: 0.5;
}

.form-switch {
  padding-left: 3.75rem;
}
.form-switch .form-check-input {
  width: 3.25rem;
  margin-left: -3.75rem;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%280, 0, 0, 0.25%29'/%3e%3c/svg%3e");
  background-position: left center;
  border-radius: 3.25rem;
  transition: background-position 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-switch .form-check-input {
    transition: none;
  }
}
.form-switch .form-check-input:focus {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23B5B5C3'/%3e%3c/svg%3e");
}
.form-switch .form-check-input:checked {
  background-position: right center;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23FFFFFF'/%3e%3c/svg%3e");
}

.form-check-inline {
  display: inline-block;
  margin-right: 1rem;
}

.btn-check {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}
.btn-check[disabled] + .btn, .btn-check:disabled + .btn {
  pointer-events: none;
  filter: none;
  opacity: 0.6;
}

.form-range {
  width: 100%;
  height: 1.5rem;
  padding: 0;
  background-color: transparent;
  appearance: none;
}
.form-range:focus {
  outline: 0;
}
.form-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #ffffff, 0 0 0 0.25rem rgba(0, 158, 247, 0.25);
}
.form-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #ffffff, 0 0 0 0.25rem rgba(0, 158, 247, 0.25);
}
.form-range::-moz-focus-outer {
  border: 0;
}
.form-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: -0.25rem;
  background-color: #009EF7;
  border: 0;
  border-radius: 1rem;
  box-shadow: 0 0.1rem 0.25rem rgba(0, 0, 0, 0.1);
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .form-range::-webkit-slider-thumb {
    transition: none;
  }
}
.form-range::-webkit-slider-thumb:active {
  background-color: #b3e2fd;
}
.form-range::-webkit-slider-runnable-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #E4E6EF;
  border-color: transparent;
  border-radius: 1rem;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075);
}
.form-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  background-color: #009EF7;
  border: 0;
  border-radius: 1rem;
  box-shadow: 0 0.1rem 0.25rem rgba(0, 0, 0, 0.1);
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .form-range::-moz-range-thumb {
    transition: none;
  }
}
.form-range::-moz-range-thumb:active {
  background-color: #b3e2fd;
}
.form-range::-moz-range-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #E4E6EF;
  border-color: transparent;
  border-radius: 1rem;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075);
}
.form-range:disabled {
  pointer-events: none;
}
.form-range:disabled::-webkit-slider-thumb {
  background-color: #A1A5B7;
}
.form-range:disabled::-moz-range-thumb {
  background-color: #A1A5B7;
}

.form-floating {
  position: relative;
}
.form-floating > .form-control,
.form-floating > .form-select {
  height: calc(3.5rem + 2px);
  padding: 1rem 1rem;
}
.form-floating > label {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  padding: 1rem 1rem;
  pointer-events: none;
  border: 1px solid transparent;
  transform-origin: 0 0;
  transition: opacity 0.1s ease-in-out, transform 0.1s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-floating > label {
    transition: none;
  }
}
.form-floating > .form-control::placeholder {
  color: transparent;
}
.form-floating > .form-control:focus, .form-floating > .form-control:not(:placeholder-shown) {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-control:-webkit-autofill {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-select {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-control:focus ~ label,
.form-floating > .form-control:not(:placeholder-shown) ~ label,
.form-floating > .form-select ~ label {
  opacity: 0.65;
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}
.form-floating > .form-control:-webkit-autofill ~ label {
  opacity: 0.65;
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}
.input-group > .form-control,
.input-group > .form-select {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
}
.input-group > .form-control:focus,
.input-group > .form-select:focus {
  z-index: 3;
}
.input-group .btn {
  position: relative;
  z-index: 2;
}
.input-group .btn:focus {
  z-index: 3;
}

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.75rem 1rem;
  font-size: 1.1rem;
  font-weight: 500;
  line-height: 1.5;
  color: #181C32;
  text-align: center;
  white-space: nowrap;
  background-color: #F5F8FA;
  border: 1px solid #E4E6EF;
  border-radius: 0.475rem;
}

.input-group-lg > .form-control,
.input-group-lg > .form-select,
.input-group-lg > .input-group-text,
.input-group-lg > .btn {
  padding: 0.825rem 1.5rem;
  font-size: 1.15rem;
  border-radius: 0.475rem;
}

.input-group-sm > .form-control,
.input-group-sm > .form-select,
.input-group-sm > .input-group-text,
.input-group-sm > .btn {
  padding: 0.55rem 0.75rem;
  font-size: 0.925rem;
  border-radius: 0.475rem;
}

.input-group-lg > .form-select,
.input-group-sm > .form-select {
  padding-right: 4rem;
}

.input-group:not(.has-validation) > :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu),
.input-group:not(.has-validation) > .dropdown-toggle:nth-last-child(n+3) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group.has-validation > :nth-last-child(n+3):not(.dropdown-toggle):not(.dropdown-menu),
.input-group.has-validation > .dropdown-toggle:nth-last-child(n+4) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
  margin-left: -1px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.5rem;
  font-size: 0.925rem;
  color: #50CD89;
}

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.75rem 1rem;
  margin-top: 0.1rem;
  font-size: 0.925rem;
  color: #000000;
  background-color: #50cd89;
  border-radius: 3px;
}

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: #50CD89;
  padding-right: calc(1.5em + 1.5rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%2350CD89' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.375rem) center;
  background-size: calc(0.75em + 0.75rem) calc(0.75em + 0.75rem);
}
.was-validated .form-control:valid:focus, .form-control.is-valid:focus {
  border-color: #50CD89;
  box-shadow: 0 0 0 0.25rem rgba(80, 205, 137, 0.25);
}

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: calc(1.5em + 1.5rem);
  background-position: top calc(0.375em + 0.375rem) right calc(0.375em + 0.375rem);
}

.was-validated .form-select:valid, .form-select.is-valid {
  border-color: #50CD89;
  padding-right: 5.5rem;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%237E8299' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e"), url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%2350CD89' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-position: right 1rem center, center right 3rem;
  background-size: 16px 12px, calc(0.75em + 0.75rem) calc(0.75em + 0.75rem);
}
.was-validated .form-select:valid:focus, .form-select.is-valid:focus {
  border-color: #50CD89;
  box-shadow: 0 0 0 0.25rem rgba(80, 205, 137, 0.25);
}

.was-validated .form-check-input:valid, .form-check-input.is-valid {
  border-color: #50CD89;
}
.was-validated .form-check-input:valid:checked, .form-check-input.is-valid:checked {
  background-color: #50CD89;
}
.was-validated .form-check-input:valid:focus, .form-check-input.is-valid:focus {
  box-shadow: 0 0 0 0.25rem rgba(80, 205, 137, 0.25);
}
.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #50CD89;
}

.form-check-inline .form-check-input ~ .valid-feedback {
  margin-left: 0.5em;
}

.was-validated .input-group .form-control:valid, .input-group .form-control.is-valid,
.was-validated .input-group .form-select:valid,
.input-group .form-select.is-valid {
  z-index: 3;
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.5rem;
  font-size: 0.925rem;
  color: #F1416C;
}

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.75rem 1rem;
  margin-top: 0.1rem;
  font-size: 0.925rem;
  color: #000000;
  background-color: #f1416c;
  border-radius: 3px;
}

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: #F1416C;
  padding-right: calc(1.5em + 1.5rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23F1416C'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23F1416C' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.375rem) center;
  background-size: calc(0.75em + 0.75rem) calc(0.75em + 0.75rem);
}
.was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
  border-color: #F1416C;
  box-shadow: 0 0 0 0.25rem rgba(241, 65, 108, 0.25);
}

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: calc(1.5em + 1.5rem);
  background-position: top calc(0.375em + 0.375rem) right calc(0.375em + 0.375rem);
}

.was-validated .form-select:invalid, .form-select.is-invalid {
  border-color: #F1416C;
  padding-right: 5.5rem;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%237E8299' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e"), url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23F1416C'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23F1416C' stroke='none'/%3e%3c/svg%3e");
  background-position: right 1rem center, center right 3rem;
  background-size: 16px 12px, calc(0.75em + 0.75rem) calc(0.75em + 0.75rem);
}
.was-validated .form-select:invalid:focus, .form-select.is-invalid:focus {
  border-color: #F1416C;
  box-shadow: 0 0 0 0.25rem rgba(241, 65, 108, 0.25);
}

.was-validated .form-check-input:invalid, .form-check-input.is-invalid {
  border-color: #F1416C;
}
.was-validated .form-check-input:invalid:checked, .form-check-input.is-invalid:checked {
  background-color: #F1416C;
}
.was-validated .form-check-input:invalid:focus, .form-check-input.is-invalid:focus {
  box-shadow: 0 0 0 0.25rem rgba(241, 65, 108, 0.25);
}
.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #F1416C;
}

.form-check-inline .form-check-input ~ .invalid-feedback {
  margin-left: 0.5em;
}

.was-validated .input-group .form-control:invalid, .input-group .form-control.is-invalid,
.was-validated .input-group .form-select:invalid,
.input-group .form-select.is-invalid {
  z-index: 3;
}

.btn {
  display: inline-block;
  font-weight: 500;
  line-height: 1.5;
  color: #181C32;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.75rem 1.5rem;
  font-size: 1.1rem;
  border-radius: 0.475rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}
.btn:hover {
  color: #181C32;
  text-decoration: none;
}
.btn-check:focus + .btn, .btn:focus {
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(0, 158, 247, 0.25);
}
.btn-check:checked + .btn, .btn-check:active + .btn, .btn:active, .btn.active {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
}
.btn-check:checked + .btn:focus, .btn-check:active + .btn:focus, .btn:active:focus, .btn.active:focus {
  box-shadow: 0 0 0 0.25rem rgba(0, 158, 247, 0.25), inset 0 3px 5px rgba(0, 0, 0, 0.125);
}
.btn:disabled, .btn.disabled, fieldset:disabled .btn {
  pointer-events: none;
  opacity: 0.6;
  box-shadow: none;
}

.btn-white {
  color: #000000;
  background-color: #ffffff;
  border-color: #ffffff;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
}
.btn-white:hover {
  color: #000000;
  background-color: white;
  border-color: white;
}
.btn-check:focus + .btn-white, .btn-white:focus {
  color: #000000;
  background-color: white;
  border-color: white;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.25rem rgba(217, 217, 217, 0.5);
}
.btn-check:checked + .btn-white, .btn-check:active + .btn-white, .btn-white:active, .btn-white.active, .show > .btn-white.dropdown-toggle {
  color: #000000;
  background-color: white;
  border-color: white;
}
.btn-check:checked + .btn-white:focus, .btn-check:active + .btn-white:focus, .btn-white:active:focus, .btn-white.active:focus, .show > .btn-white.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(217, 217, 217, 0.5);
}
.btn-white:disabled, .btn-white.disabled {
  color: #000000;
  background-color: #ffffff;
  border-color: #ffffff;
}

.btn-light {
  color: #000000;
  background-color: #F5F8FA;
  border-color: #F5F8FA;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
}
.btn-light:hover {
  color: #000000;
  background-color: #f7f9fb;
  border-color: #f6f9fb;
}
.btn-check:focus + .btn-light, .btn-light:focus {
  color: #000000;
  background-color: #f7f9fb;
  border-color: #f6f9fb;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.25rem rgba(208, 211, 213, 0.5);
}
.btn-check:checked + .btn-light, .btn-check:active + .btn-light, .btn-light:active, .btn-light.active, .show > .btn-light.dropdown-toggle {
  color: #000000;
  background-color: #f7f9fb;
  border-color: #f6f9fb;
}
.btn-check:checked + .btn-light:focus, .btn-check:active + .btn-light:focus, .btn-light:active:focus, .btn-light.active:focus, .show > .btn-light.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(208, 211, 213, 0.5);
}
.btn-light:disabled, .btn-light.disabled {
  color: #000000;
  background-color: #F5F8FA;
  border-color: #F5F8FA;
}

.btn-primary {
  color: #000000;
  background-color: #009EF7;
  border-color: #009EF7;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
}
.btn-primary:hover {
  color: #000000;
  background-color: #26adf8;
  border-color: #1aa8f8;
}
.btn-check:focus + .btn-primary, .btn-primary:focus {
  color: #000000;
  background-color: #26adf8;
  border-color: #1aa8f8;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.25rem rgba(0, 134, 210, 0.5);
}
.btn-check:checked + .btn-primary, .btn-check:active + .btn-primary, .btn-primary:active, .btn-primary.active, .show > .btn-primary.dropdown-toggle {
  color: #000000;
  background-color: #33b1f9;
  border-color: #1aa8f8;
}
.btn-check:checked + .btn-primary:focus, .btn-check:active + .btn-primary:focus, .btn-primary:active:focus, .btn-primary.active:focus, .show > .btn-primary.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(0, 134, 210, 0.5);
}
.btn-primary:disabled, .btn-primary.disabled {
  color: #000000;
  background-color: #009EF7;
  border-color: #009EF7;
}

.btn-secondary {
  color: #000000;
  background-color: #E4E6EF;
  border-color: #E4E6EF;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
}
.btn-secondary:hover {
  color: #000000;
  background-color: #e8eaf1;
  border-color: #e7e9f1;
}
.btn-check:focus + .btn-secondary, .btn-secondary:focus {
  color: #000000;
  background-color: #e8eaf1;
  border-color: #e7e9f1;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.25rem rgba(194, 196, 203, 0.5);
}
.btn-check:checked + .btn-secondary, .btn-check:active + .btn-secondary, .btn-secondary:active, .btn-secondary.active, .show > .btn-secondary.dropdown-toggle {
  color: #000000;
  background-color: #e9ebf2;
  border-color: #e7e9f1;
}
.btn-check:checked + .btn-secondary:focus, .btn-check:active + .btn-secondary:focus, .btn-secondary:active:focus, .btn-secondary.active:focus, .show > .btn-secondary.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(194, 196, 203, 0.5);
}
.btn-secondary:disabled, .btn-secondary.disabled {
  color: #000000;
  background-color: #E4E6EF;
  border-color: #E4E6EF;
}

.btn-success {
  color: #000000;
  background-color: #50CD89;
  border-color: #50CD89;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
}
.btn-success:hover {
  color: #000000;
  background-color: #6ad59b;
  border-color: #62d295;
}
.btn-check:focus + .btn-success, .btn-success:focus {
  color: #000000;
  background-color: #6ad59b;
  border-color: #62d295;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.25rem rgba(68, 174, 116, 0.5);
}
.btn-check:checked + .btn-success, .btn-check:active + .btn-success, .btn-success:active, .btn-success.active, .show > .btn-success.dropdown-toggle {
  color: #000000;
  background-color: #73d7a1;
  border-color: #62d295;
}
.btn-check:checked + .btn-success:focus, .btn-check:active + .btn-success:focus, .btn-success:active:focus, .btn-success.active:focus, .show > .btn-success.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(68, 174, 116, 0.5);
}
.btn-success:disabled, .btn-success.disabled {
  color: #000000;
  background-color: #50CD89;
  border-color: #50CD89;
}

.btn-info {
  color: #ffffff;
  background-color: #7239EA;
  border-color: #7239EA;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
}
.btn-info:hover {
  color: #ffffff;
  background-color: #6130c7;
  border-color: #5b2ebb;
}
.btn-check:focus + .btn-info, .btn-info:focus {
  color: #ffffff;
  background-color: #6130c7;
  border-color: #5b2ebb;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.25rem rgba(135, 87, 237, 0.5);
}
.btn-check:checked + .btn-info, .btn-check:active + .btn-info, .btn-info:active, .btn-info.active, .show > .btn-info.dropdown-toggle {
  color: #ffffff;
  background-color: #5b2ebb;
  border-color: #562bb0;
}
.btn-check:checked + .btn-info:focus, .btn-check:active + .btn-info:focus, .btn-info:active:focus, .btn-info.active:focus, .show > .btn-info.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(135, 87, 237, 0.5);
}
.btn-info:disabled, .btn-info.disabled {
  color: #ffffff;
  background-color: #7239EA;
  border-color: #7239EA;
}

.btn-warning {
  color: #000000;
  background-color: #FFC700;
  border-color: #FFC700;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
}
.btn-warning:hover {
  color: #000000;
  background-color: #ffcf26;
  border-color: #ffcd1a;
}
.btn-check:focus + .btn-warning, .btn-warning:focus {
  color: #000000;
  background-color: #ffcf26;
  border-color: #ffcd1a;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.25rem rgba(217, 169, 0, 0.5);
}
.btn-check:checked + .btn-warning, .btn-check:active + .btn-warning, .btn-warning:active, .btn-warning.active, .show > .btn-warning.dropdown-toggle {
  color: #000000;
  background-color: #ffd233;
  border-color: #ffcd1a;
}
.btn-check:checked + .btn-warning:focus, .btn-check:active + .btn-warning:focus, .btn-warning:active:focus, .btn-warning.active:focus, .show > .btn-warning.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(217, 169, 0, 0.5);
}
.btn-warning:disabled, .btn-warning.disabled {
  color: #000000;
  background-color: #FFC700;
  border-color: #FFC700;
}

.btn-danger {
  color: #000000;
  background-color: #F1416C;
  border-color: #F1416C;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
}
.btn-danger:hover {
  color: #000000;
  background-color: #f35e82;
  border-color: #f2547b;
}
.btn-check:focus + .btn-danger, .btn-danger:focus {
  color: #000000;
  background-color: #f35e82;
  border-color: #f2547b;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.25rem rgba(205, 55, 92, 0.5);
}
.btn-check:checked + .btn-danger, .btn-check:active + .btn-danger, .btn-danger:active, .btn-danger.active, .show > .btn-danger.dropdown-toggle {
  color: #000000;
  background-color: #f46789;
  border-color: #f2547b;
}
.btn-check:checked + .btn-danger:focus, .btn-check:active + .btn-danger:focus, .btn-danger:active:focus, .btn-danger.active:focus, .show > .btn-danger.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(205, 55, 92, 0.5);
}
.btn-danger:disabled, .btn-danger.disabled {
  color: #000000;
  background-color: #F1416C;
  border-color: #F1416C;
}

.btn-dark {
  color: #ffffff;
  background-color: #181C32;
  border-color: #181C32;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
}
.btn-dark:hover {
  color: #ffffff;
  background-color: #14182b;
  border-color: #131628;
}
.btn-check:focus + .btn-dark, .btn-dark:focus {
  color: #ffffff;
  background-color: #14182b;
  border-color: #131628;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.25rem rgba(59, 62, 81, 0.5);
}
.btn-check:checked + .btn-dark, .btn-check:active + .btn-dark, .btn-dark:active, .btn-dark.active, .show > .btn-dark.dropdown-toggle {
  color: #ffffff;
  background-color: #131628;
  border-color: #121526;
}
.btn-check:checked + .btn-dark:focus, .btn-check:active + .btn-dark:focus, .btn-dark:active:focus, .btn-dark.active:focus, .show > .btn-dark.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(59, 62, 81, 0.5);
}
.btn-dark:disabled, .btn-dark.disabled {
  color: #ffffff;
  background-color: #181C32;
  border-color: #181C32;
}

.btn-outline-white {
  color: #ffffff;
  border-color: #ffffff;
}
.btn-outline-white:hover {
  color: #000000;
  background-color: #ffffff;
  border-color: #ffffff;
}
.btn-check:focus + .btn-outline-white, .btn-outline-white:focus {
  box-shadow: 0 0 0 0.25rem rgba(255, 255, 255, 0.5);
}
.btn-check:checked + .btn-outline-white, .btn-check:active + .btn-outline-white, .btn-outline-white:active, .btn-outline-white.active, .btn-outline-white.dropdown-toggle.show {
  color: #000000;
  background-color: #ffffff;
  border-color: #ffffff;
}
.btn-check:checked + .btn-outline-white:focus, .btn-check:active + .btn-outline-white:focus, .btn-outline-white:active:focus, .btn-outline-white.active:focus, .btn-outline-white.dropdown-toggle.show:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(255, 255, 255, 0.5);
}
.btn-outline-white:disabled, .btn-outline-white.disabled {
  color: #ffffff;
  background-color: transparent;
}

.btn-outline-light {
  color: #F5F8FA;
  border-color: #F5F8FA;
}
.btn-outline-light:hover {
  color: #000000;
  background-color: #F5F8FA;
  border-color: #F5F8FA;
}
.btn-check:focus + .btn-outline-light, .btn-outline-light:focus {
  box-shadow: 0 0 0 0.25rem rgba(245, 248, 250, 0.5);
}
.btn-check:checked + .btn-outline-light, .btn-check:active + .btn-outline-light, .btn-outline-light:active, .btn-outline-light.active, .btn-outline-light.dropdown-toggle.show {
  color: #000000;
  background-color: #F5F8FA;
  border-color: #F5F8FA;
}
.btn-check:checked + .btn-outline-light:focus, .btn-check:active + .btn-outline-light:focus, .btn-outline-light:active:focus, .btn-outline-light.active:focus, .btn-outline-light.dropdown-toggle.show:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(245, 248, 250, 0.5);
}
.btn-outline-light:disabled, .btn-outline-light.disabled {
  color: #F5F8FA;
  background-color: transparent;
}

.btn-outline-primary {
  color: #009EF7;
  border-color: #009EF7;
}
.btn-outline-primary:hover {
  color: #000000;
  background-color: #009EF7;
  border-color: #009EF7;
}
.btn-check:focus + .btn-outline-primary, .btn-outline-primary:focus {
  box-shadow: 0 0 0 0.25rem rgba(0, 158, 247, 0.5);
}
.btn-check:checked + .btn-outline-primary, .btn-check:active + .btn-outline-primary, .btn-outline-primary:active, .btn-outline-primary.active, .btn-outline-primary.dropdown-toggle.show {
  color: #000000;
  background-color: #009EF7;
  border-color: #009EF7;
}
.btn-check:checked + .btn-outline-primary:focus, .btn-check:active + .btn-outline-primary:focus, .btn-outline-primary:active:focus, .btn-outline-primary.active:focus, .btn-outline-primary.dropdown-toggle.show:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(0, 158, 247, 0.5);
}
.btn-outline-primary:disabled, .btn-outline-primary.disabled {
  color: #009EF7;
  background-color: transparent;
}

.btn-outline-secondary {
  color: #E4E6EF;
  border-color: #E4E6EF;
}
.btn-outline-secondary:hover {
  color: #000000;
  background-color: #E4E6EF;
  border-color: #E4E6EF;
}
.btn-check:focus + .btn-outline-secondary, .btn-outline-secondary:focus {
  box-shadow: 0 0 0 0.25rem rgba(228, 230, 239, 0.5);
}
.btn-check:checked + .btn-outline-secondary, .btn-check:active + .btn-outline-secondary, .btn-outline-secondary:active, .btn-outline-secondary.active, .btn-outline-secondary.dropdown-toggle.show {
  color: #000000;
  background-color: #E4E6EF;
  border-color: #E4E6EF;
}
.btn-check:checked + .btn-outline-secondary:focus, .btn-check:active + .btn-outline-secondary:focus, .btn-outline-secondary:active:focus, .btn-outline-secondary.active:focus, .btn-outline-secondary.dropdown-toggle.show:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(228, 230, 239, 0.5);
}
.btn-outline-secondary:disabled, .btn-outline-secondary.disabled {
  color: #E4E6EF;
  background-color: transparent;
}

.btn-outline-success {
  color: #50CD89;
  border-color: #50CD89;
}
.btn-outline-success:hover {
  color: #000000;
  background-color: #50CD89;
  border-color: #50CD89;
}
.btn-check:focus + .btn-outline-success, .btn-outline-success:focus {
  box-shadow: 0 0 0 0.25rem rgba(80, 205, 137, 0.5);
}
.btn-check:checked + .btn-outline-success, .btn-check:active + .btn-outline-success, .btn-outline-success:active, .btn-outline-success.active, .btn-outline-success.dropdown-toggle.show {
  color: #000000;
  background-color: #50CD89;
  border-color: #50CD89;
}
.btn-check:checked + .btn-outline-success:focus, .btn-check:active + .btn-outline-success:focus, .btn-outline-success:active:focus, .btn-outline-success.active:focus, .btn-outline-success.dropdown-toggle.show:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(80, 205, 137, 0.5);
}
.btn-outline-success:disabled, .btn-outline-success.disabled {
  color: #50CD89;
  background-color: transparent;
}

.btn-outline-info {
  color: #7239EA;
  border-color: #7239EA;
}
.btn-outline-info:hover {
  color: #ffffff;
  background-color: #7239EA;
  border-color: #7239EA;
}
.btn-check:focus + .btn-outline-info, .btn-outline-info:focus {
  box-shadow: 0 0 0 0.25rem rgba(114, 57, 234, 0.5);
}
.btn-check:checked + .btn-outline-info, .btn-check:active + .btn-outline-info, .btn-outline-info:active, .btn-outline-info.active, .btn-outline-info.dropdown-toggle.show {
  color: #ffffff;
  background-color: #7239EA;
  border-color: #7239EA;
}
.btn-check:checked + .btn-outline-info:focus, .btn-check:active + .btn-outline-info:focus, .btn-outline-info:active:focus, .btn-outline-info.active:focus, .btn-outline-info.dropdown-toggle.show:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(114, 57, 234, 0.5);
}
.btn-outline-info:disabled, .btn-outline-info.disabled {
  color: #7239EA;
  background-color: transparent;
}

.btn-outline-warning {
  color: #FFC700;
  border-color: #FFC700;
}
.btn-outline-warning:hover {
  color: #000000;
  background-color: #FFC700;
  border-color: #FFC700;
}
.btn-check:focus + .btn-outline-warning, .btn-outline-warning:focus {
  box-shadow: 0 0 0 0.25rem rgba(255, 199, 0, 0.5);
}
.btn-check:checked + .btn-outline-warning, .btn-check:active + .btn-outline-warning, .btn-outline-warning:active, .btn-outline-warning.active, .btn-outline-warning.dropdown-toggle.show {
  color: #000000;
  background-color: #FFC700;
  border-color: #FFC700;
}
.btn-check:checked + .btn-outline-warning:focus, .btn-check:active + .btn-outline-warning:focus, .btn-outline-warning:active:focus, .btn-outline-warning.active:focus, .btn-outline-warning.dropdown-toggle.show:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(255, 199, 0, 0.5);
}
.btn-outline-warning:disabled, .btn-outline-warning.disabled {
  color: #FFC700;
  background-color: transparent;
}

.btn-outline-danger {
  color: #F1416C;
  border-color: #F1416C;
}
.btn-outline-danger:hover {
  color: #000000;
  background-color: #F1416C;
  border-color: #F1416C;
}
.btn-check:focus + .btn-outline-danger, .btn-outline-danger:focus {
  box-shadow: 0 0 0 0.25rem rgba(241, 65, 108, 0.5);
}
.btn-check:checked + .btn-outline-danger, .btn-check:active + .btn-outline-danger, .btn-outline-danger:active, .btn-outline-danger.active, .btn-outline-danger.dropdown-toggle.show {
  color: #000000;
  background-color: #F1416C;
  border-color: #F1416C;
}
.btn-check:checked + .btn-outline-danger:focus, .btn-check:active + .btn-outline-danger:focus, .btn-outline-danger:active:focus, .btn-outline-danger.active:focus, .btn-outline-danger.dropdown-toggle.show:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(241, 65, 108, 0.5);
}
.btn-outline-danger:disabled, .btn-outline-danger.disabled {
  color: #F1416C;
  background-color: transparent;
}

.btn-outline-dark {
  color: #181C32;
  border-color: #181C32;
}
.btn-outline-dark:hover {
  color: #ffffff;
  background-color: #181C32;
  border-color: #181C32;
}
.btn-check:focus + .btn-outline-dark, .btn-outline-dark:focus {
  box-shadow: 0 0 0 0.25rem rgba(24, 28, 50, 0.5);
}
.btn-check:checked + .btn-outline-dark, .btn-check:active + .btn-outline-dark, .btn-outline-dark:active, .btn-outline-dark.active, .btn-outline-dark.dropdown-toggle.show {
  color: #ffffff;
  background-color: #181C32;
  border-color: #181C32;
}
.btn-check:checked + .btn-outline-dark:focus, .btn-check:active + .btn-outline-dark:focus, .btn-outline-dark:active:focus, .btn-outline-dark.active:focus, .btn-outline-dark.dropdown-toggle.show:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(24, 28, 50, 0.5);
}
.btn-outline-dark:disabled, .btn-outline-dark.disabled {
  color: #181C32;
  background-color: transparent;
}

.btn-link {
  font-weight: 400;
  color: #009EF7;
  text-decoration: none;
}
.btn-link:hover {
  color: #006dab;
  text-decoration: underline;
}
.btn-link:focus {
  text-decoration: underline;
}
.btn-link:disabled, .btn-link.disabled {
  color: #7E8299;
}

.btn-lg, .btn-group-lg > .btn {
  padding: 0.825rem 1.75rem;
  font-size: 1.15rem;
  border-radius: 0.475rem;
}

.btn-sm, .btn-group-sm > .btn {
  padding: 0.55rem 1.25rem;
  font-size: 0.925rem;
  border-radius: 0.475rem;
}

.fade {
  transition: opacity 0.15s linear;
}
@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}
.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}

.dropup,
.dropend,
.dropdown,
.dropstart {
  position: relative;
}

.dropdown-toggle {
  white-space: nowrap;
}
.dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}
.dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  z-index: 1000;
  display: none;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0;
  font-size: 1rem;
  color: #181C32;
  text-align: left;
  list-style: none;
  background-color: #ffffff;
  background-clip: padding-box;
  border: 0 solid rgba(0, 0, 0, 0.15);
  border-radius: 0.475rem;
  box-shadow: 0px 0px 50px 0px rgba(82, 63, 105, 0.15);
}
.dropdown-menu[data-bs-popper] {
  left: 0;
  margin-top: 0.125rem;
}

.dropdown-menu-start {
  --bs-position: start;
}
.dropdown-menu-start[data-bs-popper] {
  right: auto /* rtl:ignore */;
  left: 0 /* rtl:ignore */;
}

.dropdown-menu-end {
  --bs-position: end;
}
.dropdown-menu-end[data-bs-popper] {
  right: 0 /* rtl:ignore */;
  left: auto /* rtl:ignore */;
}

@media (min-width: 576px) {
  .dropdown-menu-sm-start {
    --bs-position: start;
  }
  .dropdown-menu-sm-start[data-bs-popper] {
    right: auto /* rtl:ignore */;
    left: 0 /* rtl:ignore */;
  }

  .dropdown-menu-sm-end {
    --bs-position: end;
  }
  .dropdown-menu-sm-end[data-bs-popper] {
    right: 0 /* rtl:ignore */;
    left: auto /* rtl:ignore */;
  }
}
@media (min-width: 768px) {
  .dropdown-menu-md-start {
    --bs-position: start;
  }
  .dropdown-menu-md-start[data-bs-popper] {
    right: auto /* rtl:ignore */;
    left: 0 /* rtl:ignore */;
  }

  .dropdown-menu-md-end {
    --bs-position: end;
  }
  .dropdown-menu-md-end[data-bs-popper] {
    right: 0 /* rtl:ignore */;
    left: auto /* rtl:ignore */;
  }
}
@media (min-width: 992px) {
  .dropdown-menu-lg-start {
    --bs-position: start;
  }
  .dropdown-menu-lg-start[data-bs-popper] {
    right: auto /* rtl:ignore */;
    left: 0 /* rtl:ignore */;
  }

  .dropdown-menu-lg-end {
    --bs-position: end;
  }
  .dropdown-menu-lg-end[data-bs-popper] {
    right: 0 /* rtl:ignore */;
    left: auto /* rtl:ignore */;
  }
}
@media (min-width: 1200px) {
  .dropdown-menu-xl-start {
    --bs-position: start;
  }
  .dropdown-menu-xl-start[data-bs-popper] {
    right: auto /* rtl:ignore */;
    left: 0 /* rtl:ignore */;
  }

  .dropdown-menu-xl-end {
    --bs-position: end;
  }
  .dropdown-menu-xl-end[data-bs-popper] {
    right: 0 /* rtl:ignore */;
    left: auto /* rtl:ignore */;
  }
}
@media (min-width: 1400px) {
  .dropdown-menu-xxl-start {
    --bs-position: start;
  }
  .dropdown-menu-xxl-start[data-bs-popper] {
    right: auto /* rtl:ignore */;
    left: 0 /* rtl:ignore */;
  }

  .dropdown-menu-xxl-end {
    --bs-position: end;
  }
  .dropdown-menu-xxl-end[data-bs-popper] {
    right: 0 /* rtl:ignore */;
    left: auto /* rtl:ignore */;
  }
}
.dropup .dropdown-menu[data-bs-popper] {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem;
}
.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent;
}
.dropup .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropend .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
}
.dropend .dropdown-menu[data-bs-popper] {
  margin-top: 0;
  margin-left: 0.125rem;
}
.dropend .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid;
}
.dropend .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropend .dropdown-toggle::after {
  vertical-align: 0;
}

.dropstart .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
}
.dropstart .dropdown-menu[data-bs-popper] {
  margin-top: 0;
  margin-right: 0.125rem;
}
.dropstart .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
}
.dropstart .dropdown-toggle::after {
  display: none;
}
.dropstart .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent;
}
.dropstart .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropstart .dropdown-toggle::before {
  vertical-align: 0;
}

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid rgba(0, 0, 0, 0.15);
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1rem;
  clear: both;
  font-weight: 400;
  color: #181C32;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}
.dropdown-item:hover, .dropdown-item:focus {
  color: #16192d;
  text-decoration: none;
  background-color: #EFF2F5;
}
.dropdown-item.active, .dropdown-item:active {
  color: #FFFFFF;
  text-decoration: none;
  background-color: #009EF7;
}
.dropdown-item.disabled, .dropdown-item:disabled {
  color: #A1A5B7;
  pointer-events: none;
  background-color: transparent;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-header {
  display: block;
  padding: 0.5rem 1rem;
  margin-bottom: 0;
  font-size: 0.925rem;
  color: #7E8299;
  white-space: nowrap;
}

.dropdown-item-text {
  display: block;
  padding: 0.25rem 1rem;
  color: #181C32;
}

.dropdown-menu-dark {
  color: #E4E6EF;
  background-color: #3F4254;
  border-color: rgba(0, 0, 0, 0.15);
}
.dropdown-menu-dark .dropdown-item {
  color: #E4E6EF;
}
.dropdown-menu-dark .dropdown-item:hover, .dropdown-menu-dark .dropdown-item:focus {
  color: #ffffff;
  background-color: rgba(255, 255, 255, 0.15);
}
.dropdown-menu-dark .dropdown-item.active, .dropdown-menu-dark .dropdown-item:active {
  color: #FFFFFF;
  background-color: #009EF7;
}
.dropdown-menu-dark .dropdown-item.disabled, .dropdown-menu-dark .dropdown-item:disabled {
  color: #A1A5B7;
}
.dropdown-menu-dark .dropdown-divider {
  border-color: rgba(0, 0, 0, 0.15);
}
.dropdown-menu-dark .dropdown-item-text {
  color: #E4E6EF;
}
.dropdown-menu-dark .dropdown-header {
  color: #A1A5B7;
}

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle;
}
.btn-group > .btn,
.btn-group-vertical > .btn {
  position: relative;
  flex: 1 1 auto;
}
.btn-group > .btn-check:checked + .btn,
.btn-group > .btn-check:focus + .btn,
.btn-group > .btn:hover,
.btn-group > .btn:focus,
.btn-group > .btn:active,
.btn-group > .btn.active,
.btn-group-vertical > .btn-check:checked + .btn,
.btn-group-vertical > .btn-check:focus + .btn,
.btn-group-vertical > .btn:hover,
.btn-group-vertical > .btn:focus,
.btn-group-vertical > .btn:active,
.btn-group-vertical > .btn.active {
  z-index: 1;
}

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.btn-toolbar .input-group {
  width: auto;
}

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) {
  margin-left: -1px;
}
.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.btn-group > .btn:nth-child(n+3),
.btn-group > :not(.btn-check) + .btn,
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.dropdown-toggle-split {
  padding-right: 1.125rem;
  padding-left: 1.125rem;
}
.dropdown-toggle-split::after, .dropup .dropdown-toggle-split::after, .dropend .dropdown-toggle-split::after {
  margin-left: 0;
}
.dropstart .dropdown-toggle-split::before {
  margin-right: 0;
}

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.9375rem;
  padding-left: 0.9375rem;
}

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 1.3125rem;
  padding-left: 1.3125rem;
}

.btn-group.show .dropdown-toggle {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
}
.btn-group.show .dropdown-toggle.btn-link {
  box-shadow: none;
}

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.btn-group-vertical > .btn,
.btn-group-vertical > .btn-group {
  width: 100%;
}
.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) {
  margin-top: -1px;
}
.btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group-vertical > .btn-group:not(:last-child) > .btn {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.btn-group-vertical > .btn ~ .btn,
.btn-group-vertical > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-link {
  display: block;
  padding: 0.5rem 1rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .nav-link {
    transition: none;
  }
}
.nav-link:hover, .nav-link:focus {
  text-decoration: none;
}
.nav-link.disabled {
  color: #7E8299;
  pointer-events: none;
  cursor: default;
}

.nav-tabs {
  border-bottom: 1px solid #EFF2F5;
}
.nav-tabs .nav-link {
  margin-bottom: -1px;
  background: none;
  border: 1px solid transparent;
  border-top-left-radius: 0.475rem;
  border-top-right-radius: 0.475rem;
}
.nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
  border-color: #EFF2F5 #EFF2F5 #EFF2F5;
  isolation: isolate;
}
.nav-tabs .nav-link.disabled {
  color: #7E8299;
  background-color: transparent;
  border-color: transparent;
}
.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: #5E6278;
  background-color: #ffffff;
  border-color: #E4E6EF #E4E6EF #ffffff;
}
.nav-tabs .dropdown-menu {
  margin-top: -1px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav-pills .nav-link {
  background: none;
  border: 0;
  border-radius: 0.475rem;
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #FFFFFF;
  background-color: #009EF7;
}

.nav-fill > .nav-link,
.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center;
}

.nav-justified > .nav-link,
.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
}

.nav-fill .nav-item .nav-link,
.nav-justified .nav-item .nav-link {
  width: 100%;
}

.tab-content > .tab-pane {
  display: none;
}
.tab-content > .active {
  display: block;
}

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.navbar > .container,
.navbar > .container-fluid,
.navbar > .container-sm,
.navbar > .container-md,
.navbar > .container-lg,
.navbar > .container-xl,
.navbar > .container-xxl {
  display: flex;
  flex-wrap: inherit;
  align-items: center;
  justify-content: space-between;
}
.navbar-brand {
  padding-top: 0.44375rem;
  padding-bottom: 0.44375rem;
  margin-right: 1rem;
  font-size: 1.075rem;
  white-space: nowrap;
}
.navbar-brand:hover, .navbar-brand:focus {
  text-decoration: none;
}

.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}
.navbar-nav .nav-link {
  padding-right: 0;
  padding-left: 0;
}
.navbar-nav .dropdown-menu {
  position: static;
}

.navbar-text {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center;
}

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.075rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.475rem;
  transition: box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .navbar-toggler {
    transition: none;
  }
}
.navbar-toggler:hover {
  text-decoration: none;
}
.navbar-toggler:focus {
  text-decoration: none;
  outline: 0;
  box-shadow: 0 0 0 0.25rem;
}

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
}

.navbar-nav-scroll {
  max-height: var(--bs-scroll-height, 75vh);
  overflow-y: auto;
}

@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-sm .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-sm .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-sm .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-sm .navbar-toggler {
    display: none;
  }
}
@media (min-width: 768px) {
  .navbar-expand-md {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-md .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-md .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-md .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-md .navbar-toggler {
    display: none;
  }
}
@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-lg .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-lg .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-lg .navbar-toggler {
    display: none;
  }
}
@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-xl .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-xl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xl .navbar-toggler {
    display: none;
  }
}
@media (min-width: 1400px) {
  .navbar-expand-xxl {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xxl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xxl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xxl .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-xxl .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-xxl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xxl .navbar-toggler {
    display: none;
  }
}
.navbar-expand {
  flex-wrap: nowrap;
  justify-content: flex-start;
}
.navbar-expand .navbar-nav {
  flex-direction: row;
}
.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}
.navbar-expand .navbar-nav .nav-link {
  padding-right: 0.5rem;
  padding-left: 0.5rem;
}
.navbar-expand .navbar-nav-scroll {
  overflow: visible;
}
.navbar-expand .navbar-collapse {
  display: flex !important;
  flex-basis: auto;
}
.navbar-expand .navbar-toggler {
  display: none;
}

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.55);
}
.navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
  color: rgba(0, 0, 0, 0.7);
}
.navbar-light .navbar-nav .nav-link.disabled {
  color: rgba(0, 0, 0, 0.3);
}
.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.55);
  border-color: rgba(0, 0, 0, 0.1);
}
.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 0, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}
.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.55);
}
.navbar-light .navbar-text a,
.navbar-light .navbar-text a:hover,
.navbar-light .navbar-text a:focus {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-dark .navbar-brand {
  color: #ffffff;
}
.navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
  color: #ffffff;
}
.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.55);
}
.navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
  color: rgba(255, 255, 255, 0.75);
}
.navbar-dark .navbar-nav .nav-link.disabled {
  color: rgba(255, 255, 255, 0.25);
}
.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .nav-link.active {
  color: #ffffff;
}
.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.55);
  border-color: rgba(255, 255, 255, 0.1);
}
.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}
.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.55);
}
.navbar-dark .navbar-text a,
.navbar-dark .navbar-text a:hover,
.navbar-dark .navbar-text a:focus {
  color: #ffffff;
}

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #ffffff;
  background-clip: border-box;
  border: 1px solid #EFF2F5;
  border-radius: 0.475rem;
}
.card > hr {
  margin-right: 0;
  margin-left: 0;
}
.card > .list-group {
  border-top: inherit;
  border-bottom: inherit;
}
.card > .list-group:first-child {
  border-top-width: 0;
  border-top-left-radius: calc(0.475rem - 1px);
  border-top-right-radius: calc(0.475rem - 1px);
}
.card > .list-group:last-child {
  border-bottom-width: 0;
  border-bottom-right-radius: calc(0.475rem - 1px);
  border-bottom-left-radius: calc(0.475rem - 1px);
}
.card > .card-header + .list-group,
.card > .list-group + .card-footer {
  border-top: 0;
}

.card-body {
  flex: 1 1 auto;
  padding: 1rem 1rem;
}

.card-title {
  margin-bottom: 0.5rem;
}

.card-subtitle {
  margin-top: -0.25rem;
  margin-bottom: 0;
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link:hover {
  text-decoration: none;
}
.card-link + .card-link {
  margin-left: 1rem;
}

.card-header {
  padding: 0.5rem 1rem;
  margin-bottom: 0;
  background-color: #ffffff;
  border-bottom: 1px solid #EFF2F5;
}
.card-header:first-child {
  border-radius: calc(0.475rem - 1px) calc(0.475rem - 1px) 0 0;
}

.card-footer {
  padding: 0.5rem 1rem;
  background-color: #ffffff;
  border-top: 1px solid #EFF2F5;
}
.card-footer:last-child {
  border-radius: 0 0 calc(0.475rem - 1px) calc(0.475rem - 1px);
}

.card-header-tabs {
  margin-right: -0.5rem;
  margin-bottom: -0.5rem;
  margin-left: -0.5rem;
  border-bottom: 0;
}

.card-header-pills {
  margin-right: -0.5rem;
  margin-left: -0.5rem;
}

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1rem;
  border-radius: calc(0.475rem - 1px);
}

.card-img,
.card-img-top,
.card-img-bottom {
  width: 100%;
}

.card-img,
.card-img-top {
  border-top-left-radius: calc(0.475rem - 1px);
  border-top-right-radius: calc(0.475rem - 1px);
}

.card-img,
.card-img-bottom {
  border-bottom-right-radius: calc(0.475rem - 1px);
  border-bottom-left-radius: calc(0.475rem - 1px);
}

.card-group > .card {
  margin-bottom: 0.75rem;
}
@media (min-width: 576px) {
  .card-group {
    display: flex;
    flex-flow: row wrap;
  }
  .card-group > .card {
    flex: 1 0 0%;
    margin-bottom: 0;
  }
  .card-group > .card + .card {
    margin-left: 0;
    border-left: 0;
  }
  .card-group > .card:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-top,
.card-group > .card:not(:last-child) .card-header {
    border-top-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-bottom,
.card-group > .card:not(:last-child) .card-footer {
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-top,
.card-group > .card:not(:first-child) .card-header {
    border-top-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-bottom,
.card-group > .card:not(:first-child) .card-footer {
    border-bottom-left-radius: 0;
  }
}

.accordion-button {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  padding: 1.5rem 1.5rem;
  font-size: 1rem;
  color: #181C32;
  text-align: left;
  background-color: #ffffff;
  border: 0;
  border-radius: 0;
  overflow-anchor: none;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, border-radius 0.15s ease;
}
@media (prefers-reduced-motion: reduce) {
  .accordion-button {
    transition: none;
  }
}
.accordion-button:not(.collapsed) {
  color: #009EF7;
  background-color: #F5F8FA;
  box-shadow: inset 0 -1px 0 #EFF2F5;
}
.accordion-button:not(.collapsed)::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23009EF7'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  transform: rotate(180deg);
}
.accordion-button::after {
  flex-shrink: 0;
  width: 1.15rem;
  height: 1.15rem;
  margin-left: auto;
  content: "";
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23181C32'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-size: 1.15rem;
  transition: transform 0.2s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .accordion-button::after {
    transition: none;
  }
}
.accordion-button:hover {
  z-index: 2;
}
.accordion-button:focus {
  z-index: 3;
  border-color: #EFF2F5;
  outline: 0;
  box-shadow: none;
}

.accordion-header {
  margin-bottom: 0;
}

.accordion-item {
  margin-bottom: -1px;
  background-color: #ffffff;
  border: 1px solid #EFF2F5;
}
.accordion-item:first-of-type {
  border-top-left-radius: 0.475rem;
  border-top-right-radius: 0.475rem;
}
.accordion-item:first-of-type .accordion-button {
  border-top-left-radius: calc(0.475rem - 1px);
  border-top-right-radius: calc(0.475rem - 1px);
}
.accordion-item:last-of-type {
  margin-bottom: 0;
  border-bottom-right-radius: 0.475rem;
  border-bottom-left-radius: 0.475rem;
}
.accordion-item:last-of-type .accordion-button.collapsed {
  border-bottom-right-radius: calc(0.475rem - 1px);
  border-bottom-left-radius: calc(0.475rem - 1px);
}
.accordion-item:last-of-type .accordion-collapse {
  border-bottom-right-radius: 0.475rem;
  border-bottom-left-radius: 0.475rem;
}

.accordion-body {
  padding: 1.5rem 1.5rem;
}

.accordion-flush .accordion-collapse {
  border-width: 0;
}
.accordion-flush .accordion-item {
  border-right: 0;
  border-left: 0;
  border-radius: 0;
}
.accordion-flush .accordion-item:first-child {
  border-top: 0;
}
.accordion-flush .accordion-item:last-child {
  border-bottom: 0;
}
.accordion-flush .accordion-item .accordion-button {
  border-radius: 0;
}

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0 0;
  margin-bottom: 1rem;
  list-style: none;
}

.breadcrumb-item + .breadcrumb-item {
  padding-left: 0.5rem;
}
.breadcrumb-item + .breadcrumb-item::before {
  float: left;
  padding-right: 0.5rem;
  color: #7E8299;
  content: var(--bs-breadcrumb-divider, "/") /* rtl: var(--bs-breadcrumb-divider, "/") */;
}
.breadcrumb-item.active {
  color: #009EF7;
}

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
}

.page-link {
  position: relative;
  display: block;
  color: #5E6278;
  background-color: transparent;
  border: 0 solid transparent;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .page-link {
    transition: none;
  }
}
.page-link:hover {
  z-index: 2;
  color: #009EF7;
  text-decoration: none;
  background-color: #ECF8FF;
  border-color: transparent;
}
.page-link:focus {
  z-index: 3;
  color: #009EF7;
  background-color: #ECF8FF;
  outline: 0;
  box-shadow: none;
}

.page-item:not(:first-child) .page-link {
  margin-left: 0;
}
.page-item.active .page-link {
  z-index: 3;
  color: #FFFFFF;
  background-color: #009EF7;
  border-color: transparent;
}
.page-item.disabled .page-link {
  color: #B5B5C3;
  pointer-events: none;
  background-color: transparent;
  border-color: transparent;
}

.page-link {
  padding: 0.375rem 0.75rem;
}

.page-item:first-child .page-link {
  border-top-left-radius: 0.475rem;
  border-bottom-left-radius: 0.475rem;
}
.page-item:last-child .page-link {
  border-top-right-radius: 0.475rem;
  border-bottom-right-radius: 0.475rem;
}

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.075rem;
}
.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.775rem;
  border-bottom-left-radius: 0.775rem;
}
.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.775rem;
  border-bottom-right-radius: 0.775rem;
}

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.925rem;
}
.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.275rem;
  border-bottom-left-radius: 0.275rem;
}
.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.275rem;
  border-bottom-right-radius: 0.275rem;
}

.badge {
  display: inline-block;
  padding: 0.5em 0.85em;
  font-size: 0.85em;
  font-weight: 500;
  line-height: 1;
  color: #ffffff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.475rem;
}
.badge:empty {
  display: none;
}

.btn .badge {
  position: relative;
  top: -1px;
}

.alert {
  position: relative;
  padding: 1rem 1rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.475rem;
}

.alert-heading {
  color: inherit;
}

.alert-link {
  font-weight: 500;
}

.alert-dismissible {
  padding-right: 3rem;
}
.alert-dismissible .btn-close {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  padding: 1.25rem 1rem;
}

.alert-white {
  color: #666666;
  background-color: white;
  border-color: white;
}
.alert-white .alert-link {
  color: #525252;
}

.alert-light {
  color: #626364;
  background-color: #fdfefe;
  border-color: #fcfdfe;
}
.alert-light .alert-link {
  color: #4e4f50;
}

.alert-primary {
  color: #005f94;
  background-color: #ccecfd;
  border-color: #b3e2fd;
}
.alert-primary .alert-link {
  color: #004c76;
}

.alert-secondary {
  color: #5b5c60;
  background-color: #fafafc;
  border-color: #f7f8fa;
}
.alert-secondary .alert-link {
  color: #494a4d;
}

.alert-success {
  color: #205237;
  background-color: #dcf5e7;
  border-color: #cbf0dc;
}
.alert-success .alert-link {
  color: #1a422c;
}

.alert-info {
  color: #44228c;
  background-color: #e3d7fb;
  border-color: #d5c4f9;
}
.alert-info .alert-link {
  color: #361b70;
}

.alert-warning {
  color: #665000;
  background-color: #fff4cc;
  border-color: #ffeeb3;
}
.alert-warning .alert-link {
  color: #524000;
}

.alert-danger {
  color: #912741;
  background-color: #fcd9e2;
  border-color: #fbc6d3;
}
.alert-danger .alert-link {
  color: #741f34;
}

.alert-dark {
  color: #0e111e;
  background-color: #d1d2d6;
  border-color: #babbc2;
}
.alert-dark .alert-link {
  color: #0b0e18;
}

@keyframes progress-bar-stripes {
  0% {
    background-position-x: 1rem;
  }
}
.progress {
  display: flex;
  height: 1rem;
  overflow: hidden;
  font-size: 0.75rem;
  background-color: #F5F8FA;
  border-radius: 6px;
  box-shadow: none;
}

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: #ffffff;
  text-align: center;
  white-space: nowrap;
  background-color: #009EF7;
  transition: width 0.6s ease;
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar {
    transition: none;
  }
}

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem;
}

.progress-bar-animated {
  animation: 1s linear infinite progress-bar-stripes;
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar-animated {
    animation: none;
  }
}

.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: 0.475rem;
}

.list-group-numbered {
  list-style-type: none;
  counter-reset: section;
}
.list-group-numbered > li::before {
  content: counters(section, ".") ". ";
  counter-increment: section;
}

.list-group-item-action {
  width: 100%;
  color: #5E6278;
  text-align: inherit;
}
.list-group-item-action:hover, .list-group-item-action:focus {
  z-index: 1;
  color: #5E6278;
  text-decoration: none;
  background-color: #F5F8FA;
}
.list-group-item-action:active {
  color: #181C32;
  background-color: #EFF2F5;
}

.list-group-item {
  position: relative;
  display: block;
  padding: 0.5rem 1rem;
  color: #181C32;
  background-color: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.125);
}
.list-group-item:first-child {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}
.list-group-item:last-child {
  border-bottom-right-radius: inherit;
  border-bottom-left-radius: inherit;
}
.list-group-item.disabled, .list-group-item:disabled {
  color: #7E8299;
  pointer-events: none;
  background-color: #ffffff;
}
.list-group-item.active {
  z-index: 2;
  color: #FFFFFF;
  background-color: #009EF7;
  border-color: #009EF7;
}
.list-group-item + .list-group-item {
  border-top-width: 0;
}
.list-group-item + .list-group-item.active {
  margin-top: -1px;
  border-top-width: 1px;
}

.list-group-horizontal {
  flex-direction: row;
}
.list-group-horizontal > .list-group-item:first-child {
  border-bottom-left-radius: 0.475rem;
  border-top-right-radius: 0;
}
.list-group-horizontal > .list-group-item:last-child {
  border-top-right-radius: 0.475rem;
  border-bottom-left-radius: 0;
}
.list-group-horizontal > .list-group-item.active {
  margin-top: 0;
}
.list-group-horizontal > .list-group-item + .list-group-item {
  border-top-width: 1px;
  border-left-width: 0;
}
.list-group-horizontal > .list-group-item + .list-group-item.active {
  margin-left: -1px;
  border-left-width: 1px;
}

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row;
  }
  .list-group-horizontal-sm > .list-group-item:first-child {
    border-bottom-left-radius: 0.475rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item:last-child {
    border-top-right-radius: 0.475rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row;
  }
  .list-group-horizontal-md > .list-group-item:first-child {
    border-bottom-left-radius: 0.475rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item:last-child {
    border-top-right-radius: 0.475rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row;
  }
  .list-group-horizontal-lg > .list-group-item:first-child {
    border-bottom-left-radius: 0.475rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item:last-child {
    border-top-right-radius: 0.475rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row;
  }
  .list-group-horizontal-xl > .list-group-item:first-child {
    border-bottom-left-radius: 0.475rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item:last-child {
    border-top-right-radius: 0.475rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 1400px) {
  .list-group-horizontal-xxl {
    flex-direction: row;
  }
  .list-group-horizontal-xxl > .list-group-item:first-child {
    border-bottom-left-radius: 0.475rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xxl > .list-group-item:last-child {
    border-top-right-radius: 0.475rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-xxl > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xxl > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-xxl > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
.list-group-flush {
  border-radius: 0;
}
.list-group-flush > .list-group-item {
  border-width: 0 0 1px;
}
.list-group-flush > .list-group-item:last-child {
  border-bottom-width: 0;
}

.list-group-item-white {
  color: #666666;
  background-color: white;
}
.list-group-item-white.list-group-item-action:hover, .list-group-item-white.list-group-item-action:focus {
  color: #666666;
  background-color: #e6e6e6;
}
.list-group-item-white.list-group-item-action.active {
  color: #ffffff;
  background-color: #666666;
  border-color: #666666;
}

.list-group-item-light {
  color: #626364;
  background-color: #fdfefe;
}
.list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
  color: #626364;
  background-color: #e4e5e5;
}
.list-group-item-light.list-group-item-action.active {
  color: #ffffff;
  background-color: #626364;
  border-color: #626364;
}

.list-group-item-primary {
  color: #005f94;
  background-color: #ccecfd;
}
.list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
  color: #005f94;
  background-color: #b8d4e4;
}
.list-group-item-primary.list-group-item-action.active {
  color: #ffffff;
  background-color: #005f94;
  border-color: #005f94;
}

.list-group-item-secondary {
  color: #5b5c60;
  background-color: #fafafc;
}
.list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
  color: #5b5c60;
  background-color: #e1e1e3;
}
.list-group-item-secondary.list-group-item-action.active {
  color: #ffffff;
  background-color: #5b5c60;
  border-color: #5b5c60;
}

.list-group-item-success {
  color: #205237;
  background-color: #dcf5e7;
}
.list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
  color: #205237;
  background-color: #c6ddd0;
}
.list-group-item-success.list-group-item-action.active {
  color: #ffffff;
  background-color: #205237;
  border-color: #205237;
}

.list-group-item-info {
  color: #44228c;
  background-color: #e3d7fb;
}
.list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
  color: #44228c;
  background-color: #ccc2e2;
}
.list-group-item-info.list-group-item-action.active {
  color: #ffffff;
  background-color: #44228c;
  border-color: #44228c;
}

.list-group-item-warning {
  color: #665000;
  background-color: #fff4cc;
}
.list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
  color: #665000;
  background-color: #e6dcb8;
}
.list-group-item-warning.list-group-item-action.active {
  color: #ffffff;
  background-color: #665000;
  border-color: #665000;
}

.list-group-item-danger {
  color: #912741;
  background-color: #fcd9e2;
}
.list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
  color: #912741;
  background-color: #e3c3cb;
}
.list-group-item-danger.list-group-item-action.active {
  color: #ffffff;
  background-color: #912741;
  border-color: #912741;
}

.list-group-item-dark {
  color: #0e111e;
  background-color: #d1d2d6;
}
.list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
  color: #0e111e;
  background-color: #bcbdc1;
}
.list-group-item-dark.list-group-item-action.active {
  color: #ffffff;
  background-color: #0e111e;
  border-color: #0e111e;
}

.btn-close {
  box-sizing: content-box;
  width: 1em;
  height: 1em;
  padding: 0.25em 0.25em;
  color: #000000;
  background: transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000000'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e") center/1em auto no-repeat;
  border: 0;
  border-radius: 0.475rem;
  opacity: 0.5;
}
.btn-close:hover {
  color: #000000;
  text-decoration: none;
  opacity: 0.75;
}
.btn-close:focus {
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(0, 158, 247, 0.25);
  opacity: 1;
}
.btn-close:disabled, .btn-close.disabled {
  pointer-events: none;
  user-select: none;
  opacity: 0.25;
}

.btn-close-white {
  filter: invert(1) grayscale(100%) brightness(200%);
}

.toast {
  width: 350px;
  max-width: 100%;
  font-size: 0.875rem;
  pointer-events: auto;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 0.5rem 1.5rem 0.5rem rgba(0, 0, 0, 0.075);
  border-radius: 0.475rem;
}
.toast:not(.showing):not(.show) {
  opacity: 0;
}
.toast.hide {
  display: none;
}

.toast-container {
  width: max-content;
  max-width: 100%;
  pointer-events: none;
}
.toast-container > :not(:last-child) {
  margin-bottom: 1.5rem;
}

.toast-header {
  display: flex;
  align-items: center;
  padding: 0.5rem 0.75rem;
  color: #7E8299;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  border-top-left-radius: calc(0.475rem - 1px);
  border-top-right-radius: calc(0.475rem - 1px);
}
.toast-header .btn-close {
  margin-right: -0.375rem;
  margin-left: 0.75rem;
}

.toast-body {
  padding: 0.75rem;
  word-wrap: break-word;
}

.modal-open {
  overflow: hidden;
}
.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1060;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none;
}
.modal.fade .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: translate(0, -50px);
}
@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}
.modal.show .modal-dialog {
  transform: none;
}
.modal.modal-static .modal-dialog {
  transform: scale(1.02);
}

.modal-dialog-scrollable {
  height: calc(100% - 1rem);
}
.modal-dialog-scrollable .modal-content {
  max-height: 100%;
  overflow: hidden;
}
.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem);
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #ffffff;
  background-clip: padding-box;
  border: 0 solid rgba(0, 0, 0, 0.2);
  border-radius: 0.475rem;
  box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.1);
  outline: 0;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  width: 100vw;
  height: 100vh;
  background-color: #000000;
}
.modal-backdrop.fade {
  opacity: 0;
}
.modal-backdrop.show {
  opacity: 0.2;
}

.modal-header {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: space-between;
  padding: 1.75rem 1.75rem;
  border-bottom: 1px solid #EFF2F5;
  border-top-left-radius: 0.475rem;
  border-top-right-radius: 0.475rem;
}
.modal-header .btn-close {
  padding: 0.875rem 0.875rem;
  margin: -0.875rem -0.875rem -0.875rem auto;
}

.modal-title {
  margin-bottom: 0;
  line-height: 1.5;
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1.75rem;
}

.modal-footer {
  display: flex;
  flex-wrap: wrap;
  flex-shrink: 0;
  align-items: center;
  justify-content: flex-end;
  padding: 1.5rem;
  border-top: 1px solid #EFF2F5;
  border-bottom-right-radius: 0.475rem;
  border-bottom-left-radius: 0.475rem;
}
.modal-footer > * {
  margin: 0.25rem;
}

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto;
  }

  .modal-dialog-scrollable {
    height: calc(100% - 3.5rem);
  }

  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem);
  }

  .modal-content {
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.1);
  }

  .modal-sm {
    max-width: 300px;
  }
}
@media (min-width: 992px) {
  .modal-lg,
.modal-xl {
    max-width: 800px;
  }
}
@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px;
  }
}
.modal-fullscreen {
  width: 100vw;
  max-width: none;
  height: 100%;
  margin: 0;
}
.modal-fullscreen .modal-content {
  height: 100%;
  border: 0;
  border-radius: 0;
}
.modal-fullscreen .modal-header {
  border-radius: 0;
}
.modal-fullscreen .modal-body {
  overflow-y: auto;
}
.modal-fullscreen .modal-footer {
  border-radius: 0;
}

@media (max-width: 575.98px) {
  .modal-fullscreen-sm-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-sm-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-sm-down .modal-header {
    border-radius: 0;
  }
  .modal-fullscreen-sm-down .modal-body {
    overflow-y: auto;
  }
  .modal-fullscreen-sm-down .modal-footer {
    border-radius: 0;
  }
}
@media (max-width: 767.98px) {
  .modal-fullscreen-md-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-md-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-md-down .modal-header {
    border-radius: 0;
  }
  .modal-fullscreen-md-down .modal-body {
    overflow-y: auto;
  }
  .modal-fullscreen-md-down .modal-footer {
    border-radius: 0;
  }
}
@media (max-width: 991.98px) {
  .modal-fullscreen-lg-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-lg-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-lg-down .modal-header {
    border-radius: 0;
  }
  .modal-fullscreen-lg-down .modal-body {
    overflow-y: auto;
  }
  .modal-fullscreen-lg-down .modal-footer {
    border-radius: 0;
  }
}
@media (max-width: 1199.98px) {
  .modal-fullscreen-xl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-xl-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-xl-down .modal-header {
    border-radius: 0;
  }
  .modal-fullscreen-xl-down .modal-body {
    overflow-y: auto;
  }
  .modal-fullscreen-xl-down .modal-footer {
    border-radius: 0;
  }
}
@media (max-width: 1399.98px) {
  .modal-fullscreen-xxl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-xxl-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-xxl-down .modal-header {
    border-radius: 0;
  }
  .modal-fullscreen-xxl-down .modal-body {
    overflow-y: auto;
  }
  .modal-fullscreen-xxl-down .modal-footer {
    border-radius: 0;
  }
}
.tooltip {
  position: absolute;
  z-index: 1080;
  display: block;
  margin: 0;
  font-family: var(--bs-font-sans-serif);
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.925rem;
  word-wrap: break-word;
  opacity: 0;
}
.tooltip.show {
  opacity: 1;
}
.tooltip .tooltip-arrow {
  position: absolute;
  display: block;
  width: 0.8rem;
  height: 0.4rem;
}
.tooltip .tooltip-arrow::before {
  position: absolute;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-tooltip-top, .bs-tooltip-auto[data-popper-placement^=top] {
  padding: 0.4rem 0;
}
.bs-tooltip-top .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=top] .tooltip-arrow {
  bottom: 0;
}
.bs-tooltip-top .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=top] .tooltip-arrow::before {
  top: -1px;
  border-width: 0.4rem 0.4rem 0;
  border-top-color: #ffffff;
}

.bs-tooltip-end, .bs-tooltip-auto[data-popper-placement^=right] {
  padding: 0 0.4rem;
}
.bs-tooltip-end .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow {
  left: 0;
  width: 0.4rem;
  height: 0.8rem;
}
.bs-tooltip-end .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow::before {
  right: -1px;
  border-width: 0.4rem 0.4rem 0.4rem 0;
  border-right-color: #ffffff;
}

.bs-tooltip-bottom, .bs-tooltip-auto[data-popper-placement^=bottom] {
  padding: 0.4rem 0;
}
.bs-tooltip-bottom .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=bottom] .tooltip-arrow {
  top: 0;
}
.bs-tooltip-bottom .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=bottom] .tooltip-arrow::before {
  bottom: -1px;
  border-width: 0 0.4rem 0.4rem;
  border-bottom-color: #ffffff;
}

.bs-tooltip-start, .bs-tooltip-auto[data-popper-placement^=left] {
  padding: 0 0.4rem;
}
.bs-tooltip-start .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow {
  right: 0;
  width: 0.4rem;
  height: 0.8rem;
}
.bs-tooltip-start .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow::before {
  left: -1px;
  border-width: 0.4rem 0 0.4rem 0.4rem;
  border-left-color: #ffffff;
}

.tooltip-inner {
  max-width: 200px;
  padding: 0.75rem 1rem;
  color: #3F4254;
  text-align: center;
  background-color: #ffffff;
  border-radius: 3px;
}

.popover {
  position: absolute;
  top: 0;
  left: 0 /* rtl:ignore */;
  z-index: 1070;
  display: block;
  max-width: 276px;
  font-family: var(--bs-font-sans-serif);
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.925rem;
  word-wrap: break-word;
  background-color: #ffffff;
  background-clip: padding-box;
  border: 1px solid #ffffff;
  border-radius: 0.475rem;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.15);
}
.popover .popover-arrow {
  position: absolute;
  display: block;
  width: 1rem;
  height: 0.5rem;
}
.popover .popover-arrow::before, .popover .popover-arrow::after {
  position: absolute;
  display: block;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-popover-top > .popover-arrow, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow {
  bottom: calc(-0.5rem - 1px);
}
.bs-popover-top > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow::before {
  bottom: 0;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: white;
}
.bs-popover-top > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow::after {
  bottom: 1px;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: #ffffff;
}

.bs-popover-end > .popover-arrow, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow {
  left: calc(-0.5rem - 1px);
  width: 0.5rem;
  height: 1rem;
}
.bs-popover-end > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow::before {
  left: 0;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: white;
}
.bs-popover-end > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow::after {
  left: 1px;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: #ffffff;
}

.bs-popover-bottom > .popover-arrow, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow {
  top: calc(-0.5rem - 1px);
}
.bs-popover-bottom > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::before {
  top: 0;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: white;
}
.bs-popover-bottom > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::after {
  top: 1px;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: #ffffff;
}
.bs-popover-bottom .popover-header::before, .bs-popover-auto[data-popper-placement^=bottom] .popover-header::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: 1rem;
  margin-left: -0.5rem;
  content: "";
  border-bottom: 1px solid #ffffff;
}

.bs-popover-start > .popover-arrow, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow {
  right: calc(-0.5rem - 1px);
  width: 0.5rem;
  height: 1rem;
}
.bs-popover-start > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow::before {
  right: 0;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: white;
}
.bs-popover-start > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow::after {
  right: 1px;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: #ffffff;
}

.popover-header {
  padding: 1rem 1.25rem;
  margin-bottom: 0;
  font-size: 1rem;
  color: #181C32;
  background-color: #ffffff;
  border-bottom: 1px solid #e6e6e6;
  border-top-left-radius: calc(0.475rem - 1px);
  border-top-right-radius: calc(0.475rem - 1px);
}
.popover-header:empty {
  display: none;
}

.popover-body {
  padding: 1rem 1.25rem;
  color: #181C32;
}

.carousel {
  position: relative;
}

.carousel.pointer-event {
  touch-action: pan-y;
}

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
}
.carousel-inner::after {
  display: block;
  clear: both;
  content: "";
}

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  backface-visibility: hidden;
  transition: transform 0.6s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-item {
    transition: none;
  }
}

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block;
}

/* rtl:begin:ignore */
.carousel-item-next:not(.carousel-item-start),
.active.carousel-item-end {
  transform: translateX(100%);
}

.carousel-item-prev:not(.carousel-item-end),
.active.carousel-item-start {
  transform: translateX(-100%);
}

/* rtl:end:ignore */
.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none;
}
.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-start,
.carousel-fade .carousel-item-prev.carousel-item-end {
  z-index: 1;
  opacity: 1;
}
.carousel-fade .active.carousel-item-start,
.carousel-fade .active.carousel-item-end {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s 0.6s;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-fade .active.carousel-item-start,
.carousel-fade .active.carousel-item-end {
    transition: none;
  }
}

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  padding: 0;
  color: #ffffff;
  text-align: center;
  background: none;
  border: 0;
  opacity: 0.5;
  transition: opacity 0.15s ease;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-control-prev,
.carousel-control-next {
    transition: none;
  }
}
.carousel-control-prev:hover, .carousel-control-prev:focus,
.carousel-control-next:hover,
.carousel-control-next:focus {
  color: #ffffff;
  text-decoration: none;
  outline: 0;
  opacity: 0.9;
}

.carousel-control-prev {
  left: 0;
}

.carousel-control-next {
  right: 0;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: 100% 100%;
}

/* rtl:options: {
  "autoRename": true,
  "stringMap":[ {
    "name"    : "prev-next",
    "search"  : "prev",
    "replace" : "next"
  } ]
} */
.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23ffffff'%3e%3cpath d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/%3e%3c/svg%3e");
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23ffffff'%3e%3cpath d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  display: flex;
  justify-content: center;
  padding: 0;
  margin-right: 15%;
  margin-bottom: 1rem;
  margin-left: 15%;
  list-style: none;
}
.carousel-indicators [data-bs-target] {
  box-sizing: content-box;
  flex: 0 1 auto;
  width: 30px;
  height: 3px;
  padding: 0;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #ffffff;
  background-clip: padding-box;
  border: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: 0.5;
  transition: opacity 0.6s ease;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-indicators [data-bs-target] {
    transition: none;
  }
}
.carousel-indicators .active {
  opacity: 1;
}

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 1.25rem;
  left: 15%;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  color: #ffffff;
  text-align: center;
}

.carousel-dark .carousel-control-prev-icon,
.carousel-dark .carousel-control-next-icon {
  filter: invert(1) grayscale(100);
}
.carousel-dark .carousel-indicators [data-bs-target] {
  background-color: #000000;
}
.carousel-dark .carousel-caption {
  color: #000000;
}

@keyframes spinner-border {
  to {
    transform: rotate(360deg) /* rtl:ignore */;
  }
}
.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  border: 0.185em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  animation: 0.65s linear infinite spinner-border;
}

.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: 0.145em;
}

@keyframes spinner-grow {
  0% {
    transform: scale(0);
  }
  50% {
    opacity: 1;
    transform: none;
  }
}
.spinner-grow {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  background-color: currentColor;
  border-radius: 50%;
  opacity: 0;
  animation: 0.65s linear infinite spinner-grow;
}

.spinner-grow-sm {
  width: 1rem;
  height: 1rem;
}

@media (prefers-reduced-motion: reduce) {
  .spinner-border,
.spinner-grow {
    animation-duration: 1.3s;
  }
}
.clearfix::after {
  display: block;
  clear: both;
  content: "";
}

.link-white {
  color: #ffffff;
}
.link-white:hover, .link-white:focus {
  color: white;
}

.link-light {
  color: #F5F8FA;
}
.link-light:hover, .link-light:focus {
  color: #f7f9fb;
}

.link-primary {
  color: #009EF7;
}
.link-primary:hover, .link-primary:focus {
  color: #33b1f9;
}

.link-secondary {
  color: #E4E6EF;
}
.link-secondary:hover, .link-secondary:focus {
  color: #e9ebf2;
}

.link-success {
  color: #50CD89;
}
.link-success:hover, .link-success:focus {
  color: #73d7a1;
}

.link-info {
  color: #7239EA;
}
.link-info:hover, .link-info:focus {
  color: #5b2ebb;
}

.link-warning {
  color: #FFC700;
}
.link-warning:hover, .link-warning:focus {
  color: #ffd233;
}

.link-danger {
  color: #F1416C;
}
.link-danger:hover, .link-danger:focus {
  color: #f46789;
}

.link-dark {
  color: #181C32;
}
.link-dark:hover, .link-dark:focus {
  color: #131628;
}

.ratio {
  position: relative;
  width: 100%;
}
.ratio::before {
  display: block;
  padding-top: var(--bs-aspect-ratio);
  content: "";
}
.ratio > * {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.ratio-1x1 {
  --bs-aspect-ratio: 100%;
}

.ratio-4x3 {
  --bs-aspect-ratio: calc(3 / 4 * 100%);
}

.ratio-16x9 {
  --bs-aspect-ratio: calc(9 / 16 * 100%);
}

.ratio-21x9 {
  --bs-aspect-ratio: calc(9 / 21 * 100%);
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

.sticky-top {
  position: sticky;
  top: 0;
  z-index: 1020;
}

@media (min-width: 576px) {
  .sticky-sm-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}
@media (min-width: 768px) {
  .sticky-md-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}
@media (min-width: 992px) {
  .sticky-lg-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}
@media (min-width: 1200px) {
  .sticky-xl-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}
@media (min-width: 1400px) {
  .sticky-xxl-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}
.visually-hidden,
.visually-hidden-focusable:not(:focus):not(:focus-within) {
  position: absolute !important;
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  margin: -1px !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important;
}

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  content: "";
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.float-start {
  float: left !important;
}

.float-end {
  float: right !important;
}

.float-none {
  float: none !important;
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.overflow-visible {
  overflow: visible !important;
}

.overflow-scroll {
  overflow: scroll !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-grid {
  display: grid !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

.d-none {
  display: none !important;
}

.shadow {
  box-shadow: 0 0.5rem 1.5rem 0.5rem rgba(0, 0, 0, 0.075) !important;
}

.shadow-sm {
  box-shadow: 0 0.1rem 1rem 0.25rem rgba(0, 0, 0, 0.05) !important;
}

.shadow-lg {
  box-shadow: 0 1rem 2rem 1rem rgba(0, 0, 0, 0.1) !important;
}

.shadow-none {
  box-shadow: none !important;
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: sticky !important;
}

.top-0 {
  top: 0 !important;
}

.top-25 {
  top: 25% !important;
}

.top-50 {
  top: 50% !important;
}

.top-75 {
  top: 75% !important;
}

.top-100 {
  top: 100% !important;
}

.bottom-0 {
  bottom: 0 !important;
}

.bottom-25 {
  bottom: 25% !important;
}

.bottom-50 {
  bottom: 50% !important;
}

.bottom-75 {
  bottom: 75% !important;
}

.bottom-100 {
  bottom: 100% !important;
}

.start-0 {
  left: 0 !important;
}

.start-25 {
  left: 25% !important;
}

.start-50 {
  left: 50% !important;
}

.start-75 {
  left: 75% !important;
}

.start-100 {
  left: 100% !important;
}

.end-0 {
  right: 0 !important;
}

.end-25 {
  right: 25% !important;
}

.end-50 {
  right: 50% !important;
}

.end-75 {
  right: 75% !important;
}

.end-100 {
  right: 100% !important;
}

.translate-middle {
  transform: translate(-50%, -50%) !important;
}

.translate-middle-x {
  transform: translateX(-50%) !important;
}

.translate-middle-y {
  transform: translateY(-50%) !important;
}

.border {
  border: 1px solid #EFF2F5 !important;
}

.border-0 {
  border: 0 !important;
}

.border-top {
  border-top: 1px solid #EFF2F5 !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-end {
  border-right: 1px solid #EFF2F5 !important;
}

.border-end-0 {
  border-right: 0 !important;
}

.border-bottom {
  border-bottom: 1px solid #EFF2F5 !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-start {
  border-left: 1px solid #EFF2F5 !important;
}

.border-start-0 {
  border-left: 0 !important;
}

.border-white {
  border-color: #ffffff !important;
}

.border-light {
  border-color: #F5F8FA !important;
}

.border-primary {
  border-color: #009EF7 !important;
}

.border-secondary {
  border-color: #E4E6EF !important;
}

.border-success {
  border-color: #50CD89 !important;
}

.border-info {
  border-color: #7239EA !important;
}

.border-warning {
  border-color: #FFC700 !important;
}

.border-danger {
  border-color: #F1416C !important;
}

.border-dark {
  border-color: #181C32 !important;
}

.border-0 {
  border-width: 0 !important;
}

.border-1 {
  border-width: 1px !important;
}

.border-2 {
  border-width: 2px !important;
}

.border-3 {
  border-width: 3px !important;
}

.border-4 {
  border-width: 4px !important;
}

.border-5 {
  border-width: 5px !important;
}

.w-unset {
  width: unset !important;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.w-1px {
  width: 1px !important;
}

.w-2px {
  width: 2px !important;
}

.w-3px {
  width: 3px !important;
}

.w-4px {
  width: 4px !important;
}

.w-5px {
  width: 5px !important;
}

.w-6px {
  width: 6px !important;
}

.w-7px {
  width: 7px !important;
}

.w-8px {
  width: 8px !important;
}

.w-9px {
  width: 9px !important;
}

.w-10px {
  width: 10px !important;
}

.w-15px {
  width: 15px !important;
}

.w-20px {
  width: 20px !important;
}

.w-25px {
  width: 25px !important;
}

.w-30px {
  width: 30px !important;
}

.w-35px {
  width: 35px !important;
}

.w-40px {
  width: 40px !important;
}

.w-45px {
  width: 45px !important;
}

.w-50px {
  width: 50px !important;
}

.w-55px {
  width: 55px !important;
}

.w-60px {
  width: 60px !important;
}

.w-65px {
  width: 65px !important;
}

.w-70px {
  width: 70px !important;
}

.w-75px {
  width: 75px !important;
}

.w-80px {
  width: 80px !important;
}

.w-85px {
  width: 85px !important;
}

.w-90px {
  width: 90px !important;
}

.w-95px {
  width: 95px !important;
}

.w-100px {
  width: 100px !important;
}

.w-125px {
  width: 125px !important;
}

.w-150px {
  width: 150px !important;
}

.w-175px {
  width: 175px !important;
}

.w-200px {
  width: 200px !important;
}

.w-225px {
  width: 225px !important;
}

.w-250px {
  width: 250px !important;
}

.w-275px {
  width: 275px !important;
}

.w-300px {
  width: 300px !important;
}

.w-325px {
  width: 325px !important;
}

.w-350px {
  width: 350px !important;
}

.w-375px {
  width: 375px !important;
}

.w-400px {
  width: 400px !important;
}

.w-425px {
  width: 425px !important;
}

.w-450px {
  width: 450px !important;
}

.w-475px {
  width: 475px !important;
}

.w-500px {
  width: 500px !important;
}

.w-550px {
  width: 550px !important;
}

.w-600px {
  width: 600px !important;
}

.w-650px {
  width: 650px !important;
}

.w-700px {
  width: 700px !important;
}

.w-750px {
  width: 750px !important;
}

.w-800px {
  width: 800px !important;
}

.w-850px {
  width: 850px !important;
}

.w-900px {
  width: 900px !important;
}

.w-950px {
  width: 950px !important;
}

.w-1000px {
  width: 1000px !important;
}

.mw-unset {
  max-width: unset !important;
}

.mw-25 {
  max-width: 25% !important;
}

.mw-50 {
  max-width: 50% !important;
}

.mw-75 {
  max-width: 75% !important;
}

.mw-100 {
  max-width: 100% !important;
}

.mw-auto {
  max-width: auto !important;
}

.mw-1px {
  max-width: 1px !important;
}

.mw-2px {
  max-width: 2px !important;
}

.mw-3px {
  max-width: 3px !important;
}

.mw-4px {
  max-width: 4px !important;
}

.mw-5px {
  max-width: 5px !important;
}

.mw-6px {
  max-width: 6px !important;
}

.mw-7px {
  max-width: 7px !important;
}

.mw-8px {
  max-width: 8px !important;
}

.mw-9px {
  max-width: 9px !important;
}

.mw-10px {
  max-width: 10px !important;
}

.mw-15px {
  max-width: 15px !important;
}

.mw-20px {
  max-width: 20px !important;
}

.mw-25px {
  max-width: 25px !important;
}

.mw-30px {
  max-width: 30px !important;
}

.mw-35px {
  max-width: 35px !important;
}

.mw-40px {
  max-width: 40px !important;
}

.mw-45px {
  max-width: 45px !important;
}

.mw-50px {
  max-width: 50px !important;
}

.mw-55px {
  max-width: 55px !important;
}

.mw-60px {
  max-width: 60px !important;
}

.mw-65px {
  max-width: 65px !important;
}

.mw-70px {
  max-width: 70px !important;
}

.mw-75px {
  max-width: 75px !important;
}

.mw-80px {
  max-width: 80px !important;
}

.mw-85px {
  max-width: 85px !important;
}

.mw-90px {
  max-width: 90px !important;
}

.mw-95px {
  max-width: 95px !important;
}

.mw-100px {
  max-width: 100px !important;
}

.mw-125px {
  max-width: 125px !important;
}

.mw-150px {
  max-width: 150px !important;
}

.mw-175px {
  max-width: 175px !important;
}

.mw-200px {
  max-width: 200px !important;
}

.mw-225px {
  max-width: 225px !important;
}

.mw-250px {
  max-width: 250px !important;
}

.mw-275px {
  max-width: 275px !important;
}

.mw-300px {
  max-width: 300px !important;
}

.mw-325px {
  max-width: 325px !important;
}

.mw-350px {
  max-width: 350px !important;
}

.mw-375px {
  max-width: 375px !important;
}

.mw-400px {
  max-width: 400px !important;
}

.mw-425px {
  max-width: 425px !important;
}

.mw-450px {
  max-width: 450px !important;
}

.mw-475px {
  max-width: 475px !important;
}

.mw-500px {
  max-width: 500px !important;
}

.mw-550px {
  max-width: 550px !important;
}

.mw-600px {
  max-width: 600px !important;
}

.mw-650px {
  max-width: 650px !important;
}

.mw-700px {
  max-width: 700px !important;
}

.mw-750px {
  max-width: 750px !important;
}

.mw-800px {
  max-width: 800px !important;
}

.mw-850px {
  max-width: 850px !important;
}

.mw-900px {
  max-width: 900px !important;
}

.mw-950px {
  max-width: 950px !important;
}

.mw-1000px {
  max-width: 1000px !important;
}

.vw-100 {
  width: 100vw !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.h-unset {
  height: unset !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.h-1px {
  height: 1px !important;
}

.h-2px {
  height: 2px !important;
}

.h-3px {
  height: 3px !important;
}

.h-4px {
  height: 4px !important;
}

.h-5px {
  height: 5px !important;
}

.h-6px {
  height: 6px !important;
}

.h-7px {
  height: 7px !important;
}

.h-8px {
  height: 8px !important;
}

.h-9px {
  height: 9px !important;
}

.h-10px {
  height: 10px !important;
}

.h-15px {
  height: 15px !important;
}

.h-20px {
  height: 20px !important;
}

.h-25px {
  height: 25px !important;
}

.h-30px {
  height: 30px !important;
}

.h-35px {
  height: 35px !important;
}

.h-40px {
  height: 40px !important;
}

.h-45px {
  height: 45px !important;
}

.h-50px {
  height: 50px !important;
}

.h-55px {
  height: 55px !important;
}

.h-60px {
  height: 60px !important;
}

.h-65px {
  height: 65px !important;
}

.h-70px {
  height: 70px !important;
}

.h-75px {
  height: 75px !important;
}

.h-80px {
  height: 80px !important;
}

.h-85px {
  height: 85px !important;
}

.h-90px {
  height: 90px !important;
}

.h-95px {
  height: 95px !important;
}

.h-100px {
  height: 100px !important;
}

.h-125px {
  height: 125px !important;
}

.h-150px {
  height: 150px !important;
}

.h-175px {
  height: 175px !important;
}

.h-200px {
  height: 200px !important;
}

.h-225px {
  height: 225px !important;
}

.h-250px {
  height: 250px !important;
}

.h-275px {
  height: 275px !important;
}

.h-300px {
  height: 300px !important;
}

.h-325px {
  height: 325px !important;
}

.h-350px {
  height: 350px !important;
}

.h-375px {
  height: 375px !important;
}

.h-400px {
  height: 400px !important;
}

.h-425px {
  height: 425px !important;
}

.h-450px {
  height: 450px !important;
}

.h-475px {
  height: 475px !important;
}

.h-500px {
  height: 500px !important;
}

.h-550px {
  height: 550px !important;
}

.h-600px {
  height: 600px !important;
}

.h-650px {
  height: 650px !important;
}

.h-700px {
  height: 700px !important;
}

.h-750px {
  height: 750px !important;
}

.h-800px {
  height: 800px !important;
}

.h-850px {
  height: 850px !important;
}

.h-900px {
  height: 900px !important;
}

.h-950px {
  height: 950px !important;
}

.h-1000px {
  height: 1000px !important;
}

.mh-unset {
  max-height: unset !important;
}

.mh-25 {
  max-height: 25% !important;
}

.mh-50 {
  max-height: 50% !important;
}

.mh-75 {
  max-height: 75% !important;
}

.mh-100 {
  max-height: 100% !important;
}

.mh-auto {
  max-height: auto !important;
}

.mh-1px {
  max-height: 1px !important;
}

.mh-2px {
  max-height: 2px !important;
}

.mh-3px {
  max-height: 3px !important;
}

.mh-4px {
  max-height: 4px !important;
}

.mh-5px {
  max-height: 5px !important;
}

.mh-6px {
  max-height: 6px !important;
}

.mh-7px {
  max-height: 7px !important;
}

.mh-8px {
  max-height: 8px !important;
}

.mh-9px {
  max-height: 9px !important;
}

.mh-10px {
  max-height: 10px !important;
}

.mh-15px {
  max-height: 15px !important;
}

.mh-20px {
  max-height: 20px !important;
}

.mh-25px {
  max-height: 25px !important;
}

.mh-30px {
  max-height: 30px !important;
}

.mh-35px {
  max-height: 35px !important;
}

.mh-40px {
  max-height: 40px !important;
}

.mh-45px {
  max-height: 45px !important;
}

.mh-50px {
  max-height: 50px !important;
}

.mh-55px {
  max-height: 55px !important;
}

.mh-60px {
  max-height: 60px !important;
}

.mh-65px {
  max-height: 65px !important;
}

.mh-70px {
  max-height: 70px !important;
}

.mh-75px {
  max-height: 75px !important;
}

.mh-80px {
  max-height: 80px !important;
}

.mh-85px {
  max-height: 85px !important;
}

.mh-90px {
  max-height: 90px !important;
}

.mh-95px {
  max-height: 95px !important;
}

.mh-100px {
  max-height: 100px !important;
}

.mh-125px {
  max-height: 125px !important;
}

.mh-150px {
  max-height: 150px !important;
}

.mh-175px {
  max-height: 175px !important;
}

.mh-200px {
  max-height: 200px !important;
}

.mh-225px {
  max-height: 225px !important;
}

.mh-250px {
  max-height: 250px !important;
}

.mh-275px {
  max-height: 275px !important;
}

.mh-300px {
  max-height: 300px !important;
}

.mh-325px {
  max-height: 325px !important;
}

.mh-350px {
  max-height: 350px !important;
}

.mh-375px {
  max-height: 375px !important;
}

.mh-400px {
  max-height: 400px !important;
}

.mh-425px {
  max-height: 425px !important;
}

.mh-450px {
  max-height: 450px !important;
}

.mh-475px {
  max-height: 475px !important;
}

.mh-500px {
  max-height: 500px !important;
}

.mh-550px {
  max-height: 550px !important;
}

.mh-600px {
  max-height: 600px !important;
}

.mh-650px {
  max-height: 650px !important;
}

.mh-700px {
  max-height: 700px !important;
}

.mh-750px {
  max-height: 750px !important;
}

.mh-800px {
  max-height: 800px !important;
}

.mh-850px {
  max-height: 850px !important;
}

.mh-900px {
  max-height: 900px !important;
}

.mh-950px {
  max-height: 950px !important;
}

.mh-1000px {
  max-height: 1000px !important;
}

.vh-100 {
  height: 100vh !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.gap-0 {
  gap: 0 !important;
}

.gap-1 {
  gap: 0.25rem !important;
}

.gap-2 {
  gap: 0.5rem !important;
}

.gap-3 {
  gap: 0.75rem !important;
}

.gap-4 {
  gap: 1rem !important;
}

.gap-5 {
  gap: 1.25rem !important;
}

.gap-6 {
  gap: 1.5rem !important;
}

.gap-7 {
  gap: 1.75rem !important;
}

.gap-8 {
  gap: 2rem !important;
}

.gap-9 {
  gap: 2.25rem !important;
}

.gap-10 {
  gap: 2.5rem !important;
}

.gap-11 {
  gap: 2.75rem !important;
}

.gap-12 {
  gap: 3rem !important;
}

.gap-13 {
  gap: 3.25rem !important;
}

.gap-14 {
  gap: 3.5rem !important;
}

.gap-15 {
  gap: 3.75rem !important;
}

.gap-16 {
  gap: 4rem !important;
}

.gap-17 {
  gap: 4.25rem !important;
}

.gap-18 {
  gap: 4.5rem !important;
}

.gap-19 {
  gap: 4.75rem !important;
}

.gap-20 {
  gap: 5rem !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.justify-content-evenly {
  justify-content: space-evenly !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

.order-first {
  order: -1 !important;
}

.order-0 {
  order: 0 !important;
}

.order-1 {
  order: 1 !important;
}

.order-2 {
  order: 2 !important;
}

.order-3 {
  order: 3 !important;
}

.order-4 {
  order: 4 !important;
}

.order-5 {
  order: 5 !important;
}

.order-last {
  order: 6 !important;
}

.m-0 {
  margin: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.m-3 {
  margin: 0.75rem !important;
}

.m-4 {
  margin: 1rem !important;
}

.m-5 {
  margin: 1.25rem !important;
}

.m-6 {
  margin: 1.5rem !important;
}

.m-7 {
  margin: 1.75rem !important;
}

.m-8 {
  margin: 2rem !important;
}

.m-9 {
  margin: 2.25rem !important;
}

.m-10 {
  margin: 2.5rem !important;
}

.m-11 {
  margin: 2.75rem !important;
}

.m-12 {
  margin: 3rem !important;
}

.m-13 {
  margin: 3.25rem !important;
}

.m-14 {
  margin: 3.5rem !important;
}

.m-15 {
  margin: 3.75rem !important;
}

.m-16 {
  margin: 4rem !important;
}

.m-17 {
  margin: 4.25rem !important;
}

.m-18 {
  margin: 4.5rem !important;
}

.m-19 {
  margin: 4.75rem !important;
}

.m-20 {
  margin: 5rem !important;
}

.m-auto {
  margin: auto !important;
}

.mx-0 {
  margin-right: 0 !important;
  margin-left: 0 !important;
}

.mx-1 {
  margin-right: 0.25rem !important;
  margin-left: 0.25rem !important;
}

.mx-2 {
  margin-right: 0.5rem !important;
  margin-left: 0.5rem !important;
}

.mx-3 {
  margin-right: 0.75rem !important;
  margin-left: 0.75rem !important;
}

.mx-4 {
  margin-right: 1rem !important;
  margin-left: 1rem !important;
}

.mx-5 {
  margin-right: 1.25rem !important;
  margin-left: 1.25rem !important;
}

.mx-6 {
  margin-right: 1.5rem !important;
  margin-left: 1.5rem !important;
}

.mx-7 {
  margin-right: 1.75rem !important;
  margin-left: 1.75rem !important;
}

.mx-8 {
  margin-right: 2rem !important;
  margin-left: 2rem !important;
}

.mx-9 {
  margin-right: 2.25rem !important;
  margin-left: 2.25rem !important;
}

.mx-10 {
  margin-right: 2.5rem !important;
  margin-left: 2.5rem !important;
}

.mx-11 {
  margin-right: 2.75rem !important;
  margin-left: 2.75rem !important;
}

.mx-12 {
  margin-right: 3rem !important;
  margin-left: 3rem !important;
}

.mx-13 {
  margin-right: 3.25rem !important;
  margin-left: 3.25rem !important;
}

.mx-14 {
  margin-right: 3.5rem !important;
  margin-left: 3.5rem !important;
}

.mx-15 {
  margin-right: 3.75rem !important;
  margin-left: 3.75rem !important;
}

.mx-16 {
  margin-right: 4rem !important;
  margin-left: 4rem !important;
}

.mx-17 {
  margin-right: 4.25rem !important;
  margin-left: 4.25rem !important;
}

.mx-18 {
  margin-right: 4.5rem !important;
  margin-left: 4.5rem !important;
}

.mx-19 {
  margin-right: 4.75rem !important;
  margin-left: 4.75rem !important;
}

.mx-20 {
  margin-right: 5rem !important;
  margin-left: 5rem !important;
}

.mx-auto {
  margin-right: auto !important;
  margin-left: auto !important;
}

.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.my-1 {
  margin-top: 0.25rem !important;
  margin-bottom: 0.25rem !important;
}

.my-2 {
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important;
}

.my-3 {
  margin-top: 0.75rem !important;
  margin-bottom: 0.75rem !important;
}

.my-4 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}

.my-5 {
  margin-top: 1.25rem !important;
  margin-bottom: 1.25rem !important;
}

.my-6 {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important;
}

.my-7 {
  margin-top: 1.75rem !important;
  margin-bottom: 1.75rem !important;
}

.my-8 {
  margin-top: 2rem !important;
  margin-bottom: 2rem !important;
}

.my-9 {
  margin-top: 2.25rem !important;
  margin-bottom: 2.25rem !important;
}

.my-10 {
  margin-top: 2.5rem !important;
  margin-bottom: 2.5rem !important;
}

.my-11 {
  margin-top: 2.75rem !important;
  margin-bottom: 2.75rem !important;
}

.my-12 {
  margin-top: 3rem !important;
  margin-bottom: 3rem !important;
}

.my-13 {
  margin-top: 3.25rem !important;
  margin-bottom: 3.25rem !important;
}

.my-14 {
  margin-top: 3.5rem !important;
  margin-bottom: 3.5rem !important;
}

.my-15 {
  margin-top: 3.75rem !important;
  margin-bottom: 3.75rem !important;
}

.my-16 {
  margin-top: 4rem !important;
  margin-bottom: 4rem !important;
}

.my-17 {
  margin-top: 4.25rem !important;
  margin-bottom: 4.25rem !important;
}

.my-18 {
  margin-top: 4.5rem !important;
  margin-bottom: 4.5rem !important;
}

.my-19 {
  margin-top: 4.75rem !important;
  margin-bottom: 4.75rem !important;
}

.my-20 {
  margin-top: 5rem !important;
  margin-bottom: 5rem !important;
}

.my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.mt-1 {
  margin-top: 0.25rem !important;
}

.mt-2 {
  margin-top: 0.5rem !important;
}

.mt-3 {
  margin-top: 0.75rem !important;
}

.mt-4 {
  margin-top: 1rem !important;
}

.mt-5 {
  margin-top: 1.25rem !important;
}

.mt-6 {
  margin-top: 1.5rem !important;
}

.mt-7 {
  margin-top: 1.75rem !important;
}

.mt-8 {
  margin-top: 2rem !important;
}

.mt-9 {
  margin-top: 2.25rem !important;
}

.mt-10 {
  margin-top: 2.5rem !important;
}

.mt-11 {
  margin-top: 2.75rem !important;
}

.mt-12 {
  margin-top: 3rem !important;
}

.mt-13 {
  margin-top: 3.25rem !important;
}

.mt-14 {
  margin-top: 3.5rem !important;
}

.mt-15 {
  margin-top: 3.75rem !important;
}

.mt-16 {
  margin-top: 4rem !important;
}

.mt-17 {
  margin-top: 4.25rem !important;
}

.mt-18 {
  margin-top: 4.5rem !important;
}

.mt-19 {
  margin-top: 4.75rem !important;
}

.mt-20 {
  margin-top: 5rem !important;
}

.mt-auto {
  margin-top: auto !important;
}

.me-0 {
  margin-right: 0 !important;
}

.me-1 {
  margin-right: 0.25rem !important;
}

.me-2 {
  margin-right: 0.5rem !important;
}

.me-3 {
  margin-right: 0.75rem !important;
}

.me-4 {
  margin-right: 1rem !important;
}

.me-5 {
  margin-right: 1.25rem !important;
}

.me-6 {
  margin-right: 1.5rem !important;
}

.me-7 {
  margin-right: 1.75rem !important;
}

.me-8 {
  margin-right: 2rem !important;
}

.me-9 {
  margin-right: 2.25rem !important;
}

.me-10 {
  margin-right: 2.5rem !important;
}

.me-11 {
  margin-right: 2.75rem !important;
}

.me-12 {
  margin-right: 3rem !important;
}

.me-13 {
  margin-right: 3.25rem !important;
}

.me-14 {
  margin-right: 3.5rem !important;
}

.me-15 {
  margin-right: 3.75rem !important;
}

.me-16 {
  margin-right: 4rem !important;
}

.me-17 {
  margin-right: 4.25rem !important;
}

.me-18 {
  margin-right: 4.5rem !important;
}

.me-19 {
  margin-right: 4.75rem !important;
}

.me-20 {
  margin-right: 5rem !important;
}

.me-auto {
  margin-right: auto !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mb-1 {
  margin-bottom: 0.25rem !important;
}

.mb-2 {
  margin-bottom: 0.5rem !important;
}

.mb-3 {
  margin-bottom: 0.75rem !important;
}

.mb-4 {
  margin-bottom: 1rem !important;
}

.mb-5 {
  margin-bottom: 1.25rem !important;
}

.mb-6 {
  margin-bottom: 1.5rem !important;
}

.mb-7 {
  margin-bottom: 1.75rem !important;
}

.mb-8 {
  margin-bottom: 2rem !important;
}

.mb-9 {
  margin-bottom: 2.25rem !important;
}

.mb-10 {
  margin-bottom: 2.5rem !important;
}

.mb-11 {
  margin-bottom: 2.75rem !important;
}

.mb-12 {
  margin-bottom: 3rem !important;
}

.mb-13 {
  margin-bottom: 3.25rem !important;
}

.mb-14 {
  margin-bottom: 3.5rem !important;
}

.mb-15 {
  margin-bottom: 3.75rem !important;
}

.mb-16 {
  margin-bottom: 4rem !important;
}

.mb-17 {
  margin-bottom: 4.25rem !important;
}

.mb-18 {
  margin-bottom: 4.5rem !important;
}

.mb-19 {
  margin-bottom: 4.75rem !important;
}

.mb-20 {
  margin-bottom: 5rem !important;
}

.mb-auto {
  margin-bottom: auto !important;
}

.ms-0 {
  margin-left: 0 !important;
}

.ms-1 {
  margin-left: 0.25rem !important;
}

.ms-2 {
  margin-left: 0.5rem !important;
}

.ms-3 {
  margin-left: 0.75rem !important;
}

.ms-4 {
  margin-left: 1rem !important;
}

.ms-5 {
  margin-left: 1.25rem !important;
}

.ms-6 {
  margin-left: 1.5rem !important;
}

.ms-7 {
  margin-left: 1.75rem !important;
}

.ms-8 {
  margin-left: 2rem !important;
}

.ms-9 {
  margin-left: 2.25rem !important;
}

.ms-10 {
  margin-left: 2.5rem !important;
}

.ms-11 {
  margin-left: 2.75rem !important;
}

.ms-12 {
  margin-left: 3rem !important;
}

.ms-13 {
  margin-left: 3.25rem !important;
}

.ms-14 {
  margin-left: 3.5rem !important;
}

.ms-15 {
  margin-left: 3.75rem !important;
}

.ms-16 {
  margin-left: 4rem !important;
}

.ms-17 {
  margin-left: 4.25rem !important;
}

.ms-18 {
  margin-left: 4.5rem !important;
}

.ms-19 {
  margin-left: 4.75rem !important;
}

.ms-20 {
  margin-left: 5rem !important;
}

.ms-auto {
  margin-left: auto !important;
}

.m-n1 {
  margin: -0.25rem !important;
}

.m-n2 {
  margin: -0.5rem !important;
}

.m-n3 {
  margin: -0.75rem !important;
}

.m-n4 {
  margin: -1rem !important;
}

.m-n5 {
  margin: -1.25rem !important;
}

.m-n6 {
  margin: -1.5rem !important;
}

.m-n7 {
  margin: -1.75rem !important;
}

.m-n8 {
  margin: -2rem !important;
}

.m-n9 {
  margin: -2.25rem !important;
}

.m-n10 {
  margin: -2.5rem !important;
}

.m-n11 {
  margin: -2.75rem !important;
}

.m-n12 {
  margin: -3rem !important;
}

.m-n13 {
  margin: -3.25rem !important;
}

.m-n14 {
  margin: -3.5rem !important;
}

.m-n15 {
  margin: -3.75rem !important;
}

.m-n16 {
  margin: -4rem !important;
}

.m-n17 {
  margin: -4.25rem !important;
}

.m-n18 {
  margin: -4.5rem !important;
}

.m-n19 {
  margin: -4.75rem !important;
}

.m-n20 {
  margin: -5rem !important;
}

.mx-n1 {
  margin-right: -0.25rem !important;
  margin-left: -0.25rem !important;
}

.mx-n2 {
  margin-right: -0.5rem !important;
  margin-left: -0.5rem !important;
}

.mx-n3 {
  margin-right: -0.75rem !important;
  margin-left: -0.75rem !important;
}

.mx-n4 {
  margin-right: -1rem !important;
  margin-left: -1rem !important;
}

.mx-n5 {
  margin-right: -1.25rem !important;
  margin-left: -1.25rem !important;
}

.mx-n6 {
  margin-right: -1.5rem !important;
  margin-left: -1.5rem !important;
}

.mx-n7 {
  margin-right: -1.75rem !important;
  margin-left: -1.75rem !important;
}

.mx-n8 {
  margin-right: -2rem !important;
  margin-left: -2rem !important;
}

.mx-n9 {
  margin-right: -2.25rem !important;
  margin-left: -2.25rem !important;
}

.mx-n10 {
  margin-right: -2.5rem !important;
  margin-left: -2.5rem !important;
}

.mx-n11 {
  margin-right: -2.75rem !important;
  margin-left: -2.75rem !important;
}

.mx-n12 {
  margin-right: -3rem !important;
  margin-left: -3rem !important;
}

.mx-n13 {
  margin-right: -3.25rem !important;
  margin-left: -3.25rem !important;
}

.mx-n14 {
  margin-right: -3.5rem !important;
  margin-left: -3.5rem !important;
}

.mx-n15 {
  margin-right: -3.75rem !important;
  margin-left: -3.75rem !important;
}

.mx-n16 {
  margin-right: -4rem !important;
  margin-left: -4rem !important;
}

.mx-n17 {
  margin-right: -4.25rem !important;
  margin-left: -4.25rem !important;
}

.mx-n18 {
  margin-right: -4.5rem !important;
  margin-left: -4.5rem !important;
}

.mx-n19 {
  margin-right: -4.75rem !important;
  margin-left: -4.75rem !important;
}

.mx-n20 {
  margin-right: -5rem !important;
  margin-left: -5rem !important;
}

.my-n1 {
  margin-top: -0.25rem !important;
  margin-bottom: -0.25rem !important;
}

.my-n2 {
  margin-top: -0.5rem !important;
  margin-bottom: -0.5rem !important;
}

.my-n3 {
  margin-top: -0.75rem !important;
  margin-bottom: -0.75rem !important;
}

.my-n4 {
  margin-top: -1rem !important;
  margin-bottom: -1rem !important;
}

.my-n5 {
  margin-top: -1.25rem !important;
  margin-bottom: -1.25rem !important;
}

.my-n6 {
  margin-top: -1.5rem !important;
  margin-bottom: -1.5rem !important;
}

.my-n7 {
  margin-top: -1.75rem !important;
  margin-bottom: -1.75rem !important;
}

.my-n8 {
  margin-top: -2rem !important;
  margin-bottom: -2rem !important;
}

.my-n9 {
  margin-top: -2.25rem !important;
  margin-bottom: -2.25rem !important;
}

.my-n10 {
  margin-top: -2.5rem !important;
  margin-bottom: -2.5rem !important;
}

.my-n11 {
  margin-top: -2.75rem !important;
  margin-bottom: -2.75rem !important;
}

.my-n12 {
  margin-top: -3rem !important;
  margin-bottom: -3rem !important;
}

.my-n13 {
  margin-top: -3.25rem !important;
  margin-bottom: -3.25rem !important;
}

.my-n14 {
  margin-top: -3.5rem !important;
  margin-bottom: -3.5rem !important;
}

.my-n15 {
  margin-top: -3.75rem !important;
  margin-bottom: -3.75rem !important;
}

.my-n16 {
  margin-top: -4rem !important;
  margin-bottom: -4rem !important;
}

.my-n17 {
  margin-top: -4.25rem !important;
  margin-bottom: -4.25rem !important;
}

.my-n18 {
  margin-top: -4.5rem !important;
  margin-bottom: -4.5rem !important;
}

.my-n19 {
  margin-top: -4.75rem !important;
  margin-bottom: -4.75rem !important;
}

.my-n20 {
  margin-top: -5rem !important;
  margin-bottom: -5rem !important;
}

.mt-n1 {
  margin-top: -0.25rem !important;
}

.mt-n2 {
  margin-top: -0.5rem !important;
}

.mt-n3 {
  margin-top: -0.75rem !important;
}

.mt-n4 {
  margin-top: -1rem !important;
}

.mt-n5 {
  margin-top: -1.25rem !important;
}

.mt-n6 {
  margin-top: -1.5rem !important;
}

.mt-n7 {
  margin-top: -1.75rem !important;
}

.mt-n8 {
  margin-top: -2rem !important;
}

.mt-n9 {
  margin-top: -2.25rem !important;
}

.mt-n10 {
  margin-top: -2.5rem !important;
}

.mt-n11 {
  margin-top: -2.75rem !important;
}

.mt-n12 {
  margin-top: -3rem !important;
}

.mt-n13 {
  margin-top: -3.25rem !important;
}

.mt-n14 {
  margin-top: -3.5rem !important;
}

.mt-n15 {
  margin-top: -3.75rem !important;
}

.mt-n16 {
  margin-top: -4rem !important;
}

.mt-n17 {
  margin-top: -4.25rem !important;
}

.mt-n18 {
  margin-top: -4.5rem !important;
}

.mt-n19 {
  margin-top: -4.75rem !important;
}

.mt-n20 {
  margin-top: -5rem !important;
}

.me-n1 {
  margin-right: -0.25rem !important;
}

.me-n2 {
  margin-right: -0.5rem !important;
}

.me-n3 {
  margin-right: -0.75rem !important;
}

.me-n4 {
  margin-right: -1rem !important;
}

.me-n5 {
  margin-right: -1.25rem !important;
}

.me-n6 {
  margin-right: -1.5rem !important;
}

.me-n7 {
  margin-right: -1.75rem !important;
}

.me-n8 {
  margin-right: -2rem !important;
}

.me-n9 {
  margin-right: -2.25rem !important;
}

.me-n10 {
  margin-right: -2.5rem !important;
}

.me-n11 {
  margin-right: -2.75rem !important;
}

.me-n12 {
  margin-right: -3rem !important;
}

.me-n13 {
  margin-right: -3.25rem !important;
}

.me-n14 {
  margin-right: -3.5rem !important;
}

.me-n15 {
  margin-right: -3.75rem !important;
}

.me-n16 {
  margin-right: -4rem !important;
}

.me-n17 {
  margin-right: -4.25rem !important;
}

.me-n18 {
  margin-right: -4.5rem !important;
}

.me-n19 {
  margin-right: -4.75rem !important;
}

.me-n20 {
  margin-right: -5rem !important;
}

.mb-n1 {
  margin-bottom: -0.25rem !important;
}

.mb-n2 {
  margin-bottom: -0.5rem !important;
}

.mb-n3 {
  margin-bottom: -0.75rem !important;
}

.mb-n4 {
  margin-bottom: -1rem !important;
}

.mb-n5 {
  margin-bottom: -1.25rem !important;
}

.mb-n6 {
  margin-bottom: -1.5rem !important;
}

.mb-n7 {
  margin-bottom: -1.75rem !important;
}

.mb-n8 {
  margin-bottom: -2rem !important;
}

.mb-n9 {
  margin-bottom: -2.25rem !important;
}

.mb-n10 {
  margin-bottom: -2.5rem !important;
}

.mb-n11 {
  margin-bottom: -2.75rem !important;
}

.mb-n12 {
  margin-bottom: -3rem !important;
}

.mb-n13 {
  margin-bottom: -3.25rem !important;
}

.mb-n14 {
  margin-bottom: -3.5rem !important;
}

.mb-n15 {
  margin-bottom: -3.75rem !important;
}

.mb-n16 {
  margin-bottom: -4rem !important;
}

.mb-n17 {
  margin-bottom: -4.25rem !important;
}

.mb-n18 {
  margin-bottom: -4.5rem !important;
}

.mb-n19 {
  margin-bottom: -4.75rem !important;
}

.mb-n20 {
  margin-bottom: -5rem !important;
}

.ms-n1 {
  margin-left: -0.25rem !important;
}

.ms-n2 {
  margin-left: -0.5rem !important;
}

.ms-n3 {
  margin-left: -0.75rem !important;
}

.ms-n4 {
  margin-left: -1rem !important;
}

.ms-n5 {
  margin-left: -1.25rem !important;
}

.ms-n6 {
  margin-left: -1.5rem !important;
}

.ms-n7 {
  margin-left: -1.75rem !important;
}

.ms-n8 {
  margin-left: -2rem !important;
}

.ms-n9 {
  margin-left: -2.25rem !important;
}

.ms-n10 {
  margin-left: -2.5rem !important;
}

.ms-n11 {
  margin-left: -2.75rem !important;
}

.ms-n12 {
  margin-left: -3rem !important;
}

.ms-n13 {
  margin-left: -3.25rem !important;
}

.ms-n14 {
  margin-left: -3.5rem !important;
}

.ms-n15 {
  margin-left: -3.75rem !important;
}

.ms-n16 {
  margin-left: -4rem !important;
}

.ms-n17 {
  margin-left: -4.25rem !important;
}

.ms-n18 {
  margin-left: -4.5rem !important;
}

.ms-n19 {
  margin-left: -4.75rem !important;
}

.ms-n20 {
  margin-left: -5rem !important;
}

.p-0 {
  padding: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.p-3 {
  padding: 0.75rem !important;
}

.p-4 {
  padding: 1rem !important;
}

.p-5 {
  padding: 1.25rem !important;
}

.p-6 {
  padding: 1.5rem !important;
}

.p-7 {
  padding: 1.75rem !important;
}

.p-8 {
  padding: 2rem !important;
}

.p-9 {
  padding: 2.25rem !important;
}

.p-10 {
  padding: 2.5rem !important;
}

.p-11 {
  padding: 2.75rem !important;
}

.p-12 {
  padding: 3rem !important;
}

.p-13 {
  padding: 3.25rem !important;
}

.p-14 {
  padding: 3.5rem !important;
}

.p-15 {
  padding: 3.75rem !important;
}

.p-16 {
  padding: 4rem !important;
}

.p-17 {
  padding: 4.25rem !important;
}

.p-18 {
  padding: 4.5rem !important;
}

.p-19 {
  padding: 4.75rem !important;
}

.p-20 {
  padding: 5rem !important;
}

.px-0 {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.px-1 {
  padding-right: 0.25rem !important;
  padding-left: 0.25rem !important;
}

.px-2 {
  padding-right: 0.5rem !important;
  padding-left: 0.5rem !important;
}

.px-3 {
  padding-right: 0.75rem !important;
  padding-left: 0.75rem !important;
}

.px-4 {
  padding-right: 1rem !important;
  padding-left: 1rem !important;
}

.px-5 {
  padding-right: 1.25rem !important;
  padding-left: 1.25rem !important;
}

.px-6 {
  padding-right: 1.5rem !important;
  padding-left: 1.5rem !important;
}

.px-7 {
  padding-right: 1.75rem !important;
  padding-left: 1.75rem !important;
}

.px-8 {
  padding-right: 2rem !important;
  padding-left: 2rem !important;
}

.px-9 {
  padding-right: 2.25rem !important;
  padding-left: 2.25rem !important;
}

.px-10 {
  padding-right: 2.5rem !important;
  padding-left: 2.5rem !important;
}

.px-11 {
  padding-right: 2.75rem !important;
  padding-left: 2.75rem !important;
}

.px-12 {
  padding-right: 3rem !important;
  padding-left: 3rem !important;
}

.px-13 {
  padding-right: 3.25rem !important;
  padding-left: 3.25rem !important;
}

.px-14 {
  padding-right: 3.5rem !important;
  padding-left: 3.5rem !important;
}

.px-15 {
  padding-right: 3.75rem !important;
  padding-left: 3.75rem !important;
}

.px-16 {
  padding-right: 4rem !important;
  padding-left: 4rem !important;
}

.px-17 {
  padding-right: 4.25rem !important;
  padding-left: 4.25rem !important;
}

.px-18 {
  padding-right: 4.5rem !important;
  padding-left: 4.5rem !important;
}

.px-19 {
  padding-right: 4.75rem !important;
  padding-left: 4.75rem !important;
}

.px-20 {
  padding-right: 5rem !important;
  padding-left: 5rem !important;
}

.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.py-1 {
  padding-top: 0.25rem !important;
  padding-bottom: 0.25rem !important;
}

.py-2 {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
}

.py-3 {
  padding-top: 0.75rem !important;
  padding-bottom: 0.75rem !important;
}

.py-4 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

.py-5 {
  padding-top: 1.25rem !important;
  padding-bottom: 1.25rem !important;
}

.py-6 {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important;
}

.py-7 {
  padding-top: 1.75rem !important;
  padding-bottom: 1.75rem !important;
}

.py-8 {
  padding-top: 2rem !important;
  padding-bottom: 2rem !important;
}

.py-9 {
  padding-top: 2.25rem !important;
  padding-bottom: 2.25rem !important;
}

.py-10 {
  padding-top: 2.5rem !important;
  padding-bottom: 2.5rem !important;
}

.py-11 {
  padding-top: 2.75rem !important;
  padding-bottom: 2.75rem !important;
}

.py-12 {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important;
}

.py-13 {
  padding-top: 3.25rem !important;
  padding-bottom: 3.25rem !important;
}

.py-14 {
  padding-top: 3.5rem !important;
  padding-bottom: 3.5rem !important;
}

.py-15 {
  padding-top: 3.75rem !important;
  padding-bottom: 3.75rem !important;
}

.py-16 {
  padding-top: 4rem !important;
  padding-bottom: 4rem !important;
}

.py-17 {
  padding-top: 4.25rem !important;
  padding-bottom: 4.25rem !important;
}

.py-18 {
  padding-top: 4.5rem !important;
  padding-bottom: 4.5rem !important;
}

.py-19 {
  padding-top: 4.75rem !important;
  padding-bottom: 4.75rem !important;
}

.py-20 {
  padding-top: 5rem !important;
  padding-bottom: 5rem !important;
}

.pt-0 {
  padding-top: 0 !important;
}

.pt-1 {
  padding-top: 0.25rem !important;
}

.pt-2 {
  padding-top: 0.5rem !important;
}

.pt-3 {
  padding-top: 0.75rem !important;
}

.pt-4 {
  padding-top: 1rem !important;
}

.pt-5 {
  padding-top: 1.25rem !important;
}

.pt-6 {
  padding-top: 1.5rem !important;
}

.pt-7 {
  padding-top: 1.75rem !important;
}

.pt-8 {
  padding-top: 2rem !important;
}

.pt-9 {
  padding-top: 2.25rem !important;
}

.pt-10 {
  padding-top: 2.5rem !important;
}

.pt-11 {
  padding-top: 2.75rem !important;
}

.pt-12 {
  padding-top: 3rem !important;
}

.pt-13 {
  padding-top: 3.25rem !important;
}

.pt-14 {
  padding-top: 3.5rem !important;
}

.pt-15 {
  padding-top: 3.75rem !important;
}

.pt-16 {
  padding-top: 4rem !important;
}

.pt-17 {
  padding-top: 4.25rem !important;
}

.pt-18 {
  padding-top: 4.5rem !important;
}

.pt-19 {
  padding-top: 4.75rem !important;
}

.pt-20 {
  padding-top: 5rem !important;
}

.pe-0 {
  padding-right: 0 !important;
}

.pe-1 {
  padding-right: 0.25rem !important;
}

.pe-2 {
  padding-right: 0.5rem !important;
}

.pe-3 {
  padding-right: 0.75rem !important;
}

.pe-4 {
  padding-right: 1rem !important;
}

.pe-5 {
  padding-right: 1.25rem !important;
}

.pe-6 {
  padding-right: 1.5rem !important;
}

.pe-7 {
  padding-right: 1.75rem !important;
}

.pe-8 {
  padding-right: 2rem !important;
}

.pe-9 {
  padding-right: 2.25rem !important;
}

.pe-10 {
  padding-right: 2.5rem !important;
}

.pe-11 {
  padding-right: 2.75rem !important;
}

.pe-12 {
  padding-right: 3rem !important;
}

.pe-13 {
  padding-right: 3.25rem !important;
}

.pe-14 {
  padding-right: 3.5rem !important;
}

.pe-15 {
  padding-right: 3.75rem !important;
}

.pe-16 {
  padding-right: 4rem !important;
}

.pe-17 {
  padding-right: 4.25rem !important;
}

.pe-18 {
  padding-right: 4.5rem !important;
}

.pe-19 {
  padding-right: 4.75rem !important;
}

.pe-20 {
  padding-right: 5rem !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.pb-1 {
  padding-bottom: 0.25rem !important;
}

.pb-2 {
  padding-bottom: 0.5rem !important;
}

.pb-3 {
  padding-bottom: 0.75rem !important;
}

.pb-4 {
  padding-bottom: 1rem !important;
}

.pb-5 {
  padding-bottom: 1.25rem !important;
}

.pb-6 {
  padding-bottom: 1.5rem !important;
}

.pb-7 {
  padding-bottom: 1.75rem !important;
}

.pb-8 {
  padding-bottom: 2rem !important;
}

.pb-9 {
  padding-bottom: 2.25rem !important;
}

.pb-10 {
  padding-bottom: 2.5rem !important;
}

.pb-11 {
  padding-bottom: 2.75rem !important;
}

.pb-12 {
  padding-bottom: 3rem !important;
}

.pb-13 {
  padding-bottom: 3.25rem !important;
}

.pb-14 {
  padding-bottom: 3.5rem !important;
}

.pb-15 {
  padding-bottom: 3.75rem !important;
}

.pb-16 {
  padding-bottom: 4rem !important;
}

.pb-17 {
  padding-bottom: 4.25rem !important;
}

.pb-18 {
  padding-bottom: 4.5rem !important;
}

.pb-19 {
  padding-bottom: 4.75rem !important;
}

.pb-20 {
  padding-bottom: 5rem !important;
}

.ps-0 {
  padding-left: 0 !important;
}

.ps-1 {
  padding-left: 0.25rem !important;
}

.ps-2 {
  padding-left: 0.5rem !important;
}

.ps-3 {
  padding-left: 0.75rem !important;
}

.ps-4 {
  padding-left: 1rem !important;
}

.ps-5 {
  padding-left: 1.25rem !important;
}

.ps-6 {
  padding-left: 1.5rem !important;
}

.ps-7 {
  padding-left: 1.75rem !important;
}

.ps-8 {
  padding-left: 2rem !important;
}

.ps-9 {
  padding-left: 2.25rem !important;
}

.ps-10 {
  padding-left: 2.5rem !important;
}

.ps-11 {
  padding-left: 2.75rem !important;
}

.ps-12 {
  padding-left: 3rem !important;
}

.ps-13 {
  padding-left: 3.25rem !important;
}

.ps-14 {
  padding-left: 3.5rem !important;
}

.ps-15 {
  padding-left: 3.75rem !important;
}

.ps-16 {
  padding-left: 4rem !important;
}

.ps-17 {
  padding-left: 4.25rem !important;
}

.ps-18 {
  padding-left: 4.5rem !important;
}

.ps-19 {
  padding-left: 4.75rem !important;
}

.ps-20 {
  padding-left: 5rem !important;
}

.font-monospace {
  font-family: var(--bs-font-monospace) !important;
}

.fs-1 {
  font-size: calc(1.3rem + 0.6vw) !important;
}

.fs-2 {
  font-size: calc(1.275rem + 0.3vw) !important;
}

.fs-3 {
  font-size: calc(1.26rem + 0.12vw) !important;
}

.fs-4 {
  font-size: 1.25rem !important;
}

.fs-5 {
  font-size: 1.15rem !important;
}

.fs-6 {
  font-size: 1.075rem !important;
}

.fs-7 {
  font-size: 0.95rem !important;
}

.fs-8 {
  font-size: 0.85rem !important;
}

.fs-9 {
  font-size: 0.75rem !important;
}

.fs-10 {
  font-size: 0.5rem !important;
}

.fs-base {
  font-size: 1rem !important;
}

.fs-fluid {
  font-size: 100% !important;
}

.fs-2x {
  font-size: calc(1.325rem + 0.9vw) !important;
}

.fs-2qx {
  font-size: calc(1.35rem + 1.2vw) !important;
}

.fs-2hx {
  font-size: calc(1.375rem + 1.5vw) !important;
}

.fs-2tx {
  font-size: calc(1.4rem + 1.8vw) !important;
}

.fs-3x {
  font-size: calc(1.425rem + 2.1vw) !important;
}

.fs-3qx {
  font-size: calc(1.45rem + 2.4vw) !important;
}

.fs-3hx {
  font-size: calc(1.475rem + 2.7vw) !important;
}

.fs-3tx {
  font-size: calc(1.5rem + 3vw) !important;
}

.fs-4x {
  font-size: calc(1.525rem + 3.3vw) !important;
}

.fs-4qx {
  font-size: calc(1.55rem + 3.6vw) !important;
}

.fs-4hx {
  font-size: calc(1.575rem + 3.9vw) !important;
}

.fs-4tx {
  font-size: calc(1.6rem + 4.2vw) !important;
}

.fs-5x {
  font-size: calc(1.625rem + 4.5vw) !important;
}

.fs-5qx {
  font-size: calc(1.65rem + 4.8vw) !important;
}

.fs-5hx {
  font-size: calc(1.675rem + 5.1vw) !important;
}

.fs-5tx {
  font-size: calc(1.7rem + 5.4vw) !important;
}

.fst-italic {
  font-style: italic !important;
}

.fst-normal {
  font-style: normal !important;
}

.fw-light {
  font-weight: 300 !important;
}

.fw-lighter {
  font-weight: lighter !important;
}

.fw-normal {
  font-weight: 400 !important;
}

.fw-bold {
  font-weight: 500 !important;
}

.fw-bolder {
  font-weight: 600 !important;
}

.lh-0 {
  line-height: 0 !important;
}

.lh-1 {
  line-height: 1 !important;
}

.lh-sm {
  line-height: 1.25 !important;
}

.lh-base {
  line-height: 1.5 !important;
}

.lh-lg {
  line-height: 1.75 !important;
}

.lh-xl {
  line-height: 2 !important;
}

.lh-xxl {
  line-height: 2.25 !important;
}

.text-start {
  text-align: left !important;
}

.text-end {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-decoration-underline {
  text-decoration: underline !important;
}

.text-decoration-line-through {
  text-decoration: line-through !important;
}

.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

/* rtl:begin:remove */
.text-break {
  word-wrap: break-word !important;
  word-break: break-word !important;
}

/* rtl:end:remove */
.text-white {
  color: #ffffff !important;
}

.text-light {
  color: #F5F8FA !important;
}

.text-primary {
  color: #009EF7 !important;
}

.text-secondary {
  color: #E4E6EF !important;
}

.text-success {
  color: #50CD89 !important;
}

.text-info {
  color: #7239EA !important;
}

.text-warning {
  color: #FFC700 !important;
}

.text-danger {
  color: #F1416C !important;
}

.text-dark {
  color: #181C32 !important;
}

.text-body {
  color: #181C32 !important;
}

.text-muted {
  color: #A1A5B7 !important;
}

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important;
}

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important;
}

.text-reset {
  color: inherit !important;
}

.bg-white {
  background-color: #ffffff !important;
}

.bg-light {
  background-color: #F5F8FA !important;
}

.bg-primary {
  background-color: #009EF7 !important;
}

.bg-secondary {
  background-color: #E4E6EF !important;
}

.bg-success {
  background-color: #50CD89 !important;
}

.bg-info {
  background-color: #7239EA !important;
}

.bg-warning {
  background-color: #FFC700 !important;
}

.bg-danger {
  background-color: #F1416C !important;
}

.bg-dark {
  background-color: #181C32 !important;
}

.bg-body {
  background-color: #ffffff !important;
}

.bg-transparent {
  background-color: transparent !important;
}

.bg-gradient {
  background-image: var(--bs-gradient) !important;
}

.user-select-all {
  user-select: all !important;
}

.user-select-auto {
  user-select: auto !important;
}

.user-select-none {
  user-select: none !important;
}

.pe-none {
  pointer-events: none !important;
}

.pe-auto {
  pointer-events: auto !important;
}

.rounded {
  border-radius: 0.475rem !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.rounded-1 {
  border-radius: 0.275rem !important;
}

.rounded-2 {
  border-radius: 0.475rem !important;
}

.rounded-3 {
  border-radius: 0.775rem !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-pill {
  border-radius: 50rem !important;
}

.rounded-top {
  border-top-left-radius: 0.475rem !important;
  border-top-right-radius: 0.475rem !important;
}

.rounded-end {
  border-top-right-radius: 0.475rem !important;
  border-bottom-right-radius: 0.475rem !important;
}

.rounded-bottom {
  border-bottom-right-radius: 0.475rem !important;
  border-bottom-left-radius: 0.475rem !important;
}

.rounded-start {
  border-bottom-left-radius: 0.475rem !important;
  border-top-left-radius: 0.475rem !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

.opacity-0 {
  opacity: 0 !important;
}

.opacity-5 {
  opacity: 0.05 !important;
}

.opacity-10 {
  opacity: 0.1 !important;
}

.opacity-15 {
  opacity: 0.15 !important;
}

.opacity-20 {
  opacity: 0.2 !important;
}

.opacity-25 {
  opacity: 0.25 !important;
}

.opacity-50 {
  opacity: 0.5 !important;
}

.opacity-75 {
  opacity: 0.75 !important;
}

.opacity-100 {
  opacity: 1 !important;
}

.opacity-0 {
  opacity: 0 !important;
}

.opacity-0-hover:hover {
  opacity: 0 !important;
}

.opacity-5 {
  opacity: 0.05 !important;
}

.opacity-5-hover:hover {
  opacity: 0.05 !important;
}

.opacity-10 {
  opacity: 0.1 !important;
}

.opacity-10-hover:hover {
  opacity: 0.1 !important;
}

.opacity-15 {
  opacity: 0.15 !important;
}

.opacity-15-hover:hover {
  opacity: 0.15 !important;
}

.opacity-20 {
  opacity: 0.2 !important;
}

.opacity-20-hover:hover {
  opacity: 0.2 !important;
}

.opacity-25 {
  opacity: 0.25 !important;
}

.opacity-25-hover:hover {
  opacity: 0.25 !important;
}

.opacity-50 {
  opacity: 0.5 !important;
}

.opacity-50-hover:hover {
  opacity: 0.5 !important;
}

.opacity-75 {
  opacity: 0.75 !important;
}

.opacity-75-hover:hover {
  opacity: 0.75 !important;
}

.opacity-100 {
  opacity: 1 !important;
}

.opacity-100-hover:hover {
  opacity: 1 !important;
}

.min-w-unset {
  min-width: unset !important;
}

.min-w-25 {
  min-width: 25% !important;
}

.min-w-50 {
  min-width: 50% !important;
}

.min-w-75 {
  min-width: 75% !important;
}

.min-w-100 {
  min-width: 100% !important;
}

.min-w-auto {
  min-width: auto !important;
}

.min-w-1px {
  min-width: 1px !important;
}

.min-w-2px {
  min-width: 2px !important;
}

.min-w-3px {
  min-width: 3px !important;
}

.min-w-4px {
  min-width: 4px !important;
}

.min-w-5px {
  min-width: 5px !important;
}

.min-w-6px {
  min-width: 6px !important;
}

.min-w-7px {
  min-width: 7px !important;
}

.min-w-8px {
  min-width: 8px !important;
}

.min-w-9px {
  min-width: 9px !important;
}

.min-w-10px {
  min-width: 10px !important;
}

.min-w-15px {
  min-width: 15px !important;
}

.min-w-20px {
  min-width: 20px !important;
}

.min-w-25px {
  min-width: 25px !important;
}

.min-w-30px {
  min-width: 30px !important;
}

.min-w-35px {
  min-width: 35px !important;
}

.min-w-40px {
  min-width: 40px !important;
}

.min-w-45px {
  min-width: 45px !important;
}

.min-w-50px {
  min-width: 50px !important;
}

.min-w-55px {
  min-width: 55px !important;
}

.min-w-60px {
  min-width: 60px !important;
}

.min-w-65px {
  min-width: 65px !important;
}

.min-w-70px {
  min-width: 70px !important;
}

.min-w-75px {
  min-width: 75px !important;
}

.min-w-80px {
  min-width: 80px !important;
}

.min-w-85px {
  min-width: 85px !important;
}

.min-w-90px {
  min-width: 90px !important;
}

.min-w-95px {
  min-width: 95px !important;
}

.min-w-100px {
  min-width: 100px !important;
}

.min-w-125px {
  min-width: 125px !important;
}

.min-w-150px {
  min-width: 150px !important;
}

.min-w-175px {
  min-width: 175px !important;
}

.min-w-200px {
  min-width: 200px !important;
}

.min-w-225px {
  min-width: 225px !important;
}

.min-w-250px {
  min-width: 250px !important;
}

.min-w-275px {
  min-width: 275px !important;
}

.min-w-300px {
  min-width: 300px !important;
}

.min-w-325px {
  min-width: 325px !important;
}

.min-w-350px {
  min-width: 350px !important;
}

.min-w-375px {
  min-width: 375px !important;
}

.min-w-400px {
  min-width: 400px !important;
}

.min-w-425px {
  min-width: 425px !important;
}

.min-w-450px {
  min-width: 450px !important;
}

.min-w-475px {
  min-width: 475px !important;
}

.min-w-500px {
  min-width: 500px !important;
}

.min-w-550px {
  min-width: 550px !important;
}

.min-w-600px {
  min-width: 600px !important;
}

.min-w-650px {
  min-width: 650px !important;
}

.min-w-700px {
  min-width: 700px !important;
}

.min-w-750px {
  min-width: 750px !important;
}

.min-w-800px {
  min-width: 800px !important;
}

.min-w-850px {
  min-width: 850px !important;
}

.min-w-900px {
  min-width: 900px !important;
}

.min-w-950px {
  min-width: 950px !important;
}

.min-w-1000px {
  min-width: 1000px !important;
}

.min-h-unset {
  min-height: unset !important;
}

.min-h-25 {
  min-height: 25% !important;
}

.min-h-50 {
  min-height: 50% !important;
}

.min-h-75 {
  min-height: 75% !important;
}

.min-h-100 {
  min-height: 100% !important;
}

.min-h-auto {
  min-height: auto !important;
}

.min-h-1px {
  min-height: 1px !important;
}

.min-h-2px {
  min-height: 2px !important;
}

.min-h-3px {
  min-height: 3px !important;
}

.min-h-4px {
  min-height: 4px !important;
}

.min-h-5px {
  min-height: 5px !important;
}

.min-h-6px {
  min-height: 6px !important;
}

.min-h-7px {
  min-height: 7px !important;
}

.min-h-8px {
  min-height: 8px !important;
}

.min-h-9px {
  min-height: 9px !important;
}

.min-h-10px {
  min-height: 10px !important;
}

.min-h-15px {
  min-height: 15px !important;
}

.min-h-20px {
  min-height: 20px !important;
}

.min-h-25px {
  min-height: 25px !important;
}

.min-h-30px {
  min-height: 30px !important;
}

.min-h-35px {
  min-height: 35px !important;
}

.min-h-40px {
  min-height: 40px !important;
}

.min-h-45px {
  min-height: 45px !important;
}

.min-h-50px {
  min-height: 50px !important;
}

.min-h-55px {
  min-height: 55px !important;
}

.min-h-60px {
  min-height: 60px !important;
}

.min-h-65px {
  min-height: 65px !important;
}

.min-h-70px {
  min-height: 70px !important;
}

.min-h-75px {
  min-height: 75px !important;
}

.min-h-80px {
  min-height: 80px !important;
}

.min-h-85px {
  min-height: 85px !important;
}

.min-h-90px {
  min-height: 90px !important;
}

.min-h-95px {
  min-height: 95px !important;
}

.min-h-100px {
  min-height: 100px !important;
}

.min-h-125px {
  min-height: 125px !important;
}

.min-h-150px {
  min-height: 150px !important;
}

.min-h-175px {
  min-height: 175px !important;
}

.min-h-200px {
  min-height: 200px !important;
}

.min-h-225px {
  min-height: 225px !important;
}

.min-h-250px {
  min-height: 250px !important;
}

.min-h-275px {
  min-height: 275px !important;
}

.min-h-300px {
  min-height: 300px !important;
}

.min-h-325px {
  min-height: 325px !important;
}

.min-h-350px {
  min-height: 350px !important;
}

.min-h-375px {
  min-height: 375px !important;
}

.min-h-400px {
  min-height: 400px !important;
}

.min-h-425px {
  min-height: 425px !important;
}

.min-h-450px {
  min-height: 450px !important;
}

.min-h-475px {
  min-height: 475px !important;
}

.min-h-500px {
  min-height: 500px !important;
}

.min-h-550px {
  min-height: 550px !important;
}

.min-h-600px {
  min-height: 600px !important;
}

.min-h-650px {
  min-height: 650px !important;
}

.min-h-700px {
  min-height: 700px !important;
}

.min-h-750px {
  min-height: 750px !important;
}

.min-h-800px {
  min-height: 800px !important;
}

.min-h-850px {
  min-height: 850px !important;
}

.min-h-900px {
  min-height: 900px !important;
}

.min-h-950px {
  min-height: 950px !important;
}

.min-h-1000px {
  min-height: 1000px !important;
}

.z-index-n1 {
  z-index: -1 !important;
}

.z-index-n2 {
  z-index: -2 !important;
}

.z-index-0 {
  z-index: 0 !important;
}

.z-index-1 {
  z-index: 1 !important;
}

.z-index-2 {
  z-index: 2 !important;
}

.z-index-3 {
  z-index: 3 !important;
}

.border-top-0 {
  border-top-width: 0 !important;
}

.border-top-1 {
  border-top-width: 1px !important;
}

.border-top-2 {
  border-top-width: 2px !important;
}

.border-top-3 {
  border-top-width: 3px !important;
}

.border-top-4 {
  border-top-width: 4px !important;
}

.border-top-5 {
  border-top-width: 5px !important;
}

.border-bottom-0 {
  border-bottom-width: 0 !important;
}

.border-bottom-1 {
  border-bottom-width: 1px !important;
}

.border-bottom-2 {
  border-bottom-width: 2px !important;
}

.border-bottom-3 {
  border-bottom-width: 3px !important;
}

.border-bottom-4 {
  border-bottom-width: 4px !important;
}

.border-bottom-5 {
  border-bottom-width: 5px !important;
}

.border-right-0 {
  border-right-width: 0 !important;
}

.border-right-1 {
  border-right-width: 1px !important;
}

.border-right-2 {
  border-right-width: 2px !important;
}

.border-right-3 {
  border-right-width: 3px !important;
}

.border-right-4 {
  border-right-width: 4px !important;
}

.border-right-5 {
  border-right-width: 5px !important;
}

.border-left-0 {
  border-left-width: 0 !important;
}

.border-left-1 {
  border-left-width: 1px !important;
}

.border-left-2 {
  border-left-width: 2px !important;
}

.border-left-3 {
  border-left-width: 3px !important;
}

.border-left-4 {
  border-left-width: 4px !important;
}

.border-left-5 {
  border-left-width: 5px !important;
}

.ls-1 {
  letter-spacing: 0.1rem !important;
}

.ls-2 {
  letter-spacing: 0.115rem !important;
}

.ls-3 {
  letter-spacing: 0.125rem !important;
}

.ls-4 {
  letter-spacing: 0.25rem !important;
}

.ls-5 {
  letter-spacing: 0.5rem !important;
}

@media (min-width: 576px) {
  .float-sm-start {
    float: left !important;
  }

  .float-sm-end {
    float: right !important;
  }

  .float-sm-none {
    float: none !important;
  }

  .d-sm-inline {
    display: inline !important;
  }

  .d-sm-inline-block {
    display: inline-block !important;
  }

  .d-sm-block {
    display: block !important;
  }

  .d-sm-grid {
    display: grid !important;
  }

  .d-sm-table {
    display: table !important;
  }

  .d-sm-table-row {
    display: table-row !important;
  }

  .d-sm-table-cell {
    display: table-cell !important;
  }

  .d-sm-flex {
    display: flex !important;
  }

  .d-sm-inline-flex {
    display: inline-flex !important;
  }

  .d-sm-none {
    display: none !important;
  }

  .w-sm-unset {
    width: unset !important;
  }

  .w-sm-25 {
    width: 25% !important;
  }

  .w-sm-50 {
    width: 50% !important;
  }

  .w-sm-75 {
    width: 75% !important;
  }

  .w-sm-100 {
    width: 100% !important;
  }

  .w-sm-auto {
    width: auto !important;
  }

  .w-sm-1px {
    width: 1px !important;
  }

  .w-sm-2px {
    width: 2px !important;
  }

  .w-sm-3px {
    width: 3px !important;
  }

  .w-sm-4px {
    width: 4px !important;
  }

  .w-sm-5px {
    width: 5px !important;
  }

  .w-sm-6px {
    width: 6px !important;
  }

  .w-sm-7px {
    width: 7px !important;
  }

  .w-sm-8px {
    width: 8px !important;
  }

  .w-sm-9px {
    width: 9px !important;
  }

  .w-sm-10px {
    width: 10px !important;
  }

  .w-sm-15px {
    width: 15px !important;
  }

  .w-sm-20px {
    width: 20px !important;
  }

  .w-sm-25px {
    width: 25px !important;
  }

  .w-sm-30px {
    width: 30px !important;
  }

  .w-sm-35px {
    width: 35px !important;
  }

  .w-sm-40px {
    width: 40px !important;
  }

  .w-sm-45px {
    width: 45px !important;
  }

  .w-sm-50px {
    width: 50px !important;
  }

  .w-sm-55px {
    width: 55px !important;
  }

  .w-sm-60px {
    width: 60px !important;
  }

  .w-sm-65px {
    width: 65px !important;
  }

  .w-sm-70px {
    width: 70px !important;
  }

  .w-sm-75px {
    width: 75px !important;
  }

  .w-sm-80px {
    width: 80px !important;
  }

  .w-sm-85px {
    width: 85px !important;
  }

  .w-sm-90px {
    width: 90px !important;
  }

  .w-sm-95px {
    width: 95px !important;
  }

  .w-sm-100px {
    width: 100px !important;
  }

  .w-sm-125px {
    width: 125px !important;
  }

  .w-sm-150px {
    width: 150px !important;
  }

  .w-sm-175px {
    width: 175px !important;
  }

  .w-sm-200px {
    width: 200px !important;
  }

  .w-sm-225px {
    width: 225px !important;
  }

  .w-sm-250px {
    width: 250px !important;
  }

  .w-sm-275px {
    width: 275px !important;
  }

  .w-sm-300px {
    width: 300px !important;
  }

  .w-sm-325px {
    width: 325px !important;
  }

  .w-sm-350px {
    width: 350px !important;
  }

  .w-sm-375px {
    width: 375px !important;
  }

  .w-sm-400px {
    width: 400px !important;
  }

  .w-sm-425px {
    width: 425px !important;
  }

  .w-sm-450px {
    width: 450px !important;
  }

  .w-sm-475px {
    width: 475px !important;
  }

  .w-sm-500px {
    width: 500px !important;
  }

  .w-sm-550px {
    width: 550px !important;
  }

  .w-sm-600px {
    width: 600px !important;
  }

  .w-sm-650px {
    width: 650px !important;
  }

  .w-sm-700px {
    width: 700px !important;
  }

  .w-sm-750px {
    width: 750px !important;
  }

  .w-sm-800px {
    width: 800px !important;
  }

  .w-sm-850px {
    width: 850px !important;
  }

  .w-sm-900px {
    width: 900px !important;
  }

  .w-sm-950px {
    width: 950px !important;
  }

  .w-sm-1000px {
    width: 1000px !important;
  }

  .mw-sm-unset {
    max-width: unset !important;
  }

  .mw-sm-25 {
    max-width: 25% !important;
  }

  .mw-sm-50 {
    max-width: 50% !important;
  }

  .mw-sm-75 {
    max-width: 75% !important;
  }

  .mw-sm-100 {
    max-width: 100% !important;
  }

  .mw-sm-auto {
    max-width: auto !important;
  }

  .mw-sm-1px {
    max-width: 1px !important;
  }

  .mw-sm-2px {
    max-width: 2px !important;
  }

  .mw-sm-3px {
    max-width: 3px !important;
  }

  .mw-sm-4px {
    max-width: 4px !important;
  }

  .mw-sm-5px {
    max-width: 5px !important;
  }

  .mw-sm-6px {
    max-width: 6px !important;
  }

  .mw-sm-7px {
    max-width: 7px !important;
  }

  .mw-sm-8px {
    max-width: 8px !important;
  }

  .mw-sm-9px {
    max-width: 9px !important;
  }

  .mw-sm-10px {
    max-width: 10px !important;
  }

  .mw-sm-15px {
    max-width: 15px !important;
  }

  .mw-sm-20px {
    max-width: 20px !important;
  }

  .mw-sm-25px {
    max-width: 25px !important;
  }

  .mw-sm-30px {
    max-width: 30px !important;
  }

  .mw-sm-35px {
    max-width: 35px !important;
  }

  .mw-sm-40px {
    max-width: 40px !important;
  }

  .mw-sm-45px {
    max-width: 45px !important;
  }

  .mw-sm-50px {
    max-width: 50px !important;
  }

  .mw-sm-55px {
    max-width: 55px !important;
  }

  .mw-sm-60px {
    max-width: 60px !important;
  }

  .mw-sm-65px {
    max-width: 65px !important;
  }

  .mw-sm-70px {
    max-width: 70px !important;
  }

  .mw-sm-75px {
    max-width: 75px !important;
  }

  .mw-sm-80px {
    max-width: 80px !important;
  }

  .mw-sm-85px {
    max-width: 85px !important;
  }

  .mw-sm-90px {
    max-width: 90px !important;
  }

  .mw-sm-95px {
    max-width: 95px !important;
  }

  .mw-sm-100px {
    max-width: 100px !important;
  }

  .mw-sm-125px {
    max-width: 125px !important;
  }

  .mw-sm-150px {
    max-width: 150px !important;
  }

  .mw-sm-175px {
    max-width: 175px !important;
  }

  .mw-sm-200px {
    max-width: 200px !important;
  }

  .mw-sm-225px {
    max-width: 225px !important;
  }

  .mw-sm-250px {
    max-width: 250px !important;
  }

  .mw-sm-275px {
    max-width: 275px !important;
  }

  .mw-sm-300px {
    max-width: 300px !important;
  }

  .mw-sm-325px {
    max-width: 325px !important;
  }

  .mw-sm-350px {
    max-width: 350px !important;
  }

  .mw-sm-375px {
    max-width: 375px !important;
  }

  .mw-sm-400px {
    max-width: 400px !important;
  }

  .mw-sm-425px {
    max-width: 425px !important;
  }

  .mw-sm-450px {
    max-width: 450px !important;
  }

  .mw-sm-475px {
    max-width: 475px !important;
  }

  .mw-sm-500px {
    max-width: 500px !important;
  }

  .mw-sm-550px {
    max-width: 550px !important;
  }

  .mw-sm-600px {
    max-width: 600px !important;
  }

  .mw-sm-650px {
    max-width: 650px !important;
  }

  .mw-sm-700px {
    max-width: 700px !important;
  }

  .mw-sm-750px {
    max-width: 750px !important;
  }

  .mw-sm-800px {
    max-width: 800px !important;
  }

  .mw-sm-850px {
    max-width: 850px !important;
  }

  .mw-sm-900px {
    max-width: 900px !important;
  }

  .mw-sm-950px {
    max-width: 950px !important;
  }

  .mw-sm-1000px {
    max-width: 1000px !important;
  }

  .h-sm-unset {
    height: unset !important;
  }

  .h-sm-25 {
    height: 25% !important;
  }

  .h-sm-50 {
    height: 50% !important;
  }

  .h-sm-75 {
    height: 75% !important;
  }

  .h-sm-100 {
    height: 100% !important;
  }

  .h-sm-auto {
    height: auto !important;
  }

  .h-sm-1px {
    height: 1px !important;
  }

  .h-sm-2px {
    height: 2px !important;
  }

  .h-sm-3px {
    height: 3px !important;
  }

  .h-sm-4px {
    height: 4px !important;
  }

  .h-sm-5px {
    height: 5px !important;
  }

  .h-sm-6px {
    height: 6px !important;
  }

  .h-sm-7px {
    height: 7px !important;
  }

  .h-sm-8px {
    height: 8px !important;
  }

  .h-sm-9px {
    height: 9px !important;
  }

  .h-sm-10px {
    height: 10px !important;
  }

  .h-sm-15px {
    height: 15px !important;
  }

  .h-sm-20px {
    height: 20px !important;
  }

  .h-sm-25px {
    height: 25px !important;
  }

  .h-sm-30px {
    height: 30px !important;
  }

  .h-sm-35px {
    height: 35px !important;
  }

  .h-sm-40px {
    height: 40px !important;
  }

  .h-sm-45px {
    height: 45px !important;
  }

  .h-sm-50px {
    height: 50px !important;
  }

  .h-sm-55px {
    height: 55px !important;
  }

  .h-sm-60px {
    height: 60px !important;
  }

  .h-sm-65px {
    height: 65px !important;
  }

  .h-sm-70px {
    height: 70px !important;
  }

  .h-sm-75px {
    height: 75px !important;
  }

  .h-sm-80px {
    height: 80px !important;
  }

  .h-sm-85px {
    height: 85px !important;
  }

  .h-sm-90px {
    height: 90px !important;
  }

  .h-sm-95px {
    height: 95px !important;
  }

  .h-sm-100px {
    height: 100px !important;
  }

  .h-sm-125px {
    height: 125px !important;
  }

  .h-sm-150px {
    height: 150px !important;
  }

  .h-sm-175px {
    height: 175px !important;
  }

  .h-sm-200px {
    height: 200px !important;
  }

  .h-sm-225px {
    height: 225px !important;
  }

  .h-sm-250px {
    height: 250px !important;
  }

  .h-sm-275px {
    height: 275px !important;
  }

  .h-sm-300px {
    height: 300px !important;
  }

  .h-sm-325px {
    height: 325px !important;
  }

  .h-sm-350px {
    height: 350px !important;
  }

  .h-sm-375px {
    height: 375px !important;
  }

  .h-sm-400px {
    height: 400px !important;
  }

  .h-sm-425px {
    height: 425px !important;
  }

  .h-sm-450px {
    height: 450px !important;
  }

  .h-sm-475px {
    height: 475px !important;
  }

  .h-sm-500px {
    height: 500px !important;
  }

  .h-sm-550px {
    height: 550px !important;
  }

  .h-sm-600px {
    height: 600px !important;
  }

  .h-sm-650px {
    height: 650px !important;
  }

  .h-sm-700px {
    height: 700px !important;
  }

  .h-sm-750px {
    height: 750px !important;
  }

  .h-sm-800px {
    height: 800px !important;
  }

  .h-sm-850px {
    height: 850px !important;
  }

  .h-sm-900px {
    height: 900px !important;
  }

  .h-sm-950px {
    height: 950px !important;
  }

  .h-sm-1000px {
    height: 1000px !important;
  }

  .mh-sm-unset {
    max-height: unset !important;
  }

  .mh-sm-25 {
    max-height: 25% !important;
  }

  .mh-sm-50 {
    max-height: 50% !important;
  }

  .mh-sm-75 {
    max-height: 75% !important;
  }

  .mh-sm-100 {
    max-height: 100% !important;
  }

  .mh-sm-auto {
    max-height: auto !important;
  }

  .mh-sm-1px {
    max-height: 1px !important;
  }

  .mh-sm-2px {
    max-height: 2px !important;
  }

  .mh-sm-3px {
    max-height: 3px !important;
  }

  .mh-sm-4px {
    max-height: 4px !important;
  }

  .mh-sm-5px {
    max-height: 5px !important;
  }

  .mh-sm-6px {
    max-height: 6px !important;
  }

  .mh-sm-7px {
    max-height: 7px !important;
  }

  .mh-sm-8px {
    max-height: 8px !important;
  }

  .mh-sm-9px {
    max-height: 9px !important;
  }

  .mh-sm-10px {
    max-height: 10px !important;
  }

  .mh-sm-15px {
    max-height: 15px !important;
  }

  .mh-sm-20px {
    max-height: 20px !important;
  }

  .mh-sm-25px {
    max-height: 25px !important;
  }

  .mh-sm-30px {
    max-height: 30px !important;
  }

  .mh-sm-35px {
    max-height: 35px !important;
  }

  .mh-sm-40px {
    max-height: 40px !important;
  }

  .mh-sm-45px {
    max-height: 45px !important;
  }

  .mh-sm-50px {
    max-height: 50px !important;
  }

  .mh-sm-55px {
    max-height: 55px !important;
  }

  .mh-sm-60px {
    max-height: 60px !important;
  }

  .mh-sm-65px {
    max-height: 65px !important;
  }

  .mh-sm-70px {
    max-height: 70px !important;
  }

  .mh-sm-75px {
    max-height: 75px !important;
  }

  .mh-sm-80px {
    max-height: 80px !important;
  }

  .mh-sm-85px {
    max-height: 85px !important;
  }

  .mh-sm-90px {
    max-height: 90px !important;
  }

  .mh-sm-95px {
    max-height: 95px !important;
  }

  .mh-sm-100px {
    max-height: 100px !important;
  }

  .mh-sm-125px {
    max-height: 125px !important;
  }

  .mh-sm-150px {
    max-height: 150px !important;
  }

  .mh-sm-175px {
    max-height: 175px !important;
  }

  .mh-sm-200px {
    max-height: 200px !important;
  }

  .mh-sm-225px {
    max-height: 225px !important;
  }

  .mh-sm-250px {
    max-height: 250px !important;
  }

  .mh-sm-275px {
    max-height: 275px !important;
  }

  .mh-sm-300px {
    max-height: 300px !important;
  }

  .mh-sm-325px {
    max-height: 325px !important;
  }

  .mh-sm-350px {
    max-height: 350px !important;
  }

  .mh-sm-375px {
    max-height: 375px !important;
  }

  .mh-sm-400px {
    max-height: 400px !important;
  }

  .mh-sm-425px {
    max-height: 425px !important;
  }

  .mh-sm-450px {
    max-height: 450px !important;
  }

  .mh-sm-475px {
    max-height: 475px !important;
  }

  .mh-sm-500px {
    max-height: 500px !important;
  }

  .mh-sm-550px {
    max-height: 550px !important;
  }

  .mh-sm-600px {
    max-height: 600px !important;
  }

  .mh-sm-650px {
    max-height: 650px !important;
  }

  .mh-sm-700px {
    max-height: 700px !important;
  }

  .mh-sm-750px {
    max-height: 750px !important;
  }

  .mh-sm-800px {
    max-height: 800px !important;
  }

  .mh-sm-850px {
    max-height: 850px !important;
  }

  .mh-sm-900px {
    max-height: 900px !important;
  }

  .mh-sm-950px {
    max-height: 950px !important;
  }

  .mh-sm-1000px {
    max-height: 1000px !important;
  }

  .flex-sm-fill {
    flex: 1 1 auto !important;
  }

  .flex-sm-row {
    flex-direction: row !important;
  }

  .flex-sm-column {
    flex-direction: column !important;
  }

  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }

  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .gap-sm-0 {
    gap: 0 !important;
  }

  .gap-sm-1 {
    gap: 0.25rem !important;
  }

  .gap-sm-2 {
    gap: 0.5rem !important;
  }

  .gap-sm-3 {
    gap: 0.75rem !important;
  }

  .gap-sm-4 {
    gap: 1rem !important;
  }

  .gap-sm-5 {
    gap: 1.25rem !important;
  }

  .gap-sm-6 {
    gap: 1.5rem !important;
  }

  .gap-sm-7 {
    gap: 1.75rem !important;
  }

  .gap-sm-8 {
    gap: 2rem !important;
  }

  .gap-sm-9 {
    gap: 2.25rem !important;
  }

  .gap-sm-10 {
    gap: 2.5rem !important;
  }

  .gap-sm-11 {
    gap: 2.75rem !important;
  }

  .gap-sm-12 {
    gap: 3rem !important;
  }

  .gap-sm-13 {
    gap: 3.25rem !important;
  }

  .gap-sm-14 {
    gap: 3.5rem !important;
  }

  .gap-sm-15 {
    gap: 3.75rem !important;
  }

  .gap-sm-16 {
    gap: 4rem !important;
  }

  .gap-sm-17 {
    gap: 4.25rem !important;
  }

  .gap-sm-18 {
    gap: 4.5rem !important;
  }

  .gap-sm-19 {
    gap: 4.75rem !important;
  }

  .gap-sm-20 {
    gap: 5rem !important;
  }

  .justify-content-sm-start {
    justify-content: flex-start !important;
  }

  .justify-content-sm-end {
    justify-content: flex-end !important;
  }

  .justify-content-sm-center {
    justify-content: center !important;
  }

  .justify-content-sm-between {
    justify-content: space-between !important;
  }

  .justify-content-sm-around {
    justify-content: space-around !important;
  }

  .justify-content-sm-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-sm-start {
    align-items: flex-start !important;
  }

  .align-items-sm-end {
    align-items: flex-end !important;
  }

  .align-items-sm-center {
    align-items: center !important;
  }

  .align-items-sm-baseline {
    align-items: baseline !important;
  }

  .align-items-sm-stretch {
    align-items: stretch !important;
  }

  .align-content-sm-start {
    align-content: flex-start !important;
  }

  .align-content-sm-end {
    align-content: flex-end !important;
  }

  .align-content-sm-center {
    align-content: center !important;
  }

  .align-content-sm-between {
    align-content: space-between !important;
  }

  .align-content-sm-around {
    align-content: space-around !important;
  }

  .align-content-sm-stretch {
    align-content: stretch !important;
  }

  .align-self-sm-auto {
    align-self: auto !important;
  }

  .align-self-sm-start {
    align-self: flex-start !important;
  }

  .align-self-sm-end {
    align-self: flex-end !important;
  }

  .align-self-sm-center {
    align-self: center !important;
  }

  .align-self-sm-baseline {
    align-self: baseline !important;
  }

  .align-self-sm-stretch {
    align-self: stretch !important;
  }

  .order-sm-first {
    order: -1 !important;
  }

  .order-sm-0 {
    order: 0 !important;
  }

  .order-sm-1 {
    order: 1 !important;
  }

  .order-sm-2 {
    order: 2 !important;
  }

  .order-sm-3 {
    order: 3 !important;
  }

  .order-sm-4 {
    order: 4 !important;
  }

  .order-sm-5 {
    order: 5 !important;
  }

  .order-sm-last {
    order: 6 !important;
  }

  .m-sm-0 {
    margin: 0 !important;
  }

  .m-sm-1 {
    margin: 0.25rem !important;
  }

  .m-sm-2 {
    margin: 0.5rem !important;
  }

  .m-sm-3 {
    margin: 0.75rem !important;
  }

  .m-sm-4 {
    margin: 1rem !important;
  }

  .m-sm-5 {
    margin: 1.25rem !important;
  }

  .m-sm-6 {
    margin: 1.5rem !important;
  }

  .m-sm-7 {
    margin: 1.75rem !important;
  }

  .m-sm-8 {
    margin: 2rem !important;
  }

  .m-sm-9 {
    margin: 2.25rem !important;
  }

  .m-sm-10 {
    margin: 2.5rem !important;
  }

  .m-sm-11 {
    margin: 2.75rem !important;
  }

  .m-sm-12 {
    margin: 3rem !important;
  }

  .m-sm-13 {
    margin: 3.25rem !important;
  }

  .m-sm-14 {
    margin: 3.5rem !important;
  }

  .m-sm-15 {
    margin: 3.75rem !important;
  }

  .m-sm-16 {
    margin: 4rem !important;
  }

  .m-sm-17 {
    margin: 4.25rem !important;
  }

  .m-sm-18 {
    margin: 4.5rem !important;
  }

  .m-sm-19 {
    margin: 4.75rem !important;
  }

  .m-sm-20 {
    margin: 5rem !important;
  }

  .m-sm-auto {
    margin: auto !important;
  }

  .mx-sm-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .mx-sm-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }

  .mx-sm-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }

  .mx-sm-3 {
    margin-right: 0.75rem !important;
    margin-left: 0.75rem !important;
  }

  .mx-sm-4 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }

  .mx-sm-5 {
    margin-right: 1.25rem !important;
    margin-left: 1.25rem !important;
  }

  .mx-sm-6 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }

  .mx-sm-7 {
    margin-right: 1.75rem !important;
    margin-left: 1.75rem !important;
  }

  .mx-sm-8 {
    margin-right: 2rem !important;
    margin-left: 2rem !important;
  }

  .mx-sm-9 {
    margin-right: 2.25rem !important;
    margin-left: 2.25rem !important;
  }

  .mx-sm-10 {
    margin-right: 2.5rem !important;
    margin-left: 2.5rem !important;
  }

  .mx-sm-11 {
    margin-right: 2.75rem !important;
    margin-left: 2.75rem !important;
  }

  .mx-sm-12 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }

  .mx-sm-13 {
    margin-right: 3.25rem !important;
    margin-left: 3.25rem !important;
  }

  .mx-sm-14 {
    margin-right: 3.5rem !important;
    margin-left: 3.5rem !important;
  }

  .mx-sm-15 {
    margin-right: 3.75rem !important;
    margin-left: 3.75rem !important;
  }

  .mx-sm-16 {
    margin-right: 4rem !important;
    margin-left: 4rem !important;
  }

  .mx-sm-17 {
    margin-right: 4.25rem !important;
    margin-left: 4.25rem !important;
  }

  .mx-sm-18 {
    margin-right: 4.5rem !important;
    margin-left: 4.5rem !important;
  }

  .mx-sm-19 {
    margin-right: 4.75rem !important;
    margin-left: 4.75rem !important;
  }

  .mx-sm-20 {
    margin-right: 5rem !important;
    margin-left: 5rem !important;
  }

  .mx-sm-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .my-sm-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-sm-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }

  .my-sm-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .my-sm-3 {
    margin-top: 0.75rem !important;
    margin-bottom: 0.75rem !important;
  }

  .my-sm-4 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-sm-5 {
    margin-top: 1.25rem !important;
    margin-bottom: 1.25rem !important;
  }

  .my-sm-6 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-sm-7 {
    margin-top: 1.75rem !important;
    margin-bottom: 1.75rem !important;
  }

  .my-sm-8 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }

  .my-sm-9 {
    margin-top: 2.25rem !important;
    margin-bottom: 2.25rem !important;
  }

  .my-sm-10 {
    margin-top: 2.5rem !important;
    margin-bottom: 2.5rem !important;
  }

  .my-sm-11 {
    margin-top: 2.75rem !important;
    margin-bottom: 2.75rem !important;
  }

  .my-sm-12 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-sm-13 {
    margin-top: 3.25rem !important;
    margin-bottom: 3.25rem !important;
  }

  .my-sm-14 {
    margin-top: 3.5rem !important;
    margin-bottom: 3.5rem !important;
  }

  .my-sm-15 {
    margin-top: 3.75rem !important;
    margin-bottom: 3.75rem !important;
  }

  .my-sm-16 {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important;
  }

  .my-sm-17 {
    margin-top: 4.25rem !important;
    margin-bottom: 4.25rem !important;
  }

  .my-sm-18 {
    margin-top: 4.5rem !important;
    margin-bottom: 4.5rem !important;
  }

  .my-sm-19 {
    margin-top: 4.75rem !important;
    margin-bottom: 4.75rem !important;
  }

  .my-sm-20 {
    margin-top: 5rem !important;
    margin-bottom: 5rem !important;
  }

  .my-sm-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-sm-0 {
    margin-top: 0 !important;
  }

  .mt-sm-1 {
    margin-top: 0.25rem !important;
  }

  .mt-sm-2 {
    margin-top: 0.5rem !important;
  }

  .mt-sm-3 {
    margin-top: 0.75rem !important;
  }

  .mt-sm-4 {
    margin-top: 1rem !important;
  }

  .mt-sm-5 {
    margin-top: 1.25rem !important;
  }

  .mt-sm-6 {
    margin-top: 1.5rem !important;
  }

  .mt-sm-7 {
    margin-top: 1.75rem !important;
  }

  .mt-sm-8 {
    margin-top: 2rem !important;
  }

  .mt-sm-9 {
    margin-top: 2.25rem !important;
  }

  .mt-sm-10 {
    margin-top: 2.5rem !important;
  }

  .mt-sm-11 {
    margin-top: 2.75rem !important;
  }

  .mt-sm-12 {
    margin-top: 3rem !important;
  }

  .mt-sm-13 {
    margin-top: 3.25rem !important;
  }

  .mt-sm-14 {
    margin-top: 3.5rem !important;
  }

  .mt-sm-15 {
    margin-top: 3.75rem !important;
  }

  .mt-sm-16 {
    margin-top: 4rem !important;
  }

  .mt-sm-17 {
    margin-top: 4.25rem !important;
  }

  .mt-sm-18 {
    margin-top: 4.5rem !important;
  }

  .mt-sm-19 {
    margin-top: 4.75rem !important;
  }

  .mt-sm-20 {
    margin-top: 5rem !important;
  }

  .mt-sm-auto {
    margin-top: auto !important;
  }

  .me-sm-0 {
    margin-right: 0 !important;
  }

  .me-sm-1 {
    margin-right: 0.25rem !important;
  }

  .me-sm-2 {
    margin-right: 0.5rem !important;
  }

  .me-sm-3 {
    margin-right: 0.75rem !important;
  }

  .me-sm-4 {
    margin-right: 1rem !important;
  }

  .me-sm-5 {
    margin-right: 1.25rem !important;
  }

  .me-sm-6 {
    margin-right: 1.5rem !important;
  }

  .me-sm-7 {
    margin-right: 1.75rem !important;
  }

  .me-sm-8 {
    margin-right: 2rem !important;
  }

  .me-sm-9 {
    margin-right: 2.25rem !important;
  }

  .me-sm-10 {
    margin-right: 2.5rem !important;
  }

  .me-sm-11 {
    margin-right: 2.75rem !important;
  }

  .me-sm-12 {
    margin-right: 3rem !important;
  }

  .me-sm-13 {
    margin-right: 3.25rem !important;
  }

  .me-sm-14 {
    margin-right: 3.5rem !important;
  }

  .me-sm-15 {
    margin-right: 3.75rem !important;
  }

  .me-sm-16 {
    margin-right: 4rem !important;
  }

  .me-sm-17 {
    margin-right: 4.25rem !important;
  }

  .me-sm-18 {
    margin-right: 4.5rem !important;
  }

  .me-sm-19 {
    margin-right: 4.75rem !important;
  }

  .me-sm-20 {
    margin-right: 5rem !important;
  }

  .me-sm-auto {
    margin-right: auto !important;
  }

  .mb-sm-0 {
    margin-bottom: 0 !important;
  }

  .mb-sm-1 {
    margin-bottom: 0.25rem !important;
  }

  .mb-sm-2 {
    margin-bottom: 0.5rem !important;
  }

  .mb-sm-3 {
    margin-bottom: 0.75rem !important;
  }

  .mb-sm-4 {
    margin-bottom: 1rem !important;
  }

  .mb-sm-5 {
    margin-bottom: 1.25rem !important;
  }

  .mb-sm-6 {
    margin-bottom: 1.5rem !important;
  }

  .mb-sm-7 {
    margin-bottom: 1.75rem !important;
  }

  .mb-sm-8 {
    margin-bottom: 2rem !important;
  }

  .mb-sm-9 {
    margin-bottom: 2.25rem !important;
  }

  .mb-sm-10 {
    margin-bottom: 2.5rem !important;
  }

  .mb-sm-11 {
    margin-bottom: 2.75rem !important;
  }

  .mb-sm-12 {
    margin-bottom: 3rem !important;
  }

  .mb-sm-13 {
    margin-bottom: 3.25rem !important;
  }

  .mb-sm-14 {
    margin-bottom: 3.5rem !important;
  }

  .mb-sm-15 {
    margin-bottom: 3.75rem !important;
  }

  .mb-sm-16 {
    margin-bottom: 4rem !important;
  }

  .mb-sm-17 {
    margin-bottom: 4.25rem !important;
  }

  .mb-sm-18 {
    margin-bottom: 4.5rem !important;
  }

  .mb-sm-19 {
    margin-bottom: 4.75rem !important;
  }

  .mb-sm-20 {
    margin-bottom: 5rem !important;
  }

  .mb-sm-auto {
    margin-bottom: auto !important;
  }

  .ms-sm-0 {
    margin-left: 0 !important;
  }

  .ms-sm-1 {
    margin-left: 0.25rem !important;
  }

  .ms-sm-2 {
    margin-left: 0.5rem !important;
  }

  .ms-sm-3 {
    margin-left: 0.75rem !important;
  }

  .ms-sm-4 {
    margin-left: 1rem !important;
  }

  .ms-sm-5 {
    margin-left: 1.25rem !important;
  }

  .ms-sm-6 {
    margin-left: 1.5rem !important;
  }

  .ms-sm-7 {
    margin-left: 1.75rem !important;
  }

  .ms-sm-8 {
    margin-left: 2rem !important;
  }

  .ms-sm-9 {
    margin-left: 2.25rem !important;
  }

  .ms-sm-10 {
    margin-left: 2.5rem !important;
  }

  .ms-sm-11 {
    margin-left: 2.75rem !important;
  }

  .ms-sm-12 {
    margin-left: 3rem !important;
  }

  .ms-sm-13 {
    margin-left: 3.25rem !important;
  }

  .ms-sm-14 {
    margin-left: 3.5rem !important;
  }

  .ms-sm-15 {
    margin-left: 3.75rem !important;
  }

  .ms-sm-16 {
    margin-left: 4rem !important;
  }

  .ms-sm-17 {
    margin-left: 4.25rem !important;
  }

  .ms-sm-18 {
    margin-left: 4.5rem !important;
  }

  .ms-sm-19 {
    margin-left: 4.75rem !important;
  }

  .ms-sm-20 {
    margin-left: 5rem !important;
  }

  .ms-sm-auto {
    margin-left: auto !important;
  }

  .m-sm-n1 {
    margin: -0.25rem !important;
  }

  .m-sm-n2 {
    margin: -0.5rem !important;
  }

  .m-sm-n3 {
    margin: -0.75rem !important;
  }

  .m-sm-n4 {
    margin: -1rem !important;
  }

  .m-sm-n5 {
    margin: -1.25rem !important;
  }

  .m-sm-n6 {
    margin: -1.5rem !important;
  }

  .m-sm-n7 {
    margin: -1.75rem !important;
  }

  .m-sm-n8 {
    margin: -2rem !important;
  }

  .m-sm-n9 {
    margin: -2.25rem !important;
  }

  .m-sm-n10 {
    margin: -2.5rem !important;
  }

  .m-sm-n11 {
    margin: -2.75rem !important;
  }

  .m-sm-n12 {
    margin: -3rem !important;
  }

  .m-sm-n13 {
    margin: -3.25rem !important;
  }

  .m-sm-n14 {
    margin: -3.5rem !important;
  }

  .m-sm-n15 {
    margin: -3.75rem !important;
  }

  .m-sm-n16 {
    margin: -4rem !important;
  }

  .m-sm-n17 {
    margin: -4.25rem !important;
  }

  .m-sm-n18 {
    margin: -4.5rem !important;
  }

  .m-sm-n19 {
    margin: -4.75rem !important;
  }

  .m-sm-n20 {
    margin: -5rem !important;
  }

  .mx-sm-n1 {
    margin-right: -0.25rem !important;
    margin-left: -0.25rem !important;
  }

  .mx-sm-n2 {
    margin-right: -0.5rem !important;
    margin-left: -0.5rem !important;
  }

  .mx-sm-n3 {
    margin-right: -0.75rem !important;
    margin-left: -0.75rem !important;
  }

  .mx-sm-n4 {
    margin-right: -1rem !important;
    margin-left: -1rem !important;
  }

  .mx-sm-n5 {
    margin-right: -1.25rem !important;
    margin-left: -1.25rem !important;
  }

  .mx-sm-n6 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important;
  }

  .mx-sm-n7 {
    margin-right: -1.75rem !important;
    margin-left: -1.75rem !important;
  }

  .mx-sm-n8 {
    margin-right: -2rem !important;
    margin-left: -2rem !important;
  }

  .mx-sm-n9 {
    margin-right: -2.25rem !important;
    margin-left: -2.25rem !important;
  }

  .mx-sm-n10 {
    margin-right: -2.5rem !important;
    margin-left: -2.5rem !important;
  }

  .mx-sm-n11 {
    margin-right: -2.75rem !important;
    margin-left: -2.75rem !important;
  }

  .mx-sm-n12 {
    margin-right: -3rem !important;
    margin-left: -3rem !important;
  }

  .mx-sm-n13 {
    margin-right: -3.25rem !important;
    margin-left: -3.25rem !important;
  }

  .mx-sm-n14 {
    margin-right: -3.5rem !important;
    margin-left: -3.5rem !important;
  }

  .mx-sm-n15 {
    margin-right: -3.75rem !important;
    margin-left: -3.75rem !important;
  }

  .mx-sm-n16 {
    margin-right: -4rem !important;
    margin-left: -4rem !important;
  }

  .mx-sm-n17 {
    margin-right: -4.25rem !important;
    margin-left: -4.25rem !important;
  }

  .mx-sm-n18 {
    margin-right: -4.5rem !important;
    margin-left: -4.5rem !important;
  }

  .mx-sm-n19 {
    margin-right: -4.75rem !important;
    margin-left: -4.75rem !important;
  }

  .mx-sm-n20 {
    margin-right: -5rem !important;
    margin-left: -5rem !important;
  }

  .my-sm-n1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important;
  }

  .my-sm-n2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important;
  }

  .my-sm-n3 {
    margin-top: -0.75rem !important;
    margin-bottom: -0.75rem !important;
  }

  .my-sm-n4 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }

  .my-sm-n5 {
    margin-top: -1.25rem !important;
    margin-bottom: -1.25rem !important;
  }

  .my-sm-n6 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }

  .my-sm-n7 {
    margin-top: -1.75rem !important;
    margin-bottom: -1.75rem !important;
  }

  .my-sm-n8 {
    margin-top: -2rem !important;
    margin-bottom: -2rem !important;
  }

  .my-sm-n9 {
    margin-top: -2.25rem !important;
    margin-bottom: -2.25rem !important;
  }

  .my-sm-n10 {
    margin-top: -2.5rem !important;
    margin-bottom: -2.5rem !important;
  }

  .my-sm-n11 {
    margin-top: -2.75rem !important;
    margin-bottom: -2.75rem !important;
  }

  .my-sm-n12 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }

  .my-sm-n13 {
    margin-top: -3.25rem !important;
    margin-bottom: -3.25rem !important;
  }

  .my-sm-n14 {
    margin-top: -3.5rem !important;
    margin-bottom: -3.5rem !important;
  }

  .my-sm-n15 {
    margin-top: -3.75rem !important;
    margin-bottom: -3.75rem !important;
  }

  .my-sm-n16 {
    margin-top: -4rem !important;
    margin-bottom: -4rem !important;
  }

  .my-sm-n17 {
    margin-top: -4.25rem !important;
    margin-bottom: -4.25rem !important;
  }

  .my-sm-n18 {
    margin-top: -4.5rem !important;
    margin-bottom: -4.5rem !important;
  }

  .my-sm-n19 {
    margin-top: -4.75rem !important;
    margin-bottom: -4.75rem !important;
  }

  .my-sm-n20 {
    margin-top: -5rem !important;
    margin-bottom: -5rem !important;
  }

  .mt-sm-n1 {
    margin-top: -0.25rem !important;
  }

  .mt-sm-n2 {
    margin-top: -0.5rem !important;
  }

  .mt-sm-n3 {
    margin-top: -0.75rem !important;
  }

  .mt-sm-n4 {
    margin-top: -1rem !important;
  }

  .mt-sm-n5 {
    margin-top: -1.25rem !important;
  }

  .mt-sm-n6 {
    margin-top: -1.5rem !important;
  }

  .mt-sm-n7 {
    margin-top: -1.75rem !important;
  }

  .mt-sm-n8 {
    margin-top: -2rem !important;
  }

  .mt-sm-n9 {
    margin-top: -2.25rem !important;
  }

  .mt-sm-n10 {
    margin-top: -2.5rem !important;
  }

  .mt-sm-n11 {
    margin-top: -2.75rem !important;
  }

  .mt-sm-n12 {
    margin-top: -3rem !important;
  }

  .mt-sm-n13 {
    margin-top: -3.25rem !important;
  }

  .mt-sm-n14 {
    margin-top: -3.5rem !important;
  }

  .mt-sm-n15 {
    margin-top: -3.75rem !important;
  }

  .mt-sm-n16 {
    margin-top: -4rem !important;
  }

  .mt-sm-n17 {
    margin-top: -4.25rem !important;
  }

  .mt-sm-n18 {
    margin-top: -4.5rem !important;
  }

  .mt-sm-n19 {
    margin-top: -4.75rem !important;
  }

  .mt-sm-n20 {
    margin-top: -5rem !important;
  }

  .me-sm-n1 {
    margin-right: -0.25rem !important;
  }

  .me-sm-n2 {
    margin-right: -0.5rem !important;
  }

  .me-sm-n3 {
    margin-right: -0.75rem !important;
  }

  .me-sm-n4 {
    margin-right: -1rem !important;
  }

  .me-sm-n5 {
    margin-right: -1.25rem !important;
  }

  .me-sm-n6 {
    margin-right: -1.5rem !important;
  }

  .me-sm-n7 {
    margin-right: -1.75rem !important;
  }

  .me-sm-n8 {
    margin-right: -2rem !important;
  }

  .me-sm-n9 {
    margin-right: -2.25rem !important;
  }

  .me-sm-n10 {
    margin-right: -2.5rem !important;
  }

  .me-sm-n11 {
    margin-right: -2.75rem !important;
  }

  .me-sm-n12 {
    margin-right: -3rem !important;
  }

  .me-sm-n13 {
    margin-right: -3.25rem !important;
  }

  .me-sm-n14 {
    margin-right: -3.5rem !important;
  }

  .me-sm-n15 {
    margin-right: -3.75rem !important;
  }

  .me-sm-n16 {
    margin-right: -4rem !important;
  }

  .me-sm-n17 {
    margin-right: -4.25rem !important;
  }

  .me-sm-n18 {
    margin-right: -4.5rem !important;
  }

  .me-sm-n19 {
    margin-right: -4.75rem !important;
  }

  .me-sm-n20 {
    margin-right: -5rem !important;
  }

  .mb-sm-n1 {
    margin-bottom: -0.25rem !important;
  }

  .mb-sm-n2 {
    margin-bottom: -0.5rem !important;
  }

  .mb-sm-n3 {
    margin-bottom: -0.75rem !important;
  }

  .mb-sm-n4 {
    margin-bottom: -1rem !important;
  }

  .mb-sm-n5 {
    margin-bottom: -1.25rem !important;
  }

  .mb-sm-n6 {
    margin-bottom: -1.5rem !important;
  }

  .mb-sm-n7 {
    margin-bottom: -1.75rem !important;
  }

  .mb-sm-n8 {
    margin-bottom: -2rem !important;
  }

  .mb-sm-n9 {
    margin-bottom: -2.25rem !important;
  }

  .mb-sm-n10 {
    margin-bottom: -2.5rem !important;
  }

  .mb-sm-n11 {
    margin-bottom: -2.75rem !important;
  }

  .mb-sm-n12 {
    margin-bottom: -3rem !important;
  }

  .mb-sm-n13 {
    margin-bottom: -3.25rem !important;
  }

  .mb-sm-n14 {
    margin-bottom: -3.5rem !important;
  }

  .mb-sm-n15 {
    margin-bottom: -3.75rem !important;
  }

  .mb-sm-n16 {
    margin-bottom: -4rem !important;
  }

  .mb-sm-n17 {
    margin-bottom: -4.25rem !important;
  }

  .mb-sm-n18 {
    margin-bottom: -4.5rem !important;
  }

  .mb-sm-n19 {
    margin-bottom: -4.75rem !important;
  }

  .mb-sm-n20 {
    margin-bottom: -5rem !important;
  }

  .ms-sm-n1 {
    margin-left: -0.25rem !important;
  }

  .ms-sm-n2 {
    margin-left: -0.5rem !important;
  }

  .ms-sm-n3 {
    margin-left: -0.75rem !important;
  }

  .ms-sm-n4 {
    margin-left: -1rem !important;
  }

  .ms-sm-n5 {
    margin-left: -1.25rem !important;
  }

  .ms-sm-n6 {
    margin-left: -1.5rem !important;
  }

  .ms-sm-n7 {
    margin-left: -1.75rem !important;
  }

  .ms-sm-n8 {
    margin-left: -2rem !important;
  }

  .ms-sm-n9 {
    margin-left: -2.25rem !important;
  }

  .ms-sm-n10 {
    margin-left: -2.5rem !important;
  }

  .ms-sm-n11 {
    margin-left: -2.75rem !important;
  }

  .ms-sm-n12 {
    margin-left: -3rem !important;
  }

  .ms-sm-n13 {
    margin-left: -3.25rem !important;
  }

  .ms-sm-n14 {
    margin-left: -3.5rem !important;
  }

  .ms-sm-n15 {
    margin-left: -3.75rem !important;
  }

  .ms-sm-n16 {
    margin-left: -4rem !important;
  }

  .ms-sm-n17 {
    margin-left: -4.25rem !important;
  }

  .ms-sm-n18 {
    margin-left: -4.5rem !important;
  }

  .ms-sm-n19 {
    margin-left: -4.75rem !important;
  }

  .ms-sm-n20 {
    margin-left: -5rem !important;
  }

  .p-sm-0 {
    padding: 0 !important;
  }

  .p-sm-1 {
    padding: 0.25rem !important;
  }

  .p-sm-2 {
    padding: 0.5rem !important;
  }

  .p-sm-3 {
    padding: 0.75rem !important;
  }

  .p-sm-4 {
    padding: 1rem !important;
  }

  .p-sm-5 {
    padding: 1.25rem !important;
  }

  .p-sm-6 {
    padding: 1.5rem !important;
  }

  .p-sm-7 {
    padding: 1.75rem !important;
  }

  .p-sm-8 {
    padding: 2rem !important;
  }

  .p-sm-9 {
    padding: 2.25rem !important;
  }

  .p-sm-10 {
    padding: 2.5rem !important;
  }

  .p-sm-11 {
    padding: 2.75rem !important;
  }

  .p-sm-12 {
    padding: 3rem !important;
  }

  .p-sm-13 {
    padding: 3.25rem !important;
  }

  .p-sm-14 {
    padding: 3.5rem !important;
  }

  .p-sm-15 {
    padding: 3.75rem !important;
  }

  .p-sm-16 {
    padding: 4rem !important;
  }

  .p-sm-17 {
    padding: 4.25rem !important;
  }

  .p-sm-18 {
    padding: 4.5rem !important;
  }

  .p-sm-19 {
    padding: 4.75rem !important;
  }

  .p-sm-20 {
    padding: 5rem !important;
  }

  .px-sm-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .px-sm-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }

  .px-sm-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }

  .px-sm-3 {
    padding-right: 0.75rem !important;
    padding-left: 0.75rem !important;
  }

  .px-sm-4 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }

  .px-sm-5 {
    padding-right: 1.25rem !important;
    padding-left: 1.25rem !important;
  }

  .px-sm-6 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }

  .px-sm-7 {
    padding-right: 1.75rem !important;
    padding-left: 1.75rem !important;
  }

  .px-sm-8 {
    padding-right: 2rem !important;
    padding-left: 2rem !important;
  }

  .px-sm-9 {
    padding-right: 2.25rem !important;
    padding-left: 2.25rem !important;
  }

  .px-sm-10 {
    padding-right: 2.5rem !important;
    padding-left: 2.5rem !important;
  }

  .px-sm-11 {
    padding-right: 2.75rem !important;
    padding-left: 2.75rem !important;
  }

  .px-sm-12 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }

  .px-sm-13 {
    padding-right: 3.25rem !important;
    padding-left: 3.25rem !important;
  }

  .px-sm-14 {
    padding-right: 3.5rem !important;
    padding-left: 3.5rem !important;
  }

  .px-sm-15 {
    padding-right: 3.75rem !important;
    padding-left: 3.75rem !important;
  }

  .px-sm-16 {
    padding-right: 4rem !important;
    padding-left: 4rem !important;
  }

  .px-sm-17 {
    padding-right: 4.25rem !important;
    padding-left: 4.25rem !important;
  }

  .px-sm-18 {
    padding-right: 4.5rem !important;
    padding-left: 4.5rem !important;
  }

  .px-sm-19 {
    padding-right: 4.75rem !important;
    padding-left: 4.75rem !important;
  }

  .px-sm-20 {
    padding-right: 5rem !important;
    padding-left: 5rem !important;
  }

  .py-sm-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-sm-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }

  .py-sm-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .py-sm-3 {
    padding-top: 0.75rem !important;
    padding-bottom: 0.75rem !important;
  }

  .py-sm-4 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-sm-5 {
    padding-top: 1.25rem !important;
    padding-bottom: 1.25rem !important;
  }

  .py-sm-6 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-sm-7 {
    padding-top: 1.75rem !important;
    padding-bottom: 1.75rem !important;
  }

  .py-sm-8 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }

  .py-sm-9 {
    padding-top: 2.25rem !important;
    padding-bottom: 2.25rem !important;
  }

  .py-sm-10 {
    padding-top: 2.5rem !important;
    padding-bottom: 2.5rem !important;
  }

  .py-sm-11 {
    padding-top: 2.75rem !important;
    padding-bottom: 2.75rem !important;
  }

  .py-sm-12 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .py-sm-13 {
    padding-top: 3.25rem !important;
    padding-bottom: 3.25rem !important;
  }

  .py-sm-14 {
    padding-top: 3.5rem !important;
    padding-bottom: 3.5rem !important;
  }

  .py-sm-15 {
    padding-top: 3.75rem !important;
    padding-bottom: 3.75rem !important;
  }

  .py-sm-16 {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important;
  }

  .py-sm-17 {
    padding-top: 4.25rem !important;
    padding-bottom: 4.25rem !important;
  }

  .py-sm-18 {
    padding-top: 4.5rem !important;
    padding-bottom: 4.5rem !important;
  }

  .py-sm-19 {
    padding-top: 4.75rem !important;
    padding-bottom: 4.75rem !important;
  }

  .py-sm-20 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
  }

  .pt-sm-0 {
    padding-top: 0 !important;
  }

  .pt-sm-1 {
    padding-top: 0.25rem !important;
  }

  .pt-sm-2 {
    padding-top: 0.5rem !important;
  }

  .pt-sm-3 {
    padding-top: 0.75rem !important;
  }

  .pt-sm-4 {
    padding-top: 1rem !important;
  }

  .pt-sm-5 {
    padding-top: 1.25rem !important;
  }

  .pt-sm-6 {
    padding-top: 1.5rem !important;
  }

  .pt-sm-7 {
    padding-top: 1.75rem !important;
  }

  .pt-sm-8 {
    padding-top: 2rem !important;
  }

  .pt-sm-9 {
    padding-top: 2.25rem !important;
  }

  .pt-sm-10 {
    padding-top: 2.5rem !important;
  }

  .pt-sm-11 {
    padding-top: 2.75rem !important;
  }

  .pt-sm-12 {
    padding-top: 3rem !important;
  }

  .pt-sm-13 {
    padding-top: 3.25rem !important;
  }

  .pt-sm-14 {
    padding-top: 3.5rem !important;
  }

  .pt-sm-15 {
    padding-top: 3.75rem !important;
  }

  .pt-sm-16 {
    padding-top: 4rem !important;
  }

  .pt-sm-17 {
    padding-top: 4.25rem !important;
  }

  .pt-sm-18 {
    padding-top: 4.5rem !important;
  }

  .pt-sm-19 {
    padding-top: 4.75rem !important;
  }

  .pt-sm-20 {
    padding-top: 5rem !important;
  }

  .pe-sm-0 {
    padding-right: 0 !important;
  }

  .pe-sm-1 {
    padding-right: 0.25rem !important;
  }

  .pe-sm-2 {
    padding-right: 0.5rem !important;
  }

  .pe-sm-3 {
    padding-right: 0.75rem !important;
  }

  .pe-sm-4 {
    padding-right: 1rem !important;
  }

  .pe-sm-5 {
    padding-right: 1.25rem !important;
  }

  .pe-sm-6 {
    padding-right: 1.5rem !important;
  }

  .pe-sm-7 {
    padding-right: 1.75rem !important;
  }

  .pe-sm-8 {
    padding-right: 2rem !important;
  }

  .pe-sm-9 {
    padding-right: 2.25rem !important;
  }

  .pe-sm-10 {
    padding-right: 2.5rem !important;
  }

  .pe-sm-11 {
    padding-right: 2.75rem !important;
  }

  .pe-sm-12 {
    padding-right: 3rem !important;
  }

  .pe-sm-13 {
    padding-right: 3.25rem !important;
  }

  .pe-sm-14 {
    padding-right: 3.5rem !important;
  }

  .pe-sm-15 {
    padding-right: 3.75rem !important;
  }

  .pe-sm-16 {
    padding-right: 4rem !important;
  }

  .pe-sm-17 {
    padding-right: 4.25rem !important;
  }

  .pe-sm-18 {
    padding-right: 4.5rem !important;
  }

  .pe-sm-19 {
    padding-right: 4.75rem !important;
  }

  .pe-sm-20 {
    padding-right: 5rem !important;
  }

  .pb-sm-0 {
    padding-bottom: 0 !important;
  }

  .pb-sm-1 {
    padding-bottom: 0.25rem !important;
  }

  .pb-sm-2 {
    padding-bottom: 0.5rem !important;
  }

  .pb-sm-3 {
    padding-bottom: 0.75rem !important;
  }

  .pb-sm-4 {
    padding-bottom: 1rem !important;
  }

  .pb-sm-5 {
    padding-bottom: 1.25rem !important;
  }

  .pb-sm-6 {
    padding-bottom: 1.5rem !important;
  }

  .pb-sm-7 {
    padding-bottom: 1.75rem !important;
  }

  .pb-sm-8 {
    padding-bottom: 2rem !important;
  }

  .pb-sm-9 {
    padding-bottom: 2.25rem !important;
  }

  .pb-sm-10 {
    padding-bottom: 2.5rem !important;
  }

  .pb-sm-11 {
    padding-bottom: 2.75rem !important;
  }

  .pb-sm-12 {
    padding-bottom: 3rem !important;
  }

  .pb-sm-13 {
    padding-bottom: 3.25rem !important;
  }

  .pb-sm-14 {
    padding-bottom: 3.5rem !important;
  }

  .pb-sm-15 {
    padding-bottom: 3.75rem !important;
  }

  .pb-sm-16 {
    padding-bottom: 4rem !important;
  }

  .pb-sm-17 {
    padding-bottom: 4.25rem !important;
  }

  .pb-sm-18 {
    padding-bottom: 4.5rem !important;
  }

  .pb-sm-19 {
    padding-bottom: 4.75rem !important;
  }

  .pb-sm-20 {
    padding-bottom: 5rem !important;
  }

  .ps-sm-0 {
    padding-left: 0 !important;
  }

  .ps-sm-1 {
    padding-left: 0.25rem !important;
  }

  .ps-sm-2 {
    padding-left: 0.5rem !important;
  }

  .ps-sm-3 {
    padding-left: 0.75rem !important;
  }

  .ps-sm-4 {
    padding-left: 1rem !important;
  }

  .ps-sm-5 {
    padding-left: 1.25rem !important;
  }

  .ps-sm-6 {
    padding-left: 1.5rem !important;
  }

  .ps-sm-7 {
    padding-left: 1.75rem !important;
  }

  .ps-sm-8 {
    padding-left: 2rem !important;
  }

  .ps-sm-9 {
    padding-left: 2.25rem !important;
  }

  .ps-sm-10 {
    padding-left: 2.5rem !important;
  }

  .ps-sm-11 {
    padding-left: 2.75rem !important;
  }

  .ps-sm-12 {
    padding-left: 3rem !important;
  }

  .ps-sm-13 {
    padding-left: 3.25rem !important;
  }

  .ps-sm-14 {
    padding-left: 3.5rem !important;
  }

  .ps-sm-15 {
    padding-left: 3.75rem !important;
  }

  .ps-sm-16 {
    padding-left: 4rem !important;
  }

  .ps-sm-17 {
    padding-left: 4.25rem !important;
  }

  .ps-sm-18 {
    padding-left: 4.5rem !important;
  }

  .ps-sm-19 {
    padding-left: 4.75rem !important;
  }

  .ps-sm-20 {
    padding-left: 5rem !important;
  }

  .fs-sm-1 {
    font-size: calc(1.3rem + 0.6vw) !important;
  }

  .fs-sm-2 {
    font-size: calc(1.275rem + 0.3vw) !important;
  }

  .fs-sm-3 {
    font-size: calc(1.26rem + 0.12vw) !important;
  }

  .fs-sm-4 {
    font-size: 1.25rem !important;
  }

  .fs-sm-5 {
    font-size: 1.15rem !important;
  }

  .fs-sm-6 {
    font-size: 1.075rem !important;
  }

  .fs-sm-7 {
    font-size: 0.95rem !important;
  }

  .fs-sm-8 {
    font-size: 0.85rem !important;
  }

  .fs-sm-9 {
    font-size: 0.75rem !important;
  }

  .fs-sm-10 {
    font-size: 0.5rem !important;
  }

  .fs-sm-base {
    font-size: 1rem !important;
  }

  .fs-sm-fluid {
    font-size: 100% !important;
  }

  .fs-sm-2x {
    font-size: calc(1.325rem + 0.9vw) !important;
  }

  .fs-sm-2qx {
    font-size: calc(1.35rem + 1.2vw) !important;
  }

  .fs-sm-2hx {
    font-size: calc(1.375rem + 1.5vw) !important;
  }

  .fs-sm-2tx {
    font-size: calc(1.4rem + 1.8vw) !important;
  }

  .fs-sm-3x {
    font-size: calc(1.425rem + 2.1vw) !important;
  }

  .fs-sm-3qx {
    font-size: calc(1.45rem + 2.4vw) !important;
  }

  .fs-sm-3hx {
    font-size: calc(1.475rem + 2.7vw) !important;
  }

  .fs-sm-3tx {
    font-size: calc(1.5rem + 3vw) !important;
  }

  .fs-sm-4x {
    font-size: calc(1.525rem + 3.3vw) !important;
  }

  .fs-sm-4qx {
    font-size: calc(1.55rem + 3.6vw) !important;
  }

  .fs-sm-4hx {
    font-size: calc(1.575rem + 3.9vw) !important;
  }

  .fs-sm-4tx {
    font-size: calc(1.6rem + 4.2vw) !important;
  }

  .fs-sm-5x {
    font-size: calc(1.625rem + 4.5vw) !important;
  }

  .fs-sm-5qx {
    font-size: calc(1.65rem + 4.8vw) !important;
  }

  .fs-sm-5hx {
    font-size: calc(1.675rem + 5.1vw) !important;
  }

  .fs-sm-5tx {
    font-size: calc(1.7rem + 5.4vw) !important;
  }

  .text-sm-start {
    text-align: left !important;
  }

  .text-sm-end {
    text-align: right !important;
  }

  .text-sm-center {
    text-align: center !important;
  }

  .min-w-sm-unset {
    min-width: unset !important;
  }

  .min-w-sm-25 {
    min-width: 25% !important;
  }

  .min-w-sm-50 {
    min-width: 50% !important;
  }

  .min-w-sm-75 {
    min-width: 75% !important;
  }

  .min-w-sm-100 {
    min-width: 100% !important;
  }

  .min-w-sm-auto {
    min-width: auto !important;
  }

  .min-w-sm-1px {
    min-width: 1px !important;
  }

  .min-w-sm-2px {
    min-width: 2px !important;
  }

  .min-w-sm-3px {
    min-width: 3px !important;
  }

  .min-w-sm-4px {
    min-width: 4px !important;
  }

  .min-w-sm-5px {
    min-width: 5px !important;
  }

  .min-w-sm-6px {
    min-width: 6px !important;
  }

  .min-w-sm-7px {
    min-width: 7px !important;
  }

  .min-w-sm-8px {
    min-width: 8px !important;
  }

  .min-w-sm-9px {
    min-width: 9px !important;
  }

  .min-w-sm-10px {
    min-width: 10px !important;
  }

  .min-w-sm-15px {
    min-width: 15px !important;
  }

  .min-w-sm-20px {
    min-width: 20px !important;
  }

  .min-w-sm-25px {
    min-width: 25px !important;
  }

  .min-w-sm-30px {
    min-width: 30px !important;
  }

  .min-w-sm-35px {
    min-width: 35px !important;
  }

  .min-w-sm-40px {
    min-width: 40px !important;
  }

  .min-w-sm-45px {
    min-width: 45px !important;
  }

  .min-w-sm-50px {
    min-width: 50px !important;
  }

  .min-w-sm-55px {
    min-width: 55px !important;
  }

  .min-w-sm-60px {
    min-width: 60px !important;
  }

  .min-w-sm-65px {
    min-width: 65px !important;
  }

  .min-w-sm-70px {
    min-width: 70px !important;
  }

  .min-w-sm-75px {
    min-width: 75px !important;
  }

  .min-w-sm-80px {
    min-width: 80px !important;
  }

  .min-w-sm-85px {
    min-width: 85px !important;
  }

  .min-w-sm-90px {
    min-width: 90px !important;
  }

  .min-w-sm-95px {
    min-width: 95px !important;
  }

  .min-w-sm-100px {
    min-width: 100px !important;
  }

  .min-w-sm-125px {
    min-width: 125px !important;
  }

  .min-w-sm-150px {
    min-width: 150px !important;
  }

  .min-w-sm-175px {
    min-width: 175px !important;
  }

  .min-w-sm-200px {
    min-width: 200px !important;
  }

  .min-w-sm-225px {
    min-width: 225px !important;
  }

  .min-w-sm-250px {
    min-width: 250px !important;
  }

  .min-w-sm-275px {
    min-width: 275px !important;
  }

  .min-w-sm-300px {
    min-width: 300px !important;
  }

  .min-w-sm-325px {
    min-width: 325px !important;
  }

  .min-w-sm-350px {
    min-width: 350px !important;
  }

  .min-w-sm-375px {
    min-width: 375px !important;
  }

  .min-w-sm-400px {
    min-width: 400px !important;
  }

  .min-w-sm-425px {
    min-width: 425px !important;
  }

  .min-w-sm-450px {
    min-width: 450px !important;
  }

  .min-w-sm-475px {
    min-width: 475px !important;
  }

  .min-w-sm-500px {
    min-width: 500px !important;
  }

  .min-w-sm-550px {
    min-width: 550px !important;
  }

  .min-w-sm-600px {
    min-width: 600px !important;
  }

  .min-w-sm-650px {
    min-width: 650px !important;
  }

  .min-w-sm-700px {
    min-width: 700px !important;
  }

  .min-w-sm-750px {
    min-width: 750px !important;
  }

  .min-w-sm-800px {
    min-width: 800px !important;
  }

  .min-w-sm-850px {
    min-width: 850px !important;
  }

  .min-w-sm-900px {
    min-width: 900px !important;
  }

  .min-w-sm-950px {
    min-width: 950px !important;
  }

  .min-w-sm-1000px {
    min-width: 1000px !important;
  }

  .min-h-sm-unset {
    min-height: unset !important;
  }

  .min-h-sm-25 {
    min-height: 25% !important;
  }

  .min-h-sm-50 {
    min-height: 50% !important;
  }

  .min-h-sm-75 {
    min-height: 75% !important;
  }

  .min-h-sm-100 {
    min-height: 100% !important;
  }

  .min-h-sm-auto {
    min-height: auto !important;
  }

  .min-h-sm-1px {
    min-height: 1px !important;
  }

  .min-h-sm-2px {
    min-height: 2px !important;
  }

  .min-h-sm-3px {
    min-height: 3px !important;
  }

  .min-h-sm-4px {
    min-height: 4px !important;
  }

  .min-h-sm-5px {
    min-height: 5px !important;
  }

  .min-h-sm-6px {
    min-height: 6px !important;
  }

  .min-h-sm-7px {
    min-height: 7px !important;
  }

  .min-h-sm-8px {
    min-height: 8px !important;
  }

  .min-h-sm-9px {
    min-height: 9px !important;
  }

  .min-h-sm-10px {
    min-height: 10px !important;
  }

  .min-h-sm-15px {
    min-height: 15px !important;
  }

  .min-h-sm-20px {
    min-height: 20px !important;
  }

  .min-h-sm-25px {
    min-height: 25px !important;
  }

  .min-h-sm-30px {
    min-height: 30px !important;
  }

  .min-h-sm-35px {
    min-height: 35px !important;
  }

  .min-h-sm-40px {
    min-height: 40px !important;
  }

  .min-h-sm-45px {
    min-height: 45px !important;
  }

  .min-h-sm-50px {
    min-height: 50px !important;
  }

  .min-h-sm-55px {
    min-height: 55px !important;
  }

  .min-h-sm-60px {
    min-height: 60px !important;
  }

  .min-h-sm-65px {
    min-height: 65px !important;
  }

  .min-h-sm-70px {
    min-height: 70px !important;
  }

  .min-h-sm-75px {
    min-height: 75px !important;
  }

  .min-h-sm-80px {
    min-height: 80px !important;
  }

  .min-h-sm-85px {
    min-height: 85px !important;
  }

  .min-h-sm-90px {
    min-height: 90px !important;
  }

  .min-h-sm-95px {
    min-height: 95px !important;
  }

  .min-h-sm-100px {
    min-height: 100px !important;
  }

  .min-h-sm-125px {
    min-height: 125px !important;
  }

  .min-h-sm-150px {
    min-height: 150px !important;
  }

  .min-h-sm-175px {
    min-height: 175px !important;
  }

  .min-h-sm-200px {
    min-height: 200px !important;
  }

  .min-h-sm-225px {
    min-height: 225px !important;
  }

  .min-h-sm-250px {
    min-height: 250px !important;
  }

  .min-h-sm-275px {
    min-height: 275px !important;
  }

  .min-h-sm-300px {
    min-height: 300px !important;
  }

  .min-h-sm-325px {
    min-height: 325px !important;
  }

  .min-h-sm-350px {
    min-height: 350px !important;
  }

  .min-h-sm-375px {
    min-height: 375px !important;
  }

  .min-h-sm-400px {
    min-height: 400px !important;
  }

  .min-h-sm-425px {
    min-height: 425px !important;
  }

  .min-h-sm-450px {
    min-height: 450px !important;
  }

  .min-h-sm-475px {
    min-height: 475px !important;
  }

  .min-h-sm-500px {
    min-height: 500px !important;
  }

  .min-h-sm-550px {
    min-height: 550px !important;
  }

  .min-h-sm-600px {
    min-height: 600px !important;
  }

  .min-h-sm-650px {
    min-height: 650px !important;
  }

  .min-h-sm-700px {
    min-height: 700px !important;
  }

  .min-h-sm-750px {
    min-height: 750px !important;
  }

  .min-h-sm-800px {
    min-height: 800px !important;
  }

  .min-h-sm-850px {
    min-height: 850px !important;
  }

  .min-h-sm-900px {
    min-height: 900px !important;
  }

  .min-h-sm-950px {
    min-height: 950px !important;
  }

  .min-h-sm-1000px {
    min-height: 1000px !important;
  }
}
@media (min-width: 768px) {
  .float-md-start {
    float: left !important;
  }

  .float-md-end {
    float: right !important;
  }

  .float-md-none {
    float: none !important;
  }

  .d-md-inline {
    display: inline !important;
  }

  .d-md-inline-block {
    display: inline-block !important;
  }

  .d-md-block {
    display: block !important;
  }

  .d-md-grid {
    display: grid !important;
  }

  .d-md-table {
    display: table !important;
  }

  .d-md-table-row {
    display: table-row !important;
  }

  .d-md-table-cell {
    display: table-cell !important;
  }

  .d-md-flex {
    display: flex !important;
  }

  .d-md-inline-flex {
    display: inline-flex !important;
  }

  .d-md-none {
    display: none !important;
  }

  .w-md-unset {
    width: unset !important;
  }

  .w-md-25 {
    width: 25% !important;
  }

  .w-md-50 {
    width: 50% !important;
  }

  .w-md-75 {
    width: 75% !important;
  }

  .w-md-100 {
    width: 100% !important;
  }

  .w-md-auto {
    width: auto !important;
  }

  .w-md-1px {
    width: 1px !important;
  }

  .w-md-2px {
    width: 2px !important;
  }

  .w-md-3px {
    width: 3px !important;
  }

  .w-md-4px {
    width: 4px !important;
  }

  .w-md-5px {
    width: 5px !important;
  }

  .w-md-6px {
    width: 6px !important;
  }

  .w-md-7px {
    width: 7px !important;
  }

  .w-md-8px {
    width: 8px !important;
  }

  .w-md-9px {
    width: 9px !important;
  }

  .w-md-10px {
    width: 10px !important;
  }

  .w-md-15px {
    width: 15px !important;
  }

  .w-md-20px {
    width: 20px !important;
  }

  .w-md-25px {
    width: 25px !important;
  }

  .w-md-30px {
    width: 30px !important;
  }

  .w-md-35px {
    width: 35px !important;
  }

  .w-md-40px {
    width: 40px !important;
  }

  .w-md-45px {
    width: 45px !important;
  }

  .w-md-50px {
    width: 50px !important;
  }

  .w-md-55px {
    width: 55px !important;
  }

  .w-md-60px {
    width: 60px !important;
  }

  .w-md-65px {
    width: 65px !important;
  }

  .w-md-70px {
    width: 70px !important;
  }

  .w-md-75px {
    width: 75px !important;
  }

  .w-md-80px {
    width: 80px !important;
  }

  .w-md-85px {
    width: 85px !important;
  }

  .w-md-90px {
    width: 90px !important;
  }

  .w-md-95px {
    width: 95px !important;
  }

  .w-md-100px {
    width: 100px !important;
  }

  .w-md-125px {
    width: 125px !important;
  }

  .w-md-150px {
    width: 150px !important;
  }

  .w-md-175px {
    width: 175px !important;
  }

  .w-md-200px {
    width: 200px !important;
  }

  .w-md-225px {
    width: 225px !important;
  }

  .w-md-250px {
    width: 250px !important;
  }

  .w-md-275px {
    width: 275px !important;
  }

  .w-md-300px {
    width: 300px !important;
  }

  .w-md-325px {
    width: 325px !important;
  }

  .w-md-350px {
    width: 350px !important;
  }

  .w-md-375px {
    width: 375px !important;
  }

  .w-md-400px {
    width: 400px !important;
  }

  .w-md-425px {
    width: 425px !important;
  }

  .w-md-450px {
    width: 450px !important;
  }

  .w-md-475px {
    width: 475px !important;
  }

  .w-md-500px {
    width: 500px !important;
  }

  .w-md-550px {
    width: 550px !important;
  }

  .w-md-600px {
    width: 600px !important;
  }

  .w-md-650px {
    width: 650px !important;
  }

  .w-md-700px {
    width: 700px !important;
  }

  .w-md-750px {
    width: 750px !important;
  }

  .w-md-800px {
    width: 800px !important;
  }

  .w-md-850px {
    width: 850px !important;
  }

  .w-md-900px {
    width: 900px !important;
  }

  .w-md-950px {
    width: 950px !important;
  }

  .w-md-1000px {
    width: 1000px !important;
  }

  .mw-md-unset {
    max-width: unset !important;
  }

  .mw-md-25 {
    max-width: 25% !important;
  }

  .mw-md-50 {
    max-width: 50% !important;
  }

  .mw-md-75 {
    max-width: 75% !important;
  }

  .mw-md-100 {
    max-width: 100% !important;
  }

  .mw-md-auto {
    max-width: auto !important;
  }

  .mw-md-1px {
    max-width: 1px !important;
  }

  .mw-md-2px {
    max-width: 2px !important;
  }

  .mw-md-3px {
    max-width: 3px !important;
  }

  .mw-md-4px {
    max-width: 4px !important;
  }

  .mw-md-5px {
    max-width: 5px !important;
  }

  .mw-md-6px {
    max-width: 6px !important;
  }

  .mw-md-7px {
    max-width: 7px !important;
  }

  .mw-md-8px {
    max-width: 8px !important;
  }

  .mw-md-9px {
    max-width: 9px !important;
  }

  .mw-md-10px {
    max-width: 10px !important;
  }

  .mw-md-15px {
    max-width: 15px !important;
  }

  .mw-md-20px {
    max-width: 20px !important;
  }

  .mw-md-25px {
    max-width: 25px !important;
  }

  .mw-md-30px {
    max-width: 30px !important;
  }

  .mw-md-35px {
    max-width: 35px !important;
  }

  .mw-md-40px {
    max-width: 40px !important;
  }

  .mw-md-45px {
    max-width: 45px !important;
  }

  .mw-md-50px {
    max-width: 50px !important;
  }

  .mw-md-55px {
    max-width: 55px !important;
  }

  .mw-md-60px {
    max-width: 60px !important;
  }

  .mw-md-65px {
    max-width: 65px !important;
  }

  .mw-md-70px {
    max-width: 70px !important;
  }

  .mw-md-75px {
    max-width: 75px !important;
  }

  .mw-md-80px {
    max-width: 80px !important;
  }

  .mw-md-85px {
    max-width: 85px !important;
  }

  .mw-md-90px {
    max-width: 90px !important;
  }

  .mw-md-95px {
    max-width: 95px !important;
  }

  .mw-md-100px {
    max-width: 100px !important;
  }

  .mw-md-125px {
    max-width: 125px !important;
  }

  .mw-md-150px {
    max-width: 150px !important;
  }

  .mw-md-175px {
    max-width: 175px !important;
  }

  .mw-md-200px {
    max-width: 200px !important;
  }

  .mw-md-225px {
    max-width: 225px !important;
  }

  .mw-md-250px {
    max-width: 250px !important;
  }

  .mw-md-275px {
    max-width: 275px !important;
  }

  .mw-md-300px {
    max-width: 300px !important;
  }

  .mw-md-325px {
    max-width: 325px !important;
  }

  .mw-md-350px {
    max-width: 350px !important;
  }

  .mw-md-375px {
    max-width: 375px !important;
  }

  .mw-md-400px {
    max-width: 400px !important;
  }

  .mw-md-425px {
    max-width: 425px !important;
  }

  .mw-md-450px {
    max-width: 450px !important;
  }

  .mw-md-475px {
    max-width: 475px !important;
  }

  .mw-md-500px {
    max-width: 500px !important;
  }

  .mw-md-550px {
    max-width: 550px !important;
  }

  .mw-md-600px {
    max-width: 600px !important;
  }

  .mw-md-650px {
    max-width: 650px !important;
  }

  .mw-md-700px {
    max-width: 700px !important;
  }

  .mw-md-750px {
    max-width: 750px !important;
  }

  .mw-md-800px {
    max-width: 800px !important;
  }

  .mw-md-850px {
    max-width: 850px !important;
  }

  .mw-md-900px {
    max-width: 900px !important;
  }

  .mw-md-950px {
    max-width: 950px !important;
  }

  .mw-md-1000px {
    max-width: 1000px !important;
  }

  .h-md-unset {
    height: unset !important;
  }

  .h-md-25 {
    height: 25% !important;
  }

  .h-md-50 {
    height: 50% !important;
  }

  .h-md-75 {
    height: 75% !important;
  }

  .h-md-100 {
    height: 100% !important;
  }

  .h-md-auto {
    height: auto !important;
  }

  .h-md-1px {
    height: 1px !important;
  }

  .h-md-2px {
    height: 2px !important;
  }

  .h-md-3px {
    height: 3px !important;
  }

  .h-md-4px {
    height: 4px !important;
  }

  .h-md-5px {
    height: 5px !important;
  }

  .h-md-6px {
    height: 6px !important;
  }

  .h-md-7px {
    height: 7px !important;
  }

  .h-md-8px {
    height: 8px !important;
  }

  .h-md-9px {
    height: 9px !important;
  }

  .h-md-10px {
    height: 10px !important;
  }

  .h-md-15px {
    height: 15px !important;
  }

  .h-md-20px {
    height: 20px !important;
  }

  .h-md-25px {
    height: 25px !important;
  }

  .h-md-30px {
    height: 30px !important;
  }

  .h-md-35px {
    height: 35px !important;
  }

  .h-md-40px {
    height: 40px !important;
  }

  .h-md-45px {
    height: 45px !important;
  }

  .h-md-50px {
    height: 50px !important;
  }

  .h-md-55px {
    height: 55px !important;
  }

  .h-md-60px {
    height: 60px !important;
  }

  .h-md-65px {
    height: 65px !important;
  }

  .h-md-70px {
    height: 70px !important;
  }

  .h-md-75px {
    height: 75px !important;
  }

  .h-md-80px {
    height: 80px !important;
  }

  .h-md-85px {
    height: 85px !important;
  }

  .h-md-90px {
    height: 90px !important;
  }

  .h-md-95px {
    height: 95px !important;
  }

  .h-md-100px {
    height: 100px !important;
  }

  .h-md-125px {
    height: 125px !important;
  }

  .h-md-150px {
    height: 150px !important;
  }

  .h-md-175px {
    height: 175px !important;
  }

  .h-md-200px {
    height: 200px !important;
  }

  .h-md-225px {
    height: 225px !important;
  }

  .h-md-250px {
    height: 250px !important;
  }

  .h-md-275px {
    height: 275px !important;
  }

  .h-md-300px {
    height: 300px !important;
  }

  .h-md-325px {
    height: 325px !important;
  }

  .h-md-350px {
    height: 350px !important;
  }

  .h-md-375px {
    height: 375px !important;
  }

  .h-md-400px {
    height: 400px !important;
  }

  .h-md-425px {
    height: 425px !important;
  }

  .h-md-450px {
    height: 450px !important;
  }

  .h-md-475px {
    height: 475px !important;
  }

  .h-md-500px {
    height: 500px !important;
  }

  .h-md-550px {
    height: 550px !important;
  }

  .h-md-600px {
    height: 600px !important;
  }

  .h-md-650px {
    height: 650px !important;
  }

  .h-md-700px {
    height: 700px !important;
  }

  .h-md-750px {
    height: 750px !important;
  }

  .h-md-800px {
    height: 800px !important;
  }

  .h-md-850px {
    height: 850px !important;
  }

  .h-md-900px {
    height: 900px !important;
  }

  .h-md-950px {
    height: 950px !important;
  }

  .h-md-1000px {
    height: 1000px !important;
  }

  .mh-md-unset {
    max-height: unset !important;
  }

  .mh-md-25 {
    max-height: 25% !important;
  }

  .mh-md-50 {
    max-height: 50% !important;
  }

  .mh-md-75 {
    max-height: 75% !important;
  }

  .mh-md-100 {
    max-height: 100% !important;
  }

  .mh-md-auto {
    max-height: auto !important;
  }

  .mh-md-1px {
    max-height: 1px !important;
  }

  .mh-md-2px {
    max-height: 2px !important;
  }

  .mh-md-3px {
    max-height: 3px !important;
  }

  .mh-md-4px {
    max-height: 4px !important;
  }

  .mh-md-5px {
    max-height: 5px !important;
  }

  .mh-md-6px {
    max-height: 6px !important;
  }

  .mh-md-7px {
    max-height: 7px !important;
  }

  .mh-md-8px {
    max-height: 8px !important;
  }

  .mh-md-9px {
    max-height: 9px !important;
  }

  .mh-md-10px {
    max-height: 10px !important;
  }

  .mh-md-15px {
    max-height: 15px !important;
  }

  .mh-md-20px {
    max-height: 20px !important;
  }

  .mh-md-25px {
    max-height: 25px !important;
  }

  .mh-md-30px {
    max-height: 30px !important;
  }

  .mh-md-35px {
    max-height: 35px !important;
  }

  .mh-md-40px {
    max-height: 40px !important;
  }

  .mh-md-45px {
    max-height: 45px !important;
  }

  .mh-md-50px {
    max-height: 50px !important;
  }

  .mh-md-55px {
    max-height: 55px !important;
  }

  .mh-md-60px {
    max-height: 60px !important;
  }

  .mh-md-65px {
    max-height: 65px !important;
  }

  .mh-md-70px {
    max-height: 70px !important;
  }

  .mh-md-75px {
    max-height: 75px !important;
  }

  .mh-md-80px {
    max-height: 80px !important;
  }

  .mh-md-85px {
    max-height: 85px !important;
  }

  .mh-md-90px {
    max-height: 90px !important;
  }

  .mh-md-95px {
    max-height: 95px !important;
  }

  .mh-md-100px {
    max-height: 100px !important;
  }

  .mh-md-125px {
    max-height: 125px !important;
  }

  .mh-md-150px {
    max-height: 150px !important;
  }

  .mh-md-175px {
    max-height: 175px !important;
  }

  .mh-md-200px {
    max-height: 200px !important;
  }

  .mh-md-225px {
    max-height: 225px !important;
  }

  .mh-md-250px {
    max-height: 250px !important;
  }

  .mh-md-275px {
    max-height: 275px !important;
  }

  .mh-md-300px {
    max-height: 300px !important;
  }

  .mh-md-325px {
    max-height: 325px !important;
  }

  .mh-md-350px {
    max-height: 350px !important;
  }

  .mh-md-375px {
    max-height: 375px !important;
  }

  .mh-md-400px {
    max-height: 400px !important;
  }

  .mh-md-425px {
    max-height: 425px !important;
  }

  .mh-md-450px {
    max-height: 450px !important;
  }

  .mh-md-475px {
    max-height: 475px !important;
  }

  .mh-md-500px {
    max-height: 500px !important;
  }

  .mh-md-550px {
    max-height: 550px !important;
  }

  .mh-md-600px {
    max-height: 600px !important;
  }

  .mh-md-650px {
    max-height: 650px !important;
  }

  .mh-md-700px {
    max-height: 700px !important;
  }

  .mh-md-750px {
    max-height: 750px !important;
  }

  .mh-md-800px {
    max-height: 800px !important;
  }

  .mh-md-850px {
    max-height: 850px !important;
  }

  .mh-md-900px {
    max-height: 900px !important;
  }

  .mh-md-950px {
    max-height: 950px !important;
  }

  .mh-md-1000px {
    max-height: 1000px !important;
  }

  .flex-md-fill {
    flex: 1 1 auto !important;
  }

  .flex-md-row {
    flex-direction: row !important;
  }

  .flex-md-column {
    flex-direction: column !important;
  }

  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-md-wrap {
    flex-wrap: wrap !important;
  }

  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .gap-md-0 {
    gap: 0 !important;
  }

  .gap-md-1 {
    gap: 0.25rem !important;
  }

  .gap-md-2 {
    gap: 0.5rem !important;
  }

  .gap-md-3 {
    gap: 0.75rem !important;
  }

  .gap-md-4 {
    gap: 1rem !important;
  }

  .gap-md-5 {
    gap: 1.25rem !important;
  }

  .gap-md-6 {
    gap: 1.5rem !important;
  }

  .gap-md-7 {
    gap: 1.75rem !important;
  }

  .gap-md-8 {
    gap: 2rem !important;
  }

  .gap-md-9 {
    gap: 2.25rem !important;
  }

  .gap-md-10 {
    gap: 2.5rem !important;
  }

  .gap-md-11 {
    gap: 2.75rem !important;
  }

  .gap-md-12 {
    gap: 3rem !important;
  }

  .gap-md-13 {
    gap: 3.25rem !important;
  }

  .gap-md-14 {
    gap: 3.5rem !important;
  }

  .gap-md-15 {
    gap: 3.75rem !important;
  }

  .gap-md-16 {
    gap: 4rem !important;
  }

  .gap-md-17 {
    gap: 4.25rem !important;
  }

  .gap-md-18 {
    gap: 4.5rem !important;
  }

  .gap-md-19 {
    gap: 4.75rem !important;
  }

  .gap-md-20 {
    gap: 5rem !important;
  }

  .justify-content-md-start {
    justify-content: flex-start !important;
  }

  .justify-content-md-end {
    justify-content: flex-end !important;
  }

  .justify-content-md-center {
    justify-content: center !important;
  }

  .justify-content-md-between {
    justify-content: space-between !important;
  }

  .justify-content-md-around {
    justify-content: space-around !important;
  }

  .justify-content-md-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-md-start {
    align-items: flex-start !important;
  }

  .align-items-md-end {
    align-items: flex-end !important;
  }

  .align-items-md-center {
    align-items: center !important;
  }

  .align-items-md-baseline {
    align-items: baseline !important;
  }

  .align-items-md-stretch {
    align-items: stretch !important;
  }

  .align-content-md-start {
    align-content: flex-start !important;
  }

  .align-content-md-end {
    align-content: flex-end !important;
  }

  .align-content-md-center {
    align-content: center !important;
  }

  .align-content-md-between {
    align-content: space-between !important;
  }

  .align-content-md-around {
    align-content: space-around !important;
  }

  .align-content-md-stretch {
    align-content: stretch !important;
  }

  .align-self-md-auto {
    align-self: auto !important;
  }

  .align-self-md-start {
    align-self: flex-start !important;
  }

  .align-self-md-end {
    align-self: flex-end !important;
  }

  .align-self-md-center {
    align-self: center !important;
  }

  .align-self-md-baseline {
    align-self: baseline !important;
  }

  .align-self-md-stretch {
    align-self: stretch !important;
  }

  .order-md-first {
    order: -1 !important;
  }

  .order-md-0 {
    order: 0 !important;
  }

  .order-md-1 {
    order: 1 !important;
  }

  .order-md-2 {
    order: 2 !important;
  }

  .order-md-3 {
    order: 3 !important;
  }

  .order-md-4 {
    order: 4 !important;
  }

  .order-md-5 {
    order: 5 !important;
  }

  .order-md-last {
    order: 6 !important;
  }

  .m-md-0 {
    margin: 0 !important;
  }

  .m-md-1 {
    margin: 0.25rem !important;
  }

  .m-md-2 {
    margin: 0.5rem !important;
  }

  .m-md-3 {
    margin: 0.75rem !important;
  }

  .m-md-4 {
    margin: 1rem !important;
  }

  .m-md-5 {
    margin: 1.25rem !important;
  }

  .m-md-6 {
    margin: 1.5rem !important;
  }

  .m-md-7 {
    margin: 1.75rem !important;
  }

  .m-md-8 {
    margin: 2rem !important;
  }

  .m-md-9 {
    margin: 2.25rem !important;
  }

  .m-md-10 {
    margin: 2.5rem !important;
  }

  .m-md-11 {
    margin: 2.75rem !important;
  }

  .m-md-12 {
    margin: 3rem !important;
  }

  .m-md-13 {
    margin: 3.25rem !important;
  }

  .m-md-14 {
    margin: 3.5rem !important;
  }

  .m-md-15 {
    margin: 3.75rem !important;
  }

  .m-md-16 {
    margin: 4rem !important;
  }

  .m-md-17 {
    margin: 4.25rem !important;
  }

  .m-md-18 {
    margin: 4.5rem !important;
  }

  .m-md-19 {
    margin: 4.75rem !important;
  }

  .m-md-20 {
    margin: 5rem !important;
  }

  .m-md-auto {
    margin: auto !important;
  }

  .mx-md-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .mx-md-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }

  .mx-md-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }

  .mx-md-3 {
    margin-right: 0.75rem !important;
    margin-left: 0.75rem !important;
  }

  .mx-md-4 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }

  .mx-md-5 {
    margin-right: 1.25rem !important;
    margin-left: 1.25rem !important;
  }

  .mx-md-6 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }

  .mx-md-7 {
    margin-right: 1.75rem !important;
    margin-left: 1.75rem !important;
  }

  .mx-md-8 {
    margin-right: 2rem !important;
    margin-left: 2rem !important;
  }

  .mx-md-9 {
    margin-right: 2.25rem !important;
    margin-left: 2.25rem !important;
  }

  .mx-md-10 {
    margin-right: 2.5rem !important;
    margin-left: 2.5rem !important;
  }

  .mx-md-11 {
    margin-right: 2.75rem !important;
    margin-left: 2.75rem !important;
  }

  .mx-md-12 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }

  .mx-md-13 {
    margin-right: 3.25rem !important;
    margin-left: 3.25rem !important;
  }

  .mx-md-14 {
    margin-right: 3.5rem !important;
    margin-left: 3.5rem !important;
  }

  .mx-md-15 {
    margin-right: 3.75rem !important;
    margin-left: 3.75rem !important;
  }

  .mx-md-16 {
    margin-right: 4rem !important;
    margin-left: 4rem !important;
  }

  .mx-md-17 {
    margin-right: 4.25rem !important;
    margin-left: 4.25rem !important;
  }

  .mx-md-18 {
    margin-right: 4.5rem !important;
    margin-left: 4.5rem !important;
  }

  .mx-md-19 {
    margin-right: 4.75rem !important;
    margin-left: 4.75rem !important;
  }

  .mx-md-20 {
    margin-right: 5rem !important;
    margin-left: 5rem !important;
  }

  .mx-md-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .my-md-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-md-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }

  .my-md-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .my-md-3 {
    margin-top: 0.75rem !important;
    margin-bottom: 0.75rem !important;
  }

  .my-md-4 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-md-5 {
    margin-top: 1.25rem !important;
    margin-bottom: 1.25rem !important;
  }

  .my-md-6 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-md-7 {
    margin-top: 1.75rem !important;
    margin-bottom: 1.75rem !important;
  }

  .my-md-8 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }

  .my-md-9 {
    margin-top: 2.25rem !important;
    margin-bottom: 2.25rem !important;
  }

  .my-md-10 {
    margin-top: 2.5rem !important;
    margin-bottom: 2.5rem !important;
  }

  .my-md-11 {
    margin-top: 2.75rem !important;
    margin-bottom: 2.75rem !important;
  }

  .my-md-12 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-md-13 {
    margin-top: 3.25rem !important;
    margin-bottom: 3.25rem !important;
  }

  .my-md-14 {
    margin-top: 3.5rem !important;
    margin-bottom: 3.5rem !important;
  }

  .my-md-15 {
    margin-top: 3.75rem !important;
    margin-bottom: 3.75rem !important;
  }

  .my-md-16 {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important;
  }

  .my-md-17 {
    margin-top: 4.25rem !important;
    margin-bottom: 4.25rem !important;
  }

  .my-md-18 {
    margin-top: 4.5rem !important;
    margin-bottom: 4.5rem !important;
  }

  .my-md-19 {
    margin-top: 4.75rem !important;
    margin-bottom: 4.75rem !important;
  }

  .my-md-20 {
    margin-top: 5rem !important;
    margin-bottom: 5rem !important;
  }

  .my-md-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-md-0 {
    margin-top: 0 !important;
  }

  .mt-md-1 {
    margin-top: 0.25rem !important;
  }

  .mt-md-2 {
    margin-top: 0.5rem !important;
  }

  .mt-md-3 {
    margin-top: 0.75rem !important;
  }

  .mt-md-4 {
    margin-top: 1rem !important;
  }

  .mt-md-5 {
    margin-top: 1.25rem !important;
  }

  .mt-md-6 {
    margin-top: 1.5rem !important;
  }

  .mt-md-7 {
    margin-top: 1.75rem !important;
  }

  .mt-md-8 {
    margin-top: 2rem !important;
  }

  .mt-md-9 {
    margin-top: 2.25rem !important;
  }

  .mt-md-10 {
    margin-top: 2.5rem !important;
  }

  .mt-md-11 {
    margin-top: 2.75rem !important;
  }

  .mt-md-12 {
    margin-top: 3rem !important;
  }

  .mt-md-13 {
    margin-top: 3.25rem !important;
  }

  .mt-md-14 {
    margin-top: 3.5rem !important;
  }

  .mt-md-15 {
    margin-top: 3.75rem !important;
  }

  .mt-md-16 {
    margin-top: 4rem !important;
  }

  .mt-md-17 {
    margin-top: 4.25rem !important;
  }

  .mt-md-18 {
    margin-top: 4.5rem !important;
  }

  .mt-md-19 {
    margin-top: 4.75rem !important;
  }

  .mt-md-20 {
    margin-top: 5rem !important;
  }

  .mt-md-auto {
    margin-top: auto !important;
  }

  .me-md-0 {
    margin-right: 0 !important;
  }

  .me-md-1 {
    margin-right: 0.25rem !important;
  }

  .me-md-2 {
    margin-right: 0.5rem !important;
  }

  .me-md-3 {
    margin-right: 0.75rem !important;
  }

  .me-md-4 {
    margin-right: 1rem !important;
  }

  .me-md-5 {
    margin-right: 1.25rem !important;
  }

  .me-md-6 {
    margin-right: 1.5rem !important;
  }

  .me-md-7 {
    margin-right: 1.75rem !important;
  }

  .me-md-8 {
    margin-right: 2rem !important;
  }

  .me-md-9 {
    margin-right: 2.25rem !important;
  }

  .me-md-10 {
    margin-right: 2.5rem !important;
  }

  .me-md-11 {
    margin-right: 2.75rem !important;
  }

  .me-md-12 {
    margin-right: 3rem !important;
  }

  .me-md-13 {
    margin-right: 3.25rem !important;
  }

  .me-md-14 {
    margin-right: 3.5rem !important;
  }

  .me-md-15 {
    margin-right: 3.75rem !important;
  }

  .me-md-16 {
    margin-right: 4rem !important;
  }

  .me-md-17 {
    margin-right: 4.25rem !important;
  }

  .me-md-18 {
    margin-right: 4.5rem !important;
  }

  .me-md-19 {
    margin-right: 4.75rem !important;
  }

  .me-md-20 {
    margin-right: 5rem !important;
  }

  .me-md-auto {
    margin-right: auto !important;
  }

  .mb-md-0 {
    margin-bottom: 0 !important;
  }

  .mb-md-1 {
    margin-bottom: 0.25rem !important;
  }

  .mb-md-2 {
    margin-bottom: 0.5rem !important;
  }

  .mb-md-3 {
    margin-bottom: 0.75rem !important;
  }

  .mb-md-4 {
    margin-bottom: 1rem !important;
  }

  .mb-md-5 {
    margin-bottom: 1.25rem !important;
  }

  .mb-md-6 {
    margin-bottom: 1.5rem !important;
  }

  .mb-md-7 {
    margin-bottom: 1.75rem !important;
  }

  .mb-md-8 {
    margin-bottom: 2rem !important;
  }

  .mb-md-9 {
    margin-bottom: 2.25rem !important;
  }

  .mb-md-10 {
    margin-bottom: 2.5rem !important;
  }

  .mb-md-11 {
    margin-bottom: 2.75rem !important;
  }

  .mb-md-12 {
    margin-bottom: 3rem !important;
  }

  .mb-md-13 {
    margin-bottom: 3.25rem !important;
  }

  .mb-md-14 {
    margin-bottom: 3.5rem !important;
  }

  .mb-md-15 {
    margin-bottom: 3.75rem !important;
  }

  .mb-md-16 {
    margin-bottom: 4rem !important;
  }

  .mb-md-17 {
    margin-bottom: 4.25rem !important;
  }

  .mb-md-18 {
    margin-bottom: 4.5rem !important;
  }

  .mb-md-19 {
    margin-bottom: 4.75rem !important;
  }

  .mb-md-20 {
    margin-bottom: 5rem !important;
  }

  .mb-md-auto {
    margin-bottom: auto !important;
  }

  .ms-md-0 {
    margin-left: 0 !important;
  }

  .ms-md-1 {
    margin-left: 0.25rem !important;
  }

  .ms-md-2 {
    margin-left: 0.5rem !important;
  }

  .ms-md-3 {
    margin-left: 0.75rem !important;
  }

  .ms-md-4 {
    margin-left: 1rem !important;
  }

  .ms-md-5 {
    margin-left: 1.25rem !important;
  }

  .ms-md-6 {
    margin-left: 1.5rem !important;
  }

  .ms-md-7 {
    margin-left: 1.75rem !important;
  }

  .ms-md-8 {
    margin-left: 2rem !important;
  }

  .ms-md-9 {
    margin-left: 2.25rem !important;
  }

  .ms-md-10 {
    margin-left: 2.5rem !important;
  }

  .ms-md-11 {
    margin-left: 2.75rem !important;
  }

  .ms-md-12 {
    margin-left: 3rem !important;
  }

  .ms-md-13 {
    margin-left: 3.25rem !important;
  }

  .ms-md-14 {
    margin-left: 3.5rem !important;
  }

  .ms-md-15 {
    margin-left: 3.75rem !important;
  }

  .ms-md-16 {
    margin-left: 4rem !important;
  }

  .ms-md-17 {
    margin-left: 4.25rem !important;
  }

  .ms-md-18 {
    margin-left: 4.5rem !important;
  }

  .ms-md-19 {
    margin-left: 4.75rem !important;
  }

  .ms-md-20 {
    margin-left: 5rem !important;
  }

  .ms-md-auto {
    margin-left: auto !important;
  }

  .m-md-n1 {
    margin: -0.25rem !important;
  }

  .m-md-n2 {
    margin: -0.5rem !important;
  }

  .m-md-n3 {
    margin: -0.75rem !important;
  }

  .m-md-n4 {
    margin: -1rem !important;
  }

  .m-md-n5 {
    margin: -1.25rem !important;
  }

  .m-md-n6 {
    margin: -1.5rem !important;
  }

  .m-md-n7 {
    margin: -1.75rem !important;
  }

  .m-md-n8 {
    margin: -2rem !important;
  }

  .m-md-n9 {
    margin: -2.25rem !important;
  }

  .m-md-n10 {
    margin: -2.5rem !important;
  }

  .m-md-n11 {
    margin: -2.75rem !important;
  }

  .m-md-n12 {
    margin: -3rem !important;
  }

  .m-md-n13 {
    margin: -3.25rem !important;
  }

  .m-md-n14 {
    margin: -3.5rem !important;
  }

  .m-md-n15 {
    margin: -3.75rem !important;
  }

  .m-md-n16 {
    margin: -4rem !important;
  }

  .m-md-n17 {
    margin: -4.25rem !important;
  }

  .m-md-n18 {
    margin: -4.5rem !important;
  }

  .m-md-n19 {
    margin: -4.75rem !important;
  }

  .m-md-n20 {
    margin: -5rem !important;
  }

  .mx-md-n1 {
    margin-right: -0.25rem !important;
    margin-left: -0.25rem !important;
  }

  .mx-md-n2 {
    margin-right: -0.5rem !important;
    margin-left: -0.5rem !important;
  }

  .mx-md-n3 {
    margin-right: -0.75rem !important;
    margin-left: -0.75rem !important;
  }

  .mx-md-n4 {
    margin-right: -1rem !important;
    margin-left: -1rem !important;
  }

  .mx-md-n5 {
    margin-right: -1.25rem !important;
    margin-left: -1.25rem !important;
  }

  .mx-md-n6 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important;
  }

  .mx-md-n7 {
    margin-right: -1.75rem !important;
    margin-left: -1.75rem !important;
  }

  .mx-md-n8 {
    margin-right: -2rem !important;
    margin-left: -2rem !important;
  }

  .mx-md-n9 {
    margin-right: -2.25rem !important;
    margin-left: -2.25rem !important;
  }

  .mx-md-n10 {
    margin-right: -2.5rem !important;
    margin-left: -2.5rem !important;
  }

  .mx-md-n11 {
    margin-right: -2.75rem !important;
    margin-left: -2.75rem !important;
  }

  .mx-md-n12 {
    margin-right: -3rem !important;
    margin-left: -3rem !important;
  }

  .mx-md-n13 {
    margin-right: -3.25rem !important;
    margin-left: -3.25rem !important;
  }

  .mx-md-n14 {
    margin-right: -3.5rem !important;
    margin-left: -3.5rem !important;
  }

  .mx-md-n15 {
    margin-right: -3.75rem !important;
    margin-left: -3.75rem !important;
  }

  .mx-md-n16 {
    margin-right: -4rem !important;
    margin-left: -4rem !important;
  }

  .mx-md-n17 {
    margin-right: -4.25rem !important;
    margin-left: -4.25rem !important;
  }

  .mx-md-n18 {
    margin-right: -4.5rem !important;
    margin-left: -4.5rem !important;
  }

  .mx-md-n19 {
    margin-right: -4.75rem !important;
    margin-left: -4.75rem !important;
  }

  .mx-md-n20 {
    margin-right: -5rem !important;
    margin-left: -5rem !important;
  }

  .my-md-n1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important;
  }

  .my-md-n2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important;
  }

  .my-md-n3 {
    margin-top: -0.75rem !important;
    margin-bottom: -0.75rem !important;
  }

  .my-md-n4 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }

  .my-md-n5 {
    margin-top: -1.25rem !important;
    margin-bottom: -1.25rem !important;
  }

  .my-md-n6 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }

  .my-md-n7 {
    margin-top: -1.75rem !important;
    margin-bottom: -1.75rem !important;
  }

  .my-md-n8 {
    margin-top: -2rem !important;
    margin-bottom: -2rem !important;
  }

  .my-md-n9 {
    margin-top: -2.25rem !important;
    margin-bottom: -2.25rem !important;
  }

  .my-md-n10 {
    margin-top: -2.5rem !important;
    margin-bottom: -2.5rem !important;
  }

  .my-md-n11 {
    margin-top: -2.75rem !important;
    margin-bottom: -2.75rem !important;
  }

  .my-md-n12 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }

  .my-md-n13 {
    margin-top: -3.25rem !important;
    margin-bottom: -3.25rem !important;
  }

  .my-md-n14 {
    margin-top: -3.5rem !important;
    margin-bottom: -3.5rem !important;
  }

  .my-md-n15 {
    margin-top: -3.75rem !important;
    margin-bottom: -3.75rem !important;
  }

  .my-md-n16 {
    margin-top: -4rem !important;
    margin-bottom: -4rem !important;
  }

  .my-md-n17 {
    margin-top: -4.25rem !important;
    margin-bottom: -4.25rem !important;
  }

  .my-md-n18 {
    margin-top: -4.5rem !important;
    margin-bottom: -4.5rem !important;
  }

  .my-md-n19 {
    margin-top: -4.75rem !important;
    margin-bottom: -4.75rem !important;
  }

  .my-md-n20 {
    margin-top: -5rem !important;
    margin-bottom: -5rem !important;
  }

  .mt-md-n1 {
    margin-top: -0.25rem !important;
  }

  .mt-md-n2 {
    margin-top: -0.5rem !important;
  }

  .mt-md-n3 {
    margin-top: -0.75rem !important;
  }

  .mt-md-n4 {
    margin-top: -1rem !important;
  }

  .mt-md-n5 {
    margin-top: -1.25rem !important;
  }

  .mt-md-n6 {
    margin-top: -1.5rem !important;
  }

  .mt-md-n7 {
    margin-top: -1.75rem !important;
  }

  .mt-md-n8 {
    margin-top: -2rem !important;
  }

  .mt-md-n9 {
    margin-top: -2.25rem !important;
  }

  .mt-md-n10 {
    margin-top: -2.5rem !important;
  }

  .mt-md-n11 {
    margin-top: -2.75rem !important;
  }

  .mt-md-n12 {
    margin-top: -3rem !important;
  }

  .mt-md-n13 {
    margin-top: -3.25rem !important;
  }

  .mt-md-n14 {
    margin-top: -3.5rem !important;
  }

  .mt-md-n15 {
    margin-top: -3.75rem !important;
  }

  .mt-md-n16 {
    margin-top: -4rem !important;
  }

  .mt-md-n17 {
    margin-top: -4.25rem !important;
  }

  .mt-md-n18 {
    margin-top: -4.5rem !important;
  }

  .mt-md-n19 {
    margin-top: -4.75rem !important;
  }

  .mt-md-n20 {
    margin-top: -5rem !important;
  }

  .me-md-n1 {
    margin-right: -0.25rem !important;
  }

  .me-md-n2 {
    margin-right: -0.5rem !important;
  }

  .me-md-n3 {
    margin-right: -0.75rem !important;
  }

  .me-md-n4 {
    margin-right: -1rem !important;
  }

  .me-md-n5 {
    margin-right: -1.25rem !important;
  }

  .me-md-n6 {
    margin-right: -1.5rem !important;
  }

  .me-md-n7 {
    margin-right: -1.75rem !important;
  }

  .me-md-n8 {
    margin-right: -2rem !important;
  }

  .me-md-n9 {
    margin-right: -2.25rem !important;
  }

  .me-md-n10 {
    margin-right: -2.5rem !important;
  }

  .me-md-n11 {
    margin-right: -2.75rem !important;
  }

  .me-md-n12 {
    margin-right: -3rem !important;
  }

  .me-md-n13 {
    margin-right: -3.25rem !important;
  }

  .me-md-n14 {
    margin-right: -3.5rem !important;
  }

  .me-md-n15 {
    margin-right: -3.75rem !important;
  }

  .me-md-n16 {
    margin-right: -4rem !important;
  }

  .me-md-n17 {
    margin-right: -4.25rem !important;
  }

  .me-md-n18 {
    margin-right: -4.5rem !important;
  }

  .me-md-n19 {
    margin-right: -4.75rem !important;
  }

  .me-md-n20 {
    margin-right: -5rem !important;
  }

  .mb-md-n1 {
    margin-bottom: -0.25rem !important;
  }

  .mb-md-n2 {
    margin-bottom: -0.5rem !important;
  }

  .mb-md-n3 {
    margin-bottom: -0.75rem !important;
  }

  .mb-md-n4 {
    margin-bottom: -1rem !important;
  }

  .mb-md-n5 {
    margin-bottom: -1.25rem !important;
  }

  .mb-md-n6 {
    margin-bottom: -1.5rem !important;
  }

  .mb-md-n7 {
    margin-bottom: -1.75rem !important;
  }

  .mb-md-n8 {
    margin-bottom: -2rem !important;
  }

  .mb-md-n9 {
    margin-bottom: -2.25rem !important;
  }

  .mb-md-n10 {
    margin-bottom: -2.5rem !important;
  }

  .mb-md-n11 {
    margin-bottom: -2.75rem !important;
  }

  .mb-md-n12 {
    margin-bottom: -3rem !important;
  }

  .mb-md-n13 {
    margin-bottom: -3.25rem !important;
  }

  .mb-md-n14 {
    margin-bottom: -3.5rem !important;
  }

  .mb-md-n15 {
    margin-bottom: -3.75rem !important;
  }

  .mb-md-n16 {
    margin-bottom: -4rem !important;
  }

  .mb-md-n17 {
    margin-bottom: -4.25rem !important;
  }

  .mb-md-n18 {
    margin-bottom: -4.5rem !important;
  }

  .mb-md-n19 {
    margin-bottom: -4.75rem !important;
  }

  .mb-md-n20 {
    margin-bottom: -5rem !important;
  }

  .ms-md-n1 {
    margin-left: -0.25rem !important;
  }

  .ms-md-n2 {
    margin-left: -0.5rem !important;
  }

  .ms-md-n3 {
    margin-left: -0.75rem !important;
  }

  .ms-md-n4 {
    margin-left: -1rem !important;
  }

  .ms-md-n5 {
    margin-left: -1.25rem !important;
  }

  .ms-md-n6 {
    margin-left: -1.5rem !important;
  }

  .ms-md-n7 {
    margin-left: -1.75rem !important;
  }

  .ms-md-n8 {
    margin-left: -2rem !important;
  }

  .ms-md-n9 {
    margin-left: -2.25rem !important;
  }

  .ms-md-n10 {
    margin-left: -2.5rem !important;
  }

  .ms-md-n11 {
    margin-left: -2.75rem !important;
  }

  .ms-md-n12 {
    margin-left: -3rem !important;
  }

  .ms-md-n13 {
    margin-left: -3.25rem !important;
  }

  .ms-md-n14 {
    margin-left: -3.5rem !important;
  }

  .ms-md-n15 {
    margin-left: -3.75rem !important;
  }

  .ms-md-n16 {
    margin-left: -4rem !important;
  }

  .ms-md-n17 {
    margin-left: -4.25rem !important;
  }

  .ms-md-n18 {
    margin-left: -4.5rem !important;
  }

  .ms-md-n19 {
    margin-left: -4.75rem !important;
  }

  .ms-md-n20 {
    margin-left: -5rem !important;
  }

  .p-md-0 {
    padding: 0 !important;
  }

  .p-md-1 {
    padding: 0.25rem !important;
  }

  .p-md-2 {
    padding: 0.5rem !important;
  }

  .p-md-3 {
    padding: 0.75rem !important;
  }

  .p-md-4 {
    padding: 1rem !important;
  }

  .p-md-5 {
    padding: 1.25rem !important;
  }

  .p-md-6 {
    padding: 1.5rem !important;
  }

  .p-md-7 {
    padding: 1.75rem !important;
  }

  .p-md-8 {
    padding: 2rem !important;
  }

  .p-md-9 {
    padding: 2.25rem !important;
  }

  .p-md-10 {
    padding: 2.5rem !important;
  }

  .p-md-11 {
    padding: 2.75rem !important;
  }

  .p-md-12 {
    padding: 3rem !important;
  }

  .p-md-13 {
    padding: 3.25rem !important;
  }

  .p-md-14 {
    padding: 3.5rem !important;
  }

  .p-md-15 {
    padding: 3.75rem !important;
  }

  .p-md-16 {
    padding: 4rem !important;
  }

  .p-md-17 {
    padding: 4.25rem !important;
  }

  .p-md-18 {
    padding: 4.5rem !important;
  }

  .p-md-19 {
    padding: 4.75rem !important;
  }

  .p-md-20 {
    padding: 5rem !important;
  }

  .px-md-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .px-md-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }

  .px-md-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }

  .px-md-3 {
    padding-right: 0.75rem !important;
    padding-left: 0.75rem !important;
  }

  .px-md-4 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }

  .px-md-5 {
    padding-right: 1.25rem !important;
    padding-left: 1.25rem !important;
  }

  .px-md-6 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }

  .px-md-7 {
    padding-right: 1.75rem !important;
    padding-left: 1.75rem !important;
  }

  .px-md-8 {
    padding-right: 2rem !important;
    padding-left: 2rem !important;
  }

  .px-md-9 {
    padding-right: 2.25rem !important;
    padding-left: 2.25rem !important;
  }

  .px-md-10 {
    padding-right: 2.5rem !important;
    padding-left: 2.5rem !important;
  }

  .px-md-11 {
    padding-right: 2.75rem !important;
    padding-left: 2.75rem !important;
  }

  .px-md-12 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }

  .px-md-13 {
    padding-right: 3.25rem !important;
    padding-left: 3.25rem !important;
  }

  .px-md-14 {
    padding-right: 3.5rem !important;
    padding-left: 3.5rem !important;
  }

  .px-md-15 {
    padding-right: 3.75rem !important;
    padding-left: 3.75rem !important;
  }

  .px-md-16 {
    padding-right: 4rem !important;
    padding-left: 4rem !important;
  }

  .px-md-17 {
    padding-right: 4.25rem !important;
    padding-left: 4.25rem !important;
  }

  .px-md-18 {
    padding-right: 4.5rem !important;
    padding-left: 4.5rem !important;
  }

  .px-md-19 {
    padding-right: 4.75rem !important;
    padding-left: 4.75rem !important;
  }

  .px-md-20 {
    padding-right: 5rem !important;
    padding-left: 5rem !important;
  }

  .py-md-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-md-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }

  .py-md-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .py-md-3 {
    padding-top: 0.75rem !important;
    padding-bottom: 0.75rem !important;
  }

  .py-md-4 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-md-5 {
    padding-top: 1.25rem !important;
    padding-bottom: 1.25rem !important;
  }

  .py-md-6 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-md-7 {
    padding-top: 1.75rem !important;
    padding-bottom: 1.75rem !important;
  }

  .py-md-8 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }

  .py-md-9 {
    padding-top: 2.25rem !important;
    padding-bottom: 2.25rem !important;
  }

  .py-md-10 {
    padding-top: 2.5rem !important;
    padding-bottom: 2.5rem !important;
  }

  .py-md-11 {
    padding-top: 2.75rem !important;
    padding-bottom: 2.75rem !important;
  }

  .py-md-12 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .py-md-13 {
    padding-top: 3.25rem !important;
    padding-bottom: 3.25rem !important;
  }

  .py-md-14 {
    padding-top: 3.5rem !important;
    padding-bottom: 3.5rem !important;
  }

  .py-md-15 {
    padding-top: 3.75rem !important;
    padding-bottom: 3.75rem !important;
  }

  .py-md-16 {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important;
  }

  .py-md-17 {
    padding-top: 4.25rem !important;
    padding-bottom: 4.25rem !important;
  }

  .py-md-18 {
    padding-top: 4.5rem !important;
    padding-bottom: 4.5rem !important;
  }

  .py-md-19 {
    padding-top: 4.75rem !important;
    padding-bottom: 4.75rem !important;
  }

  .py-md-20 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
  }

  .pt-md-0 {
    padding-top: 0 !important;
  }

  .pt-md-1 {
    padding-top: 0.25rem !important;
  }

  .pt-md-2 {
    padding-top: 0.5rem !important;
  }

  .pt-md-3 {
    padding-top: 0.75rem !important;
  }

  .pt-md-4 {
    padding-top: 1rem !important;
  }

  .pt-md-5 {
    padding-top: 1.25rem !important;
  }

  .pt-md-6 {
    padding-top: 1.5rem !important;
  }

  .pt-md-7 {
    padding-top: 1.75rem !important;
  }

  .pt-md-8 {
    padding-top: 2rem !important;
  }

  .pt-md-9 {
    padding-top: 2.25rem !important;
  }

  .pt-md-10 {
    padding-top: 2.5rem !important;
  }

  .pt-md-11 {
    padding-top: 2.75rem !important;
  }

  .pt-md-12 {
    padding-top: 3rem !important;
  }

  .pt-md-13 {
    padding-top: 3.25rem !important;
  }

  .pt-md-14 {
    padding-top: 3.5rem !important;
  }

  .pt-md-15 {
    padding-top: 3.75rem !important;
  }

  .pt-md-16 {
    padding-top: 4rem !important;
  }

  .pt-md-17 {
    padding-top: 4.25rem !important;
  }

  .pt-md-18 {
    padding-top: 4.5rem !important;
  }

  .pt-md-19 {
    padding-top: 4.75rem !important;
  }

  .pt-md-20 {
    padding-top: 5rem !important;
  }

  .pe-md-0 {
    padding-right: 0 !important;
  }

  .pe-md-1 {
    padding-right: 0.25rem !important;
  }

  .pe-md-2 {
    padding-right: 0.5rem !important;
  }

  .pe-md-3 {
    padding-right: 0.75rem !important;
  }

  .pe-md-4 {
    padding-right: 1rem !important;
  }

  .pe-md-5 {
    padding-right: 1.25rem !important;
  }

  .pe-md-6 {
    padding-right: 1.5rem !important;
  }

  .pe-md-7 {
    padding-right: 1.75rem !important;
  }

  .pe-md-8 {
    padding-right: 2rem !important;
  }

  .pe-md-9 {
    padding-right: 2.25rem !important;
  }

  .pe-md-10 {
    padding-right: 2.5rem !important;
  }

  .pe-md-11 {
    padding-right: 2.75rem !important;
  }

  .pe-md-12 {
    padding-right: 3rem !important;
  }

  .pe-md-13 {
    padding-right: 3.25rem !important;
  }

  .pe-md-14 {
    padding-right: 3.5rem !important;
  }

  .pe-md-15 {
    padding-right: 3.75rem !important;
  }

  .pe-md-16 {
    padding-right: 4rem !important;
  }

  .pe-md-17 {
    padding-right: 4.25rem !important;
  }

  .pe-md-18 {
    padding-right: 4.5rem !important;
  }

  .pe-md-19 {
    padding-right: 4.75rem !important;
  }

  .pe-md-20 {
    padding-right: 5rem !important;
  }

  .pb-md-0 {
    padding-bottom: 0 !important;
  }

  .pb-md-1 {
    padding-bottom: 0.25rem !important;
  }

  .pb-md-2 {
    padding-bottom: 0.5rem !important;
  }

  .pb-md-3 {
    padding-bottom: 0.75rem !important;
  }

  .pb-md-4 {
    padding-bottom: 1rem !important;
  }

  .pb-md-5 {
    padding-bottom: 1.25rem !important;
  }

  .pb-md-6 {
    padding-bottom: 1.5rem !important;
  }

  .pb-md-7 {
    padding-bottom: 1.75rem !important;
  }

  .pb-md-8 {
    padding-bottom: 2rem !important;
  }

  .pb-md-9 {
    padding-bottom: 2.25rem !important;
  }

  .pb-md-10 {
    padding-bottom: 2.5rem !important;
  }

  .pb-md-11 {
    padding-bottom: 2.75rem !important;
  }

  .pb-md-12 {
    padding-bottom: 3rem !important;
  }

  .pb-md-13 {
    padding-bottom: 3.25rem !important;
  }

  .pb-md-14 {
    padding-bottom: 3.5rem !important;
  }

  .pb-md-15 {
    padding-bottom: 3.75rem !important;
  }

  .pb-md-16 {
    padding-bottom: 4rem !important;
  }

  .pb-md-17 {
    padding-bottom: 4.25rem !important;
  }

  .pb-md-18 {
    padding-bottom: 4.5rem !important;
  }

  .pb-md-19 {
    padding-bottom: 4.75rem !important;
  }

  .pb-md-20 {
    padding-bottom: 5rem !important;
  }

  .ps-md-0 {
    padding-left: 0 !important;
  }

  .ps-md-1 {
    padding-left: 0.25rem !important;
  }

  .ps-md-2 {
    padding-left: 0.5rem !important;
  }

  .ps-md-3 {
    padding-left: 0.75rem !important;
  }

  .ps-md-4 {
    padding-left: 1rem !important;
  }

  .ps-md-5 {
    padding-left: 1.25rem !important;
  }

  .ps-md-6 {
    padding-left: 1.5rem !important;
  }

  .ps-md-7 {
    padding-left: 1.75rem !important;
  }

  .ps-md-8 {
    padding-left: 2rem !important;
  }

  .ps-md-9 {
    padding-left: 2.25rem !important;
  }

  .ps-md-10 {
    padding-left: 2.5rem !important;
  }

  .ps-md-11 {
    padding-left: 2.75rem !important;
  }

  .ps-md-12 {
    padding-left: 3rem !important;
  }

  .ps-md-13 {
    padding-left: 3.25rem !important;
  }

  .ps-md-14 {
    padding-left: 3.5rem !important;
  }

  .ps-md-15 {
    padding-left: 3.75rem !important;
  }

  .ps-md-16 {
    padding-left: 4rem !important;
  }

  .ps-md-17 {
    padding-left: 4.25rem !important;
  }

  .ps-md-18 {
    padding-left: 4.5rem !important;
  }

  .ps-md-19 {
    padding-left: 4.75rem !important;
  }

  .ps-md-20 {
    padding-left: 5rem !important;
  }

  .fs-md-1 {
    font-size: calc(1.3rem + 0.6vw) !important;
  }

  .fs-md-2 {
    font-size: calc(1.275rem + 0.3vw) !important;
  }

  .fs-md-3 {
    font-size: calc(1.26rem + 0.12vw) !important;
  }

  .fs-md-4 {
    font-size: 1.25rem !important;
  }

  .fs-md-5 {
    font-size: 1.15rem !important;
  }

  .fs-md-6 {
    font-size: 1.075rem !important;
  }

  .fs-md-7 {
    font-size: 0.95rem !important;
  }

  .fs-md-8 {
    font-size: 0.85rem !important;
  }

  .fs-md-9 {
    font-size: 0.75rem !important;
  }

  .fs-md-10 {
    font-size: 0.5rem !important;
  }

  .fs-md-base {
    font-size: 1rem !important;
  }

  .fs-md-fluid {
    font-size: 100% !important;
  }

  .fs-md-2x {
    font-size: calc(1.325rem + 0.9vw) !important;
  }

  .fs-md-2qx {
    font-size: calc(1.35rem + 1.2vw) !important;
  }

  .fs-md-2hx {
    font-size: calc(1.375rem + 1.5vw) !important;
  }

  .fs-md-2tx {
    font-size: calc(1.4rem + 1.8vw) !important;
  }

  .fs-md-3x {
    font-size: calc(1.425rem + 2.1vw) !important;
  }

  .fs-md-3qx {
    font-size: calc(1.45rem + 2.4vw) !important;
  }

  .fs-md-3hx {
    font-size: calc(1.475rem + 2.7vw) !important;
  }

  .fs-md-3tx {
    font-size: calc(1.5rem + 3vw) !important;
  }

  .fs-md-4x {
    font-size: calc(1.525rem + 3.3vw) !important;
  }

  .fs-md-4qx {
    font-size: calc(1.55rem + 3.6vw) !important;
  }

  .fs-md-4hx {
    font-size: calc(1.575rem + 3.9vw) !important;
  }

  .fs-md-4tx {
    font-size: calc(1.6rem + 4.2vw) !important;
  }

  .fs-md-5x {
    font-size: calc(1.625rem + 4.5vw) !important;
  }

  .fs-md-5qx {
    font-size: calc(1.65rem + 4.8vw) !important;
  }

  .fs-md-5hx {
    font-size: calc(1.675rem + 5.1vw) !important;
  }

  .fs-md-5tx {
    font-size: calc(1.7rem + 5.4vw) !important;
  }

  .text-md-start {
    text-align: left !important;
  }

  .text-md-end {
    text-align: right !important;
  }

  .text-md-center {
    text-align: center !important;
  }

  .min-w-md-unset {
    min-width: unset !important;
  }

  .min-w-md-25 {
    min-width: 25% !important;
  }

  .min-w-md-50 {
    min-width: 50% !important;
  }

  .min-w-md-75 {
    min-width: 75% !important;
  }

  .min-w-md-100 {
    min-width: 100% !important;
  }

  .min-w-md-auto {
    min-width: auto !important;
  }

  .min-w-md-1px {
    min-width: 1px !important;
  }

  .min-w-md-2px {
    min-width: 2px !important;
  }

  .min-w-md-3px {
    min-width: 3px !important;
  }

  .min-w-md-4px {
    min-width: 4px !important;
  }

  .min-w-md-5px {
    min-width: 5px !important;
  }

  .min-w-md-6px {
    min-width: 6px !important;
  }

  .min-w-md-7px {
    min-width: 7px !important;
  }

  .min-w-md-8px {
    min-width: 8px !important;
  }

  .min-w-md-9px {
    min-width: 9px !important;
  }

  .min-w-md-10px {
    min-width: 10px !important;
  }

  .min-w-md-15px {
    min-width: 15px !important;
  }

  .min-w-md-20px {
    min-width: 20px !important;
  }

  .min-w-md-25px {
    min-width: 25px !important;
  }

  .min-w-md-30px {
    min-width: 30px !important;
  }

  .min-w-md-35px {
    min-width: 35px !important;
  }

  .min-w-md-40px {
    min-width: 40px !important;
  }

  .min-w-md-45px {
    min-width: 45px !important;
  }

  .min-w-md-50px {
    min-width: 50px !important;
  }

  .min-w-md-55px {
    min-width: 55px !important;
  }

  .min-w-md-60px {
    min-width: 60px !important;
  }

  .min-w-md-65px {
    min-width: 65px !important;
  }

  .min-w-md-70px {
    min-width: 70px !important;
  }

  .min-w-md-75px {
    min-width: 75px !important;
  }

  .min-w-md-80px {
    min-width: 80px !important;
  }

  .min-w-md-85px {
    min-width: 85px !important;
  }

  .min-w-md-90px {
    min-width: 90px !important;
  }

  .min-w-md-95px {
    min-width: 95px !important;
  }

  .min-w-md-100px {
    min-width: 100px !important;
  }

  .min-w-md-125px {
    min-width: 125px !important;
  }

  .min-w-md-150px {
    min-width: 150px !important;
  }

  .min-w-md-175px {
    min-width: 175px !important;
  }

  .min-w-md-200px {
    min-width: 200px !important;
  }

  .min-w-md-225px {
    min-width: 225px !important;
  }

  .min-w-md-250px {
    min-width: 250px !important;
  }

  .min-w-md-275px {
    min-width: 275px !important;
  }

  .min-w-md-300px {
    min-width: 300px !important;
  }

  .min-w-md-325px {
    min-width: 325px !important;
  }

  .min-w-md-350px {
    min-width: 350px !important;
  }

  .min-w-md-375px {
    min-width: 375px !important;
  }

  .min-w-md-400px {
    min-width: 400px !important;
  }

  .min-w-md-425px {
    min-width: 425px !important;
  }

  .min-w-md-450px {
    min-width: 450px !important;
  }

  .min-w-md-475px {
    min-width: 475px !important;
  }

  .min-w-md-500px {
    min-width: 500px !important;
  }

  .min-w-md-550px {
    min-width: 550px !important;
  }

  .min-w-md-600px {
    min-width: 600px !important;
  }

  .min-w-md-650px {
    min-width: 650px !important;
  }

  .min-w-md-700px {
    min-width: 700px !important;
  }

  .min-w-md-750px {
    min-width: 750px !important;
  }

  .min-w-md-800px {
    min-width: 800px !important;
  }

  .min-w-md-850px {
    min-width: 850px !important;
  }

  .min-w-md-900px {
    min-width: 900px !important;
  }

  .min-w-md-950px {
    min-width: 950px !important;
  }

  .min-w-md-1000px {
    min-width: 1000px !important;
  }

  .min-h-md-unset {
    min-height: unset !important;
  }

  .min-h-md-25 {
    min-height: 25% !important;
  }

  .min-h-md-50 {
    min-height: 50% !important;
  }

  .min-h-md-75 {
    min-height: 75% !important;
  }

  .min-h-md-100 {
    min-height: 100% !important;
  }

  .min-h-md-auto {
    min-height: auto !important;
  }

  .min-h-md-1px {
    min-height: 1px !important;
  }

  .min-h-md-2px {
    min-height: 2px !important;
  }

  .min-h-md-3px {
    min-height: 3px !important;
  }

  .min-h-md-4px {
    min-height: 4px !important;
  }

  .min-h-md-5px {
    min-height: 5px !important;
  }

  .min-h-md-6px {
    min-height: 6px !important;
  }

  .min-h-md-7px {
    min-height: 7px !important;
  }

  .min-h-md-8px {
    min-height: 8px !important;
  }

  .min-h-md-9px {
    min-height: 9px !important;
  }

  .min-h-md-10px {
    min-height: 10px !important;
  }

  .min-h-md-15px {
    min-height: 15px !important;
  }

  .min-h-md-20px {
    min-height: 20px !important;
  }

  .min-h-md-25px {
    min-height: 25px !important;
  }

  .min-h-md-30px {
    min-height: 30px !important;
  }

  .min-h-md-35px {
    min-height: 35px !important;
  }

  .min-h-md-40px {
    min-height: 40px !important;
  }

  .min-h-md-45px {
    min-height: 45px !important;
  }

  .min-h-md-50px {
    min-height: 50px !important;
  }

  .min-h-md-55px {
    min-height: 55px !important;
  }

  .min-h-md-60px {
    min-height: 60px !important;
  }

  .min-h-md-65px {
    min-height: 65px !important;
  }

  .min-h-md-70px {
    min-height: 70px !important;
  }

  .min-h-md-75px {
    min-height: 75px !important;
  }

  .min-h-md-80px {
    min-height: 80px !important;
  }

  .min-h-md-85px {
    min-height: 85px !important;
  }

  .min-h-md-90px {
    min-height: 90px !important;
  }

  .min-h-md-95px {
    min-height: 95px !important;
  }

  .min-h-md-100px {
    min-height: 100px !important;
  }

  .min-h-md-125px {
    min-height: 125px !important;
  }

  .min-h-md-150px {
    min-height: 150px !important;
  }

  .min-h-md-175px {
    min-height: 175px !important;
  }

  .min-h-md-200px {
    min-height: 200px !important;
  }

  .min-h-md-225px {
    min-height: 225px !important;
  }

  .min-h-md-250px {
    min-height: 250px !important;
  }

  .min-h-md-275px {
    min-height: 275px !important;
  }

  .min-h-md-300px {
    min-height: 300px !important;
  }

  .min-h-md-325px {
    min-height: 325px !important;
  }

  .min-h-md-350px {
    min-height: 350px !important;
  }

  .min-h-md-375px {
    min-height: 375px !important;
  }

  .min-h-md-400px {
    min-height: 400px !important;
  }

  .min-h-md-425px {
    min-height: 425px !important;
  }

  .min-h-md-450px {
    min-height: 450px !important;
  }

  .min-h-md-475px {
    min-height: 475px !important;
  }

  .min-h-md-500px {
    min-height: 500px !important;
  }

  .min-h-md-550px {
    min-height: 550px !important;
  }

  .min-h-md-600px {
    min-height: 600px !important;
  }

  .min-h-md-650px {
    min-height: 650px !important;
  }

  .min-h-md-700px {
    min-height: 700px !important;
  }

  .min-h-md-750px {
    min-height: 750px !important;
  }

  .min-h-md-800px {
    min-height: 800px !important;
  }

  .min-h-md-850px {
    min-height: 850px !important;
  }

  .min-h-md-900px {
    min-height: 900px !important;
  }

  .min-h-md-950px {
    min-height: 950px !important;
  }

  .min-h-md-1000px {
    min-height: 1000px !important;
  }
}
@media (min-width: 992px) {
  .float-lg-start {
    float: left !important;
  }

  .float-lg-end {
    float: right !important;
  }

  .float-lg-none {
    float: none !important;
  }

  .d-lg-inline {
    display: inline !important;
  }

  .d-lg-inline-block {
    display: inline-block !important;
  }

  .d-lg-block {
    display: block !important;
  }

  .d-lg-grid {
    display: grid !important;
  }

  .d-lg-table {
    display: table !important;
  }

  .d-lg-table-row {
    display: table-row !important;
  }

  .d-lg-table-cell {
    display: table-cell !important;
  }

  .d-lg-flex {
    display: flex !important;
  }

  .d-lg-inline-flex {
    display: inline-flex !important;
  }

  .d-lg-none {
    display: none !important;
  }

  .w-lg-unset {
    width: unset !important;
  }

  .w-lg-25 {
    width: 25% !important;
  }

  .w-lg-50 {
    width: 50% !important;
  }

  .w-lg-75 {
    width: 75% !important;
  }

  .w-lg-100 {
    width: 100% !important;
  }

  .w-lg-auto {
    width: auto !important;
  }

  .w-lg-1px {
    width: 1px !important;
  }

  .w-lg-2px {
    width: 2px !important;
  }

  .w-lg-3px {
    width: 3px !important;
  }

  .w-lg-4px {
    width: 4px !important;
  }

  .w-lg-5px {
    width: 5px !important;
  }

  .w-lg-6px {
    width: 6px !important;
  }

  .w-lg-7px {
    width: 7px !important;
  }

  .w-lg-8px {
    width: 8px !important;
  }

  .w-lg-9px {
    width: 9px !important;
  }

  .w-lg-10px {
    width: 10px !important;
  }

  .w-lg-15px {
    width: 15px !important;
  }

  .w-lg-20px {
    width: 20px !important;
  }

  .w-lg-25px {
    width: 25px !important;
  }

  .w-lg-30px {
    width: 30px !important;
  }

  .w-lg-35px {
    width: 35px !important;
  }

  .w-lg-40px {
    width: 40px !important;
  }

  .w-lg-45px {
    width: 45px !important;
  }

  .w-lg-50px {
    width: 50px !important;
  }

  .w-lg-55px {
    width: 55px !important;
  }

  .w-lg-60px {
    width: 60px !important;
  }

  .w-lg-65px {
    width: 65px !important;
  }

  .w-lg-70px {
    width: 70px !important;
  }

  .w-lg-75px {
    width: 75px !important;
  }

  .w-lg-80px {
    width: 80px !important;
  }

  .w-lg-85px {
    width: 85px !important;
  }

  .w-lg-90px {
    width: 90px !important;
  }

  .w-lg-95px {
    width: 95px !important;
  }

  .w-lg-100px {
    width: 100px !important;
  }

  .w-lg-125px {
    width: 125px !important;
  }

  .w-lg-150px {
    width: 150px !important;
  }

  .w-lg-175px {
    width: 175px !important;
  }

  .w-lg-200px {
    width: 200px !important;
  }

  .w-lg-225px {
    width: 225px !important;
  }

  .w-lg-250px {
    width: 250px !important;
  }

  .w-lg-275px {
    width: 275px !important;
  }

  .w-lg-300px {
    width: 300px !important;
  }

  .w-lg-325px {
    width: 325px !important;
  }

  .w-lg-350px {
    width: 350px !important;
  }

  .w-lg-375px {
    width: 375px !important;
  }

  .w-lg-400px {
    width: 400px !important;
  }

  .w-lg-425px {
    width: 425px !important;
  }

  .w-lg-450px {
    width: 450px !important;
  }

  .w-lg-475px {
    width: 475px !important;
  }

  .w-lg-500px {
    width: 500px !important;
  }

  .w-lg-550px {
    width: 550px !important;
  }

  .w-lg-600px {
    width: 600px !important;
  }

  .w-lg-650px {
    width: 650px !important;
  }

  .w-lg-700px {
    width: 700px !important;
  }

  .w-lg-750px {
    width: 750px !important;
  }

  .w-lg-800px {
    width: 800px !important;
  }

  .w-lg-850px {
    width: 850px !important;
  }

  .w-lg-900px {
    width: 900px !important;
  }

  .w-lg-950px {
    width: 950px !important;
  }

  .w-lg-1000px {
    width: 1000px !important;
  }

  .mw-lg-unset {
    max-width: unset !important;
  }

  .mw-lg-25 {
    max-width: 25% !important;
  }

  .mw-lg-50 {
    max-width: 50% !important;
  }

  .mw-lg-75 {
    max-width: 75% !important;
  }

  .mw-lg-100 {
    max-width: 100% !important;
  }

  .mw-lg-auto {
    max-width: auto !important;
  }

  .mw-lg-1px {
    max-width: 1px !important;
  }

  .mw-lg-2px {
    max-width: 2px !important;
  }

  .mw-lg-3px {
    max-width: 3px !important;
  }

  .mw-lg-4px {
    max-width: 4px !important;
  }

  .mw-lg-5px {
    max-width: 5px !important;
  }

  .mw-lg-6px {
    max-width: 6px !important;
  }

  .mw-lg-7px {
    max-width: 7px !important;
  }

  .mw-lg-8px {
    max-width: 8px !important;
  }

  .mw-lg-9px {
    max-width: 9px !important;
  }

  .mw-lg-10px {
    max-width: 10px !important;
  }

  .mw-lg-15px {
    max-width: 15px !important;
  }

  .mw-lg-20px {
    max-width: 20px !important;
  }

  .mw-lg-25px {
    max-width: 25px !important;
  }

  .mw-lg-30px {
    max-width: 30px !important;
  }

  .mw-lg-35px {
    max-width: 35px !important;
  }

  .mw-lg-40px {
    max-width: 40px !important;
  }

  .mw-lg-45px {
    max-width: 45px !important;
  }

  .mw-lg-50px {
    max-width: 50px !important;
  }

  .mw-lg-55px {
    max-width: 55px !important;
  }

  .mw-lg-60px {
    max-width: 60px !important;
  }

  .mw-lg-65px {
    max-width: 65px !important;
  }

  .mw-lg-70px {
    max-width: 70px !important;
  }

  .mw-lg-75px {
    max-width: 75px !important;
  }

  .mw-lg-80px {
    max-width: 80px !important;
  }

  .mw-lg-85px {
    max-width: 85px !important;
  }

  .mw-lg-90px {
    max-width: 90px !important;
  }

  .mw-lg-95px {
    max-width: 95px !important;
  }

  .mw-lg-100px {
    max-width: 100px !important;
  }

  .mw-lg-125px {
    max-width: 125px !important;
  }

  .mw-lg-150px {
    max-width: 150px !important;
  }

  .mw-lg-175px {
    max-width: 175px !important;
  }

  .mw-lg-200px {
    max-width: 200px !important;
  }

  .mw-lg-225px {
    max-width: 225px !important;
  }

  .mw-lg-250px {
    max-width: 250px !important;
  }

  .mw-lg-275px {
    max-width: 275px !important;
  }

  .mw-lg-300px {
    max-width: 300px !important;
  }

  .mw-lg-325px {
    max-width: 325px !important;
  }

  .mw-lg-350px {
    max-width: 350px !important;
  }

  .mw-lg-375px {
    max-width: 375px !important;
  }

  .mw-lg-400px {
    max-width: 400px !important;
  }

  .mw-lg-425px {
    max-width: 425px !important;
  }

  .mw-lg-450px {
    max-width: 450px !important;
  }

  .mw-lg-475px {
    max-width: 475px !important;
  }

  .mw-lg-500px {
    max-width: 500px !important;
  }

  .mw-lg-550px {
    max-width: 550px !important;
  }

  .mw-lg-600px {
    max-width: 600px !important;
  }

  .mw-lg-650px {
    max-width: 650px !important;
  }

  .mw-lg-700px {
    max-width: 700px !important;
  }

  .mw-lg-750px {
    max-width: 750px !important;
  }

  .mw-lg-800px {
    max-width: 800px !important;
  }

  .mw-lg-850px {
    max-width: 850px !important;
  }

  .mw-lg-900px {
    max-width: 900px !important;
  }

  .mw-lg-950px {
    max-width: 950px !important;
  }

  .mw-lg-1000px {
    max-width: 1000px !important;
  }

  .h-lg-unset {
    height: unset !important;
  }

  .h-lg-25 {
    height: 25% !important;
  }

  .h-lg-50 {
    height: 50% !important;
  }

  .h-lg-75 {
    height: 75% !important;
  }

  .h-lg-100 {
    height: 100% !important;
  }

  .h-lg-auto {
    height: auto !important;
  }

  .h-lg-1px {
    height: 1px !important;
  }

  .h-lg-2px {
    height: 2px !important;
  }

  .h-lg-3px {
    height: 3px !important;
  }

  .h-lg-4px {
    height: 4px !important;
  }

  .h-lg-5px {
    height: 5px !important;
  }

  .h-lg-6px {
    height: 6px !important;
  }

  .h-lg-7px {
    height: 7px !important;
  }

  .h-lg-8px {
    height: 8px !important;
  }

  .h-lg-9px {
    height: 9px !important;
  }

  .h-lg-10px {
    height: 10px !important;
  }

  .h-lg-15px {
    height: 15px !important;
  }

  .h-lg-20px {
    height: 20px !important;
  }

  .h-lg-25px {
    height: 25px !important;
  }

  .h-lg-30px {
    height: 30px !important;
  }

  .h-lg-35px {
    height: 35px !important;
  }

  .h-lg-40px {
    height: 40px !important;
  }

  .h-lg-45px {
    height: 45px !important;
  }

  .h-lg-50px {
    height: 50px !important;
  }

  .h-lg-55px {
    height: 55px !important;
  }

  .h-lg-60px {
    height: 60px !important;
  }

  .h-lg-65px {
    height: 65px !important;
  }

  .h-lg-70px {
    height: 70px !important;
  }

  .h-lg-75px {
    height: 75px !important;
  }

  .h-lg-80px {
    height: 80px !important;
  }

  .h-lg-85px {
    height: 85px !important;
  }

  .h-lg-90px {
    height: 90px !important;
  }

  .h-lg-95px {
    height: 95px !important;
  }

  .h-lg-100px {
    height: 100px !important;
  }

  .h-lg-125px {
    height: 125px !important;
  }

  .h-lg-150px {
    height: 150px !important;
  }

  .h-lg-175px {
    height: 175px !important;
  }

  .h-lg-200px {
    height: 200px !important;
  }

  .h-lg-225px {
    height: 225px !important;
  }

  .h-lg-250px {
    height: 250px !important;
  }

  .h-lg-275px {
    height: 275px !important;
  }

  .h-lg-300px {
    height: 300px !important;
  }

  .h-lg-325px {
    height: 325px !important;
  }

  .h-lg-350px {
    height: 350px !important;
  }

  .h-lg-375px {
    height: 375px !important;
  }

  .h-lg-400px {
    height: 400px !important;
  }

  .h-lg-425px {
    height: 425px !important;
  }

  .h-lg-450px {
    height: 450px !important;
  }

  .h-lg-475px {
    height: 475px !important;
  }

  .h-lg-500px {
    height: 500px !important;
  }

  .h-lg-550px {
    height: 550px !important;
  }

  .h-lg-600px {
    height: 600px !important;
  }

  .h-lg-650px {
    height: 650px !important;
  }

  .h-lg-700px {
    height: 700px !important;
  }

  .h-lg-750px {
    height: 750px !important;
  }

  .h-lg-800px {
    height: 800px !important;
  }

  .h-lg-850px {
    height: 850px !important;
  }

  .h-lg-900px {
    height: 900px !important;
  }

  .h-lg-950px {
    height: 950px !important;
  }

  .h-lg-1000px {
    height: 1000px !important;
  }

  .mh-lg-unset {
    max-height: unset !important;
  }

  .mh-lg-25 {
    max-height: 25% !important;
  }

  .mh-lg-50 {
    max-height: 50% !important;
  }

  .mh-lg-75 {
    max-height: 75% !important;
  }

  .mh-lg-100 {
    max-height: 100% !important;
  }

  .mh-lg-auto {
    max-height: auto !important;
  }

  .mh-lg-1px {
    max-height: 1px !important;
  }

  .mh-lg-2px {
    max-height: 2px !important;
  }

  .mh-lg-3px {
    max-height: 3px !important;
  }

  .mh-lg-4px {
    max-height: 4px !important;
  }

  .mh-lg-5px {
    max-height: 5px !important;
  }

  .mh-lg-6px {
    max-height: 6px !important;
  }

  .mh-lg-7px {
    max-height: 7px !important;
  }

  .mh-lg-8px {
    max-height: 8px !important;
  }

  .mh-lg-9px {
    max-height: 9px !important;
  }

  .mh-lg-10px {
    max-height: 10px !important;
  }

  .mh-lg-15px {
    max-height: 15px !important;
  }

  .mh-lg-20px {
    max-height: 20px !important;
  }

  .mh-lg-25px {
    max-height: 25px !important;
  }

  .mh-lg-30px {
    max-height: 30px !important;
  }

  .mh-lg-35px {
    max-height: 35px !important;
  }

  .mh-lg-40px {
    max-height: 40px !important;
  }

  .mh-lg-45px {
    max-height: 45px !important;
  }

  .mh-lg-50px {
    max-height: 50px !important;
  }

  .mh-lg-55px {
    max-height: 55px !important;
  }

  .mh-lg-60px {
    max-height: 60px !important;
  }

  .mh-lg-65px {
    max-height: 65px !important;
  }

  .mh-lg-70px {
    max-height: 70px !important;
  }

  .mh-lg-75px {
    max-height: 75px !important;
  }

  .mh-lg-80px {
    max-height: 80px !important;
  }

  .mh-lg-85px {
    max-height: 85px !important;
  }

  .mh-lg-90px {
    max-height: 90px !important;
  }

  .mh-lg-95px {
    max-height: 95px !important;
  }

  .mh-lg-100px {
    max-height: 100px !important;
  }

  .mh-lg-125px {
    max-height: 125px !important;
  }

  .mh-lg-150px {
    max-height: 150px !important;
  }

  .mh-lg-175px {
    max-height: 175px !important;
  }

  .mh-lg-200px {
    max-height: 200px !important;
  }

  .mh-lg-225px {
    max-height: 225px !important;
  }

  .mh-lg-250px {
    max-height: 250px !important;
  }

  .mh-lg-275px {
    max-height: 275px !important;
  }

  .mh-lg-300px {
    max-height: 300px !important;
  }

  .mh-lg-325px {
    max-height: 325px !important;
  }

  .mh-lg-350px {
    max-height: 350px !important;
  }

  .mh-lg-375px {
    max-height: 375px !important;
  }

  .mh-lg-400px {
    max-height: 400px !important;
  }

  .mh-lg-425px {
    max-height: 425px !important;
  }

  .mh-lg-450px {
    max-height: 450px !important;
  }

  .mh-lg-475px {
    max-height: 475px !important;
  }

  .mh-lg-500px {
    max-height: 500px !important;
  }

  .mh-lg-550px {
    max-height: 550px !important;
  }

  .mh-lg-600px {
    max-height: 600px !important;
  }

  .mh-lg-650px {
    max-height: 650px !important;
  }

  .mh-lg-700px {
    max-height: 700px !important;
  }

  .mh-lg-750px {
    max-height: 750px !important;
  }

  .mh-lg-800px {
    max-height: 800px !important;
  }

  .mh-lg-850px {
    max-height: 850px !important;
  }

  .mh-lg-900px {
    max-height: 900px !important;
  }

  .mh-lg-950px {
    max-height: 950px !important;
  }

  .mh-lg-1000px {
    max-height: 1000px !important;
  }

  .flex-lg-fill {
    flex: 1 1 auto !important;
  }

  .flex-lg-row {
    flex-direction: row !important;
  }

  .flex-lg-column {
    flex-direction: column !important;
  }

  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }

  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .gap-lg-0 {
    gap: 0 !important;
  }

  .gap-lg-1 {
    gap: 0.25rem !important;
  }

  .gap-lg-2 {
    gap: 0.5rem !important;
  }

  .gap-lg-3 {
    gap: 0.75rem !important;
  }

  .gap-lg-4 {
    gap: 1rem !important;
  }

  .gap-lg-5 {
    gap: 1.25rem !important;
  }

  .gap-lg-6 {
    gap: 1.5rem !important;
  }

  .gap-lg-7 {
    gap: 1.75rem !important;
  }

  .gap-lg-8 {
    gap: 2rem !important;
  }

  .gap-lg-9 {
    gap: 2.25rem !important;
  }

  .gap-lg-10 {
    gap: 2.5rem !important;
  }

  .gap-lg-11 {
    gap: 2.75rem !important;
  }

  .gap-lg-12 {
    gap: 3rem !important;
  }

  .gap-lg-13 {
    gap: 3.25rem !important;
  }

  .gap-lg-14 {
    gap: 3.5rem !important;
  }

  .gap-lg-15 {
    gap: 3.75rem !important;
  }

  .gap-lg-16 {
    gap: 4rem !important;
  }

  .gap-lg-17 {
    gap: 4.25rem !important;
  }

  .gap-lg-18 {
    gap: 4.5rem !important;
  }

  .gap-lg-19 {
    gap: 4.75rem !important;
  }

  .gap-lg-20 {
    gap: 5rem !important;
  }

  .justify-content-lg-start {
    justify-content: flex-start !important;
  }

  .justify-content-lg-end {
    justify-content: flex-end !important;
  }

  .justify-content-lg-center {
    justify-content: center !important;
  }

  .justify-content-lg-between {
    justify-content: space-between !important;
  }

  .justify-content-lg-around {
    justify-content: space-around !important;
  }

  .justify-content-lg-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-lg-start {
    align-items: flex-start !important;
  }

  .align-items-lg-end {
    align-items: flex-end !important;
  }

  .align-items-lg-center {
    align-items: center !important;
  }

  .align-items-lg-baseline {
    align-items: baseline !important;
  }

  .align-items-lg-stretch {
    align-items: stretch !important;
  }

  .align-content-lg-start {
    align-content: flex-start !important;
  }

  .align-content-lg-end {
    align-content: flex-end !important;
  }

  .align-content-lg-center {
    align-content: center !important;
  }

  .align-content-lg-between {
    align-content: space-between !important;
  }

  .align-content-lg-around {
    align-content: space-around !important;
  }

  .align-content-lg-stretch {
    align-content: stretch !important;
  }

  .align-self-lg-auto {
    align-self: auto !important;
  }

  .align-self-lg-start {
    align-self: flex-start !important;
  }

  .align-self-lg-end {
    align-self: flex-end !important;
  }

  .align-self-lg-center {
    align-self: center !important;
  }

  .align-self-lg-baseline {
    align-self: baseline !important;
  }

  .align-self-lg-stretch {
    align-self: stretch !important;
  }

  .order-lg-first {
    order: -1 !important;
  }

  .order-lg-0 {
    order: 0 !important;
  }

  .order-lg-1 {
    order: 1 !important;
  }

  .order-lg-2 {
    order: 2 !important;
  }

  .order-lg-3 {
    order: 3 !important;
  }

  .order-lg-4 {
    order: 4 !important;
  }

  .order-lg-5 {
    order: 5 !important;
  }

  .order-lg-last {
    order: 6 !important;
  }

  .m-lg-0 {
    margin: 0 !important;
  }

  .m-lg-1 {
    margin: 0.25rem !important;
  }

  .m-lg-2 {
    margin: 0.5rem !important;
  }

  .m-lg-3 {
    margin: 0.75rem !important;
  }

  .m-lg-4 {
    margin: 1rem !important;
  }

  .m-lg-5 {
    margin: 1.25rem !important;
  }

  .m-lg-6 {
    margin: 1.5rem !important;
  }

  .m-lg-7 {
    margin: 1.75rem !important;
  }

  .m-lg-8 {
    margin: 2rem !important;
  }

  .m-lg-9 {
    margin: 2.25rem !important;
  }

  .m-lg-10 {
    margin: 2.5rem !important;
  }

  .m-lg-11 {
    margin: 2.75rem !important;
  }

  .m-lg-12 {
    margin: 3rem !important;
  }

  .m-lg-13 {
    margin: 3.25rem !important;
  }

  .m-lg-14 {
    margin: 3.5rem !important;
  }

  .m-lg-15 {
    margin: 3.75rem !important;
  }

  .m-lg-16 {
    margin: 4rem !important;
  }

  .m-lg-17 {
    margin: 4.25rem !important;
  }

  .m-lg-18 {
    margin: 4.5rem !important;
  }

  .m-lg-19 {
    margin: 4.75rem !important;
  }

  .m-lg-20 {
    margin: 5rem !important;
  }

  .m-lg-auto {
    margin: auto !important;
  }

  .mx-lg-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .mx-lg-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }

  .mx-lg-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }

  .mx-lg-3 {
    margin-right: 0.75rem !important;
    margin-left: 0.75rem !important;
  }

  .mx-lg-4 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }

  .mx-lg-5 {
    margin-right: 1.25rem !important;
    margin-left: 1.25rem !important;
  }

  .mx-lg-6 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }

  .mx-lg-7 {
    margin-right: 1.75rem !important;
    margin-left: 1.75rem !important;
  }

  .mx-lg-8 {
    margin-right: 2rem !important;
    margin-left: 2rem !important;
  }

  .mx-lg-9 {
    margin-right: 2.25rem !important;
    margin-left: 2.25rem !important;
  }

  .mx-lg-10 {
    margin-right: 2.5rem !important;
    margin-left: 2.5rem !important;
  }

  .mx-lg-11 {
    margin-right: 2.75rem !important;
    margin-left: 2.75rem !important;
  }

  .mx-lg-12 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }

  .mx-lg-13 {
    margin-right: 3.25rem !important;
    margin-left: 3.25rem !important;
  }

  .mx-lg-14 {
    margin-right: 3.5rem !important;
    margin-left: 3.5rem !important;
  }

  .mx-lg-15 {
    margin-right: 3.75rem !important;
    margin-left: 3.75rem !important;
  }

  .mx-lg-16 {
    margin-right: 4rem !important;
    margin-left: 4rem !important;
  }

  .mx-lg-17 {
    margin-right: 4.25rem !important;
    margin-left: 4.25rem !important;
  }

  .mx-lg-18 {
    margin-right: 4.5rem !important;
    margin-left: 4.5rem !important;
  }

  .mx-lg-19 {
    margin-right: 4.75rem !important;
    margin-left: 4.75rem !important;
  }

  .mx-lg-20 {
    margin-right: 5rem !important;
    margin-left: 5rem !important;
  }

  .mx-lg-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .my-lg-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-lg-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }

  .my-lg-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .my-lg-3 {
    margin-top: 0.75rem !important;
    margin-bottom: 0.75rem !important;
  }

  .my-lg-4 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-lg-5 {
    margin-top: 1.25rem !important;
    margin-bottom: 1.25rem !important;
  }

  .my-lg-6 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-lg-7 {
    margin-top: 1.75rem !important;
    margin-bottom: 1.75rem !important;
  }

  .my-lg-8 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }

  .my-lg-9 {
    margin-top: 2.25rem !important;
    margin-bottom: 2.25rem !important;
  }

  .my-lg-10 {
    margin-top: 2.5rem !important;
    margin-bottom: 2.5rem !important;
  }

  .my-lg-11 {
    margin-top: 2.75rem !important;
    margin-bottom: 2.75rem !important;
  }

  .my-lg-12 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-lg-13 {
    margin-top: 3.25rem !important;
    margin-bottom: 3.25rem !important;
  }

  .my-lg-14 {
    margin-top: 3.5rem !important;
    margin-bottom: 3.5rem !important;
  }

  .my-lg-15 {
    margin-top: 3.75rem !important;
    margin-bottom: 3.75rem !important;
  }

  .my-lg-16 {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important;
  }

  .my-lg-17 {
    margin-top: 4.25rem !important;
    margin-bottom: 4.25rem !important;
  }

  .my-lg-18 {
    margin-top: 4.5rem !important;
    margin-bottom: 4.5rem !important;
  }

  .my-lg-19 {
    margin-top: 4.75rem !important;
    margin-bottom: 4.75rem !important;
  }

  .my-lg-20 {
    margin-top: 5rem !important;
    margin-bottom: 5rem !important;
  }

  .my-lg-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-lg-0 {
    margin-top: 0 !important;
  }

  .mt-lg-1 {
    margin-top: 0.25rem !important;
  }

  .mt-lg-2 {
    margin-top: 0.5rem !important;
  }

  .mt-lg-3 {
    margin-top: 0.75rem !important;
  }

  .mt-lg-4 {
    margin-top: 1rem !important;
  }

  .mt-lg-5 {
    margin-top: 1.25rem !important;
  }

  .mt-lg-6 {
    margin-top: 1.5rem !important;
  }

  .mt-lg-7 {
    margin-top: 1.75rem !important;
  }

  .mt-lg-8 {
    margin-top: 2rem !important;
  }

  .mt-lg-9 {
    margin-top: 2.25rem !important;
  }

  .mt-lg-10 {
    margin-top: 2.5rem !important;
  }

  .mt-lg-11 {
    margin-top: 2.75rem !important;
  }

  .mt-lg-12 {
    margin-top: 3rem !important;
  }

  .mt-lg-13 {
    margin-top: 3.25rem !important;
  }

  .mt-lg-14 {
    margin-top: 3.5rem !important;
  }

  .mt-lg-15 {
    margin-top: 3.75rem !important;
  }

  .mt-lg-16 {
    margin-top: 4rem !important;
  }

  .mt-lg-17 {
    margin-top: 4.25rem !important;
  }

  .mt-lg-18 {
    margin-top: 4.5rem !important;
  }

  .mt-lg-19 {
    margin-top: 4.75rem !important;
  }

  .mt-lg-20 {
    margin-top: 5rem !important;
  }

  .mt-lg-auto {
    margin-top: auto !important;
  }

  .me-lg-0 {
    margin-right: 0 !important;
  }

  .me-lg-1 {
    margin-right: 0.25rem !important;
  }

  .me-lg-2 {
    margin-right: 0.5rem !important;
  }

  .me-lg-3 {
    margin-right: 0.75rem !important;
  }

  .me-lg-4 {
    margin-right: 1rem !important;
  }

  .me-lg-5 {
    margin-right: 1.25rem !important;
  }

  .me-lg-6 {
    margin-right: 1.5rem !important;
  }

  .me-lg-7 {
    margin-right: 1.75rem !important;
  }

  .me-lg-8 {
    margin-right: 2rem !important;
  }

  .me-lg-9 {
    margin-right: 2.25rem !important;
  }

  .me-lg-10 {
    margin-right: 2.5rem !important;
  }

  .me-lg-11 {
    margin-right: 2.75rem !important;
  }

  .me-lg-12 {
    margin-right: 3rem !important;
  }

  .me-lg-13 {
    margin-right: 3.25rem !important;
  }

  .me-lg-14 {
    margin-right: 3.5rem !important;
  }

  .me-lg-15 {
    margin-right: 3.75rem !important;
  }

  .me-lg-16 {
    margin-right: 4rem !important;
  }

  .me-lg-17 {
    margin-right: 4.25rem !important;
  }

  .me-lg-18 {
    margin-right: 4.5rem !important;
  }

  .me-lg-19 {
    margin-right: 4.75rem !important;
  }

  .me-lg-20 {
    margin-right: 5rem !important;
  }

  .me-lg-auto {
    margin-right: auto !important;
  }

  .mb-lg-0 {
    margin-bottom: 0 !important;
  }

  .mb-lg-1 {
    margin-bottom: 0.25rem !important;
  }

  .mb-lg-2 {
    margin-bottom: 0.5rem !important;
  }

  .mb-lg-3 {
    margin-bottom: 0.75rem !important;
  }

  .mb-lg-4 {
    margin-bottom: 1rem !important;
  }

  .mb-lg-5 {
    margin-bottom: 1.25rem !important;
  }

  .mb-lg-6 {
    margin-bottom: 1.5rem !important;
  }

  .mb-lg-7 {
    margin-bottom: 1.75rem !important;
  }

  .mb-lg-8 {
    margin-bottom: 2rem !important;
  }

  .mb-lg-9 {
    margin-bottom: 2.25rem !important;
  }

  .mb-lg-10 {
    margin-bottom: 2.5rem !important;
  }

  .mb-lg-11 {
    margin-bottom: 2.75rem !important;
  }

  .mb-lg-12 {
    margin-bottom: 3rem !important;
  }

  .mb-lg-13 {
    margin-bottom: 3.25rem !important;
  }

  .mb-lg-14 {
    margin-bottom: 3.5rem !important;
  }

  .mb-lg-15 {
    margin-bottom: 3.75rem !important;
  }

  .mb-lg-16 {
    margin-bottom: 4rem !important;
  }

  .mb-lg-17 {
    margin-bottom: 4.25rem !important;
  }

  .mb-lg-18 {
    margin-bottom: 4.5rem !important;
  }

  .mb-lg-19 {
    margin-bottom: 4.75rem !important;
  }

  .mb-lg-20 {
    margin-bottom: 5rem !important;
  }

  .mb-lg-auto {
    margin-bottom: auto !important;
  }

  .ms-lg-0 {
    margin-left: 0 !important;
  }

  .ms-lg-1 {
    margin-left: 0.25rem !important;
  }

  .ms-lg-2 {
    margin-left: 0.5rem !important;
  }

  .ms-lg-3 {
    margin-left: 0.75rem !important;
  }

  .ms-lg-4 {
    margin-left: 1rem !important;
  }

  .ms-lg-5 {
    margin-left: 1.25rem !important;
  }

  .ms-lg-6 {
    margin-left: 1.5rem !important;
  }

  .ms-lg-7 {
    margin-left: 1.75rem !important;
  }

  .ms-lg-8 {
    margin-left: 2rem !important;
  }

  .ms-lg-9 {
    margin-left: 2.25rem !important;
  }

  .ms-lg-10 {
    margin-left: 2.5rem !important;
  }

  .ms-lg-11 {
    margin-left: 2.75rem !important;
  }

  .ms-lg-12 {
    margin-left: 3rem !important;
  }

  .ms-lg-13 {
    margin-left: 3.25rem !important;
  }

  .ms-lg-14 {
    margin-left: 3.5rem !important;
  }

  .ms-lg-15 {
    margin-left: 3.75rem !important;
  }

  .ms-lg-16 {
    margin-left: 4rem !important;
  }

  .ms-lg-17 {
    margin-left: 4.25rem !important;
  }

  .ms-lg-18 {
    margin-left: 4.5rem !important;
  }

  .ms-lg-19 {
    margin-left: 4.75rem !important;
  }

  .ms-lg-20 {
    margin-left: 5rem !important;
  }

  .ms-lg-auto {
    margin-left: auto !important;
  }

  .m-lg-n1 {
    margin: -0.25rem !important;
  }

  .m-lg-n2 {
    margin: -0.5rem !important;
  }

  .m-lg-n3 {
    margin: -0.75rem !important;
  }

  .m-lg-n4 {
    margin: -1rem !important;
  }

  .m-lg-n5 {
    margin: -1.25rem !important;
  }

  .m-lg-n6 {
    margin: -1.5rem !important;
  }

  .m-lg-n7 {
    margin: -1.75rem !important;
  }

  .m-lg-n8 {
    margin: -2rem !important;
  }

  .m-lg-n9 {
    margin: -2.25rem !important;
  }

  .m-lg-n10 {
    margin: -2.5rem !important;
  }

  .m-lg-n11 {
    margin: -2.75rem !important;
  }

  .m-lg-n12 {
    margin: -3rem !important;
  }

  .m-lg-n13 {
    margin: -3.25rem !important;
  }

  .m-lg-n14 {
    margin: -3.5rem !important;
  }

  .m-lg-n15 {
    margin: -3.75rem !important;
  }

  .m-lg-n16 {
    margin: -4rem !important;
  }

  .m-lg-n17 {
    margin: -4.25rem !important;
  }

  .m-lg-n18 {
    margin: -4.5rem !important;
  }

  .m-lg-n19 {
    margin: -4.75rem !important;
  }

  .m-lg-n20 {
    margin: -5rem !important;
  }

  .mx-lg-n1 {
    margin-right: -0.25rem !important;
    margin-left: -0.25rem !important;
  }

  .mx-lg-n2 {
    margin-right: -0.5rem !important;
    margin-left: -0.5rem !important;
  }

  .mx-lg-n3 {
    margin-right: -0.75rem !important;
    margin-left: -0.75rem !important;
  }

  .mx-lg-n4 {
    margin-right: -1rem !important;
    margin-left: -1rem !important;
  }

  .mx-lg-n5 {
    margin-right: -1.25rem !important;
    margin-left: -1.25rem !important;
  }

  .mx-lg-n6 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important;
  }

  .mx-lg-n7 {
    margin-right: -1.75rem !important;
    margin-left: -1.75rem !important;
  }

  .mx-lg-n8 {
    margin-right: -2rem !important;
    margin-left: -2rem !important;
  }

  .mx-lg-n9 {
    margin-right: -2.25rem !important;
    margin-left: -2.25rem !important;
  }

  .mx-lg-n10 {
    margin-right: -2.5rem !important;
    margin-left: -2.5rem !important;
  }

  .mx-lg-n11 {
    margin-right: -2.75rem !important;
    margin-left: -2.75rem !important;
  }

  .mx-lg-n12 {
    margin-right: -3rem !important;
    margin-left: -3rem !important;
  }

  .mx-lg-n13 {
    margin-right: -3.25rem !important;
    margin-left: -3.25rem !important;
  }

  .mx-lg-n14 {
    margin-right: -3.5rem !important;
    margin-left: -3.5rem !important;
  }

  .mx-lg-n15 {
    margin-right: -3.75rem !important;
    margin-left: -3.75rem !important;
  }

  .mx-lg-n16 {
    margin-right: -4rem !important;
    margin-left: -4rem !important;
  }

  .mx-lg-n17 {
    margin-right: -4.25rem !important;
    margin-left: -4.25rem !important;
  }

  .mx-lg-n18 {
    margin-right: -4.5rem !important;
    margin-left: -4.5rem !important;
  }

  .mx-lg-n19 {
    margin-right: -4.75rem !important;
    margin-left: -4.75rem !important;
  }

  .mx-lg-n20 {
    margin-right: -5rem !important;
    margin-left: -5rem !important;
  }

  .my-lg-n1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important;
  }

  .my-lg-n2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important;
  }

  .my-lg-n3 {
    margin-top: -0.75rem !important;
    margin-bottom: -0.75rem !important;
  }

  .my-lg-n4 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }

  .my-lg-n5 {
    margin-top: -1.25rem !important;
    margin-bottom: -1.25rem !important;
  }

  .my-lg-n6 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }

  .my-lg-n7 {
    margin-top: -1.75rem !important;
    margin-bottom: -1.75rem !important;
  }

  .my-lg-n8 {
    margin-top: -2rem !important;
    margin-bottom: -2rem !important;
  }

  .my-lg-n9 {
    margin-top: -2.25rem !important;
    margin-bottom: -2.25rem !important;
  }

  .my-lg-n10 {
    margin-top: -2.5rem !important;
    margin-bottom: -2.5rem !important;
  }

  .my-lg-n11 {
    margin-top: -2.75rem !important;
    margin-bottom: -2.75rem !important;
  }

  .my-lg-n12 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }

  .my-lg-n13 {
    margin-top: -3.25rem !important;
    margin-bottom: -3.25rem !important;
  }

  .my-lg-n14 {
    margin-top: -3.5rem !important;
    margin-bottom: -3.5rem !important;
  }

  .my-lg-n15 {
    margin-top: -3.75rem !important;
    margin-bottom: -3.75rem !important;
  }

  .my-lg-n16 {
    margin-top: -4rem !important;
    margin-bottom: -4rem !important;
  }

  .my-lg-n17 {
    margin-top: -4.25rem !important;
    margin-bottom: -4.25rem !important;
  }

  .my-lg-n18 {
    margin-top: -4.5rem !important;
    margin-bottom: -4.5rem !important;
  }

  .my-lg-n19 {
    margin-top: -4.75rem !important;
    margin-bottom: -4.75rem !important;
  }

  .my-lg-n20 {
    margin-top: -5rem !important;
    margin-bottom: -5rem !important;
  }

  .mt-lg-n1 {
    margin-top: -0.25rem !important;
  }

  .mt-lg-n2 {
    margin-top: -0.5rem !important;
  }

  .mt-lg-n3 {
    margin-top: -0.75rem !important;
  }

  .mt-lg-n4 {
    margin-top: -1rem !important;
  }

  .mt-lg-n5 {
    margin-top: -1.25rem !important;
  }

  .mt-lg-n6 {
    margin-top: -1.5rem !important;
  }

  .mt-lg-n7 {
    margin-top: -1.75rem !important;
  }

  .mt-lg-n8 {
    margin-top: -2rem !important;
  }

  .mt-lg-n9 {
    margin-top: -2.25rem !important;
  }

  .mt-lg-n10 {
    margin-top: -2.5rem !important;
  }

  .mt-lg-n11 {
    margin-top: -2.75rem !important;
  }

  .mt-lg-n12 {
    margin-top: -3rem !important;
  }

  .mt-lg-n13 {
    margin-top: -3.25rem !important;
  }

  .mt-lg-n14 {
    margin-top: -3.5rem !important;
  }

  .mt-lg-n15 {
    margin-top: -3.75rem !important;
  }

  .mt-lg-n16 {
    margin-top: -4rem !important;
  }

  .mt-lg-n17 {
    margin-top: -4.25rem !important;
  }

  .mt-lg-n18 {
    margin-top: -4.5rem !important;
  }

  .mt-lg-n19 {
    margin-top: -4.75rem !important;
  }

  .mt-lg-n20 {
    margin-top: -5rem !important;
  }

  .me-lg-n1 {
    margin-right: -0.25rem !important;
  }

  .me-lg-n2 {
    margin-right: -0.5rem !important;
  }

  .me-lg-n3 {
    margin-right: -0.75rem !important;
  }

  .me-lg-n4 {
    margin-right: -1rem !important;
  }

  .me-lg-n5 {
    margin-right: -1.25rem !important;
  }

  .me-lg-n6 {
    margin-right: -1.5rem !important;
  }

  .me-lg-n7 {
    margin-right: -1.75rem !important;
  }

  .me-lg-n8 {
    margin-right: -2rem !important;
  }

  .me-lg-n9 {
    margin-right: -2.25rem !important;
  }

  .me-lg-n10 {
    margin-right: -2.5rem !important;
  }

  .me-lg-n11 {
    margin-right: -2.75rem !important;
  }

  .me-lg-n12 {
    margin-right: -3rem !important;
  }

  .me-lg-n13 {
    margin-right: -3.25rem !important;
  }

  .me-lg-n14 {
    margin-right: -3.5rem !important;
  }

  .me-lg-n15 {
    margin-right: -3.75rem !important;
  }

  .me-lg-n16 {
    margin-right: -4rem !important;
  }

  .me-lg-n17 {
    margin-right: -4.25rem !important;
  }

  .me-lg-n18 {
    margin-right: -4.5rem !important;
  }

  .me-lg-n19 {
    margin-right: -4.75rem !important;
  }

  .me-lg-n20 {
    margin-right: -5rem !important;
  }

  .mb-lg-n1 {
    margin-bottom: -0.25rem !important;
  }

  .mb-lg-n2 {
    margin-bottom: -0.5rem !important;
  }

  .mb-lg-n3 {
    margin-bottom: -0.75rem !important;
  }

  .mb-lg-n4 {
    margin-bottom: -1rem !important;
  }

  .mb-lg-n5 {
    margin-bottom: -1.25rem !important;
  }

  .mb-lg-n6 {
    margin-bottom: -1.5rem !important;
  }

  .mb-lg-n7 {
    margin-bottom: -1.75rem !important;
  }

  .mb-lg-n8 {
    margin-bottom: -2rem !important;
  }

  .mb-lg-n9 {
    margin-bottom: -2.25rem !important;
  }

  .mb-lg-n10 {
    margin-bottom: -2.5rem !important;
  }

  .mb-lg-n11 {
    margin-bottom: -2.75rem !important;
  }

  .mb-lg-n12 {
    margin-bottom: -3rem !important;
  }

  .mb-lg-n13 {
    margin-bottom: -3.25rem !important;
  }

  .mb-lg-n14 {
    margin-bottom: -3.5rem !important;
  }

  .mb-lg-n15 {
    margin-bottom: -3.75rem !important;
  }

  .mb-lg-n16 {
    margin-bottom: -4rem !important;
  }

  .mb-lg-n17 {
    margin-bottom: -4.25rem !important;
  }

  .mb-lg-n18 {
    margin-bottom: -4.5rem !important;
  }

  .mb-lg-n19 {
    margin-bottom: -4.75rem !important;
  }

  .mb-lg-n20 {
    margin-bottom: -5rem !important;
  }

  .ms-lg-n1 {
    margin-left: -0.25rem !important;
  }

  .ms-lg-n2 {
    margin-left: -0.5rem !important;
  }

  .ms-lg-n3 {
    margin-left: -0.75rem !important;
  }

  .ms-lg-n4 {
    margin-left: -1rem !important;
  }

  .ms-lg-n5 {
    margin-left: -1.25rem !important;
  }

  .ms-lg-n6 {
    margin-left: -1.5rem !important;
  }

  .ms-lg-n7 {
    margin-left: -1.75rem !important;
  }

  .ms-lg-n8 {
    margin-left: -2rem !important;
  }

  .ms-lg-n9 {
    margin-left: -2.25rem !important;
  }

  .ms-lg-n10 {
    margin-left: -2.5rem !important;
  }

  .ms-lg-n11 {
    margin-left: -2.75rem !important;
  }

  .ms-lg-n12 {
    margin-left: -3rem !important;
  }

  .ms-lg-n13 {
    margin-left: -3.25rem !important;
  }

  .ms-lg-n14 {
    margin-left: -3.5rem !important;
  }

  .ms-lg-n15 {
    margin-left: -3.75rem !important;
  }

  .ms-lg-n16 {
    margin-left: -4rem !important;
  }

  .ms-lg-n17 {
    margin-left: -4.25rem !important;
  }

  .ms-lg-n18 {
    margin-left: -4.5rem !important;
  }

  .ms-lg-n19 {
    margin-left: -4.75rem !important;
  }

  .ms-lg-n20 {
    margin-left: -5rem !important;
  }

  .p-lg-0 {
    padding: 0 !important;
  }

  .p-lg-1 {
    padding: 0.25rem !important;
  }

  .p-lg-2 {
    padding: 0.5rem !important;
  }

  .p-lg-3 {
    padding: 0.75rem !important;
  }

  .p-lg-4 {
    padding: 1rem !important;
  }

  .p-lg-5 {
    padding: 1.25rem !important;
  }

  .p-lg-6 {
    padding: 1.5rem !important;
  }

  .p-lg-7 {
    padding: 1.75rem !important;
  }

  .p-lg-8 {
    padding: 2rem !important;
  }

  .p-lg-9 {
    padding: 2.25rem !important;
  }

  .p-lg-10 {
    padding: 2.5rem !important;
  }

  .p-lg-11 {
    padding: 2.75rem !important;
  }

  .p-lg-12 {
    padding: 3rem !important;
  }

  .p-lg-13 {
    padding: 3.25rem !important;
  }

  .p-lg-14 {
    padding: 3.5rem !important;
  }

  .p-lg-15 {
    padding: 3.75rem !important;
  }

  .p-lg-16 {
    padding: 4rem !important;
  }

  .p-lg-17 {
    padding: 4.25rem !important;
  }

  .p-lg-18 {
    padding: 4.5rem !important;
  }

  .p-lg-19 {
    padding: 4.75rem !important;
  }

  .p-lg-20 {
    padding: 5rem !important;
  }

  .px-lg-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .px-lg-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }

  .px-lg-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }

  .px-lg-3 {
    padding-right: 0.75rem !important;
    padding-left: 0.75rem !important;
  }

  .px-lg-4 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }

  .px-lg-5 {
    padding-right: 1.25rem !important;
    padding-left: 1.25rem !important;
  }

  .px-lg-6 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }

  .px-lg-7 {
    padding-right: 1.75rem !important;
    padding-left: 1.75rem !important;
  }

  .px-lg-8 {
    padding-right: 2rem !important;
    padding-left: 2rem !important;
  }

  .px-lg-9 {
    padding-right: 2.25rem !important;
    padding-left: 2.25rem !important;
  }

  .px-lg-10 {
    padding-right: 2.5rem !important;
    padding-left: 2.5rem !important;
  }

  .px-lg-11 {
    padding-right: 2.75rem !important;
    padding-left: 2.75rem !important;
  }

  .px-lg-12 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }

  .px-lg-13 {
    padding-right: 3.25rem !important;
    padding-left: 3.25rem !important;
  }

  .px-lg-14 {
    padding-right: 3.5rem !important;
    padding-left: 3.5rem !important;
  }

  .px-lg-15 {
    padding-right: 3.75rem !important;
    padding-left: 3.75rem !important;
  }

  .px-lg-16 {
    padding-right: 4rem !important;
    padding-left: 4rem !important;
  }

  .px-lg-17 {
    padding-right: 4.25rem !important;
    padding-left: 4.25rem !important;
  }

  .px-lg-18 {
    padding-right: 4.5rem !important;
    padding-left: 4.5rem !important;
  }

  .px-lg-19 {
    padding-right: 4.75rem !important;
    padding-left: 4.75rem !important;
  }

  .px-lg-20 {
    padding-right: 5rem !important;
    padding-left: 5rem !important;
  }

  .py-lg-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-lg-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }

  .py-lg-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .py-lg-3 {
    padding-top: 0.75rem !important;
    padding-bottom: 0.75rem !important;
  }

  .py-lg-4 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-lg-5 {
    padding-top: 1.25rem !important;
    padding-bottom: 1.25rem !important;
  }

  .py-lg-6 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-lg-7 {
    padding-top: 1.75rem !important;
    padding-bottom: 1.75rem !important;
  }

  .py-lg-8 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }

  .py-lg-9 {
    padding-top: 2.25rem !important;
    padding-bottom: 2.25rem !important;
  }

  .py-lg-10 {
    padding-top: 2.5rem !important;
    padding-bottom: 2.5rem !important;
  }

  .py-lg-11 {
    padding-top: 2.75rem !important;
    padding-bottom: 2.75rem !important;
  }

  .py-lg-12 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .py-lg-13 {
    padding-top: 3.25rem !important;
    padding-bottom: 3.25rem !important;
  }

  .py-lg-14 {
    padding-top: 3.5rem !important;
    padding-bottom: 3.5rem !important;
  }

  .py-lg-15 {
    padding-top: 3.75rem !important;
    padding-bottom: 3.75rem !important;
  }

  .py-lg-16 {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important;
  }

  .py-lg-17 {
    padding-top: 4.25rem !important;
    padding-bottom: 4.25rem !important;
  }

  .py-lg-18 {
    padding-top: 4.5rem !important;
    padding-bottom: 4.5rem !important;
  }

  .py-lg-19 {
    padding-top: 4.75rem !important;
    padding-bottom: 4.75rem !important;
  }

  .py-lg-20 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
  }

  .pt-lg-0 {
    padding-top: 0 !important;
  }

  .pt-lg-1 {
    padding-top: 0.25rem !important;
  }

  .pt-lg-2 {
    padding-top: 0.5rem !important;
  }

  .pt-lg-3 {
    padding-top: 0.75rem !important;
  }

  .pt-lg-4 {
    padding-top: 1rem !important;
  }

  .pt-lg-5 {
    padding-top: 1.25rem !important;
  }

  .pt-lg-6 {
    padding-top: 1.5rem !important;
  }

  .pt-lg-7 {
    padding-top: 1.75rem !important;
  }

  .pt-lg-8 {
    padding-top: 2rem !important;
  }

  .pt-lg-9 {
    padding-top: 2.25rem !important;
  }

  .pt-lg-10 {
    padding-top: 2.5rem !important;
  }

  .pt-lg-11 {
    padding-top: 2.75rem !important;
  }

  .pt-lg-12 {
    padding-top: 3rem !important;
  }

  .pt-lg-13 {
    padding-top: 3.25rem !important;
  }

  .pt-lg-14 {
    padding-top: 3.5rem !important;
  }

  .pt-lg-15 {
    padding-top: 3.75rem !important;
  }

  .pt-lg-16 {
    padding-top: 4rem !important;
  }

  .pt-lg-17 {
    padding-top: 4.25rem !important;
  }

  .pt-lg-18 {
    padding-top: 4.5rem !important;
  }

  .pt-lg-19 {
    padding-top: 4.75rem !important;
  }

  .pt-lg-20 {
    padding-top: 5rem !important;
  }

  .pe-lg-0 {
    padding-right: 0 !important;
  }

  .pe-lg-1 {
    padding-right: 0.25rem !important;
  }

  .pe-lg-2 {
    padding-right: 0.5rem !important;
  }

  .pe-lg-3 {
    padding-right: 0.75rem !important;
  }

  .pe-lg-4 {
    padding-right: 1rem !important;
  }

  .pe-lg-5 {
    padding-right: 1.25rem !important;
  }

  .pe-lg-6 {
    padding-right: 1.5rem !important;
  }

  .pe-lg-7 {
    padding-right: 1.75rem !important;
  }

  .pe-lg-8 {
    padding-right: 2rem !important;
  }

  .pe-lg-9 {
    padding-right: 2.25rem !important;
  }

  .pe-lg-10 {
    padding-right: 2.5rem !important;
  }

  .pe-lg-11 {
    padding-right: 2.75rem !important;
  }

  .pe-lg-12 {
    padding-right: 3rem !important;
  }

  .pe-lg-13 {
    padding-right: 3.25rem !important;
  }

  .pe-lg-14 {
    padding-right: 3.5rem !important;
  }

  .pe-lg-15 {
    padding-right: 3.75rem !important;
  }

  .pe-lg-16 {
    padding-right: 4rem !important;
  }

  .pe-lg-17 {
    padding-right: 4.25rem !important;
  }

  .pe-lg-18 {
    padding-right: 4.5rem !important;
  }

  .pe-lg-19 {
    padding-right: 4.75rem !important;
  }

  .pe-lg-20 {
    padding-right: 5rem !important;
  }

  .pb-lg-0 {
    padding-bottom: 0 !important;
  }

  .pb-lg-1 {
    padding-bottom: 0.25rem !important;
  }

  .pb-lg-2 {
    padding-bottom: 0.5rem !important;
  }

  .pb-lg-3 {
    padding-bottom: 0.75rem !important;
  }

  .pb-lg-4 {
    padding-bottom: 1rem !important;
  }

  .pb-lg-5 {
    padding-bottom: 1.25rem !important;
  }

  .pb-lg-6 {
    padding-bottom: 1.5rem !important;
  }

  .pb-lg-7 {
    padding-bottom: 1.75rem !important;
  }

  .pb-lg-8 {
    padding-bottom: 2rem !important;
  }

  .pb-lg-9 {
    padding-bottom: 2.25rem !important;
  }

  .pb-lg-10 {
    padding-bottom: 2.5rem !important;
  }

  .pb-lg-11 {
    padding-bottom: 2.75rem !important;
  }

  .pb-lg-12 {
    padding-bottom: 3rem !important;
  }

  .pb-lg-13 {
    padding-bottom: 3.25rem !important;
  }

  .pb-lg-14 {
    padding-bottom: 3.5rem !important;
  }

  .pb-lg-15 {
    padding-bottom: 3.75rem !important;
  }

  .pb-lg-16 {
    padding-bottom: 4rem !important;
  }

  .pb-lg-17 {
    padding-bottom: 4.25rem !important;
  }

  .pb-lg-18 {
    padding-bottom: 4.5rem !important;
  }

  .pb-lg-19 {
    padding-bottom: 4.75rem !important;
  }

  .pb-lg-20 {
    padding-bottom: 5rem !important;
  }

  .ps-lg-0 {
    padding-left: 0 !important;
  }

  .ps-lg-1 {
    padding-left: 0.25rem !important;
  }

  .ps-lg-2 {
    padding-left: 0.5rem !important;
  }

  .ps-lg-3 {
    padding-left: 0.75rem !important;
  }

  .ps-lg-4 {
    padding-left: 1rem !important;
  }

  .ps-lg-5 {
    padding-left: 1.25rem !important;
  }

  .ps-lg-6 {
    padding-left: 1.5rem !important;
  }

  .ps-lg-7 {
    padding-left: 1.75rem !important;
  }

  .ps-lg-8 {
    padding-left: 2rem !important;
  }

  .ps-lg-9 {
    padding-left: 2.25rem !important;
  }

  .ps-lg-10 {
    padding-left: 2.5rem !important;
  }

  .ps-lg-11 {
    padding-left: 2.75rem !important;
  }

  .ps-lg-12 {
    padding-left: 3rem !important;
  }

  .ps-lg-13 {
    padding-left: 3.25rem !important;
  }

  .ps-lg-14 {
    padding-left: 3.5rem !important;
  }

  .ps-lg-15 {
    padding-left: 3.75rem !important;
  }

  .ps-lg-16 {
    padding-left: 4rem !important;
  }

  .ps-lg-17 {
    padding-left: 4.25rem !important;
  }

  .ps-lg-18 {
    padding-left: 4.5rem !important;
  }

  .ps-lg-19 {
    padding-left: 4.75rem !important;
  }

  .ps-lg-20 {
    padding-left: 5rem !important;
  }

  .fs-lg-1 {
    font-size: calc(1.3rem + 0.6vw) !important;
  }

  .fs-lg-2 {
    font-size: calc(1.275rem + 0.3vw) !important;
  }

  .fs-lg-3 {
    font-size: calc(1.26rem + 0.12vw) !important;
  }

  .fs-lg-4 {
    font-size: 1.25rem !important;
  }

  .fs-lg-5 {
    font-size: 1.15rem !important;
  }

  .fs-lg-6 {
    font-size: 1.075rem !important;
  }

  .fs-lg-7 {
    font-size: 0.95rem !important;
  }

  .fs-lg-8 {
    font-size: 0.85rem !important;
  }

  .fs-lg-9 {
    font-size: 0.75rem !important;
  }

  .fs-lg-10 {
    font-size: 0.5rem !important;
  }

  .fs-lg-base {
    font-size: 1rem !important;
  }

  .fs-lg-fluid {
    font-size: 100% !important;
  }

  .fs-lg-2x {
    font-size: calc(1.325rem + 0.9vw) !important;
  }

  .fs-lg-2qx {
    font-size: calc(1.35rem + 1.2vw) !important;
  }

  .fs-lg-2hx {
    font-size: calc(1.375rem + 1.5vw) !important;
  }

  .fs-lg-2tx {
    font-size: calc(1.4rem + 1.8vw) !important;
  }

  .fs-lg-3x {
    font-size: calc(1.425rem + 2.1vw) !important;
  }

  .fs-lg-3qx {
    font-size: calc(1.45rem + 2.4vw) !important;
  }

  .fs-lg-3hx {
    font-size: calc(1.475rem + 2.7vw) !important;
  }

  .fs-lg-3tx {
    font-size: calc(1.5rem + 3vw) !important;
  }

  .fs-lg-4x {
    font-size: calc(1.525rem + 3.3vw) !important;
  }

  .fs-lg-4qx {
    font-size: calc(1.55rem + 3.6vw) !important;
  }

  .fs-lg-4hx {
    font-size: calc(1.575rem + 3.9vw) !important;
  }

  .fs-lg-4tx {
    font-size: calc(1.6rem + 4.2vw) !important;
  }

  .fs-lg-5x {
    font-size: calc(1.625rem + 4.5vw) !important;
  }

  .fs-lg-5qx {
    font-size: calc(1.65rem + 4.8vw) !important;
  }

  .fs-lg-5hx {
    font-size: calc(1.675rem + 5.1vw) !important;
  }

  .fs-lg-5tx {
    font-size: calc(1.7rem + 5.4vw) !important;
  }

  .text-lg-start {
    text-align: left !important;
  }

  .text-lg-end {
    text-align: right !important;
  }

  .text-lg-center {
    text-align: center !important;
  }

  .min-w-lg-unset {
    min-width: unset !important;
  }

  .min-w-lg-25 {
    min-width: 25% !important;
  }

  .min-w-lg-50 {
    min-width: 50% !important;
  }

  .min-w-lg-75 {
    min-width: 75% !important;
  }

  .min-w-lg-100 {
    min-width: 100% !important;
  }

  .min-w-lg-auto {
    min-width: auto !important;
  }

  .min-w-lg-1px {
    min-width: 1px !important;
  }

  .min-w-lg-2px {
    min-width: 2px !important;
  }

  .min-w-lg-3px {
    min-width: 3px !important;
  }

  .min-w-lg-4px {
    min-width: 4px !important;
  }

  .min-w-lg-5px {
    min-width: 5px !important;
  }

  .min-w-lg-6px {
    min-width: 6px !important;
  }

  .min-w-lg-7px {
    min-width: 7px !important;
  }

  .min-w-lg-8px {
    min-width: 8px !important;
  }

  .min-w-lg-9px {
    min-width: 9px !important;
  }

  .min-w-lg-10px {
    min-width: 10px !important;
  }

  .min-w-lg-15px {
    min-width: 15px !important;
  }

  .min-w-lg-20px {
    min-width: 20px !important;
  }

  .min-w-lg-25px {
    min-width: 25px !important;
  }

  .min-w-lg-30px {
    min-width: 30px !important;
  }

  .min-w-lg-35px {
    min-width: 35px !important;
  }

  .min-w-lg-40px {
    min-width: 40px !important;
  }

  .min-w-lg-45px {
    min-width: 45px !important;
  }

  .min-w-lg-50px {
    min-width: 50px !important;
  }

  .min-w-lg-55px {
    min-width: 55px !important;
  }

  .min-w-lg-60px {
    min-width: 60px !important;
  }

  .min-w-lg-65px {
    min-width: 65px !important;
  }

  .min-w-lg-70px {
    min-width: 70px !important;
  }

  .min-w-lg-75px {
    min-width: 75px !important;
  }

  .min-w-lg-80px {
    min-width: 80px !important;
  }

  .min-w-lg-85px {
    min-width: 85px !important;
  }

  .min-w-lg-90px {
    min-width: 90px !important;
  }

  .min-w-lg-95px {
    min-width: 95px !important;
  }

  .min-w-lg-100px {
    min-width: 100px !important;
  }

  .min-w-lg-125px {
    min-width: 125px !important;
  }

  .min-w-lg-150px {
    min-width: 150px !important;
  }

  .min-w-lg-175px {
    min-width: 175px !important;
  }

  .min-w-lg-200px {
    min-width: 200px !important;
  }

  .min-w-lg-225px {
    min-width: 225px !important;
  }

  .min-w-lg-250px {
    min-width: 250px !important;
  }

  .min-w-lg-275px {
    min-width: 275px !important;
  }

  .min-w-lg-300px {
    min-width: 300px !important;
  }

  .min-w-lg-325px {
    min-width: 325px !important;
  }

  .min-w-lg-350px {
    min-width: 350px !important;
  }

  .min-w-lg-375px {
    min-width: 375px !important;
  }

  .min-w-lg-400px {
    min-width: 400px !important;
  }

  .min-w-lg-425px {
    min-width: 425px !important;
  }

  .min-w-lg-450px {
    min-width: 450px !important;
  }

  .min-w-lg-475px {
    min-width: 475px !important;
  }

  .min-w-lg-500px {
    min-width: 500px !important;
  }

  .min-w-lg-550px {
    min-width: 550px !important;
  }

  .min-w-lg-600px {
    min-width: 600px !important;
  }

  .min-w-lg-650px {
    min-width: 650px !important;
  }

  .min-w-lg-700px {
    min-width: 700px !important;
  }

  .min-w-lg-750px {
    min-width: 750px !important;
  }

  .min-w-lg-800px {
    min-width: 800px !important;
  }

  .min-w-lg-850px {
    min-width: 850px !important;
  }

  .min-w-lg-900px {
    min-width: 900px !important;
  }

  .min-w-lg-950px {
    min-width: 950px !important;
  }

  .min-w-lg-1000px {
    min-width: 1000px !important;
  }

  .min-h-lg-unset {
    min-height: unset !important;
  }

  .min-h-lg-25 {
    min-height: 25% !important;
  }

  .min-h-lg-50 {
    min-height: 50% !important;
  }

  .min-h-lg-75 {
    min-height: 75% !important;
  }

  .min-h-lg-100 {
    min-height: 100% !important;
  }

  .min-h-lg-auto {
    min-height: auto !important;
  }

  .min-h-lg-1px {
    min-height: 1px !important;
  }

  .min-h-lg-2px {
    min-height: 2px !important;
  }

  .min-h-lg-3px {
    min-height: 3px !important;
  }

  .min-h-lg-4px {
    min-height: 4px !important;
  }

  .min-h-lg-5px {
    min-height: 5px !important;
  }

  .min-h-lg-6px {
    min-height: 6px !important;
  }

  .min-h-lg-7px {
    min-height: 7px !important;
  }

  .min-h-lg-8px {
    min-height: 8px !important;
  }

  .min-h-lg-9px {
    min-height: 9px !important;
  }

  .min-h-lg-10px {
    min-height: 10px !important;
  }

  .min-h-lg-15px {
    min-height: 15px !important;
  }

  .min-h-lg-20px {
    min-height: 20px !important;
  }

  .min-h-lg-25px {
    min-height: 25px !important;
  }

  .min-h-lg-30px {
    min-height: 30px !important;
  }

  .min-h-lg-35px {
    min-height: 35px !important;
  }

  .min-h-lg-40px {
    min-height: 40px !important;
  }

  .min-h-lg-45px {
    min-height: 45px !important;
  }

  .min-h-lg-50px {
    min-height: 50px !important;
  }

  .min-h-lg-55px {
    min-height: 55px !important;
  }

  .min-h-lg-60px {
    min-height: 60px !important;
  }

  .min-h-lg-65px {
    min-height: 65px !important;
  }

  .min-h-lg-70px {
    min-height: 70px !important;
  }

  .min-h-lg-75px {
    min-height: 75px !important;
  }

  .min-h-lg-80px {
    min-height: 80px !important;
  }

  .min-h-lg-85px {
    min-height: 85px !important;
  }

  .min-h-lg-90px {
    min-height: 90px !important;
  }

  .min-h-lg-95px {
    min-height: 95px !important;
  }

  .min-h-lg-100px {
    min-height: 100px !important;
  }

  .min-h-lg-125px {
    min-height: 125px !important;
  }

  .min-h-lg-150px {
    min-height: 150px !important;
  }

  .min-h-lg-175px {
    min-height: 175px !important;
  }

  .min-h-lg-200px {
    min-height: 200px !important;
  }

  .min-h-lg-225px {
    min-height: 225px !important;
  }

  .min-h-lg-250px {
    min-height: 250px !important;
  }

  .min-h-lg-275px {
    min-height: 275px !important;
  }

  .min-h-lg-300px {
    min-height: 300px !important;
  }

  .min-h-lg-325px {
    min-height: 325px !important;
  }

  .min-h-lg-350px {
    min-height: 350px !important;
  }

  .min-h-lg-375px {
    min-height: 375px !important;
  }

  .min-h-lg-400px {
    min-height: 400px !important;
  }

  .min-h-lg-425px {
    min-height: 425px !important;
  }

  .min-h-lg-450px {
    min-height: 450px !important;
  }

  .min-h-lg-475px {
    min-height: 475px !important;
  }

  .min-h-lg-500px {
    min-height: 500px !important;
  }

  .min-h-lg-550px {
    min-height: 550px !important;
  }

  .min-h-lg-600px {
    min-height: 600px !important;
  }

  .min-h-lg-650px {
    min-height: 650px !important;
  }

  .min-h-lg-700px {
    min-height: 700px !important;
  }

  .min-h-lg-750px {
    min-height: 750px !important;
  }

  .min-h-lg-800px {
    min-height: 800px !important;
  }

  .min-h-lg-850px {
    min-height: 850px !important;
  }

  .min-h-lg-900px {
    min-height: 900px !important;
  }

  .min-h-lg-950px {
    min-height: 950px !important;
  }

  .min-h-lg-1000px {
    min-height: 1000px !important;
  }
}
@media (min-width: 1200px) {
  .float-xl-start {
    float: left !important;
  }

  .float-xl-end {
    float: right !important;
  }

  .float-xl-none {
    float: none !important;
  }

  .d-xl-inline {
    display: inline !important;
  }

  .d-xl-inline-block {
    display: inline-block !important;
  }

  .d-xl-block {
    display: block !important;
  }

  .d-xl-grid {
    display: grid !important;
  }

  .d-xl-table {
    display: table !important;
  }

  .d-xl-table-row {
    display: table-row !important;
  }

  .d-xl-table-cell {
    display: table-cell !important;
  }

  .d-xl-flex {
    display: flex !important;
  }

  .d-xl-inline-flex {
    display: inline-flex !important;
  }

  .d-xl-none {
    display: none !important;
  }

  .w-xl-unset {
    width: unset !important;
  }

  .w-xl-25 {
    width: 25% !important;
  }

  .w-xl-50 {
    width: 50% !important;
  }

  .w-xl-75 {
    width: 75% !important;
  }

  .w-xl-100 {
    width: 100% !important;
  }

  .w-xl-auto {
    width: auto !important;
  }

  .w-xl-1px {
    width: 1px !important;
  }

  .w-xl-2px {
    width: 2px !important;
  }

  .w-xl-3px {
    width: 3px !important;
  }

  .w-xl-4px {
    width: 4px !important;
  }

  .w-xl-5px {
    width: 5px !important;
  }

  .w-xl-6px {
    width: 6px !important;
  }

  .w-xl-7px {
    width: 7px !important;
  }

  .w-xl-8px {
    width: 8px !important;
  }

  .w-xl-9px {
    width: 9px !important;
  }

  .w-xl-10px {
    width: 10px !important;
  }

  .w-xl-15px {
    width: 15px !important;
  }

  .w-xl-20px {
    width: 20px !important;
  }

  .w-xl-25px {
    width: 25px !important;
  }

  .w-xl-30px {
    width: 30px !important;
  }

  .w-xl-35px {
    width: 35px !important;
  }

  .w-xl-40px {
    width: 40px !important;
  }

  .w-xl-45px {
    width: 45px !important;
  }

  .w-xl-50px {
    width: 50px !important;
  }

  .w-xl-55px {
    width: 55px !important;
  }

  .w-xl-60px {
    width: 60px !important;
  }

  .w-xl-65px {
    width: 65px !important;
  }

  .w-xl-70px {
    width: 70px !important;
  }

  .w-xl-75px {
    width: 75px !important;
  }

  .w-xl-80px {
    width: 80px !important;
  }

  .w-xl-85px {
    width: 85px !important;
  }

  .w-xl-90px {
    width: 90px !important;
  }

  .w-xl-95px {
    width: 95px !important;
  }

  .w-xl-100px {
    width: 100px !important;
  }

  .w-xl-125px {
    width: 125px !important;
  }

  .w-xl-150px {
    width: 150px !important;
  }

  .w-xl-175px {
    width: 175px !important;
  }

  .w-xl-200px {
    width: 200px !important;
  }

  .w-xl-225px {
    width: 225px !important;
  }

  .w-xl-250px {
    width: 250px !important;
  }

  .w-xl-275px {
    width: 275px !important;
  }

  .w-xl-300px {
    width: 300px !important;
  }

  .w-xl-325px {
    width: 325px !important;
  }

  .w-xl-350px {
    width: 350px !important;
  }

  .w-xl-375px {
    width: 375px !important;
  }

  .w-xl-400px {
    width: 400px !important;
  }

  .w-xl-425px {
    width: 425px !important;
  }

  .w-xl-450px {
    width: 450px !important;
  }

  .w-xl-475px {
    width: 475px !important;
  }

  .w-xl-500px {
    width: 500px !important;
  }

  .w-xl-550px {
    width: 550px !important;
  }

  .w-xl-600px {
    width: 600px !important;
  }

  .w-xl-650px {
    width: 650px !important;
  }

  .w-xl-700px {
    width: 700px !important;
  }

  .w-xl-750px {
    width: 750px !important;
  }

  .w-xl-800px {
    width: 800px !important;
  }

  .w-xl-850px {
    width: 850px !important;
  }

  .w-xl-900px {
    width: 900px !important;
  }

  .w-xl-950px {
    width: 950px !important;
  }

  .w-xl-1000px {
    width: 1000px !important;
  }

  .mw-xl-unset {
    max-width: unset !important;
  }

  .mw-xl-25 {
    max-width: 25% !important;
  }

  .mw-xl-50 {
    max-width: 50% !important;
  }

  .mw-xl-75 {
    max-width: 75% !important;
  }

  .mw-xl-100 {
    max-width: 100% !important;
  }

  .mw-xl-auto {
    max-width: auto !important;
  }

  .mw-xl-1px {
    max-width: 1px !important;
  }

  .mw-xl-2px {
    max-width: 2px !important;
  }

  .mw-xl-3px {
    max-width: 3px !important;
  }

  .mw-xl-4px {
    max-width: 4px !important;
  }

  .mw-xl-5px {
    max-width: 5px !important;
  }

  .mw-xl-6px {
    max-width: 6px !important;
  }

  .mw-xl-7px {
    max-width: 7px !important;
  }

  .mw-xl-8px {
    max-width: 8px !important;
  }

  .mw-xl-9px {
    max-width: 9px !important;
  }

  .mw-xl-10px {
    max-width: 10px !important;
  }

  .mw-xl-15px {
    max-width: 15px !important;
  }

  .mw-xl-20px {
    max-width: 20px !important;
  }

  .mw-xl-25px {
    max-width: 25px !important;
  }

  .mw-xl-30px {
    max-width: 30px !important;
  }

  .mw-xl-35px {
    max-width: 35px !important;
  }

  .mw-xl-40px {
    max-width: 40px !important;
  }

  .mw-xl-45px {
    max-width: 45px !important;
  }

  .mw-xl-50px {
    max-width: 50px !important;
  }

  .mw-xl-55px {
    max-width: 55px !important;
  }

  .mw-xl-60px {
    max-width: 60px !important;
  }

  .mw-xl-65px {
    max-width: 65px !important;
  }

  .mw-xl-70px {
    max-width: 70px !important;
  }

  .mw-xl-75px {
    max-width: 75px !important;
  }

  .mw-xl-80px {
    max-width: 80px !important;
  }

  .mw-xl-85px {
    max-width: 85px !important;
  }

  .mw-xl-90px {
    max-width: 90px !important;
  }

  .mw-xl-95px {
    max-width: 95px !important;
  }

  .mw-xl-100px {
    max-width: 100px !important;
  }

  .mw-xl-125px {
    max-width: 125px !important;
  }

  .mw-xl-150px {
    max-width: 150px !important;
  }

  .mw-xl-175px {
    max-width: 175px !important;
  }

  .mw-xl-200px {
    max-width: 200px !important;
  }

  .mw-xl-225px {
    max-width: 225px !important;
  }

  .mw-xl-250px {
    max-width: 250px !important;
  }

  .mw-xl-275px {
    max-width: 275px !important;
  }

  .mw-xl-300px {
    max-width: 300px !important;
  }

  .mw-xl-325px {
    max-width: 325px !important;
  }

  .mw-xl-350px {
    max-width: 350px !important;
  }

  .mw-xl-375px {
    max-width: 375px !important;
  }

  .mw-xl-400px {
    max-width: 400px !important;
  }

  .mw-xl-425px {
    max-width: 425px !important;
  }

  .mw-xl-450px {
    max-width: 450px !important;
  }

  .mw-xl-475px {
    max-width: 475px !important;
  }

  .mw-xl-500px {
    max-width: 500px !important;
  }

  .mw-xl-550px {
    max-width: 550px !important;
  }

  .mw-xl-600px {
    max-width: 600px !important;
  }

  .mw-xl-650px {
    max-width: 650px !important;
  }

  .mw-xl-700px {
    max-width: 700px !important;
  }

  .mw-xl-750px {
    max-width: 750px !important;
  }

  .mw-xl-800px {
    max-width: 800px !important;
  }

  .mw-xl-850px {
    max-width: 850px !important;
  }

  .mw-xl-900px {
    max-width: 900px !important;
  }

  .mw-xl-950px {
    max-width: 950px !important;
  }

  .mw-xl-1000px {
    max-width: 1000px !important;
  }

  .h-xl-unset {
    height: unset !important;
  }

  .h-xl-25 {
    height: 25% !important;
  }

  .h-xl-50 {
    height: 50% !important;
  }

  .h-xl-75 {
    height: 75% !important;
  }

  .h-xl-100 {
    height: 100% !important;
  }

  .h-xl-auto {
    height: auto !important;
  }

  .h-xl-1px {
    height: 1px !important;
  }

  .h-xl-2px {
    height: 2px !important;
  }

  .h-xl-3px {
    height: 3px !important;
  }

  .h-xl-4px {
    height: 4px !important;
  }

  .h-xl-5px {
    height: 5px !important;
  }

  .h-xl-6px {
    height: 6px !important;
  }

  .h-xl-7px {
    height: 7px !important;
  }

  .h-xl-8px {
    height: 8px !important;
  }

  .h-xl-9px {
    height: 9px !important;
  }

  .h-xl-10px {
    height: 10px !important;
  }

  .h-xl-15px {
    height: 15px !important;
  }

  .h-xl-20px {
    height: 20px !important;
  }

  .h-xl-25px {
    height: 25px !important;
  }

  .h-xl-30px {
    height: 30px !important;
  }

  .h-xl-35px {
    height: 35px !important;
  }

  .h-xl-40px {
    height: 40px !important;
  }

  .h-xl-45px {
    height: 45px !important;
  }

  .h-xl-50px {
    height: 50px !important;
  }

  .h-xl-55px {
    height: 55px !important;
  }

  .h-xl-60px {
    height: 60px !important;
  }

  .h-xl-65px {
    height: 65px !important;
  }

  .h-xl-70px {
    height: 70px !important;
  }

  .h-xl-75px {
    height: 75px !important;
  }

  .h-xl-80px {
    height: 80px !important;
  }

  .h-xl-85px {
    height: 85px !important;
  }

  .h-xl-90px {
    height: 90px !important;
  }

  .h-xl-95px {
    height: 95px !important;
  }

  .h-xl-100px {
    height: 100px !important;
  }

  .h-xl-125px {
    height: 125px !important;
  }

  .h-xl-150px {
    height: 150px !important;
  }

  .h-xl-175px {
    height: 175px !important;
  }

  .h-xl-200px {
    height: 200px !important;
  }

  .h-xl-225px {
    height: 225px !important;
  }

  .h-xl-250px {
    height: 250px !important;
  }

  .h-xl-275px {
    height: 275px !important;
  }

  .h-xl-300px {
    height: 300px !important;
  }

  .h-xl-325px {
    height: 325px !important;
  }

  .h-xl-350px {
    height: 350px !important;
  }

  .h-xl-375px {
    height: 375px !important;
  }

  .h-xl-400px {
    height: 400px !important;
  }

  .h-xl-425px {
    height: 425px !important;
  }

  .h-xl-450px {
    height: 450px !important;
  }

  .h-xl-475px {
    height: 475px !important;
  }

  .h-xl-500px {
    height: 500px !important;
  }

  .h-xl-550px {
    height: 550px !important;
  }

  .h-xl-600px {
    height: 600px !important;
  }

  .h-xl-650px {
    height: 650px !important;
  }

  .h-xl-700px {
    height: 700px !important;
  }

  .h-xl-750px {
    height: 750px !important;
  }

  .h-xl-800px {
    height: 800px !important;
  }

  .h-xl-850px {
    height: 850px !important;
  }

  .h-xl-900px {
    height: 900px !important;
  }

  .h-xl-950px {
    height: 950px !important;
  }

  .h-xl-1000px {
    height: 1000px !important;
  }

  .mh-xl-unset {
    max-height: unset !important;
  }

  .mh-xl-25 {
    max-height: 25% !important;
  }

  .mh-xl-50 {
    max-height: 50% !important;
  }

  .mh-xl-75 {
    max-height: 75% !important;
  }

  .mh-xl-100 {
    max-height: 100% !important;
  }

  .mh-xl-auto {
    max-height: auto !important;
  }

  .mh-xl-1px {
    max-height: 1px !important;
  }

  .mh-xl-2px {
    max-height: 2px !important;
  }

  .mh-xl-3px {
    max-height: 3px !important;
  }

  .mh-xl-4px {
    max-height: 4px !important;
  }

  .mh-xl-5px {
    max-height: 5px !important;
  }

  .mh-xl-6px {
    max-height: 6px !important;
  }

  .mh-xl-7px {
    max-height: 7px !important;
  }

  .mh-xl-8px {
    max-height: 8px !important;
  }

  .mh-xl-9px {
    max-height: 9px !important;
  }

  .mh-xl-10px {
    max-height: 10px !important;
  }

  .mh-xl-15px {
    max-height: 15px !important;
  }

  .mh-xl-20px {
    max-height: 20px !important;
  }

  .mh-xl-25px {
    max-height: 25px !important;
  }

  .mh-xl-30px {
    max-height: 30px !important;
  }

  .mh-xl-35px {
    max-height: 35px !important;
  }

  .mh-xl-40px {
    max-height: 40px !important;
  }

  .mh-xl-45px {
    max-height: 45px !important;
  }

  .mh-xl-50px {
    max-height: 50px !important;
  }

  .mh-xl-55px {
    max-height: 55px !important;
  }

  .mh-xl-60px {
    max-height: 60px !important;
  }

  .mh-xl-65px {
    max-height: 65px !important;
  }

  .mh-xl-70px {
    max-height: 70px !important;
  }

  .mh-xl-75px {
    max-height: 75px !important;
  }

  .mh-xl-80px {
    max-height: 80px !important;
  }

  .mh-xl-85px {
    max-height: 85px !important;
  }

  .mh-xl-90px {
    max-height: 90px !important;
  }

  .mh-xl-95px {
    max-height: 95px !important;
  }

  .mh-xl-100px {
    max-height: 100px !important;
  }

  .mh-xl-125px {
    max-height: 125px !important;
  }

  .mh-xl-150px {
    max-height: 150px !important;
  }

  .mh-xl-175px {
    max-height: 175px !important;
  }

  .mh-xl-200px {
    max-height: 200px !important;
  }

  .mh-xl-225px {
    max-height: 225px !important;
  }

  .mh-xl-250px {
    max-height: 250px !important;
  }

  .mh-xl-275px {
    max-height: 275px !important;
  }

  .mh-xl-300px {
    max-height: 300px !important;
  }

  .mh-xl-325px {
    max-height: 325px !important;
  }

  .mh-xl-350px {
    max-height: 350px !important;
  }

  .mh-xl-375px {
    max-height: 375px !important;
  }

  .mh-xl-400px {
    max-height: 400px !important;
  }

  .mh-xl-425px {
    max-height: 425px !important;
  }

  .mh-xl-450px {
    max-height: 450px !important;
  }

  .mh-xl-475px {
    max-height: 475px !important;
  }

  .mh-xl-500px {
    max-height: 500px !important;
  }

  .mh-xl-550px {
    max-height: 550px !important;
  }

  .mh-xl-600px {
    max-height: 600px !important;
  }

  .mh-xl-650px {
    max-height: 650px !important;
  }

  .mh-xl-700px {
    max-height: 700px !important;
  }

  .mh-xl-750px {
    max-height: 750px !important;
  }

  .mh-xl-800px {
    max-height: 800px !important;
  }

  .mh-xl-850px {
    max-height: 850px !important;
  }

  .mh-xl-900px {
    max-height: 900px !important;
  }

  .mh-xl-950px {
    max-height: 950px !important;
  }

  .mh-xl-1000px {
    max-height: 1000px !important;
  }

  .flex-xl-fill {
    flex: 1 1 auto !important;
  }

  .flex-xl-row {
    flex-direction: row !important;
  }

  .flex-xl-column {
    flex-direction: column !important;
  }

  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }

  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .gap-xl-0 {
    gap: 0 !important;
  }

  .gap-xl-1 {
    gap: 0.25rem !important;
  }

  .gap-xl-2 {
    gap: 0.5rem !important;
  }

  .gap-xl-3 {
    gap: 0.75rem !important;
  }

  .gap-xl-4 {
    gap: 1rem !important;
  }

  .gap-xl-5 {
    gap: 1.25rem !important;
  }

  .gap-xl-6 {
    gap: 1.5rem !important;
  }

  .gap-xl-7 {
    gap: 1.75rem !important;
  }

  .gap-xl-8 {
    gap: 2rem !important;
  }

  .gap-xl-9 {
    gap: 2.25rem !important;
  }

  .gap-xl-10 {
    gap: 2.5rem !important;
  }

  .gap-xl-11 {
    gap: 2.75rem !important;
  }

  .gap-xl-12 {
    gap: 3rem !important;
  }

  .gap-xl-13 {
    gap: 3.25rem !important;
  }

  .gap-xl-14 {
    gap: 3.5rem !important;
  }

  .gap-xl-15 {
    gap: 3.75rem !important;
  }

  .gap-xl-16 {
    gap: 4rem !important;
  }

  .gap-xl-17 {
    gap: 4.25rem !important;
  }

  .gap-xl-18 {
    gap: 4.5rem !important;
  }

  .gap-xl-19 {
    gap: 4.75rem !important;
  }

  .gap-xl-20 {
    gap: 5rem !important;
  }

  .justify-content-xl-start {
    justify-content: flex-start !important;
  }

  .justify-content-xl-end {
    justify-content: flex-end !important;
  }

  .justify-content-xl-center {
    justify-content: center !important;
  }

  .justify-content-xl-between {
    justify-content: space-between !important;
  }

  .justify-content-xl-around {
    justify-content: space-around !important;
  }

  .justify-content-xl-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-xl-start {
    align-items: flex-start !important;
  }

  .align-items-xl-end {
    align-items: flex-end !important;
  }

  .align-items-xl-center {
    align-items: center !important;
  }

  .align-items-xl-baseline {
    align-items: baseline !important;
  }

  .align-items-xl-stretch {
    align-items: stretch !important;
  }

  .align-content-xl-start {
    align-content: flex-start !important;
  }

  .align-content-xl-end {
    align-content: flex-end !important;
  }

  .align-content-xl-center {
    align-content: center !important;
  }

  .align-content-xl-between {
    align-content: space-between !important;
  }

  .align-content-xl-around {
    align-content: space-around !important;
  }

  .align-content-xl-stretch {
    align-content: stretch !important;
  }

  .align-self-xl-auto {
    align-self: auto !important;
  }

  .align-self-xl-start {
    align-self: flex-start !important;
  }

  .align-self-xl-end {
    align-self: flex-end !important;
  }

  .align-self-xl-center {
    align-self: center !important;
  }

  .align-self-xl-baseline {
    align-self: baseline !important;
  }

  .align-self-xl-stretch {
    align-self: stretch !important;
  }

  .order-xl-first {
    order: -1 !important;
  }

  .order-xl-0 {
    order: 0 !important;
  }

  .order-xl-1 {
    order: 1 !important;
  }

  .order-xl-2 {
    order: 2 !important;
  }

  .order-xl-3 {
    order: 3 !important;
  }

  .order-xl-4 {
    order: 4 !important;
  }

  .order-xl-5 {
    order: 5 !important;
  }

  .order-xl-last {
    order: 6 !important;
  }

  .m-xl-0 {
    margin: 0 !important;
  }

  .m-xl-1 {
    margin: 0.25rem !important;
  }

  .m-xl-2 {
    margin: 0.5rem !important;
  }

  .m-xl-3 {
    margin: 0.75rem !important;
  }

  .m-xl-4 {
    margin: 1rem !important;
  }

  .m-xl-5 {
    margin: 1.25rem !important;
  }

  .m-xl-6 {
    margin: 1.5rem !important;
  }

  .m-xl-7 {
    margin: 1.75rem !important;
  }

  .m-xl-8 {
    margin: 2rem !important;
  }

  .m-xl-9 {
    margin: 2.25rem !important;
  }

  .m-xl-10 {
    margin: 2.5rem !important;
  }

  .m-xl-11 {
    margin: 2.75rem !important;
  }

  .m-xl-12 {
    margin: 3rem !important;
  }

  .m-xl-13 {
    margin: 3.25rem !important;
  }

  .m-xl-14 {
    margin: 3.5rem !important;
  }

  .m-xl-15 {
    margin: 3.75rem !important;
  }

  .m-xl-16 {
    margin: 4rem !important;
  }

  .m-xl-17 {
    margin: 4.25rem !important;
  }

  .m-xl-18 {
    margin: 4.5rem !important;
  }

  .m-xl-19 {
    margin: 4.75rem !important;
  }

  .m-xl-20 {
    margin: 5rem !important;
  }

  .m-xl-auto {
    margin: auto !important;
  }

  .mx-xl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .mx-xl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }

  .mx-xl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }

  .mx-xl-3 {
    margin-right: 0.75rem !important;
    margin-left: 0.75rem !important;
  }

  .mx-xl-4 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }

  .mx-xl-5 {
    margin-right: 1.25rem !important;
    margin-left: 1.25rem !important;
  }

  .mx-xl-6 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }

  .mx-xl-7 {
    margin-right: 1.75rem !important;
    margin-left: 1.75rem !important;
  }

  .mx-xl-8 {
    margin-right: 2rem !important;
    margin-left: 2rem !important;
  }

  .mx-xl-9 {
    margin-right: 2.25rem !important;
    margin-left: 2.25rem !important;
  }

  .mx-xl-10 {
    margin-right: 2.5rem !important;
    margin-left: 2.5rem !important;
  }

  .mx-xl-11 {
    margin-right: 2.75rem !important;
    margin-left: 2.75rem !important;
  }

  .mx-xl-12 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }

  .mx-xl-13 {
    margin-right: 3.25rem !important;
    margin-left: 3.25rem !important;
  }

  .mx-xl-14 {
    margin-right: 3.5rem !important;
    margin-left: 3.5rem !important;
  }

  .mx-xl-15 {
    margin-right: 3.75rem !important;
    margin-left: 3.75rem !important;
  }

  .mx-xl-16 {
    margin-right: 4rem !important;
    margin-left: 4rem !important;
  }

  .mx-xl-17 {
    margin-right: 4.25rem !important;
    margin-left: 4.25rem !important;
  }

  .mx-xl-18 {
    margin-right: 4.5rem !important;
    margin-left: 4.5rem !important;
  }

  .mx-xl-19 {
    margin-right: 4.75rem !important;
    margin-left: 4.75rem !important;
  }

  .mx-xl-20 {
    margin-right: 5rem !important;
    margin-left: 5rem !important;
  }

  .mx-xl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .my-xl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-xl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }

  .my-xl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .my-xl-3 {
    margin-top: 0.75rem !important;
    margin-bottom: 0.75rem !important;
  }

  .my-xl-4 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-xl-5 {
    margin-top: 1.25rem !important;
    margin-bottom: 1.25rem !important;
  }

  .my-xl-6 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-xl-7 {
    margin-top: 1.75rem !important;
    margin-bottom: 1.75rem !important;
  }

  .my-xl-8 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }

  .my-xl-9 {
    margin-top: 2.25rem !important;
    margin-bottom: 2.25rem !important;
  }

  .my-xl-10 {
    margin-top: 2.5rem !important;
    margin-bottom: 2.5rem !important;
  }

  .my-xl-11 {
    margin-top: 2.75rem !important;
    margin-bottom: 2.75rem !important;
  }

  .my-xl-12 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-xl-13 {
    margin-top: 3.25rem !important;
    margin-bottom: 3.25rem !important;
  }

  .my-xl-14 {
    margin-top: 3.5rem !important;
    margin-bottom: 3.5rem !important;
  }

  .my-xl-15 {
    margin-top: 3.75rem !important;
    margin-bottom: 3.75rem !important;
  }

  .my-xl-16 {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important;
  }

  .my-xl-17 {
    margin-top: 4.25rem !important;
    margin-bottom: 4.25rem !important;
  }

  .my-xl-18 {
    margin-top: 4.5rem !important;
    margin-bottom: 4.5rem !important;
  }

  .my-xl-19 {
    margin-top: 4.75rem !important;
    margin-bottom: 4.75rem !important;
  }

  .my-xl-20 {
    margin-top: 5rem !important;
    margin-bottom: 5rem !important;
  }

  .my-xl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-xl-0 {
    margin-top: 0 !important;
  }

  .mt-xl-1 {
    margin-top: 0.25rem !important;
  }

  .mt-xl-2 {
    margin-top: 0.5rem !important;
  }

  .mt-xl-3 {
    margin-top: 0.75rem !important;
  }

  .mt-xl-4 {
    margin-top: 1rem !important;
  }

  .mt-xl-5 {
    margin-top: 1.25rem !important;
  }

  .mt-xl-6 {
    margin-top: 1.5rem !important;
  }

  .mt-xl-7 {
    margin-top: 1.75rem !important;
  }

  .mt-xl-8 {
    margin-top: 2rem !important;
  }

  .mt-xl-9 {
    margin-top: 2.25rem !important;
  }

  .mt-xl-10 {
    margin-top: 2.5rem !important;
  }

  .mt-xl-11 {
    margin-top: 2.75rem !important;
  }

  .mt-xl-12 {
    margin-top: 3rem !important;
  }

  .mt-xl-13 {
    margin-top: 3.25rem !important;
  }

  .mt-xl-14 {
    margin-top: 3.5rem !important;
  }

  .mt-xl-15 {
    margin-top: 3.75rem !important;
  }

  .mt-xl-16 {
    margin-top: 4rem !important;
  }

  .mt-xl-17 {
    margin-top: 4.25rem !important;
  }

  .mt-xl-18 {
    margin-top: 4.5rem !important;
  }

  .mt-xl-19 {
    margin-top: 4.75rem !important;
  }

  .mt-xl-20 {
    margin-top: 5rem !important;
  }

  .mt-xl-auto {
    margin-top: auto !important;
  }

  .me-xl-0 {
    margin-right: 0 !important;
  }

  .me-xl-1 {
    margin-right: 0.25rem !important;
  }

  .me-xl-2 {
    margin-right: 0.5rem !important;
  }

  .me-xl-3 {
    margin-right: 0.75rem !important;
  }

  .me-xl-4 {
    margin-right: 1rem !important;
  }

  .me-xl-5 {
    margin-right: 1.25rem !important;
  }

  .me-xl-6 {
    margin-right: 1.5rem !important;
  }

  .me-xl-7 {
    margin-right: 1.75rem !important;
  }

  .me-xl-8 {
    margin-right: 2rem !important;
  }

  .me-xl-9 {
    margin-right: 2.25rem !important;
  }

  .me-xl-10 {
    margin-right: 2.5rem !important;
  }

  .me-xl-11 {
    margin-right: 2.75rem !important;
  }

  .me-xl-12 {
    margin-right: 3rem !important;
  }

  .me-xl-13 {
    margin-right: 3.25rem !important;
  }

  .me-xl-14 {
    margin-right: 3.5rem !important;
  }

  .me-xl-15 {
    margin-right: 3.75rem !important;
  }

  .me-xl-16 {
    margin-right: 4rem !important;
  }

  .me-xl-17 {
    margin-right: 4.25rem !important;
  }

  .me-xl-18 {
    margin-right: 4.5rem !important;
  }

  .me-xl-19 {
    margin-right: 4.75rem !important;
  }

  .me-xl-20 {
    margin-right: 5rem !important;
  }

  .me-xl-auto {
    margin-right: auto !important;
  }

  .mb-xl-0 {
    margin-bottom: 0 !important;
  }

  .mb-xl-1 {
    margin-bottom: 0.25rem !important;
  }

  .mb-xl-2 {
    margin-bottom: 0.5rem !important;
  }

  .mb-xl-3 {
    margin-bottom: 0.75rem !important;
  }

  .mb-xl-4 {
    margin-bottom: 1rem !important;
  }

  .mb-xl-5 {
    margin-bottom: 1.25rem !important;
  }

  .mb-xl-6 {
    margin-bottom: 1.5rem !important;
  }

  .mb-xl-7 {
    margin-bottom: 1.75rem !important;
  }

  .mb-xl-8 {
    margin-bottom: 2rem !important;
  }

  .mb-xl-9 {
    margin-bottom: 2.25rem !important;
  }

  .mb-xl-10 {
    margin-bottom: 2.5rem !important;
  }

  .mb-xl-11 {
    margin-bottom: 2.75rem !important;
  }

  .mb-xl-12 {
    margin-bottom: 3rem !important;
  }

  .mb-xl-13 {
    margin-bottom: 3.25rem !important;
  }

  .mb-xl-14 {
    margin-bottom: 3.5rem !important;
  }

  .mb-xl-15 {
    margin-bottom: 3.75rem !important;
  }

  .mb-xl-16 {
    margin-bottom: 4rem !important;
  }

  .mb-xl-17 {
    margin-bottom: 4.25rem !important;
  }

  .mb-xl-18 {
    margin-bottom: 4.5rem !important;
  }

  .mb-xl-19 {
    margin-bottom: 4.75rem !important;
  }

  .mb-xl-20 {
    margin-bottom: 5rem !important;
  }

  .mb-xl-auto {
    margin-bottom: auto !important;
  }

  .ms-xl-0 {
    margin-left: 0 !important;
  }

  .ms-xl-1 {
    margin-left: 0.25rem !important;
  }

  .ms-xl-2 {
    margin-left: 0.5rem !important;
  }

  .ms-xl-3 {
    margin-left: 0.75rem !important;
  }

  .ms-xl-4 {
    margin-left: 1rem !important;
  }

  .ms-xl-5 {
    margin-left: 1.25rem !important;
  }

  .ms-xl-6 {
    margin-left: 1.5rem !important;
  }

  .ms-xl-7 {
    margin-left: 1.75rem !important;
  }

  .ms-xl-8 {
    margin-left: 2rem !important;
  }

  .ms-xl-9 {
    margin-left: 2.25rem !important;
  }

  .ms-xl-10 {
    margin-left: 2.5rem !important;
  }

  .ms-xl-11 {
    margin-left: 2.75rem !important;
  }

  .ms-xl-12 {
    margin-left: 3rem !important;
  }

  .ms-xl-13 {
    margin-left: 3.25rem !important;
  }

  .ms-xl-14 {
    margin-left: 3.5rem !important;
  }

  .ms-xl-15 {
    margin-left: 3.75rem !important;
  }

  .ms-xl-16 {
    margin-left: 4rem !important;
  }

  .ms-xl-17 {
    margin-left: 4.25rem !important;
  }

  .ms-xl-18 {
    margin-left: 4.5rem !important;
  }

  .ms-xl-19 {
    margin-left: 4.75rem !important;
  }

  .ms-xl-20 {
    margin-left: 5rem !important;
  }

  .ms-xl-auto {
    margin-left: auto !important;
  }

  .m-xl-n1 {
    margin: -0.25rem !important;
  }

  .m-xl-n2 {
    margin: -0.5rem !important;
  }

  .m-xl-n3 {
    margin: -0.75rem !important;
  }

  .m-xl-n4 {
    margin: -1rem !important;
  }

  .m-xl-n5 {
    margin: -1.25rem !important;
  }

  .m-xl-n6 {
    margin: -1.5rem !important;
  }

  .m-xl-n7 {
    margin: -1.75rem !important;
  }

  .m-xl-n8 {
    margin: -2rem !important;
  }

  .m-xl-n9 {
    margin: -2.25rem !important;
  }

  .m-xl-n10 {
    margin: -2.5rem !important;
  }

  .m-xl-n11 {
    margin: -2.75rem !important;
  }

  .m-xl-n12 {
    margin: -3rem !important;
  }

  .m-xl-n13 {
    margin: -3.25rem !important;
  }

  .m-xl-n14 {
    margin: -3.5rem !important;
  }

  .m-xl-n15 {
    margin: -3.75rem !important;
  }

  .m-xl-n16 {
    margin: -4rem !important;
  }

  .m-xl-n17 {
    margin: -4.25rem !important;
  }

  .m-xl-n18 {
    margin: -4.5rem !important;
  }

  .m-xl-n19 {
    margin: -4.75rem !important;
  }

  .m-xl-n20 {
    margin: -5rem !important;
  }

  .mx-xl-n1 {
    margin-right: -0.25rem !important;
    margin-left: -0.25rem !important;
  }

  .mx-xl-n2 {
    margin-right: -0.5rem !important;
    margin-left: -0.5rem !important;
  }

  .mx-xl-n3 {
    margin-right: -0.75rem !important;
    margin-left: -0.75rem !important;
  }

  .mx-xl-n4 {
    margin-right: -1rem !important;
    margin-left: -1rem !important;
  }

  .mx-xl-n5 {
    margin-right: -1.25rem !important;
    margin-left: -1.25rem !important;
  }

  .mx-xl-n6 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important;
  }

  .mx-xl-n7 {
    margin-right: -1.75rem !important;
    margin-left: -1.75rem !important;
  }

  .mx-xl-n8 {
    margin-right: -2rem !important;
    margin-left: -2rem !important;
  }

  .mx-xl-n9 {
    margin-right: -2.25rem !important;
    margin-left: -2.25rem !important;
  }

  .mx-xl-n10 {
    margin-right: -2.5rem !important;
    margin-left: -2.5rem !important;
  }

  .mx-xl-n11 {
    margin-right: -2.75rem !important;
    margin-left: -2.75rem !important;
  }

  .mx-xl-n12 {
    margin-right: -3rem !important;
    margin-left: -3rem !important;
  }

  .mx-xl-n13 {
    margin-right: -3.25rem !important;
    margin-left: -3.25rem !important;
  }

  .mx-xl-n14 {
    margin-right: -3.5rem !important;
    margin-left: -3.5rem !important;
  }

  .mx-xl-n15 {
    margin-right: -3.75rem !important;
    margin-left: -3.75rem !important;
  }

  .mx-xl-n16 {
    margin-right: -4rem !important;
    margin-left: -4rem !important;
  }

  .mx-xl-n17 {
    margin-right: -4.25rem !important;
    margin-left: -4.25rem !important;
  }

  .mx-xl-n18 {
    margin-right: -4.5rem !important;
    margin-left: -4.5rem !important;
  }

  .mx-xl-n19 {
    margin-right: -4.75rem !important;
    margin-left: -4.75rem !important;
  }

  .mx-xl-n20 {
    margin-right: -5rem !important;
    margin-left: -5rem !important;
  }

  .my-xl-n1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important;
  }

  .my-xl-n2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important;
  }

  .my-xl-n3 {
    margin-top: -0.75rem !important;
    margin-bottom: -0.75rem !important;
  }

  .my-xl-n4 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }

  .my-xl-n5 {
    margin-top: -1.25rem !important;
    margin-bottom: -1.25rem !important;
  }

  .my-xl-n6 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }

  .my-xl-n7 {
    margin-top: -1.75rem !important;
    margin-bottom: -1.75rem !important;
  }

  .my-xl-n8 {
    margin-top: -2rem !important;
    margin-bottom: -2rem !important;
  }

  .my-xl-n9 {
    margin-top: -2.25rem !important;
    margin-bottom: -2.25rem !important;
  }

  .my-xl-n10 {
    margin-top: -2.5rem !important;
    margin-bottom: -2.5rem !important;
  }

  .my-xl-n11 {
    margin-top: -2.75rem !important;
    margin-bottom: -2.75rem !important;
  }

  .my-xl-n12 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }

  .my-xl-n13 {
    margin-top: -3.25rem !important;
    margin-bottom: -3.25rem !important;
  }

  .my-xl-n14 {
    margin-top: -3.5rem !important;
    margin-bottom: -3.5rem !important;
  }

  .my-xl-n15 {
    margin-top: -3.75rem !important;
    margin-bottom: -3.75rem !important;
  }

  .my-xl-n16 {
    margin-top: -4rem !important;
    margin-bottom: -4rem !important;
  }

  .my-xl-n17 {
    margin-top: -4.25rem !important;
    margin-bottom: -4.25rem !important;
  }

  .my-xl-n18 {
    margin-top: -4.5rem !important;
    margin-bottom: -4.5rem !important;
  }

  .my-xl-n19 {
    margin-top: -4.75rem !important;
    margin-bottom: -4.75rem !important;
  }

  .my-xl-n20 {
    margin-top: -5rem !important;
    margin-bottom: -5rem !important;
  }

  .mt-xl-n1 {
    margin-top: -0.25rem !important;
  }

  .mt-xl-n2 {
    margin-top: -0.5rem !important;
  }

  .mt-xl-n3 {
    margin-top: -0.75rem !important;
  }

  .mt-xl-n4 {
    margin-top: -1rem !important;
  }

  .mt-xl-n5 {
    margin-top: -1.25rem !important;
  }

  .mt-xl-n6 {
    margin-top: -1.5rem !important;
  }

  .mt-xl-n7 {
    margin-top: -1.75rem !important;
  }

  .mt-xl-n8 {
    margin-top: -2rem !important;
  }

  .mt-xl-n9 {
    margin-top: -2.25rem !important;
  }

  .mt-xl-n10 {
    margin-top: -2.5rem !important;
  }

  .mt-xl-n11 {
    margin-top: -2.75rem !important;
  }

  .mt-xl-n12 {
    margin-top: -3rem !important;
  }

  .mt-xl-n13 {
    margin-top: -3.25rem !important;
  }

  .mt-xl-n14 {
    margin-top: -3.5rem !important;
  }

  .mt-xl-n15 {
    margin-top: -3.75rem !important;
  }

  .mt-xl-n16 {
    margin-top: -4rem !important;
  }

  .mt-xl-n17 {
    margin-top: -4.25rem !important;
  }

  .mt-xl-n18 {
    margin-top: -4.5rem !important;
  }

  .mt-xl-n19 {
    margin-top: -4.75rem !important;
  }

  .mt-xl-n20 {
    margin-top: -5rem !important;
  }

  .me-xl-n1 {
    margin-right: -0.25rem !important;
  }

  .me-xl-n2 {
    margin-right: -0.5rem !important;
  }

  .me-xl-n3 {
    margin-right: -0.75rem !important;
  }

  .me-xl-n4 {
    margin-right: -1rem !important;
  }

  .me-xl-n5 {
    margin-right: -1.25rem !important;
  }

  .me-xl-n6 {
    margin-right: -1.5rem !important;
  }

  .me-xl-n7 {
    margin-right: -1.75rem !important;
  }

  .me-xl-n8 {
    margin-right: -2rem !important;
  }

  .me-xl-n9 {
    margin-right: -2.25rem !important;
  }

  .me-xl-n10 {
    margin-right: -2.5rem !important;
  }

  .me-xl-n11 {
    margin-right: -2.75rem !important;
  }

  .me-xl-n12 {
    margin-right: -3rem !important;
  }

  .me-xl-n13 {
    margin-right: -3.25rem !important;
  }

  .me-xl-n14 {
    margin-right: -3.5rem !important;
  }

  .me-xl-n15 {
    margin-right: -3.75rem !important;
  }

  .me-xl-n16 {
    margin-right: -4rem !important;
  }

  .me-xl-n17 {
    margin-right: -4.25rem !important;
  }

  .me-xl-n18 {
    margin-right: -4.5rem !important;
  }

  .me-xl-n19 {
    margin-right: -4.75rem !important;
  }

  .me-xl-n20 {
    margin-right: -5rem !important;
  }

  .mb-xl-n1 {
    margin-bottom: -0.25rem !important;
  }

  .mb-xl-n2 {
    margin-bottom: -0.5rem !important;
  }

  .mb-xl-n3 {
    margin-bottom: -0.75rem !important;
  }

  .mb-xl-n4 {
    margin-bottom: -1rem !important;
  }

  .mb-xl-n5 {
    margin-bottom: -1.25rem !important;
  }

  .mb-xl-n6 {
    margin-bottom: -1.5rem !important;
  }

  .mb-xl-n7 {
    margin-bottom: -1.75rem !important;
  }

  .mb-xl-n8 {
    margin-bottom: -2rem !important;
  }

  .mb-xl-n9 {
    margin-bottom: -2.25rem !important;
  }

  .mb-xl-n10 {
    margin-bottom: -2.5rem !important;
  }

  .mb-xl-n11 {
    margin-bottom: -2.75rem !important;
  }

  .mb-xl-n12 {
    margin-bottom: -3rem !important;
  }

  .mb-xl-n13 {
    margin-bottom: -3.25rem !important;
  }

  .mb-xl-n14 {
    margin-bottom: -3.5rem !important;
  }

  .mb-xl-n15 {
    margin-bottom: -3.75rem !important;
  }

  .mb-xl-n16 {
    margin-bottom: -4rem !important;
  }

  .mb-xl-n17 {
    margin-bottom: -4.25rem !important;
  }

  .mb-xl-n18 {
    margin-bottom: -4.5rem !important;
  }

  .mb-xl-n19 {
    margin-bottom: -4.75rem !important;
  }

  .mb-xl-n20 {
    margin-bottom: -5rem !important;
  }

  .ms-xl-n1 {
    margin-left: -0.25rem !important;
  }

  .ms-xl-n2 {
    margin-left: -0.5rem !important;
  }

  .ms-xl-n3 {
    margin-left: -0.75rem !important;
  }

  .ms-xl-n4 {
    margin-left: -1rem !important;
  }

  .ms-xl-n5 {
    margin-left: -1.25rem !important;
  }

  .ms-xl-n6 {
    margin-left: -1.5rem !important;
  }

  .ms-xl-n7 {
    margin-left: -1.75rem !important;
  }

  .ms-xl-n8 {
    margin-left: -2rem !important;
  }

  .ms-xl-n9 {
    margin-left: -2.25rem !important;
  }

  .ms-xl-n10 {
    margin-left: -2.5rem !important;
  }

  .ms-xl-n11 {
    margin-left: -2.75rem !important;
  }

  .ms-xl-n12 {
    margin-left: -3rem !important;
  }

  .ms-xl-n13 {
    margin-left: -3.25rem !important;
  }

  .ms-xl-n14 {
    margin-left: -3.5rem !important;
  }

  .ms-xl-n15 {
    margin-left: -3.75rem !important;
  }

  .ms-xl-n16 {
    margin-left: -4rem !important;
  }

  .ms-xl-n17 {
    margin-left: -4.25rem !important;
  }

  .ms-xl-n18 {
    margin-left: -4.5rem !important;
  }

  .ms-xl-n19 {
    margin-left: -4.75rem !important;
  }

  .ms-xl-n20 {
    margin-left: -5rem !important;
  }

  .p-xl-0 {
    padding: 0 !important;
  }

  .p-xl-1 {
    padding: 0.25rem !important;
  }

  .p-xl-2 {
    padding: 0.5rem !important;
  }

  .p-xl-3 {
    padding: 0.75rem !important;
  }

  .p-xl-4 {
    padding: 1rem !important;
  }

  .p-xl-5 {
    padding: 1.25rem !important;
  }

  .p-xl-6 {
    padding: 1.5rem !important;
  }

  .p-xl-7 {
    padding: 1.75rem !important;
  }

  .p-xl-8 {
    padding: 2rem !important;
  }

  .p-xl-9 {
    padding: 2.25rem !important;
  }

  .p-xl-10 {
    padding: 2.5rem !important;
  }

  .p-xl-11 {
    padding: 2.75rem !important;
  }

  .p-xl-12 {
    padding: 3rem !important;
  }

  .p-xl-13 {
    padding: 3.25rem !important;
  }

  .p-xl-14 {
    padding: 3.5rem !important;
  }

  .p-xl-15 {
    padding: 3.75rem !important;
  }

  .p-xl-16 {
    padding: 4rem !important;
  }

  .p-xl-17 {
    padding: 4.25rem !important;
  }

  .p-xl-18 {
    padding: 4.5rem !important;
  }

  .p-xl-19 {
    padding: 4.75rem !important;
  }

  .p-xl-20 {
    padding: 5rem !important;
  }

  .px-xl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .px-xl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }

  .px-xl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }

  .px-xl-3 {
    padding-right: 0.75rem !important;
    padding-left: 0.75rem !important;
  }

  .px-xl-4 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }

  .px-xl-5 {
    padding-right: 1.25rem !important;
    padding-left: 1.25rem !important;
  }

  .px-xl-6 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }

  .px-xl-7 {
    padding-right: 1.75rem !important;
    padding-left: 1.75rem !important;
  }

  .px-xl-8 {
    padding-right: 2rem !important;
    padding-left: 2rem !important;
  }

  .px-xl-9 {
    padding-right: 2.25rem !important;
    padding-left: 2.25rem !important;
  }

  .px-xl-10 {
    padding-right: 2.5rem !important;
    padding-left: 2.5rem !important;
  }

  .px-xl-11 {
    padding-right: 2.75rem !important;
    padding-left: 2.75rem !important;
  }

  .px-xl-12 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }

  .px-xl-13 {
    padding-right: 3.25rem !important;
    padding-left: 3.25rem !important;
  }

  .px-xl-14 {
    padding-right: 3.5rem !important;
    padding-left: 3.5rem !important;
  }

  .px-xl-15 {
    padding-right: 3.75rem !important;
    padding-left: 3.75rem !important;
  }

  .px-xl-16 {
    padding-right: 4rem !important;
    padding-left: 4rem !important;
  }

  .px-xl-17 {
    padding-right: 4.25rem !important;
    padding-left: 4.25rem !important;
  }

  .px-xl-18 {
    padding-right: 4.5rem !important;
    padding-left: 4.5rem !important;
  }

  .px-xl-19 {
    padding-right: 4.75rem !important;
    padding-left: 4.75rem !important;
  }

  .px-xl-20 {
    padding-right: 5rem !important;
    padding-left: 5rem !important;
  }

  .py-xl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-xl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }

  .py-xl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .py-xl-3 {
    padding-top: 0.75rem !important;
    padding-bottom: 0.75rem !important;
  }

  .py-xl-4 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-xl-5 {
    padding-top: 1.25rem !important;
    padding-bottom: 1.25rem !important;
  }

  .py-xl-6 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-xl-7 {
    padding-top: 1.75rem !important;
    padding-bottom: 1.75rem !important;
  }

  .py-xl-8 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }

  .py-xl-9 {
    padding-top: 2.25rem !important;
    padding-bottom: 2.25rem !important;
  }

  .py-xl-10 {
    padding-top: 2.5rem !important;
    padding-bottom: 2.5rem !important;
  }

  .py-xl-11 {
    padding-top: 2.75rem !important;
    padding-bottom: 2.75rem !important;
  }

  .py-xl-12 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .py-xl-13 {
    padding-top: 3.25rem !important;
    padding-bottom: 3.25rem !important;
  }

  .py-xl-14 {
    padding-top: 3.5rem !important;
    padding-bottom: 3.5rem !important;
  }

  .py-xl-15 {
    padding-top: 3.75rem !important;
    padding-bottom: 3.75rem !important;
  }

  .py-xl-16 {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important;
  }

  .py-xl-17 {
    padding-top: 4.25rem !important;
    padding-bottom: 4.25rem !important;
  }

  .py-xl-18 {
    padding-top: 4.5rem !important;
    padding-bottom: 4.5rem !important;
  }

  .py-xl-19 {
    padding-top: 4.75rem !important;
    padding-bottom: 4.75rem !important;
  }

  .py-xl-20 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
  }

  .pt-xl-0 {
    padding-top: 0 !important;
  }

  .pt-xl-1 {
    padding-top: 0.25rem !important;
  }

  .pt-xl-2 {
    padding-top: 0.5rem !important;
  }

  .pt-xl-3 {
    padding-top: 0.75rem !important;
  }

  .pt-xl-4 {
    padding-top: 1rem !important;
  }

  .pt-xl-5 {
    padding-top: 1.25rem !important;
  }

  .pt-xl-6 {
    padding-top: 1.5rem !important;
  }

  .pt-xl-7 {
    padding-top: 1.75rem !important;
  }

  .pt-xl-8 {
    padding-top: 2rem !important;
  }

  .pt-xl-9 {
    padding-top: 2.25rem !important;
  }

  .pt-xl-10 {
    padding-top: 2.5rem !important;
  }

  .pt-xl-11 {
    padding-top: 2.75rem !important;
  }

  .pt-xl-12 {
    padding-top: 3rem !important;
  }

  .pt-xl-13 {
    padding-top: 3.25rem !important;
  }

  .pt-xl-14 {
    padding-top: 3.5rem !important;
  }

  .pt-xl-15 {
    padding-top: 3.75rem !important;
  }

  .pt-xl-16 {
    padding-top: 4rem !important;
  }

  .pt-xl-17 {
    padding-top: 4.25rem !important;
  }

  .pt-xl-18 {
    padding-top: 4.5rem !important;
  }

  .pt-xl-19 {
    padding-top: 4.75rem !important;
  }

  .pt-xl-20 {
    padding-top: 5rem !important;
  }

  .pe-xl-0 {
    padding-right: 0 !important;
  }

  .pe-xl-1 {
    padding-right: 0.25rem !important;
  }

  .pe-xl-2 {
    padding-right: 0.5rem !important;
  }

  .pe-xl-3 {
    padding-right: 0.75rem !important;
  }

  .pe-xl-4 {
    padding-right: 1rem !important;
  }

  .pe-xl-5 {
    padding-right: 1.25rem !important;
  }

  .pe-xl-6 {
    padding-right: 1.5rem !important;
  }

  .pe-xl-7 {
    padding-right: 1.75rem !important;
  }

  .pe-xl-8 {
    padding-right: 2rem !important;
  }

  .pe-xl-9 {
    padding-right: 2.25rem !important;
  }

  .pe-xl-10 {
    padding-right: 2.5rem !important;
  }

  .pe-xl-11 {
    padding-right: 2.75rem !important;
  }

  .pe-xl-12 {
    padding-right: 3rem !important;
  }

  .pe-xl-13 {
    padding-right: 3.25rem !important;
  }

  .pe-xl-14 {
    padding-right: 3.5rem !important;
  }

  .pe-xl-15 {
    padding-right: 3.75rem !important;
  }

  .pe-xl-16 {
    padding-right: 4rem !important;
  }

  .pe-xl-17 {
    padding-right: 4.25rem !important;
  }

  .pe-xl-18 {
    padding-right: 4.5rem !important;
  }

  .pe-xl-19 {
    padding-right: 4.75rem !important;
  }

  .pe-xl-20 {
    padding-right: 5rem !important;
  }

  .pb-xl-0 {
    padding-bottom: 0 !important;
  }

  .pb-xl-1 {
    padding-bottom: 0.25rem !important;
  }

  .pb-xl-2 {
    padding-bottom: 0.5rem !important;
  }

  .pb-xl-3 {
    padding-bottom: 0.75rem !important;
  }

  .pb-xl-4 {
    padding-bottom: 1rem !important;
  }

  .pb-xl-5 {
    padding-bottom: 1.25rem !important;
  }

  .pb-xl-6 {
    padding-bottom: 1.5rem !important;
  }

  .pb-xl-7 {
    padding-bottom: 1.75rem !important;
  }

  .pb-xl-8 {
    padding-bottom: 2rem !important;
  }

  .pb-xl-9 {
    padding-bottom: 2.25rem !important;
  }

  .pb-xl-10 {
    padding-bottom: 2.5rem !important;
  }

  .pb-xl-11 {
    padding-bottom: 2.75rem !important;
  }

  .pb-xl-12 {
    padding-bottom: 3rem !important;
  }

  .pb-xl-13 {
    padding-bottom: 3.25rem !important;
  }

  .pb-xl-14 {
    padding-bottom: 3.5rem !important;
  }

  .pb-xl-15 {
    padding-bottom: 3.75rem !important;
  }

  .pb-xl-16 {
    padding-bottom: 4rem !important;
  }

  .pb-xl-17 {
    padding-bottom: 4.25rem !important;
  }

  .pb-xl-18 {
    padding-bottom: 4.5rem !important;
  }

  .pb-xl-19 {
    padding-bottom: 4.75rem !important;
  }

  .pb-xl-20 {
    padding-bottom: 5rem !important;
  }

  .ps-xl-0 {
    padding-left: 0 !important;
  }

  .ps-xl-1 {
    padding-left: 0.25rem !important;
  }

  .ps-xl-2 {
    padding-left: 0.5rem !important;
  }

  .ps-xl-3 {
    padding-left: 0.75rem !important;
  }

  .ps-xl-4 {
    padding-left: 1rem !important;
  }

  .ps-xl-5 {
    padding-left: 1.25rem !important;
  }

  .ps-xl-6 {
    padding-left: 1.5rem !important;
  }

  .ps-xl-7 {
    padding-left: 1.75rem !important;
  }

  .ps-xl-8 {
    padding-left: 2rem !important;
  }

  .ps-xl-9 {
    padding-left: 2.25rem !important;
  }

  .ps-xl-10 {
    padding-left: 2.5rem !important;
  }

  .ps-xl-11 {
    padding-left: 2.75rem !important;
  }

  .ps-xl-12 {
    padding-left: 3rem !important;
  }

  .ps-xl-13 {
    padding-left: 3.25rem !important;
  }

  .ps-xl-14 {
    padding-left: 3.5rem !important;
  }

  .ps-xl-15 {
    padding-left: 3.75rem !important;
  }

  .ps-xl-16 {
    padding-left: 4rem !important;
  }

  .ps-xl-17 {
    padding-left: 4.25rem !important;
  }

  .ps-xl-18 {
    padding-left: 4.5rem !important;
  }

  .ps-xl-19 {
    padding-left: 4.75rem !important;
  }

  .ps-xl-20 {
    padding-left: 5rem !important;
  }

  .fs-xl-1 {
    font-size: calc(1.3rem + 0.6vw) !important;
  }

  .fs-xl-2 {
    font-size: calc(1.275rem + 0.3vw) !important;
  }

  .fs-xl-3 {
    font-size: calc(1.26rem + 0.12vw) !important;
  }

  .fs-xl-4 {
    font-size: 1.25rem !important;
  }

  .fs-xl-5 {
    font-size: 1.15rem !important;
  }

  .fs-xl-6 {
    font-size: 1.075rem !important;
  }

  .fs-xl-7 {
    font-size: 0.95rem !important;
  }

  .fs-xl-8 {
    font-size: 0.85rem !important;
  }

  .fs-xl-9 {
    font-size: 0.75rem !important;
  }

  .fs-xl-10 {
    font-size: 0.5rem !important;
  }

  .fs-xl-base {
    font-size: 1rem !important;
  }

  .fs-xl-fluid {
    font-size: 100% !important;
  }

  .fs-xl-2x {
    font-size: calc(1.325rem + 0.9vw) !important;
  }

  .fs-xl-2qx {
    font-size: calc(1.35rem + 1.2vw) !important;
  }

  .fs-xl-2hx {
    font-size: calc(1.375rem + 1.5vw) !important;
  }

  .fs-xl-2tx {
    font-size: calc(1.4rem + 1.8vw) !important;
  }

  .fs-xl-3x {
    font-size: calc(1.425rem + 2.1vw) !important;
  }

  .fs-xl-3qx {
    font-size: calc(1.45rem + 2.4vw) !important;
  }

  .fs-xl-3hx {
    font-size: calc(1.475rem + 2.7vw) !important;
  }

  .fs-xl-3tx {
    font-size: calc(1.5rem + 3vw) !important;
  }

  .fs-xl-4x {
    font-size: calc(1.525rem + 3.3vw) !important;
  }

  .fs-xl-4qx {
    font-size: calc(1.55rem + 3.6vw) !important;
  }

  .fs-xl-4hx {
    font-size: calc(1.575rem + 3.9vw) !important;
  }

  .fs-xl-4tx {
    font-size: calc(1.6rem + 4.2vw) !important;
  }

  .fs-xl-5x {
    font-size: calc(1.625rem + 4.5vw) !important;
  }

  .fs-xl-5qx {
    font-size: calc(1.65rem + 4.8vw) !important;
  }

  .fs-xl-5hx {
    font-size: calc(1.675rem + 5.1vw) !important;
  }

  .fs-xl-5tx {
    font-size: calc(1.7rem + 5.4vw) !important;
  }

  .text-xl-start {
    text-align: left !important;
  }

  .text-xl-end {
    text-align: right !important;
  }

  .text-xl-center {
    text-align: center !important;
  }

  .min-w-xl-unset {
    min-width: unset !important;
  }

  .min-w-xl-25 {
    min-width: 25% !important;
  }

  .min-w-xl-50 {
    min-width: 50% !important;
  }

  .min-w-xl-75 {
    min-width: 75% !important;
  }

  .min-w-xl-100 {
    min-width: 100% !important;
  }

  .min-w-xl-auto {
    min-width: auto !important;
  }

  .min-w-xl-1px {
    min-width: 1px !important;
  }

  .min-w-xl-2px {
    min-width: 2px !important;
  }

  .min-w-xl-3px {
    min-width: 3px !important;
  }

  .min-w-xl-4px {
    min-width: 4px !important;
  }

  .min-w-xl-5px {
    min-width: 5px !important;
  }

  .min-w-xl-6px {
    min-width: 6px !important;
  }

  .min-w-xl-7px {
    min-width: 7px !important;
  }

  .min-w-xl-8px {
    min-width: 8px !important;
  }

  .min-w-xl-9px {
    min-width: 9px !important;
  }

  .min-w-xl-10px {
    min-width: 10px !important;
  }

  .min-w-xl-15px {
    min-width: 15px !important;
  }

  .min-w-xl-20px {
    min-width: 20px !important;
  }

  .min-w-xl-25px {
    min-width: 25px !important;
  }

  .min-w-xl-30px {
    min-width: 30px !important;
  }

  .min-w-xl-35px {
    min-width: 35px !important;
  }

  .min-w-xl-40px {
    min-width: 40px !important;
  }

  .min-w-xl-45px {
    min-width: 45px !important;
  }

  .min-w-xl-50px {
    min-width: 50px !important;
  }

  .min-w-xl-55px {
    min-width: 55px !important;
  }

  .min-w-xl-60px {
    min-width: 60px !important;
  }

  .min-w-xl-65px {
    min-width: 65px !important;
  }

  .min-w-xl-70px {
    min-width: 70px !important;
  }

  .min-w-xl-75px {
    min-width: 75px !important;
  }

  .min-w-xl-80px {
    min-width: 80px !important;
  }

  .min-w-xl-85px {
    min-width: 85px !important;
  }

  .min-w-xl-90px {
    min-width: 90px !important;
  }

  .min-w-xl-95px {
    min-width: 95px !important;
  }

  .min-w-xl-100px {
    min-width: 100px !important;
  }

  .min-w-xl-125px {
    min-width: 125px !important;
  }

  .min-w-xl-150px {
    min-width: 150px !important;
  }

  .min-w-xl-175px {
    min-width: 175px !important;
  }

  .min-w-xl-200px {
    min-width: 200px !important;
  }

  .min-w-xl-225px {
    min-width: 225px !important;
  }

  .min-w-xl-250px {
    min-width: 250px !important;
  }

  .min-w-xl-275px {
    min-width: 275px !important;
  }

  .min-w-xl-300px {
    min-width: 300px !important;
  }

  .min-w-xl-325px {
    min-width: 325px !important;
  }

  .min-w-xl-350px {
    min-width: 350px !important;
  }

  .min-w-xl-375px {
    min-width: 375px !important;
  }

  .min-w-xl-400px {
    min-width: 400px !important;
  }

  .min-w-xl-425px {
    min-width: 425px !important;
  }

  .min-w-xl-450px {
    min-width: 450px !important;
  }

  .min-w-xl-475px {
    min-width: 475px !important;
  }

  .min-w-xl-500px {
    min-width: 500px !important;
  }

  .min-w-xl-550px {
    min-width: 550px !important;
  }

  .min-w-xl-600px {
    min-width: 600px !important;
  }

  .min-w-xl-650px {
    min-width: 650px !important;
  }

  .min-w-xl-700px {
    min-width: 700px !important;
  }

  .min-w-xl-750px {
    min-width: 750px !important;
  }

  .min-w-xl-800px {
    min-width: 800px !important;
  }

  .min-w-xl-850px {
    min-width: 850px !important;
  }

  .min-w-xl-900px {
    min-width: 900px !important;
  }

  .min-w-xl-950px {
    min-width: 950px !important;
  }

  .min-w-xl-1000px {
    min-width: 1000px !important;
  }

  .min-h-xl-unset {
    min-height: unset !important;
  }

  .min-h-xl-25 {
    min-height: 25% !important;
  }

  .min-h-xl-50 {
    min-height: 50% !important;
  }

  .min-h-xl-75 {
    min-height: 75% !important;
  }

  .min-h-xl-100 {
    min-height: 100% !important;
  }

  .min-h-xl-auto {
    min-height: auto !important;
  }

  .min-h-xl-1px {
    min-height: 1px !important;
  }

  .min-h-xl-2px {
    min-height: 2px !important;
  }

  .min-h-xl-3px {
    min-height: 3px !important;
  }

  .min-h-xl-4px {
    min-height: 4px !important;
  }

  .min-h-xl-5px {
    min-height: 5px !important;
  }

  .min-h-xl-6px {
    min-height: 6px !important;
  }

  .min-h-xl-7px {
    min-height: 7px !important;
  }

  .min-h-xl-8px {
    min-height: 8px !important;
  }

  .min-h-xl-9px {
    min-height: 9px !important;
  }

  .min-h-xl-10px {
    min-height: 10px !important;
  }

  .min-h-xl-15px {
    min-height: 15px !important;
  }

  .min-h-xl-20px {
    min-height: 20px !important;
  }

  .min-h-xl-25px {
    min-height: 25px !important;
  }

  .min-h-xl-30px {
    min-height: 30px !important;
  }

  .min-h-xl-35px {
    min-height: 35px !important;
  }

  .min-h-xl-40px {
    min-height: 40px !important;
  }

  .min-h-xl-45px {
    min-height: 45px !important;
  }

  .min-h-xl-50px {
    min-height: 50px !important;
  }

  .min-h-xl-55px {
    min-height: 55px !important;
  }

  .min-h-xl-60px {
    min-height: 60px !important;
  }

  .min-h-xl-65px {
    min-height: 65px !important;
  }

  .min-h-xl-70px {
    min-height: 70px !important;
  }

  .min-h-xl-75px {
    min-height: 75px !important;
  }

  .min-h-xl-80px {
    min-height: 80px !important;
  }

  .min-h-xl-85px {
    min-height: 85px !important;
  }

  .min-h-xl-90px {
    min-height: 90px !important;
  }

  .min-h-xl-95px {
    min-height: 95px !important;
  }

  .min-h-xl-100px {
    min-height: 100px !important;
  }

  .min-h-xl-125px {
    min-height: 125px !important;
  }

  .min-h-xl-150px {
    min-height: 150px !important;
  }

  .min-h-xl-175px {
    min-height: 175px !important;
  }

  .min-h-xl-200px {
    min-height: 200px !important;
  }

  .min-h-xl-225px {
    min-height: 225px !important;
  }

  .min-h-xl-250px {
    min-height: 250px !important;
  }

  .min-h-xl-275px {
    min-height: 275px !important;
  }

  .min-h-xl-300px {
    min-height: 300px !important;
  }

  .min-h-xl-325px {
    min-height: 325px !important;
  }

  .min-h-xl-350px {
    min-height: 350px !important;
  }

  .min-h-xl-375px {
    min-height: 375px !important;
  }

  .min-h-xl-400px {
    min-height: 400px !important;
  }

  .min-h-xl-425px {
    min-height: 425px !important;
  }

  .min-h-xl-450px {
    min-height: 450px !important;
  }

  .min-h-xl-475px {
    min-height: 475px !important;
  }

  .min-h-xl-500px {
    min-height: 500px !important;
  }

  .min-h-xl-550px {
    min-height: 550px !important;
  }

  .min-h-xl-600px {
    min-height: 600px !important;
  }

  .min-h-xl-650px {
    min-height: 650px !important;
  }

  .min-h-xl-700px {
    min-height: 700px !important;
  }

  .min-h-xl-750px {
    min-height: 750px !important;
  }

  .min-h-xl-800px {
    min-height: 800px !important;
  }

  .min-h-xl-850px {
    min-height: 850px !important;
  }

  .min-h-xl-900px {
    min-height: 900px !important;
  }

  .min-h-xl-950px {
    min-height: 950px !important;
  }

  .min-h-xl-1000px {
    min-height: 1000px !important;
  }
}
@media (min-width: 1400px) {
  .float-xxl-start {
    float: left !important;
  }

  .float-xxl-end {
    float: right !important;
  }

  .float-xxl-none {
    float: none !important;
  }

  .d-xxl-inline {
    display: inline !important;
  }

  .d-xxl-inline-block {
    display: inline-block !important;
  }

  .d-xxl-block {
    display: block !important;
  }

  .d-xxl-grid {
    display: grid !important;
  }

  .d-xxl-table {
    display: table !important;
  }

  .d-xxl-table-row {
    display: table-row !important;
  }

  .d-xxl-table-cell {
    display: table-cell !important;
  }

  .d-xxl-flex {
    display: flex !important;
  }

  .d-xxl-inline-flex {
    display: inline-flex !important;
  }

  .d-xxl-none {
    display: none !important;
  }

  .w-xxl-unset {
    width: unset !important;
  }

  .w-xxl-25 {
    width: 25% !important;
  }

  .w-xxl-50 {
    width: 50% !important;
  }

  .w-xxl-75 {
    width: 75% !important;
  }

  .w-xxl-100 {
    width: 100% !important;
  }

  .w-xxl-auto {
    width: auto !important;
  }

  .w-xxl-1px {
    width: 1px !important;
  }

  .w-xxl-2px {
    width: 2px !important;
  }

  .w-xxl-3px {
    width: 3px !important;
  }

  .w-xxl-4px {
    width: 4px !important;
  }

  .w-xxl-5px {
    width: 5px !important;
  }

  .w-xxl-6px {
    width: 6px !important;
  }

  .w-xxl-7px {
    width: 7px !important;
  }

  .w-xxl-8px {
    width: 8px !important;
  }

  .w-xxl-9px {
    width: 9px !important;
  }

  .w-xxl-10px {
    width: 10px !important;
  }

  .w-xxl-15px {
    width: 15px !important;
  }

  .w-xxl-20px {
    width: 20px !important;
  }

  .w-xxl-25px {
    width: 25px !important;
  }

  .w-xxl-30px {
    width: 30px !important;
  }

  .w-xxl-35px {
    width: 35px !important;
  }

  .w-xxl-40px {
    width: 40px !important;
  }

  .w-xxl-45px {
    width: 45px !important;
  }

  .w-xxl-50px {
    width: 50px !important;
  }

  .w-xxl-55px {
    width: 55px !important;
  }

  .w-xxl-60px {
    width: 60px !important;
  }

  .w-xxl-65px {
    width: 65px !important;
  }

  .w-xxl-70px {
    width: 70px !important;
  }

  .w-xxl-75px {
    width: 75px !important;
  }

  .w-xxl-80px {
    width: 80px !important;
  }

  .w-xxl-85px {
    width: 85px !important;
  }

  .w-xxl-90px {
    width: 90px !important;
  }

  .w-xxl-95px {
    width: 95px !important;
  }

  .w-xxl-100px {
    width: 100px !important;
  }

  .w-xxl-125px {
    width: 125px !important;
  }

  .w-xxl-150px {
    width: 150px !important;
  }

  .w-xxl-175px {
    width: 175px !important;
  }

  .w-xxl-200px {
    width: 200px !important;
  }

  .w-xxl-225px {
    width: 225px !important;
  }

  .w-xxl-250px {
    width: 250px !important;
  }

  .w-xxl-275px {
    width: 275px !important;
  }

  .w-xxl-300px {
    width: 300px !important;
  }

  .w-xxl-325px {
    width: 325px !important;
  }

  .w-xxl-350px {
    width: 350px !important;
  }

  .w-xxl-375px {
    width: 375px !important;
  }

  .w-xxl-400px {
    width: 400px !important;
  }

  .w-xxl-425px {
    width: 425px !important;
  }

  .w-xxl-450px {
    width: 450px !important;
  }

  .w-xxl-475px {
    width: 475px !important;
  }

  .w-xxl-500px {
    width: 500px !important;
  }

  .w-xxl-550px {
    width: 550px !important;
  }

  .w-xxl-600px {
    width: 600px !important;
  }

  .w-xxl-650px {
    width: 650px !important;
  }

  .w-xxl-700px {
    width: 700px !important;
  }

  .w-xxl-750px {
    width: 750px !important;
  }

  .w-xxl-800px {
    width: 800px !important;
  }

  .w-xxl-850px {
    width: 850px !important;
  }

  .w-xxl-900px {
    width: 900px !important;
  }

  .w-xxl-950px {
    width: 950px !important;
  }

  .w-xxl-1000px {
    width: 1000px !important;
  }

  .mw-xxl-unset {
    max-width: unset !important;
  }

  .mw-xxl-25 {
    max-width: 25% !important;
  }

  .mw-xxl-50 {
    max-width: 50% !important;
  }

  .mw-xxl-75 {
    max-width: 75% !important;
  }

  .mw-xxl-100 {
    max-width: 100% !important;
  }

  .mw-xxl-auto {
    max-width: auto !important;
  }

  .mw-xxl-1px {
    max-width: 1px !important;
  }

  .mw-xxl-2px {
    max-width: 2px !important;
  }

  .mw-xxl-3px {
    max-width: 3px !important;
  }

  .mw-xxl-4px {
    max-width: 4px !important;
  }

  .mw-xxl-5px {
    max-width: 5px !important;
  }

  .mw-xxl-6px {
    max-width: 6px !important;
  }

  .mw-xxl-7px {
    max-width: 7px !important;
  }

  .mw-xxl-8px {
    max-width: 8px !important;
  }

  .mw-xxl-9px {
    max-width: 9px !important;
  }

  .mw-xxl-10px {
    max-width: 10px !important;
  }

  .mw-xxl-15px {
    max-width: 15px !important;
  }

  .mw-xxl-20px {
    max-width: 20px !important;
  }

  .mw-xxl-25px {
    max-width: 25px !important;
  }

  .mw-xxl-30px {
    max-width: 30px !important;
  }

  .mw-xxl-35px {
    max-width: 35px !important;
  }

  .mw-xxl-40px {
    max-width: 40px !important;
  }

  .mw-xxl-45px {
    max-width: 45px !important;
  }

  .mw-xxl-50px {
    max-width: 50px !important;
  }

  .mw-xxl-55px {
    max-width: 55px !important;
  }

  .mw-xxl-60px {
    max-width: 60px !important;
  }

  .mw-xxl-65px {
    max-width: 65px !important;
  }

  .mw-xxl-70px {
    max-width: 70px !important;
  }

  .mw-xxl-75px {
    max-width: 75px !important;
  }

  .mw-xxl-80px {
    max-width: 80px !important;
  }

  .mw-xxl-85px {
    max-width: 85px !important;
  }

  .mw-xxl-90px {
    max-width: 90px !important;
  }

  .mw-xxl-95px {
    max-width: 95px !important;
  }

  .mw-xxl-100px {
    max-width: 100px !important;
  }

  .mw-xxl-125px {
    max-width: 125px !important;
  }

  .mw-xxl-150px {
    max-width: 150px !important;
  }

  .mw-xxl-175px {
    max-width: 175px !important;
  }

  .mw-xxl-200px {
    max-width: 200px !important;
  }

  .mw-xxl-225px {
    max-width: 225px !important;
  }

  .mw-xxl-250px {
    max-width: 250px !important;
  }

  .mw-xxl-275px {
    max-width: 275px !important;
  }

  .mw-xxl-300px {
    max-width: 300px !important;
  }

  .mw-xxl-325px {
    max-width: 325px !important;
  }

  .mw-xxl-350px {
    max-width: 350px !important;
  }

  .mw-xxl-375px {
    max-width: 375px !important;
  }

  .mw-xxl-400px {
    max-width: 400px !important;
  }

  .mw-xxl-425px {
    max-width: 425px !important;
  }

  .mw-xxl-450px {
    max-width: 450px !important;
  }

  .mw-xxl-475px {
    max-width: 475px !important;
  }

  .mw-xxl-500px {
    max-width: 500px !important;
  }

  .mw-xxl-550px {
    max-width: 550px !important;
  }

  .mw-xxl-600px {
    max-width: 600px !important;
  }

  .mw-xxl-650px {
    max-width: 650px !important;
  }

  .mw-xxl-700px {
    max-width: 700px !important;
  }

  .mw-xxl-750px {
    max-width: 750px !important;
  }

  .mw-xxl-800px {
    max-width: 800px !important;
  }

  .mw-xxl-850px {
    max-width: 850px !important;
  }

  .mw-xxl-900px {
    max-width: 900px !important;
  }

  .mw-xxl-950px {
    max-width: 950px !important;
  }

  .mw-xxl-1000px {
    max-width: 1000px !important;
  }

  .h-xxl-unset {
    height: unset !important;
  }

  .h-xxl-25 {
    height: 25% !important;
  }

  .h-xxl-50 {
    height: 50% !important;
  }

  .h-xxl-75 {
    height: 75% !important;
  }

  .h-xxl-100 {
    height: 100% !important;
  }

  .h-xxl-auto {
    height: auto !important;
  }

  .h-xxl-1px {
    height: 1px !important;
  }

  .h-xxl-2px {
    height: 2px !important;
  }

  .h-xxl-3px {
    height: 3px !important;
  }

  .h-xxl-4px {
    height: 4px !important;
  }

  .h-xxl-5px {
    height: 5px !important;
  }

  .h-xxl-6px {
    height: 6px !important;
  }

  .h-xxl-7px {
    height: 7px !important;
  }

  .h-xxl-8px {
    height: 8px !important;
  }

  .h-xxl-9px {
    height: 9px !important;
  }

  .h-xxl-10px {
    height: 10px !important;
  }

  .h-xxl-15px {
    height: 15px !important;
  }

  .h-xxl-20px {
    height: 20px !important;
  }

  .h-xxl-25px {
    height: 25px !important;
  }

  .h-xxl-30px {
    height: 30px !important;
  }

  .h-xxl-35px {
    height: 35px !important;
  }

  .h-xxl-40px {
    height: 40px !important;
  }

  .h-xxl-45px {
    height: 45px !important;
  }

  .h-xxl-50px {
    height: 50px !important;
  }

  .h-xxl-55px {
    height: 55px !important;
  }

  .h-xxl-60px {
    height: 60px !important;
  }

  .h-xxl-65px {
    height: 65px !important;
  }

  .h-xxl-70px {
    height: 70px !important;
  }

  .h-xxl-75px {
    height: 75px !important;
  }

  .h-xxl-80px {
    height: 80px !important;
  }

  .h-xxl-85px {
    height: 85px !important;
  }

  .h-xxl-90px {
    height: 90px !important;
  }

  .h-xxl-95px {
    height: 95px !important;
  }

  .h-xxl-100px {
    height: 100px !important;
  }

  .h-xxl-125px {
    height: 125px !important;
  }

  .h-xxl-150px {
    height: 150px !important;
  }

  .h-xxl-175px {
    height: 175px !important;
  }

  .h-xxl-200px {
    height: 200px !important;
  }

  .h-xxl-225px {
    height: 225px !important;
  }

  .h-xxl-250px {
    height: 250px !important;
  }

  .h-xxl-275px {
    height: 275px !important;
  }

  .h-xxl-300px {
    height: 300px !important;
  }

  .h-xxl-325px {
    height: 325px !important;
  }

  .h-xxl-350px {
    height: 350px !important;
  }

  .h-xxl-375px {
    height: 375px !important;
  }

  .h-xxl-400px {
    height: 400px !important;
  }

  .h-xxl-425px {
    height: 425px !important;
  }

  .h-xxl-450px {
    height: 450px !important;
  }

  .h-xxl-475px {
    height: 475px !important;
  }

  .h-xxl-500px {
    height: 500px !important;
  }

  .h-xxl-550px {
    height: 550px !important;
  }

  .h-xxl-600px {
    height: 600px !important;
  }

  .h-xxl-650px {
    height: 650px !important;
  }

  .h-xxl-700px {
    height: 700px !important;
  }

  .h-xxl-750px {
    height: 750px !important;
  }

  .h-xxl-800px {
    height: 800px !important;
  }

  .h-xxl-850px {
    height: 850px !important;
  }

  .h-xxl-900px {
    height: 900px !important;
  }

  .h-xxl-950px {
    height: 950px !important;
  }

  .h-xxl-1000px {
    height: 1000px !important;
  }

  .mh-xxl-unset {
    max-height: unset !important;
  }

  .mh-xxl-25 {
    max-height: 25% !important;
  }

  .mh-xxl-50 {
    max-height: 50% !important;
  }

  .mh-xxl-75 {
    max-height: 75% !important;
  }

  .mh-xxl-100 {
    max-height: 100% !important;
  }

  .mh-xxl-auto {
    max-height: auto !important;
  }

  .mh-xxl-1px {
    max-height: 1px !important;
  }

  .mh-xxl-2px {
    max-height: 2px !important;
  }

  .mh-xxl-3px {
    max-height: 3px !important;
  }

  .mh-xxl-4px {
    max-height: 4px !important;
  }

  .mh-xxl-5px {
    max-height: 5px !important;
  }

  .mh-xxl-6px {
    max-height: 6px !important;
  }

  .mh-xxl-7px {
    max-height: 7px !important;
  }

  .mh-xxl-8px {
    max-height: 8px !important;
  }

  .mh-xxl-9px {
    max-height: 9px !important;
  }

  .mh-xxl-10px {
    max-height: 10px !important;
  }

  .mh-xxl-15px {
    max-height: 15px !important;
  }

  .mh-xxl-20px {
    max-height: 20px !important;
  }

  .mh-xxl-25px {
    max-height: 25px !important;
  }

  .mh-xxl-30px {
    max-height: 30px !important;
  }

  .mh-xxl-35px {
    max-height: 35px !important;
  }

  .mh-xxl-40px {
    max-height: 40px !important;
  }

  .mh-xxl-45px {
    max-height: 45px !important;
  }

  .mh-xxl-50px {
    max-height: 50px !important;
  }

  .mh-xxl-55px {
    max-height: 55px !important;
  }

  .mh-xxl-60px {
    max-height: 60px !important;
  }

  .mh-xxl-65px {
    max-height: 65px !important;
  }

  .mh-xxl-70px {
    max-height: 70px !important;
  }

  .mh-xxl-75px {
    max-height: 75px !important;
  }

  .mh-xxl-80px {
    max-height: 80px !important;
  }

  .mh-xxl-85px {
    max-height: 85px !important;
  }

  .mh-xxl-90px {
    max-height: 90px !important;
  }

  .mh-xxl-95px {
    max-height: 95px !important;
  }

  .mh-xxl-100px {
    max-height: 100px !important;
  }

  .mh-xxl-125px {
    max-height: 125px !important;
  }

  .mh-xxl-150px {
    max-height: 150px !important;
  }

  .mh-xxl-175px {
    max-height: 175px !important;
  }

  .mh-xxl-200px {
    max-height: 200px !important;
  }

  .mh-xxl-225px {
    max-height: 225px !important;
  }

  .mh-xxl-250px {
    max-height: 250px !important;
  }

  .mh-xxl-275px {
    max-height: 275px !important;
  }

  .mh-xxl-300px {
    max-height: 300px !important;
  }

  .mh-xxl-325px {
    max-height: 325px !important;
  }

  .mh-xxl-350px {
    max-height: 350px !important;
  }

  .mh-xxl-375px {
    max-height: 375px !important;
  }

  .mh-xxl-400px {
    max-height: 400px !important;
  }

  .mh-xxl-425px {
    max-height: 425px !important;
  }

  .mh-xxl-450px {
    max-height: 450px !important;
  }

  .mh-xxl-475px {
    max-height: 475px !important;
  }

  .mh-xxl-500px {
    max-height: 500px !important;
  }

  .mh-xxl-550px {
    max-height: 550px !important;
  }

  .mh-xxl-600px {
    max-height: 600px !important;
  }

  .mh-xxl-650px {
    max-height: 650px !important;
  }

  .mh-xxl-700px {
    max-height: 700px !important;
  }

  .mh-xxl-750px {
    max-height: 750px !important;
  }

  .mh-xxl-800px {
    max-height: 800px !important;
  }

  .mh-xxl-850px {
    max-height: 850px !important;
  }

  .mh-xxl-900px {
    max-height: 900px !important;
  }

  .mh-xxl-950px {
    max-height: 950px !important;
  }

  .mh-xxl-1000px {
    max-height: 1000px !important;
  }

  .flex-xxl-fill {
    flex: 1 1 auto !important;
  }

  .flex-xxl-row {
    flex-direction: row !important;
  }

  .flex-xxl-column {
    flex-direction: column !important;
  }

  .flex-xxl-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-xxl-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-xxl-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-xxl-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-xxl-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-xxl-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-xxl-wrap {
    flex-wrap: wrap !important;
  }

  .flex-xxl-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-xxl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .gap-xxl-0 {
    gap: 0 !important;
  }

  .gap-xxl-1 {
    gap: 0.25rem !important;
  }

  .gap-xxl-2 {
    gap: 0.5rem !important;
  }

  .gap-xxl-3 {
    gap: 0.75rem !important;
  }

  .gap-xxl-4 {
    gap: 1rem !important;
  }

  .gap-xxl-5 {
    gap: 1.25rem !important;
  }

  .gap-xxl-6 {
    gap: 1.5rem !important;
  }

  .gap-xxl-7 {
    gap: 1.75rem !important;
  }

  .gap-xxl-8 {
    gap: 2rem !important;
  }

  .gap-xxl-9 {
    gap: 2.25rem !important;
  }

  .gap-xxl-10 {
    gap: 2.5rem !important;
  }

  .gap-xxl-11 {
    gap: 2.75rem !important;
  }

  .gap-xxl-12 {
    gap: 3rem !important;
  }

  .gap-xxl-13 {
    gap: 3.25rem !important;
  }

  .gap-xxl-14 {
    gap: 3.5rem !important;
  }

  .gap-xxl-15 {
    gap: 3.75rem !important;
  }

  .gap-xxl-16 {
    gap: 4rem !important;
  }

  .gap-xxl-17 {
    gap: 4.25rem !important;
  }

  .gap-xxl-18 {
    gap: 4.5rem !important;
  }

  .gap-xxl-19 {
    gap: 4.75rem !important;
  }

  .gap-xxl-20 {
    gap: 5rem !important;
  }

  .justify-content-xxl-start {
    justify-content: flex-start !important;
  }

  .justify-content-xxl-end {
    justify-content: flex-end !important;
  }

  .justify-content-xxl-center {
    justify-content: center !important;
  }

  .justify-content-xxl-between {
    justify-content: space-between !important;
  }

  .justify-content-xxl-around {
    justify-content: space-around !important;
  }

  .justify-content-xxl-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-xxl-start {
    align-items: flex-start !important;
  }

  .align-items-xxl-end {
    align-items: flex-end !important;
  }

  .align-items-xxl-center {
    align-items: center !important;
  }

  .align-items-xxl-baseline {
    align-items: baseline !important;
  }

  .align-items-xxl-stretch {
    align-items: stretch !important;
  }

  .align-content-xxl-start {
    align-content: flex-start !important;
  }

  .align-content-xxl-end {
    align-content: flex-end !important;
  }

  .align-content-xxl-center {
    align-content: center !important;
  }

  .align-content-xxl-between {
    align-content: space-between !important;
  }

  .align-content-xxl-around {
    align-content: space-around !important;
  }

  .align-content-xxl-stretch {
    align-content: stretch !important;
  }

  .align-self-xxl-auto {
    align-self: auto !important;
  }

  .align-self-xxl-start {
    align-self: flex-start !important;
  }

  .align-self-xxl-end {
    align-self: flex-end !important;
  }

  .align-self-xxl-center {
    align-self: center !important;
  }

  .align-self-xxl-baseline {
    align-self: baseline !important;
  }

  .align-self-xxl-stretch {
    align-self: stretch !important;
  }

  .order-xxl-first {
    order: -1 !important;
  }

  .order-xxl-0 {
    order: 0 !important;
  }

  .order-xxl-1 {
    order: 1 !important;
  }

  .order-xxl-2 {
    order: 2 !important;
  }

  .order-xxl-3 {
    order: 3 !important;
  }

  .order-xxl-4 {
    order: 4 !important;
  }

  .order-xxl-5 {
    order: 5 !important;
  }

  .order-xxl-last {
    order: 6 !important;
  }

  .m-xxl-0 {
    margin: 0 !important;
  }

  .m-xxl-1 {
    margin: 0.25rem !important;
  }

  .m-xxl-2 {
    margin: 0.5rem !important;
  }

  .m-xxl-3 {
    margin: 0.75rem !important;
  }

  .m-xxl-4 {
    margin: 1rem !important;
  }

  .m-xxl-5 {
    margin: 1.25rem !important;
  }

  .m-xxl-6 {
    margin: 1.5rem !important;
  }

  .m-xxl-7 {
    margin: 1.75rem !important;
  }

  .m-xxl-8 {
    margin: 2rem !important;
  }

  .m-xxl-9 {
    margin: 2.25rem !important;
  }

  .m-xxl-10 {
    margin: 2.5rem !important;
  }

  .m-xxl-11 {
    margin: 2.75rem !important;
  }

  .m-xxl-12 {
    margin: 3rem !important;
  }

  .m-xxl-13 {
    margin: 3.25rem !important;
  }

  .m-xxl-14 {
    margin: 3.5rem !important;
  }

  .m-xxl-15 {
    margin: 3.75rem !important;
  }

  .m-xxl-16 {
    margin: 4rem !important;
  }

  .m-xxl-17 {
    margin: 4.25rem !important;
  }

  .m-xxl-18 {
    margin: 4.5rem !important;
  }

  .m-xxl-19 {
    margin: 4.75rem !important;
  }

  .m-xxl-20 {
    margin: 5rem !important;
  }

  .m-xxl-auto {
    margin: auto !important;
  }

  .mx-xxl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .mx-xxl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }

  .mx-xxl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }

  .mx-xxl-3 {
    margin-right: 0.75rem !important;
    margin-left: 0.75rem !important;
  }

  .mx-xxl-4 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }

  .mx-xxl-5 {
    margin-right: 1.25rem !important;
    margin-left: 1.25rem !important;
  }

  .mx-xxl-6 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }

  .mx-xxl-7 {
    margin-right: 1.75rem !important;
    margin-left: 1.75rem !important;
  }

  .mx-xxl-8 {
    margin-right: 2rem !important;
    margin-left: 2rem !important;
  }

  .mx-xxl-9 {
    margin-right: 2.25rem !important;
    margin-left: 2.25rem !important;
  }

  .mx-xxl-10 {
    margin-right: 2.5rem !important;
    margin-left: 2.5rem !important;
  }

  .mx-xxl-11 {
    margin-right: 2.75rem !important;
    margin-left: 2.75rem !important;
  }

  .mx-xxl-12 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }

  .mx-xxl-13 {
    margin-right: 3.25rem !important;
    margin-left: 3.25rem !important;
  }

  .mx-xxl-14 {
    margin-right: 3.5rem !important;
    margin-left: 3.5rem !important;
  }

  .mx-xxl-15 {
    margin-right: 3.75rem !important;
    margin-left: 3.75rem !important;
  }

  .mx-xxl-16 {
    margin-right: 4rem !important;
    margin-left: 4rem !important;
  }

  .mx-xxl-17 {
    margin-right: 4.25rem !important;
    margin-left: 4.25rem !important;
  }

  .mx-xxl-18 {
    margin-right: 4.5rem !important;
    margin-left: 4.5rem !important;
  }

  .mx-xxl-19 {
    margin-right: 4.75rem !important;
    margin-left: 4.75rem !important;
  }

  .mx-xxl-20 {
    margin-right: 5rem !important;
    margin-left: 5rem !important;
  }

  .mx-xxl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .my-xxl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-xxl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }

  .my-xxl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .my-xxl-3 {
    margin-top: 0.75rem !important;
    margin-bottom: 0.75rem !important;
  }

  .my-xxl-4 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-xxl-5 {
    margin-top: 1.25rem !important;
    margin-bottom: 1.25rem !important;
  }

  .my-xxl-6 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-xxl-7 {
    margin-top: 1.75rem !important;
    margin-bottom: 1.75rem !important;
  }

  .my-xxl-8 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }

  .my-xxl-9 {
    margin-top: 2.25rem !important;
    margin-bottom: 2.25rem !important;
  }

  .my-xxl-10 {
    margin-top: 2.5rem !important;
    margin-bottom: 2.5rem !important;
  }

  .my-xxl-11 {
    margin-top: 2.75rem !important;
    margin-bottom: 2.75rem !important;
  }

  .my-xxl-12 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-xxl-13 {
    margin-top: 3.25rem !important;
    margin-bottom: 3.25rem !important;
  }

  .my-xxl-14 {
    margin-top: 3.5rem !important;
    margin-bottom: 3.5rem !important;
  }

  .my-xxl-15 {
    margin-top: 3.75rem !important;
    margin-bottom: 3.75rem !important;
  }

  .my-xxl-16 {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important;
  }

  .my-xxl-17 {
    margin-top: 4.25rem !important;
    margin-bottom: 4.25rem !important;
  }

  .my-xxl-18 {
    margin-top: 4.5rem !important;
    margin-bottom: 4.5rem !important;
  }

  .my-xxl-19 {
    margin-top: 4.75rem !important;
    margin-bottom: 4.75rem !important;
  }

  .my-xxl-20 {
    margin-top: 5rem !important;
    margin-bottom: 5rem !important;
  }

  .my-xxl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-xxl-0 {
    margin-top: 0 !important;
  }

  .mt-xxl-1 {
    margin-top: 0.25rem !important;
  }

  .mt-xxl-2 {
    margin-top: 0.5rem !important;
  }

  .mt-xxl-3 {
    margin-top: 0.75rem !important;
  }

  .mt-xxl-4 {
    margin-top: 1rem !important;
  }

  .mt-xxl-5 {
    margin-top: 1.25rem !important;
  }

  .mt-xxl-6 {
    margin-top: 1.5rem !important;
  }

  .mt-xxl-7 {
    margin-top: 1.75rem !important;
  }

  .mt-xxl-8 {
    margin-top: 2rem !important;
  }

  .mt-xxl-9 {
    margin-top: 2.25rem !important;
  }

  .mt-xxl-10 {
    margin-top: 2.5rem !important;
  }

  .mt-xxl-11 {
    margin-top: 2.75rem !important;
  }

  .mt-xxl-12 {
    margin-top: 3rem !important;
  }

  .mt-xxl-13 {
    margin-top: 3.25rem !important;
  }

  .mt-xxl-14 {
    margin-top: 3.5rem !important;
  }

  .mt-xxl-15 {
    margin-top: 3.75rem !important;
  }

  .mt-xxl-16 {
    margin-top: 4rem !important;
  }

  .mt-xxl-17 {
    margin-top: 4.25rem !important;
  }

  .mt-xxl-18 {
    margin-top: 4.5rem !important;
  }

  .mt-xxl-19 {
    margin-top: 4.75rem !important;
  }

  .mt-xxl-20 {
    margin-top: 5rem !important;
  }

  .mt-xxl-auto {
    margin-top: auto !important;
  }

  .me-xxl-0 {
    margin-right: 0 !important;
  }

  .me-xxl-1 {
    margin-right: 0.25rem !important;
  }

  .me-xxl-2 {
    margin-right: 0.5rem !important;
  }

  .me-xxl-3 {
    margin-right: 0.75rem !important;
  }

  .me-xxl-4 {
    margin-right: 1rem !important;
  }

  .me-xxl-5 {
    margin-right: 1.25rem !important;
  }

  .me-xxl-6 {
    margin-right: 1.5rem !important;
  }

  .me-xxl-7 {
    margin-right: 1.75rem !important;
  }

  .me-xxl-8 {
    margin-right: 2rem !important;
  }

  .me-xxl-9 {
    margin-right: 2.25rem !important;
  }

  .me-xxl-10 {
    margin-right: 2.5rem !important;
  }

  .me-xxl-11 {
    margin-right: 2.75rem !important;
  }

  .me-xxl-12 {
    margin-right: 3rem !important;
  }

  .me-xxl-13 {
    margin-right: 3.25rem !important;
  }

  .me-xxl-14 {
    margin-right: 3.5rem !important;
  }

  .me-xxl-15 {
    margin-right: 3.75rem !important;
  }

  .me-xxl-16 {
    margin-right: 4rem !important;
  }

  .me-xxl-17 {
    margin-right: 4.25rem !important;
  }

  .me-xxl-18 {
    margin-right: 4.5rem !important;
  }

  .me-xxl-19 {
    margin-right: 4.75rem !important;
  }

  .me-xxl-20 {
    margin-right: 5rem !important;
  }

  .me-xxl-auto {
    margin-right: auto !important;
  }

  .mb-xxl-0 {
    margin-bottom: 0 !important;
  }

  .mb-xxl-1 {
    margin-bottom: 0.25rem !important;
  }

  .mb-xxl-2 {
    margin-bottom: 0.5rem !important;
  }

  .mb-xxl-3 {
    margin-bottom: 0.75rem !important;
  }

  .mb-xxl-4 {
    margin-bottom: 1rem !important;
  }

  .mb-xxl-5 {
    margin-bottom: 1.25rem !important;
  }

  .mb-xxl-6 {
    margin-bottom: 1.5rem !important;
  }

  .mb-xxl-7 {
    margin-bottom: 1.75rem !important;
  }

  .mb-xxl-8 {
    margin-bottom: 2rem !important;
  }

  .mb-xxl-9 {
    margin-bottom: 2.25rem !important;
  }

  .mb-xxl-10 {
    margin-bottom: 2.5rem !important;
  }

  .mb-xxl-11 {
    margin-bottom: 2.75rem !important;
  }

  .mb-xxl-12 {
    margin-bottom: 3rem !important;
  }

  .mb-xxl-13 {
    margin-bottom: 3.25rem !important;
  }

  .mb-xxl-14 {
    margin-bottom: 3.5rem !important;
  }

  .mb-xxl-15 {
    margin-bottom: 3.75rem !important;
  }

  .mb-xxl-16 {
    margin-bottom: 4rem !important;
  }

  .mb-xxl-17 {
    margin-bottom: 4.25rem !important;
  }

  .mb-xxl-18 {
    margin-bottom: 4.5rem !important;
  }

  .mb-xxl-19 {
    margin-bottom: 4.75rem !important;
  }

  .mb-xxl-20 {
    margin-bottom: 5rem !important;
  }

  .mb-xxl-auto {
    margin-bottom: auto !important;
  }

  .ms-xxl-0 {
    margin-left: 0 !important;
  }

  .ms-xxl-1 {
    margin-left: 0.25rem !important;
  }

  .ms-xxl-2 {
    margin-left: 0.5rem !important;
  }

  .ms-xxl-3 {
    margin-left: 0.75rem !important;
  }

  .ms-xxl-4 {
    margin-left: 1rem !important;
  }

  .ms-xxl-5 {
    margin-left: 1.25rem !important;
  }

  .ms-xxl-6 {
    margin-left: 1.5rem !important;
  }

  .ms-xxl-7 {
    margin-left: 1.75rem !important;
  }

  .ms-xxl-8 {
    margin-left: 2rem !important;
  }

  .ms-xxl-9 {
    margin-left: 2.25rem !important;
  }

  .ms-xxl-10 {
    margin-left: 2.5rem !important;
  }

  .ms-xxl-11 {
    margin-left: 2.75rem !important;
  }

  .ms-xxl-12 {
    margin-left: 3rem !important;
  }

  .ms-xxl-13 {
    margin-left: 3.25rem !important;
  }

  .ms-xxl-14 {
    margin-left: 3.5rem !important;
  }

  .ms-xxl-15 {
    margin-left: 3.75rem !important;
  }

  .ms-xxl-16 {
    margin-left: 4rem !important;
  }

  .ms-xxl-17 {
    margin-left: 4.25rem !important;
  }

  .ms-xxl-18 {
    margin-left: 4.5rem !important;
  }

  .ms-xxl-19 {
    margin-left: 4.75rem !important;
  }

  .ms-xxl-20 {
    margin-left: 5rem !important;
  }

  .ms-xxl-auto {
    margin-left: auto !important;
  }

  .m-xxl-n1 {
    margin: -0.25rem !important;
  }

  .m-xxl-n2 {
    margin: -0.5rem !important;
  }

  .m-xxl-n3 {
    margin: -0.75rem !important;
  }

  .m-xxl-n4 {
    margin: -1rem !important;
  }

  .m-xxl-n5 {
    margin: -1.25rem !important;
  }

  .m-xxl-n6 {
    margin: -1.5rem !important;
  }

  .m-xxl-n7 {
    margin: -1.75rem !important;
  }

  .m-xxl-n8 {
    margin: -2rem !important;
  }

  .m-xxl-n9 {
    margin: -2.25rem !important;
  }

  .m-xxl-n10 {
    margin: -2.5rem !important;
  }

  .m-xxl-n11 {
    margin: -2.75rem !important;
  }

  .m-xxl-n12 {
    margin: -3rem !important;
  }

  .m-xxl-n13 {
    margin: -3.25rem !important;
  }

  .m-xxl-n14 {
    margin: -3.5rem !important;
  }

  .m-xxl-n15 {
    margin: -3.75rem !important;
  }

  .m-xxl-n16 {
    margin: -4rem !important;
  }

  .m-xxl-n17 {
    margin: -4.25rem !important;
  }

  .m-xxl-n18 {
    margin: -4.5rem !important;
  }

  .m-xxl-n19 {
    margin: -4.75rem !important;
  }

  .m-xxl-n20 {
    margin: -5rem !important;
  }

  .mx-xxl-n1 {
    margin-right: -0.25rem !important;
    margin-left: -0.25rem !important;
  }

  .mx-xxl-n2 {
    margin-right: -0.5rem !important;
    margin-left: -0.5rem !important;
  }

  .mx-xxl-n3 {
    margin-right: -0.75rem !important;
    margin-left: -0.75rem !important;
  }

  .mx-xxl-n4 {
    margin-right: -1rem !important;
    margin-left: -1rem !important;
  }

  .mx-xxl-n5 {
    margin-right: -1.25rem !important;
    margin-left: -1.25rem !important;
  }

  .mx-xxl-n6 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important;
  }

  .mx-xxl-n7 {
    margin-right: -1.75rem !important;
    margin-left: -1.75rem !important;
  }

  .mx-xxl-n8 {
    margin-right: -2rem !important;
    margin-left: -2rem !important;
  }

  .mx-xxl-n9 {
    margin-right: -2.25rem !important;
    margin-left: -2.25rem !important;
  }

  .mx-xxl-n10 {
    margin-right: -2.5rem !important;
    margin-left: -2.5rem !important;
  }

  .mx-xxl-n11 {
    margin-right: -2.75rem !important;
    margin-left: -2.75rem !important;
  }

  .mx-xxl-n12 {
    margin-right: -3rem !important;
    margin-left: -3rem !important;
  }

  .mx-xxl-n13 {
    margin-right: -3.25rem !important;
    margin-left: -3.25rem !important;
  }

  .mx-xxl-n14 {
    margin-right: -3.5rem !important;
    margin-left: -3.5rem !important;
  }

  .mx-xxl-n15 {
    margin-right: -3.75rem !important;
    margin-left: -3.75rem !important;
  }

  .mx-xxl-n16 {
    margin-right: -4rem !important;
    margin-left: -4rem !important;
  }

  .mx-xxl-n17 {
    margin-right: -4.25rem !important;
    margin-left: -4.25rem !important;
  }

  .mx-xxl-n18 {
    margin-right: -4.5rem !important;
    margin-left: -4.5rem !important;
  }

  .mx-xxl-n19 {
    margin-right: -4.75rem !important;
    margin-left: -4.75rem !important;
  }

  .mx-xxl-n20 {
    margin-right: -5rem !important;
    margin-left: -5rem !important;
  }

  .my-xxl-n1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important;
  }

  .my-xxl-n2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important;
  }

  .my-xxl-n3 {
    margin-top: -0.75rem !important;
    margin-bottom: -0.75rem !important;
  }

  .my-xxl-n4 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }

  .my-xxl-n5 {
    margin-top: -1.25rem !important;
    margin-bottom: -1.25rem !important;
  }

  .my-xxl-n6 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }

  .my-xxl-n7 {
    margin-top: -1.75rem !important;
    margin-bottom: -1.75rem !important;
  }

  .my-xxl-n8 {
    margin-top: -2rem !important;
    margin-bottom: -2rem !important;
  }

  .my-xxl-n9 {
    margin-top: -2.25rem !important;
    margin-bottom: -2.25rem !important;
  }

  .my-xxl-n10 {
    margin-top: -2.5rem !important;
    margin-bottom: -2.5rem !important;
  }

  .my-xxl-n11 {
    margin-top: -2.75rem !important;
    margin-bottom: -2.75rem !important;
  }

  .my-xxl-n12 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }

  .my-xxl-n13 {
    margin-top: -3.25rem !important;
    margin-bottom: -3.25rem !important;
  }

  .my-xxl-n14 {
    margin-top: -3.5rem !important;
    margin-bottom: -3.5rem !important;
  }

  .my-xxl-n15 {
    margin-top: -3.75rem !important;
    margin-bottom: -3.75rem !important;
  }

  .my-xxl-n16 {
    margin-top: -4rem !important;
    margin-bottom: -4rem !important;
  }

  .my-xxl-n17 {
    margin-top: -4.25rem !important;
    margin-bottom: -4.25rem !important;
  }

  .my-xxl-n18 {
    margin-top: -4.5rem !important;
    margin-bottom: -4.5rem !important;
  }

  .my-xxl-n19 {
    margin-top: -4.75rem !important;
    margin-bottom: -4.75rem !important;
  }

  .my-xxl-n20 {
    margin-top: -5rem !important;
    margin-bottom: -5rem !important;
  }

  .mt-xxl-n1 {
    margin-top: -0.25rem !important;
  }

  .mt-xxl-n2 {
    margin-top: -0.5rem !important;
  }

  .mt-xxl-n3 {
    margin-top: -0.75rem !important;
  }

  .mt-xxl-n4 {
    margin-top: -1rem !important;
  }

  .mt-xxl-n5 {
    margin-top: -1.25rem !important;
  }

  .mt-xxl-n6 {
    margin-top: -1.5rem !important;
  }

  .mt-xxl-n7 {
    margin-top: -1.75rem !important;
  }

  .mt-xxl-n8 {
    margin-top: -2rem !important;
  }

  .mt-xxl-n9 {
    margin-top: -2.25rem !important;
  }

  .mt-xxl-n10 {
    margin-top: -2.5rem !important;
  }

  .mt-xxl-n11 {
    margin-top: -2.75rem !important;
  }

  .mt-xxl-n12 {
    margin-top: -3rem !important;
  }

  .mt-xxl-n13 {
    margin-top: -3.25rem !important;
  }

  .mt-xxl-n14 {
    margin-top: -3.5rem !important;
  }

  .mt-xxl-n15 {
    margin-top: -3.75rem !important;
  }

  .mt-xxl-n16 {
    margin-top: -4rem !important;
  }

  .mt-xxl-n17 {
    margin-top: -4.25rem !important;
  }

  .mt-xxl-n18 {
    margin-top: -4.5rem !important;
  }

  .mt-xxl-n19 {
    margin-top: -4.75rem !important;
  }

  .mt-xxl-n20 {
    margin-top: -5rem !important;
  }

  .me-xxl-n1 {
    margin-right: -0.25rem !important;
  }

  .me-xxl-n2 {
    margin-right: -0.5rem !important;
  }

  .me-xxl-n3 {
    margin-right: -0.75rem !important;
  }

  .me-xxl-n4 {
    margin-right: -1rem !important;
  }

  .me-xxl-n5 {
    margin-right: -1.25rem !important;
  }

  .me-xxl-n6 {
    margin-right: -1.5rem !important;
  }

  .me-xxl-n7 {
    margin-right: -1.75rem !important;
  }

  .me-xxl-n8 {
    margin-right: -2rem !important;
  }

  .me-xxl-n9 {
    margin-right: -2.25rem !important;
  }

  .me-xxl-n10 {
    margin-right: -2.5rem !important;
  }

  .me-xxl-n11 {
    margin-right: -2.75rem !important;
  }

  .me-xxl-n12 {
    margin-right: -3rem !important;
  }

  .me-xxl-n13 {
    margin-right: -3.25rem !important;
  }

  .me-xxl-n14 {
    margin-right: -3.5rem !important;
  }

  .me-xxl-n15 {
    margin-right: -3.75rem !important;
  }

  .me-xxl-n16 {
    margin-right: -4rem !important;
  }

  .me-xxl-n17 {
    margin-right: -4.25rem !important;
  }

  .me-xxl-n18 {
    margin-right: -4.5rem !important;
  }

  .me-xxl-n19 {
    margin-right: -4.75rem !important;
  }

  .me-xxl-n20 {
    margin-right: -5rem !important;
  }

  .mb-xxl-n1 {
    margin-bottom: -0.25rem !important;
  }

  .mb-xxl-n2 {
    margin-bottom: -0.5rem !important;
  }

  .mb-xxl-n3 {
    margin-bottom: -0.75rem !important;
  }

  .mb-xxl-n4 {
    margin-bottom: -1rem !important;
  }

  .mb-xxl-n5 {
    margin-bottom: -1.25rem !important;
  }

  .mb-xxl-n6 {
    margin-bottom: -1.5rem !important;
  }

  .mb-xxl-n7 {
    margin-bottom: -1.75rem !important;
  }

  .mb-xxl-n8 {
    margin-bottom: -2rem !important;
  }

  .mb-xxl-n9 {
    margin-bottom: -2.25rem !important;
  }

  .mb-xxl-n10 {
    margin-bottom: -2.5rem !important;
  }

  .mb-xxl-n11 {
    margin-bottom: -2.75rem !important;
  }

  .mb-xxl-n12 {
    margin-bottom: -3rem !important;
  }

  .mb-xxl-n13 {
    margin-bottom: -3.25rem !important;
  }

  .mb-xxl-n14 {
    margin-bottom: -3.5rem !important;
  }

  .mb-xxl-n15 {
    margin-bottom: -3.75rem !important;
  }

  .mb-xxl-n16 {
    margin-bottom: -4rem !important;
  }

  .mb-xxl-n17 {
    margin-bottom: -4.25rem !important;
  }

  .mb-xxl-n18 {
    margin-bottom: -4.5rem !important;
  }

  .mb-xxl-n19 {
    margin-bottom: -4.75rem !important;
  }

  .mb-xxl-n20 {
    margin-bottom: -5rem !important;
  }

  .ms-xxl-n1 {
    margin-left: -0.25rem !important;
  }

  .ms-xxl-n2 {
    margin-left: -0.5rem !important;
  }

  .ms-xxl-n3 {
    margin-left: -0.75rem !important;
  }

  .ms-xxl-n4 {
    margin-left: -1rem !important;
  }

  .ms-xxl-n5 {
    margin-left: -1.25rem !important;
  }

  .ms-xxl-n6 {
    margin-left: -1.5rem !important;
  }

  .ms-xxl-n7 {
    margin-left: -1.75rem !important;
  }

  .ms-xxl-n8 {
    margin-left: -2rem !important;
  }

  .ms-xxl-n9 {
    margin-left: -2.25rem !important;
  }

  .ms-xxl-n10 {
    margin-left: -2.5rem !important;
  }

  .ms-xxl-n11 {
    margin-left: -2.75rem !important;
  }

  .ms-xxl-n12 {
    margin-left: -3rem !important;
  }

  .ms-xxl-n13 {
    margin-left: -3.25rem !important;
  }

  .ms-xxl-n14 {
    margin-left: -3.5rem !important;
  }

  .ms-xxl-n15 {
    margin-left: -3.75rem !important;
  }

  .ms-xxl-n16 {
    margin-left: -4rem !important;
  }

  .ms-xxl-n17 {
    margin-left: -4.25rem !important;
  }

  .ms-xxl-n18 {
    margin-left: -4.5rem !important;
  }

  .ms-xxl-n19 {
    margin-left: -4.75rem !important;
  }

  .ms-xxl-n20 {
    margin-left: -5rem !important;
  }

  .p-xxl-0 {
    padding: 0 !important;
  }

  .p-xxl-1 {
    padding: 0.25rem !important;
  }

  .p-xxl-2 {
    padding: 0.5rem !important;
  }

  .p-xxl-3 {
    padding: 0.75rem !important;
  }

  .p-xxl-4 {
    padding: 1rem !important;
  }

  .p-xxl-5 {
    padding: 1.25rem !important;
  }

  .p-xxl-6 {
    padding: 1.5rem !important;
  }

  .p-xxl-7 {
    padding: 1.75rem !important;
  }

  .p-xxl-8 {
    padding: 2rem !important;
  }

  .p-xxl-9 {
    padding: 2.25rem !important;
  }

  .p-xxl-10 {
    padding: 2.5rem !important;
  }

  .p-xxl-11 {
    padding: 2.75rem !important;
  }

  .p-xxl-12 {
    padding: 3rem !important;
  }

  .p-xxl-13 {
    padding: 3.25rem !important;
  }

  .p-xxl-14 {
    padding: 3.5rem !important;
  }

  .p-xxl-15 {
    padding: 3.75rem !important;
  }

  .p-xxl-16 {
    padding: 4rem !important;
  }

  .p-xxl-17 {
    padding: 4.25rem !important;
  }

  .p-xxl-18 {
    padding: 4.5rem !important;
  }

  .p-xxl-19 {
    padding: 4.75rem !important;
  }

  .p-xxl-20 {
    padding: 5rem !important;
  }

  .px-xxl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .px-xxl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }

  .px-xxl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }

  .px-xxl-3 {
    padding-right: 0.75rem !important;
    padding-left: 0.75rem !important;
  }

  .px-xxl-4 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }

  .px-xxl-5 {
    padding-right: 1.25rem !important;
    padding-left: 1.25rem !important;
  }

  .px-xxl-6 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }

  .px-xxl-7 {
    padding-right: 1.75rem !important;
    padding-left: 1.75rem !important;
  }

  .px-xxl-8 {
    padding-right: 2rem !important;
    padding-left: 2rem !important;
  }

  .px-xxl-9 {
    padding-right: 2.25rem !important;
    padding-left: 2.25rem !important;
  }

  .px-xxl-10 {
    padding-right: 2.5rem !important;
    padding-left: 2.5rem !important;
  }

  .px-xxl-11 {
    padding-right: 2.75rem !important;
    padding-left: 2.75rem !important;
  }

  .px-xxl-12 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }

  .px-xxl-13 {
    padding-right: 3.25rem !important;
    padding-left: 3.25rem !important;
  }

  .px-xxl-14 {
    padding-right: 3.5rem !important;
    padding-left: 3.5rem !important;
  }

  .px-xxl-15 {
    padding-right: 3.75rem !important;
    padding-left: 3.75rem !important;
  }

  .px-xxl-16 {
    padding-right: 4rem !important;
    padding-left: 4rem !important;
  }

  .px-xxl-17 {
    padding-right: 4.25rem !important;
    padding-left: 4.25rem !important;
  }

  .px-xxl-18 {
    padding-right: 4.5rem !important;
    padding-left: 4.5rem !important;
  }

  .px-xxl-19 {
    padding-right: 4.75rem !important;
    padding-left: 4.75rem !important;
  }

  .px-xxl-20 {
    padding-right: 5rem !important;
    padding-left: 5rem !important;
  }

  .py-xxl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-xxl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }

  .py-xxl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .py-xxl-3 {
    padding-top: 0.75rem !important;
    padding-bottom: 0.75rem !important;
  }

  .py-xxl-4 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-xxl-5 {
    padding-top: 1.25rem !important;
    padding-bottom: 1.25rem !important;
  }

  .py-xxl-6 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-xxl-7 {
    padding-top: 1.75rem !important;
    padding-bottom: 1.75rem !important;
  }

  .py-xxl-8 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }

  .py-xxl-9 {
    padding-top: 2.25rem !important;
    padding-bottom: 2.25rem !important;
  }

  .py-xxl-10 {
    padding-top: 2.5rem !important;
    padding-bottom: 2.5rem !important;
  }

  .py-xxl-11 {
    padding-top: 2.75rem !important;
    padding-bottom: 2.75rem !important;
  }

  .py-xxl-12 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .py-xxl-13 {
    padding-top: 3.25rem !important;
    padding-bottom: 3.25rem !important;
  }

  .py-xxl-14 {
    padding-top: 3.5rem !important;
    padding-bottom: 3.5rem !important;
  }

  .py-xxl-15 {
    padding-top: 3.75rem !important;
    padding-bottom: 3.75rem !important;
  }

  .py-xxl-16 {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important;
  }

  .py-xxl-17 {
    padding-top: 4.25rem !important;
    padding-bottom: 4.25rem !important;
  }

  .py-xxl-18 {
    padding-top: 4.5rem !important;
    padding-bottom: 4.5rem !important;
  }

  .py-xxl-19 {
    padding-top: 4.75rem !important;
    padding-bottom: 4.75rem !important;
  }

  .py-xxl-20 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
  }

  .pt-xxl-0 {
    padding-top: 0 !important;
  }

  .pt-xxl-1 {
    padding-top: 0.25rem !important;
  }

  .pt-xxl-2 {
    padding-top: 0.5rem !important;
  }

  .pt-xxl-3 {
    padding-top: 0.75rem !important;
  }

  .pt-xxl-4 {
    padding-top: 1rem !important;
  }

  .pt-xxl-5 {
    padding-top: 1.25rem !important;
  }

  .pt-xxl-6 {
    padding-top: 1.5rem !important;
  }

  .pt-xxl-7 {
    padding-top: 1.75rem !important;
  }

  .pt-xxl-8 {
    padding-top: 2rem !important;
  }

  .pt-xxl-9 {
    padding-top: 2.25rem !important;
  }

  .pt-xxl-10 {
    padding-top: 2.5rem !important;
  }

  .pt-xxl-11 {
    padding-top: 2.75rem !important;
  }

  .pt-xxl-12 {
    padding-top: 3rem !important;
  }

  .pt-xxl-13 {
    padding-top: 3.25rem !important;
  }

  .pt-xxl-14 {
    padding-top: 3.5rem !important;
  }

  .pt-xxl-15 {
    padding-top: 3.75rem !important;
  }

  .pt-xxl-16 {
    padding-top: 4rem !important;
  }

  .pt-xxl-17 {
    padding-top: 4.25rem !important;
  }

  .pt-xxl-18 {
    padding-top: 4.5rem !important;
  }

  .pt-xxl-19 {
    padding-top: 4.75rem !important;
  }

  .pt-xxl-20 {
    padding-top: 5rem !important;
  }

  .pe-xxl-0 {
    padding-right: 0 !important;
  }

  .pe-xxl-1 {
    padding-right: 0.25rem !important;
  }

  .pe-xxl-2 {
    padding-right: 0.5rem !important;
  }

  .pe-xxl-3 {
    padding-right: 0.75rem !important;
  }

  .pe-xxl-4 {
    padding-right: 1rem !important;
  }

  .pe-xxl-5 {
    padding-right: 1.25rem !important;
  }

  .pe-xxl-6 {
    padding-right: 1.5rem !important;
  }

  .pe-xxl-7 {
    padding-right: 1.75rem !important;
  }

  .pe-xxl-8 {
    padding-right: 2rem !important;
  }

  .pe-xxl-9 {
    padding-right: 2.25rem !important;
  }

  .pe-xxl-10 {
    padding-right: 2.5rem !important;
  }

  .pe-xxl-11 {
    padding-right: 2.75rem !important;
  }

  .pe-xxl-12 {
    padding-right: 3rem !important;
  }

  .pe-xxl-13 {
    padding-right: 3.25rem !important;
  }

  .pe-xxl-14 {
    padding-right: 3.5rem !important;
  }

  .pe-xxl-15 {
    padding-right: 3.75rem !important;
  }

  .pe-xxl-16 {
    padding-right: 4rem !important;
  }

  .pe-xxl-17 {
    padding-right: 4.25rem !important;
  }

  .pe-xxl-18 {
    padding-right: 4.5rem !important;
  }

  .pe-xxl-19 {
    padding-right: 4.75rem !important;
  }

  .pe-xxl-20 {
    padding-right: 5rem !important;
  }

  .pb-xxl-0 {
    padding-bottom: 0 !important;
  }

  .pb-xxl-1 {
    padding-bottom: 0.25rem !important;
  }

  .pb-xxl-2 {
    padding-bottom: 0.5rem !important;
  }

  .pb-xxl-3 {
    padding-bottom: 0.75rem !important;
  }

  .pb-xxl-4 {
    padding-bottom: 1rem !important;
  }

  .pb-xxl-5 {
    padding-bottom: 1.25rem !important;
  }

  .pb-xxl-6 {
    padding-bottom: 1.5rem !important;
  }

  .pb-xxl-7 {
    padding-bottom: 1.75rem !important;
  }

  .pb-xxl-8 {
    padding-bottom: 2rem !important;
  }

  .pb-xxl-9 {
    padding-bottom: 2.25rem !important;
  }

  .pb-xxl-10 {
    padding-bottom: 2.5rem !important;
  }

  .pb-xxl-11 {
    padding-bottom: 2.75rem !important;
  }

  .pb-xxl-12 {
    padding-bottom: 3rem !important;
  }

  .pb-xxl-13 {
    padding-bottom: 3.25rem !important;
  }

  .pb-xxl-14 {
    padding-bottom: 3.5rem !important;
  }

  .pb-xxl-15 {
    padding-bottom: 3.75rem !important;
  }

  .pb-xxl-16 {
    padding-bottom: 4rem !important;
  }

  .pb-xxl-17 {
    padding-bottom: 4.25rem !important;
  }

  .pb-xxl-18 {
    padding-bottom: 4.5rem !important;
  }

  .pb-xxl-19 {
    padding-bottom: 4.75rem !important;
  }

  .pb-xxl-20 {
    padding-bottom: 5rem !important;
  }

  .ps-xxl-0 {
    padding-left: 0 !important;
  }

  .ps-xxl-1 {
    padding-left: 0.25rem !important;
  }

  .ps-xxl-2 {
    padding-left: 0.5rem !important;
  }

  .ps-xxl-3 {
    padding-left: 0.75rem !important;
  }

  .ps-xxl-4 {
    padding-left: 1rem !important;
  }

  .ps-xxl-5 {
    padding-left: 1.25rem !important;
  }

  .ps-xxl-6 {
    padding-left: 1.5rem !important;
  }

  .ps-xxl-7 {
    padding-left: 1.75rem !important;
  }

  .ps-xxl-8 {
    padding-left: 2rem !important;
  }

  .ps-xxl-9 {
    padding-left: 2.25rem !important;
  }

  .ps-xxl-10 {
    padding-left: 2.5rem !important;
  }

  .ps-xxl-11 {
    padding-left: 2.75rem !important;
  }

  .ps-xxl-12 {
    padding-left: 3rem !important;
  }

  .ps-xxl-13 {
    padding-left: 3.25rem !important;
  }

  .ps-xxl-14 {
    padding-left: 3.5rem !important;
  }

  .ps-xxl-15 {
    padding-left: 3.75rem !important;
  }

  .ps-xxl-16 {
    padding-left: 4rem !important;
  }

  .ps-xxl-17 {
    padding-left: 4.25rem !important;
  }

  .ps-xxl-18 {
    padding-left: 4.5rem !important;
  }

  .ps-xxl-19 {
    padding-left: 4.75rem !important;
  }

  .ps-xxl-20 {
    padding-left: 5rem !important;
  }

  .fs-xxl-1 {
    font-size: calc(1.3rem + 0.6vw) !important;
  }

  .fs-xxl-2 {
    font-size: calc(1.275rem + 0.3vw) !important;
  }

  .fs-xxl-3 {
    font-size: calc(1.26rem + 0.12vw) !important;
  }

  .fs-xxl-4 {
    font-size: 1.25rem !important;
  }

  .fs-xxl-5 {
    font-size: 1.15rem !important;
  }

  .fs-xxl-6 {
    font-size: 1.075rem !important;
  }

  .fs-xxl-7 {
    font-size: 0.95rem !important;
  }

  .fs-xxl-8 {
    font-size: 0.85rem !important;
  }

  .fs-xxl-9 {
    font-size: 0.75rem !important;
  }

  .fs-xxl-10 {
    font-size: 0.5rem !important;
  }

  .fs-xxl-base {
    font-size: 1rem !important;
  }

  .fs-xxl-fluid {
    font-size: 100% !important;
  }

  .fs-xxl-2x {
    font-size: calc(1.325rem + 0.9vw) !important;
  }

  .fs-xxl-2qx {
    font-size: calc(1.35rem + 1.2vw) !important;
  }

  .fs-xxl-2hx {
    font-size: calc(1.375rem + 1.5vw) !important;
  }

  .fs-xxl-2tx {
    font-size: calc(1.4rem + 1.8vw) !important;
  }

  .fs-xxl-3x {
    font-size: calc(1.425rem + 2.1vw) !important;
  }

  .fs-xxl-3qx {
    font-size: calc(1.45rem + 2.4vw) !important;
  }

  .fs-xxl-3hx {
    font-size: calc(1.475rem + 2.7vw) !important;
  }

  .fs-xxl-3tx {
    font-size: calc(1.5rem + 3vw) !important;
  }

  .fs-xxl-4x {
    font-size: calc(1.525rem + 3.3vw) !important;
  }

  .fs-xxl-4qx {
    font-size: calc(1.55rem + 3.6vw) !important;
  }

  .fs-xxl-4hx {
    font-size: calc(1.575rem + 3.9vw) !important;
  }

  .fs-xxl-4tx {
    font-size: calc(1.6rem + 4.2vw) !important;
  }

  .fs-xxl-5x {
    font-size: calc(1.625rem + 4.5vw) !important;
  }

  .fs-xxl-5qx {
    font-size: calc(1.65rem + 4.8vw) !important;
  }

  .fs-xxl-5hx {
    font-size: calc(1.675rem + 5.1vw) !important;
  }

  .fs-xxl-5tx {
    font-size: calc(1.7rem + 5.4vw) !important;
  }

  .text-xxl-start {
    text-align: left !important;
  }

  .text-xxl-end {
    text-align: right !important;
  }

  .text-xxl-center {
    text-align: center !important;
  }

  .min-w-xxl-unset {
    min-width: unset !important;
  }

  .min-w-xxl-25 {
    min-width: 25% !important;
  }

  .min-w-xxl-50 {
    min-width: 50% !important;
  }

  .min-w-xxl-75 {
    min-width: 75% !important;
  }

  .min-w-xxl-100 {
    min-width: 100% !important;
  }

  .min-w-xxl-auto {
    min-width: auto !important;
  }

  .min-w-xxl-1px {
    min-width: 1px !important;
  }

  .min-w-xxl-2px {
    min-width: 2px !important;
  }

  .min-w-xxl-3px {
    min-width: 3px !important;
  }

  .min-w-xxl-4px {
    min-width: 4px !important;
  }

  .min-w-xxl-5px {
    min-width: 5px !important;
  }

  .min-w-xxl-6px {
    min-width: 6px !important;
  }

  .min-w-xxl-7px {
    min-width: 7px !important;
  }

  .min-w-xxl-8px {
    min-width: 8px !important;
  }

  .min-w-xxl-9px {
    min-width: 9px !important;
  }

  .min-w-xxl-10px {
    min-width: 10px !important;
  }

  .min-w-xxl-15px {
    min-width: 15px !important;
  }

  .min-w-xxl-20px {
    min-width: 20px !important;
  }

  .min-w-xxl-25px {
    min-width: 25px !important;
  }

  .min-w-xxl-30px {
    min-width: 30px !important;
  }

  .min-w-xxl-35px {
    min-width: 35px !important;
  }

  .min-w-xxl-40px {
    min-width: 40px !important;
  }

  .min-w-xxl-45px {
    min-width: 45px !important;
  }

  .min-w-xxl-50px {
    min-width: 50px !important;
  }

  .min-w-xxl-55px {
    min-width: 55px !important;
  }

  .min-w-xxl-60px {
    min-width: 60px !important;
  }

  .min-w-xxl-65px {
    min-width: 65px !important;
  }

  .min-w-xxl-70px {
    min-width: 70px !important;
  }

  .min-w-xxl-75px {
    min-width: 75px !important;
  }

  .min-w-xxl-80px {
    min-width: 80px !important;
  }

  .min-w-xxl-85px {
    min-width: 85px !important;
  }

  .min-w-xxl-90px {
    min-width: 90px !important;
  }

  .min-w-xxl-95px {
    min-width: 95px !important;
  }

  .min-w-xxl-100px {
    min-width: 100px !important;
  }

  .min-w-xxl-125px {
    min-width: 125px !important;
  }

  .min-w-xxl-150px {
    min-width: 150px !important;
  }

  .min-w-xxl-175px {
    min-width: 175px !important;
  }

  .min-w-xxl-200px {
    min-width: 200px !important;
  }

  .min-w-xxl-225px {
    min-width: 225px !important;
  }

  .min-w-xxl-250px {
    min-width: 250px !important;
  }

  .min-w-xxl-275px {
    min-width: 275px !important;
  }

  .min-w-xxl-300px {
    min-width: 300px !important;
  }

  .min-w-xxl-325px {
    min-width: 325px !important;
  }

  .min-w-xxl-350px {
    min-width: 350px !important;
  }

  .min-w-xxl-375px {
    min-width: 375px !important;
  }

  .min-w-xxl-400px {
    min-width: 400px !important;
  }

  .min-w-xxl-425px {
    min-width: 425px !important;
  }

  .min-w-xxl-450px {
    min-width: 450px !important;
  }

  .min-w-xxl-475px {
    min-width: 475px !important;
  }

  .min-w-xxl-500px {
    min-width: 500px !important;
  }

  .min-w-xxl-550px {
    min-width: 550px !important;
  }

  .min-w-xxl-600px {
    min-width: 600px !important;
  }

  .min-w-xxl-650px {
    min-width: 650px !important;
  }

  .min-w-xxl-700px {
    min-width: 700px !important;
  }

  .min-w-xxl-750px {
    min-width: 750px !important;
  }

  .min-w-xxl-800px {
    min-width: 800px !important;
  }

  .min-w-xxl-850px {
    min-width: 850px !important;
  }

  .min-w-xxl-900px {
    min-width: 900px !important;
  }

  .min-w-xxl-950px {
    min-width: 950px !important;
  }

  .min-w-xxl-1000px {
    min-width: 1000px !important;
  }

  .min-h-xxl-unset {
    min-height: unset !important;
  }

  .min-h-xxl-25 {
    min-height: 25% !important;
  }

  .min-h-xxl-50 {
    min-height: 50% !important;
  }

  .min-h-xxl-75 {
    min-height: 75% !important;
  }

  .min-h-xxl-100 {
    min-height: 100% !important;
  }

  .min-h-xxl-auto {
    min-height: auto !important;
  }

  .min-h-xxl-1px {
    min-height: 1px !important;
  }

  .min-h-xxl-2px {
    min-height: 2px !important;
  }

  .min-h-xxl-3px {
    min-height: 3px !important;
  }

  .min-h-xxl-4px {
    min-height: 4px !important;
  }

  .min-h-xxl-5px {
    min-height: 5px !important;
  }

  .min-h-xxl-6px {
    min-height: 6px !important;
  }

  .min-h-xxl-7px {
    min-height: 7px !important;
  }

  .min-h-xxl-8px {
    min-height: 8px !important;
  }

  .min-h-xxl-9px {
    min-height: 9px !important;
  }

  .min-h-xxl-10px {
    min-height: 10px !important;
  }

  .min-h-xxl-15px {
    min-height: 15px !important;
  }

  .min-h-xxl-20px {
    min-height: 20px !important;
  }

  .min-h-xxl-25px {
    min-height: 25px !important;
  }

  .min-h-xxl-30px {
    min-height: 30px !important;
  }

  .min-h-xxl-35px {
    min-height: 35px !important;
  }

  .min-h-xxl-40px {
    min-height: 40px !important;
  }

  .min-h-xxl-45px {
    min-height: 45px !important;
  }

  .min-h-xxl-50px {
    min-height: 50px !important;
  }

  .min-h-xxl-55px {
    min-height: 55px !important;
  }

  .min-h-xxl-60px {
    min-height: 60px !important;
  }

  .min-h-xxl-65px {
    min-height: 65px !important;
  }

  .min-h-xxl-70px {
    min-height: 70px !important;
  }

  .min-h-xxl-75px {
    min-height: 75px !important;
  }

  .min-h-xxl-80px {
    min-height: 80px !important;
  }

  .min-h-xxl-85px {
    min-height: 85px !important;
  }

  .min-h-xxl-90px {
    min-height: 90px !important;
  }

  .min-h-xxl-95px {
    min-height: 95px !important;
  }

  .min-h-xxl-100px {
    min-height: 100px !important;
  }

  .min-h-xxl-125px {
    min-height: 125px !important;
  }

  .min-h-xxl-150px {
    min-height: 150px !important;
  }

  .min-h-xxl-175px {
    min-height: 175px !important;
  }

  .min-h-xxl-200px {
    min-height: 200px !important;
  }

  .min-h-xxl-225px {
    min-height: 225px !important;
  }

  .min-h-xxl-250px {
    min-height: 250px !important;
  }

  .min-h-xxl-275px {
    min-height: 275px !important;
  }

  .min-h-xxl-300px {
    min-height: 300px !important;
  }

  .min-h-xxl-325px {
    min-height: 325px !important;
  }

  .min-h-xxl-350px {
    min-height: 350px !important;
  }

  .min-h-xxl-375px {
    min-height: 375px !important;
  }

  .min-h-xxl-400px {
    min-height: 400px !important;
  }

  .min-h-xxl-425px {
    min-height: 425px !important;
  }

  .min-h-xxl-450px {
    min-height: 450px !important;
  }

  .min-h-xxl-475px {
    min-height: 475px !important;
  }

  .min-h-xxl-500px {
    min-height: 500px !important;
  }

  .min-h-xxl-550px {
    min-height: 550px !important;
  }

  .min-h-xxl-600px {
    min-height: 600px !important;
  }

  .min-h-xxl-650px {
    min-height: 650px !important;
  }

  .min-h-xxl-700px {
    min-height: 700px !important;
  }

  .min-h-xxl-750px {
    min-height: 750px !important;
  }

  .min-h-xxl-800px {
    min-height: 800px !important;
  }

  .min-h-xxl-850px {
    min-height: 850px !important;
  }

  .min-h-xxl-900px {
    min-height: 900px !important;
  }

  .min-h-xxl-950px {
    min-height: 950px !important;
  }

  .min-h-xxl-1000px {
    min-height: 1000px !important;
  }
}
@media (min-width: 1200px) {
  .fs-1 {
    font-size: 1.75rem !important;
  }

  .fs-2 {
    font-size: 1.5rem !important;
  }

  .fs-3 {
    font-size: 1.35rem !important;
  }

  .fs-2x {
    font-size: 2rem !important;
  }

  .fs-2qx {
    font-size: 2.25rem !important;
  }

  .fs-2hx {
    font-size: 2.5rem !important;
  }

  .fs-2tx {
    font-size: 2.75rem !important;
  }

  .fs-3x {
    font-size: 3rem !important;
  }

  .fs-3qx {
    font-size: 3.25rem !important;
  }

  .fs-3hx {
    font-size: 3.5rem !important;
  }

  .fs-3tx {
    font-size: 3.75rem !important;
  }

  .fs-4x {
    font-size: 4rem !important;
  }

  .fs-4qx {
    font-size: 4.25rem !important;
  }

  .fs-4hx {
    font-size: 4.5rem !important;
  }

  .fs-4tx {
    font-size: 4.75rem !important;
  }

  .fs-5x {
    font-size: 5rem !important;
  }

  .fs-5qx {
    font-size: 5.25rem !important;
  }

  .fs-5hx {
    font-size: 5.5rem !important;
  }

  .fs-5tx {
    font-size: 5.75rem !important;
  }

  .fs-sm-1 {
    font-size: 1.75rem !important;
  }

  .fs-sm-2 {
    font-size: 1.5rem !important;
  }

  .fs-sm-3 {
    font-size: 1.35rem !important;
  }

  .fs-sm-2x {
    font-size: 2rem !important;
  }

  .fs-sm-2qx {
    font-size: 2.25rem !important;
  }

  .fs-sm-2hx {
    font-size: 2.5rem !important;
  }

  .fs-sm-2tx {
    font-size: 2.75rem !important;
  }

  .fs-sm-3x {
    font-size: 3rem !important;
  }

  .fs-sm-3qx {
    font-size: 3.25rem !important;
  }

  .fs-sm-3hx {
    font-size: 3.5rem !important;
  }

  .fs-sm-3tx {
    font-size: 3.75rem !important;
  }

  .fs-sm-4x {
    font-size: 4rem !important;
  }

  .fs-sm-4qx {
    font-size: 4.25rem !important;
  }

  .fs-sm-4hx {
    font-size: 4.5rem !important;
  }

  .fs-sm-4tx {
    font-size: 4.75rem !important;
  }

  .fs-sm-5x {
    font-size: 5rem !important;
  }

  .fs-sm-5qx {
    font-size: 5.25rem !important;
  }

  .fs-sm-5hx {
    font-size: 5.5rem !important;
  }

  .fs-sm-5tx {
    font-size: 5.75rem !important;
  }

  .fs-md-1 {
    font-size: 1.75rem !important;
  }

  .fs-md-2 {
    font-size: 1.5rem !important;
  }

  .fs-md-3 {
    font-size: 1.35rem !important;
  }

  .fs-md-2x {
    font-size: 2rem !important;
  }

  .fs-md-2qx {
    font-size: 2.25rem !important;
  }

  .fs-md-2hx {
    font-size: 2.5rem !important;
  }

  .fs-md-2tx {
    font-size: 2.75rem !important;
  }

  .fs-md-3x {
    font-size: 3rem !important;
  }

  .fs-md-3qx {
    font-size: 3.25rem !important;
  }

  .fs-md-3hx {
    font-size: 3.5rem !important;
  }

  .fs-md-3tx {
    font-size: 3.75rem !important;
  }

  .fs-md-4x {
    font-size: 4rem !important;
  }

  .fs-md-4qx {
    font-size: 4.25rem !important;
  }

  .fs-md-4hx {
    font-size: 4.5rem !important;
  }

  .fs-md-4tx {
    font-size: 4.75rem !important;
  }

  .fs-md-5x {
    font-size: 5rem !important;
  }

  .fs-md-5qx {
    font-size: 5.25rem !important;
  }

  .fs-md-5hx {
    font-size: 5.5rem !important;
  }

  .fs-md-5tx {
    font-size: 5.75rem !important;
  }

  .fs-lg-1 {
    font-size: 1.75rem !important;
  }

  .fs-lg-2 {
    font-size: 1.5rem !important;
  }

  .fs-lg-3 {
    font-size: 1.35rem !important;
  }

  .fs-lg-2x {
    font-size: 2rem !important;
  }

  .fs-lg-2qx {
    font-size: 2.25rem !important;
  }

  .fs-lg-2hx {
    font-size: 2.5rem !important;
  }

  .fs-lg-2tx {
    font-size: 2.75rem !important;
  }

  .fs-lg-3x {
    font-size: 3rem !important;
  }

  .fs-lg-3qx {
    font-size: 3.25rem !important;
  }

  .fs-lg-3hx {
    font-size: 3.5rem !important;
  }

  .fs-lg-3tx {
    font-size: 3.75rem !important;
  }

  .fs-lg-4x {
    font-size: 4rem !important;
  }

  .fs-lg-4qx {
    font-size: 4.25rem !important;
  }

  .fs-lg-4hx {
    font-size: 4.5rem !important;
  }

  .fs-lg-4tx {
    font-size: 4.75rem !important;
  }

  .fs-lg-5x {
    font-size: 5rem !important;
  }

  .fs-lg-5qx {
    font-size: 5.25rem !important;
  }

  .fs-lg-5hx {
    font-size: 5.5rem !important;
  }

  .fs-lg-5tx {
    font-size: 5.75rem !important;
  }
}
@media print {
  .d-print-inline {
    display: inline !important;
  }

  .d-print-inline-block {
    display: inline-block !important;
  }

  .d-print-block {
    display: block !important;
  }

  .d-print-grid {
    display: grid !important;
  }

  .d-print-table {
    display: table !important;
  }

  .d-print-table-row {
    display: table-row !important;
  }

  .d-print-table-cell {
    display: table-cell !important;
  }

  .d-print-flex {
    display: flex !important;
  }

  .d-print-inline-flex {
    display: inline-flex !important;
  }

  .d-print-none {
    display: none !important;
  }
}
:root {
  --bs-light-primary:#ECF8FF;
  --bs-active-primary:#0095E8;
  --bs-light-success:#E8FFF3;
  --bs-active-success:#47BE7D;
  --bs-light-info:#F8F5FF;
  --bs-active-info:#5014D0;
  --bs-light-warning:#FFF8DD;
  --bs-active-warning:#F1BC00;
  --bs-light-danger:#FFF5F8;
  --bs-active-danger:#D9214E;
  --bs-light-dark:#EFF2F5;
  --bs-active-dark:#131628;
  --bs-gray-100:#F5F8FA;
  --bs-gray-200:#EFF2F5;
  --bs-gray-300:#E4E6EF;
  --bs-gray-400:#B5B5C3;
  --bs-gray-500:#A1A5B7;
  --bs-gray-600:#7E8299;
  --bs-gray-700:#5E6278;
  --bs-gray-800:#3F4254;
  --bs-gray-900:#181C32;
  --bs-xs:0;
  --bs-sm:576px;
  --bs-md:768px;
  --bs-lg:992px;
  --bs-xl:1200px;
  --bs-xxl:1400px;
}

.bg-white {
  --bg-color: 255, 255, 255;
}
.bg-white.hoverable:hover {
  background-color: #F5F8FA !important;
}

.bg-hover-white {
  cursor: pointer;
}
.bg-hover-white:hover {
  --bg-color: 255, 255, 255;
  background-color: #ffffff !important;
}

.bg-active-white.active {
  --bg-color: 255, 255, 255;
  background-color: #ffffff !important;
}

.bg-state-white {
  cursor: pointer;
}
.bg-state-white.active, .bg-state-white:hover {
  --bg-color: 255, 255, 255;
  background-color: #ffffff !important;
}

.bg-light {
  --bg-color: 245, 248, 250;
}
.bg-light.hoverable:hover {
  background-color: #E4E6EF !important;
}

.bg-hover-light {
  cursor: pointer;
}
.bg-hover-light:hover {
  --bg-color: 245, 248, 250;
  background-color: #F5F8FA !important;
}

.bg-active-light.active {
  --bg-color: 245, 248, 250;
  background-color: #F5F8FA !important;
}

.bg-state-light {
  cursor: pointer;
}
.bg-state-light.active, .bg-state-light:hover {
  --bg-color: 245, 248, 250;
  background-color: #F5F8FA !important;
}

.bg-light-primary {
  background-color: #ECF8FF !important;
}
.bg-light-primary.hoverable:hover {
  background-color: #cdedff !important;
}

.bg-primary {
  --bg-color: 0, 158, 247;
}
.bg-primary.hoverable:hover {
  background-color: #0095E8 !important;
}

.bg-hover-light-primary {
  cursor: pointer;
}
.bg-hover-light-primary:hover {
  background-color: #ECF8FF !important;
}

.bg-state-light-primary {
  cursor: pointer;
}
.bg-state-light-primary.active, .bg-state-light-primary:hover {
  background-color: #ECF8FF !important;
}

.bg-hover-primary {
  cursor: pointer;
}
.bg-hover-primary:hover {
  --bg-color: 0, 158, 247;
  background-color: #009EF7 !important;
}

.bg-active-primary.active {
  --bg-color: 0, 158, 247;
  background-color: #009EF7 !important;
}

.bg-state-primary {
  cursor: pointer;
}
.bg-state-primary.active, .bg-state-primary:hover {
  --bg-color: 0, 158, 247;
  background-color: #009EF7 !important;
}

.bg-secondary {
  --bg-color: 228, 230, 239;
}
.bg-secondary.hoverable:hover {
  background-color: #B5B5C3 !important;
}

.bg-hover-secondary {
  cursor: pointer;
}
.bg-hover-secondary:hover {
  --bg-color: 228, 230, 239;
  background-color: #E4E6EF !important;
}

.bg-active-secondary.active {
  --bg-color: 228, 230, 239;
  background-color: #E4E6EF !important;
}

.bg-state-secondary {
  cursor: pointer;
}
.bg-state-secondary.active, .bg-state-secondary:hover {
  --bg-color: 228, 230, 239;
  background-color: #E4E6EF !important;
}

.bg-light-success {
  background-color: #E8FFF3 !important;
}
.bg-light-success.hoverable:hover {
  background-color: #c9ffe3 !important;
}

.bg-success {
  --bg-color: 80, 205, 137;
}
.bg-success.hoverable:hover {
  background-color: #47BE7D !important;
}

.bg-hover-light-success {
  cursor: pointer;
}
.bg-hover-light-success:hover {
  background-color: #E8FFF3 !important;
}

.bg-state-light-success {
  cursor: pointer;
}
.bg-state-light-success.active, .bg-state-light-success:hover {
  background-color: #E8FFF3 !important;
}

.bg-hover-success {
  cursor: pointer;
}
.bg-hover-success:hover {
  --bg-color: 80, 205, 137;
  background-color: #50CD89 !important;
}

.bg-active-success.active {
  --bg-color: 80, 205, 137;
  background-color: #50CD89 !important;
}

.bg-state-success {
  cursor: pointer;
}
.bg-state-success.active, .bg-state-success:hover {
  --bg-color: 80, 205, 137;
  background-color: #50CD89 !important;
}

.bg-light-info {
  background-color: #F8F5FF !important;
}
.bg-light-info.hoverable:hover {
  background-color: #e3d6ff !important;
}

.bg-info {
  --bg-color: 114, 57, 234;
}
.bg-info.hoverable:hover {
  background-color: #5014D0 !important;
}

.bg-hover-light-info {
  cursor: pointer;
}
.bg-hover-light-info:hover {
  background-color: #F8F5FF !important;
}

.bg-state-light-info {
  cursor: pointer;
}
.bg-state-light-info.active, .bg-state-light-info:hover {
  background-color: #F8F5FF !important;
}

.bg-hover-info {
  cursor: pointer;
}
.bg-hover-info:hover {
  --bg-color: 114, 57, 234;
  background-color: #7239EA !important;
}

.bg-active-info.active {
  --bg-color: 114, 57, 234;
  background-color: #7239EA !important;
}

.bg-state-info {
  cursor: pointer;
}
.bg-state-info.active, .bg-state-info:hover {
  --bg-color: 114, 57, 234;
  background-color: #7239EA !important;
}

.bg-light-warning {
  background-color: #FFF8DD !important;
}
.bg-light-warning.hoverable:hover {
  background-color: #fff2be !important;
}

.bg-warning {
  --bg-color: 255, 199, 0;
}
.bg-warning.hoverable:hover {
  background-color: #F1BC00 !important;
}

.bg-hover-light-warning {
  cursor: pointer;
}
.bg-hover-light-warning:hover {
  background-color: #FFF8DD !important;
}

.bg-state-light-warning {
  cursor: pointer;
}
.bg-state-light-warning.active, .bg-state-light-warning:hover {
  background-color: #FFF8DD !important;
}

.bg-hover-warning {
  cursor: pointer;
}
.bg-hover-warning:hover {
  --bg-color: 255, 199, 0;
  background-color: #FFC700 !important;
}

.bg-active-warning.active {
  --bg-color: 255, 199, 0;
  background-color: #FFC700 !important;
}

.bg-state-warning {
  cursor: pointer;
}
.bg-state-warning.active, .bg-state-warning:hover {
  --bg-color: 255, 199, 0;
  background-color: #FFC700 !important;
}

.bg-light-danger {
  background-color: #FFF5F8 !important;
}
.bg-light-danger.hoverable:hover {
  background-color: #ffd6e3 !important;
}

.bg-danger {
  --bg-color: 241, 65, 108;
}
.bg-danger.hoverable:hover {
  background-color: #D9214E !important;
}

.bg-hover-light-danger {
  cursor: pointer;
}
.bg-hover-light-danger:hover {
  background-color: #FFF5F8 !important;
}

.bg-state-light-danger {
  cursor: pointer;
}
.bg-state-light-danger.active, .bg-state-light-danger:hover {
  background-color: #FFF5F8 !important;
}

.bg-hover-danger {
  cursor: pointer;
}
.bg-hover-danger:hover {
  --bg-color: 241, 65, 108;
  background-color: #F1416C !important;
}

.bg-active-danger.active {
  --bg-color: 241, 65, 108;
  background-color: #F1416C !important;
}

.bg-state-danger {
  cursor: pointer;
}
.bg-state-danger.active, .bg-state-danger:hover {
  --bg-color: 241, 65, 108;
  background-color: #F1416C !important;
}

.bg-light-dark {
  background-color: #EFF2F5 !important;
}
.bg-light-dark.hoverable:hover {
  background-color: #dce3e9 !important;
}

.bg-dark {
  --bg-color: 24, 28, 50;
}
.bg-dark.hoverable:hover {
  background-color: #131628 !important;
}

.bg-hover-light-dark {
  cursor: pointer;
}
.bg-hover-light-dark:hover {
  background-color: #EFF2F5 !important;
}

.bg-state-light-dark {
  cursor: pointer;
}
.bg-state-light-dark.active, .bg-state-light-dark:hover {
  background-color: #EFF2F5 !important;
}

.bg-hover-dark {
  cursor: pointer;
}
.bg-hover-dark:hover {
  --bg-color: 24, 28, 50;
  background-color: #181C32 !important;
}

.bg-active-dark.active {
  --bg-color: 24, 28, 50;
  background-color: #181C32 !important;
}

.bg-state-dark {
  cursor: pointer;
}
.bg-state-dark.active, .bg-state-dark:hover {
  --bg-color: 24, 28, 50;
  background-color: #181C32 !important;
}

.bg-opacity-0 {
  background-color: rgba(var(--bg-color), 0) !important;
}

.bg-hover-opacity-0:hover {
  background-color: rgba(var(--bg-color), 0) !important;
}

.bg-active-opacity-0.active {
  background-color: rgba(var(--bg-color), 0) !important;
}

.bg-state-opacity-0 .active, .bg-state-opacity-0:hover {
  background-color: rgba(var(--bg-color), 0) !important;
}

.bg-opacity-5 {
  background-color: rgba(var(--bg-color), 0.05) !important;
}

.bg-hover-opacity-5:hover {
  background-color: rgba(var(--bg-color), 0.05) !important;
}

.bg-active-opacity-5.active {
  background-color: rgba(var(--bg-color), 0.05) !important;
}

.bg-state-opacity-5 .active, .bg-state-opacity-5:hover {
  background-color: rgba(var(--bg-color), 0.05) !important;
}

.bg-opacity-10 {
  background-color: rgba(var(--bg-color), 0.1) !important;
}

.bg-hover-opacity-10:hover {
  background-color: rgba(var(--bg-color), 0.1) !important;
}

.bg-active-opacity-10.active {
  background-color: rgba(var(--bg-color), 0.1) !important;
}

.bg-state-opacity-10 .active, .bg-state-opacity-10:hover {
  background-color: rgba(var(--bg-color), 0.1) !important;
}

.bg-opacity-15 {
  background-color: rgba(var(--bg-color), 0.15) !important;
}

.bg-hover-opacity-15:hover {
  background-color: rgba(var(--bg-color), 0.15) !important;
}

.bg-active-opacity-15.active {
  background-color: rgba(var(--bg-color), 0.15) !important;
}

.bg-state-opacity-15 .active, .bg-state-opacity-15:hover {
  background-color: rgba(var(--bg-color), 0.15) !important;
}

.bg-opacity-20 {
  background-color: rgba(var(--bg-color), 0.2) !important;
}

.bg-hover-opacity-20:hover {
  background-color: rgba(var(--bg-color), 0.2) !important;
}

.bg-active-opacity-20.active {
  background-color: rgba(var(--bg-color), 0.2) !important;
}

.bg-state-opacity-20 .active, .bg-state-opacity-20:hover {
  background-color: rgba(var(--bg-color), 0.2) !important;
}

.bg-opacity-25 {
  background-color: rgba(var(--bg-color), 0.25) !important;
}

.bg-hover-opacity-25:hover {
  background-color: rgba(var(--bg-color), 0.25) !important;
}

.bg-active-opacity-25.active {
  background-color: rgba(var(--bg-color), 0.25) !important;
}

.bg-state-opacity-25 .active, .bg-state-opacity-25:hover {
  background-color: rgba(var(--bg-color), 0.25) !important;
}

.bg-opacity-50 {
  background-color: rgba(var(--bg-color), 0.5) !important;
}

.bg-hover-opacity-50:hover {
  background-color: rgba(var(--bg-color), 0.5) !important;
}

.bg-active-opacity-50.active {
  background-color: rgba(var(--bg-color), 0.5) !important;
}

.bg-state-opacity-50 .active, .bg-state-opacity-50:hover {
  background-color: rgba(var(--bg-color), 0.5) !important;
}

.bg-opacity-75 {
  background-color: rgba(var(--bg-color), 0.75) !important;
}

.bg-hover-opacity-75:hover {
  background-color: rgba(var(--bg-color), 0.75) !important;
}

.bg-active-opacity-75.active {
  background-color: rgba(var(--bg-color), 0.75) !important;
}

.bg-state-opacity-75 .active, .bg-state-opacity-75:hover {
  background-color: rgba(var(--bg-color), 0.75) !important;
}

.bg-opacity-100 {
  background-color: rgba(var(--bg-color), 1) !important;
}

.bg-hover-opacity-100:hover {
  background-color: rgba(var(--bg-color), 1) !important;
}

.bg-active-opacity-100.active {
  background-color: rgba(var(--bg-color), 1) !important;
}

.bg-state-opacity-100 .active, .bg-state-opacity-100:hover {
  background-color: rgba(var(--bg-color), 1) !important;
}

.bg-lighten {
  background-color: rgba(245, 248, 250, 0.5) !important;
}

.bg-hover-lighten {
  cursor: pointer;
}
.bg-hover-lighten:hover {
  background-color: rgba(245, 248, 250, 0.5) !important;
}

.bg-active-lighten.active {
  background-color: rgba(245, 248, 250, 0.5) !important;
}

.bg-state-lighten {
  cursor: pointer;
}
.bg-state-lighten.active, .bg-state-lighten:hover {
  background-color: rgba(245, 248, 250, 0.5) !important;
}

.hoverable {
  cursor: pointer;
  transition: all 0.2s ease;
}
.hoverable:hover {
  transition: all 0.2s ease;
}

.bgi-no-repeat {
  background-repeat: no-repeat;
}

.bgi-position-y-top {
  background-position-y: top;
}

.bgi-position-y-bottom {
  background-position-y: bottom;
}

.bgi-position-y-center {
  background-position-y: center;
}

.bgi-position-x-start {
  background-position-x: left;
}

.bgi-position-x-end {
  background-position-x: right;
}

.bgi-position-x-center {
  background-position-x: center;
}

.bgi-position-top {
  background-position: 0 top;
}

.bgi-position-bottom {
  background-position: 0 bottom;
}

.bgi-position-center {
  background-position: center;
}

.bgi-size-auto {
  background-size: auto;
}

.bgi-size-cover {
  background-size: cover;
}

.bgi-size-contain {
  background-size: contain;
}

.bgi-attachment-fixed {
  background-attachment: fixed;
}

.bgi-attachment-auto {
  background-attachment: auto;
}

@media (min-width: 576px) {
  .bgi-size-sm-auto {
    background-size: auto;
  }

  .bgi-size-sm-cover {
    background-size: cover;
  }

  .bgi-size-sm-contain {
    background-size: contain;
  }

  .bgi-attachment-sm-fixed {
    background-attachment: fixed;
  }

  .bgi-attachment-sm-auto {
    background-attachment: auto;
  }
}
@media (min-width: 768px) {
  .bgi-size-md-auto {
    background-size: auto;
  }

  .bgi-size-md-cover {
    background-size: cover;
  }

  .bgi-size-md-contain {
    background-size: contain;
  }

  .bgi-attachment-md-fixed {
    background-attachment: fixed;
  }

  .bgi-attachment-md-auto {
    background-attachment: auto;
  }
}
@media (min-width: 992px) {
  .bgi-size-lg-auto {
    background-size: auto;
  }

  .bgi-size-lg-cover {
    background-size: cover;
  }

  .bgi-size-lg-contain {
    background-size: contain;
  }

  .bgi-attachment-lg-fixed {
    background-attachment: fixed;
  }

  .bgi-attachment-lg-auto {
    background-attachment: auto;
  }
}
@media (min-width: 1200px) {
  .bgi-size-xl-auto {
    background-size: auto;
  }

  .bgi-size-xl-cover {
    background-size: cover;
  }

  .bgi-size-xl-contain {
    background-size: contain;
  }

  .bgi-attachment-xl-fixed {
    background-attachment: fixed;
  }

  .bgi-attachment-xl-auto {
    background-attachment: auto;
  }
}
@media (min-width: 1400px) {
  .bgi-size-xxl-auto {
    background-size: auto;
  }

  .bgi-size-xxl-cover {
    background-size: cover;
  }

  .bgi-size-xxl-contain {
    background-size: contain;
  }

  .bgi-attachment-xxl-fixed {
    background-attachment: fixed;
  }

  .bgi-attachment-xxl-auto {
    background-attachment: auto;
  }
}
.border-active:not(.active):not(:active):not(:hover):not(:focus) {
  border-color: transparent !important;
}

.border-hover:not(:hover):not(:focus):not(.active):not(:active) {
  cursor: pointer;
  border-color: transparent !important;
}

.border-gray-100 {
  border-color: #F5F8FA !important;
}

.border-gray-200 {
  border-color: #EFF2F5 !important;
}

.border-gray-300 {
  border-color: #E4E6EF !important;
}

.border-gray-400 {
  border-color: #B5B5C3 !important;
}

.border-gray-500 {
  border-color: #A1A5B7 !important;
}

.border-gray-600 {
  border-color: #7E8299 !important;
}

.border-gray-700 {
  border-color: #5E6278 !important;
}

.border-gray-800 {
  border-color: #3F4254 !important;
}

.border-gray-900 {
  border-color: #181C32 !important;
}

.border-dashed {
  border-style: dashed !important;
  border-color: #E4E6EF;
}

.border-top-dashed {
  border-top-style: dashed !important;
}

.border-bottom-dashed {
  border-bottom-style: dashed !important;
}

.border-start-dashed {
  border-left-style: dashed !important;
}

.border-end-dashed {
  border-right-style: dashed !important;
}

.border-dotted {
  border-style: dotted !important;
}

.border-top-dotted {
  border-top-style: dotted !important;
}

.border-bottom-dotted {
  border-bottom-style: dotted !important;
}

.border-start-dotted {
  border-left-style: dotted !important;
}

.border-end-dotted {
  border-right-style: dotted !important;
}

.rounded-top-0 {
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
}

.rounded-bottom-0 {
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.border-transparent {
  border-color: transparent !important;
}

.flex-root {
  flex: 1;
}

.flex-column-auto {
  flex: none;
}

.flex-column-fluid {
  flex: 1 0 auto;
}

.flex-row-auto {
  flex: 0 0 auto;
}

.flex-row-fluid {
  flex: 1 auto;
  min-width: 0;
}

.flex-center {
  justify-content: center;
  align-items: center;
}

.flex-stack {
  justify-content: space-between;
  align-items: center;
}

@media (min-width: 576px) {
  .flex-sm-root {
    flex: 1;
  }

  .flex-sm-column-auto {
    flex: none;
  }

  .flex-sm-column-fluid {
    flex: 1 0 auto;
  }

  .flex-sm-row-auto {
    flex: 0 0 auto;
  }

  .flex-sm-row-fluid {
    flex: 1 auto;
    min-width: 0;
  }

  .flex-sm-center {
    justify-content: center;
    align-items: center;
  }

  .flex-sm-stack {
    justify-content: space-between;
    align-items: center;
  }
}
@media (min-width: 768px) {
  .flex-md-root {
    flex: 1;
  }

  .flex-md-column-auto {
    flex: none;
  }

  .flex-md-column-fluid {
    flex: 1 0 auto;
  }

  .flex-md-row-auto {
    flex: 0 0 auto;
  }

  .flex-md-row-fluid {
    flex: 1 auto;
    min-width: 0;
  }

  .flex-md-center {
    justify-content: center;
    align-items: center;
  }

  .flex-md-stack {
    justify-content: space-between;
    align-items: center;
  }
}
@media (min-width: 992px) {
  .flex-lg-root {
    flex: 1;
  }

  .flex-lg-column-auto {
    flex: none;
  }

  .flex-lg-column-fluid {
    flex: 1 0 auto;
  }

  .flex-lg-row-auto {
    flex: 0 0 auto;
  }

  .flex-lg-row-fluid {
    flex: 1 auto;
    min-width: 0;
  }

  .flex-lg-center {
    justify-content: center;
    align-items: center;
  }

  .flex-lg-stack {
    justify-content: space-between;
    align-items: center;
  }
}
@media (min-width: 1200px) {
  .flex-xl-root {
    flex: 1;
  }

  .flex-xl-column-auto {
    flex: none;
  }

  .flex-xl-column-fluid {
    flex: 1 0 auto;
  }

  .flex-xl-row-auto {
    flex: 0 0 auto;
  }

  .flex-xl-row-fluid {
    flex: 1 auto;
    min-width: 0;
  }

  .flex-xl-center {
    justify-content: center;
    align-items: center;
  }

  .flex-xl-stack {
    justify-content: space-between;
    align-items: center;
  }
}
@media (min-width: 1400px) {
  .flex-xxl-root {
    flex: 1;
  }

  .flex-xxl-column-auto {
    flex: none;
  }

  .flex-xxl-column-fluid {
    flex: 1 0 auto;
  }

  .flex-xxl-row-auto {
    flex: 0 0 auto;
  }

  .flex-xxl-row-fluid {
    flex: 1 auto;
    min-width: 0;
  }

  .flex-xxl-center {
    justify-content: center;
    align-items: center;
  }

  .flex-xxl-stack {
    justify-content: space-between;
    align-items: center;
  }
}
.shadow-xs {
  box-shadow: 0px 0px 30px rgba(25, 53, 80, 0.055);
}

.text-white {
  color: #ffffff !important;
}

.text-inverse-white {
  color: #7E8299 !important;
}

.text-hover-white {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-hover-white i {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-hover-white:hover {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: #ffffff !important;
}
.text-hover-white:hover i {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: #ffffff !important;
}
.text-hover-white:hover .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #ffffff;
}
.text-hover-white:hover .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}

.text-hover-inverse-white {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-hover-inverse-white i {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-hover-inverse-white:hover {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: #7E8299 !important;
}
.text-hover-inverse-white:hover i {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: #7E8299 !important;
}
.text-hover-inverse-white:hover .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #7E8299;
}
.text-hover-inverse-white:hover .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}

.text-active-white {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-active-white i {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-active-white.active {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: #ffffff !important;
}
.text-active-white.active i {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: #ffffff !important;
}
.text-active-white.active .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #ffffff;
}
.text-active-white.active .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}

.text-active-inverse-white {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-active-inverse-white i {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-active-inverse-white.active {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: #7E8299 !important;
}
.text-active-inverse-white.active i {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: #7E8299 !important;
}
.text-active-inverse-white.active .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #7E8299;
}
.text-active-inverse-white.active .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}

.text-primary {
  color: #009EF7 !important;
}

.text-inverse-primary {
  color: #FFFFFF !important;
}

.text-light-primary {
  color: #ECF8FF !important;
}

.text-hover-primary {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-hover-primary i {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-hover-primary:hover {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: #009EF7 !important;
}
.text-hover-primary:hover i {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: #009EF7 !important;
}
.text-hover-primary:hover .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #009EF7;
}
.text-hover-primary:hover .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}

.text-hover-inverse-primary {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-hover-inverse-primary i {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-hover-inverse-primary:hover {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: #FFFFFF !important;
}
.text-hover-inverse-primary:hover i {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: #FFFFFF !important;
}
.text-hover-inverse-primary:hover .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #FFFFFF;
}
.text-hover-inverse-primary:hover .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}

.text-active-primary {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-active-primary i {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-active-primary.active {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: #009EF7 !important;
}
.text-active-primary.active i {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: #009EF7 !important;
}
.text-active-primary.active .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #009EF7;
}
.text-active-primary.active .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}

.text-active-inverse-primary {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-active-inverse-primary i {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-active-inverse-primary.active {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: #FFFFFF !important;
}
.text-active-inverse-primary.active i {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: #FFFFFF !important;
}
.text-active-inverse-primary.active .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #FFFFFF;
}
.text-active-inverse-primary.active .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}

.text-secondary {
  color: #E4E6EF !important;
}

.text-inverse-secondary {
  color: #3F4254 !important;
}

.text-hover-secondary {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-hover-secondary i {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-hover-secondary:hover {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: #E4E6EF !important;
}
.text-hover-secondary:hover i {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: #E4E6EF !important;
}
.text-hover-secondary:hover .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #E4E6EF;
}
.text-hover-secondary:hover .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}

.text-hover-inverse-secondary {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-hover-inverse-secondary i {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-hover-inverse-secondary:hover {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: #3F4254 !important;
}
.text-hover-inverse-secondary:hover i {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: #3F4254 !important;
}
.text-hover-inverse-secondary:hover .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #3F4254;
}
.text-hover-inverse-secondary:hover .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}

.text-active-secondary {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-active-secondary i {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-active-secondary.active {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: #E4E6EF !important;
}
.text-active-secondary.active i {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: #E4E6EF !important;
}
.text-active-secondary.active .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #E4E6EF;
}
.text-active-secondary.active .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}

.text-active-inverse-secondary {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-active-inverse-secondary i {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-active-inverse-secondary.active {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: #3F4254 !important;
}
.text-active-inverse-secondary.active i {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: #3F4254 !important;
}
.text-active-inverse-secondary.active .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #3F4254;
}
.text-active-inverse-secondary.active .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}

.text-light {
  color: #F5F8FA !important;
}

.text-inverse-light {
  color: #7E8299 !important;
}

.text-hover-light {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-hover-light i {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-hover-light:hover {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: #F5F8FA !important;
}
.text-hover-light:hover i {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: #F5F8FA !important;
}
.text-hover-light:hover .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #F5F8FA;
}
.text-hover-light:hover .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}

.text-hover-inverse-light {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-hover-inverse-light i {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-hover-inverse-light:hover {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: #7E8299 !important;
}
.text-hover-inverse-light:hover i {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: #7E8299 !important;
}
.text-hover-inverse-light:hover .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #7E8299;
}
.text-hover-inverse-light:hover .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}

.text-active-light {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-active-light i {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-active-light.active {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: #F5F8FA !important;
}
.text-active-light.active i {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: #F5F8FA !important;
}
.text-active-light.active .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #F5F8FA;
}
.text-active-light.active .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}

.text-active-inverse-light {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-active-inverse-light i {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-active-inverse-light.active {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: #7E8299 !important;
}
.text-active-inverse-light.active i {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: #7E8299 !important;
}
.text-active-inverse-light.active .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #7E8299;
}
.text-active-inverse-light.active .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}

.text-success {
  color: #50CD89 !important;
}

.text-inverse-success {
  color: #FFFFFF !important;
}

.text-light-success {
  color: #E8FFF3 !important;
}

.text-hover-success {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-hover-success i {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-hover-success:hover {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: #50CD89 !important;
}
.text-hover-success:hover i {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: #50CD89 !important;
}
.text-hover-success:hover .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #50CD89;
}
.text-hover-success:hover .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}

.text-hover-inverse-success {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-hover-inverse-success i {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-hover-inverse-success:hover {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: #FFFFFF !important;
}
.text-hover-inverse-success:hover i {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: #FFFFFF !important;
}
.text-hover-inverse-success:hover .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #FFFFFF;
}
.text-hover-inverse-success:hover .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}

.text-active-success {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-active-success i {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-active-success.active {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: #50CD89 !important;
}
.text-active-success.active i {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: #50CD89 !important;
}
.text-active-success.active .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #50CD89;
}
.text-active-success.active .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}

.text-active-inverse-success {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-active-inverse-success i {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-active-inverse-success.active {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: #FFFFFF !important;
}
.text-active-inverse-success.active i {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: #FFFFFF !important;
}
.text-active-inverse-success.active .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #FFFFFF;
}
.text-active-inverse-success.active .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}

.text-info {
  color: #7239EA !important;
}

.text-inverse-info {
  color: #FFFFFF !important;
}

.text-light-info {
  color: #F8F5FF !important;
}

.text-hover-info {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-hover-info i {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-hover-info:hover {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: #7239EA !important;
}
.text-hover-info:hover i {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: #7239EA !important;
}
.text-hover-info:hover .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #7239EA;
}
.text-hover-info:hover .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}

.text-hover-inverse-info {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-hover-inverse-info i {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-hover-inverse-info:hover {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: #FFFFFF !important;
}
.text-hover-inverse-info:hover i {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: #FFFFFF !important;
}
.text-hover-inverse-info:hover .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #FFFFFF;
}
.text-hover-inverse-info:hover .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}

.text-active-info {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-active-info i {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-active-info.active {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: #7239EA !important;
}
.text-active-info.active i {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: #7239EA !important;
}
.text-active-info.active .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #7239EA;
}
.text-active-info.active .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}

.text-active-inverse-info {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-active-inverse-info i {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-active-inverse-info.active {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: #FFFFFF !important;
}
.text-active-inverse-info.active i {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: #FFFFFF !important;
}
.text-active-inverse-info.active .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #FFFFFF;
}
.text-active-inverse-info.active .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}

.text-warning {
  color: #FFC700 !important;
}

.text-inverse-warning {
  color: #FFFFFF !important;
}

.text-light-warning {
  color: #FFF8DD !important;
}

.text-hover-warning {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-hover-warning i {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-hover-warning:hover {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: #FFC700 !important;
}
.text-hover-warning:hover i {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: #FFC700 !important;
}
.text-hover-warning:hover .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #FFC700;
}
.text-hover-warning:hover .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}

.text-hover-inverse-warning {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-hover-inverse-warning i {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-hover-inverse-warning:hover {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: #FFFFFF !important;
}
.text-hover-inverse-warning:hover i {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: #FFFFFF !important;
}
.text-hover-inverse-warning:hover .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #FFFFFF;
}
.text-hover-inverse-warning:hover .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}

.text-active-warning {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-active-warning i {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-active-warning.active {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: #FFC700 !important;
}
.text-active-warning.active i {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: #FFC700 !important;
}
.text-active-warning.active .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #FFC700;
}
.text-active-warning.active .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}

.text-active-inverse-warning {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-active-inverse-warning i {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-active-inverse-warning.active {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: #FFFFFF !important;
}
.text-active-inverse-warning.active i {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: #FFFFFF !important;
}
.text-active-inverse-warning.active .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #FFFFFF;
}
.text-active-inverse-warning.active .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}

.text-danger {
  color: #F1416C !important;
}

.text-inverse-danger {
  color: #FFFFFF !important;
}

.text-light-danger {
  color: #FFF5F8 !important;
}

.text-hover-danger {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-hover-danger i {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-hover-danger:hover {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: #F1416C !important;
}
.text-hover-danger:hover i {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: #F1416C !important;
}
.text-hover-danger:hover .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #F1416C;
}
.text-hover-danger:hover .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}

.text-hover-inverse-danger {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-hover-inverse-danger i {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-hover-inverse-danger:hover {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: #FFFFFF !important;
}
.text-hover-inverse-danger:hover i {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: #FFFFFF !important;
}
.text-hover-inverse-danger:hover .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #FFFFFF;
}
.text-hover-inverse-danger:hover .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}

.text-active-danger {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-active-danger i {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-active-danger.active {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: #F1416C !important;
}
.text-active-danger.active i {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: #F1416C !important;
}
.text-active-danger.active .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #F1416C;
}
.text-active-danger.active .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}

.text-active-inverse-danger {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-active-inverse-danger i {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-active-inverse-danger.active {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: #FFFFFF !important;
}
.text-active-inverse-danger.active i {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: #FFFFFF !important;
}
.text-active-inverse-danger.active .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #FFFFFF;
}
.text-active-inverse-danger.active .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}

.text-dark {
  color: #181C32 !important;
}

.text-inverse-dark {
  color: #ffffff !important;
}

.text-light-dark {
  color: #EFF2F5 !important;
}

.text-hover-dark {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-hover-dark i {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-hover-dark:hover {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: #181C32 !important;
}
.text-hover-dark:hover i {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: #181C32 !important;
}
.text-hover-dark:hover .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #181C32;
}
.text-hover-dark:hover .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}

.text-hover-inverse-dark {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-hover-inverse-dark i {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-hover-inverse-dark:hover {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: #ffffff !important;
}
.text-hover-inverse-dark:hover i {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: #ffffff !important;
}
.text-hover-inverse-dark:hover .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #ffffff;
}
.text-hover-inverse-dark:hover .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}

.text-active-dark {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-active-dark i {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-active-dark.active {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: #181C32 !important;
}
.text-active-dark.active i {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: #181C32 !important;
}
.text-active-dark.active .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #181C32;
}
.text-active-dark.active .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}

.text-active-inverse-dark {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-active-inverse-dark i {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-active-inverse-dark.active {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: #ffffff !important;
}
.text-active-inverse-dark.active i {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: #ffffff !important;
}
.text-active-inverse-dark.active .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #ffffff;
}
.text-active-inverse-dark.active .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}

.text-muted {
  color: #A1A5B7 !important;
}

.text-inverse-muted {
  color: !important;
}

.text-hover-muted {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-hover-muted i {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-hover-muted:hover {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: #A1A5B7 !important;
}
.text-hover-muted:hover i {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: #A1A5B7 !important;
}
.text-hover-muted:hover .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #A1A5B7;
}
.text-hover-muted:hover .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}

.text-hover-inverse-muted {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-hover-inverse-muted i {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-hover-inverse-muted:hover {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: !important;
}
.text-hover-inverse-muted:hover i {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: !important;
}
.text-hover-inverse-muted:hover .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.text-hover-inverse-muted:hover .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}

.text-active-muted {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-active-muted i {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-active-muted.active {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: #A1A5B7 !important;
}
.text-active-muted.active i {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: #A1A5B7 !important;
}
.text-active-muted.active .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #A1A5B7;
}
.text-active-muted.active .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}

.text-active-inverse-muted {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-active-inverse-muted i {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-active-inverse-muted.active {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: !important;
}
.text-active-inverse-muted.active i {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: !important;
}
.text-active-inverse-muted.active .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.text-active-inverse-muted.active .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}

.text-gray-100 {
  color: #F5F8FA !important;
}

.text-inverse-gray-100 {
  color: !important;
}

.text-hover-gray-100 {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-hover-gray-100 i {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-hover-gray-100:hover {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: #F5F8FA !important;
}
.text-hover-gray-100:hover i {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: #F5F8FA !important;
}
.text-hover-gray-100:hover .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #F5F8FA;
}
.text-hover-gray-100:hover .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}

.text-hover-inverse-gray-100 {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-hover-inverse-gray-100 i {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-hover-inverse-gray-100:hover {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: !important;
}
.text-hover-inverse-gray-100:hover i {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: !important;
}
.text-hover-inverse-gray-100:hover .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.text-hover-inverse-gray-100:hover .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}

.text-active-gray-100 {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-active-gray-100 i {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-active-gray-100.active {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: #F5F8FA !important;
}
.text-active-gray-100.active i {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: #F5F8FA !important;
}
.text-active-gray-100.active .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #F5F8FA;
}
.text-active-gray-100.active .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}

.text-active-inverse-gray-100 {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-active-inverse-gray-100 i {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-active-inverse-gray-100.active {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: !important;
}
.text-active-inverse-gray-100.active i {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: !important;
}
.text-active-inverse-gray-100.active .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.text-active-inverse-gray-100.active .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}

.text-gray-200 {
  color: #EFF2F5 !important;
}

.text-inverse-gray-200 {
  color: !important;
}

.text-hover-gray-200 {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-hover-gray-200 i {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-hover-gray-200:hover {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: #EFF2F5 !important;
}
.text-hover-gray-200:hover i {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: #EFF2F5 !important;
}
.text-hover-gray-200:hover .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #EFF2F5;
}
.text-hover-gray-200:hover .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}

.text-hover-inverse-gray-200 {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-hover-inverse-gray-200 i {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-hover-inverse-gray-200:hover {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: !important;
}
.text-hover-inverse-gray-200:hover i {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: !important;
}
.text-hover-inverse-gray-200:hover .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.text-hover-inverse-gray-200:hover .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}

.text-active-gray-200 {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-active-gray-200 i {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-active-gray-200.active {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: #EFF2F5 !important;
}
.text-active-gray-200.active i {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: #EFF2F5 !important;
}
.text-active-gray-200.active .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #EFF2F5;
}
.text-active-gray-200.active .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}

.text-active-inverse-gray-200 {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-active-inverse-gray-200 i {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-active-inverse-gray-200.active {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: !important;
}
.text-active-inverse-gray-200.active i {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: !important;
}
.text-active-inverse-gray-200.active .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.text-active-inverse-gray-200.active .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}

.text-gray-300 {
  color: #E4E6EF !important;
}

.text-inverse-gray-300 {
  color: !important;
}

.text-hover-gray-300 {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-hover-gray-300 i {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-hover-gray-300:hover {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: #E4E6EF !important;
}
.text-hover-gray-300:hover i {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: #E4E6EF !important;
}
.text-hover-gray-300:hover .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #E4E6EF;
}
.text-hover-gray-300:hover .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}

.text-hover-inverse-gray-300 {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-hover-inverse-gray-300 i {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-hover-inverse-gray-300:hover {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: !important;
}
.text-hover-inverse-gray-300:hover i {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: !important;
}
.text-hover-inverse-gray-300:hover .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.text-hover-inverse-gray-300:hover .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}

.text-active-gray-300 {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-active-gray-300 i {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-active-gray-300.active {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: #E4E6EF !important;
}
.text-active-gray-300.active i {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: #E4E6EF !important;
}
.text-active-gray-300.active .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #E4E6EF;
}
.text-active-gray-300.active .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}

.text-active-inverse-gray-300 {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-active-inverse-gray-300 i {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-active-inverse-gray-300.active {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: !important;
}
.text-active-inverse-gray-300.active i {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: !important;
}
.text-active-inverse-gray-300.active .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.text-active-inverse-gray-300.active .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}

.text-gray-400 {
  color: #B5B5C3 !important;
}

.text-inverse-gray-400 {
  color: !important;
}

.text-hover-gray-400 {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-hover-gray-400 i {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-hover-gray-400:hover {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: #B5B5C3 !important;
}
.text-hover-gray-400:hover i {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: #B5B5C3 !important;
}
.text-hover-gray-400:hover .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #B5B5C3;
}
.text-hover-gray-400:hover .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}

.text-hover-inverse-gray-400 {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-hover-inverse-gray-400 i {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-hover-inverse-gray-400:hover {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: !important;
}
.text-hover-inverse-gray-400:hover i {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: !important;
}
.text-hover-inverse-gray-400:hover .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.text-hover-inverse-gray-400:hover .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}

.text-active-gray-400 {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-active-gray-400 i {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-active-gray-400.active {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: #B5B5C3 !important;
}
.text-active-gray-400.active i {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: #B5B5C3 !important;
}
.text-active-gray-400.active .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #B5B5C3;
}
.text-active-gray-400.active .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}

.text-active-inverse-gray-400 {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-active-inverse-gray-400 i {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-active-inverse-gray-400.active {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: !important;
}
.text-active-inverse-gray-400.active i {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: !important;
}
.text-active-inverse-gray-400.active .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.text-active-inverse-gray-400.active .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}

.text-gray-500 {
  color: #A1A5B7 !important;
}

.text-inverse-gray-500 {
  color: !important;
}

.text-hover-gray-500 {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-hover-gray-500 i {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-hover-gray-500:hover {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: #A1A5B7 !important;
}
.text-hover-gray-500:hover i {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: #A1A5B7 !important;
}
.text-hover-gray-500:hover .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #A1A5B7;
}
.text-hover-gray-500:hover .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}

.text-hover-inverse-gray-500 {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-hover-inverse-gray-500 i {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-hover-inverse-gray-500:hover {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: !important;
}
.text-hover-inverse-gray-500:hover i {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: !important;
}
.text-hover-inverse-gray-500:hover .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.text-hover-inverse-gray-500:hover .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}

.text-active-gray-500 {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-active-gray-500 i {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-active-gray-500.active {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: #A1A5B7 !important;
}
.text-active-gray-500.active i {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: #A1A5B7 !important;
}
.text-active-gray-500.active .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #A1A5B7;
}
.text-active-gray-500.active .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}

.text-active-inverse-gray-500 {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-active-inverse-gray-500 i {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-active-inverse-gray-500.active {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: !important;
}
.text-active-inverse-gray-500.active i {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: !important;
}
.text-active-inverse-gray-500.active .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.text-active-inverse-gray-500.active .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}

.text-gray-600 {
  color: #7E8299 !important;
}

.text-inverse-gray-600 {
  color: !important;
}

.text-hover-gray-600 {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-hover-gray-600 i {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-hover-gray-600:hover {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: #7E8299 !important;
}
.text-hover-gray-600:hover i {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: #7E8299 !important;
}
.text-hover-gray-600:hover .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #7E8299;
}
.text-hover-gray-600:hover .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}

.text-hover-inverse-gray-600 {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-hover-inverse-gray-600 i {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-hover-inverse-gray-600:hover {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: !important;
}
.text-hover-inverse-gray-600:hover i {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: !important;
}
.text-hover-inverse-gray-600:hover .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.text-hover-inverse-gray-600:hover .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}

.text-active-gray-600 {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-active-gray-600 i {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-active-gray-600.active {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: #7E8299 !important;
}
.text-active-gray-600.active i {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: #7E8299 !important;
}
.text-active-gray-600.active .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #7E8299;
}
.text-active-gray-600.active .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}

.text-active-inverse-gray-600 {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-active-inverse-gray-600 i {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-active-inverse-gray-600.active {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: !important;
}
.text-active-inverse-gray-600.active i {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: !important;
}
.text-active-inverse-gray-600.active .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.text-active-inverse-gray-600.active .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}

.text-gray-700 {
  color: #5E6278 !important;
}

.text-inverse-gray-700 {
  color: !important;
}

.text-hover-gray-700 {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-hover-gray-700 i {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-hover-gray-700:hover {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: #5E6278 !important;
}
.text-hover-gray-700:hover i {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: #5E6278 !important;
}
.text-hover-gray-700:hover .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #5E6278;
}
.text-hover-gray-700:hover .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}

.text-hover-inverse-gray-700 {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-hover-inverse-gray-700 i {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-hover-inverse-gray-700:hover {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: !important;
}
.text-hover-inverse-gray-700:hover i {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: !important;
}
.text-hover-inverse-gray-700:hover .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.text-hover-inverse-gray-700:hover .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}

.text-active-gray-700 {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-active-gray-700 i {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-active-gray-700.active {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: #5E6278 !important;
}
.text-active-gray-700.active i {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: #5E6278 !important;
}
.text-active-gray-700.active .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #5E6278;
}
.text-active-gray-700.active .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}

.text-active-inverse-gray-700 {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-active-inverse-gray-700 i {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-active-inverse-gray-700.active {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: !important;
}
.text-active-inverse-gray-700.active i {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: !important;
}
.text-active-inverse-gray-700.active .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.text-active-inverse-gray-700.active .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}

.text-gray-800 {
  color: #3F4254 !important;
}

.text-inverse-gray-800 {
  color: !important;
}

.text-hover-gray-800 {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-hover-gray-800 i {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-hover-gray-800:hover {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: #3F4254 !important;
}
.text-hover-gray-800:hover i {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: #3F4254 !important;
}
.text-hover-gray-800:hover .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #3F4254;
}
.text-hover-gray-800:hover .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}

.text-hover-inverse-gray-800 {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-hover-inverse-gray-800 i {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-hover-inverse-gray-800:hover {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: !important;
}
.text-hover-inverse-gray-800:hover i {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: !important;
}
.text-hover-inverse-gray-800:hover .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.text-hover-inverse-gray-800:hover .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}

.text-active-gray-800 {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-active-gray-800 i {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-active-gray-800.active {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: #3F4254 !important;
}
.text-active-gray-800.active i {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: #3F4254 !important;
}
.text-active-gray-800.active .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #3F4254;
}
.text-active-gray-800.active .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}

.text-active-inverse-gray-800 {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-active-inverse-gray-800 i {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-active-inverse-gray-800.active {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: !important;
}
.text-active-inverse-gray-800.active i {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: !important;
}
.text-active-inverse-gray-800.active .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.text-active-inverse-gray-800.active .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}

.text-gray-900 {
  color: #181C32 !important;
}

.text-inverse-gray-900 {
  color: !important;
}

.text-hover-gray-900 {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-hover-gray-900 i {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-hover-gray-900:hover {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: #181C32 !important;
}
.text-hover-gray-900:hover i {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: #181C32 !important;
}
.text-hover-gray-900:hover .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #181C32;
}
.text-hover-gray-900:hover .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}

.text-hover-inverse-gray-900 {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-hover-inverse-gray-900 i {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-hover-inverse-gray-900:hover {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: !important;
}
.text-hover-inverse-gray-900:hover i {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: !important;
}
.text-hover-inverse-gray-900:hover .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.text-hover-inverse-gray-900:hover .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}

.text-active-gray-900 {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-active-gray-900 i {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-active-gray-900.active {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: #181C32 !important;
}
.text-active-gray-900.active i {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: #181C32 !important;
}
.text-active-gray-900.active .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #181C32;
}
.text-active-gray-900.active .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}

.text-active-inverse-gray-900 {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-active-inverse-gray-900 i {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-active-inverse-gray-900.active {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: !important;
}
.text-active-inverse-gray-900.active i {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: !important;
}
.text-active-inverse-gray-900.active .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.text-active-inverse-gray-900.active .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}

.fw-boldest {
  font-weight: 700 !important;
}

.text-transparent {
  color: transparent;
}

.cursor-pointer {
  cursor: pointer;
}

.cursor-default {
  cursor: default;
}

.cursor-move {
  cursor: move;
}

i {
  line-height: 1;
  font-size: 1rem;
  color: #A1A5B7;
}

a {
  transition: color 0.2s ease, background-color 0.2s ease;
}
a:hover {
  transition: color 0.2s ease, background-color 0.2s ease;
}

.opacity-active-0.active {
  opacity: 0 !important;
}

.opacity-state-0:hover, .opacity-state-0.active {
  opacity: 0 !important;
}

.opacity-active-5.active {
  opacity: 0.05 !important;
}

.opacity-state-5:hover, .opacity-state-5.active {
  opacity: 0.05 !important;
}

.opacity-active-10.active {
  opacity: 0.1 !important;
}

.opacity-state-10:hover, .opacity-state-10.active {
  opacity: 0.1 !important;
}

.opacity-active-15.active {
  opacity: 0.15 !important;
}

.opacity-state-15:hover, .opacity-state-15.active {
  opacity: 0.15 !important;
}

.opacity-active-20.active {
  opacity: 0.2 !important;
}

.opacity-state-20:hover, .opacity-state-20.active {
  opacity: 0.2 !important;
}

.opacity-active-25.active {
  opacity: 0.25 !important;
}

.opacity-state-25:hover, .opacity-state-25.active {
  opacity: 0.25 !important;
}

.opacity-active-50.active {
  opacity: 0.5 !important;
}

.opacity-state-50:hover, .opacity-state-50.active {
  opacity: 0.5 !important;
}

.opacity-active-75.active {
  opacity: 0.75 !important;
}

.opacity-state-75:hover, .opacity-state-75.active {
  opacity: 0.75 !important;
}

.opacity-active-100.active {
  opacity: 1 !important;
}

.opacity-state-100:hover, .opacity-state-100.active {
  opacity: 1 !important;
}

.transform-90 {
  transform: rotate(90deg);
  transform-origin: right top;
}

.animation {
  animation-duration: 1s;
  animation-fill-mode: both;
}

@keyframes animationSlideInDown {
  from {
    transform: translate3d(0, -100%, 0);
    visibility: visible;
  }
  to {
    transform: translate3d(0, 0, 0);
  }
}
.animation-slide-in-down {
  animation-name: animationSlideInDown;
}

@keyframes animationSlideInUp {
  from {
    transform: translate3d(0, 100%, 0);
    visibility: visible;
  }
  to {
    transform: translate3d(0, 0, 0);
  }
}
.animation-slide-in-up {
  animation-name: animationSlideInUp;
}

@keyframes animationFadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.animation-fade-in {
  animation-name: animationFadeIn;
}

@keyframes animationFadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
.animation-fade-out {
  animation-name: animationFadeOut;
}

.animation-blink {
  animation: animationBlink 1s steps(5, start) infinite;
}

@keyframes animationBlink {
  to {
    visibility: hidden;
  }
}
.nav-line-tabs {
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: #EFF2F5;
}
.nav-line-tabs .nav-item {
  margin-bottom: -1px;
}
.nav-line-tabs .nav-item .nav-link {
  color: #A1A5B7;
  border: 0;
  border-bottom: 1px solid transparent;
  transition: color 0.2s ease, background-color 0.2s ease;
  padding: 0.5rem 0;
  margin: 0 1rem;
}
.nav-line-tabs .nav-item:first-child .nav-link {
  margin-left: 0;
}
.nav-line-tabs .nav-item:last-child .nav-link {
  margin-right: 0;
}
.nav-line-tabs .nav-item .nav-link.active,
.nav-line-tabs .nav-item.show .nav-link,
.nav-line-tabs .nav-item .nav-link:hover:not(.disabled) {
  background-color: transparent;
  border: 0;
  border-bottom: 1px solid #009EF7;
  transition: color 0.2s ease, background-color 0.2s ease;
}
.nav-line-tabs.nav-line-tabs-2x {
  border-bottom-width: 2px;
}
.nav-line-tabs.nav-line-tabs-2x .nav-item {
  margin-bottom: -2px;
}
.nav-line-tabs.nav-line-tabs-2x .nav-item .nav-link {
  border-bottom-width: 2px;
}
.nav-line-tabs.nav-line-tabs-2x .nav-item .nav-link.active,
.nav-line-tabs.nav-line-tabs-2x .nav-item.show .nav-link,
.nav-line-tabs.nav-line-tabs-2x .nav-item .nav-link:hover:not(.disabled) {
  border-bottom-width: 2px;
}

.nav-pills .nav-item {
  margin-right: 0.5rem;
}
.nav-pills .nav-item:last-child {
  margin-right: 0;
}

.nav-stretch {
  align-items: stretch;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}
.nav-stretch .nav-item {
  display: flex;
  align-items: stretch;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}
.nav-stretch .nav-link {
  display: flex;
  align-items: center;
}

.nav-group {
  padding: 0.35rem;
  border-radius: 0.475rem;
  background-color: #F5F8FA;
}
.nav-group.nav-group-outline {
  background-color: transparent;
  border: 1px solid #EFF2F5;
}
.nav-group.nav-group-fluid {
  display: flex;
}
.nav-group.nav-group-fluid > label,
.nav-group.nav-group-fluid > .btn {
  position: relative;
  flex-shrink: 0;
  flex-grow: 1;
  flex-basis: 0;
}
.nav-group.nav-group-fluid > label {
  margin-right: 0.1rem;
}
.nav-group.nav-group-fluid > label > .btn {
  width: 100%;
}
.nav-group.nav-group-fluid > label:last-child {
  margin-right: 0;
}

.pagination {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0;
}

.page-item {
  margin-right: 0.5rem;
}
.page-item:last-child {
  margin-right: 0;
}
.page-item .page-link {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0.475rem;
  height: 2.5rem;
  min-width: 2.5rem;
  font-weight: 500;
  font-size: 1.075rem;
}
.page-item .page-link i {
  font-size: 0.85rem;
}
.page-item .page-link .previous,
.page-item .page-link .next {
  display: block;
  height: 0.875rem;
  width: 0.875rem;
}
.page-item.next:not(:hover):not(:focus):not(.disabled) .page-link, .page-item.previous:not(:hover):not(:focus):not(.disabled) .page-link {
  background-color: #ffffff;
}
.page-item.next:not(:hover):not(:focus):not(.disabled) .page-link i, .page-item.previous:not(:hover):not(:focus):not(.disabled) .page-link i {
  color: #5E6278;
}
.page-item.next:not(:hover):not(:focus):not(.disabled) .page-link .svg-icon svg [fill]:not(.permanent):not(g), .page-item.previous:not(:hover):not(:focus):not(.disabled) .page-link .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #5E6278;
}
.page-item.next:not(:hover):not(:focus):not(.disabled) .page-link .svg-icon svg:hover [fill]:not(.permanent):not(g), .page-item.previous:not(:hover):not(:focus):not(.disabled) .page-link .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.page-item.next:not(:hover):not(:focus):not(.disabled) .page-link .previous, .page-item.previous:not(:hover):not(:focus):not(.disabled) .page-link .previous {
  background-repeat: no-repeat;
  background-position: center;
  background-color: transparent;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 9' fill='%235E6278'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M2.06463 4.42111C1.96161 4.22088 1.9809 3.9637 2.12863 3.78597L5.12847 0.177181C5.31402 -0.046034 5.63049 -0.060261 5.83532 0.145404C6.04015 0.351069 6.05578 0.698744 5.87023 0.921959L3.19406 4.14137L5.84414 7.06417C6.03896 7.27904 6.03835 7.62686 5.84278 7.84105C5.64721 8.05524 5.33073 8.05469 5.13591 7.83982L2.14806 4.54449C2.1141 4.50704 2.08629 4.46541 2.06463 4.42111Z'/%3e%3c/svg%3e");
}
.page-item.next:not(:hover):not(:focus):not(.disabled) .page-link .next, .page-item.previous:not(:hover):not(:focus):not(.disabled) .page-link .next {
  background-repeat: no-repeat;
  background-position: center;
  background-color: transparent;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 9' fill='%235E6278'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M5.93537 4.57889C6.03839 4.77912 6.0191 5.0363 5.87137 5.21403L2.87153 8.82282C2.68598 9.04603 2.36951 9.06026 2.16468 8.8546C1.95985 8.64893 1.94422 8.30126 2.12977 8.07804L4.80594 4.85863L2.15586 1.93583C1.96104 1.72096 1.96165 1.37314 2.15722 1.15895C2.35279 0.944757 2.66927 0.945311 2.86409 1.16018L5.85194 4.45551C5.8859 4.49296 5.91371 4.53459 5.93537 4.57889Z'/%3e%3c/svg%3e");
}
.page-item:focus:not(.disabled) .page-link i {
  color: #009EF7;
}
.page-item:focus:not(.disabled) .page-link .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #009EF7;
}
.page-item:focus:not(.disabled) .page-link .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.page-item:focus:not(.disabled) .page-link .previous {
  background-repeat: no-repeat;
  background-position: center;
  background-color: transparent;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 9' fill='%23009EF7'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M2.06463 4.42111C1.96161 4.22088 1.9809 3.9637 2.12863 3.78597L5.12847 0.177181C5.31402 -0.046034 5.63049 -0.060261 5.83532 0.145404C6.04015 0.351069 6.05578 0.698744 5.87023 0.921959L3.19406 4.14137L5.84414 7.06417C6.03896 7.27904 6.03835 7.62686 5.84278 7.84105C5.64721 8.05524 5.33073 8.05469 5.13591 7.83982L2.14806 4.54449C2.1141 4.50704 2.08629 4.46541 2.06463 4.42111Z'/%3e%3c/svg%3e");
}
.page-item:focus:not(.disabled) .page-link .next {
  background-repeat: no-repeat;
  background-position: center;
  background-color: transparent;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 9' fill='%23009EF7'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M5.93537 4.57889C6.03839 4.77912 6.0191 5.0363 5.87137 5.21403L2.87153 8.82282C2.68598 9.04603 2.36951 9.06026 2.16468 8.8546C1.95985 8.64893 1.94422 8.30126 2.12977 8.07804L4.80594 4.85863L2.15586 1.93583C1.96104 1.72096 1.96165 1.37314 2.15722 1.15895C2.35279 0.944757 2.66927 0.945311 2.86409 1.16018L5.85194 4.45551C5.8859 4.49296 5.91371 4.53459 5.93537 4.57889Z'/%3e%3c/svg%3e");
}
.page-item:hover:not(.disabled) .page-link i {
  color: #009EF7;
}
.page-item:hover:not(.disabled) .page-link .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #009EF7;
}
.page-item:hover:not(.disabled) .page-link .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.page-item:hover:not(.disabled) .page-link .previous {
  background-repeat: no-repeat;
  background-position: center;
  background-color: transparent;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 9' fill='%23009EF7'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M2.06463 4.42111C1.96161 4.22088 1.9809 3.9637 2.12863 3.78597L5.12847 0.177181C5.31402 -0.046034 5.63049 -0.060261 5.83532 0.145404C6.04015 0.351069 6.05578 0.698744 5.87023 0.921959L3.19406 4.14137L5.84414 7.06417C6.03896 7.27904 6.03835 7.62686 5.84278 7.84105C5.64721 8.05524 5.33073 8.05469 5.13591 7.83982L2.14806 4.54449C2.1141 4.50704 2.08629 4.46541 2.06463 4.42111Z'/%3e%3c/svg%3e");
}
.page-item:hover:not(.disabled) .page-link .next {
  background-repeat: no-repeat;
  background-position: center;
  background-color: transparent;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 9' fill='%23009EF7'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M5.93537 4.57889C6.03839 4.77912 6.0191 5.0363 5.87137 5.21403L2.87153 8.82282C2.68598 9.04603 2.36951 9.06026 2.16468 8.8546C1.95985 8.64893 1.94422 8.30126 2.12977 8.07804L4.80594 4.85863L2.15586 1.93583C1.96104 1.72096 1.96165 1.37314 2.15722 1.15895C2.35279 0.944757 2.66927 0.945311 2.86409 1.16018L5.85194 4.45551C5.8859 4.49296 5.91371 4.53459 5.93537 4.57889Z'/%3e%3c/svg%3e");
}
.page-item.active .page-link i {
  color: #FFFFFF;
}
.page-item.active .page-link .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #FFFFFF;
}
.page-item.active .page-link .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.page-item.active .page-link .previous {
  background-repeat: no-repeat;
  background-position: center;
  background-color: transparent;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 9' fill='%23FFFFFF'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M2.06463 4.42111C1.96161 4.22088 1.9809 3.9637 2.12863 3.78597L5.12847 0.177181C5.31402 -0.046034 5.63049 -0.060261 5.83532 0.145404C6.04015 0.351069 6.05578 0.698744 5.87023 0.921959L3.19406 4.14137L5.84414 7.06417C6.03896 7.27904 6.03835 7.62686 5.84278 7.84105C5.64721 8.05524 5.33073 8.05469 5.13591 7.83982L2.14806 4.54449C2.1141 4.50704 2.08629 4.46541 2.06463 4.42111Z'/%3e%3c/svg%3e");
}
.page-item.active .page-link .next {
  background-repeat: no-repeat;
  background-position: center;
  background-color: transparent;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 9' fill='%23FFFFFF'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M5.93537 4.57889C6.03839 4.77912 6.0191 5.0363 5.87137 5.21403L2.87153 8.82282C2.68598 9.04603 2.36951 9.06026 2.16468 8.8546C1.95985 8.64893 1.94422 8.30126 2.12977 8.07804L4.80594 4.85863L2.15586 1.93583C1.96104 1.72096 1.96165 1.37314 2.15722 1.15895C2.35279 0.944757 2.66927 0.945311 2.86409 1.16018L5.85194 4.45551C5.8859 4.49296 5.91371 4.53459 5.93537 4.57889Z'/%3e%3c/svg%3e");
}
.page-item.disabled .page-link i {
  color: #B5B5C3;
}
.page-item.disabled .page-link .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #B5B5C3;
}
.page-item.disabled .page-link .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.page-item.disabled .page-link .previous {
  background-repeat: no-repeat;
  background-position: center;
  background-color: transparent;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 9' fill='%23B5B5C3'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M2.06463 4.42111C1.96161 4.22088 1.9809 3.9637 2.12863 3.78597L5.12847 0.177181C5.31402 -0.046034 5.63049 -0.060261 5.83532 0.145404C6.04015 0.351069 6.05578 0.698744 5.87023 0.921959L3.19406 4.14137L5.84414 7.06417C6.03896 7.27904 6.03835 7.62686 5.84278 7.84105C5.64721 8.05524 5.33073 8.05469 5.13591 7.83982L2.14806 4.54449C2.1141 4.50704 2.08629 4.46541 2.06463 4.42111Z'/%3e%3c/svg%3e");
}
.page-item.disabled .page-link .next {
  background-repeat: no-repeat;
  background-position: center;
  background-color: transparent;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 9' fill='%23B5B5C3'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M5.93537 4.57889C6.03839 4.77912 6.0191 5.0363 5.87137 5.21403L2.87153 8.82282C2.68598 9.04603 2.36951 9.06026 2.16468 8.8546C1.95985 8.64893 1.94422 8.30126 2.12977 8.07804L4.80594 4.85863L2.15586 1.93583C1.96104 1.72096 1.96165 1.37314 2.15722 1.15895C2.35279 0.944757 2.66927 0.945311 2.86409 1.16018L5.85194 4.45551C5.8859 4.49296 5.91371 4.53459 5.93537 4.57889Z'/%3e%3c/svg%3e");
}

.separator {
  display: block;
  height: 0;
  border-bottom: 1px solid #EFF2F5;
}
.separator.separator-dotted {
  border-bottom-style: dotted;
  border-bottom-color: #E4E6EF;
}
.separator.separator-dashed {
  border-bottom-style: dashed;
  border-bottom-color: #E4E6EF;
}

.carousel.carousel-custom .carousel-indicators {
  align-items: center;
  position: static;
  margin: 0;
  padding: 0;
  list-style: none;
}
.carousel.carousel-custom .carousel-indicators li {
  transform: none;
  opacity: 1;
}
.carousel.carousel-custom .carousel-indicators li.active {
  transform: none;
  opacity: 1;
}
.carousel.carousel-custom .carousel-indicators.carousel-indicators-dots li {
  border-radius: 0;
  background-color: transparent;
  height: 13px;
  width: 13px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.carousel.carousel-custom .carousel-indicators.carousel-indicators-dots li:after {
  display: inline-block;
  content: " ";
  border-radius: 50%;
  transition: all 0.3s ease;
  background-color: #EFF2F5;
  height: 9px;
  width: 9px;
}
.carousel.carousel-custom .carousel-indicators.carousel-indicators-dots li.active {
  background-color: transparent;
}
.carousel.carousel-custom .carousel-indicators.carousel-indicators-dots li.active:after {
  transition: all 0.3s ease;
  height: 13px;
  width: 13px;
  background-color: #E4E6EF;
}
.carousel.carousel-custom.carousel-stretch {
  height: 100%;
  display: flex;
  flex-direction: column;
}
.carousel.carousel-custom.carousel-stretch .carousel-inner {
  flex-grow: 1;
}
.carousel.carousel-custom.carousel-stretch .carousel-item {
  height: 100%;
}
.carousel.carousel-custom.carousel-stretch .carousel-wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.menu-group {
  display: flex;
}

.menu,
.menu-wrapper {
  display: flex;
  padding: 0;
  margin: 0;
  list-style: none;
}

.menu-sub {
  display: none;
  padding: 0;
  margin: 0;
  list-style: none;
  flex-direction: column;
}

.menu-item {
  display: block;
  padding: 0;
}
.menu-item .menu-link {
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 0;
  flex: 0 0 100%;
  padding: 0.65rem 1rem;
  transition: none;
  outline: none !important;
}
.menu-item .menu-link .menu-icon {
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2rem;
  margin-right: 0.5rem;
}
.menu-item .menu-link .menu-icon .svg-icon {
  line-height: 1;
}
.menu-item .menu-link .menu-bullet {
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1.25rem;
  margin-right: 0.5rem;
}
.menu-item .menu-link .menu-title {
  display: flex;
  align-items: center;
  flex-grow: 1;
}
.menu-item .menu-link .menu-badge {
  flex-shrink: 0;
  margin-left: 0.5rem;
}
.menu-item .menu-link .menu-arrow {
  display: flex;
  align-items: stretch;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  margin-left: 0.5rem;
  width: 0.8rem;
  height: 0.8rem;
}
.menu-item .menu-link .menu-arrow:after {
  display: block;
  width: 100%;
  content: " ";
  background-repeat: no-repeat;
  background-position: center;
  background-color: transparent;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 9' fill='%23A1A5B7'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M5.93537 4.57889C6.03839 4.77912 6.0191 5.0363 5.87137 5.21403L2.87153 8.82282C2.68598 9.04603 2.36951 9.06026 2.16468 8.8546C1.95985 8.64893 1.94422 8.30126 2.12977 8.07804L4.80594 4.85863L2.15586 1.93583C1.96104 1.72096 1.96165 1.37314 2.15722 1.15895C2.35279 0.944757 2.66927 0.945311 2.86409 1.16018L5.85194 4.45551C5.8859 4.49296 5.91371 4.53459 5.93537 4.57889Z'/%3e%3c/svg%3e");
}
.menu-item .menu-content {
  padding: 0.65rem 1rem;
}

.menu-item.show .menu-link .menu-arrow:after {
  -webkit-backface-visibility: hidden;
  transition: transform 0.3s ease;
}

.menu-center {
  justify-content: center;
}

.menu-item.menu-accordion.showing:not(.menu-dropdown) > .menu-link .menu-arrow:after, .menu-item.menu-accordion.show:not(.hiding):not(.menu-dropdown) > .menu-link .menu-arrow:after {
  transform: rotateZ(90deg) /*rtl:ignore*/;
  transition: transform 0.3s ease;
}
[direction=rtl] .menu-item.menu-accordion.showing:not(.menu-dropdown) > .menu-link .menu-arrow:after, [direction=rtl] .menu-item.menu-accordion.show:not(.hiding):not(.menu-dropdown) > .menu-link .menu-arrow:after {
  transform: rotateZ(-90deg) /*rtl:ignore*/;
}

.menu-sub-dropdown {
  display: none;
  border-radius: 0.475rem;
  background-color: #ffffff;
  box-shadow: 0px 0px 50px 0px rgba(82, 63, 105, 0.15);
  z-index: 105;
}
.show.menu-dropdown > .menu-sub-dropdown, .menu-sub-dropdown.menu.show, .menu-sub-dropdown.show[data-popper-placement] {
  display: flex;
  animation: menu-sub-dropdown-animation-fade-in 0.3s ease 1, menu-sub-dropdown-animation-move-up 0.3s ease 1;
}
.show.menu-dropdown > .menu-sub-dropdown[data-popper-placement=top], .show.menu-dropdown > .menu-sub-dropdown[data-popper-placement=top-start], .show.menu-dropdown > .menu-sub-dropdown[data-popper-placement=top-end], .menu-sub-dropdown.menu.show[data-popper-placement=top], .menu-sub-dropdown.menu.show[data-popper-placement=top-start], .menu-sub-dropdown.menu.show[data-popper-placement=top-end], .menu-sub-dropdown.show[data-popper-placement][data-popper-placement=top], .menu-sub-dropdown.show[data-popper-placement][data-popper-placement=top-start], .menu-sub-dropdown.show[data-popper-placement][data-popper-placement=top-end] {
  animation: menu-sub-dropdown-animation-fade-in 0.3s ease 1, menu-sub-dropdown-animation-move-down 0.3s ease 1;
}

.menu-sub-accordion {
  display: none;
}
.show:not(.menu-dropdown) > .menu-sub-accordion, .menu-sub-accordion.show {
  display: flex;
}

.menu-inline {
  display: flex;
}

.menu-fit > .menu-item > .menu-content,
.menu-fit > .menu-item > .menu-link {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.menu-column {
  flex-direction: column;
  width: 100%;
}

.menu-row {
  flex-direction: row;
}
.menu-row > .menu-item {
  display: flex;
  align-items: center;
}
.menu-row > .menu-item > .menu-link .menu-arrow:after {
  transform: rotateZ(90deg) /*rtl:ignore*/;
  transition: transform 0.3s ease;
}
[direction=rtl] .menu-row > .menu-item > .menu-link .menu-arrow:after {
  transform: rotateZ(-90deg) /*rtl:ignore*/;
}

.menu-rounded .menu-link {
  border-radius: 0.475rem;
}

.menu-pill .menu-link {
  border-radius: 50px;
}

.menu-rounded-0 .menu-link {
  border-radius: 0 !important;
}

@media (min-width: 576px) {
  .menu-item.menu-sm-accordion.showing:not(.menu-dropdown) > .menu-link .menu-arrow:after, .menu-item.menu-sm-accordion.show:not(.hiding):not(.menu-dropdown) > .menu-link .menu-arrow:after {
    transform: rotateZ(90deg) /*rtl:ignore*/;
    transition: transform 0.3s ease;
  }
  [direction=rtl] .menu-item.menu-sm-accordion.showing:not(.menu-dropdown) > .menu-link .menu-arrow:after, [direction=rtl] .menu-item.menu-sm-accordion.show:not(.hiding):not(.menu-dropdown) > .menu-link .menu-arrow:after {
    transform: rotateZ(-90deg) /*rtl:ignore*/;
  }

  .menu-sub-sm-dropdown {
    display: none;
    border-radius: 0.475rem;
    background-color: #ffffff;
    box-shadow: 0px 0px 50px 0px rgba(82, 63, 105, 0.15);
    z-index: 105;
  }
  .show.menu-dropdown > .menu-sub-sm-dropdown, .menu-sub-sm-dropdown.menu.show, .menu-sub-sm-dropdown.show[data-popper-placement] {
    display: flex;
    animation: menu-sub-dropdown-animation-fade-in 0.3s ease 1, menu-sub-dropdown-animation-move-up 0.3s ease 1;
  }
  .show.menu-dropdown > .menu-sub-sm-dropdown[data-popper-placement=top], .show.menu-dropdown > .menu-sub-sm-dropdown[data-popper-placement=top-start], .show.menu-dropdown > .menu-sub-sm-dropdown[data-popper-placement=top-end], .menu-sub-sm-dropdown.menu.show[data-popper-placement=top], .menu-sub-sm-dropdown.menu.show[data-popper-placement=top-start], .menu-sub-sm-dropdown.menu.show[data-popper-placement=top-end], .menu-sub-sm-dropdown.show[data-popper-placement][data-popper-placement=top], .menu-sub-sm-dropdown.show[data-popper-placement][data-popper-placement=top-start], .menu-sub-sm-dropdown.show[data-popper-placement][data-popper-placement=top-end] {
    animation: menu-sub-dropdown-animation-fade-in 0.3s ease 1, menu-sub-dropdown-animation-move-down 0.3s ease 1;
  }

  .menu-sub-sm-accordion {
    display: none;
  }
  .show:not(.menu-dropdown) > .menu-sub-sm-accordion, .menu-sub-sm-accordion.show {
    display: flex;
  }

  .menu-sm-inline {
    display: flex;
  }

  .menu-sm-fit > .menu-item > .menu-content,
.menu-sm-fit > .menu-item > .menu-link {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .menu-sm-column {
    flex-direction: column;
    width: 100%;
  }

  .menu-sm-row {
    flex-direction: row;
  }
  .menu-sm-row > .menu-item {
    display: flex;
    align-items: center;
  }
  .menu-sm-row > .menu-item > .menu-link .menu-arrow:after {
    transform: rotateZ(90deg) /*rtl:ignore*/;
    transition: transform 0.3s ease;
  }
  [direction=rtl] .menu-sm-row > .menu-item > .menu-link .menu-arrow:after {
    transform: rotateZ(-90deg) /*rtl:ignore*/;
  }

  .menu-sm-rounded .menu-link {
    border-radius: 0.475rem;
  }

  .menu-sm-pill .menu-link {
    border-radius: 50px;
  }

  .menu-sm-rounded-0 .menu-link {
    border-radius: 0 !important;
  }
}
@media (min-width: 768px) {
  .menu-item.menu-md-accordion.showing:not(.menu-dropdown) > .menu-link .menu-arrow:after, .menu-item.menu-md-accordion.show:not(.hiding):not(.menu-dropdown) > .menu-link .menu-arrow:after {
    transform: rotateZ(90deg) /*rtl:ignore*/;
    transition: transform 0.3s ease;
  }
  [direction=rtl] .menu-item.menu-md-accordion.showing:not(.menu-dropdown) > .menu-link .menu-arrow:after, [direction=rtl] .menu-item.menu-md-accordion.show:not(.hiding):not(.menu-dropdown) > .menu-link .menu-arrow:after {
    transform: rotateZ(-90deg) /*rtl:ignore*/;
  }

  .menu-sub-md-dropdown {
    display: none;
    border-radius: 0.475rem;
    background-color: #ffffff;
    box-shadow: 0px 0px 50px 0px rgba(82, 63, 105, 0.15);
    z-index: 105;
  }
  .show.menu-dropdown > .menu-sub-md-dropdown, .menu-sub-md-dropdown.menu.show, .menu-sub-md-dropdown.show[data-popper-placement] {
    display: flex;
    animation: menu-sub-dropdown-animation-fade-in 0.3s ease 1, menu-sub-dropdown-animation-move-up 0.3s ease 1;
  }
  .show.menu-dropdown > .menu-sub-md-dropdown[data-popper-placement=top], .show.menu-dropdown > .menu-sub-md-dropdown[data-popper-placement=top-start], .show.menu-dropdown > .menu-sub-md-dropdown[data-popper-placement=top-end], .menu-sub-md-dropdown.menu.show[data-popper-placement=top], .menu-sub-md-dropdown.menu.show[data-popper-placement=top-start], .menu-sub-md-dropdown.menu.show[data-popper-placement=top-end], .menu-sub-md-dropdown.show[data-popper-placement][data-popper-placement=top], .menu-sub-md-dropdown.show[data-popper-placement][data-popper-placement=top-start], .menu-sub-md-dropdown.show[data-popper-placement][data-popper-placement=top-end] {
    animation: menu-sub-dropdown-animation-fade-in 0.3s ease 1, menu-sub-dropdown-animation-move-down 0.3s ease 1;
  }

  .menu-sub-md-accordion {
    display: none;
  }
  .show:not(.menu-dropdown) > .menu-sub-md-accordion, .menu-sub-md-accordion.show {
    display: flex;
  }

  .menu-md-inline {
    display: flex;
  }

  .menu-md-fit > .menu-item > .menu-content,
.menu-md-fit > .menu-item > .menu-link {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .menu-md-column {
    flex-direction: column;
    width: 100%;
  }

  .menu-md-row {
    flex-direction: row;
  }
  .menu-md-row > .menu-item {
    display: flex;
    align-items: center;
  }
  .menu-md-row > .menu-item > .menu-link .menu-arrow:after {
    transform: rotateZ(90deg) /*rtl:ignore*/;
    transition: transform 0.3s ease;
  }
  [direction=rtl] .menu-md-row > .menu-item > .menu-link .menu-arrow:after {
    transform: rotateZ(-90deg) /*rtl:ignore*/;
  }

  .menu-md-rounded .menu-link {
    border-radius: 0.475rem;
  }

  .menu-md-pill .menu-link {
    border-radius: 50px;
  }

  .menu-md-rounded-0 .menu-link {
    border-radius: 0 !important;
  }
}
@media (min-width: 992px) {
  .menu-item.menu-lg-accordion.showing:not(.menu-dropdown) > .menu-link .menu-arrow:after, .menu-item.menu-lg-accordion.show:not(.hiding):not(.menu-dropdown) > .menu-link .menu-arrow:after {
    transform: rotateZ(90deg) /*rtl:ignore*/;
    transition: transform 0.3s ease;
  }
  [direction=rtl] .menu-item.menu-lg-accordion.showing:not(.menu-dropdown) > .menu-link .menu-arrow:after, [direction=rtl] .menu-item.menu-lg-accordion.show:not(.hiding):not(.menu-dropdown) > .menu-link .menu-arrow:after {
    transform: rotateZ(-90deg) /*rtl:ignore*/;
  }

  .menu-sub-lg-dropdown {
    display: none;
    border-radius: 0.475rem;
    background-color: #ffffff;
    box-shadow: 0px 0px 50px 0px rgba(82, 63, 105, 0.15);
    z-index: 105;
  }
  .show.menu-dropdown > .menu-sub-lg-dropdown, .menu-sub-lg-dropdown.menu.show, .menu-sub-lg-dropdown.show[data-popper-placement] {
    display: flex;
    animation: menu-sub-dropdown-animation-fade-in 0.3s ease 1, menu-sub-dropdown-animation-move-up 0.3s ease 1;
  }
  .show.menu-dropdown > .menu-sub-lg-dropdown[data-popper-placement=top], .show.menu-dropdown > .menu-sub-lg-dropdown[data-popper-placement=top-start], .show.menu-dropdown > .menu-sub-lg-dropdown[data-popper-placement=top-end], .menu-sub-lg-dropdown.menu.show[data-popper-placement=top], .menu-sub-lg-dropdown.menu.show[data-popper-placement=top-start], .menu-sub-lg-dropdown.menu.show[data-popper-placement=top-end], .menu-sub-lg-dropdown.show[data-popper-placement][data-popper-placement=top], .menu-sub-lg-dropdown.show[data-popper-placement][data-popper-placement=top-start], .menu-sub-lg-dropdown.show[data-popper-placement][data-popper-placement=top-end] {
    animation: menu-sub-dropdown-animation-fade-in 0.3s ease 1, menu-sub-dropdown-animation-move-down 0.3s ease 1;
  }

  .menu-sub-lg-accordion {
    display: none;
  }
  .show:not(.menu-dropdown) > .menu-sub-lg-accordion, .menu-sub-lg-accordion.show {
    display: flex;
  }

  .menu-lg-inline {
    display: flex;
  }

  .menu-lg-fit > .menu-item > .menu-content,
.menu-lg-fit > .menu-item > .menu-link {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .menu-lg-column {
    flex-direction: column;
    width: 100%;
  }

  .menu-lg-row {
    flex-direction: row;
  }
  .menu-lg-row > .menu-item {
    display: flex;
    align-items: center;
  }
  .menu-lg-row > .menu-item > .menu-link .menu-arrow:after {
    transform: rotateZ(90deg) /*rtl:ignore*/;
    transition: transform 0.3s ease;
  }
  [direction=rtl] .menu-lg-row > .menu-item > .menu-link .menu-arrow:after {
    transform: rotateZ(-90deg) /*rtl:ignore*/;
  }

  .menu-lg-rounded .menu-link {
    border-radius: 0.475rem;
  }

  .menu-lg-pill .menu-link {
    border-radius: 50px;
  }

  .menu-lg-rounded-0 .menu-link {
    border-radius: 0 !important;
  }
}
@media (min-width: 1200px) {
  .menu-item.menu-xl-accordion.showing:not(.menu-dropdown) > .menu-link .menu-arrow:after, .menu-item.menu-xl-accordion.show:not(.hiding):not(.menu-dropdown) > .menu-link .menu-arrow:after {
    transform: rotateZ(90deg) /*rtl:ignore*/;
    transition: transform 0.3s ease;
  }
  [direction=rtl] .menu-item.menu-xl-accordion.showing:not(.menu-dropdown) > .menu-link .menu-arrow:after, [direction=rtl] .menu-item.menu-xl-accordion.show:not(.hiding):not(.menu-dropdown) > .menu-link .menu-arrow:after {
    transform: rotateZ(-90deg) /*rtl:ignore*/;
  }

  .menu-sub-xl-dropdown {
    display: none;
    border-radius: 0.475rem;
    background-color: #ffffff;
    box-shadow: 0px 0px 50px 0px rgba(82, 63, 105, 0.15);
    z-index: 105;
  }
  .show.menu-dropdown > .menu-sub-xl-dropdown, .menu-sub-xl-dropdown.menu.show, .menu-sub-xl-dropdown.show[data-popper-placement] {
    display: flex;
    animation: menu-sub-dropdown-animation-fade-in 0.3s ease 1, menu-sub-dropdown-animation-move-up 0.3s ease 1;
  }
  .show.menu-dropdown > .menu-sub-xl-dropdown[data-popper-placement=top], .show.menu-dropdown > .menu-sub-xl-dropdown[data-popper-placement=top-start], .show.menu-dropdown > .menu-sub-xl-dropdown[data-popper-placement=top-end], .menu-sub-xl-dropdown.menu.show[data-popper-placement=top], .menu-sub-xl-dropdown.menu.show[data-popper-placement=top-start], .menu-sub-xl-dropdown.menu.show[data-popper-placement=top-end], .menu-sub-xl-dropdown.show[data-popper-placement][data-popper-placement=top], .menu-sub-xl-dropdown.show[data-popper-placement][data-popper-placement=top-start], .menu-sub-xl-dropdown.show[data-popper-placement][data-popper-placement=top-end] {
    animation: menu-sub-dropdown-animation-fade-in 0.3s ease 1, menu-sub-dropdown-animation-move-down 0.3s ease 1;
  }

  .menu-sub-xl-accordion {
    display: none;
  }
  .show:not(.menu-dropdown) > .menu-sub-xl-accordion, .menu-sub-xl-accordion.show {
    display: flex;
  }

  .menu-xl-inline {
    display: flex;
  }

  .menu-xl-fit > .menu-item > .menu-content,
.menu-xl-fit > .menu-item > .menu-link {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .menu-xl-column {
    flex-direction: column;
    width: 100%;
  }

  .menu-xl-row {
    flex-direction: row;
  }
  .menu-xl-row > .menu-item {
    display: flex;
    align-items: center;
  }
  .menu-xl-row > .menu-item > .menu-link .menu-arrow:after {
    transform: rotateZ(90deg) /*rtl:ignore*/;
    transition: transform 0.3s ease;
  }
  [direction=rtl] .menu-xl-row > .menu-item > .menu-link .menu-arrow:after {
    transform: rotateZ(-90deg) /*rtl:ignore*/;
  }

  .menu-xl-rounded .menu-link {
    border-radius: 0.475rem;
  }

  .menu-xl-pill .menu-link {
    border-radius: 50px;
  }

  .menu-xl-rounded-0 .menu-link {
    border-radius: 0 !important;
  }
}
@media (min-width: 1400px) {
  .menu-item.menu-xxl-accordion.showing:not(.menu-dropdown) > .menu-link .menu-arrow:after, .menu-item.menu-xxl-accordion.show:not(.hiding):not(.menu-dropdown) > .menu-link .menu-arrow:after {
    transform: rotateZ(90deg) /*rtl:ignore*/;
    transition: transform 0.3s ease;
  }
  [direction=rtl] .menu-item.menu-xxl-accordion.showing:not(.menu-dropdown) > .menu-link .menu-arrow:after, [direction=rtl] .menu-item.menu-xxl-accordion.show:not(.hiding):not(.menu-dropdown) > .menu-link .menu-arrow:after {
    transform: rotateZ(-90deg) /*rtl:ignore*/;
  }

  .menu-sub-xxl-dropdown {
    display: none;
    border-radius: 0.475rem;
    background-color: #ffffff;
    box-shadow: 0px 0px 50px 0px rgba(82, 63, 105, 0.15);
    z-index: 105;
  }
  .show.menu-dropdown > .menu-sub-xxl-dropdown, .menu-sub-xxl-dropdown.menu.show, .menu-sub-xxl-dropdown.show[data-popper-placement] {
    display: flex;
    animation: menu-sub-dropdown-animation-fade-in 0.3s ease 1, menu-sub-dropdown-animation-move-up 0.3s ease 1;
  }
  .show.menu-dropdown > .menu-sub-xxl-dropdown[data-popper-placement=top], .show.menu-dropdown > .menu-sub-xxl-dropdown[data-popper-placement=top-start], .show.menu-dropdown > .menu-sub-xxl-dropdown[data-popper-placement=top-end], .menu-sub-xxl-dropdown.menu.show[data-popper-placement=top], .menu-sub-xxl-dropdown.menu.show[data-popper-placement=top-start], .menu-sub-xxl-dropdown.menu.show[data-popper-placement=top-end], .menu-sub-xxl-dropdown.show[data-popper-placement][data-popper-placement=top], .menu-sub-xxl-dropdown.show[data-popper-placement][data-popper-placement=top-start], .menu-sub-xxl-dropdown.show[data-popper-placement][data-popper-placement=top-end] {
    animation: menu-sub-dropdown-animation-fade-in 0.3s ease 1, menu-sub-dropdown-animation-move-down 0.3s ease 1;
  }

  .menu-sub-xxl-accordion {
    display: none;
  }
  .show:not(.menu-dropdown) > .menu-sub-xxl-accordion, .menu-sub-xxl-accordion.show {
    display: flex;
  }

  .menu-xxl-inline {
    display: flex;
  }

  .menu-xxl-fit > .menu-item > .menu-content,
.menu-xxl-fit > .menu-item > .menu-link {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .menu-xxl-column {
    flex-direction: column;
    width: 100%;
  }

  .menu-xxl-row {
    flex-direction: row;
  }
  .menu-xxl-row > .menu-item {
    display: flex;
    align-items: center;
  }
  .menu-xxl-row > .menu-item > .menu-link .menu-arrow:after {
    transform: rotateZ(90deg) /*rtl:ignore*/;
    transition: transform 0.3s ease;
  }
  [direction=rtl] .menu-xxl-row > .menu-item > .menu-link .menu-arrow:after {
    transform: rotateZ(-90deg) /*rtl:ignore*/;
  }

  .menu-xxl-rounded .menu-link {
    border-radius: 0.475rem;
  }

  .menu-xxl-pill .menu-link {
    border-radius: 50px;
  }

  .menu-xxl-rounded-0 .menu-link {
    border-radius: 0 !important;
  }
}
@media (max-width: 575.98px) {
  .menu-item.menu-sm-down-accordion.showing:not(.menu-dropdown) > .menu-link .menu-arrow:after, .menu-item.menu-sm-down-accordion.show:not(.hiding):not(.menu-dropdown) > .menu-link .menu-arrow:after {
    transform: rotateZ(90deg) /*rtl:ignore*/;
    transition: transform 0.3s ease;
  }
  [direction=rtl] .menu-item.menu-sm-down-accordion.showing:not(.menu-dropdown) > .menu-link .menu-arrow:after, [direction=rtl] .menu-item.menu-sm-down-accordion.show:not(.hiding):not(.menu-dropdown) > .menu-link .menu-arrow:after {
    transform: rotateZ(-90deg) /*rtl:ignore*/;
  }

  .menu-sub-sm-down-dropdown {
    display: none;
    border-radius: 0.475rem;
    background-color: #ffffff;
    box-shadow: 0px 0px 50px 0px rgba(82, 63, 105, 0.15);
    z-index: 105;
  }
  .show.menu-dropdown > .menu-sub-sm-down-dropdown, .menu-sub-sm-down-dropdown.menu.show, .menu-sub-sm-down-dropdown.show[data-popper-placement] {
    display: flex;
    animation: menu-sub-dropdown-animation-fade-in 0.3s ease 1, menu-sub-dropdown-animation-move-up 0.3s ease 1;
  }
  .show.menu-dropdown > .menu-sub-sm-down-dropdown[data-popper-placement=top], .show.menu-dropdown > .menu-sub-sm-down-dropdown[data-popper-placement=top-start], .show.menu-dropdown > .menu-sub-sm-down-dropdown[data-popper-placement=top-end], .menu-sub-sm-down-dropdown.menu.show[data-popper-placement=top], .menu-sub-sm-down-dropdown.menu.show[data-popper-placement=top-start], .menu-sub-sm-down-dropdown.menu.show[data-popper-placement=top-end], .menu-sub-sm-down-dropdown.show[data-popper-placement][data-popper-placement=top], .menu-sub-sm-down-dropdown.show[data-popper-placement][data-popper-placement=top-start], .menu-sub-sm-down-dropdown.show[data-popper-placement][data-popper-placement=top-end] {
    animation: menu-sub-dropdown-animation-fade-in 0.3s ease 1, menu-sub-dropdown-animation-move-down 0.3s ease 1;
  }

  .menu-sub-sm-down-accordion {
    display: none;
  }
  .show:not(.menu-dropdown) > .menu-sub-sm-down-accordion, .menu-sub-sm-down-accordion.show {
    display: flex;
  }

  .menu-sm-down-inline {
    display: flex;
  }

  .menu-sm-down-fit > .menu-item > .menu-content,
.menu-sm-down-fit > .menu-item > .menu-link {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .menu-sm-down-column {
    flex-direction: column;
    width: 100%;
  }

  .menu-sm-down-row {
    flex-direction: row;
  }
  .menu-sm-down-row > .menu-item {
    display: flex;
    align-items: center;
  }
  .menu-sm-down-row > .menu-item > .menu-link .menu-arrow:after {
    transform: rotateZ(90deg) /*rtl:ignore*/;
    transition: transform 0.3s ease;
  }
  [direction=rtl] .menu-sm-down-row > .menu-item > .menu-link .menu-arrow:after {
    transform: rotateZ(-90deg) /*rtl:ignore*/;
  }

  .menu-sm-down-rounded .menu-link {
    border-radius: 0.475rem;
  }

  .menu-sm-down-pill .menu-link {
    border-radius: 50px;
  }

  .menu-sm-down-rounded-0 .menu-link {
    border-radius: 0 !important;
  }
}
@media (max-width: 767.98px) {
  .menu-item.menu-md-down-accordion.showing:not(.menu-dropdown) > .menu-link .menu-arrow:after, .menu-item.menu-md-down-accordion.show:not(.hiding):not(.menu-dropdown) > .menu-link .menu-arrow:after {
    transform: rotateZ(90deg) /*rtl:ignore*/;
    transition: transform 0.3s ease;
  }
  [direction=rtl] .menu-item.menu-md-down-accordion.showing:not(.menu-dropdown) > .menu-link .menu-arrow:after, [direction=rtl] .menu-item.menu-md-down-accordion.show:not(.hiding):not(.menu-dropdown) > .menu-link .menu-arrow:after {
    transform: rotateZ(-90deg) /*rtl:ignore*/;
  }

  .menu-sub-md-down-dropdown {
    display: none;
    border-radius: 0.475rem;
    background-color: #ffffff;
    box-shadow: 0px 0px 50px 0px rgba(82, 63, 105, 0.15);
    z-index: 105;
  }
  .show.menu-dropdown > .menu-sub-md-down-dropdown, .menu-sub-md-down-dropdown.menu.show, .menu-sub-md-down-dropdown.show[data-popper-placement] {
    display: flex;
    animation: menu-sub-dropdown-animation-fade-in 0.3s ease 1, menu-sub-dropdown-animation-move-up 0.3s ease 1;
  }
  .show.menu-dropdown > .menu-sub-md-down-dropdown[data-popper-placement=top], .show.menu-dropdown > .menu-sub-md-down-dropdown[data-popper-placement=top-start], .show.menu-dropdown > .menu-sub-md-down-dropdown[data-popper-placement=top-end], .menu-sub-md-down-dropdown.menu.show[data-popper-placement=top], .menu-sub-md-down-dropdown.menu.show[data-popper-placement=top-start], .menu-sub-md-down-dropdown.menu.show[data-popper-placement=top-end], .menu-sub-md-down-dropdown.show[data-popper-placement][data-popper-placement=top], .menu-sub-md-down-dropdown.show[data-popper-placement][data-popper-placement=top-start], .menu-sub-md-down-dropdown.show[data-popper-placement][data-popper-placement=top-end] {
    animation: menu-sub-dropdown-animation-fade-in 0.3s ease 1, menu-sub-dropdown-animation-move-down 0.3s ease 1;
  }

  .menu-sub-md-down-accordion {
    display: none;
  }
  .show:not(.menu-dropdown) > .menu-sub-md-down-accordion, .menu-sub-md-down-accordion.show {
    display: flex;
  }

  .menu-md-down-inline {
    display: flex;
  }

  .menu-md-down-fit > .menu-item > .menu-content,
.menu-md-down-fit > .menu-item > .menu-link {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .menu-md-down-column {
    flex-direction: column;
    width: 100%;
  }

  .menu-md-down-row {
    flex-direction: row;
  }
  .menu-md-down-row > .menu-item {
    display: flex;
    align-items: center;
  }
  .menu-md-down-row > .menu-item > .menu-link .menu-arrow:after {
    transform: rotateZ(90deg) /*rtl:ignore*/;
    transition: transform 0.3s ease;
  }
  [direction=rtl] .menu-md-down-row > .menu-item > .menu-link .menu-arrow:after {
    transform: rotateZ(-90deg) /*rtl:ignore*/;
  }

  .menu-md-down-rounded .menu-link {
    border-radius: 0.475rem;
  }

  .menu-md-down-pill .menu-link {
    border-radius: 50px;
  }

  .menu-md-down-rounded-0 .menu-link {
    border-radius: 0 !important;
  }
}
@media (max-width: 991.98px) {
  .menu-item.menu-lg-down-accordion.showing:not(.menu-dropdown) > .menu-link .menu-arrow:after, .menu-item.menu-lg-down-accordion.show:not(.hiding):not(.menu-dropdown) > .menu-link .menu-arrow:after {
    transform: rotateZ(90deg) /*rtl:ignore*/;
    transition: transform 0.3s ease;
  }
  [direction=rtl] .menu-item.menu-lg-down-accordion.showing:not(.menu-dropdown) > .menu-link .menu-arrow:after, [direction=rtl] .menu-item.menu-lg-down-accordion.show:not(.hiding):not(.menu-dropdown) > .menu-link .menu-arrow:after {
    transform: rotateZ(-90deg) /*rtl:ignore*/;
  }

  .menu-sub-lg-down-dropdown {
    display: none;
    border-radius: 0.475rem;
    background-color: #ffffff;
    box-shadow: 0px 0px 50px 0px rgba(82, 63, 105, 0.15);
    z-index: 105;
  }
  .show.menu-dropdown > .menu-sub-lg-down-dropdown, .menu-sub-lg-down-dropdown.menu.show, .menu-sub-lg-down-dropdown.show[data-popper-placement] {
    display: flex;
    animation: menu-sub-dropdown-animation-fade-in 0.3s ease 1, menu-sub-dropdown-animation-move-up 0.3s ease 1;
  }
  .show.menu-dropdown > .menu-sub-lg-down-dropdown[data-popper-placement=top], .show.menu-dropdown > .menu-sub-lg-down-dropdown[data-popper-placement=top-start], .show.menu-dropdown > .menu-sub-lg-down-dropdown[data-popper-placement=top-end], .menu-sub-lg-down-dropdown.menu.show[data-popper-placement=top], .menu-sub-lg-down-dropdown.menu.show[data-popper-placement=top-start], .menu-sub-lg-down-dropdown.menu.show[data-popper-placement=top-end], .menu-sub-lg-down-dropdown.show[data-popper-placement][data-popper-placement=top], .menu-sub-lg-down-dropdown.show[data-popper-placement][data-popper-placement=top-start], .menu-sub-lg-down-dropdown.show[data-popper-placement][data-popper-placement=top-end] {
    animation: menu-sub-dropdown-animation-fade-in 0.3s ease 1, menu-sub-dropdown-animation-move-down 0.3s ease 1;
  }

  .menu-sub-lg-down-accordion {
    display: none;
  }
  .show:not(.menu-dropdown) > .menu-sub-lg-down-accordion, .menu-sub-lg-down-accordion.show {
    display: flex;
  }

  .menu-lg-down-inline {
    display: flex;
  }

  .menu-lg-down-fit > .menu-item > .menu-content,
.menu-lg-down-fit > .menu-item > .menu-link {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .menu-lg-down-column {
    flex-direction: column;
    width: 100%;
  }

  .menu-lg-down-row {
    flex-direction: row;
  }
  .menu-lg-down-row > .menu-item {
    display: flex;
    align-items: center;
  }
  .menu-lg-down-row > .menu-item > .menu-link .menu-arrow:after {
    transform: rotateZ(90deg) /*rtl:ignore*/;
    transition: transform 0.3s ease;
  }
  [direction=rtl] .menu-lg-down-row > .menu-item > .menu-link .menu-arrow:after {
    transform: rotateZ(-90deg) /*rtl:ignore*/;
  }

  .menu-lg-down-rounded .menu-link {
    border-radius: 0.475rem;
  }

  .menu-lg-down-pill .menu-link {
    border-radius: 50px;
  }

  .menu-lg-down-rounded-0 .menu-link {
    border-radius: 0 !important;
  }
}
@media (max-width: 1199.98px) {
  .menu-item.menu-xl-down-accordion.showing:not(.menu-dropdown) > .menu-link .menu-arrow:after, .menu-item.menu-xl-down-accordion.show:not(.hiding):not(.menu-dropdown) > .menu-link .menu-arrow:after {
    transform: rotateZ(90deg) /*rtl:ignore*/;
    transition: transform 0.3s ease;
  }
  [direction=rtl] .menu-item.menu-xl-down-accordion.showing:not(.menu-dropdown) > .menu-link .menu-arrow:after, [direction=rtl] .menu-item.menu-xl-down-accordion.show:not(.hiding):not(.menu-dropdown) > .menu-link .menu-arrow:after {
    transform: rotateZ(-90deg) /*rtl:ignore*/;
  }

  .menu-sub-xl-down-dropdown {
    display: none;
    border-radius: 0.475rem;
    background-color: #ffffff;
    box-shadow: 0px 0px 50px 0px rgba(82, 63, 105, 0.15);
    z-index: 105;
  }
  .show.menu-dropdown > .menu-sub-xl-down-dropdown, .menu-sub-xl-down-dropdown.menu.show, .menu-sub-xl-down-dropdown.show[data-popper-placement] {
    display: flex;
    animation: menu-sub-dropdown-animation-fade-in 0.3s ease 1, menu-sub-dropdown-animation-move-up 0.3s ease 1;
  }
  .show.menu-dropdown > .menu-sub-xl-down-dropdown[data-popper-placement=top], .show.menu-dropdown > .menu-sub-xl-down-dropdown[data-popper-placement=top-start], .show.menu-dropdown > .menu-sub-xl-down-dropdown[data-popper-placement=top-end], .menu-sub-xl-down-dropdown.menu.show[data-popper-placement=top], .menu-sub-xl-down-dropdown.menu.show[data-popper-placement=top-start], .menu-sub-xl-down-dropdown.menu.show[data-popper-placement=top-end], .menu-sub-xl-down-dropdown.show[data-popper-placement][data-popper-placement=top], .menu-sub-xl-down-dropdown.show[data-popper-placement][data-popper-placement=top-start], .menu-sub-xl-down-dropdown.show[data-popper-placement][data-popper-placement=top-end] {
    animation: menu-sub-dropdown-animation-fade-in 0.3s ease 1, menu-sub-dropdown-animation-move-down 0.3s ease 1;
  }

  .menu-sub-xl-down-accordion {
    display: none;
  }
  .show:not(.menu-dropdown) > .menu-sub-xl-down-accordion, .menu-sub-xl-down-accordion.show {
    display: flex;
  }

  .menu-xl-down-inline {
    display: flex;
  }

  .menu-xl-down-fit > .menu-item > .menu-content,
.menu-xl-down-fit > .menu-item > .menu-link {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .menu-xl-down-column {
    flex-direction: column;
    width: 100%;
  }

  .menu-xl-down-row {
    flex-direction: row;
  }
  .menu-xl-down-row > .menu-item {
    display: flex;
    align-items: center;
  }
  .menu-xl-down-row > .menu-item > .menu-link .menu-arrow:after {
    transform: rotateZ(90deg) /*rtl:ignore*/;
    transition: transform 0.3s ease;
  }
  [direction=rtl] .menu-xl-down-row > .menu-item > .menu-link .menu-arrow:after {
    transform: rotateZ(-90deg) /*rtl:ignore*/;
  }

  .menu-xl-down-rounded .menu-link {
    border-radius: 0.475rem;
  }

  .menu-xl-down-pill .menu-link {
    border-radius: 50px;
  }

  .menu-xl-down-rounded-0 .menu-link {
    border-radius: 0 !important;
  }
}
@media (max-width: 1399.98px) {
  .menu-item.menu-xxl-down-accordion.showing:not(.menu-dropdown) > .menu-link .menu-arrow:after, .menu-item.menu-xxl-down-accordion.show:not(.hiding):not(.menu-dropdown) > .menu-link .menu-arrow:after {
    transform: rotateZ(90deg) /*rtl:ignore*/;
    transition: transform 0.3s ease;
  }
  [direction=rtl] .menu-item.menu-xxl-down-accordion.showing:not(.menu-dropdown) > .menu-link .menu-arrow:after, [direction=rtl] .menu-item.menu-xxl-down-accordion.show:not(.hiding):not(.menu-dropdown) > .menu-link .menu-arrow:after {
    transform: rotateZ(-90deg) /*rtl:ignore*/;
  }

  .menu-sub-xxl-down-dropdown {
    display: none;
    border-radius: 0.475rem;
    background-color: #ffffff;
    box-shadow: 0px 0px 50px 0px rgba(82, 63, 105, 0.15);
    z-index: 105;
  }
  .show.menu-dropdown > .menu-sub-xxl-down-dropdown, .menu-sub-xxl-down-dropdown.menu.show, .menu-sub-xxl-down-dropdown.show[data-popper-placement] {
    display: flex;
    animation: menu-sub-dropdown-animation-fade-in 0.3s ease 1, menu-sub-dropdown-animation-move-up 0.3s ease 1;
  }
  .show.menu-dropdown > .menu-sub-xxl-down-dropdown[data-popper-placement=top], .show.menu-dropdown > .menu-sub-xxl-down-dropdown[data-popper-placement=top-start], .show.menu-dropdown > .menu-sub-xxl-down-dropdown[data-popper-placement=top-end], .menu-sub-xxl-down-dropdown.menu.show[data-popper-placement=top], .menu-sub-xxl-down-dropdown.menu.show[data-popper-placement=top-start], .menu-sub-xxl-down-dropdown.menu.show[data-popper-placement=top-end], .menu-sub-xxl-down-dropdown.show[data-popper-placement][data-popper-placement=top], .menu-sub-xxl-down-dropdown.show[data-popper-placement][data-popper-placement=top-start], .menu-sub-xxl-down-dropdown.show[data-popper-placement][data-popper-placement=top-end] {
    animation: menu-sub-dropdown-animation-fade-in 0.3s ease 1, menu-sub-dropdown-animation-move-down 0.3s ease 1;
  }

  .menu-sub-xxl-down-accordion {
    display: none;
  }
  .show:not(.menu-dropdown) > .menu-sub-xxl-down-accordion, .menu-sub-xxl-down-accordion.show {
    display: flex;
  }

  .menu-xxl-down-inline {
    display: flex;
  }

  .menu-xxl-down-fit > .menu-item > .menu-content,
.menu-xxl-down-fit > .menu-item > .menu-link {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .menu-xxl-down-column {
    flex-direction: column;
    width: 100%;
  }

  .menu-xxl-down-row {
    flex-direction: row;
  }
  .menu-xxl-down-row > .menu-item {
    display: flex;
    align-items: center;
  }
  .menu-xxl-down-row > .menu-item > .menu-link .menu-arrow:after {
    transform: rotateZ(90deg) /*rtl:ignore*/;
    transition: transform 0.3s ease;
  }
  [direction=rtl] .menu-xxl-down-row > .menu-item > .menu-link .menu-arrow:after {
    transform: rotateZ(-90deg) /*rtl:ignore*/;
  }

  .menu-xxl-down-rounded .menu-link {
    border-radius: 0.475rem;
  }

  .menu-xxl-down-pill .menu-link {
    border-radius: 50px;
  }

  .menu-xxl-down-rounded-0 .menu-link {
    border-radius: 0 !important;
  }
}
.menu-sub:not([data-popper-placement]) > .menu-item > .menu-content,
.menu-sub:not([data-popper-placement]) > .menu-item > .menu-link {
  padding-left: calc(1rem + 1rem);
}
.menu-sub:not([data-popper-placement]) > .menu-item > .menu-sub:not([data-popper-placement]) > .menu-item > .menu-content,
.menu-sub:not([data-popper-placement]) > .menu-item > .menu-sub:not([data-popper-placement]) > .menu-item > .menu-link {
  padding-left: calc(2rem + 1rem);
}
.menu-sub:not([data-popper-placement]) > .menu-item > .menu-sub:not([data-popper-placement]) > .menu-item > .menu-sub:not([data-popper-placement]) > .menu-item > .menu-content,
.menu-sub:not([data-popper-placement]) > .menu-item > .menu-sub:not([data-popper-placement]) > .menu-item > .menu-sub:not([data-popper-placement]) > .menu-item > .menu-link {
  padding-left: calc(3rem + 1rem);
}
.menu-sub:not([data-popper-placement]) > .menu-item > .menu-sub:not([data-popper-placement]) > .menu-item > .menu-sub:not([data-popper-placement]) > .menu-item > .menu-sub:not([data-popper-placement]) > .menu-item > .menu-content,
.menu-sub:not([data-popper-placement]) > .menu-item > .menu-sub:not([data-popper-placement]) > .menu-item > .menu-sub:not([data-popper-placement]) > .menu-item > .menu-sub:not([data-popper-placement]) > .menu-item > .menu-link {
  padding-left: calc(4rem + 1rem);
}
.menu-fit .menu-sub:not([data-popper-placement]) > .menu-item > .menu-content,
.menu-fit .menu-sub:not([data-popper-placement]) > .menu-item > .menu-link {
  padding-left: 1rem;
  padding-right: 0;
}
.menu-fit .menu-sub:not([data-popper-placement]) > .menu-item > .menu-sub:not([data-popper-placement]) > .menu-item > .menu-content,
.menu-fit .menu-sub:not([data-popper-placement]) > .menu-item > .menu-sub:not([data-popper-placement]) > .menu-item > .menu-link {
  padding-left: calc(2rem);
  padding-right: 0;
}
.menu-fit .menu-sub:not([data-popper-placement]) > .menu-item > .menu-sub:not([data-popper-placement]) > .menu-item > .menu-sub:not([data-popper-placement]) > .menu-item > .menu-content,
.menu-fit .menu-sub:not([data-popper-placement]) > .menu-item > .menu-sub:not([data-popper-placement]) > .menu-item > .menu-sub:not([data-popper-placement]) > .menu-item > .menu-link {
  padding-left: calc(3rem);
  padding-right: 0;
}
.menu-fit .menu-sub:not([data-popper-placement]) > .menu-item > .menu-sub:not([data-popper-placement]) > .menu-item > .menu-sub:not([data-popper-placement]) > .menu-item > .menu-sub:not([data-popper-placement]) > .menu-item > .menu-content,
.menu-fit .menu-sub:not([data-popper-placement]) > .menu-item > .menu-sub:not([data-popper-placement]) > .menu-item > .menu-sub:not([data-popper-placement]) > .menu-item > .menu-sub:not([data-popper-placement]) > .menu-item > .menu-link {
  padding-left: calc(4rem);
  padding-right: 0;
}

@keyframes menu-sub-dropdown-animation-fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes menu-sub-dropdown-animation-move-up {
  from {
    margin-top: 0.75rem;
  }
  to {
    margin-top: 0;
  }
}
@keyframes menu-sub-dropdown-animation-move-down {
  from {
    margin-bottom: 0.75rem;
  }
  to {
    margin-bottom: 0;
  }
}
.menu-white .menu-item .menu-link {
  color: #ffffff;
}
.menu-white .menu-item .menu-link .menu-title {
  color: #ffffff;
}
.menu-white .menu-item .menu-link .menu-icon i {
  color: #ffffff;
}
.menu-white .menu-item .menu-link .menu-icon .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #ffffff;
}
.menu-white .menu-item .menu-link .menu-icon .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.menu-white .menu-item .menu-link .menu-bullet .bullet {
  background-color: #ffffff;
}
.menu-white .menu-item .menu-link .menu-arrow:after {
  background-repeat: no-repeat;
  background-position: center;
  background-color: transparent;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 9' fill='%23ffffff'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M5.93537 4.57889C6.03839 4.77912 6.0191 5.0363 5.87137 5.21403L2.87153 8.82282C2.68598 9.04603 2.36951 9.06026 2.16468 8.8546C1.95985 8.64893 1.94422 8.30126 2.12977 8.07804L4.80594 4.85863L2.15586 1.93583C1.96104 1.72096 1.96165 1.37314 2.15722 1.15895C2.35279 0.944757 2.66927 0.945311 2.86409 1.16018L5.85194 4.45551C5.8859 4.49296 5.91371 4.53459 5.93537 4.57889Z'/%3e%3c/svg%3e");
}

.menu-title-white .menu-item .menu-link {
  color: #ffffff;
}
.menu-title-white .menu-item .menu-link .menu-title {
  color: #ffffff;
}

.menu-icon-white .menu-item .menu-link .menu-icon i {
  color: #ffffff;
}
.menu-icon-white .menu-item .menu-link .menu-icon .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #ffffff;
}
.menu-icon-white .menu-item .menu-link .menu-icon .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}

.menu-bullet-white .menu-item .menu-link .menu-bullet .bullet {
  background-color: #ffffff;
}

.menu-arrow-white .menu-item .menu-link .menu-arrow:after {
  background-repeat: no-repeat;
  background-position: center;
  background-color: transparent;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 9' fill='%23ffffff'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M5.93537 4.57889C6.03839 4.77912 6.0191 5.0363 5.87137 5.21403L2.87153 8.82282C2.68598 9.04603 2.36951 9.06026 2.16468 8.8546C1.95985 8.64893 1.94422 8.30126 2.12977 8.07804L4.80594 4.85863L2.15586 1.93583C1.96104 1.72096 1.96165 1.37314 2.15722 1.15895C2.35279 0.944757 2.66927 0.945311 2.86409 1.16018L5.85194 4.45551C5.8859 4.49296 5.91371 4.53459 5.93537 4.57889Z'/%3e%3c/svg%3e");
}

.menu-primary .menu-item .menu-link {
  color: #009EF7;
}
.menu-primary .menu-item .menu-link .menu-title {
  color: #009EF7;
}
.menu-primary .menu-item .menu-link .menu-icon i {
  color: #009EF7;
}
.menu-primary .menu-item .menu-link .menu-icon .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #009EF7;
}
.menu-primary .menu-item .menu-link .menu-icon .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.menu-primary .menu-item .menu-link .menu-bullet .bullet {
  background-color: #009EF7;
}
.menu-primary .menu-item .menu-link .menu-arrow:after {
  background-repeat: no-repeat;
  background-position: center;
  background-color: transparent;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 9' fill='%23009EF7'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M5.93537 4.57889C6.03839 4.77912 6.0191 5.0363 5.87137 5.21403L2.87153 8.82282C2.68598 9.04603 2.36951 9.06026 2.16468 8.8546C1.95985 8.64893 1.94422 8.30126 2.12977 8.07804L4.80594 4.85863L2.15586 1.93583C1.96104 1.72096 1.96165 1.37314 2.15722 1.15895C2.35279 0.944757 2.66927 0.945311 2.86409 1.16018L5.85194 4.45551C5.8859 4.49296 5.91371 4.53459 5.93537 4.57889Z'/%3e%3c/svg%3e");
}

.menu-title-primary .menu-item .menu-link {
  color: #009EF7;
}
.menu-title-primary .menu-item .menu-link .menu-title {
  color: #009EF7;
}

.menu-icon-primary .menu-item .menu-link .menu-icon i {
  color: #009EF7;
}
.menu-icon-primary .menu-item .menu-link .menu-icon .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #009EF7;
}
.menu-icon-primary .menu-item .menu-link .menu-icon .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}

.menu-bullet-primary .menu-item .menu-link .menu-bullet .bullet {
  background-color: #009EF7;
}

.menu-arrow-primary .menu-item .menu-link .menu-arrow:after {
  background-repeat: no-repeat;
  background-position: center;
  background-color: transparent;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 9' fill='%23009EF7'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M5.93537 4.57889C6.03839 4.77912 6.0191 5.0363 5.87137 5.21403L2.87153 8.82282C2.68598 9.04603 2.36951 9.06026 2.16468 8.8546C1.95985 8.64893 1.94422 8.30126 2.12977 8.07804L4.80594 4.85863L2.15586 1.93583C1.96104 1.72096 1.96165 1.37314 2.15722 1.15895C2.35279 0.944757 2.66927 0.945311 2.86409 1.16018L5.85194 4.45551C5.8859 4.49296 5.91371 4.53459 5.93537 4.57889Z'/%3e%3c/svg%3e");
}

.menu-secondary .menu-item .menu-link {
  color: #E4E6EF;
}
.menu-secondary .menu-item .menu-link .menu-title {
  color: #E4E6EF;
}
.menu-secondary .menu-item .menu-link .menu-icon i {
  color: #E4E6EF;
}
.menu-secondary .menu-item .menu-link .menu-icon .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #E4E6EF;
}
.menu-secondary .menu-item .menu-link .menu-icon .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.menu-secondary .menu-item .menu-link .menu-bullet .bullet {
  background-color: #E4E6EF;
}
.menu-secondary .menu-item .menu-link .menu-arrow:after {
  background-repeat: no-repeat;
  background-position: center;
  background-color: transparent;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 9' fill='%23E4E6EF'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M5.93537 4.57889C6.03839 4.77912 6.0191 5.0363 5.87137 5.21403L2.87153 8.82282C2.68598 9.04603 2.36951 9.06026 2.16468 8.8546C1.95985 8.64893 1.94422 8.30126 2.12977 8.07804L4.80594 4.85863L2.15586 1.93583C1.96104 1.72096 1.96165 1.37314 2.15722 1.15895C2.35279 0.944757 2.66927 0.945311 2.86409 1.16018L5.85194 4.45551C5.8859 4.49296 5.91371 4.53459 5.93537 4.57889Z'/%3e%3c/svg%3e");
}

.menu-title-secondary .menu-item .menu-link {
  color: #E4E6EF;
}
.menu-title-secondary .menu-item .menu-link .menu-title {
  color: #E4E6EF;
}

.menu-icon-secondary .menu-item .menu-link .menu-icon i {
  color: #E4E6EF;
}
.menu-icon-secondary .menu-item .menu-link .menu-icon .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #E4E6EF;
}
.menu-icon-secondary .menu-item .menu-link .menu-icon .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}

.menu-bullet-secondary .menu-item .menu-link .menu-bullet .bullet {
  background-color: #E4E6EF;
}

.menu-arrow-secondary .menu-item .menu-link .menu-arrow:after {
  background-repeat: no-repeat;
  background-position: center;
  background-color: transparent;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 9' fill='%23E4E6EF'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M5.93537 4.57889C6.03839 4.77912 6.0191 5.0363 5.87137 5.21403L2.87153 8.82282C2.68598 9.04603 2.36951 9.06026 2.16468 8.8546C1.95985 8.64893 1.94422 8.30126 2.12977 8.07804L4.80594 4.85863L2.15586 1.93583C1.96104 1.72096 1.96165 1.37314 2.15722 1.15895C2.35279 0.944757 2.66927 0.945311 2.86409 1.16018L5.85194 4.45551C5.8859 4.49296 5.91371 4.53459 5.93537 4.57889Z'/%3e%3c/svg%3e");
}

.menu-light .menu-item .menu-link {
  color: #F5F8FA;
}
.menu-light .menu-item .menu-link .menu-title {
  color: #F5F8FA;
}
.menu-light .menu-item .menu-link .menu-icon i {
  color: #F5F8FA;
}
.menu-light .menu-item .menu-link .menu-icon .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #F5F8FA;
}
.menu-light .menu-item .menu-link .menu-icon .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.menu-light .menu-item .menu-link .menu-bullet .bullet {
  background-color: #F5F8FA;
}
.menu-light .menu-item .menu-link .menu-arrow:after {
  background-repeat: no-repeat;
  background-position: center;
  background-color: transparent;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 9' fill='%23F5F8FA'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M5.93537 4.57889C6.03839 4.77912 6.0191 5.0363 5.87137 5.21403L2.87153 8.82282C2.68598 9.04603 2.36951 9.06026 2.16468 8.8546C1.95985 8.64893 1.94422 8.30126 2.12977 8.07804L4.80594 4.85863L2.15586 1.93583C1.96104 1.72096 1.96165 1.37314 2.15722 1.15895C2.35279 0.944757 2.66927 0.945311 2.86409 1.16018L5.85194 4.45551C5.8859 4.49296 5.91371 4.53459 5.93537 4.57889Z'/%3e%3c/svg%3e");
}

.menu-title-light .menu-item .menu-link {
  color: #F5F8FA;
}
.menu-title-light .menu-item .menu-link .menu-title {
  color: #F5F8FA;
}

.menu-icon-light .menu-item .menu-link .menu-icon i {
  color: #F5F8FA;
}
.menu-icon-light .menu-item .menu-link .menu-icon .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #F5F8FA;
}
.menu-icon-light .menu-item .menu-link .menu-icon .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}

.menu-bullet-light .menu-item .menu-link .menu-bullet .bullet {
  background-color: #F5F8FA;
}

.menu-arrow-light .menu-item .menu-link .menu-arrow:after {
  background-repeat: no-repeat;
  background-position: center;
  background-color: transparent;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 9' fill='%23F5F8FA'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M5.93537 4.57889C6.03839 4.77912 6.0191 5.0363 5.87137 5.21403L2.87153 8.82282C2.68598 9.04603 2.36951 9.06026 2.16468 8.8546C1.95985 8.64893 1.94422 8.30126 2.12977 8.07804L4.80594 4.85863L2.15586 1.93583C1.96104 1.72096 1.96165 1.37314 2.15722 1.15895C2.35279 0.944757 2.66927 0.945311 2.86409 1.16018L5.85194 4.45551C5.8859 4.49296 5.91371 4.53459 5.93537 4.57889Z'/%3e%3c/svg%3e");
}

.menu-success .menu-item .menu-link {
  color: #50CD89;
}
.menu-success .menu-item .menu-link .menu-title {
  color: #50CD89;
}
.menu-success .menu-item .menu-link .menu-icon i {
  color: #50CD89;
}
.menu-success .menu-item .menu-link .menu-icon .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #50CD89;
}
.menu-success .menu-item .menu-link .menu-icon .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.menu-success .menu-item .menu-link .menu-bullet .bullet {
  background-color: #50CD89;
}
.menu-success .menu-item .menu-link .menu-arrow:after {
  background-repeat: no-repeat;
  background-position: center;
  background-color: transparent;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 9' fill='%2350CD89'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M5.93537 4.57889C6.03839 4.77912 6.0191 5.0363 5.87137 5.21403L2.87153 8.82282C2.68598 9.04603 2.36951 9.06026 2.16468 8.8546C1.95985 8.64893 1.94422 8.30126 2.12977 8.07804L4.80594 4.85863L2.15586 1.93583C1.96104 1.72096 1.96165 1.37314 2.15722 1.15895C2.35279 0.944757 2.66927 0.945311 2.86409 1.16018L5.85194 4.45551C5.8859 4.49296 5.91371 4.53459 5.93537 4.57889Z'/%3e%3c/svg%3e");
}

.menu-title-success .menu-item .menu-link {
  color: #50CD89;
}
.menu-title-success .menu-item .menu-link .menu-title {
  color: #50CD89;
}

.menu-icon-success .menu-item .menu-link .menu-icon i {
  color: #50CD89;
}
.menu-icon-success .menu-item .menu-link .menu-icon .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #50CD89;
}
.menu-icon-success .menu-item .menu-link .menu-icon .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}

.menu-bullet-success .menu-item .menu-link .menu-bullet .bullet {
  background-color: #50CD89;
}

.menu-arrow-success .menu-item .menu-link .menu-arrow:after {
  background-repeat: no-repeat;
  background-position: center;
  background-color: transparent;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 9' fill='%2350CD89'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M5.93537 4.57889C6.03839 4.77912 6.0191 5.0363 5.87137 5.21403L2.87153 8.82282C2.68598 9.04603 2.36951 9.06026 2.16468 8.8546C1.95985 8.64893 1.94422 8.30126 2.12977 8.07804L4.80594 4.85863L2.15586 1.93583C1.96104 1.72096 1.96165 1.37314 2.15722 1.15895C2.35279 0.944757 2.66927 0.945311 2.86409 1.16018L5.85194 4.45551C5.8859 4.49296 5.91371 4.53459 5.93537 4.57889Z'/%3e%3c/svg%3e");
}

.menu-info .menu-item .menu-link {
  color: #7239EA;
}
.menu-info .menu-item .menu-link .menu-title {
  color: #7239EA;
}
.menu-info .menu-item .menu-link .menu-icon i {
  color: #7239EA;
}
.menu-info .menu-item .menu-link .menu-icon .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #7239EA;
}
.menu-info .menu-item .menu-link .menu-icon .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.menu-info .menu-item .menu-link .menu-bullet .bullet {
  background-color: #7239EA;
}
.menu-info .menu-item .menu-link .menu-arrow:after {
  background-repeat: no-repeat;
  background-position: center;
  background-color: transparent;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 9' fill='%237239EA'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M5.93537 4.57889C6.03839 4.77912 6.0191 5.0363 5.87137 5.21403L2.87153 8.82282C2.68598 9.04603 2.36951 9.06026 2.16468 8.8546C1.95985 8.64893 1.94422 8.30126 2.12977 8.07804L4.80594 4.85863L2.15586 1.93583C1.96104 1.72096 1.96165 1.37314 2.15722 1.15895C2.35279 0.944757 2.66927 0.945311 2.86409 1.16018L5.85194 4.45551C5.8859 4.49296 5.91371 4.53459 5.93537 4.57889Z'/%3e%3c/svg%3e");
}

.menu-title-info .menu-item .menu-link {
  color: #7239EA;
}
.menu-title-info .menu-item .menu-link .menu-title {
  color: #7239EA;
}

.menu-icon-info .menu-item .menu-link .menu-icon i {
  color: #7239EA;
}
.menu-icon-info .menu-item .menu-link .menu-icon .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #7239EA;
}
.menu-icon-info .menu-item .menu-link .menu-icon .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}

.menu-bullet-info .menu-item .menu-link .menu-bullet .bullet {
  background-color: #7239EA;
}

.menu-arrow-info .menu-item .menu-link .menu-arrow:after {
  background-repeat: no-repeat;
  background-position: center;
  background-color: transparent;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 9' fill='%237239EA'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M5.93537 4.57889C6.03839 4.77912 6.0191 5.0363 5.87137 5.21403L2.87153 8.82282C2.68598 9.04603 2.36951 9.06026 2.16468 8.8546C1.95985 8.64893 1.94422 8.30126 2.12977 8.07804L4.80594 4.85863L2.15586 1.93583C1.96104 1.72096 1.96165 1.37314 2.15722 1.15895C2.35279 0.944757 2.66927 0.945311 2.86409 1.16018L5.85194 4.45551C5.8859 4.49296 5.91371 4.53459 5.93537 4.57889Z'/%3e%3c/svg%3e");
}

.menu-warning .menu-item .menu-link {
  color: #FFC700;
}
.menu-warning .menu-item .menu-link .menu-title {
  color: #FFC700;
}
.menu-warning .menu-item .menu-link .menu-icon i {
  color: #FFC700;
}
.menu-warning .menu-item .menu-link .menu-icon .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #FFC700;
}
.menu-warning .menu-item .menu-link .menu-icon .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.menu-warning .menu-item .menu-link .menu-bullet .bullet {
  background-color: #FFC700;
}
.menu-warning .menu-item .menu-link .menu-arrow:after {
  background-repeat: no-repeat;
  background-position: center;
  background-color: transparent;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 9' fill='%23FFC700'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M5.93537 4.57889C6.03839 4.77912 6.0191 5.0363 5.87137 5.21403L2.87153 8.82282C2.68598 9.04603 2.36951 9.06026 2.16468 8.8546C1.95985 8.64893 1.94422 8.30126 2.12977 8.07804L4.80594 4.85863L2.15586 1.93583C1.96104 1.72096 1.96165 1.37314 2.15722 1.15895C2.35279 0.944757 2.66927 0.945311 2.86409 1.16018L5.85194 4.45551C5.8859 4.49296 5.91371 4.53459 5.93537 4.57889Z'/%3e%3c/svg%3e");
}

.menu-title-warning .menu-item .menu-link {
  color: #FFC700;
}
.menu-title-warning .menu-item .menu-link .menu-title {
  color: #FFC700;
}

.menu-icon-warning .menu-item .menu-link .menu-icon i {
  color: #FFC700;
}
.menu-icon-warning .menu-item .menu-link .menu-icon .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #FFC700;
}
.menu-icon-warning .menu-item .menu-link .menu-icon .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}

.menu-bullet-warning .menu-item .menu-link .menu-bullet .bullet {
  background-color: #FFC700;
}

.menu-arrow-warning .menu-item .menu-link .menu-arrow:after {
  background-repeat: no-repeat;
  background-position: center;
  background-color: transparent;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 9' fill='%23FFC700'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M5.93537 4.57889C6.03839 4.77912 6.0191 5.0363 5.87137 5.21403L2.87153 8.82282C2.68598 9.04603 2.36951 9.06026 2.16468 8.8546C1.95985 8.64893 1.94422 8.30126 2.12977 8.07804L4.80594 4.85863L2.15586 1.93583C1.96104 1.72096 1.96165 1.37314 2.15722 1.15895C2.35279 0.944757 2.66927 0.945311 2.86409 1.16018L5.85194 4.45551C5.8859 4.49296 5.91371 4.53459 5.93537 4.57889Z'/%3e%3c/svg%3e");
}

.menu-danger .menu-item .menu-link {
  color: #F1416C;
}
.menu-danger .menu-item .menu-link .menu-title {
  color: #F1416C;
}
.menu-danger .menu-item .menu-link .menu-icon i {
  color: #F1416C;
}
.menu-danger .menu-item .menu-link .menu-icon .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #F1416C;
}
.menu-danger .menu-item .menu-link .menu-icon .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.menu-danger .menu-item .menu-link .menu-bullet .bullet {
  background-color: #F1416C;
}
.menu-danger .menu-item .menu-link .menu-arrow:after {
  background-repeat: no-repeat;
  background-position: center;
  background-color: transparent;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 9' fill='%23F1416C'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M5.93537 4.57889C6.03839 4.77912 6.0191 5.0363 5.87137 5.21403L2.87153 8.82282C2.68598 9.04603 2.36951 9.06026 2.16468 8.8546C1.95985 8.64893 1.94422 8.30126 2.12977 8.07804L4.80594 4.85863L2.15586 1.93583C1.96104 1.72096 1.96165 1.37314 2.15722 1.15895C2.35279 0.944757 2.66927 0.945311 2.86409 1.16018L5.85194 4.45551C5.8859 4.49296 5.91371 4.53459 5.93537 4.57889Z'/%3e%3c/svg%3e");
}

.menu-title-danger .menu-item .menu-link {
  color: #F1416C;
}
.menu-title-danger .menu-item .menu-link .menu-title {
  color: #F1416C;
}

.menu-icon-danger .menu-item .menu-link .menu-icon i {
  color: #F1416C;
}
.menu-icon-danger .menu-item .menu-link .menu-icon .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #F1416C;
}
.menu-icon-danger .menu-item .menu-link .menu-icon .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}

.menu-bullet-danger .menu-item .menu-link .menu-bullet .bullet {
  background-color: #F1416C;
}

.menu-arrow-danger .menu-item .menu-link .menu-arrow:after {
  background-repeat: no-repeat;
  background-position: center;
  background-color: transparent;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 9' fill='%23F1416C'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M5.93537 4.57889C6.03839 4.77912 6.0191 5.0363 5.87137 5.21403L2.87153 8.82282C2.68598 9.04603 2.36951 9.06026 2.16468 8.8546C1.95985 8.64893 1.94422 8.30126 2.12977 8.07804L4.80594 4.85863L2.15586 1.93583C1.96104 1.72096 1.96165 1.37314 2.15722 1.15895C2.35279 0.944757 2.66927 0.945311 2.86409 1.16018L5.85194 4.45551C5.8859 4.49296 5.91371 4.53459 5.93537 4.57889Z'/%3e%3c/svg%3e");
}

.menu-dark .menu-item .menu-link {
  color: #181C32;
}
.menu-dark .menu-item .menu-link .menu-title {
  color: #181C32;
}
.menu-dark .menu-item .menu-link .menu-icon i {
  color: #181C32;
}
.menu-dark .menu-item .menu-link .menu-icon .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #181C32;
}
.menu-dark .menu-item .menu-link .menu-icon .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.menu-dark .menu-item .menu-link .menu-bullet .bullet {
  background-color: #181C32;
}
.menu-dark .menu-item .menu-link .menu-arrow:after {
  background-repeat: no-repeat;
  background-position: center;
  background-color: transparent;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 9' fill='%23181C32'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M5.93537 4.57889C6.03839 4.77912 6.0191 5.0363 5.87137 5.21403L2.87153 8.82282C2.68598 9.04603 2.36951 9.06026 2.16468 8.8546C1.95985 8.64893 1.94422 8.30126 2.12977 8.07804L4.80594 4.85863L2.15586 1.93583C1.96104 1.72096 1.96165 1.37314 2.15722 1.15895C2.35279 0.944757 2.66927 0.945311 2.86409 1.16018L5.85194 4.45551C5.8859 4.49296 5.91371 4.53459 5.93537 4.57889Z'/%3e%3c/svg%3e");
}

.menu-title-dark .menu-item .menu-link {
  color: #181C32;
}
.menu-title-dark .menu-item .menu-link .menu-title {
  color: #181C32;
}

.menu-icon-dark .menu-item .menu-link .menu-icon i {
  color: #181C32;
}
.menu-icon-dark .menu-item .menu-link .menu-icon .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #181C32;
}
.menu-icon-dark .menu-item .menu-link .menu-icon .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}

.menu-bullet-dark .menu-item .menu-link .menu-bullet .bullet {
  background-color: #181C32;
}

.menu-arrow-dark .menu-item .menu-link .menu-arrow:after {
  background-repeat: no-repeat;
  background-position: center;
  background-color: transparent;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 9' fill='%23181C32'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M5.93537 4.57889C6.03839 4.77912 6.0191 5.0363 5.87137 5.21403L2.87153 8.82282C2.68598 9.04603 2.36951 9.06026 2.16468 8.8546C1.95985 8.64893 1.94422 8.30126 2.12977 8.07804L4.80594 4.85863L2.15586 1.93583C1.96104 1.72096 1.96165 1.37314 2.15722 1.15895C2.35279 0.944757 2.66927 0.945311 2.86409 1.16018L5.85194 4.45551C5.8859 4.49296 5.91371 4.53459 5.93537 4.57889Z'/%3e%3c/svg%3e");
}

.menu-muted .menu-item .menu-link {
  color: #A1A5B7;
}
.menu-muted .menu-item .menu-link .menu-title {
  color: #A1A5B7;
}
.menu-muted .menu-item .menu-link .menu-icon i {
  color: #A1A5B7;
}
.menu-muted .menu-item .menu-link .menu-icon .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #A1A5B7;
}
.menu-muted .menu-item .menu-link .menu-icon .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.menu-muted .menu-item .menu-link .menu-bullet .bullet {
  background-color: #A1A5B7;
}
.menu-muted .menu-item .menu-link .menu-arrow:after {
  background-repeat: no-repeat;
  background-position: center;
  background-color: transparent;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 9' fill='%23A1A5B7'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M5.93537 4.57889C6.03839 4.77912 6.0191 5.0363 5.87137 5.21403L2.87153 8.82282C2.68598 9.04603 2.36951 9.06026 2.16468 8.8546C1.95985 8.64893 1.94422 8.30126 2.12977 8.07804L4.80594 4.85863L2.15586 1.93583C1.96104 1.72096 1.96165 1.37314 2.15722 1.15895C2.35279 0.944757 2.66927 0.945311 2.86409 1.16018L5.85194 4.45551C5.8859 4.49296 5.91371 4.53459 5.93537 4.57889Z'/%3e%3c/svg%3e");
}

.menu-title-muted .menu-item .menu-link {
  color: #A1A5B7;
}
.menu-title-muted .menu-item .menu-link .menu-title {
  color: #A1A5B7;
}

.menu-icon-muted .menu-item .menu-link .menu-icon i {
  color: #A1A5B7;
}
.menu-icon-muted .menu-item .menu-link .menu-icon .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #A1A5B7;
}
.menu-icon-muted .menu-item .menu-link .menu-icon .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}

.menu-bullet-muted .menu-item .menu-link .menu-bullet .bullet {
  background-color: #A1A5B7;
}

.menu-arrow-muted .menu-item .menu-link .menu-arrow:after {
  background-repeat: no-repeat;
  background-position: center;
  background-color: transparent;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 9' fill='%23A1A5B7'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M5.93537 4.57889C6.03839 4.77912 6.0191 5.0363 5.87137 5.21403L2.87153 8.82282C2.68598 9.04603 2.36951 9.06026 2.16468 8.8546C1.95985 8.64893 1.94422 8.30126 2.12977 8.07804L4.80594 4.85863L2.15586 1.93583C1.96104 1.72096 1.96165 1.37314 2.15722 1.15895C2.35279 0.944757 2.66927 0.945311 2.86409 1.16018L5.85194 4.45551C5.8859 4.49296 5.91371 4.53459 5.93537 4.57889Z'/%3e%3c/svg%3e");
}

.menu-gray-100 .menu-item .menu-link {
  color: #F5F8FA;
}
.menu-gray-100 .menu-item .menu-link .menu-title {
  color: #F5F8FA;
}
.menu-gray-100 .menu-item .menu-link .menu-icon i {
  color: #F5F8FA;
}
.menu-gray-100 .menu-item .menu-link .menu-icon .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #F5F8FA;
}
.menu-gray-100 .menu-item .menu-link .menu-icon .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.menu-gray-100 .menu-item .menu-link .menu-bullet .bullet {
  background-color: #F5F8FA;
}
.menu-gray-100 .menu-item .menu-link .menu-arrow:after {
  background-repeat: no-repeat;
  background-position: center;
  background-color: transparent;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 9' fill='%23F5F8FA'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M5.93537 4.57889C6.03839 4.77912 6.0191 5.0363 5.87137 5.21403L2.87153 8.82282C2.68598 9.04603 2.36951 9.06026 2.16468 8.8546C1.95985 8.64893 1.94422 8.30126 2.12977 8.07804L4.80594 4.85863L2.15586 1.93583C1.96104 1.72096 1.96165 1.37314 2.15722 1.15895C2.35279 0.944757 2.66927 0.945311 2.86409 1.16018L5.85194 4.45551C5.8859 4.49296 5.91371 4.53459 5.93537 4.57889Z'/%3e%3c/svg%3e");
}

.menu-title-gray-100 .menu-item .menu-link {
  color: #F5F8FA;
}
.menu-title-gray-100 .menu-item .menu-link .menu-title {
  color: #F5F8FA;
}

.menu-icon-gray-100 .menu-item .menu-link .menu-icon i {
  color: #F5F8FA;
}
.menu-icon-gray-100 .menu-item .menu-link .menu-icon .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #F5F8FA;
}
.menu-icon-gray-100 .menu-item .menu-link .menu-icon .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}

.menu-bullet-gray-100 .menu-item .menu-link .menu-bullet .bullet {
  background-color: #F5F8FA;
}

.menu-arrow-gray-100 .menu-item .menu-link .menu-arrow:after {
  background-repeat: no-repeat;
  background-position: center;
  background-color: transparent;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 9' fill='%23F5F8FA'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M5.93537 4.57889C6.03839 4.77912 6.0191 5.0363 5.87137 5.21403L2.87153 8.82282C2.68598 9.04603 2.36951 9.06026 2.16468 8.8546C1.95985 8.64893 1.94422 8.30126 2.12977 8.07804L4.80594 4.85863L2.15586 1.93583C1.96104 1.72096 1.96165 1.37314 2.15722 1.15895C2.35279 0.944757 2.66927 0.945311 2.86409 1.16018L5.85194 4.45551C5.8859 4.49296 5.91371 4.53459 5.93537 4.57889Z'/%3e%3c/svg%3e");
}

.menu-gray-200 .menu-item .menu-link {
  color: #EFF2F5;
}
.menu-gray-200 .menu-item .menu-link .menu-title {
  color: #EFF2F5;
}
.menu-gray-200 .menu-item .menu-link .menu-icon i {
  color: #EFF2F5;
}
.menu-gray-200 .menu-item .menu-link .menu-icon .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #EFF2F5;
}
.menu-gray-200 .menu-item .menu-link .menu-icon .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.menu-gray-200 .menu-item .menu-link .menu-bullet .bullet {
  background-color: #EFF2F5;
}
.menu-gray-200 .menu-item .menu-link .menu-arrow:after {
  background-repeat: no-repeat;
  background-position: center;
  background-color: transparent;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 9' fill='%23EFF2F5'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M5.93537 4.57889C6.03839 4.77912 6.0191 5.0363 5.87137 5.21403L2.87153 8.82282C2.68598 9.04603 2.36951 9.06026 2.16468 8.8546C1.95985 8.64893 1.94422 8.30126 2.12977 8.07804L4.80594 4.85863L2.15586 1.93583C1.96104 1.72096 1.96165 1.37314 2.15722 1.15895C2.35279 0.944757 2.66927 0.945311 2.86409 1.16018L5.85194 4.45551C5.8859 4.49296 5.91371 4.53459 5.93537 4.57889Z'/%3e%3c/svg%3e");
}

.menu-title-gray-200 .menu-item .menu-link {
  color: #EFF2F5;
}
.menu-title-gray-200 .menu-item .menu-link .menu-title {
  color: #EFF2F5;
}

.menu-icon-gray-200 .menu-item .menu-link .menu-icon i {
  color: #EFF2F5;
}
.menu-icon-gray-200 .menu-item .menu-link .menu-icon .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #EFF2F5;
}
.menu-icon-gray-200 .menu-item .menu-link .menu-icon .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}

.menu-bullet-gray-200 .menu-item .menu-link .menu-bullet .bullet {
  background-color: #EFF2F5;
}

.menu-arrow-gray-200 .menu-item .menu-link .menu-arrow:after {
  background-repeat: no-repeat;
  background-position: center;
  background-color: transparent;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 9' fill='%23EFF2F5'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M5.93537 4.57889C6.03839 4.77912 6.0191 5.0363 5.87137 5.21403L2.87153 8.82282C2.68598 9.04603 2.36951 9.06026 2.16468 8.8546C1.95985 8.64893 1.94422 8.30126 2.12977 8.07804L4.80594 4.85863L2.15586 1.93583C1.96104 1.72096 1.96165 1.37314 2.15722 1.15895C2.35279 0.944757 2.66927 0.945311 2.86409 1.16018L5.85194 4.45551C5.8859 4.49296 5.91371 4.53459 5.93537 4.57889Z'/%3e%3c/svg%3e");
}

.menu-gray-300 .menu-item .menu-link {
  color: #E4E6EF;
}
.menu-gray-300 .menu-item .menu-link .menu-title {
  color: #E4E6EF;
}
.menu-gray-300 .menu-item .menu-link .menu-icon i {
  color: #E4E6EF;
}
.menu-gray-300 .menu-item .menu-link .menu-icon .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #E4E6EF;
}
.menu-gray-300 .menu-item .menu-link .menu-icon .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.menu-gray-300 .menu-item .menu-link .menu-bullet .bullet {
  background-color: #E4E6EF;
}
.menu-gray-300 .menu-item .menu-link .menu-arrow:after {
  background-repeat: no-repeat;
  background-position: center;
  background-color: transparent;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 9' fill='%23E4E6EF'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M5.93537 4.57889C6.03839 4.77912 6.0191 5.0363 5.87137 5.21403L2.87153 8.82282C2.68598 9.04603 2.36951 9.06026 2.16468 8.8546C1.95985 8.64893 1.94422 8.30126 2.12977 8.07804L4.80594 4.85863L2.15586 1.93583C1.96104 1.72096 1.96165 1.37314 2.15722 1.15895C2.35279 0.944757 2.66927 0.945311 2.86409 1.16018L5.85194 4.45551C5.8859 4.49296 5.91371 4.53459 5.93537 4.57889Z'/%3e%3c/svg%3e");
}

.menu-title-gray-300 .menu-item .menu-link {
  color: #E4E6EF;
}
.menu-title-gray-300 .menu-item .menu-link .menu-title {
  color: #E4E6EF;
}

.menu-icon-gray-300 .menu-item .menu-link .menu-icon i {
  color: #E4E6EF;
}
.menu-icon-gray-300 .menu-item .menu-link .menu-icon .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #E4E6EF;
}
.menu-icon-gray-300 .menu-item .menu-link .menu-icon .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}

.menu-bullet-gray-300 .menu-item .menu-link .menu-bullet .bullet {
  background-color: #E4E6EF;
}

.menu-arrow-gray-300 .menu-item .menu-link .menu-arrow:after {
  background-repeat: no-repeat;
  background-position: center;
  background-color: transparent;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 9' fill='%23E4E6EF'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M5.93537 4.57889C6.03839 4.77912 6.0191 5.0363 5.87137 5.21403L2.87153 8.82282C2.68598 9.04603 2.36951 9.06026 2.16468 8.8546C1.95985 8.64893 1.94422 8.30126 2.12977 8.07804L4.80594 4.85863L2.15586 1.93583C1.96104 1.72096 1.96165 1.37314 2.15722 1.15895C2.35279 0.944757 2.66927 0.945311 2.86409 1.16018L5.85194 4.45551C5.8859 4.49296 5.91371 4.53459 5.93537 4.57889Z'/%3e%3c/svg%3e");
}

.menu-gray-400 .menu-item .menu-link {
  color: #B5B5C3;
}
.menu-gray-400 .menu-item .menu-link .menu-title {
  color: #B5B5C3;
}
.menu-gray-400 .menu-item .menu-link .menu-icon i {
  color: #B5B5C3;
}
.menu-gray-400 .menu-item .menu-link .menu-icon .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #B5B5C3;
}
.menu-gray-400 .menu-item .menu-link .menu-icon .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.menu-gray-400 .menu-item .menu-link .menu-bullet .bullet {
  background-color: #B5B5C3;
}
.menu-gray-400 .menu-item .menu-link .menu-arrow:after {
  background-repeat: no-repeat;
  background-position: center;
  background-color: transparent;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 9' fill='%23B5B5C3'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M5.93537 4.57889C6.03839 4.77912 6.0191 5.0363 5.87137 5.21403L2.87153 8.82282C2.68598 9.04603 2.36951 9.06026 2.16468 8.8546C1.95985 8.64893 1.94422 8.30126 2.12977 8.07804L4.80594 4.85863L2.15586 1.93583C1.96104 1.72096 1.96165 1.37314 2.15722 1.15895C2.35279 0.944757 2.66927 0.945311 2.86409 1.16018L5.85194 4.45551C5.8859 4.49296 5.91371 4.53459 5.93537 4.57889Z'/%3e%3c/svg%3e");
}

.menu-title-gray-400 .menu-item .menu-link {
  color: #B5B5C3;
}
.menu-title-gray-400 .menu-item .menu-link .menu-title {
  color: #B5B5C3;
}

.menu-icon-gray-400 .menu-item .menu-link .menu-icon i {
  color: #B5B5C3;
}
.menu-icon-gray-400 .menu-item .menu-link .menu-icon .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #B5B5C3;
}
.menu-icon-gray-400 .menu-item .menu-link .menu-icon .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}

.menu-bullet-gray-400 .menu-item .menu-link .menu-bullet .bullet {
  background-color: #B5B5C3;
}

.menu-arrow-gray-400 .menu-item .menu-link .menu-arrow:after {
  background-repeat: no-repeat;
  background-position: center;
  background-color: transparent;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 9' fill='%23B5B5C3'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M5.93537 4.57889C6.03839 4.77912 6.0191 5.0363 5.87137 5.21403L2.87153 8.82282C2.68598 9.04603 2.36951 9.06026 2.16468 8.8546C1.95985 8.64893 1.94422 8.30126 2.12977 8.07804L4.80594 4.85863L2.15586 1.93583C1.96104 1.72096 1.96165 1.37314 2.15722 1.15895C2.35279 0.944757 2.66927 0.945311 2.86409 1.16018L5.85194 4.45551C5.8859 4.49296 5.91371 4.53459 5.93537 4.57889Z'/%3e%3c/svg%3e");
}

.menu-gray-500 .menu-item .menu-link {
  color: #A1A5B7;
}
.menu-gray-500 .menu-item .menu-link .menu-title {
  color: #A1A5B7;
}
.menu-gray-500 .menu-item .menu-link .menu-icon i {
  color: #A1A5B7;
}
.menu-gray-500 .menu-item .menu-link .menu-icon .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #A1A5B7;
}
.menu-gray-500 .menu-item .menu-link .menu-icon .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.menu-gray-500 .menu-item .menu-link .menu-bullet .bullet {
  background-color: #A1A5B7;
}
.menu-gray-500 .menu-item .menu-link .menu-arrow:after {
  background-repeat: no-repeat;
  background-position: center;
  background-color: transparent;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 9' fill='%23A1A5B7'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M5.93537 4.57889C6.03839 4.77912 6.0191 5.0363 5.87137 5.21403L2.87153 8.82282C2.68598 9.04603 2.36951 9.06026 2.16468 8.8546C1.95985 8.64893 1.94422 8.30126 2.12977 8.07804L4.80594 4.85863L2.15586 1.93583C1.96104 1.72096 1.96165 1.37314 2.15722 1.15895C2.35279 0.944757 2.66927 0.945311 2.86409 1.16018L5.85194 4.45551C5.8859 4.49296 5.91371 4.53459 5.93537 4.57889Z'/%3e%3c/svg%3e");
}

.menu-title-gray-500 .menu-item .menu-link {
  color: #A1A5B7;
}
.menu-title-gray-500 .menu-item .menu-link .menu-title {
  color: #A1A5B7;
}

.menu-icon-gray-500 .menu-item .menu-link .menu-icon i {
  color: #A1A5B7;
}
.menu-icon-gray-500 .menu-item .menu-link .menu-icon .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #A1A5B7;
}
.menu-icon-gray-500 .menu-item .menu-link .menu-icon .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}

.menu-bullet-gray-500 .menu-item .menu-link .menu-bullet .bullet {
  background-color: #A1A5B7;
}

.menu-arrow-gray-500 .menu-item .menu-link .menu-arrow:after {
  background-repeat: no-repeat;
  background-position: center;
  background-color: transparent;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 9' fill='%23A1A5B7'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M5.93537 4.57889C6.03839 4.77912 6.0191 5.0363 5.87137 5.21403L2.87153 8.82282C2.68598 9.04603 2.36951 9.06026 2.16468 8.8546C1.95985 8.64893 1.94422 8.30126 2.12977 8.07804L4.80594 4.85863L2.15586 1.93583C1.96104 1.72096 1.96165 1.37314 2.15722 1.15895C2.35279 0.944757 2.66927 0.945311 2.86409 1.16018L5.85194 4.45551C5.8859 4.49296 5.91371 4.53459 5.93537 4.57889Z'/%3e%3c/svg%3e");
}

.menu-gray-600 .menu-item .menu-link {
  color: #7E8299;
}
.menu-gray-600 .menu-item .menu-link .menu-title {
  color: #7E8299;
}
.menu-gray-600 .menu-item .menu-link .menu-icon i {
  color: #7E8299;
}
.menu-gray-600 .menu-item .menu-link .menu-icon .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #7E8299;
}
.menu-gray-600 .menu-item .menu-link .menu-icon .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.menu-gray-600 .menu-item .menu-link .menu-bullet .bullet {
  background-color: #7E8299;
}
.menu-gray-600 .menu-item .menu-link .menu-arrow:after {
  background-repeat: no-repeat;
  background-position: center;
  background-color: transparent;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 9' fill='%237E8299'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M5.93537 4.57889C6.03839 4.77912 6.0191 5.0363 5.87137 5.21403L2.87153 8.82282C2.68598 9.04603 2.36951 9.06026 2.16468 8.8546C1.95985 8.64893 1.94422 8.30126 2.12977 8.07804L4.80594 4.85863L2.15586 1.93583C1.96104 1.72096 1.96165 1.37314 2.15722 1.15895C2.35279 0.944757 2.66927 0.945311 2.86409 1.16018L5.85194 4.45551C5.8859 4.49296 5.91371 4.53459 5.93537 4.57889Z'/%3e%3c/svg%3e");
}

.menu-title-gray-600 .menu-item .menu-link {
  color: #7E8299;
}
.menu-title-gray-600 .menu-item .menu-link .menu-title {
  color: #7E8299;
}

.menu-icon-gray-600 .menu-item .menu-link .menu-icon i {
  color: #7E8299;
}
.menu-icon-gray-600 .menu-item .menu-link .menu-icon .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #7E8299;
}
.menu-icon-gray-600 .menu-item .menu-link .menu-icon .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}

.menu-bullet-gray-600 .menu-item .menu-link .menu-bullet .bullet {
  background-color: #7E8299;
}

.menu-arrow-gray-600 .menu-item .menu-link .menu-arrow:after {
  background-repeat: no-repeat;
  background-position: center;
  background-color: transparent;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 9' fill='%237E8299'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M5.93537 4.57889C6.03839 4.77912 6.0191 5.0363 5.87137 5.21403L2.87153 8.82282C2.68598 9.04603 2.36951 9.06026 2.16468 8.8546C1.95985 8.64893 1.94422 8.30126 2.12977 8.07804L4.80594 4.85863L2.15586 1.93583C1.96104 1.72096 1.96165 1.37314 2.15722 1.15895C2.35279 0.944757 2.66927 0.945311 2.86409 1.16018L5.85194 4.45551C5.8859 4.49296 5.91371 4.53459 5.93537 4.57889Z'/%3e%3c/svg%3e");
}

.menu-gray-700 .menu-item .menu-link {
  color: #5E6278;
}
.menu-gray-700 .menu-item .menu-link .menu-title {
  color: #5E6278;
}
.menu-gray-700 .menu-item .menu-link .menu-icon i {
  color: #5E6278;
}
.menu-gray-700 .menu-item .menu-link .menu-icon .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #5E6278;
}
.menu-gray-700 .menu-item .menu-link .menu-icon .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.menu-gray-700 .menu-item .menu-link .menu-bullet .bullet {
  background-color: #5E6278;
}
.menu-gray-700 .menu-item .menu-link .menu-arrow:after {
  background-repeat: no-repeat;
  background-position: center;
  background-color: transparent;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 9' fill='%235E6278'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M5.93537 4.57889C6.03839 4.77912 6.0191 5.0363 5.87137 5.21403L2.87153 8.82282C2.68598 9.04603 2.36951 9.06026 2.16468 8.8546C1.95985 8.64893 1.94422 8.30126 2.12977 8.07804L4.80594 4.85863L2.15586 1.93583C1.96104 1.72096 1.96165 1.37314 2.15722 1.15895C2.35279 0.944757 2.66927 0.945311 2.86409 1.16018L5.85194 4.45551C5.8859 4.49296 5.91371 4.53459 5.93537 4.57889Z'/%3e%3c/svg%3e");
}

.menu-title-gray-700 .menu-item .menu-link {
  color: #5E6278;
}
.menu-title-gray-700 .menu-item .menu-link .menu-title {
  color: #5E6278;
}

.menu-icon-gray-700 .menu-item .menu-link .menu-icon i {
  color: #5E6278;
}
.menu-icon-gray-700 .menu-item .menu-link .menu-icon .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #5E6278;
}
.menu-icon-gray-700 .menu-item .menu-link .menu-icon .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}

.menu-bullet-gray-700 .menu-item .menu-link .menu-bullet .bullet {
  background-color: #5E6278;
}

.menu-arrow-gray-700 .menu-item .menu-link .menu-arrow:after {
  background-repeat: no-repeat;
  background-position: center;
  background-color: transparent;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 9' fill='%235E6278'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M5.93537 4.57889C6.03839 4.77912 6.0191 5.0363 5.87137 5.21403L2.87153 8.82282C2.68598 9.04603 2.36951 9.06026 2.16468 8.8546C1.95985 8.64893 1.94422 8.30126 2.12977 8.07804L4.80594 4.85863L2.15586 1.93583C1.96104 1.72096 1.96165 1.37314 2.15722 1.15895C2.35279 0.944757 2.66927 0.945311 2.86409 1.16018L5.85194 4.45551C5.8859 4.49296 5.91371 4.53459 5.93537 4.57889Z'/%3e%3c/svg%3e");
}

.menu-gray-800 .menu-item .menu-link {
  color: #3F4254;
}
.menu-gray-800 .menu-item .menu-link .menu-title {
  color: #3F4254;
}
.menu-gray-800 .menu-item .menu-link .menu-icon i {
  color: #3F4254;
}
.menu-gray-800 .menu-item .menu-link .menu-icon .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #3F4254;
}
.menu-gray-800 .menu-item .menu-link .menu-icon .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.menu-gray-800 .menu-item .menu-link .menu-bullet .bullet {
  background-color: #3F4254;
}
.menu-gray-800 .menu-item .menu-link .menu-arrow:after {
  background-repeat: no-repeat;
  background-position: center;
  background-color: transparent;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 9' fill='%233F4254'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M5.93537 4.57889C6.03839 4.77912 6.0191 5.0363 5.87137 5.21403L2.87153 8.82282C2.68598 9.04603 2.36951 9.06026 2.16468 8.8546C1.95985 8.64893 1.94422 8.30126 2.12977 8.07804L4.80594 4.85863L2.15586 1.93583C1.96104 1.72096 1.96165 1.37314 2.15722 1.15895C2.35279 0.944757 2.66927 0.945311 2.86409 1.16018L5.85194 4.45551C5.8859 4.49296 5.91371 4.53459 5.93537 4.57889Z'/%3e%3c/svg%3e");
}

.menu-title-gray-800 .menu-item .menu-link {
  color: #3F4254;
}
.menu-title-gray-800 .menu-item .menu-link .menu-title {
  color: #3F4254;
}

.menu-icon-gray-800 .menu-item .menu-link .menu-icon i {
  color: #3F4254;
}
.menu-icon-gray-800 .menu-item .menu-link .menu-icon .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #3F4254;
}
.menu-icon-gray-800 .menu-item .menu-link .menu-icon .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}

.menu-bullet-gray-800 .menu-item .menu-link .menu-bullet .bullet {
  background-color: #3F4254;
}

.menu-arrow-gray-800 .menu-item .menu-link .menu-arrow:after {
  background-repeat: no-repeat;
  background-position: center;
  background-color: transparent;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 9' fill='%233F4254'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M5.93537 4.57889C6.03839 4.77912 6.0191 5.0363 5.87137 5.21403L2.87153 8.82282C2.68598 9.04603 2.36951 9.06026 2.16468 8.8546C1.95985 8.64893 1.94422 8.30126 2.12977 8.07804L4.80594 4.85863L2.15586 1.93583C1.96104 1.72096 1.96165 1.37314 2.15722 1.15895C2.35279 0.944757 2.66927 0.945311 2.86409 1.16018L5.85194 4.45551C5.8859 4.49296 5.91371 4.53459 5.93537 4.57889Z'/%3e%3c/svg%3e");
}

.menu-gray-900 .menu-item .menu-link {
  color: #181C32;
}
.menu-gray-900 .menu-item .menu-link .menu-title {
  color: #181C32;
}
.menu-gray-900 .menu-item .menu-link .menu-icon i {
  color: #181C32;
}
.menu-gray-900 .menu-item .menu-link .menu-icon .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #181C32;
}
.menu-gray-900 .menu-item .menu-link .menu-icon .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.menu-gray-900 .menu-item .menu-link .menu-bullet .bullet {
  background-color: #181C32;
}
.menu-gray-900 .menu-item .menu-link .menu-arrow:after {
  background-repeat: no-repeat;
  background-position: center;
  background-color: transparent;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 9' fill='%23181C32'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M5.93537 4.57889C6.03839 4.77912 6.0191 5.0363 5.87137 5.21403L2.87153 8.82282C2.68598 9.04603 2.36951 9.06026 2.16468 8.8546C1.95985 8.64893 1.94422 8.30126 2.12977 8.07804L4.80594 4.85863L2.15586 1.93583C1.96104 1.72096 1.96165 1.37314 2.15722 1.15895C2.35279 0.944757 2.66927 0.945311 2.86409 1.16018L5.85194 4.45551C5.8859 4.49296 5.91371 4.53459 5.93537 4.57889Z'/%3e%3c/svg%3e");
}

.menu-title-gray-900 .menu-item .menu-link {
  color: #181C32;
}
.menu-title-gray-900 .menu-item .menu-link .menu-title {
  color: #181C32;
}

.menu-icon-gray-900 .menu-item .menu-link .menu-icon i {
  color: #181C32;
}
.menu-icon-gray-900 .menu-item .menu-link .menu-icon .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #181C32;
}
.menu-icon-gray-900 .menu-item .menu-link .menu-icon .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}

.menu-bullet-gray-900 .menu-item .menu-link .menu-bullet .bullet {
  background-color: #181C32;
}

.menu-arrow-gray-900 .menu-item .menu-link .menu-arrow:after {
  background-repeat: no-repeat;
  background-position: center;
  background-color: transparent;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 9' fill='%23181C32'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M5.93537 4.57889C6.03839 4.77912 6.0191 5.0363 5.87137 5.21403L2.87153 8.82282C2.68598 9.04603 2.36951 9.06026 2.16468 8.8546C1.95985 8.64893 1.94422 8.30126 2.12977 8.07804L4.80594 4.85863L2.15586 1.93583C1.96104 1.72096 1.96165 1.37314 2.15722 1.15895C2.35279 0.944757 2.66927 0.945311 2.86409 1.16018L5.85194 4.45551C5.8859 4.49296 5.91371 4.53459 5.93537 4.57889Z'/%3e%3c/svg%3e");
}

.menu-hover-bg .menu-item.hover > .menu-link:not(.disabled):not(.active),
.menu-hover-bg .menu-item .menu-link:hover:not(.disabled):not(.active) {
  transition: color 0.2s ease, background-color 0.2s ease;
  background-color: rgba(245, 248, 250, 0.8);
}

.menu-show-bg .menu-item.here > .menu-link, .menu-show-bg .menu-item.show > .menu-link {
  transition: color 0.2s ease, background-color 0.2s ease;
  background-color: rgba(245, 248, 250, 0.8);
}

.menu-active-bg .menu-item .menu-link.active {
  transition: color 0.2s ease, background-color 0.2s ease;
  background-color: rgba(245, 248, 250, 0.8);
}

.menu-state-bg .menu-item.hover > .menu-link:not(.disabled):not(.active),
.menu-state-bg .menu-item .menu-link:hover:not(.disabled):not(.active) {
  transition: color 0.2s ease, background-color 0.2s ease;
  background-color: rgba(245, 248, 250, 0.8);
}
.menu-state-bg .menu-item.here > .menu-link, .menu-state-bg .menu-item.show > .menu-link {
  transition: color 0.2s ease, background-color 0.2s ease;
  background-color: rgba(245, 248, 250, 0.8);
}
.menu-state-bg .menu-item .menu-link.active {
  transition: color 0.2s ease, background-color 0.2s ease;
  background-color: rgba(245, 248, 250, 0.8);
}

.menu-hover-primary .menu-item.hover > .menu-link:not(.disabled):not(.active),
.menu-hover-primary .menu-item .menu-link:hover:not(.disabled):not(.active) {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: #009EF7;
}
.menu-hover-primary .menu-item.hover > .menu-link:not(.disabled):not(.active) .menu-title,
.menu-hover-primary .menu-item .menu-link:hover:not(.disabled):not(.active) .menu-title {
  color: #009EF7;
}
.menu-hover-primary .menu-item.hover > .menu-link:not(.disabled):not(.active) .menu-icon i,
.menu-hover-primary .menu-item .menu-link:hover:not(.disabled):not(.active) .menu-icon i {
  color: #009EF7;
}
.menu-hover-primary .menu-item.hover > .menu-link:not(.disabled):not(.active) .menu-icon .svg-icon svg [fill]:not(.permanent):not(g),
.menu-hover-primary .menu-item .menu-link:hover:not(.disabled):not(.active) .menu-icon .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #009EF7;
}
.menu-hover-primary .menu-item.hover > .menu-link:not(.disabled):not(.active) .menu-icon .svg-icon svg:hover [fill]:not(.permanent):not(g),
.menu-hover-primary .menu-item .menu-link:hover:not(.disabled):not(.active) .menu-icon .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.menu-hover-primary .menu-item.hover > .menu-link:not(.disabled):not(.active) .menu-bullet .bullet,
.menu-hover-primary .menu-item .menu-link:hover:not(.disabled):not(.active) .menu-bullet .bullet {
  background-color: #009EF7;
}
.menu-hover-primary .menu-item.hover > .menu-link:not(.disabled):not(.active) .menu-arrow:after,
.menu-hover-primary .menu-item .menu-link:hover:not(.disabled):not(.active) .menu-arrow:after {
  background-repeat: no-repeat;
  background-position: center;
  background-color: transparent;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 9' fill='%23009EF7'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M5.93537 4.57889C6.03839 4.77912 6.0191 5.0363 5.87137 5.21403L2.87153 8.82282C2.68598 9.04603 2.36951 9.06026 2.16468 8.8546C1.95985 8.64893 1.94422 8.30126 2.12977 8.07804L4.80594 4.85863L2.15586 1.93583C1.96104 1.72096 1.96165 1.37314 2.15722 1.15895C2.35279 0.944757 2.66927 0.945311 2.86409 1.16018L5.85194 4.45551C5.8859 4.49296 5.91371 4.53459 5.93537 4.57889Z'/%3e%3c/svg%3e");
}

.menu-show-primary .menu-item.here > .menu-link, .menu-show-primary .menu-item.show > .menu-link {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: #009EF7;
}
.menu-show-primary .menu-item.here > .menu-link .menu-title, .menu-show-primary .menu-item.show > .menu-link .menu-title {
  color: #009EF7;
}
.menu-show-primary .menu-item.here > .menu-link .menu-icon i, .menu-show-primary .menu-item.show > .menu-link .menu-icon i {
  color: #009EF7;
}
.menu-show-primary .menu-item.here > .menu-link .menu-icon .svg-icon svg [fill]:not(.permanent):not(g), .menu-show-primary .menu-item.show > .menu-link .menu-icon .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #009EF7;
}
.menu-show-primary .menu-item.here > .menu-link .menu-icon .svg-icon svg:hover [fill]:not(.permanent):not(g), .menu-show-primary .menu-item.show > .menu-link .menu-icon .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.menu-show-primary .menu-item.here > .menu-link .menu-bullet .bullet, .menu-show-primary .menu-item.show > .menu-link .menu-bullet .bullet {
  background-color: #009EF7;
}
.menu-show-primary .menu-item.here > .menu-link .menu-arrow:after, .menu-show-primary .menu-item.show > .menu-link .menu-arrow:after {
  background-repeat: no-repeat;
  background-position: center;
  background-color: transparent;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 9' fill='%23009EF7'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M5.93537 4.57889C6.03839 4.77912 6.0191 5.0363 5.87137 5.21403L2.87153 8.82282C2.68598 9.04603 2.36951 9.06026 2.16468 8.8546C1.95985 8.64893 1.94422 8.30126 2.12977 8.07804L4.80594 4.85863L2.15586 1.93583C1.96104 1.72096 1.96165 1.37314 2.15722 1.15895C2.35279 0.944757 2.66927 0.945311 2.86409 1.16018L5.85194 4.45551C5.8859 4.49296 5.91371 4.53459 5.93537 4.57889Z'/%3e%3c/svg%3e");
}

.menu-active-primary .menu-item .menu-link.active {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: #009EF7;
}
.menu-active-primary .menu-item .menu-link.active .menu-title {
  color: #009EF7;
}
.menu-active-primary .menu-item .menu-link.active .menu-icon i {
  color: #009EF7;
}
.menu-active-primary .menu-item .menu-link.active .menu-icon .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #009EF7;
}
.menu-active-primary .menu-item .menu-link.active .menu-icon .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.menu-active-primary .menu-item .menu-link.active .menu-bullet .bullet {
  background-color: #009EF7;
}
.menu-active-primary .menu-item .menu-link.active .menu-arrow:after {
  background-repeat: no-repeat;
  background-position: center;
  background-color: transparent;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 9' fill='%23009EF7'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M5.93537 4.57889C6.03839 4.77912 6.0191 5.0363 5.87137 5.21403L2.87153 8.82282C2.68598 9.04603 2.36951 9.06026 2.16468 8.8546C1.95985 8.64893 1.94422 8.30126 2.12977 8.07804L4.80594 4.85863L2.15586 1.93583C1.96104 1.72096 1.96165 1.37314 2.15722 1.15895C2.35279 0.944757 2.66927 0.945311 2.86409 1.16018L5.85194 4.45551C5.8859 4.49296 5.91371 4.53459 5.93537 4.57889Z'/%3e%3c/svg%3e");
}

.menu-state-primary .menu-item.hover > .menu-link:not(.disabled):not(.active),
.menu-state-primary .menu-item .menu-link:hover:not(.disabled):not(.active) {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: #009EF7;
}
.menu-state-primary .menu-item.hover > .menu-link:not(.disabled):not(.active) .menu-title,
.menu-state-primary .menu-item .menu-link:hover:not(.disabled):not(.active) .menu-title {
  color: #009EF7;
}
.menu-state-primary .menu-item.hover > .menu-link:not(.disabled):not(.active) .menu-icon i,
.menu-state-primary .menu-item .menu-link:hover:not(.disabled):not(.active) .menu-icon i {
  color: #009EF7;
}
.menu-state-primary .menu-item.hover > .menu-link:not(.disabled):not(.active) .menu-icon .svg-icon svg [fill]:not(.permanent):not(g),
.menu-state-primary .menu-item .menu-link:hover:not(.disabled):not(.active) .menu-icon .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #009EF7;
}
.menu-state-primary .menu-item.hover > .menu-link:not(.disabled):not(.active) .menu-icon .svg-icon svg:hover [fill]:not(.permanent):not(g),
.menu-state-primary .menu-item .menu-link:hover:not(.disabled):not(.active) .menu-icon .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.menu-state-primary .menu-item.hover > .menu-link:not(.disabled):not(.active) .menu-bullet .bullet,
.menu-state-primary .menu-item .menu-link:hover:not(.disabled):not(.active) .menu-bullet .bullet {
  background-color: #009EF7;
}
.menu-state-primary .menu-item.hover > .menu-link:not(.disabled):not(.active) .menu-arrow:after,
.menu-state-primary .menu-item .menu-link:hover:not(.disabled):not(.active) .menu-arrow:after {
  background-repeat: no-repeat;
  background-position: center;
  background-color: transparent;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 9' fill='%23009EF7'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M5.93537 4.57889C6.03839 4.77912 6.0191 5.0363 5.87137 5.21403L2.87153 8.82282C2.68598 9.04603 2.36951 9.06026 2.16468 8.8546C1.95985 8.64893 1.94422 8.30126 2.12977 8.07804L4.80594 4.85863L2.15586 1.93583C1.96104 1.72096 1.96165 1.37314 2.15722 1.15895C2.35279 0.944757 2.66927 0.945311 2.86409 1.16018L5.85194 4.45551C5.8859 4.49296 5.91371 4.53459 5.93537 4.57889Z'/%3e%3c/svg%3e");
}
.menu-state-primary .menu-item.here > .menu-link, .menu-state-primary .menu-item.show > .menu-link {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: #009EF7;
}
.menu-state-primary .menu-item.here > .menu-link .menu-title, .menu-state-primary .menu-item.show > .menu-link .menu-title {
  color: #009EF7;
}
.menu-state-primary .menu-item.here > .menu-link .menu-icon i, .menu-state-primary .menu-item.show > .menu-link .menu-icon i {
  color: #009EF7;
}
.menu-state-primary .menu-item.here > .menu-link .menu-icon .svg-icon svg [fill]:not(.permanent):not(g), .menu-state-primary .menu-item.show > .menu-link .menu-icon .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #009EF7;
}
.menu-state-primary .menu-item.here > .menu-link .menu-icon .svg-icon svg:hover [fill]:not(.permanent):not(g), .menu-state-primary .menu-item.show > .menu-link .menu-icon .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.menu-state-primary .menu-item.here > .menu-link .menu-bullet .bullet, .menu-state-primary .menu-item.show > .menu-link .menu-bullet .bullet {
  background-color: #009EF7;
}
.menu-state-primary .menu-item.here > .menu-link .menu-arrow:after, .menu-state-primary .menu-item.show > .menu-link .menu-arrow:after {
  background-repeat: no-repeat;
  background-position: center;
  background-color: transparent;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 9' fill='%23009EF7'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M5.93537 4.57889C6.03839 4.77912 6.0191 5.0363 5.87137 5.21403L2.87153 8.82282C2.68598 9.04603 2.36951 9.06026 2.16468 8.8546C1.95985 8.64893 1.94422 8.30126 2.12977 8.07804L4.80594 4.85863L2.15586 1.93583C1.96104 1.72096 1.96165 1.37314 2.15722 1.15895C2.35279 0.944757 2.66927 0.945311 2.86409 1.16018L5.85194 4.45551C5.8859 4.49296 5.91371 4.53459 5.93537 4.57889Z'/%3e%3c/svg%3e");
}
.menu-state-primary .menu-item .menu-link.active {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: #009EF7;
}
.menu-state-primary .menu-item .menu-link.active .menu-title {
  color: #009EF7;
}
.menu-state-primary .menu-item .menu-link.active .menu-icon i {
  color: #009EF7;
}
.menu-state-primary .menu-item .menu-link.active .menu-icon .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #009EF7;
}
.menu-state-primary .menu-item .menu-link.active .menu-icon .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.menu-state-primary .menu-item .menu-link.active .menu-bullet .bullet {
  background-color: #009EF7;
}
.menu-state-primary .menu-item .menu-link.active .menu-arrow:after {
  background-repeat: no-repeat;
  background-position: center;
  background-color: transparent;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 9' fill='%23009EF7'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M5.93537 4.57889C6.03839 4.77912 6.0191 5.0363 5.87137 5.21403L2.87153 8.82282C2.68598 9.04603 2.36951 9.06026 2.16468 8.8546C1.95985 8.64893 1.94422 8.30126 2.12977 8.07804L4.80594 4.85863L2.15586 1.93583C1.96104 1.72096 1.96165 1.37314 2.15722 1.15895C2.35279 0.944757 2.66927 0.945311 2.86409 1.16018L5.85194 4.45551C5.8859 4.49296 5.91371 4.53459 5.93537 4.57889Z'/%3e%3c/svg%3e");
}

.menu-hover-bg-primary .menu-item.hover > .menu-link:not(.disabled):not(.active),
.menu-hover-bg-primary .menu-item .menu-link:hover:not(.disabled):not(.active) {
  transition: color 0.2s ease, background-color 0.2s ease;
  background-color: #009EF7;
  color: #FFFFFF;
}
.menu-hover-bg-primary .menu-item.hover > .menu-link:not(.disabled):not(.active) .menu-title,
.menu-hover-bg-primary .menu-item .menu-link:hover:not(.disabled):not(.active) .menu-title {
  color: #FFFFFF;
}
.menu-hover-bg-primary .menu-item.hover > .menu-link:not(.disabled):not(.active) .menu-icon i,
.menu-hover-bg-primary .menu-item .menu-link:hover:not(.disabled):not(.active) .menu-icon i {
  color: #FFFFFF;
}
.menu-hover-bg-primary .menu-item.hover > .menu-link:not(.disabled):not(.active) .menu-icon .svg-icon svg [fill]:not(.permanent):not(g),
.menu-hover-bg-primary .menu-item .menu-link:hover:not(.disabled):not(.active) .menu-icon .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #FFFFFF;
}
.menu-hover-bg-primary .menu-item.hover > .menu-link:not(.disabled):not(.active) .menu-icon .svg-icon svg:hover [fill]:not(.permanent):not(g),
.menu-hover-bg-primary .menu-item .menu-link:hover:not(.disabled):not(.active) .menu-icon .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.menu-hover-bg-primary .menu-item.hover > .menu-link:not(.disabled):not(.active) .menu-bullet .bullet,
.menu-hover-bg-primary .menu-item .menu-link:hover:not(.disabled):not(.active) .menu-bullet .bullet {
  background-color: #FFFFFF;
}
.menu-hover-bg-primary .menu-item.hover > .menu-link:not(.disabled):not(.active) .menu-arrow:after,
.menu-hover-bg-primary .menu-item .menu-link:hover:not(.disabled):not(.active) .menu-arrow:after {
  background-repeat: no-repeat;
  background-position: center;
  background-color: transparent;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 9' fill='%23FFFFFF'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M5.93537 4.57889C6.03839 4.77912 6.0191 5.0363 5.87137 5.21403L2.87153 8.82282C2.68598 9.04603 2.36951 9.06026 2.16468 8.8546C1.95985 8.64893 1.94422 8.30126 2.12977 8.07804L4.80594 4.85863L2.15586 1.93583C1.96104 1.72096 1.96165 1.37314 2.15722 1.15895C2.35279 0.944757 2.66927 0.945311 2.86409 1.16018L5.85194 4.45551C5.8859 4.49296 5.91371 4.53459 5.93537 4.57889Z'/%3e%3c/svg%3e");
}

.menu-show-bg-primary .menu-item.here > .menu-link, .menu-show-bg-primary .menu-item.show > .menu-link {
  transition: color 0.2s ease, background-color 0.2s ease;
  background-color: #009EF7;
  color: #FFFFFF;
}
.menu-show-bg-primary .menu-item.here > .menu-link .menu-title, .menu-show-bg-primary .menu-item.show > .menu-link .menu-title {
  color: #FFFFFF;
}
.menu-show-bg-primary .menu-item.here > .menu-link .menu-icon i, .menu-show-bg-primary .menu-item.show > .menu-link .menu-icon i {
  color: #FFFFFF;
}
.menu-show-bg-primary .menu-item.here > .menu-link .menu-icon .svg-icon svg [fill]:not(.permanent):not(g), .menu-show-bg-primary .menu-item.show > .menu-link .menu-icon .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #FFFFFF;
}
.menu-show-bg-primary .menu-item.here > .menu-link .menu-icon .svg-icon svg:hover [fill]:not(.permanent):not(g), .menu-show-bg-primary .menu-item.show > .menu-link .menu-icon .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.menu-show-bg-primary .menu-item.here > .menu-link .menu-bullet .bullet, .menu-show-bg-primary .menu-item.show > .menu-link .menu-bullet .bullet {
  background-color: #FFFFFF;
}
.menu-show-bg-primary .menu-item.here > .menu-link .menu-arrow:after, .menu-show-bg-primary .menu-item.show > .menu-link .menu-arrow:after {
  background-repeat: no-repeat;
  background-position: center;
  background-color: transparent;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 9' fill='%23FFFFFF'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M5.93537 4.57889C6.03839 4.77912 6.0191 5.0363 5.87137 5.21403L2.87153 8.82282C2.68598 9.04603 2.36951 9.06026 2.16468 8.8546C1.95985 8.64893 1.94422 8.30126 2.12977 8.07804L4.80594 4.85863L2.15586 1.93583C1.96104 1.72096 1.96165 1.37314 2.15722 1.15895C2.35279 0.944757 2.66927 0.945311 2.86409 1.16018L5.85194 4.45551C5.8859 4.49296 5.91371 4.53459 5.93537 4.57889Z'/%3e%3c/svg%3e");
}

.menu-active-bg-primary .menu-item .menu-link.active {
  transition: color 0.2s ease, background-color 0.2s ease;
  background-color: #009EF7;
  color: #FFFFFF;
}
.menu-active-bg-primary .menu-item .menu-link.active .menu-title {
  color: #FFFFFF;
}
.menu-active-bg-primary .menu-item .menu-link.active .menu-icon i {
  color: #FFFFFF;
}
.menu-active-bg-primary .menu-item .menu-link.active .menu-icon .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #FFFFFF;
}
.menu-active-bg-primary .menu-item .menu-link.active .menu-icon .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.menu-active-bg-primary .menu-item .menu-link.active .menu-bullet .bullet {
  background-color: #FFFFFF;
}
.menu-active-bg-primary .menu-item .menu-link.active .menu-arrow:after {
  background-repeat: no-repeat;
  background-position: center;
  background-color: transparent;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 9' fill='%23FFFFFF'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M5.93537 4.57889C6.03839 4.77912 6.0191 5.0363 5.87137 5.21403L2.87153 8.82282C2.68598 9.04603 2.36951 9.06026 2.16468 8.8546C1.95985 8.64893 1.94422 8.30126 2.12977 8.07804L4.80594 4.85863L2.15586 1.93583C1.96104 1.72096 1.96165 1.37314 2.15722 1.15895C2.35279 0.944757 2.66927 0.945311 2.86409 1.16018L5.85194 4.45551C5.8859 4.49296 5.91371 4.53459 5.93537 4.57889Z'/%3e%3c/svg%3e");
}

.menu-state-bg-primary .menu-item.hover > .menu-link:not(.disabled):not(.active),
.menu-state-bg-primary .menu-item .menu-link:hover:not(.disabled):not(.active) {
  transition: color 0.2s ease, background-color 0.2s ease;
  background-color: #009EF7;
  color: #FFFFFF;
}
.menu-state-bg-primary .menu-item.hover > .menu-link:not(.disabled):not(.active) .menu-title,
.menu-state-bg-primary .menu-item .menu-link:hover:not(.disabled):not(.active) .menu-title {
  color: #FFFFFF;
}
.menu-state-bg-primary .menu-item.hover > .menu-link:not(.disabled):not(.active) .menu-icon i,
.menu-state-bg-primary .menu-item .menu-link:hover:not(.disabled):not(.active) .menu-icon i {
  color: #FFFFFF;
}
.menu-state-bg-primary .menu-item.hover > .menu-link:not(.disabled):not(.active) .menu-icon .svg-icon svg [fill]:not(.permanent):not(g),
.menu-state-bg-primary .menu-item .menu-link:hover:not(.disabled):not(.active) .menu-icon .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #FFFFFF;
}
.menu-state-bg-primary .menu-item.hover > .menu-link:not(.disabled):not(.active) .menu-icon .svg-icon svg:hover [fill]:not(.permanent):not(g),
.menu-state-bg-primary .menu-item .menu-link:hover:not(.disabled):not(.active) .menu-icon .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.menu-state-bg-primary .menu-item.hover > .menu-link:not(.disabled):not(.active) .menu-bullet .bullet,
.menu-state-bg-primary .menu-item .menu-link:hover:not(.disabled):not(.active) .menu-bullet .bullet {
  background-color: #FFFFFF;
}
.menu-state-bg-primary .menu-item.hover > .menu-link:not(.disabled):not(.active) .menu-arrow:after,
.menu-state-bg-primary .menu-item .menu-link:hover:not(.disabled):not(.active) .menu-arrow:after {
  background-repeat: no-repeat;
  background-position: center;
  background-color: transparent;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 9' fill='%23FFFFFF'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M5.93537 4.57889C6.03839 4.77912 6.0191 5.0363 5.87137 5.21403L2.87153 8.82282C2.68598 9.04603 2.36951 9.06026 2.16468 8.8546C1.95985 8.64893 1.94422 8.30126 2.12977 8.07804L4.80594 4.85863L2.15586 1.93583C1.96104 1.72096 1.96165 1.37314 2.15722 1.15895C2.35279 0.944757 2.66927 0.945311 2.86409 1.16018L5.85194 4.45551C5.8859 4.49296 5.91371 4.53459 5.93537 4.57889Z'/%3e%3c/svg%3e");
}
.menu-state-bg-primary .menu-item.here > .menu-link, .menu-state-bg-primary .menu-item.show > .menu-link {
  transition: color 0.2s ease, background-color 0.2s ease;
  background-color: #009EF7;
  color: #FFFFFF;
}
.menu-state-bg-primary .menu-item.here > .menu-link .menu-title, .menu-state-bg-primary .menu-item.show > .menu-link .menu-title {
  color: #FFFFFF;
}
.menu-state-bg-primary .menu-item.here > .menu-link .menu-icon i, .menu-state-bg-primary .menu-item.show > .menu-link .menu-icon i {
  color: #FFFFFF;
}
.menu-state-bg-primary .menu-item.here > .menu-link .menu-icon .svg-icon svg [fill]:not(.permanent):not(g), .menu-state-bg-primary .menu-item.show > .menu-link .menu-icon .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #FFFFFF;
}
.menu-state-bg-primary .menu-item.here > .menu-link .menu-icon .svg-icon svg:hover [fill]:not(.permanent):not(g), .menu-state-bg-primary .menu-item.show > .menu-link .menu-icon .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.menu-state-bg-primary .menu-item.here > .menu-link .menu-bullet .bullet, .menu-state-bg-primary .menu-item.show > .menu-link .menu-bullet .bullet {
  background-color: #FFFFFF;
}
.menu-state-bg-primary .menu-item.here > .menu-link .menu-arrow:after, .menu-state-bg-primary .menu-item.show > .menu-link .menu-arrow:after {
  background-repeat: no-repeat;
  background-position: center;
  background-color: transparent;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 9' fill='%23FFFFFF'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M5.93537 4.57889C6.03839 4.77912 6.0191 5.0363 5.87137 5.21403L2.87153 8.82282C2.68598 9.04603 2.36951 9.06026 2.16468 8.8546C1.95985 8.64893 1.94422 8.30126 2.12977 8.07804L4.80594 4.85863L2.15586 1.93583C1.96104 1.72096 1.96165 1.37314 2.15722 1.15895C2.35279 0.944757 2.66927 0.945311 2.86409 1.16018L5.85194 4.45551C5.8859 4.49296 5.91371 4.53459 5.93537 4.57889Z'/%3e%3c/svg%3e");
}
.menu-state-bg-primary .menu-item .menu-link.active {
  transition: color 0.2s ease, background-color 0.2s ease;
  background-color: #009EF7;
  color: #FFFFFF;
}
.menu-state-bg-primary .menu-item .menu-link.active .menu-title {
  color: #FFFFFF;
}
.menu-state-bg-primary .menu-item .menu-link.active .menu-icon i {
  color: #FFFFFF;
}
.menu-state-bg-primary .menu-item .menu-link.active .menu-icon .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #FFFFFF;
}
.menu-state-bg-primary .menu-item .menu-link.active .menu-icon .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.menu-state-bg-primary .menu-item .menu-link.active .menu-bullet .bullet {
  background-color: #FFFFFF;
}
.menu-state-bg-primary .menu-item .menu-link.active .menu-arrow:after {
  background-repeat: no-repeat;
  background-position: center;
  background-color: transparent;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 9' fill='%23FFFFFF'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M5.93537 4.57889C6.03839 4.77912 6.0191 5.0363 5.87137 5.21403L2.87153 8.82282C2.68598 9.04603 2.36951 9.06026 2.16468 8.8546C1.95985 8.64893 1.94422 8.30126 2.12977 8.07804L4.80594 4.85863L2.15586 1.93583C1.96104 1.72096 1.96165 1.37314 2.15722 1.15895C2.35279 0.944757 2.66927 0.945311 2.86409 1.16018L5.85194 4.45551C5.8859 4.49296 5.91371 4.53459 5.93537 4.57889Z'/%3e%3c/svg%3e");
}

.menu-show-bg-light-primary .menu-item.here > .menu-link, .menu-show-bg-light-primary .menu-item.show > .menu-link {
  transition: color 0.2s ease, background-color 0.2s ease;
  background-color: #ECF8FF;
  color: #009EF7;
}
.menu-show-bg-light-primary .menu-item.here > .menu-link .menu-title, .menu-show-bg-light-primary .menu-item.show > .menu-link .menu-title {
  color: #009EF7;
}
.menu-show-bg-light-primary .menu-item.here > .menu-link .menu-icon i, .menu-show-bg-light-primary .menu-item.show > .menu-link .menu-icon i {
  color: #009EF7;
}
.menu-show-bg-light-primary .menu-item.here > .menu-link .menu-icon .svg-icon svg [fill]:not(.permanent):not(g), .menu-show-bg-light-primary .menu-item.show > .menu-link .menu-icon .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #009EF7;
}
.menu-show-bg-light-primary .menu-item.here > .menu-link .menu-icon .svg-icon svg:hover [fill]:not(.permanent):not(g), .menu-show-bg-light-primary .menu-item.show > .menu-link .menu-icon .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.menu-show-bg-light-primary .menu-item.here > .menu-link .menu-bullet .bullet, .menu-show-bg-light-primary .menu-item.show > .menu-link .menu-bullet .bullet {
  background-color: #009EF7;
}
.menu-show-bg-light-primary .menu-item.here > .menu-link .menu-arrow:after, .menu-show-bg-light-primary .menu-item.show > .menu-link .menu-arrow:after {
  background-repeat: no-repeat;
  background-position: center;
  background-color: transparent;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 9' fill='%23009EF7'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M5.93537 4.57889C6.03839 4.77912 6.0191 5.0363 5.87137 5.21403L2.87153 8.82282C2.68598 9.04603 2.36951 9.06026 2.16468 8.8546C1.95985 8.64893 1.94422 8.30126 2.12977 8.07804L4.80594 4.85863L2.15586 1.93583C1.96104 1.72096 1.96165 1.37314 2.15722 1.15895C2.35279 0.944757 2.66927 0.945311 2.86409 1.16018L5.85194 4.45551C5.8859 4.49296 5.91371 4.53459 5.93537 4.57889Z'/%3e%3c/svg%3e");
}

.menu-hover-bg-light-primary .menu-item.hover > .menu-link:not(.disabled):not(.active),
.menu-hover-bg-light-primary .menu-item .menu-link:hover:not(.disabled):not(.active) {
  transition: color 0.2s ease, background-color 0.2s ease;
  background-color: #ECF8FF;
  color: #009EF7;
}
.menu-hover-bg-light-primary .menu-item.hover > .menu-link:not(.disabled):not(.active) .menu-title,
.menu-hover-bg-light-primary .menu-item .menu-link:hover:not(.disabled):not(.active) .menu-title {
  color: #009EF7;
}
.menu-hover-bg-light-primary .menu-item.hover > .menu-link:not(.disabled):not(.active) .menu-icon i,
.menu-hover-bg-light-primary .menu-item .menu-link:hover:not(.disabled):not(.active) .menu-icon i {
  color: #009EF7;
}
.menu-hover-bg-light-primary .menu-item.hover > .menu-link:not(.disabled):not(.active) .menu-icon .svg-icon svg [fill]:not(.permanent):not(g),
.menu-hover-bg-light-primary .menu-item .menu-link:hover:not(.disabled):not(.active) .menu-icon .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #009EF7;
}
.menu-hover-bg-light-primary .menu-item.hover > .menu-link:not(.disabled):not(.active) .menu-icon .svg-icon svg:hover [fill]:not(.permanent):not(g),
.menu-hover-bg-light-primary .menu-item .menu-link:hover:not(.disabled):not(.active) .menu-icon .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.menu-hover-bg-light-primary .menu-item.hover > .menu-link:not(.disabled):not(.active) .menu-bullet .bullet,
.menu-hover-bg-light-primary .menu-item .menu-link:hover:not(.disabled):not(.active) .menu-bullet .bullet {
  background-color: #009EF7;
}
.menu-hover-bg-light-primary .menu-item.hover > .menu-link:not(.disabled):not(.active) .menu-arrow:after,
.menu-hover-bg-light-primary .menu-item .menu-link:hover:not(.disabled):not(.active) .menu-arrow:after {
  background-repeat: no-repeat;
  background-position: center;
  background-color: transparent;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 9' fill='%23009EF7'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M5.93537 4.57889C6.03839 4.77912 6.0191 5.0363 5.87137 5.21403L2.87153 8.82282C2.68598 9.04603 2.36951 9.06026 2.16468 8.8546C1.95985 8.64893 1.94422 8.30126 2.12977 8.07804L4.80594 4.85863L2.15586 1.93583C1.96104 1.72096 1.96165 1.37314 2.15722 1.15895C2.35279 0.944757 2.66927 0.945311 2.86409 1.16018L5.85194 4.45551C5.8859 4.49296 5.91371 4.53459 5.93537 4.57889Z'/%3e%3c/svg%3e");
}

.menu-active-bg-light-primary .menu-item .menu-link.active {
  transition: color 0.2s ease, background-color 0.2s ease;
  background-color: #ECF8FF;
  color: #009EF7;
}
.menu-active-bg-light-primary .menu-item .menu-link.active .menu-title {
  color: #009EF7;
}
.menu-active-bg-light-primary .menu-item .menu-link.active .menu-icon i {
  color: #009EF7;
}
.menu-active-bg-light-primary .menu-item .menu-link.active .menu-icon .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #009EF7;
}
.menu-active-bg-light-primary .menu-item .menu-link.active .menu-icon .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.menu-active-bg-light-primary .menu-item .menu-link.active .menu-bullet .bullet {
  background-color: #009EF7;
}
.menu-active-bg-light-primary .menu-item .menu-link.active .menu-arrow:after {
  background-repeat: no-repeat;
  background-position: center;
  background-color: transparent;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 9' fill='%23009EF7'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M5.93537 4.57889C6.03839 4.77912 6.0191 5.0363 5.87137 5.21403L2.87153 8.82282C2.68598 9.04603 2.36951 9.06026 2.16468 8.8546C1.95985 8.64893 1.94422 8.30126 2.12977 8.07804L4.80594 4.85863L2.15586 1.93583C1.96104 1.72096 1.96165 1.37314 2.15722 1.15895C2.35279 0.944757 2.66927 0.945311 2.86409 1.16018L5.85194 4.45551C5.8859 4.49296 5.91371 4.53459 5.93537 4.57889Z'/%3e%3c/svg%3e");
}

.menu-state-bg-light-primary .menu-item.here > .menu-link, .menu-state-bg-light-primary .menu-item.show > .menu-link {
  transition: color 0.2s ease, background-color 0.2s ease;
  background-color: #ECF8FF;
  color: #009EF7;
}
.menu-state-bg-light-primary .menu-item.here > .menu-link .menu-title, .menu-state-bg-light-primary .menu-item.show > .menu-link .menu-title {
  color: #009EF7;
}
.menu-state-bg-light-primary .menu-item.here > .menu-link .menu-icon i, .menu-state-bg-light-primary .menu-item.show > .menu-link .menu-icon i {
  color: #009EF7;
}
.menu-state-bg-light-primary .menu-item.here > .menu-link .menu-icon .svg-icon svg [fill]:not(.permanent):not(g), .menu-state-bg-light-primary .menu-item.show > .menu-link .menu-icon .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #009EF7;
}
.menu-state-bg-light-primary .menu-item.here > .menu-link .menu-icon .svg-icon svg:hover [fill]:not(.permanent):not(g), .menu-state-bg-light-primary .menu-item.show > .menu-link .menu-icon .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.menu-state-bg-light-primary .menu-item.here > .menu-link .menu-bullet .bullet, .menu-state-bg-light-primary .menu-item.show > .menu-link .menu-bullet .bullet {
  background-color: #009EF7;
}
.menu-state-bg-light-primary .menu-item.here > .menu-link .menu-arrow:after, .menu-state-bg-light-primary .menu-item.show > .menu-link .menu-arrow:after {
  background-repeat: no-repeat;
  background-position: center;
  background-color: transparent;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 9' fill='%23009EF7'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M5.93537 4.57889C6.03839 4.77912 6.0191 5.0363 5.87137 5.21403L2.87153 8.82282C2.68598 9.04603 2.36951 9.06026 2.16468 8.8546C1.95985 8.64893 1.94422 8.30126 2.12977 8.07804L4.80594 4.85863L2.15586 1.93583C1.96104 1.72096 1.96165 1.37314 2.15722 1.15895C2.35279 0.944757 2.66927 0.945311 2.86409 1.16018L5.85194 4.45551C5.8859 4.49296 5.91371 4.53459 5.93537 4.57889Z'/%3e%3c/svg%3e");
}
.menu-state-bg-light-primary .menu-item.hover > .menu-link:not(.disabled):not(.active),
.menu-state-bg-light-primary .menu-item .menu-link:hover:not(.disabled):not(.active) {
  transition: color 0.2s ease, background-color 0.2s ease;
  background-color: #ECF8FF;
  color: #009EF7;
}
.menu-state-bg-light-primary .menu-item.hover > .menu-link:not(.disabled):not(.active) .menu-title,
.menu-state-bg-light-primary .menu-item .menu-link:hover:not(.disabled):not(.active) .menu-title {
  color: #009EF7;
}
.menu-state-bg-light-primary .menu-item.hover > .menu-link:not(.disabled):not(.active) .menu-icon i,
.menu-state-bg-light-primary .menu-item .menu-link:hover:not(.disabled):not(.active) .menu-icon i {
  color: #009EF7;
}
.menu-state-bg-light-primary .menu-item.hover > .menu-link:not(.disabled):not(.active) .menu-icon .svg-icon svg [fill]:not(.permanent):not(g),
.menu-state-bg-light-primary .menu-item .menu-link:hover:not(.disabled):not(.active) .menu-icon .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #009EF7;
}
.menu-state-bg-light-primary .menu-item.hover > .menu-link:not(.disabled):not(.active) .menu-icon .svg-icon svg:hover [fill]:not(.permanent):not(g),
.menu-state-bg-light-primary .menu-item .menu-link:hover:not(.disabled):not(.active) .menu-icon .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.menu-state-bg-light-primary .menu-item.hover > .menu-link:not(.disabled):not(.active) .menu-bullet .bullet,
.menu-state-bg-light-primary .menu-item .menu-link:hover:not(.disabled):not(.active) .menu-bullet .bullet {
  background-color: #009EF7;
}
.menu-state-bg-light-primary .menu-item.hover > .menu-link:not(.disabled):not(.active) .menu-arrow:after,
.menu-state-bg-light-primary .menu-item .menu-link:hover:not(.disabled):not(.active) .menu-arrow:after {
  background-repeat: no-repeat;
  background-position: center;
  background-color: transparent;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 9' fill='%23009EF7'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M5.93537 4.57889C6.03839 4.77912 6.0191 5.0363 5.87137 5.21403L2.87153 8.82282C2.68598 9.04603 2.36951 9.06026 2.16468 8.8546C1.95985 8.64893 1.94422 8.30126 2.12977 8.07804L4.80594 4.85863L2.15586 1.93583C1.96104 1.72096 1.96165 1.37314 2.15722 1.15895C2.35279 0.944757 2.66927 0.945311 2.86409 1.16018L5.85194 4.45551C5.8859 4.49296 5.91371 4.53459 5.93537 4.57889Z'/%3e%3c/svg%3e");
}
.menu-state-bg-light-primary .menu-item .menu-link.active {
  transition: color 0.2s ease, background-color 0.2s ease;
  background-color: #ECF8FF;
  color: #009EF7;
}
.menu-state-bg-light-primary .menu-item .menu-link.active .menu-title {
  color: #009EF7;
}
.menu-state-bg-light-primary .menu-item .menu-link.active .menu-icon i {
  color: #009EF7;
}
.menu-state-bg-light-primary .menu-item .menu-link.active .menu-icon .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #009EF7;
}
.menu-state-bg-light-primary .menu-item .menu-link.active .menu-icon .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.menu-state-bg-light-primary .menu-item .menu-link.active .menu-bullet .bullet {
  background-color: #009EF7;
}
.menu-state-bg-light-primary .menu-item .menu-link.active .menu-arrow:after {
  background-repeat: no-repeat;
  background-position: center;
  background-color: transparent;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 9' fill='%23009EF7'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M5.93537 4.57889C6.03839 4.77912 6.0191 5.0363 5.87137 5.21403L2.87153 8.82282C2.68598 9.04603 2.36951 9.06026 2.16468 8.8546C1.95985 8.64893 1.94422 8.30126 2.12977 8.07804L4.80594 4.85863L2.15586 1.93583C1.96104 1.72096 1.96165 1.37314 2.15722 1.15895C2.35279 0.944757 2.66927 0.945311 2.86409 1.16018L5.85194 4.45551C5.8859 4.49296 5.91371 4.53459 5.93537 4.57889Z'/%3e%3c/svg%3e");
}

.menu-hover-title-primary .menu-item.hover > .menu-link:not(.disabled):not(.active),
.menu-hover-title-primary .menu-item .menu-link:hover:not(.disabled):not(.active) {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: #009EF7;
}
.menu-hover-title-primary .menu-item.hover > .menu-link:not(.disabled):not(.active) .menu-title,
.menu-hover-title-primary .menu-item .menu-link:hover:not(.disabled):not(.active) .menu-title {
  color: #009EF7;
}

.menu-show-title-primary .menu-item.here > .menu-link, .menu-show-title-primary .menu-item.show > .menu-link {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: #009EF7;
}
.menu-show-title-primary .menu-item.here > .menu-link .menu-title, .menu-show-title-primary .menu-item.show > .menu-link .menu-title {
  color: #009EF7;
}

.menu-active-title-primary .menu-item .menu-link.active {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: #009EF7;
}
.menu-active-title-primary .menu-item .menu-link.active .menu-title {
  color: #009EF7;
}

.menu-state-title-primary .menu-item.hover > .menu-link:not(.disabled):not(.active),
.menu-state-title-primary .menu-item .menu-link:hover:not(.disabled):not(.active) {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: #009EF7;
}
.menu-state-title-primary .menu-item.hover > .menu-link:not(.disabled):not(.active) .menu-title,
.menu-state-title-primary .menu-item .menu-link:hover:not(.disabled):not(.active) .menu-title {
  color: #009EF7;
}
.menu-state-title-primary .menu-item.here > .menu-link, .menu-state-title-primary .menu-item.show > .menu-link {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: #009EF7;
}
.menu-state-title-primary .menu-item.here > .menu-link .menu-title, .menu-state-title-primary .menu-item.show > .menu-link .menu-title {
  color: #009EF7;
}
.menu-state-title-primary .menu-item .menu-link.active {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: #009EF7;
}
.menu-state-title-primary .menu-item .menu-link.active .menu-title {
  color: #009EF7;
}

.menu-hover-icon-primary .menu-item.hover > .menu-link:not(.disabled):not(.active),
.menu-hover-icon-primary .menu-item .menu-link:hover:not(.disabled):not(.active) {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.menu-hover-icon-primary .menu-item.hover > .menu-link:not(.disabled):not(.active) .menu-icon i,
.menu-hover-icon-primary .menu-item .menu-link:hover:not(.disabled):not(.active) .menu-icon i {
  color: #009EF7;
}
.menu-hover-icon-primary .menu-item.hover > .menu-link:not(.disabled):not(.active) .menu-icon .svg-icon svg [fill]:not(.permanent):not(g),
.menu-hover-icon-primary .menu-item .menu-link:hover:not(.disabled):not(.active) .menu-icon .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #009EF7;
}
.menu-hover-icon-primary .menu-item.hover > .menu-link:not(.disabled):not(.active) .menu-icon .svg-icon svg:hover [fill]:not(.permanent):not(g),
.menu-hover-icon-primary .menu-item .menu-link:hover:not(.disabled):not(.active) .menu-icon .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}

.menu-show-icon-primary .menu-item.here > .menu-link, .menu-show-icon-primary .menu-item.show > .menu-link {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.menu-show-icon-primary .menu-item.here > .menu-link .menu-icon i, .menu-show-icon-primary .menu-item.show > .menu-link .menu-icon i {
  color: #009EF7;
}
.menu-show-icon-primary .menu-item.here > .menu-link .menu-icon .svg-icon svg [fill]:not(.permanent):not(g), .menu-show-icon-primary .menu-item.show > .menu-link .menu-icon .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #009EF7;
}
.menu-show-icon-primary .menu-item.here > .menu-link .menu-icon .svg-icon svg:hover [fill]:not(.permanent):not(g), .menu-show-icon-primary .menu-item.show > .menu-link .menu-icon .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}

.menu-active-icon-primary .menu-item .menu-link.active {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.menu-active-icon-primary .menu-item .menu-link.active .menu-icon i {
  color: #009EF7;
}
.menu-active-icon-primary .menu-item .menu-link.active .menu-icon .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #009EF7;
}
.menu-active-icon-primary .menu-item .menu-link.active .menu-icon .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}

.menu-state-icon-primary .menu-item.hover > .menu-link:not(.disabled):not(.active),
.menu-state-icon-primary .menu-item .menu-link:hover:not(.disabled):not(.active) {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.menu-state-icon-primary .menu-item.hover > .menu-link:not(.disabled):not(.active) .menu-icon i,
.menu-state-icon-primary .menu-item .menu-link:hover:not(.disabled):not(.active) .menu-icon i {
  color: #009EF7;
}
.menu-state-icon-primary .menu-item.hover > .menu-link:not(.disabled):not(.active) .menu-icon .svg-icon svg [fill]:not(.permanent):not(g),
.menu-state-icon-primary .menu-item .menu-link:hover:not(.disabled):not(.active) .menu-icon .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #009EF7;
}
.menu-state-icon-primary .menu-item.hover > .menu-link:not(.disabled):not(.active) .menu-icon .svg-icon svg:hover [fill]:not(.permanent):not(g),
.menu-state-icon-primary .menu-item .menu-link:hover:not(.disabled):not(.active) .menu-icon .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.menu-state-icon-primary .menu-item.here > .menu-link, .menu-state-icon-primary .menu-item.show > .menu-link {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.menu-state-icon-primary .menu-item.here > .menu-link .menu-icon i, .menu-state-icon-primary .menu-item.show > .menu-link .menu-icon i {
  color: #009EF7;
}
.menu-state-icon-primary .menu-item.here > .menu-link .menu-icon .svg-icon svg [fill]:not(.permanent):not(g), .menu-state-icon-primary .menu-item.show > .menu-link .menu-icon .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #009EF7;
}
.menu-state-icon-primary .menu-item.here > .menu-link .menu-icon .svg-icon svg:hover [fill]:not(.permanent):not(g), .menu-state-icon-primary .menu-item.show > .menu-link .menu-icon .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.menu-state-icon-primary .menu-item .menu-link.active {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.menu-state-icon-primary .menu-item .menu-link.active .menu-icon i {
  color: #009EF7;
}
.menu-state-icon-primary .menu-item .menu-link.active .menu-icon .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #009EF7;
}
.menu-state-icon-primary .menu-item .menu-link.active .menu-icon .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}

.menu-hover-bullet-primary .menu-item.hover > .menu-link:not(.disabled):not(.active),
.menu-hover-bullet-primary .menu-item .menu-link:hover:not(.disabled):not(.active) {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.menu-hover-bullet-primary .menu-item.hover > .menu-link:not(.disabled):not(.active) .menu-bullet .bullet,
.menu-hover-bullet-primary .menu-item .menu-link:hover:not(.disabled):not(.active) .menu-bullet .bullet {
  background-color: #009EF7;
}

.menu-show-bullet-primary .menu-item.here > .menu-link, .menu-show-bullet-primary .menu-item.show > .menu-link {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.menu-show-bullet-primary .menu-item.here > .menu-link .menu-bullet .bullet, .menu-show-bullet-primary .menu-item.show > .menu-link .menu-bullet .bullet {
  background-color: #009EF7;
}

.menu-active-bullet-primary .menu-item .menu-link.active {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.menu-active-bullet-primary .menu-item .menu-link.active .menu-bullet .bullet {
  background-color: #009EF7;
}

.menu-state-bullet-primary .menu-item.hover > .menu-link:not(.disabled):not(.active),
.menu-state-bullet-primary .menu-item .menu-link:hover:not(.disabled):not(.active) {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.menu-state-bullet-primary .menu-item.hover > .menu-link:not(.disabled):not(.active) .menu-bullet .bullet,
.menu-state-bullet-primary .menu-item .menu-link:hover:not(.disabled):not(.active) .menu-bullet .bullet {
  background-color: #009EF7;
}
.menu-state-bullet-primary .menu-item.here > .menu-link, .menu-state-bullet-primary .menu-item.show > .menu-link {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.menu-state-bullet-primary .menu-item.here > .menu-link .menu-bullet .bullet, .menu-state-bullet-primary .menu-item.show > .menu-link .menu-bullet .bullet {
  background-color: #009EF7;
}
.menu-state-bullet-primary .menu-item .menu-link.active {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.menu-state-bullet-primary .menu-item .menu-link.active .menu-bullet .bullet {
  background-color: #009EF7;
}

.menu-hover-arrow-primary .menu-item.hover > .menu-link:not(.disabled):not(.active),
.menu-hover-arrow-primary .menu-item .menu-link:hover:not(.disabled):not(.active) {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.menu-hover-arrow-primary .menu-item.hover > .menu-link:not(.disabled):not(.active) .menu-arrow:after,
.menu-hover-arrow-primary .menu-item .menu-link:hover:not(.disabled):not(.active) .menu-arrow:after {
  background-repeat: no-repeat;
  background-position: center;
  background-color: transparent;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 9' fill='%23009EF7'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M5.93537 4.57889C6.03839 4.77912 6.0191 5.0363 5.87137 5.21403L2.87153 8.82282C2.68598 9.04603 2.36951 9.06026 2.16468 8.8546C1.95985 8.64893 1.94422 8.30126 2.12977 8.07804L4.80594 4.85863L2.15586 1.93583C1.96104 1.72096 1.96165 1.37314 2.15722 1.15895C2.35279 0.944757 2.66927 0.945311 2.86409 1.16018L5.85194 4.45551C5.8859 4.49296 5.91371 4.53459 5.93537 4.57889Z'/%3e%3c/svg%3e");
}

.menu-show-arrow-primary .menu-item.here > .menu-link, .menu-show-arrow-primary .menu-item.show > .menu-link {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.menu-show-arrow-primary .menu-item.here > .menu-link .menu-arrow:after, .menu-show-arrow-primary .menu-item.show > .menu-link .menu-arrow:after {
  background-repeat: no-repeat;
  background-position: center;
  background-color: transparent;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 9' fill='%23009EF7'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M5.93537 4.57889C6.03839 4.77912 6.0191 5.0363 5.87137 5.21403L2.87153 8.82282C2.68598 9.04603 2.36951 9.06026 2.16468 8.8546C1.95985 8.64893 1.94422 8.30126 2.12977 8.07804L4.80594 4.85863L2.15586 1.93583C1.96104 1.72096 1.96165 1.37314 2.15722 1.15895C2.35279 0.944757 2.66927 0.945311 2.86409 1.16018L5.85194 4.45551C5.8859 4.49296 5.91371 4.53459 5.93537 4.57889Z'/%3e%3c/svg%3e");
}

.menu-active-arrow-primary .menu-item .menu-link.active {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.menu-active-arrow-primary .menu-item .menu-link.active .menu-arrow:after {
  background-repeat: no-repeat;
  background-position: center;
  background-color: transparent;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 9' fill='%23009EF7'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M5.93537 4.57889C6.03839 4.77912 6.0191 5.0363 5.87137 5.21403L2.87153 8.82282C2.68598 9.04603 2.36951 9.06026 2.16468 8.8546C1.95985 8.64893 1.94422 8.30126 2.12977 8.07804L4.80594 4.85863L2.15586 1.93583C1.96104 1.72096 1.96165 1.37314 2.15722 1.15895C2.35279 0.944757 2.66927 0.945311 2.86409 1.16018L5.85194 4.45551C5.8859 4.49296 5.91371 4.53459 5.93537 4.57889Z'/%3e%3c/svg%3e");
}

.menu-state-arrow-primary .menu-item.hover > .menu-link:not(.disabled):not(.active),
.menu-state-arrow-primary .menu-item .menu-link:hover:not(.disabled):not(.active) {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.menu-state-arrow-primary .menu-item.hover > .menu-link:not(.disabled):not(.active) .menu-arrow:after,
.menu-state-arrow-primary .menu-item .menu-link:hover:not(.disabled):not(.active) .menu-arrow:after {
  background-repeat: no-repeat;
  background-position: center;
  background-color: transparent;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 9' fill='%23009EF7'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M5.93537 4.57889C6.03839 4.77912 6.0191 5.0363 5.87137 5.21403L2.87153 8.82282C2.68598 9.04603 2.36951 9.06026 2.16468 8.8546C1.95985 8.64893 1.94422 8.30126 2.12977 8.07804L4.80594 4.85863L2.15586 1.93583C1.96104 1.72096 1.96165 1.37314 2.15722 1.15895C2.35279 0.944757 2.66927 0.945311 2.86409 1.16018L5.85194 4.45551C5.8859 4.49296 5.91371 4.53459 5.93537 4.57889Z'/%3e%3c/svg%3e");
}
.menu-state-arrow-primary .menu-item.here > .menu-link, .menu-state-arrow-primary .menu-item.show > .menu-link {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.menu-state-arrow-primary .menu-item.here > .menu-link .menu-arrow:after, .menu-state-arrow-primary .menu-item.show > .menu-link .menu-arrow:after {
  background-repeat: no-repeat;
  background-position: center;
  background-color: transparent;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 9' fill='%23009EF7'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M5.93537 4.57889C6.03839 4.77912 6.0191 5.0363 5.87137 5.21403L2.87153 8.82282C2.68598 9.04603 2.36951 9.06026 2.16468 8.8546C1.95985 8.64893 1.94422 8.30126 2.12977 8.07804L4.80594 4.85863L2.15586 1.93583C1.96104 1.72096 1.96165 1.37314 2.15722 1.15895C2.35279 0.944757 2.66927 0.945311 2.86409 1.16018L5.85194 4.45551C5.8859 4.49296 5.91371 4.53459 5.93537 4.57889Z'/%3e%3c/svg%3e");
}
.menu-state-arrow-primary .menu-item .menu-link.active {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.menu-state-arrow-primary .menu-item .menu-link.active .menu-arrow:after {
  background-repeat: no-repeat;
  background-position: center;
  background-color: transparent;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 9' fill='%23009EF7'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M5.93537 4.57889C6.03839 4.77912 6.0191 5.0363 5.87137 5.21403L2.87153 8.82282C2.68598 9.04603 2.36951 9.06026 2.16468 8.8546C1.95985 8.64893 1.94422 8.30126 2.12977 8.07804L4.80594 4.85863L2.15586 1.93583C1.96104 1.72096 1.96165 1.37314 2.15722 1.15895C2.35279 0.944757 2.66927 0.945311 2.86409 1.16018L5.85194 4.45551C5.8859 4.49296 5.91371 4.53459 5.93537 4.57889Z'/%3e%3c/svg%3e");
}

.anchor {
  display: flex;
  align-items: center;
}
.anchor a {
  position: relative;
  display: none;
  align-items: center;
  justify-content: flex-start;
  height: 1em;
  width: 1.25em;
  margin-left: -1.25em;
  font-weight: 500;
  font-size: 0.8em;
  color: #A1A5B7;
  transition: all 0.2s ease;
}
.anchor a:before {
  content: "#";
}
.anchor:hover a {
  display: flex;
}
.anchor:hover a:hover {
  color: #009EF7;
  transition: all 0.2s ease;
}

.card {
  box-shadow: 0px 0px 20px 0px rgba(76, 87, 125, 0.02);
  border: 0;
}
.card > .card-header {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  flex-wrap: wrap;
  min-height: 70px;
  padding: 0 2.25rem;
  background-color: transparent;
}
.card > .card-header .card-title {
  display: flex;
  align-items: center;
  margin: 0.5rem;
  margin-left: 0;
}
.card > .card-header .card-title.flex-column {
  align-items: flex-start;
  justify-content: center;
}
.card > .card-header .card-title .card-icon {
  margin-right: 0.75rem;
  line-height: 0;
}
.card > .card-header .card-title .card-icon i {
  font-size: 1.25rem;
  color: #7E8299;
  line-height: 0;
}
.card > .card-header .card-title .card-icon i:after, .card > .card-header .card-title .card-icon i:before {
  line-height: 0;
}
.card > .card-header .card-title .card-icon .svg-icon svg {
  height: 24px;
  width: 24px;
}
.card > .card-header .card-title .card-icon .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #7E8299;
}
.card > .card-header .card-title .card-icon .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.card > .card-header .card-title,
.card > .card-header .card-title .card-label {
  font-weight: 500;
  font-size: 1.275rem;
  color: #181C32;
}
.card > .card-header .card-title .card-label {
  margin: 0 0.75rem 0 0;
  flex-wrap: wrap;
}
.card > .card-header .card-title small, .card > .card-header .card-title .small {
  color: #A1A5B7;
  font-size: 1rem;
}
.card > .card-header .card-title h1, .card > .card-header .card-title .h1, .card > .card-header .card-title h2, .card > .card-header .card-title .h2, .card > .card-header .card-title h3, .card > .card-header .card-title .h3, .card > .card-header .card-title h4, .card > .card-header .card-title .h4, .card > .card-header .card-title h5, .card > .card-header .card-title .h5, .card > .card-header .card-title h6, .card > .card-header .card-title .h6 {
  margin-bottom: 0;
}
.card > .card-header .card-toolbar {
  display: flex;
  align-items: center;
  margin: 0.5rem 0;
  flex-wrap: wrap;
}
.card > .card-body {
  padding: 2rem 2.25rem;
}
.card > .card-footer {
  padding: 2rem 2.25rem;
  background-color: transparent;
}
.card .card-scroll {
  position: relative;
  overflow: auto;
}
.card.card-flush > .card-header {
  border-bottom: 0;
}
.card.card-flush > .card-footer {
  border-top: 0;
}
.card.card-px-0 .card-header,
.card.card-px-0 .card-body,
.card.card-px-0 .card-footer {
  padding-left: 0;
  padding-right: 0;
}
.card.card-outline {
  box-shadow: none;
  border: 1px solid #EFF2F5;
}
.card.card-dashed {
  box-shadow: none;
  border: 1px dashed #E4E6EF;
}

.card.card-stretch {
  height: calc(100% - var(--bs-gutter-y));
}
.card.card-stretch-75 {
  height: calc(75% - var(--bs-gutter-y));
}
.card.card-stretch-50 {
  height: calc(50% - var(--bs-gutter-y));
}
.card.card-stretch-33 {
  height: calc(33.333% - var(--bs-gutter-y));
}
.card.card-stretch-25 {
  height: calc(25% - var(--bs-gutter-y));
}
.card .card-header-stretch {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  align-items: stretch;
}
.card .card-header-stretch .card-toolbar {
  margin: 0;
  align-items: stretch;
}
@media (min-width: 576px) {
  .card.card-sm-stretch {
    height: calc(100% - var(--bs-gutter-y));
  }
  .card.card-sm-stretch-75 {
    height: calc(75% - var(--bs-gutter-y));
  }
  .card.card-sm-stretch-50 {
    height: calc(50% - var(--bs-gutter-y));
  }
  .card.card-sm-stretch-33 {
    height: calc(33.333% - var(--bs-gutter-y));
  }
  .card.card-sm-stretch-25 {
    height: calc(25% - var(--bs-gutter-y));
  }
  .card .card-header-sm-stretch {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    align-items: stretch;
  }
  .card .card-header-sm-stretch .card-toolbar {
    margin: 0;
    align-items: stretch;
  }
}
@media (min-width: 768px) {
  .card.card-md-stretch {
    height: calc(100% - var(--bs-gutter-y));
  }
  .card.card-md-stretch-75 {
    height: calc(75% - var(--bs-gutter-y));
  }
  .card.card-md-stretch-50 {
    height: calc(50% - var(--bs-gutter-y));
  }
  .card.card-md-stretch-33 {
    height: calc(33.333% - var(--bs-gutter-y));
  }
  .card.card-md-stretch-25 {
    height: calc(25% - var(--bs-gutter-y));
  }
  .card .card-header-md-stretch {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    align-items: stretch;
  }
  .card .card-header-md-stretch .card-toolbar {
    margin: 0;
    align-items: stretch;
  }
}
@media (min-width: 992px) {
  .card.card-lg-stretch {
    height: calc(100% - var(--bs-gutter-y));
  }
  .card.card-lg-stretch-75 {
    height: calc(75% - var(--bs-gutter-y));
  }
  .card.card-lg-stretch-50 {
    height: calc(50% - var(--bs-gutter-y));
  }
  .card.card-lg-stretch-33 {
    height: calc(33.333% - var(--bs-gutter-y));
  }
  .card.card-lg-stretch-25 {
    height: calc(25% - var(--bs-gutter-y));
  }
  .card .card-header-lg-stretch {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    align-items: stretch;
  }
  .card .card-header-lg-stretch .card-toolbar {
    margin: 0;
    align-items: stretch;
  }
}
@media (min-width: 1200px) {
  .card.card-xl-stretch {
    height: calc(100% - var(--bs-gutter-y));
  }
  .card.card-xl-stretch-75 {
    height: calc(75% - var(--bs-gutter-y));
  }
  .card.card-xl-stretch-50 {
    height: calc(50% - var(--bs-gutter-y));
  }
  .card.card-xl-stretch-33 {
    height: calc(33.333% - var(--bs-gutter-y));
  }
  .card.card-xl-stretch-25 {
    height: calc(25% - var(--bs-gutter-y));
  }
  .card .card-header-xl-stretch {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    align-items: stretch;
  }
  .card .card-header-xl-stretch .card-toolbar {
    margin: 0;
    align-items: stretch;
  }
}
@media (min-width: 1400px) {
  .card.card-xxl-stretch {
    height: calc(100% - var(--bs-gutter-y));
  }
  .card.card-xxl-stretch-75 {
    height: calc(75% - var(--bs-gutter-y));
  }
  .card.card-xxl-stretch-50 {
    height: calc(50% - var(--bs-gutter-y));
  }
  .card.card-xxl-stretch-33 {
    height: calc(33.333% - var(--bs-gutter-y));
  }
  .card.card-xxl-stretch-25 {
    height: calc(25% - var(--bs-gutter-y));
  }
  .card .card-header-xxl-stretch {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    align-items: stretch;
  }
  .card .card-header-xxl-stretch .card-toolbar {
    margin: 0;
    align-items: stretch;
  }
}

.card-p {
  padding: 2rem 2.25rem !important;
}

.card-px {
  padding-left: 2.25rem !important;
  padding-right: 2.25rem !important;
}

.card-py {
  padding-top: 2rem !important;
  padding-bottom: 2rem !important;
}

.card-rounded {
  border-radius: 0.475rem;
}

.card-rounded-start {
  border-top-left-radius: 0.475rem;
  border-bottom-left-radius: 0.475rem;
}

.card-rounded-end {
  border-top-right-radius: 0.475rem;
  border-bottom-right-radius: 0.475rem;
}

.card-rounded-top {
  border-top-left-radius: 0.475rem;
  border-top-right-radius: 0.475rem;
}

.card-rounded-bottom {
  border-bottom-left-radius: 0.475rem;
  border-bottom-right-radius: 0.475rem;
}

@media (max-width: 767.98px) {
  .card > .card-header:not(.flex-nowrap) {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }
}
.breadcrumb {
  display: flex;
  align-items: center;
  background-color: transparent;
  padding: 0;
  margin: 0;
}
.breadcrumb .breadcrumb-item {
  display: flex;
  align-items: center;
  padding-left: 0;
  padding-right: 0.5rem;
}
.breadcrumb .breadcrumb-item:last-child {
  padding-right: 0;
}
.breadcrumb .breadcrumb-item:after {
  content: "/";
  padding-left: 0.5rem;
}
.breadcrumb .breadcrumb-item:before {
  display: none;
}
.breadcrumb .breadcrumb-item:last-child:after {
  display: none;
}

.breadcrumb-line .breadcrumb-item:after {
  content: "-";
}

.breadcrumb-dot .breadcrumb-item:after {
  content: "•";
}

.breadcrumb-separatorless .breadcrumb-item:after {
  display: none;
}

.btn {
  outline: none !important;
}
.btn:not(.btn-shadow):not(.shadow):not(.shadow-sm):not(.shadow-lg) {
  box-shadow: none !important;
}
.btn:not(.btn-outline):not(.btn-dashed):not(.border-hover):not(.border-active):not(.btn-flush):not(.btn-icon) {
  border: 0;
  padding: calc(0.75rem + 1px) calc(1.5rem + 1px);
}
.btn:not(.btn-outline):not(.btn-dashed):not(.border-hover):not(.border-active):not(.btn-flush):not(.btn-icon).btn-lg, .btn-group-lg > .btn:not(.btn-outline):not(.btn-dashed):not(.border-hover):not(.border-active):not(.btn-flush):not(.btn-icon) {
  padding: calc(0.825rem + 1px) calc(1.75rem + 1px);
}
.btn:not(.btn-outline):not(.btn-dashed):not(.border-hover):not(.border-active):not(.btn-flush):not(.btn-icon).btn-sm, .btn-group-sm > .btn:not(.btn-outline):not(.btn-dashed):not(.border-hover):not(.border-active):not(.btn-flush):not(.btn-icon) {
  padding: calc(0.55rem + 1px) calc(1.25rem + 1px);
}
.btn.btn-link {
  border: 0;
  border-radius: 0;
  padding-left: 0 !important;
  padding-right: 0 !important;
  text-decoration: none;
  font-weight: 500;
}
.btn.btn-outline-dashed {
  border: 1px dashed #E4E6EF;
}
.btn.btn-flush {
  appearance: none;
  box-shadow: none;
  border-radius: 0;
  border: none;
  cursor: pointer;
  background-color: transparent;
  outline: none !important;
  margin: 0;
  padding: 0;
}
.btn.btn-flex {
  display: inline-flex;
  align-items: center;
}

.btn i {
  display: inline-flex;
  font-size: 1rem;
  padding-right: 0.35rem;
  vertical-align: middle;
  line-height: 0;
}
.btn .svg-icon {
  flex-shrink: 0;
  line-height: 0;
  margin-right: 0.5rem;
}
.btn.btn-icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  height: calc(1.5em + 1.5rem + 2px);
  width: calc(1.5em + 1.5rem + 2px);
}
.btn.btn-icon:not(.btn-outline):not(.btn-dashed):not(.border-hover):not(.border-active):not(.btn-flush) {
  border: 0;
}
.btn.btn-icon.btn-sm, .btn-group-sm > .btn.btn-icon {
  height: calc(1.5em + 1.1rem + 2px);
  width: calc(1.5em + 1.1rem + 2px);
}
.btn.btn-icon.btn-lg, .btn-group-lg > .btn.btn-icon {
  height: calc(1.5em + 1.65rem + 2px);
  width: calc(1.5em + 1.65rem + 2px);
}
.btn.btn-icon.btn-circle {
  border-radius: 50%;
}
.btn.btn-icon i,
.btn.btn-icon .svg-icon {
  padding: 0;
  margin: 0;
  line-height: 1;
}

.btn.btn-white {
  color: #7E8299;
  border-color: #ffffff;
  background-color: #ffffff;
}
.btn.btn-white i {
  color: #7E8299;
}
.btn.btn-white .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #7E8299;
}
.btn.btn-white .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.btn.btn-white.dropdown-toggle:after {
  color: #7E8299;
}
.btn-check:checked + .btn.btn-white, .btn-check:active + .btn.btn-white, .btn.btn-white:focus:not(.btn-active), .btn.btn-white:hover:not(.btn-active), .btn.btn-white:active:not(.btn-active), .btn.btn-white.active, .btn.btn-white.show, .show > .btn.btn-white {
  color: #7E8299;
  border-color: #F5F8FA;
  background-color: #F5F8FA;
}
.btn-check:checked + .btn.btn-white i, .btn-check:active + .btn.btn-white i, .btn.btn-white:focus:not(.btn-active) i, .btn.btn-white:hover:not(.btn-active) i, .btn.btn-white:active:not(.btn-active) i, .btn.btn-white.active i, .btn.btn-white.show i, .show > .btn.btn-white i {
  color: #7E8299;
}
.btn-check:checked + .btn.btn-white .svg-icon svg [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-white .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-white:focus:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-white:hover:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-white:active:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-white.active .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-white.show .svg-icon svg [fill]:not(.permanent):not(g), .show > .btn.btn-white .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #7E8299;
}
.btn-check:checked + .btn.btn-white .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-white .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-white:focus:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-white:hover:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-white:active:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-white.active .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-white.show .svg-icon svg:hover [fill]:not(.permanent):not(g), .show > .btn.btn-white .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.btn-check:checked + .btn.btn-white.dropdown-toggle:after, .btn-check:active + .btn.btn-white.dropdown-toggle:after, .btn.btn-white:focus:not(.btn-active).dropdown-toggle:after, .btn.btn-white:hover:not(.btn-active).dropdown-toggle:after, .btn.btn-white:active:not(.btn-active).dropdown-toggle:after, .btn.btn-white.active.dropdown-toggle:after, .btn.btn-white.show.dropdown-toggle:after, .show > .btn.btn-white.dropdown-toggle:after {
  color: #7E8299;
}

.btn.btn-bg-white {
  border-color: #ffffff;
  background-color: #ffffff;
}
.btn-check:checked + .btn.btn-active-white, .btn-check:active + .btn.btn-active-white, .btn.btn-active-white:focus:not(.btn-active), .btn.btn-active-white:hover:not(.btn-active), .btn.btn-active-white:active:not(.btn-active), .btn.btn-active-white.active, .btn.btn-active-white.show, .show > .btn.btn-active-white {
  color: #7E8299;
  border-color: #ffffff;
  background-color: #ffffff;
}
.btn-check:checked + .btn.btn-active-white i, .btn-check:active + .btn.btn-active-white i, .btn.btn-active-white:focus:not(.btn-active) i, .btn.btn-active-white:hover:not(.btn-active) i, .btn.btn-active-white:active:not(.btn-active) i, .btn.btn-active-white.active i, .btn.btn-active-white.show i, .show > .btn.btn-active-white i {
  color: #7E8299;
}
.btn-check:checked + .btn.btn-active-white .svg-icon svg [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-active-white .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-white:focus:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-white:hover:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-white:active:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-white.active .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-white.show .svg-icon svg [fill]:not(.permanent):not(g), .show > .btn.btn-active-white .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #7E8299;
}
.btn-check:checked + .btn.btn-active-white .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-active-white .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-white:focus:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-white:hover:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-white:active:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-white.active .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-white.show .svg-icon svg:hover [fill]:not(.permanent):not(g), .show > .btn.btn-active-white .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.btn-check:checked + .btn.btn-active-white.dropdown-toggle:after, .btn-check:active + .btn.btn-active-white.dropdown-toggle:after, .btn.btn-active-white:focus:not(.btn-active).dropdown-toggle:after, .btn.btn-active-white:hover:not(.btn-active).dropdown-toggle:after, .btn.btn-active-white:active:not(.btn-active).dropdown-toggle:after, .btn.btn-active-white.active.dropdown-toggle:after, .btn.btn-active-white.show.dropdown-toggle:after, .show > .btn.btn-active-white.dropdown-toggle:after {
  color: #7E8299;
}

.btn.btn-light {
  color: #7E8299;
  border-color: #F5F8FA;
  background-color: #F5F8FA;
}
.btn.btn-light i {
  color: #7E8299;
}
.btn.btn-light .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #7E8299;
}
.btn.btn-light .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.btn.btn-light.dropdown-toggle:after {
  color: #7E8299;
}
.btn-check:checked + .btn.btn-light, .btn-check:active + .btn.btn-light, .btn.btn-light:focus:not(.btn-active), .btn.btn-light:hover:not(.btn-active), .btn.btn-light:active:not(.btn-active), .btn.btn-light.active, .btn.btn-light.show, .show > .btn.btn-light {
  color: #7E8299;
  border-color: #E4E6EF;
  background-color: #E4E6EF;
}
.btn-check:checked + .btn.btn-light i, .btn-check:active + .btn.btn-light i, .btn.btn-light:focus:not(.btn-active) i, .btn.btn-light:hover:not(.btn-active) i, .btn.btn-light:active:not(.btn-active) i, .btn.btn-light.active i, .btn.btn-light.show i, .show > .btn.btn-light i {
  color: #7E8299;
}
.btn-check:checked + .btn.btn-light .svg-icon svg [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-light .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-light:focus:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-light:hover:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-light:active:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-light.active .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-light.show .svg-icon svg [fill]:not(.permanent):not(g), .show > .btn.btn-light .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #7E8299;
}
.btn-check:checked + .btn.btn-light .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-light .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-light:focus:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-light:hover:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-light:active:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-light.active .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-light.show .svg-icon svg:hover [fill]:not(.permanent):not(g), .show > .btn.btn-light .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.btn-check:checked + .btn.btn-light.dropdown-toggle:after, .btn-check:active + .btn.btn-light.dropdown-toggle:after, .btn.btn-light:focus:not(.btn-active).dropdown-toggle:after, .btn.btn-light:hover:not(.btn-active).dropdown-toggle:after, .btn.btn-light:active:not(.btn-active).dropdown-toggle:after, .btn.btn-light.active.dropdown-toggle:after, .btn.btn-light.show.dropdown-toggle:after, .show > .btn.btn-light.dropdown-toggle:after {
  color: #7E8299;
}

.btn.btn-bg-light {
  border-color: #F5F8FA;
  background-color: #F5F8FA;
}
.btn-check:checked + .btn.btn-active-light, .btn-check:active + .btn.btn-active-light, .btn.btn-active-light:focus:not(.btn-active), .btn.btn-active-light:hover:not(.btn-active), .btn.btn-active-light:active:not(.btn-active), .btn.btn-active-light.active, .btn.btn-active-light.show, .show > .btn.btn-active-light {
  color: #7E8299;
  border-color: #F5F8FA;
  background-color: #F5F8FA;
}
.btn-check:checked + .btn.btn-active-light i, .btn-check:active + .btn.btn-active-light i, .btn.btn-active-light:focus:not(.btn-active) i, .btn.btn-active-light:hover:not(.btn-active) i, .btn.btn-active-light:active:not(.btn-active) i, .btn.btn-active-light.active i, .btn.btn-active-light.show i, .show > .btn.btn-active-light i {
  color: #7E8299;
}
.btn-check:checked + .btn.btn-active-light .svg-icon svg [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-active-light .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-light:focus:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-light:hover:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-light:active:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-light.active .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-light.show .svg-icon svg [fill]:not(.permanent):not(g), .show > .btn.btn-active-light .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #7E8299;
}
.btn-check:checked + .btn.btn-active-light .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-active-light .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-light:focus:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-light:hover:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-light:active:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-light.active .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-light.show .svg-icon svg:hover [fill]:not(.permanent):not(g), .show > .btn.btn-active-light .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.btn-check:checked + .btn.btn-active-light.dropdown-toggle:after, .btn-check:active + .btn.btn-active-light.dropdown-toggle:after, .btn.btn-active-light:focus:not(.btn-active).dropdown-toggle:after, .btn.btn-active-light:hover:not(.btn-active).dropdown-toggle:after, .btn.btn-active-light:active:not(.btn-active).dropdown-toggle:after, .btn.btn-active-light.active.dropdown-toggle:after, .btn.btn-active-light.show.dropdown-toggle:after, .show > .btn.btn-active-light.dropdown-toggle:after {
  color: #7E8299;
}

.btn.btn-primary {
  color: #FFFFFF;
  border-color: #009EF7;
  background-color: #009EF7;
}
.btn.btn-primary i {
  color: #FFFFFF;
}
.btn.btn-primary .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #FFFFFF;
}
.btn.btn-primary .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.btn.btn-primary.dropdown-toggle:after {
  color: #FFFFFF;
}
.btn-check:checked + .btn.btn-primary, .btn-check:active + .btn.btn-primary, .btn.btn-primary:focus:not(.btn-active), .btn.btn-primary:hover:not(.btn-active), .btn.btn-primary:active:not(.btn-active), .btn.btn-primary.active, .btn.btn-primary.show, .show > .btn.btn-primary {
  color: #FFFFFF;
  border-color: #0095E8;
  background-color: #0095E8;
}
.btn-check:checked + .btn.btn-primary i, .btn-check:active + .btn.btn-primary i, .btn.btn-primary:focus:not(.btn-active) i, .btn.btn-primary:hover:not(.btn-active) i, .btn.btn-primary:active:not(.btn-active) i, .btn.btn-primary.active i, .btn.btn-primary.show i, .show > .btn.btn-primary i {
  color: #FFFFFF;
}
.btn-check:checked + .btn.btn-primary .svg-icon svg [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-primary .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-primary:focus:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-primary:hover:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-primary:active:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-primary.active .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-primary.show .svg-icon svg [fill]:not(.permanent):not(g), .show > .btn.btn-primary .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #FFFFFF;
}
.btn-check:checked + .btn.btn-primary .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-primary .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-primary:focus:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-primary:hover:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-primary:active:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-primary.active .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-primary.show .svg-icon svg:hover [fill]:not(.permanent):not(g), .show > .btn.btn-primary .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.btn-check:checked + .btn.btn-primary.dropdown-toggle:after, .btn-check:active + .btn.btn-primary.dropdown-toggle:after, .btn.btn-primary:focus:not(.btn-active).dropdown-toggle:after, .btn.btn-primary:hover:not(.btn-active).dropdown-toggle:after, .btn.btn-primary:active:not(.btn-active).dropdown-toggle:after, .btn.btn-primary.active.dropdown-toggle:after, .btn.btn-primary.show.dropdown-toggle:after, .show > .btn.btn-primary.dropdown-toggle:after {
  color: #FFFFFF;
}

.btn.btn-light-primary {
  color: #009EF7;
  border-color: #ECF8FF;
  background-color: #ECF8FF;
}
.btn.btn-light-primary i {
  color: #009EF7;
}
.btn.btn-light-primary .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #009EF7;
}
.btn.btn-light-primary .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.btn.btn-light-primary.dropdown-toggle:after {
  color: #009EF7;
}
.btn-check:checked + .btn.btn-light-primary, .btn-check:active + .btn.btn-light-primary, .btn.btn-light-primary:focus:not(.btn-active), .btn.btn-light-primary:hover:not(.btn-active), .btn.btn-light-primary:active:not(.btn-active), .btn.btn-light-primary.active, .btn.btn-light-primary.show, .show > .btn.btn-light-primary {
  color: #FFFFFF;
  border-color: #009EF7;
  background-color: #009EF7;
}
.btn-check:checked + .btn.btn-light-primary i, .btn-check:active + .btn.btn-light-primary i, .btn.btn-light-primary:focus:not(.btn-active) i, .btn.btn-light-primary:hover:not(.btn-active) i, .btn.btn-light-primary:active:not(.btn-active) i, .btn.btn-light-primary.active i, .btn.btn-light-primary.show i, .show > .btn.btn-light-primary i {
  color: #FFFFFF;
}
.btn-check:checked + .btn.btn-light-primary .svg-icon svg [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-light-primary .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-light-primary:focus:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-light-primary:hover:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-light-primary:active:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-light-primary.active .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-light-primary.show .svg-icon svg [fill]:not(.permanent):not(g), .show > .btn.btn-light-primary .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #FFFFFF;
}
.btn-check:checked + .btn.btn-light-primary .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-light-primary .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-light-primary:focus:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-light-primary:hover:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-light-primary:active:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-light-primary.active .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-light-primary.show .svg-icon svg:hover [fill]:not(.permanent):not(g), .show > .btn.btn-light-primary .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.btn-check:checked + .btn.btn-light-primary.dropdown-toggle:after, .btn-check:active + .btn.btn-light-primary.dropdown-toggle:after, .btn.btn-light-primary:focus:not(.btn-active).dropdown-toggle:after, .btn.btn-light-primary:hover:not(.btn-active).dropdown-toggle:after, .btn.btn-light-primary:active:not(.btn-active).dropdown-toggle:after, .btn.btn-light-primary.active.dropdown-toggle:after, .btn.btn-light-primary.show.dropdown-toggle:after, .show > .btn.btn-light-primary.dropdown-toggle:after {
  color: #FFFFFF;
}

.btn.btn-bg-primary {
  border-color: #009EF7;
  background-color: #009EF7;
}
.btn-check:checked + .btn.btn-active-primary, .btn-check:active + .btn.btn-active-primary, .btn.btn-active-primary:focus:not(.btn-active), .btn.btn-active-primary:hover:not(.btn-active), .btn.btn-active-primary:active:not(.btn-active), .btn.btn-active-primary.active, .btn.btn-active-primary.show, .show > .btn.btn-active-primary {
  color: #FFFFFF;
  border-color: #009EF7;
  background-color: #009EF7;
}
.btn-check:checked + .btn.btn-active-primary i, .btn-check:active + .btn.btn-active-primary i, .btn.btn-active-primary:focus:not(.btn-active) i, .btn.btn-active-primary:hover:not(.btn-active) i, .btn.btn-active-primary:active:not(.btn-active) i, .btn.btn-active-primary.active i, .btn.btn-active-primary.show i, .show > .btn.btn-active-primary i {
  color: #FFFFFF;
}
.btn-check:checked + .btn.btn-active-primary .svg-icon svg [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-active-primary .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-primary:focus:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-primary:hover:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-primary:active:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-primary.active .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-primary.show .svg-icon svg [fill]:not(.permanent):not(g), .show > .btn.btn-active-primary .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #FFFFFF;
}
.btn-check:checked + .btn.btn-active-primary .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-active-primary .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-primary:focus:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-primary:hover:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-primary:active:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-primary.active .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-primary.show .svg-icon svg:hover [fill]:not(.permanent):not(g), .show > .btn.btn-active-primary .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.btn-check:checked + .btn.btn-active-primary.dropdown-toggle:after, .btn-check:active + .btn.btn-active-primary.dropdown-toggle:after, .btn.btn-active-primary:focus:not(.btn-active).dropdown-toggle:after, .btn.btn-active-primary:hover:not(.btn-active).dropdown-toggle:after, .btn.btn-active-primary:active:not(.btn-active).dropdown-toggle:after, .btn.btn-active-primary.active.dropdown-toggle:after, .btn.btn-active-primary.show.dropdown-toggle:after, .show > .btn.btn-active-primary.dropdown-toggle:after {
  color: #FFFFFF;
}

.btn-check:checked + .btn.btn-active-light-primary, .btn-check:active + .btn.btn-active-light-primary, .btn.btn-active-light-primary:focus:not(.btn-active), .btn.btn-active-light-primary:hover:not(.btn-active), .btn.btn-active-light-primary:active:not(.btn-active), .btn.btn-active-light-primary.active, .btn.btn-active-light-primary.show, .show > .btn.btn-active-light-primary {
  color: #009EF7;
  border-color: #ECF8FF;
  background-color: #ECF8FF;
}
.btn-check:checked + .btn.btn-active-light-primary i, .btn-check:active + .btn.btn-active-light-primary i, .btn.btn-active-light-primary:focus:not(.btn-active) i, .btn.btn-active-light-primary:hover:not(.btn-active) i, .btn.btn-active-light-primary:active:not(.btn-active) i, .btn.btn-active-light-primary.active i, .btn.btn-active-light-primary.show i, .show > .btn.btn-active-light-primary i {
  color: #009EF7;
}
.btn-check:checked + .btn.btn-active-light-primary .svg-icon svg [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-active-light-primary .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-light-primary:focus:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-light-primary:hover:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-light-primary:active:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-light-primary.active .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-light-primary.show .svg-icon svg [fill]:not(.permanent):not(g), .show > .btn.btn-active-light-primary .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #009EF7;
}
.btn-check:checked + .btn.btn-active-light-primary .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-active-light-primary .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-light-primary:focus:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-light-primary:hover:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-light-primary:active:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-light-primary.active .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-light-primary.show .svg-icon svg:hover [fill]:not(.permanent):not(g), .show > .btn.btn-active-light-primary .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.btn-check:checked + .btn.btn-active-light-primary.dropdown-toggle:after, .btn-check:active + .btn.btn-active-light-primary.dropdown-toggle:after, .btn.btn-active-light-primary:focus:not(.btn-active).dropdown-toggle:after, .btn.btn-active-light-primary:hover:not(.btn-active).dropdown-toggle:after, .btn.btn-active-light-primary:active:not(.btn-active).dropdown-toggle:after, .btn.btn-active-light-primary.active.dropdown-toggle:after, .btn.btn-active-light-primary.show.dropdown-toggle:after, .show > .btn.btn-active-light-primary.dropdown-toggle:after {
  color: #009EF7;
}
.btn.btn-active-light-primary.btn-outline {
  border-color: #009EF7 !important;
}

.btn.btn-secondary {
  color: #3F4254;
  border-color: #E4E6EF;
  background-color: #E4E6EF;
}
.btn.btn-secondary i {
  color: #3F4254;
}
.btn.btn-secondary .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #3F4254;
}
.btn.btn-secondary .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.btn.btn-secondary.dropdown-toggle:after {
  color: #3F4254;
}
.btn-check:checked + .btn.btn-secondary, .btn-check:active + .btn.btn-secondary, .btn.btn-secondary:focus:not(.btn-active), .btn.btn-secondary:hover:not(.btn-active), .btn.btn-secondary:active:not(.btn-active), .btn.btn-secondary.active, .btn.btn-secondary.show, .show > .btn.btn-secondary {
  color: #3F4254;
  border-color: #B5B5C3;
  background-color: #B5B5C3;
}
.btn-check:checked + .btn.btn-secondary i, .btn-check:active + .btn.btn-secondary i, .btn.btn-secondary:focus:not(.btn-active) i, .btn.btn-secondary:hover:not(.btn-active) i, .btn.btn-secondary:active:not(.btn-active) i, .btn.btn-secondary.active i, .btn.btn-secondary.show i, .show > .btn.btn-secondary i {
  color: #3F4254;
}
.btn-check:checked + .btn.btn-secondary .svg-icon svg [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-secondary .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-secondary:focus:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-secondary:hover:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-secondary:active:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-secondary.active .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-secondary.show .svg-icon svg [fill]:not(.permanent):not(g), .show > .btn.btn-secondary .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #3F4254;
}
.btn-check:checked + .btn.btn-secondary .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-secondary .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-secondary:focus:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-secondary:hover:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-secondary:active:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-secondary.active .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-secondary.show .svg-icon svg:hover [fill]:not(.permanent):not(g), .show > .btn.btn-secondary .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.btn-check:checked + .btn.btn-secondary.dropdown-toggle:after, .btn-check:active + .btn.btn-secondary.dropdown-toggle:after, .btn.btn-secondary:focus:not(.btn-active).dropdown-toggle:after, .btn.btn-secondary:hover:not(.btn-active).dropdown-toggle:after, .btn.btn-secondary:active:not(.btn-active).dropdown-toggle:after, .btn.btn-secondary.active.dropdown-toggle:after, .btn.btn-secondary.show.dropdown-toggle:after, .show > .btn.btn-secondary.dropdown-toggle:after {
  color: #3F4254;
}

.btn.btn-bg-secondary {
  border-color: #E4E6EF;
  background-color: #E4E6EF;
}
.btn-check:checked + .btn.btn-active-secondary, .btn-check:active + .btn.btn-active-secondary, .btn.btn-active-secondary:focus:not(.btn-active), .btn.btn-active-secondary:hover:not(.btn-active), .btn.btn-active-secondary:active:not(.btn-active), .btn.btn-active-secondary.active, .btn.btn-active-secondary.show, .show > .btn.btn-active-secondary {
  color: #3F4254;
  border-color: #E4E6EF;
  background-color: #E4E6EF;
}
.btn-check:checked + .btn.btn-active-secondary i, .btn-check:active + .btn.btn-active-secondary i, .btn.btn-active-secondary:focus:not(.btn-active) i, .btn.btn-active-secondary:hover:not(.btn-active) i, .btn.btn-active-secondary:active:not(.btn-active) i, .btn.btn-active-secondary.active i, .btn.btn-active-secondary.show i, .show > .btn.btn-active-secondary i {
  color: #3F4254;
}
.btn-check:checked + .btn.btn-active-secondary .svg-icon svg [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-active-secondary .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-secondary:focus:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-secondary:hover:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-secondary:active:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-secondary.active .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-secondary.show .svg-icon svg [fill]:not(.permanent):not(g), .show > .btn.btn-active-secondary .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #3F4254;
}
.btn-check:checked + .btn.btn-active-secondary .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-active-secondary .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-secondary:focus:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-secondary:hover:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-secondary:active:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-secondary.active .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-secondary.show .svg-icon svg:hover [fill]:not(.permanent):not(g), .show > .btn.btn-active-secondary .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.btn-check:checked + .btn.btn-active-secondary.dropdown-toggle:after, .btn-check:active + .btn.btn-active-secondary.dropdown-toggle:after, .btn.btn-active-secondary:focus:not(.btn-active).dropdown-toggle:after, .btn.btn-active-secondary:hover:not(.btn-active).dropdown-toggle:after, .btn.btn-active-secondary:active:not(.btn-active).dropdown-toggle:after, .btn.btn-active-secondary.active.dropdown-toggle:after, .btn.btn-active-secondary.show.dropdown-toggle:after, .show > .btn.btn-active-secondary.dropdown-toggle:after {
  color: #3F4254;
}

.btn.btn-success {
  color: #FFFFFF;
  border-color: #50CD89;
  background-color: #50CD89;
}
.btn.btn-success i {
  color: #FFFFFF;
}
.btn.btn-success .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #FFFFFF;
}
.btn.btn-success .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.btn.btn-success.dropdown-toggle:after {
  color: #FFFFFF;
}
.btn-check:checked + .btn.btn-success, .btn-check:active + .btn.btn-success, .btn.btn-success:focus:not(.btn-active), .btn.btn-success:hover:not(.btn-active), .btn.btn-success:active:not(.btn-active), .btn.btn-success.active, .btn.btn-success.show, .show > .btn.btn-success {
  color: #FFFFFF;
  border-color: #47BE7D;
  background-color: #47BE7D;
}
.btn-check:checked + .btn.btn-success i, .btn-check:active + .btn.btn-success i, .btn.btn-success:focus:not(.btn-active) i, .btn.btn-success:hover:not(.btn-active) i, .btn.btn-success:active:not(.btn-active) i, .btn.btn-success.active i, .btn.btn-success.show i, .show > .btn.btn-success i {
  color: #FFFFFF;
}
.btn-check:checked + .btn.btn-success .svg-icon svg [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-success .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-success:focus:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-success:hover:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-success:active:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-success.active .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-success.show .svg-icon svg [fill]:not(.permanent):not(g), .show > .btn.btn-success .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #FFFFFF;
}
.btn-check:checked + .btn.btn-success .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-success .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-success:focus:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-success:hover:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-success:active:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-success.active .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-success.show .svg-icon svg:hover [fill]:not(.permanent):not(g), .show > .btn.btn-success .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.btn-check:checked + .btn.btn-success.dropdown-toggle:after, .btn-check:active + .btn.btn-success.dropdown-toggle:after, .btn.btn-success:focus:not(.btn-active).dropdown-toggle:after, .btn.btn-success:hover:not(.btn-active).dropdown-toggle:after, .btn.btn-success:active:not(.btn-active).dropdown-toggle:after, .btn.btn-success.active.dropdown-toggle:after, .btn.btn-success.show.dropdown-toggle:after, .show > .btn.btn-success.dropdown-toggle:after {
  color: #FFFFFF;
}

.btn.btn-light-success {
  color: #50CD89;
  border-color: #E8FFF3;
  background-color: #E8FFF3;
}
.btn.btn-light-success i {
  color: #50CD89;
}
.btn.btn-light-success .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #50CD89;
}
.btn.btn-light-success .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.btn.btn-light-success.dropdown-toggle:after {
  color: #50CD89;
}
.btn-check:checked + .btn.btn-light-success, .btn-check:active + .btn.btn-light-success, .btn.btn-light-success:focus:not(.btn-active), .btn.btn-light-success:hover:not(.btn-active), .btn.btn-light-success:active:not(.btn-active), .btn.btn-light-success.active, .btn.btn-light-success.show, .show > .btn.btn-light-success {
  color: #FFFFFF;
  border-color: #50CD89;
  background-color: #50CD89;
}
.btn-check:checked + .btn.btn-light-success i, .btn-check:active + .btn.btn-light-success i, .btn.btn-light-success:focus:not(.btn-active) i, .btn.btn-light-success:hover:not(.btn-active) i, .btn.btn-light-success:active:not(.btn-active) i, .btn.btn-light-success.active i, .btn.btn-light-success.show i, .show > .btn.btn-light-success i {
  color: #FFFFFF;
}
.btn-check:checked + .btn.btn-light-success .svg-icon svg [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-light-success .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-light-success:focus:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-light-success:hover:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-light-success:active:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-light-success.active .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-light-success.show .svg-icon svg [fill]:not(.permanent):not(g), .show > .btn.btn-light-success .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #FFFFFF;
}
.btn-check:checked + .btn.btn-light-success .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-light-success .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-light-success:focus:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-light-success:hover:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-light-success:active:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-light-success.active .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-light-success.show .svg-icon svg:hover [fill]:not(.permanent):not(g), .show > .btn.btn-light-success .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.btn-check:checked + .btn.btn-light-success.dropdown-toggle:after, .btn-check:active + .btn.btn-light-success.dropdown-toggle:after, .btn.btn-light-success:focus:not(.btn-active).dropdown-toggle:after, .btn.btn-light-success:hover:not(.btn-active).dropdown-toggle:after, .btn.btn-light-success:active:not(.btn-active).dropdown-toggle:after, .btn.btn-light-success.active.dropdown-toggle:after, .btn.btn-light-success.show.dropdown-toggle:after, .show > .btn.btn-light-success.dropdown-toggle:after {
  color: #FFFFFF;
}

.btn.btn-bg-success {
  border-color: #50CD89;
  background-color: #50CD89;
}
.btn-check:checked + .btn.btn-active-success, .btn-check:active + .btn.btn-active-success, .btn.btn-active-success:focus:not(.btn-active), .btn.btn-active-success:hover:not(.btn-active), .btn.btn-active-success:active:not(.btn-active), .btn.btn-active-success.active, .btn.btn-active-success.show, .show > .btn.btn-active-success {
  color: #FFFFFF;
  border-color: #50CD89;
  background-color: #50CD89;
}
.btn-check:checked + .btn.btn-active-success i, .btn-check:active + .btn.btn-active-success i, .btn.btn-active-success:focus:not(.btn-active) i, .btn.btn-active-success:hover:not(.btn-active) i, .btn.btn-active-success:active:not(.btn-active) i, .btn.btn-active-success.active i, .btn.btn-active-success.show i, .show > .btn.btn-active-success i {
  color: #FFFFFF;
}
.btn-check:checked + .btn.btn-active-success .svg-icon svg [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-active-success .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-success:focus:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-success:hover:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-success:active:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-success.active .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-success.show .svg-icon svg [fill]:not(.permanent):not(g), .show > .btn.btn-active-success .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #FFFFFF;
}
.btn-check:checked + .btn.btn-active-success .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-active-success .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-success:focus:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-success:hover:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-success:active:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-success.active .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-success.show .svg-icon svg:hover [fill]:not(.permanent):not(g), .show > .btn.btn-active-success .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.btn-check:checked + .btn.btn-active-success.dropdown-toggle:after, .btn-check:active + .btn.btn-active-success.dropdown-toggle:after, .btn.btn-active-success:focus:not(.btn-active).dropdown-toggle:after, .btn.btn-active-success:hover:not(.btn-active).dropdown-toggle:after, .btn.btn-active-success:active:not(.btn-active).dropdown-toggle:after, .btn.btn-active-success.active.dropdown-toggle:after, .btn.btn-active-success.show.dropdown-toggle:after, .show > .btn.btn-active-success.dropdown-toggle:after {
  color: #FFFFFF;
}

.btn-check:checked + .btn.btn-active-light-success, .btn-check:active + .btn.btn-active-light-success, .btn.btn-active-light-success:focus:not(.btn-active), .btn.btn-active-light-success:hover:not(.btn-active), .btn.btn-active-light-success:active:not(.btn-active), .btn.btn-active-light-success.active, .btn.btn-active-light-success.show, .show > .btn.btn-active-light-success {
  color: #50CD89;
  border-color: #E8FFF3;
  background-color: #E8FFF3;
}
.btn-check:checked + .btn.btn-active-light-success i, .btn-check:active + .btn.btn-active-light-success i, .btn.btn-active-light-success:focus:not(.btn-active) i, .btn.btn-active-light-success:hover:not(.btn-active) i, .btn.btn-active-light-success:active:not(.btn-active) i, .btn.btn-active-light-success.active i, .btn.btn-active-light-success.show i, .show > .btn.btn-active-light-success i {
  color: #50CD89;
}
.btn-check:checked + .btn.btn-active-light-success .svg-icon svg [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-active-light-success .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-light-success:focus:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-light-success:hover:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-light-success:active:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-light-success.active .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-light-success.show .svg-icon svg [fill]:not(.permanent):not(g), .show > .btn.btn-active-light-success .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #50CD89;
}
.btn-check:checked + .btn.btn-active-light-success .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-active-light-success .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-light-success:focus:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-light-success:hover:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-light-success:active:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-light-success.active .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-light-success.show .svg-icon svg:hover [fill]:not(.permanent):not(g), .show > .btn.btn-active-light-success .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.btn-check:checked + .btn.btn-active-light-success.dropdown-toggle:after, .btn-check:active + .btn.btn-active-light-success.dropdown-toggle:after, .btn.btn-active-light-success:focus:not(.btn-active).dropdown-toggle:after, .btn.btn-active-light-success:hover:not(.btn-active).dropdown-toggle:after, .btn.btn-active-light-success:active:not(.btn-active).dropdown-toggle:after, .btn.btn-active-light-success.active.dropdown-toggle:after, .btn.btn-active-light-success.show.dropdown-toggle:after, .show > .btn.btn-active-light-success.dropdown-toggle:after {
  color: #50CD89;
}
.btn.btn-active-light-success.btn-outline {
  border-color: #50CD89 !important;
}

.btn.btn-info {
  color: #FFFFFF;
  border-color: #7239EA;
  background-color: #7239EA;
}
.btn.btn-info i {
  color: #FFFFFF;
}
.btn.btn-info .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #FFFFFF;
}
.btn.btn-info .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.btn.btn-info.dropdown-toggle:after {
  color: #FFFFFF;
}
.btn-check:checked + .btn.btn-info, .btn-check:active + .btn.btn-info, .btn.btn-info:focus:not(.btn-active), .btn.btn-info:hover:not(.btn-active), .btn.btn-info:active:not(.btn-active), .btn.btn-info.active, .btn.btn-info.show, .show > .btn.btn-info {
  color: #FFFFFF;
  border-color: #5014D0;
  background-color: #5014D0;
}
.btn-check:checked + .btn.btn-info i, .btn-check:active + .btn.btn-info i, .btn.btn-info:focus:not(.btn-active) i, .btn.btn-info:hover:not(.btn-active) i, .btn.btn-info:active:not(.btn-active) i, .btn.btn-info.active i, .btn.btn-info.show i, .show > .btn.btn-info i {
  color: #FFFFFF;
}
.btn-check:checked + .btn.btn-info .svg-icon svg [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-info .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-info:focus:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-info:hover:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-info:active:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-info.active .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-info.show .svg-icon svg [fill]:not(.permanent):not(g), .show > .btn.btn-info .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #FFFFFF;
}
.btn-check:checked + .btn.btn-info .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-info .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-info:focus:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-info:hover:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-info:active:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-info.active .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-info.show .svg-icon svg:hover [fill]:not(.permanent):not(g), .show > .btn.btn-info .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.btn-check:checked + .btn.btn-info.dropdown-toggle:after, .btn-check:active + .btn.btn-info.dropdown-toggle:after, .btn.btn-info:focus:not(.btn-active).dropdown-toggle:after, .btn.btn-info:hover:not(.btn-active).dropdown-toggle:after, .btn.btn-info:active:not(.btn-active).dropdown-toggle:after, .btn.btn-info.active.dropdown-toggle:after, .btn.btn-info.show.dropdown-toggle:after, .show > .btn.btn-info.dropdown-toggle:after {
  color: #FFFFFF;
}

.btn.btn-light-info {
  color: #7239EA;
  border-color: #F8F5FF;
  background-color: #F8F5FF;
}
.btn.btn-light-info i {
  color: #7239EA;
}
.btn.btn-light-info .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #7239EA;
}
.btn.btn-light-info .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.btn.btn-light-info.dropdown-toggle:after {
  color: #7239EA;
}
.btn-check:checked + .btn.btn-light-info, .btn-check:active + .btn.btn-light-info, .btn.btn-light-info:focus:not(.btn-active), .btn.btn-light-info:hover:not(.btn-active), .btn.btn-light-info:active:not(.btn-active), .btn.btn-light-info.active, .btn.btn-light-info.show, .show > .btn.btn-light-info {
  color: #FFFFFF;
  border-color: #7239EA;
  background-color: #7239EA;
}
.btn-check:checked + .btn.btn-light-info i, .btn-check:active + .btn.btn-light-info i, .btn.btn-light-info:focus:not(.btn-active) i, .btn.btn-light-info:hover:not(.btn-active) i, .btn.btn-light-info:active:not(.btn-active) i, .btn.btn-light-info.active i, .btn.btn-light-info.show i, .show > .btn.btn-light-info i {
  color: #FFFFFF;
}
.btn-check:checked + .btn.btn-light-info .svg-icon svg [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-light-info .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-light-info:focus:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-light-info:hover:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-light-info:active:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-light-info.active .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-light-info.show .svg-icon svg [fill]:not(.permanent):not(g), .show > .btn.btn-light-info .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #FFFFFF;
}
.btn-check:checked + .btn.btn-light-info .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-light-info .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-light-info:focus:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-light-info:hover:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-light-info:active:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-light-info.active .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-light-info.show .svg-icon svg:hover [fill]:not(.permanent):not(g), .show > .btn.btn-light-info .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.btn-check:checked + .btn.btn-light-info.dropdown-toggle:after, .btn-check:active + .btn.btn-light-info.dropdown-toggle:after, .btn.btn-light-info:focus:not(.btn-active).dropdown-toggle:after, .btn.btn-light-info:hover:not(.btn-active).dropdown-toggle:after, .btn.btn-light-info:active:not(.btn-active).dropdown-toggle:after, .btn.btn-light-info.active.dropdown-toggle:after, .btn.btn-light-info.show.dropdown-toggle:after, .show > .btn.btn-light-info.dropdown-toggle:after {
  color: #FFFFFF;
}

.btn.btn-bg-info {
  border-color: #7239EA;
  background-color: #7239EA;
}
.btn-check:checked + .btn.btn-active-info, .btn-check:active + .btn.btn-active-info, .btn.btn-active-info:focus:not(.btn-active), .btn.btn-active-info:hover:not(.btn-active), .btn.btn-active-info:active:not(.btn-active), .btn.btn-active-info.active, .btn.btn-active-info.show, .show > .btn.btn-active-info {
  color: #FFFFFF;
  border-color: #7239EA;
  background-color: #7239EA;
}
.btn-check:checked + .btn.btn-active-info i, .btn-check:active + .btn.btn-active-info i, .btn.btn-active-info:focus:not(.btn-active) i, .btn.btn-active-info:hover:not(.btn-active) i, .btn.btn-active-info:active:not(.btn-active) i, .btn.btn-active-info.active i, .btn.btn-active-info.show i, .show > .btn.btn-active-info i {
  color: #FFFFFF;
}
.btn-check:checked + .btn.btn-active-info .svg-icon svg [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-active-info .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-info:focus:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-info:hover:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-info:active:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-info.active .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-info.show .svg-icon svg [fill]:not(.permanent):not(g), .show > .btn.btn-active-info .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #FFFFFF;
}
.btn-check:checked + .btn.btn-active-info .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-active-info .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-info:focus:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-info:hover:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-info:active:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-info.active .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-info.show .svg-icon svg:hover [fill]:not(.permanent):not(g), .show > .btn.btn-active-info .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.btn-check:checked + .btn.btn-active-info.dropdown-toggle:after, .btn-check:active + .btn.btn-active-info.dropdown-toggle:after, .btn.btn-active-info:focus:not(.btn-active).dropdown-toggle:after, .btn.btn-active-info:hover:not(.btn-active).dropdown-toggle:after, .btn.btn-active-info:active:not(.btn-active).dropdown-toggle:after, .btn.btn-active-info.active.dropdown-toggle:after, .btn.btn-active-info.show.dropdown-toggle:after, .show > .btn.btn-active-info.dropdown-toggle:after {
  color: #FFFFFF;
}

.btn-check:checked + .btn.btn-active-light-info, .btn-check:active + .btn.btn-active-light-info, .btn.btn-active-light-info:focus:not(.btn-active), .btn.btn-active-light-info:hover:not(.btn-active), .btn.btn-active-light-info:active:not(.btn-active), .btn.btn-active-light-info.active, .btn.btn-active-light-info.show, .show > .btn.btn-active-light-info {
  color: #7239EA;
  border-color: #F8F5FF;
  background-color: #F8F5FF;
}
.btn-check:checked + .btn.btn-active-light-info i, .btn-check:active + .btn.btn-active-light-info i, .btn.btn-active-light-info:focus:not(.btn-active) i, .btn.btn-active-light-info:hover:not(.btn-active) i, .btn.btn-active-light-info:active:not(.btn-active) i, .btn.btn-active-light-info.active i, .btn.btn-active-light-info.show i, .show > .btn.btn-active-light-info i {
  color: #7239EA;
}
.btn-check:checked + .btn.btn-active-light-info .svg-icon svg [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-active-light-info .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-light-info:focus:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-light-info:hover:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-light-info:active:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-light-info.active .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-light-info.show .svg-icon svg [fill]:not(.permanent):not(g), .show > .btn.btn-active-light-info .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #7239EA;
}
.btn-check:checked + .btn.btn-active-light-info .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-active-light-info .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-light-info:focus:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-light-info:hover:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-light-info:active:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-light-info.active .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-light-info.show .svg-icon svg:hover [fill]:not(.permanent):not(g), .show > .btn.btn-active-light-info .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.btn-check:checked + .btn.btn-active-light-info.dropdown-toggle:after, .btn-check:active + .btn.btn-active-light-info.dropdown-toggle:after, .btn.btn-active-light-info:focus:not(.btn-active).dropdown-toggle:after, .btn.btn-active-light-info:hover:not(.btn-active).dropdown-toggle:after, .btn.btn-active-light-info:active:not(.btn-active).dropdown-toggle:after, .btn.btn-active-light-info.active.dropdown-toggle:after, .btn.btn-active-light-info.show.dropdown-toggle:after, .show > .btn.btn-active-light-info.dropdown-toggle:after {
  color: #7239EA;
}
.btn.btn-active-light-info.btn-outline {
  border-color: #7239EA !important;
}

.btn.btn-warning {
  color: #FFFFFF;
  border-color: #FFC700;
  background-color: #FFC700;
}
.btn.btn-warning i {
  color: #FFFFFF;
}
.btn.btn-warning .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #FFFFFF;
}
.btn.btn-warning .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.btn.btn-warning.dropdown-toggle:after {
  color: #FFFFFF;
}
.btn-check:checked + .btn.btn-warning, .btn-check:active + .btn.btn-warning, .btn.btn-warning:focus:not(.btn-active), .btn.btn-warning:hover:not(.btn-active), .btn.btn-warning:active:not(.btn-active), .btn.btn-warning.active, .btn.btn-warning.show, .show > .btn.btn-warning {
  color: #FFFFFF;
  border-color: #F1BC00;
  background-color: #F1BC00;
}
.btn-check:checked + .btn.btn-warning i, .btn-check:active + .btn.btn-warning i, .btn.btn-warning:focus:not(.btn-active) i, .btn.btn-warning:hover:not(.btn-active) i, .btn.btn-warning:active:not(.btn-active) i, .btn.btn-warning.active i, .btn.btn-warning.show i, .show > .btn.btn-warning i {
  color: #FFFFFF;
}
.btn-check:checked + .btn.btn-warning .svg-icon svg [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-warning .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-warning:focus:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-warning:hover:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-warning:active:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-warning.active .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-warning.show .svg-icon svg [fill]:not(.permanent):not(g), .show > .btn.btn-warning .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #FFFFFF;
}
.btn-check:checked + .btn.btn-warning .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-warning .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-warning:focus:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-warning:hover:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-warning:active:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-warning.active .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-warning.show .svg-icon svg:hover [fill]:not(.permanent):not(g), .show > .btn.btn-warning .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.btn-check:checked + .btn.btn-warning.dropdown-toggle:after, .btn-check:active + .btn.btn-warning.dropdown-toggle:after, .btn.btn-warning:focus:not(.btn-active).dropdown-toggle:after, .btn.btn-warning:hover:not(.btn-active).dropdown-toggle:after, .btn.btn-warning:active:not(.btn-active).dropdown-toggle:after, .btn.btn-warning.active.dropdown-toggle:after, .btn.btn-warning.show.dropdown-toggle:after, .show > .btn.btn-warning.dropdown-toggle:after {
  color: #FFFFFF;
}

.btn.btn-light-warning {
  color: #FFC700;
  border-color: #FFF8DD;
  background-color: #FFF8DD;
}
.btn.btn-light-warning i {
  color: #FFC700;
}
.btn.btn-light-warning .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #FFC700;
}
.btn.btn-light-warning .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.btn.btn-light-warning.dropdown-toggle:after {
  color: #FFC700;
}
.btn-check:checked + .btn.btn-light-warning, .btn-check:active + .btn.btn-light-warning, .btn.btn-light-warning:focus:not(.btn-active), .btn.btn-light-warning:hover:not(.btn-active), .btn.btn-light-warning:active:not(.btn-active), .btn.btn-light-warning.active, .btn.btn-light-warning.show, .show > .btn.btn-light-warning {
  color: #FFFFFF;
  border-color: #FFC700;
  background-color: #FFC700;
}
.btn-check:checked + .btn.btn-light-warning i, .btn-check:active + .btn.btn-light-warning i, .btn.btn-light-warning:focus:not(.btn-active) i, .btn.btn-light-warning:hover:not(.btn-active) i, .btn.btn-light-warning:active:not(.btn-active) i, .btn.btn-light-warning.active i, .btn.btn-light-warning.show i, .show > .btn.btn-light-warning i {
  color: #FFFFFF;
}
.btn-check:checked + .btn.btn-light-warning .svg-icon svg [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-light-warning .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-light-warning:focus:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-light-warning:hover:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-light-warning:active:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-light-warning.active .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-light-warning.show .svg-icon svg [fill]:not(.permanent):not(g), .show > .btn.btn-light-warning .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #FFFFFF;
}
.btn-check:checked + .btn.btn-light-warning .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-light-warning .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-light-warning:focus:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-light-warning:hover:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-light-warning:active:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-light-warning.active .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-light-warning.show .svg-icon svg:hover [fill]:not(.permanent):not(g), .show > .btn.btn-light-warning .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.btn-check:checked + .btn.btn-light-warning.dropdown-toggle:after, .btn-check:active + .btn.btn-light-warning.dropdown-toggle:after, .btn.btn-light-warning:focus:not(.btn-active).dropdown-toggle:after, .btn.btn-light-warning:hover:not(.btn-active).dropdown-toggle:after, .btn.btn-light-warning:active:not(.btn-active).dropdown-toggle:after, .btn.btn-light-warning.active.dropdown-toggle:after, .btn.btn-light-warning.show.dropdown-toggle:after, .show > .btn.btn-light-warning.dropdown-toggle:after {
  color: #FFFFFF;
}

.btn.btn-bg-warning {
  border-color: #FFC700;
  background-color: #FFC700;
}
.btn-check:checked + .btn.btn-active-warning, .btn-check:active + .btn.btn-active-warning, .btn.btn-active-warning:focus:not(.btn-active), .btn.btn-active-warning:hover:not(.btn-active), .btn.btn-active-warning:active:not(.btn-active), .btn.btn-active-warning.active, .btn.btn-active-warning.show, .show > .btn.btn-active-warning {
  color: #FFFFFF;
  border-color: #FFC700;
  background-color: #FFC700;
}
.btn-check:checked + .btn.btn-active-warning i, .btn-check:active + .btn.btn-active-warning i, .btn.btn-active-warning:focus:not(.btn-active) i, .btn.btn-active-warning:hover:not(.btn-active) i, .btn.btn-active-warning:active:not(.btn-active) i, .btn.btn-active-warning.active i, .btn.btn-active-warning.show i, .show > .btn.btn-active-warning i {
  color: #FFFFFF;
}
.btn-check:checked + .btn.btn-active-warning .svg-icon svg [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-active-warning .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-warning:focus:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-warning:hover:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-warning:active:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-warning.active .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-warning.show .svg-icon svg [fill]:not(.permanent):not(g), .show > .btn.btn-active-warning .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #FFFFFF;
}
.btn-check:checked + .btn.btn-active-warning .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-active-warning .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-warning:focus:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-warning:hover:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-warning:active:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-warning.active .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-warning.show .svg-icon svg:hover [fill]:not(.permanent):not(g), .show > .btn.btn-active-warning .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.btn-check:checked + .btn.btn-active-warning.dropdown-toggle:after, .btn-check:active + .btn.btn-active-warning.dropdown-toggle:after, .btn.btn-active-warning:focus:not(.btn-active).dropdown-toggle:after, .btn.btn-active-warning:hover:not(.btn-active).dropdown-toggle:after, .btn.btn-active-warning:active:not(.btn-active).dropdown-toggle:after, .btn.btn-active-warning.active.dropdown-toggle:after, .btn.btn-active-warning.show.dropdown-toggle:after, .show > .btn.btn-active-warning.dropdown-toggle:after {
  color: #FFFFFF;
}

.btn-check:checked + .btn.btn-active-light-warning, .btn-check:active + .btn.btn-active-light-warning, .btn.btn-active-light-warning:focus:not(.btn-active), .btn.btn-active-light-warning:hover:not(.btn-active), .btn.btn-active-light-warning:active:not(.btn-active), .btn.btn-active-light-warning.active, .btn.btn-active-light-warning.show, .show > .btn.btn-active-light-warning {
  color: #FFC700;
  border-color: #FFF8DD;
  background-color: #FFF8DD;
}
.btn-check:checked + .btn.btn-active-light-warning i, .btn-check:active + .btn.btn-active-light-warning i, .btn.btn-active-light-warning:focus:not(.btn-active) i, .btn.btn-active-light-warning:hover:not(.btn-active) i, .btn.btn-active-light-warning:active:not(.btn-active) i, .btn.btn-active-light-warning.active i, .btn.btn-active-light-warning.show i, .show > .btn.btn-active-light-warning i {
  color: #FFC700;
}
.btn-check:checked + .btn.btn-active-light-warning .svg-icon svg [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-active-light-warning .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-light-warning:focus:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-light-warning:hover:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-light-warning:active:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-light-warning.active .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-light-warning.show .svg-icon svg [fill]:not(.permanent):not(g), .show > .btn.btn-active-light-warning .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #FFC700;
}
.btn-check:checked + .btn.btn-active-light-warning .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-active-light-warning .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-light-warning:focus:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-light-warning:hover:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-light-warning:active:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-light-warning.active .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-light-warning.show .svg-icon svg:hover [fill]:not(.permanent):not(g), .show > .btn.btn-active-light-warning .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.btn-check:checked + .btn.btn-active-light-warning.dropdown-toggle:after, .btn-check:active + .btn.btn-active-light-warning.dropdown-toggle:after, .btn.btn-active-light-warning:focus:not(.btn-active).dropdown-toggle:after, .btn.btn-active-light-warning:hover:not(.btn-active).dropdown-toggle:after, .btn.btn-active-light-warning:active:not(.btn-active).dropdown-toggle:after, .btn.btn-active-light-warning.active.dropdown-toggle:after, .btn.btn-active-light-warning.show.dropdown-toggle:after, .show > .btn.btn-active-light-warning.dropdown-toggle:after {
  color: #FFC700;
}
.btn.btn-active-light-warning.btn-outline {
  border-color: #FFC700 !important;
}

.btn.btn-danger {
  color: #FFFFFF;
  border-color: #F1416C;
  background-color: #F1416C;
}
.btn.btn-danger i {
  color: #FFFFFF;
}
.btn.btn-danger .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #FFFFFF;
}
.btn.btn-danger .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.btn.btn-danger.dropdown-toggle:after {
  color: #FFFFFF;
}
.btn-check:checked + .btn.btn-danger, .btn-check:active + .btn.btn-danger, .btn.btn-danger:focus:not(.btn-active), .btn.btn-danger:hover:not(.btn-active), .btn.btn-danger:active:not(.btn-active), .btn.btn-danger.active, .btn.btn-danger.show, .show > .btn.btn-danger {
  color: #FFFFFF;
  border-color: #D9214E;
  background-color: #D9214E;
}
.btn-check:checked + .btn.btn-danger i, .btn-check:active + .btn.btn-danger i, .btn.btn-danger:focus:not(.btn-active) i, .btn.btn-danger:hover:not(.btn-active) i, .btn.btn-danger:active:not(.btn-active) i, .btn.btn-danger.active i, .btn.btn-danger.show i, .show > .btn.btn-danger i {
  color: #FFFFFF;
}
.btn-check:checked + .btn.btn-danger .svg-icon svg [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-danger .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-danger:focus:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-danger:hover:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-danger:active:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-danger.active .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-danger.show .svg-icon svg [fill]:not(.permanent):not(g), .show > .btn.btn-danger .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #FFFFFF;
}
.btn-check:checked + .btn.btn-danger .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-danger .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-danger:focus:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-danger:hover:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-danger:active:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-danger.active .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-danger.show .svg-icon svg:hover [fill]:not(.permanent):not(g), .show > .btn.btn-danger .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.btn-check:checked + .btn.btn-danger.dropdown-toggle:after, .btn-check:active + .btn.btn-danger.dropdown-toggle:after, .btn.btn-danger:focus:not(.btn-active).dropdown-toggle:after, .btn.btn-danger:hover:not(.btn-active).dropdown-toggle:after, .btn.btn-danger:active:not(.btn-active).dropdown-toggle:after, .btn.btn-danger.active.dropdown-toggle:after, .btn.btn-danger.show.dropdown-toggle:after, .show > .btn.btn-danger.dropdown-toggle:after {
  color: #FFFFFF;
}

.btn.btn-light-danger {
  color: #F1416C;
  border-color: #FFF5F8;
  background-color: #FFF5F8;
}
.btn.btn-light-danger i {
  color: #F1416C;
}
.btn.btn-light-danger .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #F1416C;
}
.btn.btn-light-danger .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.btn.btn-light-danger.dropdown-toggle:after {
  color: #F1416C;
}
.btn-check:checked + .btn.btn-light-danger, .btn-check:active + .btn.btn-light-danger, .btn.btn-light-danger:focus:not(.btn-active), .btn.btn-light-danger:hover:not(.btn-active), .btn.btn-light-danger:active:not(.btn-active), .btn.btn-light-danger.active, .btn.btn-light-danger.show, .show > .btn.btn-light-danger {
  color: #FFFFFF;
  border-color: #F1416C;
  background-color: #F1416C;
}
.btn-check:checked + .btn.btn-light-danger i, .btn-check:active + .btn.btn-light-danger i, .btn.btn-light-danger:focus:not(.btn-active) i, .btn.btn-light-danger:hover:not(.btn-active) i, .btn.btn-light-danger:active:not(.btn-active) i, .btn.btn-light-danger.active i, .btn.btn-light-danger.show i, .show > .btn.btn-light-danger i {
  color: #FFFFFF;
}
.btn-check:checked + .btn.btn-light-danger .svg-icon svg [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-light-danger .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-light-danger:focus:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-light-danger:hover:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-light-danger:active:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-light-danger.active .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-light-danger.show .svg-icon svg [fill]:not(.permanent):not(g), .show > .btn.btn-light-danger .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #FFFFFF;
}
.btn-check:checked + .btn.btn-light-danger .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-light-danger .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-light-danger:focus:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-light-danger:hover:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-light-danger:active:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-light-danger.active .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-light-danger.show .svg-icon svg:hover [fill]:not(.permanent):not(g), .show > .btn.btn-light-danger .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.btn-check:checked + .btn.btn-light-danger.dropdown-toggle:after, .btn-check:active + .btn.btn-light-danger.dropdown-toggle:after, .btn.btn-light-danger:focus:not(.btn-active).dropdown-toggle:after, .btn.btn-light-danger:hover:not(.btn-active).dropdown-toggle:after, .btn.btn-light-danger:active:not(.btn-active).dropdown-toggle:after, .btn.btn-light-danger.active.dropdown-toggle:after, .btn.btn-light-danger.show.dropdown-toggle:after, .show > .btn.btn-light-danger.dropdown-toggle:after {
  color: #FFFFFF;
}

.btn.btn-bg-danger {
  border-color: #F1416C;
  background-color: #F1416C;
}
.btn-check:checked + .btn.btn-active-danger, .btn-check:active + .btn.btn-active-danger, .btn.btn-active-danger:focus:not(.btn-active), .btn.btn-active-danger:hover:not(.btn-active), .btn.btn-active-danger:active:not(.btn-active), .btn.btn-active-danger.active, .btn.btn-active-danger.show, .show > .btn.btn-active-danger {
  color: #FFFFFF;
  border-color: #F1416C;
  background-color: #F1416C;
}
.btn-check:checked + .btn.btn-active-danger i, .btn-check:active + .btn.btn-active-danger i, .btn.btn-active-danger:focus:not(.btn-active) i, .btn.btn-active-danger:hover:not(.btn-active) i, .btn.btn-active-danger:active:not(.btn-active) i, .btn.btn-active-danger.active i, .btn.btn-active-danger.show i, .show > .btn.btn-active-danger i {
  color: #FFFFFF;
}
.btn-check:checked + .btn.btn-active-danger .svg-icon svg [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-active-danger .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-danger:focus:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-danger:hover:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-danger:active:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-danger.active .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-danger.show .svg-icon svg [fill]:not(.permanent):not(g), .show > .btn.btn-active-danger .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #FFFFFF;
}
.btn-check:checked + .btn.btn-active-danger .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-active-danger .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-danger:focus:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-danger:hover:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-danger:active:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-danger.active .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-danger.show .svg-icon svg:hover [fill]:not(.permanent):not(g), .show > .btn.btn-active-danger .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.btn-check:checked + .btn.btn-active-danger.dropdown-toggle:after, .btn-check:active + .btn.btn-active-danger.dropdown-toggle:after, .btn.btn-active-danger:focus:not(.btn-active).dropdown-toggle:after, .btn.btn-active-danger:hover:not(.btn-active).dropdown-toggle:after, .btn.btn-active-danger:active:not(.btn-active).dropdown-toggle:after, .btn.btn-active-danger.active.dropdown-toggle:after, .btn.btn-active-danger.show.dropdown-toggle:after, .show > .btn.btn-active-danger.dropdown-toggle:after {
  color: #FFFFFF;
}

.btn-check:checked + .btn.btn-active-light-danger, .btn-check:active + .btn.btn-active-light-danger, .btn.btn-active-light-danger:focus:not(.btn-active), .btn.btn-active-light-danger:hover:not(.btn-active), .btn.btn-active-light-danger:active:not(.btn-active), .btn.btn-active-light-danger.active, .btn.btn-active-light-danger.show, .show > .btn.btn-active-light-danger {
  color: #F1416C;
  border-color: #FFF5F8;
  background-color: #FFF5F8;
}
.btn-check:checked + .btn.btn-active-light-danger i, .btn-check:active + .btn.btn-active-light-danger i, .btn.btn-active-light-danger:focus:not(.btn-active) i, .btn.btn-active-light-danger:hover:not(.btn-active) i, .btn.btn-active-light-danger:active:not(.btn-active) i, .btn.btn-active-light-danger.active i, .btn.btn-active-light-danger.show i, .show > .btn.btn-active-light-danger i {
  color: #F1416C;
}
.btn-check:checked + .btn.btn-active-light-danger .svg-icon svg [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-active-light-danger .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-light-danger:focus:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-light-danger:hover:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-light-danger:active:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-light-danger.active .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-light-danger.show .svg-icon svg [fill]:not(.permanent):not(g), .show > .btn.btn-active-light-danger .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #F1416C;
}
.btn-check:checked + .btn.btn-active-light-danger .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-active-light-danger .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-light-danger:focus:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-light-danger:hover:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-light-danger:active:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-light-danger.active .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-light-danger.show .svg-icon svg:hover [fill]:not(.permanent):not(g), .show > .btn.btn-active-light-danger .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.btn-check:checked + .btn.btn-active-light-danger.dropdown-toggle:after, .btn-check:active + .btn.btn-active-light-danger.dropdown-toggle:after, .btn.btn-active-light-danger:focus:not(.btn-active).dropdown-toggle:after, .btn.btn-active-light-danger:hover:not(.btn-active).dropdown-toggle:after, .btn.btn-active-light-danger:active:not(.btn-active).dropdown-toggle:after, .btn.btn-active-light-danger.active.dropdown-toggle:after, .btn.btn-active-light-danger.show.dropdown-toggle:after, .show > .btn.btn-active-light-danger.dropdown-toggle:after {
  color: #F1416C;
}
.btn.btn-active-light-danger.btn-outline {
  border-color: #F1416C !important;
}

.btn.btn-dark {
  color: #ffffff;
  border-color: #181C32;
  background-color: #181C32;
}
.btn.btn-dark i {
  color: #ffffff;
}
.btn.btn-dark .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #ffffff;
}
.btn.btn-dark .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.btn.btn-dark.dropdown-toggle:after {
  color: #ffffff;
}
.btn-check:checked + .btn.btn-dark, .btn-check:active + .btn.btn-dark, .btn.btn-dark:focus:not(.btn-active), .btn.btn-dark:hover:not(.btn-active), .btn.btn-dark:active:not(.btn-active), .btn.btn-dark.active, .btn.btn-dark.show, .show > .btn.btn-dark {
  color: #ffffff;
  border-color: #131628;
  background-color: #131628;
}
.btn-check:checked + .btn.btn-dark i, .btn-check:active + .btn.btn-dark i, .btn.btn-dark:focus:not(.btn-active) i, .btn.btn-dark:hover:not(.btn-active) i, .btn.btn-dark:active:not(.btn-active) i, .btn.btn-dark.active i, .btn.btn-dark.show i, .show > .btn.btn-dark i {
  color: #ffffff;
}
.btn-check:checked + .btn.btn-dark .svg-icon svg [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-dark .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-dark:focus:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-dark:hover:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-dark:active:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-dark.active .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-dark.show .svg-icon svg [fill]:not(.permanent):not(g), .show > .btn.btn-dark .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #ffffff;
}
.btn-check:checked + .btn.btn-dark .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-dark .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-dark:focus:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-dark:hover:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-dark:active:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-dark.active .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-dark.show .svg-icon svg:hover [fill]:not(.permanent):not(g), .show > .btn.btn-dark .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.btn-check:checked + .btn.btn-dark.dropdown-toggle:after, .btn-check:active + .btn.btn-dark.dropdown-toggle:after, .btn.btn-dark:focus:not(.btn-active).dropdown-toggle:after, .btn.btn-dark:hover:not(.btn-active).dropdown-toggle:after, .btn.btn-dark:active:not(.btn-active).dropdown-toggle:after, .btn.btn-dark.active.dropdown-toggle:after, .btn.btn-dark.show.dropdown-toggle:after, .show > .btn.btn-dark.dropdown-toggle:after {
  color: #ffffff;
}

.btn.btn-light-dark {
  color: #181C32;
  border-color: #EFF2F5;
  background-color: #EFF2F5;
}
.btn.btn-light-dark i {
  color: #181C32;
}
.btn.btn-light-dark .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #181C32;
}
.btn.btn-light-dark .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.btn.btn-light-dark.dropdown-toggle:after {
  color: #181C32;
}
.btn-check:checked + .btn.btn-light-dark, .btn-check:active + .btn.btn-light-dark, .btn.btn-light-dark:focus:not(.btn-active), .btn.btn-light-dark:hover:not(.btn-active), .btn.btn-light-dark:active:not(.btn-active), .btn.btn-light-dark.active, .btn.btn-light-dark.show, .show > .btn.btn-light-dark {
  color: #ffffff;
  border-color: #181C32;
  background-color: #181C32;
}
.btn-check:checked + .btn.btn-light-dark i, .btn-check:active + .btn.btn-light-dark i, .btn.btn-light-dark:focus:not(.btn-active) i, .btn.btn-light-dark:hover:not(.btn-active) i, .btn.btn-light-dark:active:not(.btn-active) i, .btn.btn-light-dark.active i, .btn.btn-light-dark.show i, .show > .btn.btn-light-dark i {
  color: #ffffff;
}
.btn-check:checked + .btn.btn-light-dark .svg-icon svg [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-light-dark .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-light-dark:focus:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-light-dark:hover:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-light-dark:active:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-light-dark.active .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-light-dark.show .svg-icon svg [fill]:not(.permanent):not(g), .show > .btn.btn-light-dark .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #ffffff;
}
.btn-check:checked + .btn.btn-light-dark .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-light-dark .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-light-dark:focus:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-light-dark:hover:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-light-dark:active:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-light-dark.active .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-light-dark.show .svg-icon svg:hover [fill]:not(.permanent):not(g), .show > .btn.btn-light-dark .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.btn-check:checked + .btn.btn-light-dark.dropdown-toggle:after, .btn-check:active + .btn.btn-light-dark.dropdown-toggle:after, .btn.btn-light-dark:focus:not(.btn-active).dropdown-toggle:after, .btn.btn-light-dark:hover:not(.btn-active).dropdown-toggle:after, .btn.btn-light-dark:active:not(.btn-active).dropdown-toggle:after, .btn.btn-light-dark.active.dropdown-toggle:after, .btn.btn-light-dark.show.dropdown-toggle:after, .show > .btn.btn-light-dark.dropdown-toggle:after {
  color: #ffffff;
}

.btn.btn-bg-dark {
  border-color: #181C32;
  background-color: #181C32;
}
.btn-check:checked + .btn.btn-active-dark, .btn-check:active + .btn.btn-active-dark, .btn.btn-active-dark:focus:not(.btn-active), .btn.btn-active-dark:hover:not(.btn-active), .btn.btn-active-dark:active:not(.btn-active), .btn.btn-active-dark.active, .btn.btn-active-dark.show, .show > .btn.btn-active-dark {
  color: #ffffff;
  border-color: #181C32;
  background-color: #181C32;
}
.btn-check:checked + .btn.btn-active-dark i, .btn-check:active + .btn.btn-active-dark i, .btn.btn-active-dark:focus:not(.btn-active) i, .btn.btn-active-dark:hover:not(.btn-active) i, .btn.btn-active-dark:active:not(.btn-active) i, .btn.btn-active-dark.active i, .btn.btn-active-dark.show i, .show > .btn.btn-active-dark i {
  color: #ffffff;
}
.btn-check:checked + .btn.btn-active-dark .svg-icon svg [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-active-dark .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-dark:focus:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-dark:hover:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-dark:active:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-dark.active .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-dark.show .svg-icon svg [fill]:not(.permanent):not(g), .show > .btn.btn-active-dark .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #ffffff;
}
.btn-check:checked + .btn.btn-active-dark .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-active-dark .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-dark:focus:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-dark:hover:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-dark:active:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-dark.active .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-dark.show .svg-icon svg:hover [fill]:not(.permanent):not(g), .show > .btn.btn-active-dark .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.btn-check:checked + .btn.btn-active-dark.dropdown-toggle:after, .btn-check:active + .btn.btn-active-dark.dropdown-toggle:after, .btn.btn-active-dark:focus:not(.btn-active).dropdown-toggle:after, .btn.btn-active-dark:hover:not(.btn-active).dropdown-toggle:after, .btn.btn-active-dark:active:not(.btn-active).dropdown-toggle:after, .btn.btn-active-dark.active.dropdown-toggle:after, .btn.btn-active-dark.show.dropdown-toggle:after, .show > .btn.btn-active-dark.dropdown-toggle:after {
  color: #ffffff;
}

.btn-check:checked + .btn.btn-active-light-dark, .btn-check:active + .btn.btn-active-light-dark, .btn.btn-active-light-dark:focus:not(.btn-active), .btn.btn-active-light-dark:hover:not(.btn-active), .btn.btn-active-light-dark:active:not(.btn-active), .btn.btn-active-light-dark.active, .btn.btn-active-light-dark.show, .show > .btn.btn-active-light-dark {
  color: #181C32;
  border-color: #EFF2F5;
  background-color: #EFF2F5;
}
.btn-check:checked + .btn.btn-active-light-dark i, .btn-check:active + .btn.btn-active-light-dark i, .btn.btn-active-light-dark:focus:not(.btn-active) i, .btn.btn-active-light-dark:hover:not(.btn-active) i, .btn.btn-active-light-dark:active:not(.btn-active) i, .btn.btn-active-light-dark.active i, .btn.btn-active-light-dark.show i, .show > .btn.btn-active-light-dark i {
  color: #181C32;
}
.btn-check:checked + .btn.btn-active-light-dark .svg-icon svg [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-active-light-dark .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-light-dark:focus:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-light-dark:hover:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-light-dark:active:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-light-dark.active .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-light-dark.show .svg-icon svg [fill]:not(.permanent):not(g), .show > .btn.btn-active-light-dark .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #181C32;
}
.btn-check:checked + .btn.btn-active-light-dark .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-active-light-dark .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-light-dark:focus:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-light-dark:hover:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-light-dark:active:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-light-dark.active .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-light-dark.show .svg-icon svg:hover [fill]:not(.permanent):not(g), .show > .btn.btn-active-light-dark .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.btn-check:checked + .btn.btn-active-light-dark.dropdown-toggle:after, .btn-check:active + .btn.btn-active-light-dark.dropdown-toggle:after, .btn.btn-active-light-dark:focus:not(.btn-active).dropdown-toggle:after, .btn.btn-active-light-dark:hover:not(.btn-active).dropdown-toggle:after, .btn.btn-active-light-dark:active:not(.btn-active).dropdown-toggle:after, .btn.btn-active-light-dark.active.dropdown-toggle:after, .btn.btn-active-light-dark.show.dropdown-toggle:after, .show > .btn.btn-active-light-dark.dropdown-toggle:after {
  color: #181C32;
}
.btn.btn-active-light-dark.btn-outline {
  border-color: #181C32 !important;
}

.btn.btn-color-white {
  color: #ffffff;
}
.btn.btn-color-white i {
  color: #ffffff;
}
.btn.btn-color-white .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #ffffff;
}
.btn.btn-color-white .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.btn.btn-color-white.dropdown-toggle:after {
  color: #ffffff;
}
.btn-check:checked + .btn.btn-active-color-white, .btn-check:active + .btn.btn-active-color-white, .btn.btn-active-color-white:focus:not(.btn-active), .btn.btn-active-color-white:hover:not(.btn-active), .btn.btn-active-color-white:active:not(.btn-active), .btn.btn-active-color-white.active, .btn.btn-active-color-white.show, .show > .btn.btn-active-color-white {
  color: #ffffff;
}
.btn-check:checked + .btn.btn-active-color-white i, .btn-check:active + .btn.btn-active-color-white i, .btn.btn-active-color-white:focus:not(.btn-active) i, .btn.btn-active-color-white:hover:not(.btn-active) i, .btn.btn-active-color-white:active:not(.btn-active) i, .btn.btn-active-color-white.active i, .btn.btn-active-color-white.show i, .show > .btn.btn-active-color-white i {
  color: #ffffff;
}
.btn-check:checked + .btn.btn-active-color-white .svg-icon svg [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-active-color-white .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-color-white:focus:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-color-white:hover:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-color-white:active:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-color-white.active .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-color-white.show .svg-icon svg [fill]:not(.permanent):not(g), .show > .btn.btn-active-color-white .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #ffffff;
}
.btn-check:checked + .btn.btn-active-color-white .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-active-color-white .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-color-white:focus:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-color-white:hover:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-color-white:active:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-color-white.active .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-color-white.show .svg-icon svg:hover [fill]:not(.permanent):not(g), .show > .btn.btn-active-color-white .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.btn-check:checked + .btn.btn-active-color-white.dropdown-toggle:after, .btn-check:active + .btn.btn-active-color-white.dropdown-toggle:after, .btn.btn-active-color-white:focus:not(.btn-active).dropdown-toggle:after, .btn.btn-active-color-white:hover:not(.btn-active).dropdown-toggle:after, .btn.btn-active-color-white:active:not(.btn-active).dropdown-toggle:after, .btn.btn-active-color-white.active.dropdown-toggle:after, .btn.btn-active-color-white.show.dropdown-toggle:after, .show > .btn.btn-active-color-white.dropdown-toggle:after {
  color: #ffffff;
}

.btn.btn-icon-white i {
  color: #ffffff;
}
.btn.btn-icon-white .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #ffffff;
}
.btn.btn-icon-white .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.btn.btn-icon-white.dropdown-toggle:after {
  color: #ffffff;
}
.btn-check:checked + .btn.btn-active-icon-white i, .btn-check:active + .btn.btn-active-icon-white i, .btn.btn-active-icon-white:focus:not(.btn-active) i, .btn.btn-active-icon-white:hover:not(.btn-active) i, .btn.btn-active-icon-white:active:not(.btn-active) i, .btn.btn-active-icon-white.active i, .btn.btn-active-icon-white.show i, .show > .btn.btn-active-icon-white i {
  color: #ffffff;
}
.btn-check:checked + .btn.btn-active-icon-white .svg-icon svg [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-active-icon-white .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-icon-white:focus:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-icon-white:hover:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-icon-white:active:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-icon-white.active .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-icon-white.show .svg-icon svg [fill]:not(.permanent):not(g), .show > .btn.btn-active-icon-white .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #ffffff;
}
.btn-check:checked + .btn.btn-active-icon-white .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-active-icon-white .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-icon-white:focus:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-icon-white:hover:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-icon-white:active:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-icon-white.active .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-icon-white.show .svg-icon svg:hover [fill]:not(.permanent):not(g), .show > .btn.btn-active-icon-white .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.btn-check:checked + .btn.btn-active-icon-white.dropdown-toggle:after, .btn-check:active + .btn.btn-active-icon-white.dropdown-toggle:after, .btn.btn-active-icon-white:focus:not(.btn-active).dropdown-toggle:after, .btn.btn-active-icon-white:hover:not(.btn-active).dropdown-toggle:after, .btn.btn-active-icon-white:active:not(.btn-active).dropdown-toggle:after, .btn.btn-active-icon-white.active.dropdown-toggle:after, .btn.btn-active-icon-white.show.dropdown-toggle:after, .show > .btn.btn-active-icon-white.dropdown-toggle:after {
  color: #ffffff;
}

.btn.btn-text-white {
  color: #ffffff;
}
.btn-check:checked + .btn.btn-active-text-white, .btn-check:active + .btn.btn-active-text-white, .btn.btn-active-text-white:focus:not(.btn-active), .btn.btn-active-text-white:hover:not(.btn-active), .btn.btn-active-text-white:active:not(.btn-active), .btn.btn-active-text-white.active, .btn.btn-active-text-white.show, .show > .btn.btn-active-text-white {
  color: #ffffff;
}

.btn.btn-color-primary {
  color: #009EF7;
}
.btn.btn-color-primary i {
  color: #009EF7;
}
.btn.btn-color-primary .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #009EF7;
}
.btn.btn-color-primary .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.btn.btn-color-primary.dropdown-toggle:after {
  color: #009EF7;
}
.btn-check:checked + .btn.btn-active-color-primary, .btn-check:active + .btn.btn-active-color-primary, .btn.btn-active-color-primary:focus:not(.btn-active), .btn.btn-active-color-primary:hover:not(.btn-active), .btn.btn-active-color-primary:active:not(.btn-active), .btn.btn-active-color-primary.active, .btn.btn-active-color-primary.show, .show > .btn.btn-active-color-primary {
  color: #009EF7;
}
.btn-check:checked + .btn.btn-active-color-primary i, .btn-check:active + .btn.btn-active-color-primary i, .btn.btn-active-color-primary:focus:not(.btn-active) i, .btn.btn-active-color-primary:hover:not(.btn-active) i, .btn.btn-active-color-primary:active:not(.btn-active) i, .btn.btn-active-color-primary.active i, .btn.btn-active-color-primary.show i, .show > .btn.btn-active-color-primary i {
  color: #009EF7;
}
.btn-check:checked + .btn.btn-active-color-primary .svg-icon svg [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-active-color-primary .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-color-primary:focus:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-color-primary:hover:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-color-primary:active:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-color-primary.active .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-color-primary.show .svg-icon svg [fill]:not(.permanent):not(g), .show > .btn.btn-active-color-primary .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #009EF7;
}
.btn-check:checked + .btn.btn-active-color-primary .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-active-color-primary .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-color-primary:focus:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-color-primary:hover:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-color-primary:active:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-color-primary.active .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-color-primary.show .svg-icon svg:hover [fill]:not(.permanent):not(g), .show > .btn.btn-active-color-primary .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.btn-check:checked + .btn.btn-active-color-primary.dropdown-toggle:after, .btn-check:active + .btn.btn-active-color-primary.dropdown-toggle:after, .btn.btn-active-color-primary:focus:not(.btn-active).dropdown-toggle:after, .btn.btn-active-color-primary:hover:not(.btn-active).dropdown-toggle:after, .btn.btn-active-color-primary:active:not(.btn-active).dropdown-toggle:after, .btn.btn-active-color-primary.active.dropdown-toggle:after, .btn.btn-active-color-primary.show.dropdown-toggle:after, .show > .btn.btn-active-color-primary.dropdown-toggle:after {
  color: #009EF7;
}

.btn.btn-icon-primary i {
  color: #009EF7;
}
.btn.btn-icon-primary .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #009EF7;
}
.btn.btn-icon-primary .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.btn.btn-icon-primary.dropdown-toggle:after {
  color: #009EF7;
}
.btn-check:checked + .btn.btn-active-icon-primary i, .btn-check:active + .btn.btn-active-icon-primary i, .btn.btn-active-icon-primary:focus:not(.btn-active) i, .btn.btn-active-icon-primary:hover:not(.btn-active) i, .btn.btn-active-icon-primary:active:not(.btn-active) i, .btn.btn-active-icon-primary.active i, .btn.btn-active-icon-primary.show i, .show > .btn.btn-active-icon-primary i {
  color: #009EF7;
}
.btn-check:checked + .btn.btn-active-icon-primary .svg-icon svg [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-active-icon-primary .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-icon-primary:focus:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-icon-primary:hover:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-icon-primary:active:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-icon-primary.active .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-icon-primary.show .svg-icon svg [fill]:not(.permanent):not(g), .show > .btn.btn-active-icon-primary .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #009EF7;
}
.btn-check:checked + .btn.btn-active-icon-primary .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-active-icon-primary .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-icon-primary:focus:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-icon-primary:hover:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-icon-primary:active:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-icon-primary.active .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-icon-primary.show .svg-icon svg:hover [fill]:not(.permanent):not(g), .show > .btn.btn-active-icon-primary .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.btn-check:checked + .btn.btn-active-icon-primary.dropdown-toggle:after, .btn-check:active + .btn.btn-active-icon-primary.dropdown-toggle:after, .btn.btn-active-icon-primary:focus:not(.btn-active).dropdown-toggle:after, .btn.btn-active-icon-primary:hover:not(.btn-active).dropdown-toggle:after, .btn.btn-active-icon-primary:active:not(.btn-active).dropdown-toggle:after, .btn.btn-active-icon-primary.active.dropdown-toggle:after, .btn.btn-active-icon-primary.show.dropdown-toggle:after, .show > .btn.btn-active-icon-primary.dropdown-toggle:after {
  color: #009EF7;
}

.btn.btn-text-primary {
  color: #009EF7;
}
.btn-check:checked + .btn.btn-active-text-primary, .btn-check:active + .btn.btn-active-text-primary, .btn.btn-active-text-primary:focus:not(.btn-active), .btn.btn-active-text-primary:hover:not(.btn-active), .btn.btn-active-text-primary:active:not(.btn-active), .btn.btn-active-text-primary.active, .btn.btn-active-text-primary.show, .show > .btn.btn-active-text-primary {
  color: #009EF7;
}

.btn.btn-color-secondary {
  color: #E4E6EF;
}
.btn.btn-color-secondary i {
  color: #E4E6EF;
}
.btn.btn-color-secondary .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #E4E6EF;
}
.btn.btn-color-secondary .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.btn.btn-color-secondary.dropdown-toggle:after {
  color: #E4E6EF;
}
.btn-check:checked + .btn.btn-active-color-secondary, .btn-check:active + .btn.btn-active-color-secondary, .btn.btn-active-color-secondary:focus:not(.btn-active), .btn.btn-active-color-secondary:hover:not(.btn-active), .btn.btn-active-color-secondary:active:not(.btn-active), .btn.btn-active-color-secondary.active, .btn.btn-active-color-secondary.show, .show > .btn.btn-active-color-secondary {
  color: #E4E6EF;
}
.btn-check:checked + .btn.btn-active-color-secondary i, .btn-check:active + .btn.btn-active-color-secondary i, .btn.btn-active-color-secondary:focus:not(.btn-active) i, .btn.btn-active-color-secondary:hover:not(.btn-active) i, .btn.btn-active-color-secondary:active:not(.btn-active) i, .btn.btn-active-color-secondary.active i, .btn.btn-active-color-secondary.show i, .show > .btn.btn-active-color-secondary i {
  color: #E4E6EF;
}
.btn-check:checked + .btn.btn-active-color-secondary .svg-icon svg [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-active-color-secondary .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-color-secondary:focus:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-color-secondary:hover:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-color-secondary:active:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-color-secondary.active .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-color-secondary.show .svg-icon svg [fill]:not(.permanent):not(g), .show > .btn.btn-active-color-secondary .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #E4E6EF;
}
.btn-check:checked + .btn.btn-active-color-secondary .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-active-color-secondary .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-color-secondary:focus:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-color-secondary:hover:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-color-secondary:active:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-color-secondary.active .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-color-secondary.show .svg-icon svg:hover [fill]:not(.permanent):not(g), .show > .btn.btn-active-color-secondary .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.btn-check:checked + .btn.btn-active-color-secondary.dropdown-toggle:after, .btn-check:active + .btn.btn-active-color-secondary.dropdown-toggle:after, .btn.btn-active-color-secondary:focus:not(.btn-active).dropdown-toggle:after, .btn.btn-active-color-secondary:hover:not(.btn-active).dropdown-toggle:after, .btn.btn-active-color-secondary:active:not(.btn-active).dropdown-toggle:after, .btn.btn-active-color-secondary.active.dropdown-toggle:after, .btn.btn-active-color-secondary.show.dropdown-toggle:after, .show > .btn.btn-active-color-secondary.dropdown-toggle:after {
  color: #E4E6EF;
}

.btn.btn-icon-secondary i {
  color: #E4E6EF;
}
.btn.btn-icon-secondary .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #E4E6EF;
}
.btn.btn-icon-secondary .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.btn.btn-icon-secondary.dropdown-toggle:after {
  color: #E4E6EF;
}
.btn-check:checked + .btn.btn-active-icon-secondary i, .btn-check:active + .btn.btn-active-icon-secondary i, .btn.btn-active-icon-secondary:focus:not(.btn-active) i, .btn.btn-active-icon-secondary:hover:not(.btn-active) i, .btn.btn-active-icon-secondary:active:not(.btn-active) i, .btn.btn-active-icon-secondary.active i, .btn.btn-active-icon-secondary.show i, .show > .btn.btn-active-icon-secondary i {
  color: #E4E6EF;
}
.btn-check:checked + .btn.btn-active-icon-secondary .svg-icon svg [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-active-icon-secondary .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-icon-secondary:focus:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-icon-secondary:hover:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-icon-secondary:active:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-icon-secondary.active .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-icon-secondary.show .svg-icon svg [fill]:not(.permanent):not(g), .show > .btn.btn-active-icon-secondary .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #E4E6EF;
}
.btn-check:checked + .btn.btn-active-icon-secondary .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-active-icon-secondary .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-icon-secondary:focus:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-icon-secondary:hover:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-icon-secondary:active:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-icon-secondary.active .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-icon-secondary.show .svg-icon svg:hover [fill]:not(.permanent):not(g), .show > .btn.btn-active-icon-secondary .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.btn-check:checked + .btn.btn-active-icon-secondary.dropdown-toggle:after, .btn-check:active + .btn.btn-active-icon-secondary.dropdown-toggle:after, .btn.btn-active-icon-secondary:focus:not(.btn-active).dropdown-toggle:after, .btn.btn-active-icon-secondary:hover:not(.btn-active).dropdown-toggle:after, .btn.btn-active-icon-secondary:active:not(.btn-active).dropdown-toggle:after, .btn.btn-active-icon-secondary.active.dropdown-toggle:after, .btn.btn-active-icon-secondary.show.dropdown-toggle:after, .show > .btn.btn-active-icon-secondary.dropdown-toggle:after {
  color: #E4E6EF;
}

.btn.btn-text-secondary {
  color: #E4E6EF;
}
.btn-check:checked + .btn.btn-active-text-secondary, .btn-check:active + .btn.btn-active-text-secondary, .btn.btn-active-text-secondary:focus:not(.btn-active), .btn.btn-active-text-secondary:hover:not(.btn-active), .btn.btn-active-text-secondary:active:not(.btn-active), .btn.btn-active-text-secondary.active, .btn.btn-active-text-secondary.show, .show > .btn.btn-active-text-secondary {
  color: #E4E6EF;
}

.btn.btn-color-light {
  color: #F5F8FA;
}
.btn.btn-color-light i {
  color: #F5F8FA;
}
.btn.btn-color-light .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #F5F8FA;
}
.btn.btn-color-light .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.btn.btn-color-light.dropdown-toggle:after {
  color: #F5F8FA;
}
.btn-check:checked + .btn.btn-active-color-light, .btn-check:active + .btn.btn-active-color-light, .btn.btn-active-color-light:focus:not(.btn-active), .btn.btn-active-color-light:hover:not(.btn-active), .btn.btn-active-color-light:active:not(.btn-active), .btn.btn-active-color-light.active, .btn.btn-active-color-light.show, .show > .btn.btn-active-color-light {
  color: #F5F8FA;
}
.btn-check:checked + .btn.btn-active-color-light i, .btn-check:active + .btn.btn-active-color-light i, .btn.btn-active-color-light:focus:not(.btn-active) i, .btn.btn-active-color-light:hover:not(.btn-active) i, .btn.btn-active-color-light:active:not(.btn-active) i, .btn.btn-active-color-light.active i, .btn.btn-active-color-light.show i, .show > .btn.btn-active-color-light i {
  color: #F5F8FA;
}
.btn-check:checked + .btn.btn-active-color-light .svg-icon svg [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-active-color-light .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-color-light:focus:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-color-light:hover:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-color-light:active:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-color-light.active .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-color-light.show .svg-icon svg [fill]:not(.permanent):not(g), .show > .btn.btn-active-color-light .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #F5F8FA;
}
.btn-check:checked + .btn.btn-active-color-light .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-active-color-light .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-color-light:focus:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-color-light:hover:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-color-light:active:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-color-light.active .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-color-light.show .svg-icon svg:hover [fill]:not(.permanent):not(g), .show > .btn.btn-active-color-light .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.btn-check:checked + .btn.btn-active-color-light.dropdown-toggle:after, .btn-check:active + .btn.btn-active-color-light.dropdown-toggle:after, .btn.btn-active-color-light:focus:not(.btn-active).dropdown-toggle:after, .btn.btn-active-color-light:hover:not(.btn-active).dropdown-toggle:after, .btn.btn-active-color-light:active:not(.btn-active).dropdown-toggle:after, .btn.btn-active-color-light.active.dropdown-toggle:after, .btn.btn-active-color-light.show.dropdown-toggle:after, .show > .btn.btn-active-color-light.dropdown-toggle:after {
  color: #F5F8FA;
}

.btn.btn-icon-light i {
  color: #F5F8FA;
}
.btn.btn-icon-light .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #F5F8FA;
}
.btn.btn-icon-light .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.btn.btn-icon-light.dropdown-toggle:after {
  color: #F5F8FA;
}
.btn-check:checked + .btn.btn-active-icon-light i, .btn-check:active + .btn.btn-active-icon-light i, .btn.btn-active-icon-light:focus:not(.btn-active) i, .btn.btn-active-icon-light:hover:not(.btn-active) i, .btn.btn-active-icon-light:active:not(.btn-active) i, .btn.btn-active-icon-light.active i, .btn.btn-active-icon-light.show i, .show > .btn.btn-active-icon-light i {
  color: #F5F8FA;
}
.btn-check:checked + .btn.btn-active-icon-light .svg-icon svg [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-active-icon-light .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-icon-light:focus:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-icon-light:hover:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-icon-light:active:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-icon-light.active .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-icon-light.show .svg-icon svg [fill]:not(.permanent):not(g), .show > .btn.btn-active-icon-light .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #F5F8FA;
}
.btn-check:checked + .btn.btn-active-icon-light .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-active-icon-light .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-icon-light:focus:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-icon-light:hover:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-icon-light:active:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-icon-light.active .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-icon-light.show .svg-icon svg:hover [fill]:not(.permanent):not(g), .show > .btn.btn-active-icon-light .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.btn-check:checked + .btn.btn-active-icon-light.dropdown-toggle:after, .btn-check:active + .btn.btn-active-icon-light.dropdown-toggle:after, .btn.btn-active-icon-light:focus:not(.btn-active).dropdown-toggle:after, .btn.btn-active-icon-light:hover:not(.btn-active).dropdown-toggle:after, .btn.btn-active-icon-light:active:not(.btn-active).dropdown-toggle:after, .btn.btn-active-icon-light.active.dropdown-toggle:after, .btn.btn-active-icon-light.show.dropdown-toggle:after, .show > .btn.btn-active-icon-light.dropdown-toggle:after {
  color: #F5F8FA;
}

.btn.btn-text-light {
  color: #F5F8FA;
}
.btn-check:checked + .btn.btn-active-text-light, .btn-check:active + .btn.btn-active-text-light, .btn.btn-active-text-light:focus:not(.btn-active), .btn.btn-active-text-light:hover:not(.btn-active), .btn.btn-active-text-light:active:not(.btn-active), .btn.btn-active-text-light.active, .btn.btn-active-text-light.show, .show > .btn.btn-active-text-light {
  color: #F5F8FA;
}

.btn.btn-color-success {
  color: #50CD89;
}
.btn.btn-color-success i {
  color: #50CD89;
}
.btn.btn-color-success .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #50CD89;
}
.btn.btn-color-success .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.btn.btn-color-success.dropdown-toggle:after {
  color: #50CD89;
}
.btn-check:checked + .btn.btn-active-color-success, .btn-check:active + .btn.btn-active-color-success, .btn.btn-active-color-success:focus:not(.btn-active), .btn.btn-active-color-success:hover:not(.btn-active), .btn.btn-active-color-success:active:not(.btn-active), .btn.btn-active-color-success.active, .btn.btn-active-color-success.show, .show > .btn.btn-active-color-success {
  color: #50CD89;
}
.btn-check:checked + .btn.btn-active-color-success i, .btn-check:active + .btn.btn-active-color-success i, .btn.btn-active-color-success:focus:not(.btn-active) i, .btn.btn-active-color-success:hover:not(.btn-active) i, .btn.btn-active-color-success:active:not(.btn-active) i, .btn.btn-active-color-success.active i, .btn.btn-active-color-success.show i, .show > .btn.btn-active-color-success i {
  color: #50CD89;
}
.btn-check:checked + .btn.btn-active-color-success .svg-icon svg [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-active-color-success .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-color-success:focus:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-color-success:hover:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-color-success:active:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-color-success.active .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-color-success.show .svg-icon svg [fill]:not(.permanent):not(g), .show > .btn.btn-active-color-success .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #50CD89;
}
.btn-check:checked + .btn.btn-active-color-success .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-active-color-success .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-color-success:focus:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-color-success:hover:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-color-success:active:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-color-success.active .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-color-success.show .svg-icon svg:hover [fill]:not(.permanent):not(g), .show > .btn.btn-active-color-success .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.btn-check:checked + .btn.btn-active-color-success.dropdown-toggle:after, .btn-check:active + .btn.btn-active-color-success.dropdown-toggle:after, .btn.btn-active-color-success:focus:not(.btn-active).dropdown-toggle:after, .btn.btn-active-color-success:hover:not(.btn-active).dropdown-toggle:after, .btn.btn-active-color-success:active:not(.btn-active).dropdown-toggle:after, .btn.btn-active-color-success.active.dropdown-toggle:after, .btn.btn-active-color-success.show.dropdown-toggle:after, .show > .btn.btn-active-color-success.dropdown-toggle:after {
  color: #50CD89;
}

.btn.btn-icon-success i {
  color: #50CD89;
}
.btn.btn-icon-success .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #50CD89;
}
.btn.btn-icon-success .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.btn.btn-icon-success.dropdown-toggle:after {
  color: #50CD89;
}
.btn-check:checked + .btn.btn-active-icon-success i, .btn-check:active + .btn.btn-active-icon-success i, .btn.btn-active-icon-success:focus:not(.btn-active) i, .btn.btn-active-icon-success:hover:not(.btn-active) i, .btn.btn-active-icon-success:active:not(.btn-active) i, .btn.btn-active-icon-success.active i, .btn.btn-active-icon-success.show i, .show > .btn.btn-active-icon-success i {
  color: #50CD89;
}
.btn-check:checked + .btn.btn-active-icon-success .svg-icon svg [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-active-icon-success .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-icon-success:focus:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-icon-success:hover:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-icon-success:active:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-icon-success.active .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-icon-success.show .svg-icon svg [fill]:not(.permanent):not(g), .show > .btn.btn-active-icon-success .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #50CD89;
}
.btn-check:checked + .btn.btn-active-icon-success .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-active-icon-success .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-icon-success:focus:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-icon-success:hover:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-icon-success:active:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-icon-success.active .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-icon-success.show .svg-icon svg:hover [fill]:not(.permanent):not(g), .show > .btn.btn-active-icon-success .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.btn-check:checked + .btn.btn-active-icon-success.dropdown-toggle:after, .btn-check:active + .btn.btn-active-icon-success.dropdown-toggle:after, .btn.btn-active-icon-success:focus:not(.btn-active).dropdown-toggle:after, .btn.btn-active-icon-success:hover:not(.btn-active).dropdown-toggle:after, .btn.btn-active-icon-success:active:not(.btn-active).dropdown-toggle:after, .btn.btn-active-icon-success.active.dropdown-toggle:after, .btn.btn-active-icon-success.show.dropdown-toggle:after, .show > .btn.btn-active-icon-success.dropdown-toggle:after {
  color: #50CD89;
}

.btn.btn-text-success {
  color: #50CD89;
}
.btn-check:checked + .btn.btn-active-text-success, .btn-check:active + .btn.btn-active-text-success, .btn.btn-active-text-success:focus:not(.btn-active), .btn.btn-active-text-success:hover:not(.btn-active), .btn.btn-active-text-success:active:not(.btn-active), .btn.btn-active-text-success.active, .btn.btn-active-text-success.show, .show > .btn.btn-active-text-success {
  color: #50CD89;
}

.btn.btn-color-info {
  color: #7239EA;
}
.btn.btn-color-info i {
  color: #7239EA;
}
.btn.btn-color-info .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #7239EA;
}
.btn.btn-color-info .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.btn.btn-color-info.dropdown-toggle:after {
  color: #7239EA;
}
.btn-check:checked + .btn.btn-active-color-info, .btn-check:active + .btn.btn-active-color-info, .btn.btn-active-color-info:focus:not(.btn-active), .btn.btn-active-color-info:hover:not(.btn-active), .btn.btn-active-color-info:active:not(.btn-active), .btn.btn-active-color-info.active, .btn.btn-active-color-info.show, .show > .btn.btn-active-color-info {
  color: #7239EA;
}
.btn-check:checked + .btn.btn-active-color-info i, .btn-check:active + .btn.btn-active-color-info i, .btn.btn-active-color-info:focus:not(.btn-active) i, .btn.btn-active-color-info:hover:not(.btn-active) i, .btn.btn-active-color-info:active:not(.btn-active) i, .btn.btn-active-color-info.active i, .btn.btn-active-color-info.show i, .show > .btn.btn-active-color-info i {
  color: #7239EA;
}
.btn-check:checked + .btn.btn-active-color-info .svg-icon svg [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-active-color-info .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-color-info:focus:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-color-info:hover:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-color-info:active:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-color-info.active .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-color-info.show .svg-icon svg [fill]:not(.permanent):not(g), .show > .btn.btn-active-color-info .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #7239EA;
}
.btn-check:checked + .btn.btn-active-color-info .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-active-color-info .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-color-info:focus:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-color-info:hover:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-color-info:active:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-color-info.active .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-color-info.show .svg-icon svg:hover [fill]:not(.permanent):not(g), .show > .btn.btn-active-color-info .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.btn-check:checked + .btn.btn-active-color-info.dropdown-toggle:after, .btn-check:active + .btn.btn-active-color-info.dropdown-toggle:after, .btn.btn-active-color-info:focus:not(.btn-active).dropdown-toggle:after, .btn.btn-active-color-info:hover:not(.btn-active).dropdown-toggle:after, .btn.btn-active-color-info:active:not(.btn-active).dropdown-toggle:after, .btn.btn-active-color-info.active.dropdown-toggle:after, .btn.btn-active-color-info.show.dropdown-toggle:after, .show > .btn.btn-active-color-info.dropdown-toggle:after {
  color: #7239EA;
}

.btn.btn-icon-info i {
  color: #7239EA;
}
.btn.btn-icon-info .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #7239EA;
}
.btn.btn-icon-info .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.btn.btn-icon-info.dropdown-toggle:after {
  color: #7239EA;
}
.btn-check:checked + .btn.btn-active-icon-info i, .btn-check:active + .btn.btn-active-icon-info i, .btn.btn-active-icon-info:focus:not(.btn-active) i, .btn.btn-active-icon-info:hover:not(.btn-active) i, .btn.btn-active-icon-info:active:not(.btn-active) i, .btn.btn-active-icon-info.active i, .btn.btn-active-icon-info.show i, .show > .btn.btn-active-icon-info i {
  color: #7239EA;
}
.btn-check:checked + .btn.btn-active-icon-info .svg-icon svg [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-active-icon-info .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-icon-info:focus:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-icon-info:hover:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-icon-info:active:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-icon-info.active .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-icon-info.show .svg-icon svg [fill]:not(.permanent):not(g), .show > .btn.btn-active-icon-info .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #7239EA;
}
.btn-check:checked + .btn.btn-active-icon-info .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-active-icon-info .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-icon-info:focus:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-icon-info:hover:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-icon-info:active:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-icon-info.active .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-icon-info.show .svg-icon svg:hover [fill]:not(.permanent):not(g), .show > .btn.btn-active-icon-info .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.btn-check:checked + .btn.btn-active-icon-info.dropdown-toggle:after, .btn-check:active + .btn.btn-active-icon-info.dropdown-toggle:after, .btn.btn-active-icon-info:focus:not(.btn-active).dropdown-toggle:after, .btn.btn-active-icon-info:hover:not(.btn-active).dropdown-toggle:after, .btn.btn-active-icon-info:active:not(.btn-active).dropdown-toggle:after, .btn.btn-active-icon-info.active.dropdown-toggle:after, .btn.btn-active-icon-info.show.dropdown-toggle:after, .show > .btn.btn-active-icon-info.dropdown-toggle:after {
  color: #7239EA;
}

.btn.btn-text-info {
  color: #7239EA;
}
.btn-check:checked + .btn.btn-active-text-info, .btn-check:active + .btn.btn-active-text-info, .btn.btn-active-text-info:focus:not(.btn-active), .btn.btn-active-text-info:hover:not(.btn-active), .btn.btn-active-text-info:active:not(.btn-active), .btn.btn-active-text-info.active, .btn.btn-active-text-info.show, .show > .btn.btn-active-text-info {
  color: #7239EA;
}

.btn.btn-color-warning {
  color: #FFC700;
}
.btn.btn-color-warning i {
  color: #FFC700;
}
.btn.btn-color-warning .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #FFC700;
}
.btn.btn-color-warning .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.btn.btn-color-warning.dropdown-toggle:after {
  color: #FFC700;
}
.btn-check:checked + .btn.btn-active-color-warning, .btn-check:active + .btn.btn-active-color-warning, .btn.btn-active-color-warning:focus:not(.btn-active), .btn.btn-active-color-warning:hover:not(.btn-active), .btn.btn-active-color-warning:active:not(.btn-active), .btn.btn-active-color-warning.active, .btn.btn-active-color-warning.show, .show > .btn.btn-active-color-warning {
  color: #FFC700;
}
.btn-check:checked + .btn.btn-active-color-warning i, .btn-check:active + .btn.btn-active-color-warning i, .btn.btn-active-color-warning:focus:not(.btn-active) i, .btn.btn-active-color-warning:hover:not(.btn-active) i, .btn.btn-active-color-warning:active:not(.btn-active) i, .btn.btn-active-color-warning.active i, .btn.btn-active-color-warning.show i, .show > .btn.btn-active-color-warning i {
  color: #FFC700;
}
.btn-check:checked + .btn.btn-active-color-warning .svg-icon svg [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-active-color-warning .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-color-warning:focus:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-color-warning:hover:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-color-warning:active:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-color-warning.active .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-color-warning.show .svg-icon svg [fill]:not(.permanent):not(g), .show > .btn.btn-active-color-warning .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #FFC700;
}
.btn-check:checked + .btn.btn-active-color-warning .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-active-color-warning .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-color-warning:focus:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-color-warning:hover:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-color-warning:active:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-color-warning.active .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-color-warning.show .svg-icon svg:hover [fill]:not(.permanent):not(g), .show > .btn.btn-active-color-warning .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.btn-check:checked + .btn.btn-active-color-warning.dropdown-toggle:after, .btn-check:active + .btn.btn-active-color-warning.dropdown-toggle:after, .btn.btn-active-color-warning:focus:not(.btn-active).dropdown-toggle:after, .btn.btn-active-color-warning:hover:not(.btn-active).dropdown-toggle:after, .btn.btn-active-color-warning:active:not(.btn-active).dropdown-toggle:after, .btn.btn-active-color-warning.active.dropdown-toggle:after, .btn.btn-active-color-warning.show.dropdown-toggle:after, .show > .btn.btn-active-color-warning.dropdown-toggle:after {
  color: #FFC700;
}

.btn.btn-icon-warning i {
  color: #FFC700;
}
.btn.btn-icon-warning .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #FFC700;
}
.btn.btn-icon-warning .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.btn.btn-icon-warning.dropdown-toggle:after {
  color: #FFC700;
}
.btn-check:checked + .btn.btn-active-icon-warning i, .btn-check:active + .btn.btn-active-icon-warning i, .btn.btn-active-icon-warning:focus:not(.btn-active) i, .btn.btn-active-icon-warning:hover:not(.btn-active) i, .btn.btn-active-icon-warning:active:not(.btn-active) i, .btn.btn-active-icon-warning.active i, .btn.btn-active-icon-warning.show i, .show > .btn.btn-active-icon-warning i {
  color: #FFC700;
}
.btn-check:checked + .btn.btn-active-icon-warning .svg-icon svg [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-active-icon-warning .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-icon-warning:focus:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-icon-warning:hover:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-icon-warning:active:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-icon-warning.active .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-icon-warning.show .svg-icon svg [fill]:not(.permanent):not(g), .show > .btn.btn-active-icon-warning .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #FFC700;
}
.btn-check:checked + .btn.btn-active-icon-warning .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-active-icon-warning .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-icon-warning:focus:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-icon-warning:hover:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-icon-warning:active:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-icon-warning.active .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-icon-warning.show .svg-icon svg:hover [fill]:not(.permanent):not(g), .show > .btn.btn-active-icon-warning .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.btn-check:checked + .btn.btn-active-icon-warning.dropdown-toggle:after, .btn-check:active + .btn.btn-active-icon-warning.dropdown-toggle:after, .btn.btn-active-icon-warning:focus:not(.btn-active).dropdown-toggle:after, .btn.btn-active-icon-warning:hover:not(.btn-active).dropdown-toggle:after, .btn.btn-active-icon-warning:active:not(.btn-active).dropdown-toggle:after, .btn.btn-active-icon-warning.active.dropdown-toggle:after, .btn.btn-active-icon-warning.show.dropdown-toggle:after, .show > .btn.btn-active-icon-warning.dropdown-toggle:after {
  color: #FFC700;
}

.btn.btn-text-warning {
  color: #FFC700;
}
.btn-check:checked + .btn.btn-active-text-warning, .btn-check:active + .btn.btn-active-text-warning, .btn.btn-active-text-warning:focus:not(.btn-active), .btn.btn-active-text-warning:hover:not(.btn-active), .btn.btn-active-text-warning:active:not(.btn-active), .btn.btn-active-text-warning.active, .btn.btn-active-text-warning.show, .show > .btn.btn-active-text-warning {
  color: #FFC700;
}

.btn.btn-color-danger {
  color: #F1416C;
}
.btn.btn-color-danger i {
  color: #F1416C;
}
.btn.btn-color-danger .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #F1416C;
}
.btn.btn-color-danger .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.btn.btn-color-danger.dropdown-toggle:after {
  color: #F1416C;
}
.btn-check:checked + .btn.btn-active-color-danger, .btn-check:active + .btn.btn-active-color-danger, .btn.btn-active-color-danger:focus:not(.btn-active), .btn.btn-active-color-danger:hover:not(.btn-active), .btn.btn-active-color-danger:active:not(.btn-active), .btn.btn-active-color-danger.active, .btn.btn-active-color-danger.show, .show > .btn.btn-active-color-danger {
  color: #F1416C;
}
.btn-check:checked + .btn.btn-active-color-danger i, .btn-check:active + .btn.btn-active-color-danger i, .btn.btn-active-color-danger:focus:not(.btn-active) i, .btn.btn-active-color-danger:hover:not(.btn-active) i, .btn.btn-active-color-danger:active:not(.btn-active) i, .btn.btn-active-color-danger.active i, .btn.btn-active-color-danger.show i, .show > .btn.btn-active-color-danger i {
  color: #F1416C;
}
.btn-check:checked + .btn.btn-active-color-danger .svg-icon svg [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-active-color-danger .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-color-danger:focus:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-color-danger:hover:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-color-danger:active:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-color-danger.active .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-color-danger.show .svg-icon svg [fill]:not(.permanent):not(g), .show > .btn.btn-active-color-danger .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #F1416C;
}
.btn-check:checked + .btn.btn-active-color-danger .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-active-color-danger .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-color-danger:focus:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-color-danger:hover:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-color-danger:active:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-color-danger.active .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-color-danger.show .svg-icon svg:hover [fill]:not(.permanent):not(g), .show > .btn.btn-active-color-danger .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.btn-check:checked + .btn.btn-active-color-danger.dropdown-toggle:after, .btn-check:active + .btn.btn-active-color-danger.dropdown-toggle:after, .btn.btn-active-color-danger:focus:not(.btn-active).dropdown-toggle:after, .btn.btn-active-color-danger:hover:not(.btn-active).dropdown-toggle:after, .btn.btn-active-color-danger:active:not(.btn-active).dropdown-toggle:after, .btn.btn-active-color-danger.active.dropdown-toggle:after, .btn.btn-active-color-danger.show.dropdown-toggle:after, .show > .btn.btn-active-color-danger.dropdown-toggle:after {
  color: #F1416C;
}

.btn.btn-icon-danger i {
  color: #F1416C;
}
.btn.btn-icon-danger .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #F1416C;
}
.btn.btn-icon-danger .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.btn.btn-icon-danger.dropdown-toggle:after {
  color: #F1416C;
}
.btn-check:checked + .btn.btn-active-icon-danger i, .btn-check:active + .btn.btn-active-icon-danger i, .btn.btn-active-icon-danger:focus:not(.btn-active) i, .btn.btn-active-icon-danger:hover:not(.btn-active) i, .btn.btn-active-icon-danger:active:not(.btn-active) i, .btn.btn-active-icon-danger.active i, .btn.btn-active-icon-danger.show i, .show > .btn.btn-active-icon-danger i {
  color: #F1416C;
}
.btn-check:checked + .btn.btn-active-icon-danger .svg-icon svg [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-active-icon-danger .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-icon-danger:focus:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-icon-danger:hover:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-icon-danger:active:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-icon-danger.active .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-icon-danger.show .svg-icon svg [fill]:not(.permanent):not(g), .show > .btn.btn-active-icon-danger .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #F1416C;
}
.btn-check:checked + .btn.btn-active-icon-danger .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-active-icon-danger .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-icon-danger:focus:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-icon-danger:hover:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-icon-danger:active:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-icon-danger.active .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-icon-danger.show .svg-icon svg:hover [fill]:not(.permanent):not(g), .show > .btn.btn-active-icon-danger .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.btn-check:checked + .btn.btn-active-icon-danger.dropdown-toggle:after, .btn-check:active + .btn.btn-active-icon-danger.dropdown-toggle:after, .btn.btn-active-icon-danger:focus:not(.btn-active).dropdown-toggle:after, .btn.btn-active-icon-danger:hover:not(.btn-active).dropdown-toggle:after, .btn.btn-active-icon-danger:active:not(.btn-active).dropdown-toggle:after, .btn.btn-active-icon-danger.active.dropdown-toggle:after, .btn.btn-active-icon-danger.show.dropdown-toggle:after, .show > .btn.btn-active-icon-danger.dropdown-toggle:after {
  color: #F1416C;
}

.btn.btn-text-danger {
  color: #F1416C;
}
.btn-check:checked + .btn.btn-active-text-danger, .btn-check:active + .btn.btn-active-text-danger, .btn.btn-active-text-danger:focus:not(.btn-active), .btn.btn-active-text-danger:hover:not(.btn-active), .btn.btn-active-text-danger:active:not(.btn-active), .btn.btn-active-text-danger.active, .btn.btn-active-text-danger.show, .show > .btn.btn-active-text-danger {
  color: #F1416C;
}

.btn.btn-color-dark {
  color: #181C32;
}
.btn.btn-color-dark i {
  color: #181C32;
}
.btn.btn-color-dark .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #181C32;
}
.btn.btn-color-dark .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.btn.btn-color-dark.dropdown-toggle:after {
  color: #181C32;
}
.btn-check:checked + .btn.btn-active-color-dark, .btn-check:active + .btn.btn-active-color-dark, .btn.btn-active-color-dark:focus:not(.btn-active), .btn.btn-active-color-dark:hover:not(.btn-active), .btn.btn-active-color-dark:active:not(.btn-active), .btn.btn-active-color-dark.active, .btn.btn-active-color-dark.show, .show > .btn.btn-active-color-dark {
  color: #181C32;
}
.btn-check:checked + .btn.btn-active-color-dark i, .btn-check:active + .btn.btn-active-color-dark i, .btn.btn-active-color-dark:focus:not(.btn-active) i, .btn.btn-active-color-dark:hover:not(.btn-active) i, .btn.btn-active-color-dark:active:not(.btn-active) i, .btn.btn-active-color-dark.active i, .btn.btn-active-color-dark.show i, .show > .btn.btn-active-color-dark i {
  color: #181C32;
}
.btn-check:checked + .btn.btn-active-color-dark .svg-icon svg [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-active-color-dark .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-color-dark:focus:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-color-dark:hover:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-color-dark:active:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-color-dark.active .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-color-dark.show .svg-icon svg [fill]:not(.permanent):not(g), .show > .btn.btn-active-color-dark .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #181C32;
}
.btn-check:checked + .btn.btn-active-color-dark .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-active-color-dark .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-color-dark:focus:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-color-dark:hover:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-color-dark:active:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-color-dark.active .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-color-dark.show .svg-icon svg:hover [fill]:not(.permanent):not(g), .show > .btn.btn-active-color-dark .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.btn-check:checked + .btn.btn-active-color-dark.dropdown-toggle:after, .btn-check:active + .btn.btn-active-color-dark.dropdown-toggle:after, .btn.btn-active-color-dark:focus:not(.btn-active).dropdown-toggle:after, .btn.btn-active-color-dark:hover:not(.btn-active).dropdown-toggle:after, .btn.btn-active-color-dark:active:not(.btn-active).dropdown-toggle:after, .btn.btn-active-color-dark.active.dropdown-toggle:after, .btn.btn-active-color-dark.show.dropdown-toggle:after, .show > .btn.btn-active-color-dark.dropdown-toggle:after {
  color: #181C32;
}

.btn.btn-icon-dark i {
  color: #181C32;
}
.btn.btn-icon-dark .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #181C32;
}
.btn.btn-icon-dark .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.btn.btn-icon-dark.dropdown-toggle:after {
  color: #181C32;
}
.btn-check:checked + .btn.btn-active-icon-dark i, .btn-check:active + .btn.btn-active-icon-dark i, .btn.btn-active-icon-dark:focus:not(.btn-active) i, .btn.btn-active-icon-dark:hover:not(.btn-active) i, .btn.btn-active-icon-dark:active:not(.btn-active) i, .btn.btn-active-icon-dark.active i, .btn.btn-active-icon-dark.show i, .show > .btn.btn-active-icon-dark i {
  color: #181C32;
}
.btn-check:checked + .btn.btn-active-icon-dark .svg-icon svg [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-active-icon-dark .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-icon-dark:focus:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-icon-dark:hover:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-icon-dark:active:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-icon-dark.active .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-icon-dark.show .svg-icon svg [fill]:not(.permanent):not(g), .show > .btn.btn-active-icon-dark .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #181C32;
}
.btn-check:checked + .btn.btn-active-icon-dark .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-active-icon-dark .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-icon-dark:focus:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-icon-dark:hover:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-icon-dark:active:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-icon-dark.active .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-icon-dark.show .svg-icon svg:hover [fill]:not(.permanent):not(g), .show > .btn.btn-active-icon-dark .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.btn-check:checked + .btn.btn-active-icon-dark.dropdown-toggle:after, .btn-check:active + .btn.btn-active-icon-dark.dropdown-toggle:after, .btn.btn-active-icon-dark:focus:not(.btn-active).dropdown-toggle:after, .btn.btn-active-icon-dark:hover:not(.btn-active).dropdown-toggle:after, .btn.btn-active-icon-dark:active:not(.btn-active).dropdown-toggle:after, .btn.btn-active-icon-dark.active.dropdown-toggle:after, .btn.btn-active-icon-dark.show.dropdown-toggle:after, .show > .btn.btn-active-icon-dark.dropdown-toggle:after {
  color: #181C32;
}

.btn.btn-text-dark {
  color: #181C32;
}
.btn-check:checked + .btn.btn-active-text-dark, .btn-check:active + .btn.btn-active-text-dark, .btn.btn-active-text-dark:focus:not(.btn-active), .btn.btn-active-text-dark:hover:not(.btn-active), .btn.btn-active-text-dark:active:not(.btn-active), .btn.btn-active-text-dark.active, .btn.btn-active-text-dark.show, .show > .btn.btn-active-text-dark {
  color: #181C32;
}

.btn.btn-color-muted {
  color: #A1A5B7;
}
.btn.btn-color-muted i {
  color: #A1A5B7;
}
.btn.btn-color-muted .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #A1A5B7;
}
.btn.btn-color-muted .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.btn.btn-color-muted.dropdown-toggle:after {
  color: #A1A5B7;
}
.btn-check:checked + .btn.btn-active-color-muted, .btn-check:active + .btn.btn-active-color-muted, .btn.btn-active-color-muted:focus:not(.btn-active), .btn.btn-active-color-muted:hover:not(.btn-active), .btn.btn-active-color-muted:active:not(.btn-active), .btn.btn-active-color-muted.active, .btn.btn-active-color-muted.show, .show > .btn.btn-active-color-muted {
  color: #A1A5B7;
}
.btn-check:checked + .btn.btn-active-color-muted i, .btn-check:active + .btn.btn-active-color-muted i, .btn.btn-active-color-muted:focus:not(.btn-active) i, .btn.btn-active-color-muted:hover:not(.btn-active) i, .btn.btn-active-color-muted:active:not(.btn-active) i, .btn.btn-active-color-muted.active i, .btn.btn-active-color-muted.show i, .show > .btn.btn-active-color-muted i {
  color: #A1A5B7;
}
.btn-check:checked + .btn.btn-active-color-muted .svg-icon svg [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-active-color-muted .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-color-muted:focus:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-color-muted:hover:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-color-muted:active:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-color-muted.active .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-color-muted.show .svg-icon svg [fill]:not(.permanent):not(g), .show > .btn.btn-active-color-muted .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #A1A5B7;
}
.btn-check:checked + .btn.btn-active-color-muted .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-active-color-muted .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-color-muted:focus:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-color-muted:hover:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-color-muted:active:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-color-muted.active .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-color-muted.show .svg-icon svg:hover [fill]:not(.permanent):not(g), .show > .btn.btn-active-color-muted .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.btn-check:checked + .btn.btn-active-color-muted.dropdown-toggle:after, .btn-check:active + .btn.btn-active-color-muted.dropdown-toggle:after, .btn.btn-active-color-muted:focus:not(.btn-active).dropdown-toggle:after, .btn.btn-active-color-muted:hover:not(.btn-active).dropdown-toggle:after, .btn.btn-active-color-muted:active:not(.btn-active).dropdown-toggle:after, .btn.btn-active-color-muted.active.dropdown-toggle:after, .btn.btn-active-color-muted.show.dropdown-toggle:after, .show > .btn.btn-active-color-muted.dropdown-toggle:after {
  color: #A1A5B7;
}

.btn.btn-icon-muted i {
  color: #A1A5B7;
}
.btn.btn-icon-muted .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #A1A5B7;
}
.btn.btn-icon-muted .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.btn.btn-icon-muted.dropdown-toggle:after {
  color: #A1A5B7;
}
.btn-check:checked + .btn.btn-active-icon-muted i, .btn-check:active + .btn.btn-active-icon-muted i, .btn.btn-active-icon-muted:focus:not(.btn-active) i, .btn.btn-active-icon-muted:hover:not(.btn-active) i, .btn.btn-active-icon-muted:active:not(.btn-active) i, .btn.btn-active-icon-muted.active i, .btn.btn-active-icon-muted.show i, .show > .btn.btn-active-icon-muted i {
  color: #A1A5B7;
}
.btn-check:checked + .btn.btn-active-icon-muted .svg-icon svg [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-active-icon-muted .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-icon-muted:focus:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-icon-muted:hover:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-icon-muted:active:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-icon-muted.active .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-icon-muted.show .svg-icon svg [fill]:not(.permanent):not(g), .show > .btn.btn-active-icon-muted .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #A1A5B7;
}
.btn-check:checked + .btn.btn-active-icon-muted .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-active-icon-muted .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-icon-muted:focus:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-icon-muted:hover:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-icon-muted:active:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-icon-muted.active .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-icon-muted.show .svg-icon svg:hover [fill]:not(.permanent):not(g), .show > .btn.btn-active-icon-muted .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.btn-check:checked + .btn.btn-active-icon-muted.dropdown-toggle:after, .btn-check:active + .btn.btn-active-icon-muted.dropdown-toggle:after, .btn.btn-active-icon-muted:focus:not(.btn-active).dropdown-toggle:after, .btn.btn-active-icon-muted:hover:not(.btn-active).dropdown-toggle:after, .btn.btn-active-icon-muted:active:not(.btn-active).dropdown-toggle:after, .btn.btn-active-icon-muted.active.dropdown-toggle:after, .btn.btn-active-icon-muted.show.dropdown-toggle:after, .show > .btn.btn-active-icon-muted.dropdown-toggle:after {
  color: #A1A5B7;
}

.btn.btn-text-muted {
  color: #A1A5B7;
}
.btn-check:checked + .btn.btn-active-text-muted, .btn-check:active + .btn.btn-active-text-muted, .btn.btn-active-text-muted:focus:not(.btn-active), .btn.btn-active-text-muted:hover:not(.btn-active), .btn.btn-active-text-muted:active:not(.btn-active), .btn.btn-active-text-muted.active, .btn.btn-active-text-muted.show, .show > .btn.btn-active-text-muted {
  color: #A1A5B7;
}

.btn.btn-color-gray-100 {
  color: #F5F8FA;
}
.btn.btn-color-gray-100 i {
  color: #F5F8FA;
}
.btn.btn-color-gray-100 .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #F5F8FA;
}
.btn.btn-color-gray-100 .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.btn.btn-color-gray-100.dropdown-toggle:after {
  color: #F5F8FA;
}
.btn-check:checked + .btn.btn-active-color-gray-100, .btn-check:active + .btn.btn-active-color-gray-100, .btn.btn-active-color-gray-100:focus:not(.btn-active), .btn.btn-active-color-gray-100:hover:not(.btn-active), .btn.btn-active-color-gray-100:active:not(.btn-active), .btn.btn-active-color-gray-100.active, .btn.btn-active-color-gray-100.show, .show > .btn.btn-active-color-gray-100 {
  color: #F5F8FA;
}
.btn-check:checked + .btn.btn-active-color-gray-100 i, .btn-check:active + .btn.btn-active-color-gray-100 i, .btn.btn-active-color-gray-100:focus:not(.btn-active) i, .btn.btn-active-color-gray-100:hover:not(.btn-active) i, .btn.btn-active-color-gray-100:active:not(.btn-active) i, .btn.btn-active-color-gray-100.active i, .btn.btn-active-color-gray-100.show i, .show > .btn.btn-active-color-gray-100 i {
  color: #F5F8FA;
}
.btn-check:checked + .btn.btn-active-color-gray-100 .svg-icon svg [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-active-color-gray-100 .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-color-gray-100:focus:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-color-gray-100:hover:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-color-gray-100:active:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-color-gray-100.active .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-color-gray-100.show .svg-icon svg [fill]:not(.permanent):not(g), .show > .btn.btn-active-color-gray-100 .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #F5F8FA;
}
.btn-check:checked + .btn.btn-active-color-gray-100 .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-active-color-gray-100 .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-color-gray-100:focus:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-color-gray-100:hover:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-color-gray-100:active:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-color-gray-100.active .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-color-gray-100.show .svg-icon svg:hover [fill]:not(.permanent):not(g), .show > .btn.btn-active-color-gray-100 .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.btn-check:checked + .btn.btn-active-color-gray-100.dropdown-toggle:after, .btn-check:active + .btn.btn-active-color-gray-100.dropdown-toggle:after, .btn.btn-active-color-gray-100:focus:not(.btn-active).dropdown-toggle:after, .btn.btn-active-color-gray-100:hover:not(.btn-active).dropdown-toggle:after, .btn.btn-active-color-gray-100:active:not(.btn-active).dropdown-toggle:after, .btn.btn-active-color-gray-100.active.dropdown-toggle:after, .btn.btn-active-color-gray-100.show.dropdown-toggle:after, .show > .btn.btn-active-color-gray-100.dropdown-toggle:after {
  color: #F5F8FA;
}

.btn.btn-icon-gray-100 i {
  color: #F5F8FA;
}
.btn.btn-icon-gray-100 .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #F5F8FA;
}
.btn.btn-icon-gray-100 .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.btn.btn-icon-gray-100.dropdown-toggle:after {
  color: #F5F8FA;
}
.btn-check:checked + .btn.btn-active-icon-gray-100 i, .btn-check:active + .btn.btn-active-icon-gray-100 i, .btn.btn-active-icon-gray-100:focus:not(.btn-active) i, .btn.btn-active-icon-gray-100:hover:not(.btn-active) i, .btn.btn-active-icon-gray-100:active:not(.btn-active) i, .btn.btn-active-icon-gray-100.active i, .btn.btn-active-icon-gray-100.show i, .show > .btn.btn-active-icon-gray-100 i {
  color: #F5F8FA;
}
.btn-check:checked + .btn.btn-active-icon-gray-100 .svg-icon svg [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-active-icon-gray-100 .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-icon-gray-100:focus:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-icon-gray-100:hover:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-icon-gray-100:active:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-icon-gray-100.active .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-icon-gray-100.show .svg-icon svg [fill]:not(.permanent):not(g), .show > .btn.btn-active-icon-gray-100 .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #F5F8FA;
}
.btn-check:checked + .btn.btn-active-icon-gray-100 .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-active-icon-gray-100 .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-icon-gray-100:focus:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-icon-gray-100:hover:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-icon-gray-100:active:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-icon-gray-100.active .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-icon-gray-100.show .svg-icon svg:hover [fill]:not(.permanent):not(g), .show > .btn.btn-active-icon-gray-100 .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.btn-check:checked + .btn.btn-active-icon-gray-100.dropdown-toggle:after, .btn-check:active + .btn.btn-active-icon-gray-100.dropdown-toggle:after, .btn.btn-active-icon-gray-100:focus:not(.btn-active).dropdown-toggle:after, .btn.btn-active-icon-gray-100:hover:not(.btn-active).dropdown-toggle:after, .btn.btn-active-icon-gray-100:active:not(.btn-active).dropdown-toggle:after, .btn.btn-active-icon-gray-100.active.dropdown-toggle:after, .btn.btn-active-icon-gray-100.show.dropdown-toggle:after, .show > .btn.btn-active-icon-gray-100.dropdown-toggle:after {
  color: #F5F8FA;
}

.btn.btn-text-gray-100 {
  color: #F5F8FA;
}
.btn-check:checked + .btn.btn-active-text-gray-100, .btn-check:active + .btn.btn-active-text-gray-100, .btn.btn-active-text-gray-100:focus:not(.btn-active), .btn.btn-active-text-gray-100:hover:not(.btn-active), .btn.btn-active-text-gray-100:active:not(.btn-active), .btn.btn-active-text-gray-100.active, .btn.btn-active-text-gray-100.show, .show > .btn.btn-active-text-gray-100 {
  color: #F5F8FA;
}

.btn.btn-color-gray-200 {
  color: #EFF2F5;
}
.btn.btn-color-gray-200 i {
  color: #EFF2F5;
}
.btn.btn-color-gray-200 .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #EFF2F5;
}
.btn.btn-color-gray-200 .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.btn.btn-color-gray-200.dropdown-toggle:after {
  color: #EFF2F5;
}
.btn-check:checked + .btn.btn-active-color-gray-200, .btn-check:active + .btn.btn-active-color-gray-200, .btn.btn-active-color-gray-200:focus:not(.btn-active), .btn.btn-active-color-gray-200:hover:not(.btn-active), .btn.btn-active-color-gray-200:active:not(.btn-active), .btn.btn-active-color-gray-200.active, .btn.btn-active-color-gray-200.show, .show > .btn.btn-active-color-gray-200 {
  color: #EFF2F5;
}
.btn-check:checked + .btn.btn-active-color-gray-200 i, .btn-check:active + .btn.btn-active-color-gray-200 i, .btn.btn-active-color-gray-200:focus:not(.btn-active) i, .btn.btn-active-color-gray-200:hover:not(.btn-active) i, .btn.btn-active-color-gray-200:active:not(.btn-active) i, .btn.btn-active-color-gray-200.active i, .btn.btn-active-color-gray-200.show i, .show > .btn.btn-active-color-gray-200 i {
  color: #EFF2F5;
}
.btn-check:checked + .btn.btn-active-color-gray-200 .svg-icon svg [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-active-color-gray-200 .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-color-gray-200:focus:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-color-gray-200:hover:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-color-gray-200:active:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-color-gray-200.active .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-color-gray-200.show .svg-icon svg [fill]:not(.permanent):not(g), .show > .btn.btn-active-color-gray-200 .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #EFF2F5;
}
.btn-check:checked + .btn.btn-active-color-gray-200 .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-active-color-gray-200 .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-color-gray-200:focus:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-color-gray-200:hover:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-color-gray-200:active:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-color-gray-200.active .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-color-gray-200.show .svg-icon svg:hover [fill]:not(.permanent):not(g), .show > .btn.btn-active-color-gray-200 .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.btn-check:checked + .btn.btn-active-color-gray-200.dropdown-toggle:after, .btn-check:active + .btn.btn-active-color-gray-200.dropdown-toggle:after, .btn.btn-active-color-gray-200:focus:not(.btn-active).dropdown-toggle:after, .btn.btn-active-color-gray-200:hover:not(.btn-active).dropdown-toggle:after, .btn.btn-active-color-gray-200:active:not(.btn-active).dropdown-toggle:after, .btn.btn-active-color-gray-200.active.dropdown-toggle:after, .btn.btn-active-color-gray-200.show.dropdown-toggle:after, .show > .btn.btn-active-color-gray-200.dropdown-toggle:after {
  color: #EFF2F5;
}

.btn.btn-icon-gray-200 i {
  color: #EFF2F5;
}
.btn.btn-icon-gray-200 .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #EFF2F5;
}
.btn.btn-icon-gray-200 .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.btn.btn-icon-gray-200.dropdown-toggle:after {
  color: #EFF2F5;
}
.btn-check:checked + .btn.btn-active-icon-gray-200 i, .btn-check:active + .btn.btn-active-icon-gray-200 i, .btn.btn-active-icon-gray-200:focus:not(.btn-active) i, .btn.btn-active-icon-gray-200:hover:not(.btn-active) i, .btn.btn-active-icon-gray-200:active:not(.btn-active) i, .btn.btn-active-icon-gray-200.active i, .btn.btn-active-icon-gray-200.show i, .show > .btn.btn-active-icon-gray-200 i {
  color: #EFF2F5;
}
.btn-check:checked + .btn.btn-active-icon-gray-200 .svg-icon svg [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-active-icon-gray-200 .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-icon-gray-200:focus:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-icon-gray-200:hover:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-icon-gray-200:active:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-icon-gray-200.active .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-icon-gray-200.show .svg-icon svg [fill]:not(.permanent):not(g), .show > .btn.btn-active-icon-gray-200 .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #EFF2F5;
}
.btn-check:checked + .btn.btn-active-icon-gray-200 .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-active-icon-gray-200 .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-icon-gray-200:focus:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-icon-gray-200:hover:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-icon-gray-200:active:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-icon-gray-200.active .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-icon-gray-200.show .svg-icon svg:hover [fill]:not(.permanent):not(g), .show > .btn.btn-active-icon-gray-200 .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.btn-check:checked + .btn.btn-active-icon-gray-200.dropdown-toggle:after, .btn-check:active + .btn.btn-active-icon-gray-200.dropdown-toggle:after, .btn.btn-active-icon-gray-200:focus:not(.btn-active).dropdown-toggle:after, .btn.btn-active-icon-gray-200:hover:not(.btn-active).dropdown-toggle:after, .btn.btn-active-icon-gray-200:active:not(.btn-active).dropdown-toggle:after, .btn.btn-active-icon-gray-200.active.dropdown-toggle:after, .btn.btn-active-icon-gray-200.show.dropdown-toggle:after, .show > .btn.btn-active-icon-gray-200.dropdown-toggle:after {
  color: #EFF2F5;
}

.btn.btn-text-gray-200 {
  color: #EFF2F5;
}
.btn-check:checked + .btn.btn-active-text-gray-200, .btn-check:active + .btn.btn-active-text-gray-200, .btn.btn-active-text-gray-200:focus:not(.btn-active), .btn.btn-active-text-gray-200:hover:not(.btn-active), .btn.btn-active-text-gray-200:active:not(.btn-active), .btn.btn-active-text-gray-200.active, .btn.btn-active-text-gray-200.show, .show > .btn.btn-active-text-gray-200 {
  color: #EFF2F5;
}

.btn.btn-color-gray-300 {
  color: #E4E6EF;
}
.btn.btn-color-gray-300 i {
  color: #E4E6EF;
}
.btn.btn-color-gray-300 .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #E4E6EF;
}
.btn.btn-color-gray-300 .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.btn.btn-color-gray-300.dropdown-toggle:after {
  color: #E4E6EF;
}
.btn-check:checked + .btn.btn-active-color-gray-300, .btn-check:active + .btn.btn-active-color-gray-300, .btn.btn-active-color-gray-300:focus:not(.btn-active), .btn.btn-active-color-gray-300:hover:not(.btn-active), .btn.btn-active-color-gray-300:active:not(.btn-active), .btn.btn-active-color-gray-300.active, .btn.btn-active-color-gray-300.show, .show > .btn.btn-active-color-gray-300 {
  color: #E4E6EF;
}
.btn-check:checked + .btn.btn-active-color-gray-300 i, .btn-check:active + .btn.btn-active-color-gray-300 i, .btn.btn-active-color-gray-300:focus:not(.btn-active) i, .btn.btn-active-color-gray-300:hover:not(.btn-active) i, .btn.btn-active-color-gray-300:active:not(.btn-active) i, .btn.btn-active-color-gray-300.active i, .btn.btn-active-color-gray-300.show i, .show > .btn.btn-active-color-gray-300 i {
  color: #E4E6EF;
}
.btn-check:checked + .btn.btn-active-color-gray-300 .svg-icon svg [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-active-color-gray-300 .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-color-gray-300:focus:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-color-gray-300:hover:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-color-gray-300:active:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-color-gray-300.active .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-color-gray-300.show .svg-icon svg [fill]:not(.permanent):not(g), .show > .btn.btn-active-color-gray-300 .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #E4E6EF;
}
.btn-check:checked + .btn.btn-active-color-gray-300 .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-active-color-gray-300 .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-color-gray-300:focus:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-color-gray-300:hover:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-color-gray-300:active:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-color-gray-300.active .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-color-gray-300.show .svg-icon svg:hover [fill]:not(.permanent):not(g), .show > .btn.btn-active-color-gray-300 .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.btn-check:checked + .btn.btn-active-color-gray-300.dropdown-toggle:after, .btn-check:active + .btn.btn-active-color-gray-300.dropdown-toggle:after, .btn.btn-active-color-gray-300:focus:not(.btn-active).dropdown-toggle:after, .btn.btn-active-color-gray-300:hover:not(.btn-active).dropdown-toggle:after, .btn.btn-active-color-gray-300:active:not(.btn-active).dropdown-toggle:after, .btn.btn-active-color-gray-300.active.dropdown-toggle:after, .btn.btn-active-color-gray-300.show.dropdown-toggle:after, .show > .btn.btn-active-color-gray-300.dropdown-toggle:after {
  color: #E4E6EF;
}

.btn.btn-icon-gray-300 i {
  color: #E4E6EF;
}
.btn.btn-icon-gray-300 .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #E4E6EF;
}
.btn.btn-icon-gray-300 .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.btn.btn-icon-gray-300.dropdown-toggle:after {
  color: #E4E6EF;
}
.btn-check:checked + .btn.btn-active-icon-gray-300 i, .btn-check:active + .btn.btn-active-icon-gray-300 i, .btn.btn-active-icon-gray-300:focus:not(.btn-active) i, .btn.btn-active-icon-gray-300:hover:not(.btn-active) i, .btn.btn-active-icon-gray-300:active:not(.btn-active) i, .btn.btn-active-icon-gray-300.active i, .btn.btn-active-icon-gray-300.show i, .show > .btn.btn-active-icon-gray-300 i {
  color: #E4E6EF;
}
.btn-check:checked + .btn.btn-active-icon-gray-300 .svg-icon svg [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-active-icon-gray-300 .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-icon-gray-300:focus:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-icon-gray-300:hover:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-icon-gray-300:active:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-icon-gray-300.active .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-icon-gray-300.show .svg-icon svg [fill]:not(.permanent):not(g), .show > .btn.btn-active-icon-gray-300 .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #E4E6EF;
}
.btn-check:checked + .btn.btn-active-icon-gray-300 .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-active-icon-gray-300 .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-icon-gray-300:focus:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-icon-gray-300:hover:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-icon-gray-300:active:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-icon-gray-300.active .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-icon-gray-300.show .svg-icon svg:hover [fill]:not(.permanent):not(g), .show > .btn.btn-active-icon-gray-300 .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.btn-check:checked + .btn.btn-active-icon-gray-300.dropdown-toggle:after, .btn-check:active + .btn.btn-active-icon-gray-300.dropdown-toggle:after, .btn.btn-active-icon-gray-300:focus:not(.btn-active).dropdown-toggle:after, .btn.btn-active-icon-gray-300:hover:not(.btn-active).dropdown-toggle:after, .btn.btn-active-icon-gray-300:active:not(.btn-active).dropdown-toggle:after, .btn.btn-active-icon-gray-300.active.dropdown-toggle:after, .btn.btn-active-icon-gray-300.show.dropdown-toggle:after, .show > .btn.btn-active-icon-gray-300.dropdown-toggle:after {
  color: #E4E6EF;
}

.btn.btn-text-gray-300 {
  color: #E4E6EF;
}
.btn-check:checked + .btn.btn-active-text-gray-300, .btn-check:active + .btn.btn-active-text-gray-300, .btn.btn-active-text-gray-300:focus:not(.btn-active), .btn.btn-active-text-gray-300:hover:not(.btn-active), .btn.btn-active-text-gray-300:active:not(.btn-active), .btn.btn-active-text-gray-300.active, .btn.btn-active-text-gray-300.show, .show > .btn.btn-active-text-gray-300 {
  color: #E4E6EF;
}

.btn.btn-color-gray-400 {
  color: #B5B5C3;
}
.btn.btn-color-gray-400 i {
  color: #B5B5C3;
}
.btn.btn-color-gray-400 .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #B5B5C3;
}
.btn.btn-color-gray-400 .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.btn.btn-color-gray-400.dropdown-toggle:after {
  color: #B5B5C3;
}
.btn-check:checked + .btn.btn-active-color-gray-400, .btn-check:active + .btn.btn-active-color-gray-400, .btn.btn-active-color-gray-400:focus:not(.btn-active), .btn.btn-active-color-gray-400:hover:not(.btn-active), .btn.btn-active-color-gray-400:active:not(.btn-active), .btn.btn-active-color-gray-400.active, .btn.btn-active-color-gray-400.show, .show > .btn.btn-active-color-gray-400 {
  color: #B5B5C3;
}
.btn-check:checked + .btn.btn-active-color-gray-400 i, .btn-check:active + .btn.btn-active-color-gray-400 i, .btn.btn-active-color-gray-400:focus:not(.btn-active) i, .btn.btn-active-color-gray-400:hover:not(.btn-active) i, .btn.btn-active-color-gray-400:active:not(.btn-active) i, .btn.btn-active-color-gray-400.active i, .btn.btn-active-color-gray-400.show i, .show > .btn.btn-active-color-gray-400 i {
  color: #B5B5C3;
}
.btn-check:checked + .btn.btn-active-color-gray-400 .svg-icon svg [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-active-color-gray-400 .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-color-gray-400:focus:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-color-gray-400:hover:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-color-gray-400:active:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-color-gray-400.active .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-color-gray-400.show .svg-icon svg [fill]:not(.permanent):not(g), .show > .btn.btn-active-color-gray-400 .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #B5B5C3;
}
.btn-check:checked + .btn.btn-active-color-gray-400 .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-active-color-gray-400 .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-color-gray-400:focus:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-color-gray-400:hover:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-color-gray-400:active:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-color-gray-400.active .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-color-gray-400.show .svg-icon svg:hover [fill]:not(.permanent):not(g), .show > .btn.btn-active-color-gray-400 .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.btn-check:checked + .btn.btn-active-color-gray-400.dropdown-toggle:after, .btn-check:active + .btn.btn-active-color-gray-400.dropdown-toggle:after, .btn.btn-active-color-gray-400:focus:not(.btn-active).dropdown-toggle:after, .btn.btn-active-color-gray-400:hover:not(.btn-active).dropdown-toggle:after, .btn.btn-active-color-gray-400:active:not(.btn-active).dropdown-toggle:after, .btn.btn-active-color-gray-400.active.dropdown-toggle:after, .btn.btn-active-color-gray-400.show.dropdown-toggle:after, .show > .btn.btn-active-color-gray-400.dropdown-toggle:after {
  color: #B5B5C3;
}

.btn.btn-icon-gray-400 i {
  color: #B5B5C3;
}
.btn.btn-icon-gray-400 .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #B5B5C3;
}
.btn.btn-icon-gray-400 .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.btn.btn-icon-gray-400.dropdown-toggle:after {
  color: #B5B5C3;
}
.btn-check:checked + .btn.btn-active-icon-gray-400 i, .btn-check:active + .btn.btn-active-icon-gray-400 i, .btn.btn-active-icon-gray-400:focus:not(.btn-active) i, .btn.btn-active-icon-gray-400:hover:not(.btn-active) i, .btn.btn-active-icon-gray-400:active:not(.btn-active) i, .btn.btn-active-icon-gray-400.active i, .btn.btn-active-icon-gray-400.show i, .show > .btn.btn-active-icon-gray-400 i {
  color: #B5B5C3;
}
.btn-check:checked + .btn.btn-active-icon-gray-400 .svg-icon svg [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-active-icon-gray-400 .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-icon-gray-400:focus:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-icon-gray-400:hover:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-icon-gray-400:active:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-icon-gray-400.active .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-icon-gray-400.show .svg-icon svg [fill]:not(.permanent):not(g), .show > .btn.btn-active-icon-gray-400 .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #B5B5C3;
}
.btn-check:checked + .btn.btn-active-icon-gray-400 .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-active-icon-gray-400 .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-icon-gray-400:focus:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-icon-gray-400:hover:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-icon-gray-400:active:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-icon-gray-400.active .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-icon-gray-400.show .svg-icon svg:hover [fill]:not(.permanent):not(g), .show > .btn.btn-active-icon-gray-400 .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.btn-check:checked + .btn.btn-active-icon-gray-400.dropdown-toggle:after, .btn-check:active + .btn.btn-active-icon-gray-400.dropdown-toggle:after, .btn.btn-active-icon-gray-400:focus:not(.btn-active).dropdown-toggle:after, .btn.btn-active-icon-gray-400:hover:not(.btn-active).dropdown-toggle:after, .btn.btn-active-icon-gray-400:active:not(.btn-active).dropdown-toggle:after, .btn.btn-active-icon-gray-400.active.dropdown-toggle:after, .btn.btn-active-icon-gray-400.show.dropdown-toggle:after, .show > .btn.btn-active-icon-gray-400.dropdown-toggle:after {
  color: #B5B5C3;
}

.btn.btn-text-gray-400 {
  color: #B5B5C3;
}
.btn-check:checked + .btn.btn-active-text-gray-400, .btn-check:active + .btn.btn-active-text-gray-400, .btn.btn-active-text-gray-400:focus:not(.btn-active), .btn.btn-active-text-gray-400:hover:not(.btn-active), .btn.btn-active-text-gray-400:active:not(.btn-active), .btn.btn-active-text-gray-400.active, .btn.btn-active-text-gray-400.show, .show > .btn.btn-active-text-gray-400 {
  color: #B5B5C3;
}

.btn.btn-color-gray-500 {
  color: #A1A5B7;
}
.btn.btn-color-gray-500 i {
  color: #A1A5B7;
}
.btn.btn-color-gray-500 .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #A1A5B7;
}
.btn.btn-color-gray-500 .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.btn.btn-color-gray-500.dropdown-toggle:after {
  color: #A1A5B7;
}
.btn-check:checked + .btn.btn-active-color-gray-500, .btn-check:active + .btn.btn-active-color-gray-500, .btn.btn-active-color-gray-500:focus:not(.btn-active), .btn.btn-active-color-gray-500:hover:not(.btn-active), .btn.btn-active-color-gray-500:active:not(.btn-active), .btn.btn-active-color-gray-500.active, .btn.btn-active-color-gray-500.show, .show > .btn.btn-active-color-gray-500 {
  color: #A1A5B7;
}
.btn-check:checked + .btn.btn-active-color-gray-500 i, .btn-check:active + .btn.btn-active-color-gray-500 i, .btn.btn-active-color-gray-500:focus:not(.btn-active) i, .btn.btn-active-color-gray-500:hover:not(.btn-active) i, .btn.btn-active-color-gray-500:active:not(.btn-active) i, .btn.btn-active-color-gray-500.active i, .btn.btn-active-color-gray-500.show i, .show > .btn.btn-active-color-gray-500 i {
  color: #A1A5B7;
}
.btn-check:checked + .btn.btn-active-color-gray-500 .svg-icon svg [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-active-color-gray-500 .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-color-gray-500:focus:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-color-gray-500:hover:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-color-gray-500:active:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-color-gray-500.active .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-color-gray-500.show .svg-icon svg [fill]:not(.permanent):not(g), .show > .btn.btn-active-color-gray-500 .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #A1A5B7;
}
.btn-check:checked + .btn.btn-active-color-gray-500 .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-active-color-gray-500 .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-color-gray-500:focus:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-color-gray-500:hover:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-color-gray-500:active:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-color-gray-500.active .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-color-gray-500.show .svg-icon svg:hover [fill]:not(.permanent):not(g), .show > .btn.btn-active-color-gray-500 .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.btn-check:checked + .btn.btn-active-color-gray-500.dropdown-toggle:after, .btn-check:active + .btn.btn-active-color-gray-500.dropdown-toggle:after, .btn.btn-active-color-gray-500:focus:not(.btn-active).dropdown-toggle:after, .btn.btn-active-color-gray-500:hover:not(.btn-active).dropdown-toggle:after, .btn.btn-active-color-gray-500:active:not(.btn-active).dropdown-toggle:after, .btn.btn-active-color-gray-500.active.dropdown-toggle:after, .btn.btn-active-color-gray-500.show.dropdown-toggle:after, .show > .btn.btn-active-color-gray-500.dropdown-toggle:after {
  color: #A1A5B7;
}

.btn.btn-icon-gray-500 i {
  color: #A1A5B7;
}
.btn.btn-icon-gray-500 .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #A1A5B7;
}
.btn.btn-icon-gray-500 .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.btn.btn-icon-gray-500.dropdown-toggle:after {
  color: #A1A5B7;
}
.btn-check:checked + .btn.btn-active-icon-gray-500 i, .btn-check:active + .btn.btn-active-icon-gray-500 i, .btn.btn-active-icon-gray-500:focus:not(.btn-active) i, .btn.btn-active-icon-gray-500:hover:not(.btn-active) i, .btn.btn-active-icon-gray-500:active:not(.btn-active) i, .btn.btn-active-icon-gray-500.active i, .btn.btn-active-icon-gray-500.show i, .show > .btn.btn-active-icon-gray-500 i {
  color: #A1A5B7;
}
.btn-check:checked + .btn.btn-active-icon-gray-500 .svg-icon svg [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-active-icon-gray-500 .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-icon-gray-500:focus:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-icon-gray-500:hover:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-icon-gray-500:active:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-icon-gray-500.active .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-icon-gray-500.show .svg-icon svg [fill]:not(.permanent):not(g), .show > .btn.btn-active-icon-gray-500 .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #A1A5B7;
}
.btn-check:checked + .btn.btn-active-icon-gray-500 .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-active-icon-gray-500 .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-icon-gray-500:focus:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-icon-gray-500:hover:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-icon-gray-500:active:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-icon-gray-500.active .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-icon-gray-500.show .svg-icon svg:hover [fill]:not(.permanent):not(g), .show > .btn.btn-active-icon-gray-500 .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.btn-check:checked + .btn.btn-active-icon-gray-500.dropdown-toggle:after, .btn-check:active + .btn.btn-active-icon-gray-500.dropdown-toggle:after, .btn.btn-active-icon-gray-500:focus:not(.btn-active).dropdown-toggle:after, .btn.btn-active-icon-gray-500:hover:not(.btn-active).dropdown-toggle:after, .btn.btn-active-icon-gray-500:active:not(.btn-active).dropdown-toggle:after, .btn.btn-active-icon-gray-500.active.dropdown-toggle:after, .btn.btn-active-icon-gray-500.show.dropdown-toggle:after, .show > .btn.btn-active-icon-gray-500.dropdown-toggle:after {
  color: #A1A5B7;
}

.btn.btn-text-gray-500 {
  color: #A1A5B7;
}
.btn-check:checked + .btn.btn-active-text-gray-500, .btn-check:active + .btn.btn-active-text-gray-500, .btn.btn-active-text-gray-500:focus:not(.btn-active), .btn.btn-active-text-gray-500:hover:not(.btn-active), .btn.btn-active-text-gray-500:active:not(.btn-active), .btn.btn-active-text-gray-500.active, .btn.btn-active-text-gray-500.show, .show > .btn.btn-active-text-gray-500 {
  color: #A1A5B7;
}

.btn.btn-color-gray-600 {
  color: #7E8299;
}
.btn.btn-color-gray-600 i {
  color: #7E8299;
}
.btn.btn-color-gray-600 .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #7E8299;
}
.btn.btn-color-gray-600 .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.btn.btn-color-gray-600.dropdown-toggle:after {
  color: #7E8299;
}
.btn-check:checked + .btn.btn-active-color-gray-600, .btn-check:active + .btn.btn-active-color-gray-600, .btn.btn-active-color-gray-600:focus:not(.btn-active), .btn.btn-active-color-gray-600:hover:not(.btn-active), .btn.btn-active-color-gray-600:active:not(.btn-active), .btn.btn-active-color-gray-600.active, .btn.btn-active-color-gray-600.show, .show > .btn.btn-active-color-gray-600 {
  color: #7E8299;
}
.btn-check:checked + .btn.btn-active-color-gray-600 i, .btn-check:active + .btn.btn-active-color-gray-600 i, .btn.btn-active-color-gray-600:focus:not(.btn-active) i, .btn.btn-active-color-gray-600:hover:not(.btn-active) i, .btn.btn-active-color-gray-600:active:not(.btn-active) i, .btn.btn-active-color-gray-600.active i, .btn.btn-active-color-gray-600.show i, .show > .btn.btn-active-color-gray-600 i {
  color: #7E8299;
}
.btn-check:checked + .btn.btn-active-color-gray-600 .svg-icon svg [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-active-color-gray-600 .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-color-gray-600:focus:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-color-gray-600:hover:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-color-gray-600:active:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-color-gray-600.active .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-color-gray-600.show .svg-icon svg [fill]:not(.permanent):not(g), .show > .btn.btn-active-color-gray-600 .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #7E8299;
}
.btn-check:checked + .btn.btn-active-color-gray-600 .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-active-color-gray-600 .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-color-gray-600:focus:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-color-gray-600:hover:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-color-gray-600:active:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-color-gray-600.active .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-color-gray-600.show .svg-icon svg:hover [fill]:not(.permanent):not(g), .show > .btn.btn-active-color-gray-600 .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.btn-check:checked + .btn.btn-active-color-gray-600.dropdown-toggle:after, .btn-check:active + .btn.btn-active-color-gray-600.dropdown-toggle:after, .btn.btn-active-color-gray-600:focus:not(.btn-active).dropdown-toggle:after, .btn.btn-active-color-gray-600:hover:not(.btn-active).dropdown-toggle:after, .btn.btn-active-color-gray-600:active:not(.btn-active).dropdown-toggle:after, .btn.btn-active-color-gray-600.active.dropdown-toggle:after, .btn.btn-active-color-gray-600.show.dropdown-toggle:after, .show > .btn.btn-active-color-gray-600.dropdown-toggle:after {
  color: #7E8299;
}

.btn.btn-icon-gray-600 i {
  color: #7E8299;
}
.btn.btn-icon-gray-600 .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #7E8299;
}
.btn.btn-icon-gray-600 .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.btn.btn-icon-gray-600.dropdown-toggle:after {
  color: #7E8299;
}
.btn-check:checked + .btn.btn-active-icon-gray-600 i, .btn-check:active + .btn.btn-active-icon-gray-600 i, .btn.btn-active-icon-gray-600:focus:not(.btn-active) i, .btn.btn-active-icon-gray-600:hover:not(.btn-active) i, .btn.btn-active-icon-gray-600:active:not(.btn-active) i, .btn.btn-active-icon-gray-600.active i, .btn.btn-active-icon-gray-600.show i, .show > .btn.btn-active-icon-gray-600 i {
  color: #7E8299;
}
.btn-check:checked + .btn.btn-active-icon-gray-600 .svg-icon svg [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-active-icon-gray-600 .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-icon-gray-600:focus:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-icon-gray-600:hover:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-icon-gray-600:active:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-icon-gray-600.active .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-icon-gray-600.show .svg-icon svg [fill]:not(.permanent):not(g), .show > .btn.btn-active-icon-gray-600 .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #7E8299;
}
.btn-check:checked + .btn.btn-active-icon-gray-600 .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-active-icon-gray-600 .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-icon-gray-600:focus:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-icon-gray-600:hover:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-icon-gray-600:active:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-icon-gray-600.active .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-icon-gray-600.show .svg-icon svg:hover [fill]:not(.permanent):not(g), .show > .btn.btn-active-icon-gray-600 .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.btn-check:checked + .btn.btn-active-icon-gray-600.dropdown-toggle:after, .btn-check:active + .btn.btn-active-icon-gray-600.dropdown-toggle:after, .btn.btn-active-icon-gray-600:focus:not(.btn-active).dropdown-toggle:after, .btn.btn-active-icon-gray-600:hover:not(.btn-active).dropdown-toggle:after, .btn.btn-active-icon-gray-600:active:not(.btn-active).dropdown-toggle:after, .btn.btn-active-icon-gray-600.active.dropdown-toggle:after, .btn.btn-active-icon-gray-600.show.dropdown-toggle:after, .show > .btn.btn-active-icon-gray-600.dropdown-toggle:after {
  color: #7E8299;
}

.btn.btn-text-gray-600 {
  color: #7E8299;
}
.btn-check:checked + .btn.btn-active-text-gray-600, .btn-check:active + .btn.btn-active-text-gray-600, .btn.btn-active-text-gray-600:focus:not(.btn-active), .btn.btn-active-text-gray-600:hover:not(.btn-active), .btn.btn-active-text-gray-600:active:not(.btn-active), .btn.btn-active-text-gray-600.active, .btn.btn-active-text-gray-600.show, .show > .btn.btn-active-text-gray-600 {
  color: #7E8299;
}

.btn.btn-color-gray-700 {
  color: #5E6278;
}
.btn.btn-color-gray-700 i {
  color: #5E6278;
}
.btn.btn-color-gray-700 .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #5E6278;
}
.btn.btn-color-gray-700 .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.btn.btn-color-gray-700.dropdown-toggle:after {
  color: #5E6278;
}
.btn-check:checked + .btn.btn-active-color-gray-700, .btn-check:active + .btn.btn-active-color-gray-700, .btn.btn-active-color-gray-700:focus:not(.btn-active), .btn.btn-active-color-gray-700:hover:not(.btn-active), .btn.btn-active-color-gray-700:active:not(.btn-active), .btn.btn-active-color-gray-700.active, .btn.btn-active-color-gray-700.show, .show > .btn.btn-active-color-gray-700 {
  color: #5E6278;
}
.btn-check:checked + .btn.btn-active-color-gray-700 i, .btn-check:active + .btn.btn-active-color-gray-700 i, .btn.btn-active-color-gray-700:focus:not(.btn-active) i, .btn.btn-active-color-gray-700:hover:not(.btn-active) i, .btn.btn-active-color-gray-700:active:not(.btn-active) i, .btn.btn-active-color-gray-700.active i, .btn.btn-active-color-gray-700.show i, .show > .btn.btn-active-color-gray-700 i {
  color: #5E6278;
}
.btn-check:checked + .btn.btn-active-color-gray-700 .svg-icon svg [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-active-color-gray-700 .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-color-gray-700:focus:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-color-gray-700:hover:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-color-gray-700:active:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-color-gray-700.active .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-color-gray-700.show .svg-icon svg [fill]:not(.permanent):not(g), .show > .btn.btn-active-color-gray-700 .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #5E6278;
}
.btn-check:checked + .btn.btn-active-color-gray-700 .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-active-color-gray-700 .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-color-gray-700:focus:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-color-gray-700:hover:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-color-gray-700:active:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-color-gray-700.active .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-color-gray-700.show .svg-icon svg:hover [fill]:not(.permanent):not(g), .show > .btn.btn-active-color-gray-700 .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.btn-check:checked + .btn.btn-active-color-gray-700.dropdown-toggle:after, .btn-check:active + .btn.btn-active-color-gray-700.dropdown-toggle:after, .btn.btn-active-color-gray-700:focus:not(.btn-active).dropdown-toggle:after, .btn.btn-active-color-gray-700:hover:not(.btn-active).dropdown-toggle:after, .btn.btn-active-color-gray-700:active:not(.btn-active).dropdown-toggle:after, .btn.btn-active-color-gray-700.active.dropdown-toggle:after, .btn.btn-active-color-gray-700.show.dropdown-toggle:after, .show > .btn.btn-active-color-gray-700.dropdown-toggle:after {
  color: #5E6278;
}

.btn.btn-icon-gray-700 i {
  color: #5E6278;
}
.btn.btn-icon-gray-700 .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #5E6278;
}
.btn.btn-icon-gray-700 .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.btn.btn-icon-gray-700.dropdown-toggle:after {
  color: #5E6278;
}
.btn-check:checked + .btn.btn-active-icon-gray-700 i, .btn-check:active + .btn.btn-active-icon-gray-700 i, .btn.btn-active-icon-gray-700:focus:not(.btn-active) i, .btn.btn-active-icon-gray-700:hover:not(.btn-active) i, .btn.btn-active-icon-gray-700:active:not(.btn-active) i, .btn.btn-active-icon-gray-700.active i, .btn.btn-active-icon-gray-700.show i, .show > .btn.btn-active-icon-gray-700 i {
  color: #5E6278;
}
.btn-check:checked + .btn.btn-active-icon-gray-700 .svg-icon svg [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-active-icon-gray-700 .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-icon-gray-700:focus:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-icon-gray-700:hover:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-icon-gray-700:active:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-icon-gray-700.active .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-icon-gray-700.show .svg-icon svg [fill]:not(.permanent):not(g), .show > .btn.btn-active-icon-gray-700 .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #5E6278;
}
.btn-check:checked + .btn.btn-active-icon-gray-700 .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-active-icon-gray-700 .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-icon-gray-700:focus:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-icon-gray-700:hover:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-icon-gray-700:active:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-icon-gray-700.active .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-icon-gray-700.show .svg-icon svg:hover [fill]:not(.permanent):not(g), .show > .btn.btn-active-icon-gray-700 .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.btn-check:checked + .btn.btn-active-icon-gray-700.dropdown-toggle:after, .btn-check:active + .btn.btn-active-icon-gray-700.dropdown-toggle:after, .btn.btn-active-icon-gray-700:focus:not(.btn-active).dropdown-toggle:after, .btn.btn-active-icon-gray-700:hover:not(.btn-active).dropdown-toggle:after, .btn.btn-active-icon-gray-700:active:not(.btn-active).dropdown-toggle:after, .btn.btn-active-icon-gray-700.active.dropdown-toggle:after, .btn.btn-active-icon-gray-700.show.dropdown-toggle:after, .show > .btn.btn-active-icon-gray-700.dropdown-toggle:after {
  color: #5E6278;
}

.btn.btn-text-gray-700 {
  color: #5E6278;
}
.btn-check:checked + .btn.btn-active-text-gray-700, .btn-check:active + .btn.btn-active-text-gray-700, .btn.btn-active-text-gray-700:focus:not(.btn-active), .btn.btn-active-text-gray-700:hover:not(.btn-active), .btn.btn-active-text-gray-700:active:not(.btn-active), .btn.btn-active-text-gray-700.active, .btn.btn-active-text-gray-700.show, .show > .btn.btn-active-text-gray-700 {
  color: #5E6278;
}

.btn.btn-color-gray-800 {
  color: #3F4254;
}
.btn.btn-color-gray-800 i {
  color: #3F4254;
}
.btn.btn-color-gray-800 .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #3F4254;
}
.btn.btn-color-gray-800 .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.btn.btn-color-gray-800.dropdown-toggle:after {
  color: #3F4254;
}
.btn-check:checked + .btn.btn-active-color-gray-800, .btn-check:active + .btn.btn-active-color-gray-800, .btn.btn-active-color-gray-800:focus:not(.btn-active), .btn.btn-active-color-gray-800:hover:not(.btn-active), .btn.btn-active-color-gray-800:active:not(.btn-active), .btn.btn-active-color-gray-800.active, .btn.btn-active-color-gray-800.show, .show > .btn.btn-active-color-gray-800 {
  color: #3F4254;
}
.btn-check:checked + .btn.btn-active-color-gray-800 i, .btn-check:active + .btn.btn-active-color-gray-800 i, .btn.btn-active-color-gray-800:focus:not(.btn-active) i, .btn.btn-active-color-gray-800:hover:not(.btn-active) i, .btn.btn-active-color-gray-800:active:not(.btn-active) i, .btn.btn-active-color-gray-800.active i, .btn.btn-active-color-gray-800.show i, .show > .btn.btn-active-color-gray-800 i {
  color: #3F4254;
}
.btn-check:checked + .btn.btn-active-color-gray-800 .svg-icon svg [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-active-color-gray-800 .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-color-gray-800:focus:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-color-gray-800:hover:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-color-gray-800:active:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-color-gray-800.active .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-color-gray-800.show .svg-icon svg [fill]:not(.permanent):not(g), .show > .btn.btn-active-color-gray-800 .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #3F4254;
}
.btn-check:checked + .btn.btn-active-color-gray-800 .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-active-color-gray-800 .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-color-gray-800:focus:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-color-gray-800:hover:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-color-gray-800:active:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-color-gray-800.active .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-color-gray-800.show .svg-icon svg:hover [fill]:not(.permanent):not(g), .show > .btn.btn-active-color-gray-800 .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.btn-check:checked + .btn.btn-active-color-gray-800.dropdown-toggle:after, .btn-check:active + .btn.btn-active-color-gray-800.dropdown-toggle:after, .btn.btn-active-color-gray-800:focus:not(.btn-active).dropdown-toggle:after, .btn.btn-active-color-gray-800:hover:not(.btn-active).dropdown-toggle:after, .btn.btn-active-color-gray-800:active:not(.btn-active).dropdown-toggle:after, .btn.btn-active-color-gray-800.active.dropdown-toggle:after, .btn.btn-active-color-gray-800.show.dropdown-toggle:after, .show > .btn.btn-active-color-gray-800.dropdown-toggle:after {
  color: #3F4254;
}

.btn.btn-icon-gray-800 i {
  color: #3F4254;
}
.btn.btn-icon-gray-800 .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #3F4254;
}
.btn.btn-icon-gray-800 .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.btn.btn-icon-gray-800.dropdown-toggle:after {
  color: #3F4254;
}
.btn-check:checked + .btn.btn-active-icon-gray-800 i, .btn-check:active + .btn.btn-active-icon-gray-800 i, .btn.btn-active-icon-gray-800:focus:not(.btn-active) i, .btn.btn-active-icon-gray-800:hover:not(.btn-active) i, .btn.btn-active-icon-gray-800:active:not(.btn-active) i, .btn.btn-active-icon-gray-800.active i, .btn.btn-active-icon-gray-800.show i, .show > .btn.btn-active-icon-gray-800 i {
  color: #3F4254;
}
.btn-check:checked + .btn.btn-active-icon-gray-800 .svg-icon svg [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-active-icon-gray-800 .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-icon-gray-800:focus:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-icon-gray-800:hover:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-icon-gray-800:active:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-icon-gray-800.active .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-icon-gray-800.show .svg-icon svg [fill]:not(.permanent):not(g), .show > .btn.btn-active-icon-gray-800 .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #3F4254;
}
.btn-check:checked + .btn.btn-active-icon-gray-800 .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-active-icon-gray-800 .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-icon-gray-800:focus:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-icon-gray-800:hover:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-icon-gray-800:active:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-icon-gray-800.active .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-icon-gray-800.show .svg-icon svg:hover [fill]:not(.permanent):not(g), .show > .btn.btn-active-icon-gray-800 .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.btn-check:checked + .btn.btn-active-icon-gray-800.dropdown-toggle:after, .btn-check:active + .btn.btn-active-icon-gray-800.dropdown-toggle:after, .btn.btn-active-icon-gray-800:focus:not(.btn-active).dropdown-toggle:after, .btn.btn-active-icon-gray-800:hover:not(.btn-active).dropdown-toggle:after, .btn.btn-active-icon-gray-800:active:not(.btn-active).dropdown-toggle:after, .btn.btn-active-icon-gray-800.active.dropdown-toggle:after, .btn.btn-active-icon-gray-800.show.dropdown-toggle:after, .show > .btn.btn-active-icon-gray-800.dropdown-toggle:after {
  color: #3F4254;
}

.btn.btn-text-gray-800 {
  color: #3F4254;
}
.btn-check:checked + .btn.btn-active-text-gray-800, .btn-check:active + .btn.btn-active-text-gray-800, .btn.btn-active-text-gray-800:focus:not(.btn-active), .btn.btn-active-text-gray-800:hover:not(.btn-active), .btn.btn-active-text-gray-800:active:not(.btn-active), .btn.btn-active-text-gray-800.active, .btn.btn-active-text-gray-800.show, .show > .btn.btn-active-text-gray-800 {
  color: #3F4254;
}

.btn.btn-color-gray-900 {
  color: #181C32;
}
.btn.btn-color-gray-900 i {
  color: #181C32;
}
.btn.btn-color-gray-900 .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #181C32;
}
.btn.btn-color-gray-900 .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.btn.btn-color-gray-900.dropdown-toggle:after {
  color: #181C32;
}
.btn-check:checked + .btn.btn-active-color-gray-900, .btn-check:active + .btn.btn-active-color-gray-900, .btn.btn-active-color-gray-900:focus:not(.btn-active), .btn.btn-active-color-gray-900:hover:not(.btn-active), .btn.btn-active-color-gray-900:active:not(.btn-active), .btn.btn-active-color-gray-900.active, .btn.btn-active-color-gray-900.show, .show > .btn.btn-active-color-gray-900 {
  color: #181C32;
}
.btn-check:checked + .btn.btn-active-color-gray-900 i, .btn-check:active + .btn.btn-active-color-gray-900 i, .btn.btn-active-color-gray-900:focus:not(.btn-active) i, .btn.btn-active-color-gray-900:hover:not(.btn-active) i, .btn.btn-active-color-gray-900:active:not(.btn-active) i, .btn.btn-active-color-gray-900.active i, .btn.btn-active-color-gray-900.show i, .show > .btn.btn-active-color-gray-900 i {
  color: #181C32;
}
.btn-check:checked + .btn.btn-active-color-gray-900 .svg-icon svg [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-active-color-gray-900 .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-color-gray-900:focus:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-color-gray-900:hover:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-color-gray-900:active:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-color-gray-900.active .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-color-gray-900.show .svg-icon svg [fill]:not(.permanent):not(g), .show > .btn.btn-active-color-gray-900 .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #181C32;
}
.btn-check:checked + .btn.btn-active-color-gray-900 .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-active-color-gray-900 .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-color-gray-900:focus:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-color-gray-900:hover:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-color-gray-900:active:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-color-gray-900.active .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-color-gray-900.show .svg-icon svg:hover [fill]:not(.permanent):not(g), .show > .btn.btn-active-color-gray-900 .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.btn-check:checked + .btn.btn-active-color-gray-900.dropdown-toggle:after, .btn-check:active + .btn.btn-active-color-gray-900.dropdown-toggle:after, .btn.btn-active-color-gray-900:focus:not(.btn-active).dropdown-toggle:after, .btn.btn-active-color-gray-900:hover:not(.btn-active).dropdown-toggle:after, .btn.btn-active-color-gray-900:active:not(.btn-active).dropdown-toggle:after, .btn.btn-active-color-gray-900.active.dropdown-toggle:after, .btn.btn-active-color-gray-900.show.dropdown-toggle:after, .show > .btn.btn-active-color-gray-900.dropdown-toggle:after {
  color: #181C32;
}

.btn.btn-icon-gray-900 i {
  color: #181C32;
}
.btn.btn-icon-gray-900 .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #181C32;
}
.btn.btn-icon-gray-900 .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.btn.btn-icon-gray-900.dropdown-toggle:after {
  color: #181C32;
}
.btn-check:checked + .btn.btn-active-icon-gray-900 i, .btn-check:active + .btn.btn-active-icon-gray-900 i, .btn.btn-active-icon-gray-900:focus:not(.btn-active) i, .btn.btn-active-icon-gray-900:hover:not(.btn-active) i, .btn.btn-active-icon-gray-900:active:not(.btn-active) i, .btn.btn-active-icon-gray-900.active i, .btn.btn-active-icon-gray-900.show i, .show > .btn.btn-active-icon-gray-900 i {
  color: #181C32;
}
.btn-check:checked + .btn.btn-active-icon-gray-900 .svg-icon svg [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-active-icon-gray-900 .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-icon-gray-900:focus:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-icon-gray-900:hover:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-icon-gray-900:active:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-icon-gray-900.active .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-icon-gray-900.show .svg-icon svg [fill]:not(.permanent):not(g), .show > .btn.btn-active-icon-gray-900 .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #181C32;
}
.btn-check:checked + .btn.btn-active-icon-gray-900 .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-active-icon-gray-900 .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-icon-gray-900:focus:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-icon-gray-900:hover:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-icon-gray-900:active:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-icon-gray-900.active .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-icon-gray-900.show .svg-icon svg:hover [fill]:not(.permanent):not(g), .show > .btn.btn-active-icon-gray-900 .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.btn-check:checked + .btn.btn-active-icon-gray-900.dropdown-toggle:after, .btn-check:active + .btn.btn-active-icon-gray-900.dropdown-toggle:after, .btn.btn-active-icon-gray-900:focus:not(.btn-active).dropdown-toggle:after, .btn.btn-active-icon-gray-900:hover:not(.btn-active).dropdown-toggle:after, .btn.btn-active-icon-gray-900:active:not(.btn-active).dropdown-toggle:after, .btn.btn-active-icon-gray-900.active.dropdown-toggle:after, .btn.btn-active-icon-gray-900.show.dropdown-toggle:after, .show > .btn.btn-active-icon-gray-900.dropdown-toggle:after {
  color: #181C32;
}

.btn.btn-text-gray-900 {
  color: #181C32;
}
.btn-check:checked + .btn.btn-active-text-gray-900, .btn-check:active + .btn.btn-active-text-gray-900, .btn.btn-active-text-gray-900:focus:not(.btn-active), .btn.btn-active-text-gray-900:hover:not(.btn-active), .btn.btn-active-text-gray-900:active:not(.btn-active), .btn.btn-active-text-gray-900.active, .btn.btn-active-text-gray-900.show, .show > .btn.btn-active-text-gray-900 {
  color: #181C32;
}

.btn.btn-facebook {
  color: #ffffff;
  border-color: #3b5998;
  background-color: #3b5998;
}
.btn.btn-facebook i {
  color: #ffffff;
}
.btn.btn-facebook .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #ffffff;
}
.btn.btn-facebook .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.btn.btn-facebook.dropdown-toggle:after {
  color: #ffffff;
}
.btn-check:checked + .btn.btn-facebook, .btn-check:active + .btn.btn-facebook, .btn.btn-facebook:focus:not(.btn-active), .btn.btn-facebook:hover:not(.btn-active), .btn.btn-facebook:active:not(.btn-active), .btn.btn-facebook.active, .btn.btn-facebook.show, .show > .btn.btn-facebook {
  border-color: #30497c;
  background-color: #30497c;
}

.btn.btn-light-facebook {
  color: #3b5998;
  border-color: rgba(59, 89, 152, 0.1);
  background-color: rgba(59, 89, 152, 0.1);
}
.btn.btn-light-facebook i {
  color: #3b5998;
}
.btn.btn-light-facebook .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #3b5998;
}
.btn.btn-light-facebook .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.btn.btn-light-facebook.dropdown-toggle:after {
  color: #3b5998;
}
.btn-check:checked + .btn.btn-light-facebook, .btn-check:active + .btn.btn-light-facebook, .btn.btn-light-facebook:focus:not(.btn-active), .btn.btn-light-facebook:hover:not(.btn-active), .btn.btn-light-facebook:active:not(.btn-active), .btn.btn-light-facebook.active, .btn.btn-light-facebook.show, .show > .btn.btn-light-facebook {
  color: #ffffff;
  border-color: #3b5998;
  background-color: #3b5998;
}
.btn-check:checked + .btn.btn-light-facebook i, .btn-check:active + .btn.btn-light-facebook i, .btn.btn-light-facebook:focus:not(.btn-active) i, .btn.btn-light-facebook:hover:not(.btn-active) i, .btn.btn-light-facebook:active:not(.btn-active) i, .btn.btn-light-facebook.active i, .btn.btn-light-facebook.show i, .show > .btn.btn-light-facebook i {
  color: #ffffff;
}
.btn-check:checked + .btn.btn-light-facebook .svg-icon svg [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-light-facebook .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-light-facebook:focus:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-light-facebook:hover:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-light-facebook:active:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-light-facebook.active .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-light-facebook.show .svg-icon svg [fill]:not(.permanent):not(g), .show > .btn.btn-light-facebook .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #ffffff;
}
.btn-check:checked + .btn.btn-light-facebook .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-light-facebook .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-light-facebook:focus:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-light-facebook:hover:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-light-facebook:active:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-light-facebook.active .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-light-facebook.show .svg-icon svg:hover [fill]:not(.permanent):not(g), .show > .btn.btn-light-facebook .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.btn-check:checked + .btn.btn-light-facebook.dropdown-toggle:after, .btn-check:active + .btn.btn-light-facebook.dropdown-toggle:after, .btn.btn-light-facebook:focus:not(.btn-active).dropdown-toggle:after, .btn.btn-light-facebook:hover:not(.btn-active).dropdown-toggle:after, .btn.btn-light-facebook:active:not(.btn-active).dropdown-toggle:after, .btn.btn-light-facebook.active.dropdown-toggle:after, .btn.btn-light-facebook.show.dropdown-toggle:after, .show > .btn.btn-light-facebook.dropdown-toggle:after {
  color: #ffffff;
}

.btn.btn-google {
  color: #ffffff;
  border-color: #dd4b39;
  background-color: #dd4b39;
}
.btn.btn-google i {
  color: #ffffff;
}
.btn.btn-google .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #ffffff;
}
.btn.btn-google .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.btn.btn-google.dropdown-toggle:after {
  color: #ffffff;
}
.btn-check:checked + .btn.btn-google, .btn-check:active + .btn.btn-google, .btn.btn-google:focus:not(.btn-active), .btn.btn-google:hover:not(.btn-active), .btn.btn-google:active:not(.btn-active), .btn.btn-google.active, .btn.btn-google.show, .show > .btn.btn-google {
  border-color: #cd3623;
  background-color: #cd3623;
}

.btn.btn-light-google {
  color: #dd4b39;
  border-color: rgba(221, 75, 57, 0.1);
  background-color: rgba(221, 75, 57, 0.1);
}
.btn.btn-light-google i {
  color: #dd4b39;
}
.btn.btn-light-google .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #dd4b39;
}
.btn.btn-light-google .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.btn.btn-light-google.dropdown-toggle:after {
  color: #dd4b39;
}
.btn-check:checked + .btn.btn-light-google, .btn-check:active + .btn.btn-light-google, .btn.btn-light-google:focus:not(.btn-active), .btn.btn-light-google:hover:not(.btn-active), .btn.btn-light-google:active:not(.btn-active), .btn.btn-light-google.active, .btn.btn-light-google.show, .show > .btn.btn-light-google {
  color: #ffffff;
  border-color: #dd4b39;
  background-color: #dd4b39;
}
.btn-check:checked + .btn.btn-light-google i, .btn-check:active + .btn.btn-light-google i, .btn.btn-light-google:focus:not(.btn-active) i, .btn.btn-light-google:hover:not(.btn-active) i, .btn.btn-light-google:active:not(.btn-active) i, .btn.btn-light-google.active i, .btn.btn-light-google.show i, .show > .btn.btn-light-google i {
  color: #ffffff;
}
.btn-check:checked + .btn.btn-light-google .svg-icon svg [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-light-google .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-light-google:focus:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-light-google:hover:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-light-google:active:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-light-google.active .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-light-google.show .svg-icon svg [fill]:not(.permanent):not(g), .show > .btn.btn-light-google .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #ffffff;
}
.btn-check:checked + .btn.btn-light-google .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-light-google .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-light-google:focus:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-light-google:hover:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-light-google:active:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-light-google.active .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-light-google.show .svg-icon svg:hover [fill]:not(.permanent):not(g), .show > .btn.btn-light-google .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.btn-check:checked + .btn.btn-light-google.dropdown-toggle:after, .btn-check:active + .btn.btn-light-google.dropdown-toggle:after, .btn.btn-light-google:focus:not(.btn-active).dropdown-toggle:after, .btn.btn-light-google:hover:not(.btn-active).dropdown-toggle:after, .btn.btn-light-google:active:not(.btn-active).dropdown-toggle:after, .btn.btn-light-google.active.dropdown-toggle:after, .btn.btn-light-google.show.dropdown-toggle:after, .show > .btn.btn-light-google.dropdown-toggle:after {
  color: #ffffff;
}

.btn.btn-twitter {
  color: #ffffff;
  border-color: #1da1f2;
  background-color: #1da1f2;
}
.btn.btn-twitter i {
  color: #ffffff;
}
.btn.btn-twitter .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #ffffff;
}
.btn.btn-twitter .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.btn.btn-twitter.dropdown-toggle:after {
  color: #ffffff;
}
.btn-check:checked + .btn.btn-twitter, .btn-check:active + .btn.btn-twitter, .btn.btn-twitter:focus:not(.btn-active), .btn.btn-twitter:hover:not(.btn-active), .btn.btn-twitter:active:not(.btn-active), .btn.btn-twitter.active, .btn.btn-twitter.show, .show > .btn.btn-twitter {
  border-color: #0d8ddc;
  background-color: #0d8ddc;
}

.btn.btn-light-twitter {
  color: #1da1f2;
  border-color: rgba(29, 161, 242, 0.1);
  background-color: rgba(29, 161, 242, 0.1);
}
.btn.btn-light-twitter i {
  color: #1da1f2;
}
.btn.btn-light-twitter .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #1da1f2;
}
.btn.btn-light-twitter .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.btn.btn-light-twitter.dropdown-toggle:after {
  color: #1da1f2;
}
.btn-check:checked + .btn.btn-light-twitter, .btn-check:active + .btn.btn-light-twitter, .btn.btn-light-twitter:focus:not(.btn-active), .btn.btn-light-twitter:hover:not(.btn-active), .btn.btn-light-twitter:active:not(.btn-active), .btn.btn-light-twitter.active, .btn.btn-light-twitter.show, .show > .btn.btn-light-twitter {
  color: #ffffff;
  border-color: #1da1f2;
  background-color: #1da1f2;
}
.btn-check:checked + .btn.btn-light-twitter i, .btn-check:active + .btn.btn-light-twitter i, .btn.btn-light-twitter:focus:not(.btn-active) i, .btn.btn-light-twitter:hover:not(.btn-active) i, .btn.btn-light-twitter:active:not(.btn-active) i, .btn.btn-light-twitter.active i, .btn.btn-light-twitter.show i, .show > .btn.btn-light-twitter i {
  color: #ffffff;
}
.btn-check:checked + .btn.btn-light-twitter .svg-icon svg [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-light-twitter .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-light-twitter:focus:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-light-twitter:hover:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-light-twitter:active:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-light-twitter.active .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-light-twitter.show .svg-icon svg [fill]:not(.permanent):not(g), .show > .btn.btn-light-twitter .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #ffffff;
}
.btn-check:checked + .btn.btn-light-twitter .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-light-twitter .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-light-twitter:focus:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-light-twitter:hover:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-light-twitter:active:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-light-twitter.active .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-light-twitter.show .svg-icon svg:hover [fill]:not(.permanent):not(g), .show > .btn.btn-light-twitter .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.btn-check:checked + .btn.btn-light-twitter.dropdown-toggle:after, .btn-check:active + .btn.btn-light-twitter.dropdown-toggle:after, .btn.btn-light-twitter:focus:not(.btn-active).dropdown-toggle:after, .btn.btn-light-twitter:hover:not(.btn-active).dropdown-toggle:after, .btn.btn-light-twitter:active:not(.btn-active).dropdown-toggle:after, .btn.btn-light-twitter.active.dropdown-toggle:after, .btn.btn-light-twitter.show.dropdown-toggle:after, .show > .btn.btn-light-twitter.dropdown-toggle:after {
  color: #ffffff;
}

.btn.btn-instagram {
  color: #ffffff;
  border-color: #e1306c;
  background-color: #e1306c;
}
.btn.btn-instagram i {
  color: #ffffff;
}
.btn.btn-instagram .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #ffffff;
}
.btn.btn-instagram .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.btn.btn-instagram.dropdown-toggle:after {
  color: #ffffff;
}
.btn-check:checked + .btn.btn-instagram, .btn-check:active + .btn.btn-instagram, .btn.btn-instagram:focus:not(.btn-active), .btn.btn-instagram:hover:not(.btn-active), .btn.btn-instagram:active:not(.btn-active), .btn.btn-instagram.active, .btn.btn-instagram.show, .show > .btn.btn-instagram {
  border-color: #cd1e59;
  background-color: #cd1e59;
}

.btn.btn-light-instagram {
  color: #e1306c;
  border-color: rgba(225, 48, 108, 0.1);
  background-color: rgba(225, 48, 108, 0.1);
}
.btn.btn-light-instagram i {
  color: #e1306c;
}
.btn.btn-light-instagram .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #e1306c;
}
.btn.btn-light-instagram .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.btn.btn-light-instagram.dropdown-toggle:after {
  color: #e1306c;
}
.btn-check:checked + .btn.btn-light-instagram, .btn-check:active + .btn.btn-light-instagram, .btn.btn-light-instagram:focus:not(.btn-active), .btn.btn-light-instagram:hover:not(.btn-active), .btn.btn-light-instagram:active:not(.btn-active), .btn.btn-light-instagram.active, .btn.btn-light-instagram.show, .show > .btn.btn-light-instagram {
  color: #ffffff;
  border-color: #e1306c;
  background-color: #e1306c;
}
.btn-check:checked + .btn.btn-light-instagram i, .btn-check:active + .btn.btn-light-instagram i, .btn.btn-light-instagram:focus:not(.btn-active) i, .btn.btn-light-instagram:hover:not(.btn-active) i, .btn.btn-light-instagram:active:not(.btn-active) i, .btn.btn-light-instagram.active i, .btn.btn-light-instagram.show i, .show > .btn.btn-light-instagram i {
  color: #ffffff;
}
.btn-check:checked + .btn.btn-light-instagram .svg-icon svg [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-light-instagram .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-light-instagram:focus:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-light-instagram:hover:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-light-instagram:active:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-light-instagram.active .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-light-instagram.show .svg-icon svg [fill]:not(.permanent):not(g), .show > .btn.btn-light-instagram .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #ffffff;
}
.btn-check:checked + .btn.btn-light-instagram .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-light-instagram .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-light-instagram:focus:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-light-instagram:hover:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-light-instagram:active:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-light-instagram.active .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-light-instagram.show .svg-icon svg:hover [fill]:not(.permanent):not(g), .show > .btn.btn-light-instagram .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.btn-check:checked + .btn.btn-light-instagram.dropdown-toggle:after, .btn-check:active + .btn.btn-light-instagram.dropdown-toggle:after, .btn.btn-light-instagram:focus:not(.btn-active).dropdown-toggle:after, .btn.btn-light-instagram:hover:not(.btn-active).dropdown-toggle:after, .btn.btn-light-instagram:active:not(.btn-active).dropdown-toggle:after, .btn.btn-light-instagram.active.dropdown-toggle:after, .btn.btn-light-instagram.show.dropdown-toggle:after, .show > .btn.btn-light-instagram.dropdown-toggle:after {
  color: #ffffff;
}

.btn.btn-youtube {
  color: #ffffff;
  border-color: #ff0000;
  background-color: #ff0000;
}
.btn.btn-youtube i {
  color: #ffffff;
}
.btn.btn-youtube .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #ffffff;
}
.btn.btn-youtube .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.btn.btn-youtube.dropdown-toggle:after {
  color: #ffffff;
}
.btn-check:checked + .btn.btn-youtube, .btn-check:active + .btn.btn-youtube, .btn.btn-youtube:focus:not(.btn-active), .btn.btn-youtube:hover:not(.btn-active), .btn.btn-youtube:active:not(.btn-active), .btn.btn-youtube.active, .btn.btn-youtube.show, .show > .btn.btn-youtube {
  border-color: #d90000;
  background-color: #d90000;
}

.btn.btn-light-youtube {
  color: #ff0000;
  border-color: rgba(255, 0, 0, 0.1);
  background-color: rgba(255, 0, 0, 0.1);
}
.btn.btn-light-youtube i {
  color: #ff0000;
}
.btn.btn-light-youtube .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #ff0000;
}
.btn.btn-light-youtube .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.btn.btn-light-youtube.dropdown-toggle:after {
  color: #ff0000;
}
.btn-check:checked + .btn.btn-light-youtube, .btn-check:active + .btn.btn-light-youtube, .btn.btn-light-youtube:focus:not(.btn-active), .btn.btn-light-youtube:hover:not(.btn-active), .btn.btn-light-youtube:active:not(.btn-active), .btn.btn-light-youtube.active, .btn.btn-light-youtube.show, .show > .btn.btn-light-youtube {
  color: #ffffff;
  border-color: #ff0000;
  background-color: #ff0000;
}
.btn-check:checked + .btn.btn-light-youtube i, .btn-check:active + .btn.btn-light-youtube i, .btn.btn-light-youtube:focus:not(.btn-active) i, .btn.btn-light-youtube:hover:not(.btn-active) i, .btn.btn-light-youtube:active:not(.btn-active) i, .btn.btn-light-youtube.active i, .btn.btn-light-youtube.show i, .show > .btn.btn-light-youtube i {
  color: #ffffff;
}
.btn-check:checked + .btn.btn-light-youtube .svg-icon svg [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-light-youtube .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-light-youtube:focus:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-light-youtube:hover:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-light-youtube:active:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-light-youtube.active .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-light-youtube.show .svg-icon svg [fill]:not(.permanent):not(g), .show > .btn.btn-light-youtube .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #ffffff;
}
.btn-check:checked + .btn.btn-light-youtube .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-light-youtube .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-light-youtube:focus:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-light-youtube:hover:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-light-youtube:active:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-light-youtube.active .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-light-youtube.show .svg-icon svg:hover [fill]:not(.permanent):not(g), .show > .btn.btn-light-youtube .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.btn-check:checked + .btn.btn-light-youtube.dropdown-toggle:after, .btn-check:active + .btn.btn-light-youtube.dropdown-toggle:after, .btn.btn-light-youtube:focus:not(.btn-active).dropdown-toggle:after, .btn.btn-light-youtube:hover:not(.btn-active).dropdown-toggle:after, .btn.btn-light-youtube:active:not(.btn-active).dropdown-toggle:after, .btn.btn-light-youtube.active.dropdown-toggle:after, .btn.btn-light-youtube.show.dropdown-toggle:after, .show > .btn.btn-light-youtube.dropdown-toggle:after {
  color: #ffffff;
}

.btn.btn-linkedin {
  color: #ffffff;
  border-color: #0077b5;
  background-color: #0077b5;
}
.btn.btn-linkedin i {
  color: #ffffff;
}
.btn.btn-linkedin .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #ffffff;
}
.btn.btn-linkedin .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.btn.btn-linkedin.dropdown-toggle:after {
  color: #ffffff;
}
.btn-check:checked + .btn.btn-linkedin, .btn-check:active + .btn.btn-linkedin, .btn.btn-linkedin:focus:not(.btn-active), .btn.btn-linkedin:hover:not(.btn-active), .btn.btn-linkedin:active:not(.btn-active), .btn.btn-linkedin.active, .btn.btn-linkedin.show, .show > .btn.btn-linkedin {
  border-color: #005e8f;
  background-color: #005e8f;
}

.btn.btn-light-linkedin {
  color: #0077b5;
  border-color: rgba(0, 119, 181, 0.1);
  background-color: rgba(0, 119, 181, 0.1);
}
.btn.btn-light-linkedin i {
  color: #0077b5;
}
.btn.btn-light-linkedin .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #0077b5;
}
.btn.btn-light-linkedin .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.btn.btn-light-linkedin.dropdown-toggle:after {
  color: #0077b5;
}
.btn-check:checked + .btn.btn-light-linkedin, .btn-check:active + .btn.btn-light-linkedin, .btn.btn-light-linkedin:focus:not(.btn-active), .btn.btn-light-linkedin:hover:not(.btn-active), .btn.btn-light-linkedin:active:not(.btn-active), .btn.btn-light-linkedin.active, .btn.btn-light-linkedin.show, .show > .btn.btn-light-linkedin {
  color: #ffffff;
  border-color: #0077b5;
  background-color: #0077b5;
}
.btn-check:checked + .btn.btn-light-linkedin i, .btn-check:active + .btn.btn-light-linkedin i, .btn.btn-light-linkedin:focus:not(.btn-active) i, .btn.btn-light-linkedin:hover:not(.btn-active) i, .btn.btn-light-linkedin:active:not(.btn-active) i, .btn.btn-light-linkedin.active i, .btn.btn-light-linkedin.show i, .show > .btn.btn-light-linkedin i {
  color: #ffffff;
}
.btn-check:checked + .btn.btn-light-linkedin .svg-icon svg [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-light-linkedin .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-light-linkedin:focus:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-light-linkedin:hover:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-light-linkedin:active:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-light-linkedin.active .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-light-linkedin.show .svg-icon svg [fill]:not(.permanent):not(g), .show > .btn.btn-light-linkedin .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #ffffff;
}
.btn-check:checked + .btn.btn-light-linkedin .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-light-linkedin .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-light-linkedin:focus:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-light-linkedin:hover:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-light-linkedin:active:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-light-linkedin.active .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-light-linkedin.show .svg-icon svg:hover [fill]:not(.permanent):not(g), .show > .btn.btn-light-linkedin .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.btn-check:checked + .btn.btn-light-linkedin.dropdown-toggle:after, .btn-check:active + .btn.btn-light-linkedin.dropdown-toggle:after, .btn.btn-light-linkedin:focus:not(.btn-active).dropdown-toggle:after, .btn.btn-light-linkedin:hover:not(.btn-active).dropdown-toggle:after, .btn.btn-light-linkedin:active:not(.btn-active).dropdown-toggle:after, .btn.btn-light-linkedin.active.dropdown-toggle:after, .btn.btn-light-linkedin.show.dropdown-toggle:after, .show > .btn.btn-light-linkedin.dropdown-toggle:after {
  color: #ffffff;
}

.btn.btn-outline-dashed.btn-outline-default {
  border-width: 1px;
  border-style: dashed;
  color: #7E8299;
  border-color: #E4E6EF;
  background-color: rgba(245, 248, 250, 0.6);
}
.btn.btn-outline-dashed.btn-outline-default i {
  color: #7E8299;
}
.btn.btn-outline-dashed.btn-outline-default .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #7E8299;
}
.btn.btn-outline-dashed.btn-outline-default .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.btn.btn-outline-dashed.btn-outline-default.dropdown-toggle:after {
  color: #7E8299;
}
.btn-check:checked + .btn.btn-outline-dashed.btn-outline-default, .btn-check:active + .btn.btn-outline-dashed.btn-outline-default, .btn.btn-outline-dashed.btn-outline-default:focus:not(.btn-active), .btn.btn-outline-dashed.btn-outline-default:hover:not(.btn-active), .btn.btn-outline-dashed.btn-outline-default:active:not(.btn-active), .btn.btn-outline-dashed.btn-outline-default.active, .btn.btn-outline-dashed.btn-outline-default.show, .show > .btn.btn-outline-dashed.btn-outline-default {
  color: #009EF7;
  border-color: #009EF7;
  background-color: #ECF8FF;
}
.btn-check:checked + .btn.btn-outline-dashed.btn-outline-default i, .btn-check:active + .btn.btn-outline-dashed.btn-outline-default i, .btn.btn-outline-dashed.btn-outline-default:focus:not(.btn-active) i, .btn.btn-outline-dashed.btn-outline-default:hover:not(.btn-active) i, .btn.btn-outline-dashed.btn-outline-default:active:not(.btn-active) i, .btn.btn-outline-dashed.btn-outline-default.active i, .btn.btn-outline-dashed.btn-outline-default.show i, .show > .btn.btn-outline-dashed.btn-outline-default i {
  color: #009EF7;
}
.btn-check:checked + .btn.btn-outline-dashed.btn-outline-default .svg-icon svg [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-outline-dashed.btn-outline-default .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-outline-dashed.btn-outline-default:focus:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-outline-dashed.btn-outline-default:hover:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-outline-dashed.btn-outline-default:active:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-outline-dashed.btn-outline-default.active .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-outline-dashed.btn-outline-default.show .svg-icon svg [fill]:not(.permanent):not(g), .show > .btn.btn-outline-dashed.btn-outline-default .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #009EF7;
}
.btn-check:checked + .btn.btn-outline-dashed.btn-outline-default .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-outline-dashed.btn-outline-default .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-outline-dashed.btn-outline-default:focus:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-outline-dashed.btn-outline-default:hover:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-outline-dashed.btn-outline-default:active:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-outline-dashed.btn-outline-default.active .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-outline-dashed.btn-outline-default.show .svg-icon svg:hover [fill]:not(.permanent):not(g), .show > .btn.btn-outline-dashed.btn-outline-default .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.btn-check:checked + .btn.btn-outline-dashed.btn-outline-default.dropdown-toggle:after, .btn-check:active + .btn.btn-outline-dashed.btn-outline-default.dropdown-toggle:after, .btn.btn-outline-dashed.btn-outline-default:focus:not(.btn-active).dropdown-toggle:after, .btn.btn-outline-dashed.btn-outline-default:hover:not(.btn-active).dropdown-toggle:after, .btn.btn-outline-dashed.btn-outline-default:active:not(.btn-active).dropdown-toggle:after, .btn.btn-outline-dashed.btn-outline-default.active.dropdown-toggle:after, .btn.btn-outline-dashed.btn-outline-default.show.dropdown-toggle:after, .show > .btn.btn-outline-dashed.btn-outline-default.dropdown-toggle:after {
  color: #009EF7;
}

code:not([class*=language-]) {
  font-weight: 400;
  color: #b93993;
  line-height: inherit;
  font-size: inherit;
  background-color: #F5F8FA;
  padding: 1px 0.4rem;
  margin: 0 0.5rem;
  box-shadow: 0px 3px 9px rgba(0, 0, 0, 0.08);
  border-radius: 0.3rem;
}

.form-control {
  box-shadow: none !important;
}
.dropdown.show > .form-control {
  color: #181C32;
  background-color: #ffffff;
  border-color: #B5B5C3;
}
.form-control[readonly] {
  background-color: #ffffff;
}
.form-control.form-control-solid {
  background-color: #F5F8FA;
  border-color: #F5F8FA;
  color: #5E6278;
  transition: color 0.2s ease, background-color 0.2s ease;
}
.form-control.form-control-solid::placeholder {
  color: #A1A5B7;
}
.form-control.form-control-solid::-moz-placeholder {
  color: #A1A5B7;
  opacity: 1;
}
.dropdown.show > .form-control.form-control-solid, .form-control.form-control-solid:active, .form-control.form-control-solid.active, .form-control.form-control-solid:focus, .form-control.form-control-solid.focus {
  background-color: #eef3f7;
  border-color: #eef3f7;
  color: #5E6278;
  transition: color 0.2s ease, background-color 0.2s ease;
}
.form-control.form-control-white {
  background-color: #ffffff;
  border-color: #ffffff;
}
.dropdown.show > .form-control.form-control-white, .form-control.form-control-white:active, .form-control.form-control-white.active, .form-control.form-control-white:focus, .form-control.form-control-white.focus {
  background-color: #ffffff;
  border-color: #ffffff;
}
.form-control.form-control-flush {
  border: 0;
  background-color: transparent;
  outline: none !important;
  box-shadow: none;
  border-radius: 0;
}

.placeholder-gray-500::placeholder {
  color: #A1A5B7;
}
.placeholder-gray-500::-moz-placeholder {
  color: #A1A5B7;
  opacity: 1;
}

.placeholder-white::placeholder {
  color: #ffffff;
}
.placeholder-white::-moz-placeholder {
  color: #ffffff;
  opacity: 1;
}

.resize-none {
  resize: none;
}

.form-select {
  box-shadow: none !important;
}
.form-select.form-select-solid {
  background-color: #F5F8FA;
  border-color: #F5F8FA;
  color: #5E6278;
  transition: color 0.2s ease, background-color 0.2s ease;
}
.form-select.form-select-solid::placeholder {
  color: #A1A5B7;
}
.form-select.form-select-solid::-moz-placeholder {
  color: #A1A5B7;
  opacity: 1;
}
.dropdown.show > .form-select.form-select-solid, .form-select.form-select-solid:active, .form-select.form-select-solid.active, .form-select.form-select-solid:focus, .form-select.form-select-solid.focus {
  background-color: #eef3f7;
  border-color: #eef3f7 !important;
  color: #5E6278;
  transition: color 0.2s ease, background-color 0.2s ease;
}
.form-select.form-select-white {
  background-color: #ffffff;
  border-color: #ffffff;
  color: #181C32;
}
.form-select.form-select-white::placeholder {
  color: #A1A5B7;
}
.form-select.form-select-white::-moz-placeholder {
  color: #A1A5B7;
  opacity: 1;
}
.dropdown.show > .form-select.form-select-white, .form-select.form-select-white:active, .form-select.form-select-white.active, .form-select.form-select-white:focus, .form-select.form-select-white.focus {
  background-color: #ffffff;
  border-color: #ffffff !important;
  color: #181C32;
}

.form-check .form-check-label {
  cursor: pointer;
}
.form-check .form-check-input {
  cursor: pointer;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
.form-check.form-check-custom {
  display: flex;
  align-items: center;
  padding-left: 0;
  margin: 0;
}
.form-check.form-check-custom .form-check-input {
  margin: 0;
  float: none;
  flex-shrink: 0;
}
.form-check.form-check-custom .form-check-label {
  margin-left: 0.55rem;
}
.form-check:not(.form-switch) .form-check-input[type=checkbox] {
  background-size: 60% 60%;
}
.form-check.form-check-solid .form-check-input {
  border: 0;
  background-color: #EFF2F5;
}
.form-check.form-check-solid .form-check-input:active, .form-check.form-check-solid .form-check-input:focus {
  filter: none;
  background-color: #EFF2F5;
}
.form-check.form-check-solid .form-check-input:checked {
  background-color: #009EF7;
}
.form-check.form-check-solid .form-check-input[type=radio], .form-check.form-check-solid .form-check-input[type=radio]:active, .form-check.form-check-solid .form-check-input[type=radio]:focus {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23FFFFFF'/%3e%3c/svg%3e");
}
.form-check.form-check-success .form-check-input:checked {
  background-color: #50CD89;
}
.form-check.form-check-danger .form-check-input:checked {
  background-color: #F1416C;
}
.form-check.form-check-warning .form-check-input:checked {
  background-color: #FFC700;
}

.form-check.form-check-custom.form-check-sm .form-check-input {
  height: 1.5rem;
  width: 1.5rem;
}
.form-check.form-check-custom.form-check-lg .form-check-input {
  height: 2.25rem;
  width: 2.25rem;
}

.form-switch.form-check-solid .form-check-input {
  height: 2.25rem;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23ffffff'/%3e%3c/svg%3e");
}
.form-switch.form-check-solid .form-check-input:active, .form-switch.form-check-solid .form-check-input:checked, .form-switch.form-check-solid .form-check-input:focus {
  filter: none;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23ffffff'/%3e%3c/svg%3e");
}
.form-switch.form-check-solid.form-switch-sm .form-check-input {
  height: 1.5rem;
  width: 2.5rem;
}
.form-switch.form-check-solid.form-switch-lg .form-check-input {
  height: 2.75rem;
  width: 3.75rem;
}

.input-group.input-group-solid {
  background-color: #F5F8FA;
  border-color: #F5F8FA;
  border-radius: 0.475rem;
}
.input-group.input-group-solid.input-group-sm {
  border-radius: 0.475rem;
}
.input-group.input-group-solid.input-group-lg {
  border-radius: 0.475rem;
}
.input-group.input-group-solid .input-group-text {
  background-color: transparent;
  border-color: transparent;
}
.input-group.input-group-solid .form-control {
  background-color: transparent;
  border-color: transparent;
}

.required:after {
  content: "*";
  position: relative;
  font-size: inherit;
  color: #F1416C;
  padding-left: 0.25rem;
  font-weight: bold;
}

.modal .modal-header {
  align-items: center;
  justify-content: space-between;
}
.modal .modal-header h1, .modal .modal-header .h1, .modal .modal-header h2, .modal .modal-header .h2, .modal .modal-header h3, .modal .modal-header .h3, .modal .modal-header h4, .modal .modal-header .h4, .modal .modal-header h5, .modal .modal-header .h5, .modal .modal-header h6, .modal .modal-header .h6 {
  margin-bottom: 0;
}
.modal .modal-dialog {
  outline: none !important;
}

.modal.modal-sticky.modal-sticky-bottom-right {
  padding: 0 !important;
  max-width: 500px;
  height: auto;
  position: fixed;
  left: auto;
  top: auto;
  bottom: 25px;
  right: 25px;
  margin: 0;
  box-shadow: 0px 0px 60px -15px rgba(0, 0, 0, 0.2);
  border-radius: 0.475rem;
}
.modal.modal-sticky.modal-sticky-bottom-right .modal-dialog {
  position: static;
  max-width: 500px;
  width: auto;
  margin: 0;
  border-radius: 0.475rem;
}
.modal.modal-sticky.modal-sticky-bottom-right .modal-dialog .modal-content {
  border: 0;
  border-radius: 0.475rem;
}
.modal.modal-sticky.modal-sticky-bottom-right.modal-sticky-lg {
  max-width: 600px;
}
.modal.modal-sticky.modal-sticky-bottom-right.modal-sticky-lg .modal-dialog {
  max-width: 600px;
}
@media (max-width: 767.98px) {
  .modal.modal-sticky.modal-sticky-bottom-right {
    bottom: 10px;
    right: 10px;
    left: 10px;
    width: auto;
  }
}

.progress-vertical {
  display: flex;
  align-items: stretch;
  justify-content: space-between;
}
.progress-vertical .progress {
  height: 100%;
  border-radius: 0.475rem;
  display: flex;
  align-items: flex-end;
  margin-right: 1rem;
}
.progress-vertical .progress:last-child {
  margin-right: 0;
}
.progress-vertical .progress .progress-bar {
  width: 8px;
  border-radius: 0.475rem;
}

.table > :not(:last-child) > :last-child > * {
  border-bottom-color: inherit;
}
.table tr, .table th, .table td {
  border-color: inherit;
  border-width: inherit;
  border-style: inherit;
  text-transform: inherit;
  font-weight: inherit;
  font-size: inherit;
  color: inherit;
  height: inherit;
  min-height: inherit;
}
.table tr:first-child, .table th:first-child, .table td:first-child {
  padding-left: 0;
}
.table tr:last-child, .table th:last-child, .table td:last-child {
  padding-right: 0;
}
.table tfoot tr:last-child,
.table tbody tr:last-child {
  border-bottom: 0 !important;
}
.table tfoot tr:last-child th, .table tfoot tr:last-child td,
.table tbody tr:last-child th,
.table tbody tr:last-child td {
  border-bottom: 0 !important;
}
.table tfoot th, .table tfoot td {
  border-top: inherit;
}
.table.table-rounded {
  border-radius: 0.475rem;
  border-spacing: 0;
  border-collapse: separate;
}
.table.table-flush tr, .table.table-flush th, .table.table-flush td {
  padding: inherit;
}
.table.table-row-bordered tr {
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: #EFF2F5;
}
.table.table-row-bordered tfoot th, .table.table-row-bordered tfoot td {
  border-top-width: 1px !important;
}
.table.table-row-dashed tr {
  border-bottom-width: 1px;
  border-bottom-style: dashed;
  border-bottom-color: #EFF2F5;
}
.table.table-row-dashed tfoot th, .table.table-row-dashed tfoot td {
  border-top-width: 1px !important;
}
.table.table-row-gray-100 tr {
  border-bottom-color: #F5F8FA;
}
.table.table-row-gray-200 tr {
  border-bottom-color: #EFF2F5;
}
.table.table-row-gray-300 tr {
  border-bottom-color: #E4E6EF;
}
.table.table-row-gray-400 tr {
  border-bottom-color: #B5B5C3;
}
.table.table-row-gray-500 tr {
  border-bottom-color: #A1A5B7;
}
.table.table-row-gray-600 tr {
  border-bottom-color: #7E8299;
}
.table.table-row-gray-700 tr {
  border-bottom-color: #5E6278;
}
.table.table-row-gray-800 tr {
  border-bottom-color: #3F4254;
}
.table.table-row-gray-900 tr {
  border-bottom-color: #181C32;
}

.table.g-0 th, .table.g-0 td {
  padding: 0;
}

.table.gy-0 th, .table.gy-0 td {
  padding-top: 0;
  padding-bottom: 0;
}

.table.gx-0 th, .table.gx-0 td {
  padding-left: 0;
  padding-right: 0;
}

.table.gs-0 th:first-child, .table.gs-0 td:first-child {
  padding-left: 0;
}
.table.gs-0 th:last-child, .table.gs-0 td:last-child {
  padding-right: 0;
}

.table.g-1 th, .table.g-1 td {
  padding: 0.25rem;
}

.table.gy-1 th, .table.gy-1 td {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}

.table.gx-1 th, .table.gx-1 td {
  padding-left: 0.25rem;
  padding-right: 0.25rem;
}

.table.gs-1 th:first-child, .table.gs-1 td:first-child {
  padding-left: 0.25rem;
}
.table.gs-1 th:last-child, .table.gs-1 td:last-child {
  padding-right: 0.25rem;
}

.table.g-2 th, .table.g-2 td {
  padding: 0.5rem;
}

.table.gy-2 th, .table.gy-2 td {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.table.gx-2 th, .table.gx-2 td {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.table.gs-2 th:first-child, .table.gs-2 td:first-child {
  padding-left: 0.5rem;
}
.table.gs-2 th:last-child, .table.gs-2 td:last-child {
  padding-right: 0.5rem;
}

.table.g-3 th, .table.g-3 td {
  padding: 0.75rem;
}

.table.gy-3 th, .table.gy-3 td {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}

.table.gx-3 th, .table.gx-3 td {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}

.table.gs-3 th:first-child, .table.gs-3 td:first-child {
  padding-left: 0.75rem;
}
.table.gs-3 th:last-child, .table.gs-3 td:last-child {
  padding-right: 0.75rem;
}

.table.g-4 th, .table.g-4 td {
  padding: 1rem;
}

.table.gy-4 th, .table.gy-4 td {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.table.gx-4 th, .table.gx-4 td {
  padding-left: 1rem;
  padding-right: 1rem;
}

.table.gs-4 th:first-child, .table.gs-4 td:first-child {
  padding-left: 1rem;
}
.table.gs-4 th:last-child, .table.gs-4 td:last-child {
  padding-right: 1rem;
}

.table.g-5 th, .table.g-5 td {
  padding: 1.25rem;
}

.table.gy-5 th, .table.gy-5 td {
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
}

.table.gx-5 th, .table.gx-5 td {
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}

.table.gs-5 th:first-child, .table.gs-5 td:first-child {
  padding-left: 1.25rem;
}
.table.gs-5 th:last-child, .table.gs-5 td:last-child {
  padding-right: 1.25rem;
}

.table.g-6 th, .table.g-6 td {
  padding: 1.5rem;
}

.table.gy-6 th, .table.gy-6 td {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

.table.gx-6 th, .table.gx-6 td {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.table.gs-6 th:first-child, .table.gs-6 td:first-child {
  padding-left: 1.5rem;
}
.table.gs-6 th:last-child, .table.gs-6 td:last-child {
  padding-right: 1.5rem;
}

.table.g-7 th, .table.g-7 td {
  padding: 1.75rem;
}

.table.gy-7 th, .table.gy-7 td {
  padding-top: 1.75rem;
  padding-bottom: 1.75rem;
}

.table.gx-7 th, .table.gx-7 td {
  padding-left: 1.75rem;
  padding-right: 1.75rem;
}

.table.gs-7 th:first-child, .table.gs-7 td:first-child {
  padding-left: 1.75rem;
}
.table.gs-7 th:last-child, .table.gs-7 td:last-child {
  padding-right: 1.75rem;
}

.table.g-8 th, .table.g-8 td {
  padding: 2rem;
}

.table.gy-8 th, .table.gy-8 td {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.table.gx-8 th, .table.gx-8 td {
  padding-left: 2rem;
  padding-right: 2rem;
}

.table.gs-8 th:first-child, .table.gs-8 td:first-child {
  padding-left: 2rem;
}
.table.gs-8 th:last-child, .table.gs-8 td:last-child {
  padding-right: 2rem;
}

.table.g-9 th, .table.g-9 td {
  padding: 2.25rem;
}

.table.gy-9 th, .table.gy-9 td {
  padding-top: 2.25rem;
  padding-bottom: 2.25rem;
}

.table.gx-9 th, .table.gx-9 td {
  padding-left: 2.25rem;
  padding-right: 2.25rem;
}

.table.gs-9 th:first-child, .table.gs-9 td:first-child {
  padding-left: 2.25rem;
}
.table.gs-9 th:last-child, .table.gs-9 td:last-child {
  padding-right: 2.25rem;
}

.table.g-10 th, .table.g-10 td {
  padding: 2.5rem;
}

.table.gy-10 th, .table.gy-10 td {
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
}

.table.gx-10 th, .table.gx-10 td {
  padding-left: 2.5rem;
  padding-right: 2.5rem;
}

.table.gs-10 th:first-child, .table.gs-10 td:first-child {
  padding-left: 2.5rem;
}
.table.gs-10 th:last-child, .table.gs-10 td:last-child {
  padding-right: 2.5rem;
}

@media (min-width: 576px) {
  .table.g-sm-0 th, .table.g-sm-0 td {
    padding: 0;
  }

  .table.gy-sm-0 th, .table.gy-sm-0 td {
    padding-top: 0;
    padding-bottom: 0;
  }

  .table.gx-sm-0 th, .table.gx-sm-0 td {
    padding-left: 0;
    padding-right: 0;
  }

  .table.gs-sm-0 th:first-child, .table.gs-sm-0 td:first-child {
    padding-left: 0;
  }
  .table.gs-sm-0 th:last-child, .table.gs-sm-0 td:last-child {
    padding-right: 0;
  }

  .table.g-sm-1 th, .table.g-sm-1 td {
    padding: 0.25rem;
  }

  .table.gy-sm-1 th, .table.gy-sm-1 td {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
  }

  .table.gx-sm-1 th, .table.gx-sm-1 td {
    padding-left: 0.25rem;
    padding-right: 0.25rem;
  }

  .table.gs-sm-1 th:first-child, .table.gs-sm-1 td:first-child {
    padding-left: 0.25rem;
  }
  .table.gs-sm-1 th:last-child, .table.gs-sm-1 td:last-child {
    padding-right: 0.25rem;
  }

  .table.g-sm-2 th, .table.g-sm-2 td {
    padding: 0.5rem;
  }

  .table.gy-sm-2 th, .table.gy-sm-2 td {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }

  .table.gx-sm-2 th, .table.gx-sm-2 td {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }

  .table.gs-sm-2 th:first-child, .table.gs-sm-2 td:first-child {
    padding-left: 0.5rem;
  }
  .table.gs-sm-2 th:last-child, .table.gs-sm-2 td:last-child {
    padding-right: 0.5rem;
  }

  .table.g-sm-3 th, .table.g-sm-3 td {
    padding: 0.75rem;
  }

  .table.gy-sm-3 th, .table.gy-sm-3 td {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
  }

  .table.gx-sm-3 th, .table.gx-sm-3 td {
    padding-left: 0.75rem;
    padding-right: 0.75rem;
  }

  .table.gs-sm-3 th:first-child, .table.gs-sm-3 td:first-child {
    padding-left: 0.75rem;
  }
  .table.gs-sm-3 th:last-child, .table.gs-sm-3 td:last-child {
    padding-right: 0.75rem;
  }

  .table.g-sm-4 th, .table.g-sm-4 td {
    padding: 1rem;
  }

  .table.gy-sm-4 th, .table.gy-sm-4 td {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

  .table.gx-sm-4 th, .table.gx-sm-4 td {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .table.gs-sm-4 th:first-child, .table.gs-sm-4 td:first-child {
    padding-left: 1rem;
  }
  .table.gs-sm-4 th:last-child, .table.gs-sm-4 td:last-child {
    padding-right: 1rem;
  }

  .table.g-sm-5 th, .table.g-sm-5 td {
    padding: 1.25rem;
  }

  .table.gy-sm-5 th, .table.gy-sm-5 td {
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
  }

  .table.gx-sm-5 th, .table.gx-sm-5 td {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }

  .table.gs-sm-5 th:first-child, .table.gs-sm-5 td:first-child {
    padding-left: 1.25rem;
  }
  .table.gs-sm-5 th:last-child, .table.gs-sm-5 td:last-child {
    padding-right: 1.25rem;
  }

  .table.g-sm-6 th, .table.g-sm-6 td {
    padding: 1.5rem;
  }

  .table.gy-sm-6 th, .table.gy-sm-6 td {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }

  .table.gx-sm-6 th, .table.gx-sm-6 td {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }

  .table.gs-sm-6 th:first-child, .table.gs-sm-6 td:first-child {
    padding-left: 1.5rem;
  }
  .table.gs-sm-6 th:last-child, .table.gs-sm-6 td:last-child {
    padding-right: 1.5rem;
  }

  .table.g-sm-7 th, .table.g-sm-7 td {
    padding: 1.75rem;
  }

  .table.gy-sm-7 th, .table.gy-sm-7 td {
    padding-top: 1.75rem;
    padding-bottom: 1.75rem;
  }

  .table.gx-sm-7 th, .table.gx-sm-7 td {
    padding-left: 1.75rem;
    padding-right: 1.75rem;
  }

  .table.gs-sm-7 th:first-child, .table.gs-sm-7 td:first-child {
    padding-left: 1.75rem;
  }
  .table.gs-sm-7 th:last-child, .table.gs-sm-7 td:last-child {
    padding-right: 1.75rem;
  }

  .table.g-sm-8 th, .table.g-sm-8 td {
    padding: 2rem;
  }

  .table.gy-sm-8 th, .table.gy-sm-8 td {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }

  .table.gx-sm-8 th, .table.gx-sm-8 td {
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .table.gs-sm-8 th:first-child, .table.gs-sm-8 td:first-child {
    padding-left: 2rem;
  }
  .table.gs-sm-8 th:last-child, .table.gs-sm-8 td:last-child {
    padding-right: 2rem;
  }

  .table.g-sm-9 th, .table.g-sm-9 td {
    padding: 2.25rem;
  }

  .table.gy-sm-9 th, .table.gy-sm-9 td {
    padding-top: 2.25rem;
    padding-bottom: 2.25rem;
  }

  .table.gx-sm-9 th, .table.gx-sm-9 td {
    padding-left: 2.25rem;
    padding-right: 2.25rem;
  }

  .table.gs-sm-9 th:first-child, .table.gs-sm-9 td:first-child {
    padding-left: 2.25rem;
  }
  .table.gs-sm-9 th:last-child, .table.gs-sm-9 td:last-child {
    padding-right: 2.25rem;
  }

  .table.g-sm-10 th, .table.g-sm-10 td {
    padding: 2.5rem;
  }

  .table.gy-sm-10 th, .table.gy-sm-10 td {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
  }

  .table.gx-sm-10 th, .table.gx-sm-10 td {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }

  .table.gs-sm-10 th:first-child, .table.gs-sm-10 td:first-child {
    padding-left: 2.5rem;
  }
  .table.gs-sm-10 th:last-child, .table.gs-sm-10 td:last-child {
    padding-right: 2.5rem;
  }
}
@media (min-width: 768px) {
  .table.g-md-0 th, .table.g-md-0 td {
    padding: 0;
  }

  .table.gy-md-0 th, .table.gy-md-0 td {
    padding-top: 0;
    padding-bottom: 0;
  }

  .table.gx-md-0 th, .table.gx-md-0 td {
    padding-left: 0;
    padding-right: 0;
  }

  .table.gs-md-0 th:first-child, .table.gs-md-0 td:first-child {
    padding-left: 0;
  }
  .table.gs-md-0 th:last-child, .table.gs-md-0 td:last-child {
    padding-right: 0;
  }

  .table.g-md-1 th, .table.g-md-1 td {
    padding: 0.25rem;
  }

  .table.gy-md-1 th, .table.gy-md-1 td {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
  }

  .table.gx-md-1 th, .table.gx-md-1 td {
    padding-left: 0.25rem;
    padding-right: 0.25rem;
  }

  .table.gs-md-1 th:first-child, .table.gs-md-1 td:first-child {
    padding-left: 0.25rem;
  }
  .table.gs-md-1 th:last-child, .table.gs-md-1 td:last-child {
    padding-right: 0.25rem;
  }

  .table.g-md-2 th, .table.g-md-2 td {
    padding: 0.5rem;
  }

  .table.gy-md-2 th, .table.gy-md-2 td {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }

  .table.gx-md-2 th, .table.gx-md-2 td {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }

  .table.gs-md-2 th:first-child, .table.gs-md-2 td:first-child {
    padding-left: 0.5rem;
  }
  .table.gs-md-2 th:last-child, .table.gs-md-2 td:last-child {
    padding-right: 0.5rem;
  }

  .table.g-md-3 th, .table.g-md-3 td {
    padding: 0.75rem;
  }

  .table.gy-md-3 th, .table.gy-md-3 td {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
  }

  .table.gx-md-3 th, .table.gx-md-3 td {
    padding-left: 0.75rem;
    padding-right: 0.75rem;
  }

  .table.gs-md-3 th:first-child, .table.gs-md-3 td:first-child {
    padding-left: 0.75rem;
  }
  .table.gs-md-3 th:last-child, .table.gs-md-3 td:last-child {
    padding-right: 0.75rem;
  }

  .table.g-md-4 th, .table.g-md-4 td {
    padding: 1rem;
  }

  .table.gy-md-4 th, .table.gy-md-4 td {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

  .table.gx-md-4 th, .table.gx-md-4 td {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .table.gs-md-4 th:first-child, .table.gs-md-4 td:first-child {
    padding-left: 1rem;
  }
  .table.gs-md-4 th:last-child, .table.gs-md-4 td:last-child {
    padding-right: 1rem;
  }

  .table.g-md-5 th, .table.g-md-5 td {
    padding: 1.25rem;
  }

  .table.gy-md-5 th, .table.gy-md-5 td {
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
  }

  .table.gx-md-5 th, .table.gx-md-5 td {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }

  .table.gs-md-5 th:first-child, .table.gs-md-5 td:first-child {
    padding-left: 1.25rem;
  }
  .table.gs-md-5 th:last-child, .table.gs-md-5 td:last-child {
    padding-right: 1.25rem;
  }

  .table.g-md-6 th, .table.g-md-6 td {
    padding: 1.5rem;
  }

  .table.gy-md-6 th, .table.gy-md-6 td {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }

  .table.gx-md-6 th, .table.gx-md-6 td {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }

  .table.gs-md-6 th:first-child, .table.gs-md-6 td:first-child {
    padding-left: 1.5rem;
  }
  .table.gs-md-6 th:last-child, .table.gs-md-6 td:last-child {
    padding-right: 1.5rem;
  }

  .table.g-md-7 th, .table.g-md-7 td {
    padding: 1.75rem;
  }

  .table.gy-md-7 th, .table.gy-md-7 td {
    padding-top: 1.75rem;
    padding-bottom: 1.75rem;
  }

  .table.gx-md-7 th, .table.gx-md-7 td {
    padding-left: 1.75rem;
    padding-right: 1.75rem;
  }

  .table.gs-md-7 th:first-child, .table.gs-md-7 td:first-child {
    padding-left: 1.75rem;
  }
  .table.gs-md-7 th:last-child, .table.gs-md-7 td:last-child {
    padding-right: 1.75rem;
  }

  .table.g-md-8 th, .table.g-md-8 td {
    padding: 2rem;
  }

  .table.gy-md-8 th, .table.gy-md-8 td {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }

  .table.gx-md-8 th, .table.gx-md-8 td {
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .table.gs-md-8 th:first-child, .table.gs-md-8 td:first-child {
    padding-left: 2rem;
  }
  .table.gs-md-8 th:last-child, .table.gs-md-8 td:last-child {
    padding-right: 2rem;
  }

  .table.g-md-9 th, .table.g-md-9 td {
    padding: 2.25rem;
  }

  .table.gy-md-9 th, .table.gy-md-9 td {
    padding-top: 2.25rem;
    padding-bottom: 2.25rem;
  }

  .table.gx-md-9 th, .table.gx-md-9 td {
    padding-left: 2.25rem;
    padding-right: 2.25rem;
  }

  .table.gs-md-9 th:first-child, .table.gs-md-9 td:first-child {
    padding-left: 2.25rem;
  }
  .table.gs-md-9 th:last-child, .table.gs-md-9 td:last-child {
    padding-right: 2.25rem;
  }

  .table.g-md-10 th, .table.g-md-10 td {
    padding: 2.5rem;
  }

  .table.gy-md-10 th, .table.gy-md-10 td {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
  }

  .table.gx-md-10 th, .table.gx-md-10 td {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }

  .table.gs-md-10 th:first-child, .table.gs-md-10 td:first-child {
    padding-left: 2.5rem;
  }
  .table.gs-md-10 th:last-child, .table.gs-md-10 td:last-child {
    padding-right: 2.5rem;
  }
}
@media (min-width: 992px) {
  .table.g-lg-0 th, .table.g-lg-0 td {
    padding: 0;
  }

  .table.gy-lg-0 th, .table.gy-lg-0 td {
    padding-top: 0;
    padding-bottom: 0;
  }

  .table.gx-lg-0 th, .table.gx-lg-0 td {
    padding-left: 0;
    padding-right: 0;
  }

  .table.gs-lg-0 th:first-child, .table.gs-lg-0 td:first-child {
    padding-left: 0;
  }
  .table.gs-lg-0 th:last-child, .table.gs-lg-0 td:last-child {
    padding-right: 0;
  }

  .table.g-lg-1 th, .table.g-lg-1 td {
    padding: 0.25rem;
  }

  .table.gy-lg-1 th, .table.gy-lg-1 td {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
  }

  .table.gx-lg-1 th, .table.gx-lg-1 td {
    padding-left: 0.25rem;
    padding-right: 0.25rem;
  }

  .table.gs-lg-1 th:first-child, .table.gs-lg-1 td:first-child {
    padding-left: 0.25rem;
  }
  .table.gs-lg-1 th:last-child, .table.gs-lg-1 td:last-child {
    padding-right: 0.25rem;
  }

  .table.g-lg-2 th, .table.g-lg-2 td {
    padding: 0.5rem;
  }

  .table.gy-lg-2 th, .table.gy-lg-2 td {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }

  .table.gx-lg-2 th, .table.gx-lg-2 td {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }

  .table.gs-lg-2 th:first-child, .table.gs-lg-2 td:first-child {
    padding-left: 0.5rem;
  }
  .table.gs-lg-2 th:last-child, .table.gs-lg-2 td:last-child {
    padding-right: 0.5rem;
  }

  .table.g-lg-3 th, .table.g-lg-3 td {
    padding: 0.75rem;
  }

  .table.gy-lg-3 th, .table.gy-lg-3 td {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
  }

  .table.gx-lg-3 th, .table.gx-lg-3 td {
    padding-left: 0.75rem;
    padding-right: 0.75rem;
  }

  .table.gs-lg-3 th:first-child, .table.gs-lg-3 td:first-child {
    padding-left: 0.75rem;
  }
  .table.gs-lg-3 th:last-child, .table.gs-lg-3 td:last-child {
    padding-right: 0.75rem;
  }

  .table.g-lg-4 th, .table.g-lg-4 td {
    padding: 1rem;
  }

  .table.gy-lg-4 th, .table.gy-lg-4 td {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

  .table.gx-lg-4 th, .table.gx-lg-4 td {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .table.gs-lg-4 th:first-child, .table.gs-lg-4 td:first-child {
    padding-left: 1rem;
  }
  .table.gs-lg-4 th:last-child, .table.gs-lg-4 td:last-child {
    padding-right: 1rem;
  }

  .table.g-lg-5 th, .table.g-lg-5 td {
    padding: 1.25rem;
  }

  .table.gy-lg-5 th, .table.gy-lg-5 td {
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
  }

  .table.gx-lg-5 th, .table.gx-lg-5 td {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }

  .table.gs-lg-5 th:first-child, .table.gs-lg-5 td:first-child {
    padding-left: 1.25rem;
  }
  .table.gs-lg-5 th:last-child, .table.gs-lg-5 td:last-child {
    padding-right: 1.25rem;
  }

  .table.g-lg-6 th, .table.g-lg-6 td {
    padding: 1.5rem;
  }

  .table.gy-lg-6 th, .table.gy-lg-6 td {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }

  .table.gx-lg-6 th, .table.gx-lg-6 td {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }

  .table.gs-lg-6 th:first-child, .table.gs-lg-6 td:first-child {
    padding-left: 1.5rem;
  }
  .table.gs-lg-6 th:last-child, .table.gs-lg-6 td:last-child {
    padding-right: 1.5rem;
  }

  .table.g-lg-7 th, .table.g-lg-7 td {
    padding: 1.75rem;
  }

  .table.gy-lg-7 th, .table.gy-lg-7 td {
    padding-top: 1.75rem;
    padding-bottom: 1.75rem;
  }

  .table.gx-lg-7 th, .table.gx-lg-7 td {
    padding-left: 1.75rem;
    padding-right: 1.75rem;
  }

  .table.gs-lg-7 th:first-child, .table.gs-lg-7 td:first-child {
    padding-left: 1.75rem;
  }
  .table.gs-lg-7 th:last-child, .table.gs-lg-7 td:last-child {
    padding-right: 1.75rem;
  }

  .table.g-lg-8 th, .table.g-lg-8 td {
    padding: 2rem;
  }

  .table.gy-lg-8 th, .table.gy-lg-8 td {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }

  .table.gx-lg-8 th, .table.gx-lg-8 td {
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .table.gs-lg-8 th:first-child, .table.gs-lg-8 td:first-child {
    padding-left: 2rem;
  }
  .table.gs-lg-8 th:last-child, .table.gs-lg-8 td:last-child {
    padding-right: 2rem;
  }

  .table.g-lg-9 th, .table.g-lg-9 td {
    padding: 2.25rem;
  }

  .table.gy-lg-9 th, .table.gy-lg-9 td {
    padding-top: 2.25rem;
    padding-bottom: 2.25rem;
  }

  .table.gx-lg-9 th, .table.gx-lg-9 td {
    padding-left: 2.25rem;
    padding-right: 2.25rem;
  }

  .table.gs-lg-9 th:first-child, .table.gs-lg-9 td:first-child {
    padding-left: 2.25rem;
  }
  .table.gs-lg-9 th:last-child, .table.gs-lg-9 td:last-child {
    padding-right: 2.25rem;
  }

  .table.g-lg-10 th, .table.g-lg-10 td {
    padding: 2.5rem;
  }

  .table.gy-lg-10 th, .table.gy-lg-10 td {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
  }

  .table.gx-lg-10 th, .table.gx-lg-10 td {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }

  .table.gs-lg-10 th:first-child, .table.gs-lg-10 td:first-child {
    padding-left: 2.5rem;
  }
  .table.gs-lg-10 th:last-child, .table.gs-lg-10 td:last-child {
    padding-right: 2.5rem;
  }
}
@media (min-width: 1200px) {
  .table.g-xl-0 th, .table.g-xl-0 td {
    padding: 0;
  }

  .table.gy-xl-0 th, .table.gy-xl-0 td {
    padding-top: 0;
    padding-bottom: 0;
  }

  .table.gx-xl-0 th, .table.gx-xl-0 td {
    padding-left: 0;
    padding-right: 0;
  }

  .table.gs-xl-0 th:first-child, .table.gs-xl-0 td:first-child {
    padding-left: 0;
  }
  .table.gs-xl-0 th:last-child, .table.gs-xl-0 td:last-child {
    padding-right: 0;
  }

  .table.g-xl-1 th, .table.g-xl-1 td {
    padding: 0.25rem;
  }

  .table.gy-xl-1 th, .table.gy-xl-1 td {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
  }

  .table.gx-xl-1 th, .table.gx-xl-1 td {
    padding-left: 0.25rem;
    padding-right: 0.25rem;
  }

  .table.gs-xl-1 th:first-child, .table.gs-xl-1 td:first-child {
    padding-left: 0.25rem;
  }
  .table.gs-xl-1 th:last-child, .table.gs-xl-1 td:last-child {
    padding-right: 0.25rem;
  }

  .table.g-xl-2 th, .table.g-xl-2 td {
    padding: 0.5rem;
  }

  .table.gy-xl-2 th, .table.gy-xl-2 td {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }

  .table.gx-xl-2 th, .table.gx-xl-2 td {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }

  .table.gs-xl-2 th:first-child, .table.gs-xl-2 td:first-child {
    padding-left: 0.5rem;
  }
  .table.gs-xl-2 th:last-child, .table.gs-xl-2 td:last-child {
    padding-right: 0.5rem;
  }

  .table.g-xl-3 th, .table.g-xl-3 td {
    padding: 0.75rem;
  }

  .table.gy-xl-3 th, .table.gy-xl-3 td {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
  }

  .table.gx-xl-3 th, .table.gx-xl-3 td {
    padding-left: 0.75rem;
    padding-right: 0.75rem;
  }

  .table.gs-xl-3 th:first-child, .table.gs-xl-3 td:first-child {
    padding-left: 0.75rem;
  }
  .table.gs-xl-3 th:last-child, .table.gs-xl-3 td:last-child {
    padding-right: 0.75rem;
  }

  .table.g-xl-4 th, .table.g-xl-4 td {
    padding: 1rem;
  }

  .table.gy-xl-4 th, .table.gy-xl-4 td {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

  .table.gx-xl-4 th, .table.gx-xl-4 td {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .table.gs-xl-4 th:first-child, .table.gs-xl-4 td:first-child {
    padding-left: 1rem;
  }
  .table.gs-xl-4 th:last-child, .table.gs-xl-4 td:last-child {
    padding-right: 1rem;
  }

  .table.g-xl-5 th, .table.g-xl-5 td {
    padding: 1.25rem;
  }

  .table.gy-xl-5 th, .table.gy-xl-5 td {
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
  }

  .table.gx-xl-5 th, .table.gx-xl-5 td {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }

  .table.gs-xl-5 th:first-child, .table.gs-xl-5 td:first-child {
    padding-left: 1.25rem;
  }
  .table.gs-xl-5 th:last-child, .table.gs-xl-5 td:last-child {
    padding-right: 1.25rem;
  }

  .table.g-xl-6 th, .table.g-xl-6 td {
    padding: 1.5rem;
  }

  .table.gy-xl-6 th, .table.gy-xl-6 td {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }

  .table.gx-xl-6 th, .table.gx-xl-6 td {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }

  .table.gs-xl-6 th:first-child, .table.gs-xl-6 td:first-child {
    padding-left: 1.5rem;
  }
  .table.gs-xl-6 th:last-child, .table.gs-xl-6 td:last-child {
    padding-right: 1.5rem;
  }

  .table.g-xl-7 th, .table.g-xl-7 td {
    padding: 1.75rem;
  }

  .table.gy-xl-7 th, .table.gy-xl-7 td {
    padding-top: 1.75rem;
    padding-bottom: 1.75rem;
  }

  .table.gx-xl-7 th, .table.gx-xl-7 td {
    padding-left: 1.75rem;
    padding-right: 1.75rem;
  }

  .table.gs-xl-7 th:first-child, .table.gs-xl-7 td:first-child {
    padding-left: 1.75rem;
  }
  .table.gs-xl-7 th:last-child, .table.gs-xl-7 td:last-child {
    padding-right: 1.75rem;
  }

  .table.g-xl-8 th, .table.g-xl-8 td {
    padding: 2rem;
  }

  .table.gy-xl-8 th, .table.gy-xl-8 td {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }

  .table.gx-xl-8 th, .table.gx-xl-8 td {
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .table.gs-xl-8 th:first-child, .table.gs-xl-8 td:first-child {
    padding-left: 2rem;
  }
  .table.gs-xl-8 th:last-child, .table.gs-xl-8 td:last-child {
    padding-right: 2rem;
  }

  .table.g-xl-9 th, .table.g-xl-9 td {
    padding: 2.25rem;
  }

  .table.gy-xl-9 th, .table.gy-xl-9 td {
    padding-top: 2.25rem;
    padding-bottom: 2.25rem;
  }

  .table.gx-xl-9 th, .table.gx-xl-9 td {
    padding-left: 2.25rem;
    padding-right: 2.25rem;
  }

  .table.gs-xl-9 th:first-child, .table.gs-xl-9 td:first-child {
    padding-left: 2.25rem;
  }
  .table.gs-xl-9 th:last-child, .table.gs-xl-9 td:last-child {
    padding-right: 2.25rem;
  }

  .table.g-xl-10 th, .table.g-xl-10 td {
    padding: 2.5rem;
  }

  .table.gy-xl-10 th, .table.gy-xl-10 td {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
  }

  .table.gx-xl-10 th, .table.gx-xl-10 td {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }

  .table.gs-xl-10 th:first-child, .table.gs-xl-10 td:first-child {
    padding-left: 2.5rem;
  }
  .table.gs-xl-10 th:last-child, .table.gs-xl-10 td:last-child {
    padding-right: 2.5rem;
  }
}
@media (min-width: 1400px) {
  .table.g-xxl-0 th, .table.g-xxl-0 td {
    padding: 0;
  }

  .table.gy-xxl-0 th, .table.gy-xxl-0 td {
    padding-top: 0;
    padding-bottom: 0;
  }

  .table.gx-xxl-0 th, .table.gx-xxl-0 td {
    padding-left: 0;
    padding-right: 0;
  }

  .table.gs-xxl-0 th:first-child, .table.gs-xxl-0 td:first-child {
    padding-left: 0;
  }
  .table.gs-xxl-0 th:last-child, .table.gs-xxl-0 td:last-child {
    padding-right: 0;
  }

  .table.g-xxl-1 th, .table.g-xxl-1 td {
    padding: 0.25rem;
  }

  .table.gy-xxl-1 th, .table.gy-xxl-1 td {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
  }

  .table.gx-xxl-1 th, .table.gx-xxl-1 td {
    padding-left: 0.25rem;
    padding-right: 0.25rem;
  }

  .table.gs-xxl-1 th:first-child, .table.gs-xxl-1 td:first-child {
    padding-left: 0.25rem;
  }
  .table.gs-xxl-1 th:last-child, .table.gs-xxl-1 td:last-child {
    padding-right: 0.25rem;
  }

  .table.g-xxl-2 th, .table.g-xxl-2 td {
    padding: 0.5rem;
  }

  .table.gy-xxl-2 th, .table.gy-xxl-2 td {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }

  .table.gx-xxl-2 th, .table.gx-xxl-2 td {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }

  .table.gs-xxl-2 th:first-child, .table.gs-xxl-2 td:first-child {
    padding-left: 0.5rem;
  }
  .table.gs-xxl-2 th:last-child, .table.gs-xxl-2 td:last-child {
    padding-right: 0.5rem;
  }

  .table.g-xxl-3 th, .table.g-xxl-3 td {
    padding: 0.75rem;
  }

  .table.gy-xxl-3 th, .table.gy-xxl-3 td {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
  }

  .table.gx-xxl-3 th, .table.gx-xxl-3 td {
    padding-left: 0.75rem;
    padding-right: 0.75rem;
  }

  .table.gs-xxl-3 th:first-child, .table.gs-xxl-3 td:first-child {
    padding-left: 0.75rem;
  }
  .table.gs-xxl-3 th:last-child, .table.gs-xxl-3 td:last-child {
    padding-right: 0.75rem;
  }

  .table.g-xxl-4 th, .table.g-xxl-4 td {
    padding: 1rem;
  }

  .table.gy-xxl-4 th, .table.gy-xxl-4 td {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

  .table.gx-xxl-4 th, .table.gx-xxl-4 td {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .table.gs-xxl-4 th:first-child, .table.gs-xxl-4 td:first-child {
    padding-left: 1rem;
  }
  .table.gs-xxl-4 th:last-child, .table.gs-xxl-4 td:last-child {
    padding-right: 1rem;
  }

  .table.g-xxl-5 th, .table.g-xxl-5 td {
    padding: 1.25rem;
  }

  .table.gy-xxl-5 th, .table.gy-xxl-5 td {
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
  }

  .table.gx-xxl-5 th, .table.gx-xxl-5 td {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }

  .table.gs-xxl-5 th:first-child, .table.gs-xxl-5 td:first-child {
    padding-left: 1.25rem;
  }
  .table.gs-xxl-5 th:last-child, .table.gs-xxl-5 td:last-child {
    padding-right: 1.25rem;
  }

  .table.g-xxl-6 th, .table.g-xxl-6 td {
    padding: 1.5rem;
  }

  .table.gy-xxl-6 th, .table.gy-xxl-6 td {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }

  .table.gx-xxl-6 th, .table.gx-xxl-6 td {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }

  .table.gs-xxl-6 th:first-child, .table.gs-xxl-6 td:first-child {
    padding-left: 1.5rem;
  }
  .table.gs-xxl-6 th:last-child, .table.gs-xxl-6 td:last-child {
    padding-right: 1.5rem;
  }

  .table.g-xxl-7 th, .table.g-xxl-7 td {
    padding: 1.75rem;
  }

  .table.gy-xxl-7 th, .table.gy-xxl-7 td {
    padding-top: 1.75rem;
    padding-bottom: 1.75rem;
  }

  .table.gx-xxl-7 th, .table.gx-xxl-7 td {
    padding-left: 1.75rem;
    padding-right: 1.75rem;
  }

  .table.gs-xxl-7 th:first-child, .table.gs-xxl-7 td:first-child {
    padding-left: 1.75rem;
  }
  .table.gs-xxl-7 th:last-child, .table.gs-xxl-7 td:last-child {
    padding-right: 1.75rem;
  }

  .table.g-xxl-8 th, .table.g-xxl-8 td {
    padding: 2rem;
  }

  .table.gy-xxl-8 th, .table.gy-xxl-8 td {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }

  .table.gx-xxl-8 th, .table.gx-xxl-8 td {
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .table.gs-xxl-8 th:first-child, .table.gs-xxl-8 td:first-child {
    padding-left: 2rem;
  }
  .table.gs-xxl-8 th:last-child, .table.gs-xxl-8 td:last-child {
    padding-right: 2rem;
  }

  .table.g-xxl-9 th, .table.g-xxl-9 td {
    padding: 2.25rem;
  }

  .table.gy-xxl-9 th, .table.gy-xxl-9 td {
    padding-top: 2.25rem;
    padding-bottom: 2.25rem;
  }

  .table.gx-xxl-9 th, .table.gx-xxl-9 td {
    padding-left: 2.25rem;
    padding-right: 2.25rem;
  }

  .table.gs-xxl-9 th:first-child, .table.gs-xxl-9 td:first-child {
    padding-left: 2.25rem;
  }
  .table.gs-xxl-9 th:last-child, .table.gs-xxl-9 td:last-child {
    padding-right: 2.25rem;
  }

  .table.g-xxl-10 th, .table.g-xxl-10 td {
    padding: 2.5rem;
  }

  .table.gy-xxl-10 th, .table.gy-xxl-10 td {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
  }

  .table.gx-xxl-10 th, .table.gx-xxl-10 td {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }

  .table.gs-xxl-10 th:first-child, .table.gs-xxl-10 td:first-child {
    padding-left: 2.5rem;
  }
  .table.gs-xxl-10 th:last-child, .table.gs-xxl-10 td:last-child {
    padding-right: 2.5rem;
  }
}
.popover {
  /*rtl:raw:
  left: 0 !important;
  right: auto !important;
  */
}
.popover .popover-header {
  font-size: 1rem;
  font-weight: 500;
  border-bottom: 1px solid #F5F8FA;
}
.popover.bs-popover-right {
  margin-right: auto/*rtl:ignore*/;
  margin-left: 0.5rem /*rtl:ignore*/;
}
.popover.bs-popover-right > .arrow {
  right: auto/*rtl:ignore*/;
  left: calc(-0.5rem - 1px) /*rtl:ignore*/;
}
.popover.bs-popover-right > .arrow::before {
  right: auto/*rtl:ignore*/;
  left: 0 /*rtl:ignore*/;
  border-width: 0.5rem 0.5rem 0.5rem 0 /*rtl:ignore*/;
  border-right-color: white /*rtl:ignore*/;
}
.popover.bs-popover-right > .arrow::after {
  right: auto/*rtl:ignore*/;
  left: 1px /*rtl:ignore*/;
  border-width: 0.5rem 0.5rem 0.5rem 0 /*rtl:ignore*/;
  border-right-color: #ffffff /*rtl:ignore*/;
}
.popover.bs-popover-left {
  margin-left: auto/*rtl:ignore*/;
  margin-right: 0.5rem /*rtl:ignore*/;
}
.popover.bs-popover-left > .arrow {
  left: auto/*rtl:ignore*/;
  right: calc(-0.5rem - 1px) /*rtl:ignore*/;
}
.popover.bs-popover-left > .arrow::before {
  left: auto/*rtl:ignore*/;
  right: 0 /*rtl:ignore*/;
  border-width: 0.5rem 0 0.5rem 0.5rem /*rtl:ignore*/;
  border-left-color: white /*rtl:ignore*/;
}
.popover.bs-popover-left > .arrow::after {
  left: auto/*rtl:ignore*/;
  right: 1px /*rtl:ignore*/;
  border-width: 0.5rem 0 0.5rem 0.5rem /*rtl:ignore*/;
  border-left-color: #ffffff /*rtl:ignore*/;
}

.popover-dark {
  background-color: #181C32;
  border: 0;
}
.popover-dark .popover-header {
  background-color: #181C32;
  color: #EFF2F5;
  border-bottom-color: #3F4254;
}
.popover-dark .popover-body {
  color: #B5B5C3;
}
.popover-dark.bs-popover-top > .popover-arrow::before, .popover-dark.bs-popover-auto[data-popper-placement^=top] > .popover-arrow::before {
  border-top-color: #F5F8FA;
}
.popover-dark.bs-popover-top > .popover-arrow::after, .popover-dark.bs-popover-auto[data-popper-placement^=top] > .popover-arrow::after {
  border-top-color: #181C32;
}
.popover-dark.bs-popover-end > .popover-arrow::before, .popover-dark.bs-popover-auto[data-popper-placement^=right] > .popover-arrow::before {
  border-right-color: #F5F8FA;
}
.popover-dark.bs-popover-end > .popover-arrow::after, .popover-dark.bs-popover-auto[data-popper-placement^=right] > .popover-arrow::after {
  border-right-color: #181C32;
}
.popover-dark.bs-popover-bottom > .popover-arrow::before, .popover-dark.bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::before {
  border-bottom-color: #F5F8FA;
}
.popover-dark.bs-popover-bottom > .popover-arrow::after, .popover-dark.bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::after {
  border-bottom-color: #181C32;
}
.popover-dark.bs-popover-bottom .popover-header::before, .popover-dark.bs-popover-auto[data-popper-placement^=bottom] .popover-header::before {
  border-bottom-color: #181C32;
}
.popover-dark.bs-popover-start > .popover-arrow::before, .popover-dark.bs-popover-auto[data-popper-placement^=left] > .popover-arrow::before {
  border-left-color: #F5F8FA;
}
.popover-dark.bs-popover-start > .popover-arrow::after, .popover-dark.bs-popover-auto[data-popper-placement^=left] > .popover-arrow::after {
  border-left-color: #181C32;
}

.tooltip .tooltip-inner {
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.15);
}
.tooltip.tooltop-auto-width .tooltip-inner {
  white-space: nowrap;
  max-width: none;
}
.tooltip.tooltip-dark .tooltip-inner {
  color: #ffffff;
  background-color: #181C32;
}
.tooltip.tooltip-dark.bs-tooltip-top .tooltip-arrow:before, .tooltip.tooltip-dark.bs-tooltip-auto[data-popper-placement^=top] .tooltip-arrow:before {
  border-top-color: #181C32;
}
.tooltip.tooltip-dark.bs-tooltip-right .tooltip-arrow:before {
  border-right-color: #181C32;
}
.tooltip.tooltip-dark.bs-tooltip-bottom .tooltip-arrow:before, .tooltip.tooltip-dark.bs-tooltip-auto[data-popper-placement^=bottom] .tooltip-arrow:before {
  border-bottom-color: #181C32;
}
.tooltip.tooltip-dark.bs-tooltip-left .tooltip-arrow:before {
  border-left-color: #181C32;
}

.accordion .accordion-header {
  cursor: pointer;
}
.accordion.accordion-icon-toggle .accordion-icon {
  display: flex;
  flex-shrink: 0;
  transition: all 0.2s ease;
  transform: rotate(90deg);
  align-items: center;
  justify-content: center;
}
.accordion.accordion-icon-toggle .accordion-icon i {
  color: #009EF7;
}
.accordion.accordion-icon-toggle .accordion-icon .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #009EF7;
}
.accordion.accordion-icon-toggle .accordion-icon .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.accordion.accordion-icon-toggle .collapsed .accordion-icon {
  transition: all 0.2s ease;
  transform: rotate(0);
}
.accordion.accordion-icon-toggle .collapsed .accordion-icon i {
  color: #A1A5B7;
}
.accordion.accordion-icon-toggle .collapsed .accordion-icon .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #A1A5B7;
}
.accordion.accordion-icon-toggle .collapsed .accordion-icon .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.accordion.accordion-borderless .accordion-item {
  border: 0;
}
.accordion.accordion-flush .accordion-item {
  background-color: transparent;
  border: 0;
  border-radius: none;
  padding-left: 0;
  padding-right: 0;
}

.feedback {
  display: none;
}

.feedback-popup {
  display: flex;
  justify-content: center;
  margin: 0 auto;
  position: fixed;
  z-index: 1000;
  box-shadow: 0px 0px 50px 0px rgba(82, 63, 105, 0.15);
  background-color: #ffffff;
  border-radius: 0.475rem;
  padding: 1rem 1.25rem;
}

.feedback-top-center {
  display: flex;
  transition: top 0.6s ease;
  left: 50%;
  transform: translateX(-50%);
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.feedback-top-center.feedback-shown {
  top: 0px;
  transition: top 0.6s ease;
}

.image-input {
  position: relative;
  display: inline-block;
  border-radius: 0.475rem;
  background-repeat: no-repeat;
  background-size: cover;
}
.image-input:not(.image-input-empty) {
  background-image: none !important;
}
.image-input .image-input-wrapper {
  width: 120px;
  height: 120px;
  border-radius: 0.475rem;
  background-repeat: no-repeat;
  background-size: cover;
}
.image-input [data-kt-image-input-action=change] {
  cursor: pointer;
  position: absolute;
  right: -10px;
  top: -10px;
}
.image-input [data-kt-image-input-action=change] input {
  width: 0 !important;
  height: 0 !important;
  overflow: hidden;
  opacity: 0;
}
.image-input [data-kt-image-input-action=cancel],
.image-input [data-kt-image-input-action=remove] {
  position: absolute;
  right: -10px;
  bottom: -5px;
}
.image-input [data-kt-image-input-action=cancel] {
  display: none;
}
.image-input.image-input-changed [data-kt-image-input-action=cancel] {
  display: flex;
}
.image-input.image-input-changed [data-kt-image-input-action=remove] {
  display: none;
}
.image-input.image-input-empty [data-kt-image-input-action=remove],
.image-input.image-input-empty [data-kt-image-input-action=cancel] {
  display: none;
}
.image-input.image-input-circle {
  border-radius: 50%;
}
.image-input.image-input-circle .image-input-wrapper {
  border-radius: 50%;
}
.image-input.image-input-circle [data-action=change] {
  right: 5px;
  top: 5px;
}
.image-input.image-input-circle [data-action=cancel],
.image-input.image-input-circle [data-action=remove] {
  right: 5px;
  bottom: 5px;
}
.image-input.image-input-outline .image-input-wrapper {
  border: 3px solid #ffffff;
  box-shadow: 0 0.5rem 1.5rem 0.5rem rgba(0, 0, 0, 0.075);
}

.symbol {
  display: inline-block;
  flex-shrink: 0;
  position: relative;
  border-radius: 0.475rem;
}
.symbol .symbol-label {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  color: #3F4254;
  background-color: #F5F8FA;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  border-radius: 0.475rem;
}
.symbol .badge {
  position: absolute;
  border: 2px solid #ffffff;
  top: 0;
  left: 100%;
  transform: translateX(-50%) translateY(-50%) !important;
}
.symbol > img {
  width: 100%;
  flex-shrink: 0;
  display: inline-block;
  border-radius: 0.475rem;
}
.symbol.symbol-square,
.symbol.symbol-square > img,
.symbol.symbol-square .symbol-label {
  border-radius: 0 !important;
}
.symbol.symbol-circle,
.symbol.symbol-circle > img,
.symbol.symbol-circle .symbol-label {
  border-radius: 50%;
}
.symbol > img {
  width: 50px;
  height: 50px;
}
.symbol .symbol-label {
  width: 50px;
  height: 50px;
}
.symbol.symbol-fixed .symbol-label {
  width: 50px;
  height: 50px;
}
.symbol.symbol-fixed > img {
  width: 50px;
  height: 50px;
  max-width: auto;
}
.symbol.symbol-2by3 .symbol-label {
  height: 50px;
  width: 75px;
}
.symbol.symbol-2by3 > img {
  height: 50px;
  width: 75px;
  max-width: auto;
}
.symbol.symbol-20px > img {
  width: 20px;
  height: 20px;
}
.symbol.symbol-20px .symbol-label {
  width: 20px;
  height: 20px;
}
.symbol.symbol-20px.symbol-fixed .symbol-label {
  width: 20px;
  height: 20px;
}
.symbol.symbol-20px.symbol-fixed > img {
  width: 20px;
  height: 20px;
  max-width: auto;
}
.symbol.symbol-20px.symbol-2by3 .symbol-label {
  height: 20px;
  width: 30px;
}
.symbol.symbol-20px.symbol-2by3 > img {
  height: 20px;
  width: 30px;
  max-width: auto;
}
.symbol.symbol-25px > img {
  width: 25px;
  height: 25px;
}
.symbol.symbol-25px .symbol-label {
  width: 25px;
  height: 25px;
}
.symbol.symbol-25px.symbol-fixed .symbol-label {
  width: 25px;
  height: 25px;
}
.symbol.symbol-25px.symbol-fixed > img {
  width: 25px;
  height: 25px;
  max-width: auto;
}
.symbol.symbol-25px.symbol-2by3 .symbol-label {
  height: 25px;
  width: 37.5px;
}
.symbol.symbol-25px.symbol-2by3 > img {
  height: 25px;
  width: 37.5px;
  max-width: auto;
}
.symbol.symbol-30px > img {
  width: 30px;
  height: 30px;
}
.symbol.symbol-30px .symbol-label {
  width: 30px;
  height: 30px;
}
.symbol.symbol-30px.symbol-fixed .symbol-label {
  width: 30px;
  height: 30px;
}
.symbol.symbol-30px.symbol-fixed > img {
  width: 30px;
  height: 30px;
  max-width: auto;
}
.symbol.symbol-30px.symbol-2by3 .symbol-label {
  height: 30px;
  width: 45px;
}
.symbol.symbol-30px.symbol-2by3 > img {
  height: 30px;
  width: 45px;
  max-width: auto;
}
.symbol.symbol-35px > img {
  width: 35px;
  height: 35px;
}
.symbol.symbol-35px .symbol-label {
  width: 35px;
  height: 35px;
}
.symbol.symbol-35px.symbol-fixed .symbol-label {
  width: 35px;
  height: 35px;
}
.symbol.symbol-35px.symbol-fixed > img {
  width: 35px;
  height: 35px;
  max-width: auto;
}
.symbol.symbol-35px.symbol-2by3 .symbol-label {
  height: 35px;
  width: 52.5px;
}
.symbol.symbol-35px.symbol-2by3 > img {
  height: 35px;
  width: 52.5px;
  max-width: auto;
}
.symbol.symbol-40px > img {
  width: 40px;
  height: 40px;
}
.symbol.symbol-40px .symbol-label {
  width: 40px;
  height: 40px;
}
.symbol.symbol-40px.symbol-fixed .symbol-label {
  width: 40px;
  height: 40px;
}
.symbol.symbol-40px.symbol-fixed > img {
  width: 40px;
  height: 40px;
  max-width: auto;
}
.symbol.symbol-40px.symbol-2by3 .symbol-label {
  height: 40px;
  width: 60px;
}
.symbol.symbol-40px.symbol-2by3 > img {
  height: 40px;
  width: 60px;
  max-width: auto;
}
.symbol.symbol-45px > img {
  width: 45px;
  height: 45px;
}
.symbol.symbol-45px .symbol-label {
  width: 45px;
  height: 45px;
}
.symbol.symbol-45px.symbol-fixed .symbol-label {
  width: 45px;
  height: 45px;
}
.symbol.symbol-45px.symbol-fixed > img {
  width: 45px;
  height: 45px;
  max-width: auto;
}
.symbol.symbol-45px.symbol-2by3 .symbol-label {
  height: 45px;
  width: 67.5px;
}
.symbol.symbol-45px.symbol-2by3 > img {
  height: 45px;
  width: 67.5px;
  max-width: auto;
}
.symbol.symbol-50px > img {
  width: 50px;
  height: 50px;
}
.symbol.symbol-50px .symbol-label {
  width: 50px;
  height: 50px;
}
.symbol.symbol-50px.symbol-fixed .symbol-label {
  width: 50px;
  height: 50px;
}
.symbol.symbol-50px.symbol-fixed > img {
  width: 50px;
  height: 50px;
  max-width: auto;
}
.symbol.symbol-50px.symbol-2by3 .symbol-label {
  height: 50px;
  width: 75px;
}
.symbol.symbol-50px.symbol-2by3 > img {
  height: 50px;
  width: 75px;
  max-width: auto;
}
.symbol.symbol-55px > img {
  width: 55px;
  height: 55px;
}
.symbol.symbol-55px .symbol-label {
  width: 55px;
  height: 55px;
}
.symbol.symbol-55px.symbol-fixed .symbol-label {
  width: 55px;
  height: 55px;
}
.symbol.symbol-55px.symbol-fixed > img {
  width: 55px;
  height: 55px;
  max-width: auto;
}
.symbol.symbol-55px.symbol-2by3 .symbol-label {
  height: 55px;
  width: 82.5px;
}
.symbol.symbol-55px.symbol-2by3 > img {
  height: 55px;
  width: 82.5px;
  max-width: auto;
}
.symbol.symbol-60px > img {
  width: 60px;
  height: 60px;
}
.symbol.symbol-60px .symbol-label {
  width: 60px;
  height: 60px;
}
.symbol.symbol-60px.symbol-fixed .symbol-label {
  width: 60px;
  height: 60px;
}
.symbol.symbol-60px.symbol-fixed > img {
  width: 60px;
  height: 60px;
  max-width: auto;
}
.symbol.symbol-60px.symbol-2by3 .symbol-label {
  height: 60px;
  width: 90px;
}
.symbol.symbol-60px.symbol-2by3 > img {
  height: 60px;
  width: 90px;
  max-width: auto;
}
.symbol.symbol-65px > img {
  width: 65px;
  height: 65px;
}
.symbol.symbol-65px .symbol-label {
  width: 65px;
  height: 65px;
}
.symbol.symbol-65px.symbol-fixed .symbol-label {
  width: 65px;
  height: 65px;
}
.symbol.symbol-65px.symbol-fixed > img {
  width: 65px;
  height: 65px;
  max-width: auto;
}
.symbol.symbol-65px.symbol-2by3 .symbol-label {
  height: 65px;
  width: 97.5px;
}
.symbol.symbol-65px.symbol-2by3 > img {
  height: 65px;
  width: 97.5px;
  max-width: auto;
}
.symbol.symbol-70px > img {
  width: 70px;
  height: 70px;
}
.symbol.symbol-70px .symbol-label {
  width: 70px;
  height: 70px;
}
.symbol.symbol-70px.symbol-fixed .symbol-label {
  width: 70px;
  height: 70px;
}
.symbol.symbol-70px.symbol-fixed > img {
  width: 70px;
  height: 70px;
  max-width: auto;
}
.symbol.symbol-70px.symbol-2by3 .symbol-label {
  height: 70px;
  width: 105px;
}
.symbol.symbol-70px.symbol-2by3 > img {
  height: 70px;
  width: 105px;
  max-width: auto;
}
.symbol.symbol-75px > img {
  width: 75px;
  height: 75px;
}
.symbol.symbol-75px .symbol-label {
  width: 75px;
  height: 75px;
}
.symbol.symbol-75px.symbol-fixed .symbol-label {
  width: 75px;
  height: 75px;
}
.symbol.symbol-75px.symbol-fixed > img {
  width: 75px;
  height: 75px;
  max-width: auto;
}
.symbol.symbol-75px.symbol-2by3 .symbol-label {
  height: 75px;
  width: 112.5px;
}
.symbol.symbol-75px.symbol-2by3 > img {
  height: 75px;
  width: 112.5px;
  max-width: auto;
}
.symbol.symbol-100px > img {
  width: 100px;
  height: 100px;
}
.symbol.symbol-100px .symbol-label {
  width: 100px;
  height: 100px;
}
.symbol.symbol-100px.symbol-fixed .symbol-label {
  width: 100px;
  height: 100px;
}
.symbol.symbol-100px.symbol-fixed > img {
  width: 100px;
  height: 100px;
  max-width: auto;
}
.symbol.symbol-100px.symbol-2by3 .symbol-label {
  height: 100px;
  width: 150px;
}
.symbol.symbol-100px.symbol-2by3 > img {
  height: 100px;
  width: 150px;
  max-width: auto;
}
.symbol.symbol-125px > img {
  width: 125px;
  height: 125px;
}
.symbol.symbol-125px .symbol-label {
  width: 125px;
  height: 125px;
}
.symbol.symbol-125px.symbol-fixed .symbol-label {
  width: 125px;
  height: 125px;
}
.symbol.symbol-125px.symbol-fixed > img {
  width: 125px;
  height: 125px;
  max-width: auto;
}
.symbol.symbol-125px.symbol-2by3 .symbol-label {
  height: 125px;
  width: 187.5px;
}
.symbol.symbol-125px.symbol-2by3 > img {
  height: 125px;
  width: 187.5px;
  max-width: auto;
}
.symbol.symbol-150px > img {
  width: 150px;
  height: 150px;
}
.symbol.symbol-150px .symbol-label {
  width: 150px;
  height: 150px;
}
.symbol.symbol-150px.symbol-fixed .symbol-label {
  width: 150px;
  height: 150px;
}
.symbol.symbol-150px.symbol-fixed > img {
  width: 150px;
  height: 150px;
  max-width: auto;
}
.symbol.symbol-150px.symbol-2by3 .symbol-label {
  height: 150px;
  width: 225px;
}
.symbol.symbol-150px.symbol-2by3 > img {
  height: 150px;
  width: 225px;
  max-width: auto;
}
.symbol.symbol-160px > img {
  width: 160px;
  height: 160px;
}
.symbol.symbol-160px .symbol-label {
  width: 160px;
  height: 160px;
}
.symbol.symbol-160px.symbol-fixed .symbol-label {
  width: 160px;
  height: 160px;
}
.symbol.symbol-160px.symbol-fixed > img {
  width: 160px;
  height: 160px;
  max-width: auto;
}
.symbol.symbol-160px.symbol-2by3 .symbol-label {
  height: 160px;
  width: 240px;
}
.symbol.symbol-160px.symbol-2by3 > img {
  height: 160px;
  width: 240px;
  max-width: auto;
}
.symbol.symbol-175px > img {
  width: 175px;
  height: 175px;
}
.symbol.symbol-175px .symbol-label {
  width: 175px;
  height: 175px;
}
.symbol.symbol-175px.symbol-fixed .symbol-label {
  width: 175px;
  height: 175px;
}
.symbol.symbol-175px.symbol-fixed > img {
  width: 175px;
  height: 175px;
  max-width: auto;
}
.symbol.symbol-175px.symbol-2by3 .symbol-label {
  height: 175px;
  width: 262.5px;
}
.symbol.symbol-175px.symbol-2by3 > img {
  height: 175px;
  width: 262.5px;
  max-width: auto;
}
.symbol.symbol-200px > img {
  width: 200px;
  height: 200px;
}
.symbol.symbol-200px .symbol-label {
  width: 200px;
  height: 200px;
}
.symbol.symbol-200px.symbol-fixed .symbol-label {
  width: 200px;
  height: 200px;
}
.symbol.symbol-200px.symbol-fixed > img {
  width: 200px;
  height: 200px;
  max-width: auto;
}
.symbol.symbol-200px.symbol-2by3 .symbol-label {
  height: 200px;
  width: 300px;
}
.symbol.symbol-200px.symbol-2by3 > img {
  height: 200px;
  width: 300px;
  max-width: auto;
}
@media (min-width: 576px) {
  .symbol.symbol-sm-20px > img {
    width: 20px;
    height: 20px;
  }
  .symbol.symbol-sm-20px .symbol-label {
    width: 20px;
    height: 20px;
  }
  .symbol.symbol-sm-20px.symbol-fixed .symbol-label {
    width: 20px;
    height: 20px;
  }
  .symbol.symbol-sm-20px.symbol-fixed > img {
    width: 20px;
    height: 20px;
    max-width: auto;
  }
  .symbol.symbol-sm-20px.symbol-2by3 .symbol-label {
    height: 20px;
    width: 30px;
  }
  .symbol.symbol-sm-20px.symbol-2by3 > img {
    height: 20px;
    width: 30px;
    max-width: auto;
  }
  .symbol.symbol-sm-25px > img {
    width: 25px;
    height: 25px;
  }
  .symbol.symbol-sm-25px .symbol-label {
    width: 25px;
    height: 25px;
  }
  .symbol.symbol-sm-25px.symbol-fixed .symbol-label {
    width: 25px;
    height: 25px;
  }
  .symbol.symbol-sm-25px.symbol-fixed > img {
    width: 25px;
    height: 25px;
    max-width: auto;
  }
  .symbol.symbol-sm-25px.symbol-2by3 .symbol-label {
    height: 25px;
    width: 37.5px;
  }
  .symbol.symbol-sm-25px.symbol-2by3 > img {
    height: 25px;
    width: 37.5px;
    max-width: auto;
  }
  .symbol.symbol-sm-30px > img {
    width: 30px;
    height: 30px;
  }
  .symbol.symbol-sm-30px .symbol-label {
    width: 30px;
    height: 30px;
  }
  .symbol.symbol-sm-30px.symbol-fixed .symbol-label {
    width: 30px;
    height: 30px;
  }
  .symbol.symbol-sm-30px.symbol-fixed > img {
    width: 30px;
    height: 30px;
    max-width: auto;
  }
  .symbol.symbol-sm-30px.symbol-2by3 .symbol-label {
    height: 30px;
    width: 45px;
  }
  .symbol.symbol-sm-30px.symbol-2by3 > img {
    height: 30px;
    width: 45px;
    max-width: auto;
  }
  .symbol.symbol-sm-35px > img {
    width: 35px;
    height: 35px;
  }
  .symbol.symbol-sm-35px .symbol-label {
    width: 35px;
    height: 35px;
  }
  .symbol.symbol-sm-35px.symbol-fixed .symbol-label {
    width: 35px;
    height: 35px;
  }
  .symbol.symbol-sm-35px.symbol-fixed > img {
    width: 35px;
    height: 35px;
    max-width: auto;
  }
  .symbol.symbol-sm-35px.symbol-2by3 .symbol-label {
    height: 35px;
    width: 52.5px;
  }
  .symbol.symbol-sm-35px.symbol-2by3 > img {
    height: 35px;
    width: 52.5px;
    max-width: auto;
  }
  .symbol.symbol-sm-40px > img {
    width: 40px;
    height: 40px;
  }
  .symbol.symbol-sm-40px .symbol-label {
    width: 40px;
    height: 40px;
  }
  .symbol.symbol-sm-40px.symbol-fixed .symbol-label {
    width: 40px;
    height: 40px;
  }
  .symbol.symbol-sm-40px.symbol-fixed > img {
    width: 40px;
    height: 40px;
    max-width: auto;
  }
  .symbol.symbol-sm-40px.symbol-2by3 .symbol-label {
    height: 40px;
    width: 60px;
  }
  .symbol.symbol-sm-40px.symbol-2by3 > img {
    height: 40px;
    width: 60px;
    max-width: auto;
  }
  .symbol.symbol-sm-45px > img {
    width: 45px;
    height: 45px;
  }
  .symbol.symbol-sm-45px .symbol-label {
    width: 45px;
    height: 45px;
  }
  .symbol.symbol-sm-45px.symbol-fixed .symbol-label {
    width: 45px;
    height: 45px;
  }
  .symbol.symbol-sm-45px.symbol-fixed > img {
    width: 45px;
    height: 45px;
    max-width: auto;
  }
  .symbol.symbol-sm-45px.symbol-2by3 .symbol-label {
    height: 45px;
    width: 67.5px;
  }
  .symbol.symbol-sm-45px.symbol-2by3 > img {
    height: 45px;
    width: 67.5px;
    max-width: auto;
  }
  .symbol.symbol-sm-50px > img {
    width: 50px;
    height: 50px;
  }
  .symbol.symbol-sm-50px .symbol-label {
    width: 50px;
    height: 50px;
  }
  .symbol.symbol-sm-50px.symbol-fixed .symbol-label {
    width: 50px;
    height: 50px;
  }
  .symbol.symbol-sm-50px.symbol-fixed > img {
    width: 50px;
    height: 50px;
    max-width: auto;
  }
  .symbol.symbol-sm-50px.symbol-2by3 .symbol-label {
    height: 50px;
    width: 75px;
  }
  .symbol.symbol-sm-50px.symbol-2by3 > img {
    height: 50px;
    width: 75px;
    max-width: auto;
  }
  .symbol.symbol-sm-55px > img {
    width: 55px;
    height: 55px;
  }
  .symbol.symbol-sm-55px .symbol-label {
    width: 55px;
    height: 55px;
  }
  .symbol.symbol-sm-55px.symbol-fixed .symbol-label {
    width: 55px;
    height: 55px;
  }
  .symbol.symbol-sm-55px.symbol-fixed > img {
    width: 55px;
    height: 55px;
    max-width: auto;
  }
  .symbol.symbol-sm-55px.symbol-2by3 .symbol-label {
    height: 55px;
    width: 82.5px;
  }
  .symbol.symbol-sm-55px.symbol-2by3 > img {
    height: 55px;
    width: 82.5px;
    max-width: auto;
  }
  .symbol.symbol-sm-60px > img {
    width: 60px;
    height: 60px;
  }
  .symbol.symbol-sm-60px .symbol-label {
    width: 60px;
    height: 60px;
  }
  .symbol.symbol-sm-60px.symbol-fixed .symbol-label {
    width: 60px;
    height: 60px;
  }
  .symbol.symbol-sm-60px.symbol-fixed > img {
    width: 60px;
    height: 60px;
    max-width: auto;
  }
  .symbol.symbol-sm-60px.symbol-2by3 .symbol-label {
    height: 60px;
    width: 90px;
  }
  .symbol.symbol-sm-60px.symbol-2by3 > img {
    height: 60px;
    width: 90px;
    max-width: auto;
  }
  .symbol.symbol-sm-65px > img {
    width: 65px;
    height: 65px;
  }
  .symbol.symbol-sm-65px .symbol-label {
    width: 65px;
    height: 65px;
  }
  .symbol.symbol-sm-65px.symbol-fixed .symbol-label {
    width: 65px;
    height: 65px;
  }
  .symbol.symbol-sm-65px.symbol-fixed > img {
    width: 65px;
    height: 65px;
    max-width: auto;
  }
  .symbol.symbol-sm-65px.symbol-2by3 .symbol-label {
    height: 65px;
    width: 97.5px;
  }
  .symbol.symbol-sm-65px.symbol-2by3 > img {
    height: 65px;
    width: 97.5px;
    max-width: auto;
  }
  .symbol.symbol-sm-70px > img {
    width: 70px;
    height: 70px;
  }
  .symbol.symbol-sm-70px .symbol-label {
    width: 70px;
    height: 70px;
  }
  .symbol.symbol-sm-70px.symbol-fixed .symbol-label {
    width: 70px;
    height: 70px;
  }
  .symbol.symbol-sm-70px.symbol-fixed > img {
    width: 70px;
    height: 70px;
    max-width: auto;
  }
  .symbol.symbol-sm-70px.symbol-2by3 .symbol-label {
    height: 70px;
    width: 105px;
  }
  .symbol.symbol-sm-70px.symbol-2by3 > img {
    height: 70px;
    width: 105px;
    max-width: auto;
  }
  .symbol.symbol-sm-75px > img {
    width: 75px;
    height: 75px;
  }
  .symbol.symbol-sm-75px .symbol-label {
    width: 75px;
    height: 75px;
  }
  .symbol.symbol-sm-75px.symbol-fixed .symbol-label {
    width: 75px;
    height: 75px;
  }
  .symbol.symbol-sm-75px.symbol-fixed > img {
    width: 75px;
    height: 75px;
    max-width: auto;
  }
  .symbol.symbol-sm-75px.symbol-2by3 .symbol-label {
    height: 75px;
    width: 112.5px;
  }
  .symbol.symbol-sm-75px.symbol-2by3 > img {
    height: 75px;
    width: 112.5px;
    max-width: auto;
  }
  .symbol.symbol-sm-100px > img {
    width: 100px;
    height: 100px;
  }
  .symbol.symbol-sm-100px .symbol-label {
    width: 100px;
    height: 100px;
  }
  .symbol.symbol-sm-100px.symbol-fixed .symbol-label {
    width: 100px;
    height: 100px;
  }
  .symbol.symbol-sm-100px.symbol-fixed > img {
    width: 100px;
    height: 100px;
    max-width: auto;
  }
  .symbol.symbol-sm-100px.symbol-2by3 .symbol-label {
    height: 100px;
    width: 150px;
  }
  .symbol.symbol-sm-100px.symbol-2by3 > img {
    height: 100px;
    width: 150px;
    max-width: auto;
  }
  .symbol.symbol-sm-125px > img {
    width: 125px;
    height: 125px;
  }
  .symbol.symbol-sm-125px .symbol-label {
    width: 125px;
    height: 125px;
  }
  .symbol.symbol-sm-125px.symbol-fixed .symbol-label {
    width: 125px;
    height: 125px;
  }
  .symbol.symbol-sm-125px.symbol-fixed > img {
    width: 125px;
    height: 125px;
    max-width: auto;
  }
  .symbol.symbol-sm-125px.symbol-2by3 .symbol-label {
    height: 125px;
    width: 187.5px;
  }
  .symbol.symbol-sm-125px.symbol-2by3 > img {
    height: 125px;
    width: 187.5px;
    max-width: auto;
  }
  .symbol.symbol-sm-150px > img {
    width: 150px;
    height: 150px;
  }
  .symbol.symbol-sm-150px .symbol-label {
    width: 150px;
    height: 150px;
  }
  .symbol.symbol-sm-150px.symbol-fixed .symbol-label {
    width: 150px;
    height: 150px;
  }
  .symbol.symbol-sm-150px.symbol-fixed > img {
    width: 150px;
    height: 150px;
    max-width: auto;
  }
  .symbol.symbol-sm-150px.symbol-2by3 .symbol-label {
    height: 150px;
    width: 225px;
  }
  .symbol.symbol-sm-150px.symbol-2by3 > img {
    height: 150px;
    width: 225px;
    max-width: auto;
  }
  .symbol.symbol-sm-160px > img {
    width: 160px;
    height: 160px;
  }
  .symbol.symbol-sm-160px .symbol-label {
    width: 160px;
    height: 160px;
  }
  .symbol.symbol-sm-160px.symbol-fixed .symbol-label {
    width: 160px;
    height: 160px;
  }
  .symbol.symbol-sm-160px.symbol-fixed > img {
    width: 160px;
    height: 160px;
    max-width: auto;
  }
  .symbol.symbol-sm-160px.symbol-2by3 .symbol-label {
    height: 160px;
    width: 240px;
  }
  .symbol.symbol-sm-160px.symbol-2by3 > img {
    height: 160px;
    width: 240px;
    max-width: auto;
  }
  .symbol.symbol-sm-175px > img {
    width: 175px;
    height: 175px;
  }
  .symbol.symbol-sm-175px .symbol-label {
    width: 175px;
    height: 175px;
  }
  .symbol.symbol-sm-175px.symbol-fixed .symbol-label {
    width: 175px;
    height: 175px;
  }
  .symbol.symbol-sm-175px.symbol-fixed > img {
    width: 175px;
    height: 175px;
    max-width: auto;
  }
  .symbol.symbol-sm-175px.symbol-2by3 .symbol-label {
    height: 175px;
    width: 262.5px;
  }
  .symbol.symbol-sm-175px.symbol-2by3 > img {
    height: 175px;
    width: 262.5px;
    max-width: auto;
  }
  .symbol.symbol-sm-200px > img {
    width: 200px;
    height: 200px;
  }
  .symbol.symbol-sm-200px .symbol-label {
    width: 200px;
    height: 200px;
  }
  .symbol.symbol-sm-200px.symbol-fixed .symbol-label {
    width: 200px;
    height: 200px;
  }
  .symbol.symbol-sm-200px.symbol-fixed > img {
    width: 200px;
    height: 200px;
    max-width: auto;
  }
  .symbol.symbol-sm-200px.symbol-2by3 .symbol-label {
    height: 200px;
    width: 300px;
  }
  .symbol.symbol-sm-200px.symbol-2by3 > img {
    height: 200px;
    width: 300px;
    max-width: auto;
  }
}
@media (min-width: 768px) {
  .symbol.symbol-md-20px > img {
    width: 20px;
    height: 20px;
  }
  .symbol.symbol-md-20px .symbol-label {
    width: 20px;
    height: 20px;
  }
  .symbol.symbol-md-20px.symbol-fixed .symbol-label {
    width: 20px;
    height: 20px;
  }
  .symbol.symbol-md-20px.symbol-fixed > img {
    width: 20px;
    height: 20px;
    max-width: auto;
  }
  .symbol.symbol-md-20px.symbol-2by3 .symbol-label {
    height: 20px;
    width: 30px;
  }
  .symbol.symbol-md-20px.symbol-2by3 > img {
    height: 20px;
    width: 30px;
    max-width: auto;
  }
  .symbol.symbol-md-25px > img {
    width: 25px;
    height: 25px;
  }
  .symbol.symbol-md-25px .symbol-label {
    width: 25px;
    height: 25px;
  }
  .symbol.symbol-md-25px.symbol-fixed .symbol-label {
    width: 25px;
    height: 25px;
  }
  .symbol.symbol-md-25px.symbol-fixed > img {
    width: 25px;
    height: 25px;
    max-width: auto;
  }
  .symbol.symbol-md-25px.symbol-2by3 .symbol-label {
    height: 25px;
    width: 37.5px;
  }
  .symbol.symbol-md-25px.symbol-2by3 > img {
    height: 25px;
    width: 37.5px;
    max-width: auto;
  }
  .symbol.symbol-md-30px > img {
    width: 30px;
    height: 30px;
  }
  .symbol.symbol-md-30px .symbol-label {
    width: 30px;
    height: 30px;
  }
  .symbol.symbol-md-30px.symbol-fixed .symbol-label {
    width: 30px;
    height: 30px;
  }
  .symbol.symbol-md-30px.symbol-fixed > img {
    width: 30px;
    height: 30px;
    max-width: auto;
  }
  .symbol.symbol-md-30px.symbol-2by3 .symbol-label {
    height: 30px;
    width: 45px;
  }
  .symbol.symbol-md-30px.symbol-2by3 > img {
    height: 30px;
    width: 45px;
    max-width: auto;
  }
  .symbol.symbol-md-35px > img {
    width: 35px;
    height: 35px;
  }
  .symbol.symbol-md-35px .symbol-label {
    width: 35px;
    height: 35px;
  }
  .symbol.symbol-md-35px.symbol-fixed .symbol-label {
    width: 35px;
    height: 35px;
  }
  .symbol.symbol-md-35px.symbol-fixed > img {
    width: 35px;
    height: 35px;
    max-width: auto;
  }
  .symbol.symbol-md-35px.symbol-2by3 .symbol-label {
    height: 35px;
    width: 52.5px;
  }
  .symbol.symbol-md-35px.symbol-2by3 > img {
    height: 35px;
    width: 52.5px;
    max-width: auto;
  }
  .symbol.symbol-md-40px > img {
    width: 40px;
    height: 40px;
  }
  .symbol.symbol-md-40px .symbol-label {
    width: 40px;
    height: 40px;
  }
  .symbol.symbol-md-40px.symbol-fixed .symbol-label {
    width: 40px;
    height: 40px;
  }
  .symbol.symbol-md-40px.symbol-fixed > img {
    width: 40px;
    height: 40px;
    max-width: auto;
  }
  .symbol.symbol-md-40px.symbol-2by3 .symbol-label {
    height: 40px;
    width: 60px;
  }
  .symbol.symbol-md-40px.symbol-2by3 > img {
    height: 40px;
    width: 60px;
    max-width: auto;
  }
  .symbol.symbol-md-45px > img {
    width: 45px;
    height: 45px;
  }
  .symbol.symbol-md-45px .symbol-label {
    width: 45px;
    height: 45px;
  }
  .symbol.symbol-md-45px.symbol-fixed .symbol-label {
    width: 45px;
    height: 45px;
  }
  .symbol.symbol-md-45px.symbol-fixed > img {
    width: 45px;
    height: 45px;
    max-width: auto;
  }
  .symbol.symbol-md-45px.symbol-2by3 .symbol-label {
    height: 45px;
    width: 67.5px;
  }
  .symbol.symbol-md-45px.symbol-2by3 > img {
    height: 45px;
    width: 67.5px;
    max-width: auto;
  }
  .symbol.symbol-md-50px > img {
    width: 50px;
    height: 50px;
  }
  .symbol.symbol-md-50px .symbol-label {
    width: 50px;
    height: 50px;
  }
  .symbol.symbol-md-50px.symbol-fixed .symbol-label {
    width: 50px;
    height: 50px;
  }
  .symbol.symbol-md-50px.symbol-fixed > img {
    width: 50px;
    height: 50px;
    max-width: auto;
  }
  .symbol.symbol-md-50px.symbol-2by3 .symbol-label {
    height: 50px;
    width: 75px;
  }
  .symbol.symbol-md-50px.symbol-2by3 > img {
    height: 50px;
    width: 75px;
    max-width: auto;
  }
  .symbol.symbol-md-55px > img {
    width: 55px;
    height: 55px;
  }
  .symbol.symbol-md-55px .symbol-label {
    width: 55px;
    height: 55px;
  }
  .symbol.symbol-md-55px.symbol-fixed .symbol-label {
    width: 55px;
    height: 55px;
  }
  .symbol.symbol-md-55px.symbol-fixed > img {
    width: 55px;
    height: 55px;
    max-width: auto;
  }
  .symbol.symbol-md-55px.symbol-2by3 .symbol-label {
    height: 55px;
    width: 82.5px;
  }
  .symbol.symbol-md-55px.symbol-2by3 > img {
    height: 55px;
    width: 82.5px;
    max-width: auto;
  }
  .symbol.symbol-md-60px > img {
    width: 60px;
    height: 60px;
  }
  .symbol.symbol-md-60px .symbol-label {
    width: 60px;
    height: 60px;
  }
  .symbol.symbol-md-60px.symbol-fixed .symbol-label {
    width: 60px;
    height: 60px;
  }
  .symbol.symbol-md-60px.symbol-fixed > img {
    width: 60px;
    height: 60px;
    max-width: auto;
  }
  .symbol.symbol-md-60px.symbol-2by3 .symbol-label {
    height: 60px;
    width: 90px;
  }
  .symbol.symbol-md-60px.symbol-2by3 > img {
    height: 60px;
    width: 90px;
    max-width: auto;
  }
  .symbol.symbol-md-65px > img {
    width: 65px;
    height: 65px;
  }
  .symbol.symbol-md-65px .symbol-label {
    width: 65px;
    height: 65px;
  }
  .symbol.symbol-md-65px.symbol-fixed .symbol-label {
    width: 65px;
    height: 65px;
  }
  .symbol.symbol-md-65px.symbol-fixed > img {
    width: 65px;
    height: 65px;
    max-width: auto;
  }
  .symbol.symbol-md-65px.symbol-2by3 .symbol-label {
    height: 65px;
    width: 97.5px;
  }
  .symbol.symbol-md-65px.symbol-2by3 > img {
    height: 65px;
    width: 97.5px;
    max-width: auto;
  }
  .symbol.symbol-md-70px > img {
    width: 70px;
    height: 70px;
  }
  .symbol.symbol-md-70px .symbol-label {
    width: 70px;
    height: 70px;
  }
  .symbol.symbol-md-70px.symbol-fixed .symbol-label {
    width: 70px;
    height: 70px;
  }
  .symbol.symbol-md-70px.symbol-fixed > img {
    width: 70px;
    height: 70px;
    max-width: auto;
  }
  .symbol.symbol-md-70px.symbol-2by3 .symbol-label {
    height: 70px;
    width: 105px;
  }
  .symbol.symbol-md-70px.symbol-2by3 > img {
    height: 70px;
    width: 105px;
    max-width: auto;
  }
  .symbol.symbol-md-75px > img {
    width: 75px;
    height: 75px;
  }
  .symbol.symbol-md-75px .symbol-label {
    width: 75px;
    height: 75px;
  }
  .symbol.symbol-md-75px.symbol-fixed .symbol-label {
    width: 75px;
    height: 75px;
  }
  .symbol.symbol-md-75px.symbol-fixed > img {
    width: 75px;
    height: 75px;
    max-width: auto;
  }
  .symbol.symbol-md-75px.symbol-2by3 .symbol-label {
    height: 75px;
    width: 112.5px;
  }
  .symbol.symbol-md-75px.symbol-2by3 > img {
    height: 75px;
    width: 112.5px;
    max-width: auto;
  }
  .symbol.symbol-md-100px > img {
    width: 100px;
    height: 100px;
  }
  .symbol.symbol-md-100px .symbol-label {
    width: 100px;
    height: 100px;
  }
  .symbol.symbol-md-100px.symbol-fixed .symbol-label {
    width: 100px;
    height: 100px;
  }
  .symbol.symbol-md-100px.symbol-fixed > img {
    width: 100px;
    height: 100px;
    max-width: auto;
  }
  .symbol.symbol-md-100px.symbol-2by3 .symbol-label {
    height: 100px;
    width: 150px;
  }
  .symbol.symbol-md-100px.symbol-2by3 > img {
    height: 100px;
    width: 150px;
    max-width: auto;
  }
  .symbol.symbol-md-125px > img {
    width: 125px;
    height: 125px;
  }
  .symbol.symbol-md-125px .symbol-label {
    width: 125px;
    height: 125px;
  }
  .symbol.symbol-md-125px.symbol-fixed .symbol-label {
    width: 125px;
    height: 125px;
  }
  .symbol.symbol-md-125px.symbol-fixed > img {
    width: 125px;
    height: 125px;
    max-width: auto;
  }
  .symbol.symbol-md-125px.symbol-2by3 .symbol-label {
    height: 125px;
    width: 187.5px;
  }
  .symbol.symbol-md-125px.symbol-2by3 > img {
    height: 125px;
    width: 187.5px;
    max-width: auto;
  }
  .symbol.symbol-md-150px > img {
    width: 150px;
    height: 150px;
  }
  .symbol.symbol-md-150px .symbol-label {
    width: 150px;
    height: 150px;
  }
  .symbol.symbol-md-150px.symbol-fixed .symbol-label {
    width: 150px;
    height: 150px;
  }
  .symbol.symbol-md-150px.symbol-fixed > img {
    width: 150px;
    height: 150px;
    max-width: auto;
  }
  .symbol.symbol-md-150px.symbol-2by3 .symbol-label {
    height: 150px;
    width: 225px;
  }
  .symbol.symbol-md-150px.symbol-2by3 > img {
    height: 150px;
    width: 225px;
    max-width: auto;
  }
  .symbol.symbol-md-160px > img {
    width: 160px;
    height: 160px;
  }
  .symbol.symbol-md-160px .symbol-label {
    width: 160px;
    height: 160px;
  }
  .symbol.symbol-md-160px.symbol-fixed .symbol-label {
    width: 160px;
    height: 160px;
  }
  .symbol.symbol-md-160px.symbol-fixed > img {
    width: 160px;
    height: 160px;
    max-width: auto;
  }
  .symbol.symbol-md-160px.symbol-2by3 .symbol-label {
    height: 160px;
    width: 240px;
  }
  .symbol.symbol-md-160px.symbol-2by3 > img {
    height: 160px;
    width: 240px;
    max-width: auto;
  }
  .symbol.symbol-md-175px > img {
    width: 175px;
    height: 175px;
  }
  .symbol.symbol-md-175px .symbol-label {
    width: 175px;
    height: 175px;
  }
  .symbol.symbol-md-175px.symbol-fixed .symbol-label {
    width: 175px;
    height: 175px;
  }
  .symbol.symbol-md-175px.symbol-fixed > img {
    width: 175px;
    height: 175px;
    max-width: auto;
  }
  .symbol.symbol-md-175px.symbol-2by3 .symbol-label {
    height: 175px;
    width: 262.5px;
  }
  .symbol.symbol-md-175px.symbol-2by3 > img {
    height: 175px;
    width: 262.5px;
    max-width: auto;
  }
  .symbol.symbol-md-200px > img {
    width: 200px;
    height: 200px;
  }
  .symbol.symbol-md-200px .symbol-label {
    width: 200px;
    height: 200px;
  }
  .symbol.symbol-md-200px.symbol-fixed .symbol-label {
    width: 200px;
    height: 200px;
  }
  .symbol.symbol-md-200px.symbol-fixed > img {
    width: 200px;
    height: 200px;
    max-width: auto;
  }
  .symbol.symbol-md-200px.symbol-2by3 .symbol-label {
    height: 200px;
    width: 300px;
  }
  .symbol.symbol-md-200px.symbol-2by3 > img {
    height: 200px;
    width: 300px;
    max-width: auto;
  }
}
@media (min-width: 992px) {
  .symbol.symbol-lg-20px > img {
    width: 20px;
    height: 20px;
  }
  .symbol.symbol-lg-20px .symbol-label {
    width: 20px;
    height: 20px;
  }
  .symbol.symbol-lg-20px.symbol-fixed .symbol-label {
    width: 20px;
    height: 20px;
  }
  .symbol.symbol-lg-20px.symbol-fixed > img {
    width: 20px;
    height: 20px;
    max-width: auto;
  }
  .symbol.symbol-lg-20px.symbol-2by3 .symbol-label {
    height: 20px;
    width: 30px;
  }
  .symbol.symbol-lg-20px.symbol-2by3 > img {
    height: 20px;
    width: 30px;
    max-width: auto;
  }
  .symbol.symbol-lg-25px > img {
    width: 25px;
    height: 25px;
  }
  .symbol.symbol-lg-25px .symbol-label {
    width: 25px;
    height: 25px;
  }
  .symbol.symbol-lg-25px.symbol-fixed .symbol-label {
    width: 25px;
    height: 25px;
  }
  .symbol.symbol-lg-25px.symbol-fixed > img {
    width: 25px;
    height: 25px;
    max-width: auto;
  }
  .symbol.symbol-lg-25px.symbol-2by3 .symbol-label {
    height: 25px;
    width: 37.5px;
  }
  .symbol.symbol-lg-25px.symbol-2by3 > img {
    height: 25px;
    width: 37.5px;
    max-width: auto;
  }
  .symbol.symbol-lg-30px > img {
    width: 30px;
    height: 30px;
  }
  .symbol.symbol-lg-30px .symbol-label {
    width: 30px;
    height: 30px;
  }
  .symbol.symbol-lg-30px.symbol-fixed .symbol-label {
    width: 30px;
    height: 30px;
  }
  .symbol.symbol-lg-30px.symbol-fixed > img {
    width: 30px;
    height: 30px;
    max-width: auto;
  }
  .symbol.symbol-lg-30px.symbol-2by3 .symbol-label {
    height: 30px;
    width: 45px;
  }
  .symbol.symbol-lg-30px.symbol-2by3 > img {
    height: 30px;
    width: 45px;
    max-width: auto;
  }
  .symbol.symbol-lg-35px > img {
    width: 35px;
    height: 35px;
  }
  .symbol.symbol-lg-35px .symbol-label {
    width: 35px;
    height: 35px;
  }
  .symbol.symbol-lg-35px.symbol-fixed .symbol-label {
    width: 35px;
    height: 35px;
  }
  .symbol.symbol-lg-35px.symbol-fixed > img {
    width: 35px;
    height: 35px;
    max-width: auto;
  }
  .symbol.symbol-lg-35px.symbol-2by3 .symbol-label {
    height: 35px;
    width: 52.5px;
  }
  .symbol.symbol-lg-35px.symbol-2by3 > img {
    height: 35px;
    width: 52.5px;
    max-width: auto;
  }
  .symbol.symbol-lg-40px > img {
    width: 40px;
    height: 40px;
  }
  .symbol.symbol-lg-40px .symbol-label {
    width: 40px;
    height: 40px;
  }
  .symbol.symbol-lg-40px.symbol-fixed .symbol-label {
    width: 40px;
    height: 40px;
  }
  .symbol.symbol-lg-40px.symbol-fixed > img {
    width: 40px;
    height: 40px;
    max-width: auto;
  }
  .symbol.symbol-lg-40px.symbol-2by3 .symbol-label {
    height: 40px;
    width: 60px;
  }
  .symbol.symbol-lg-40px.symbol-2by3 > img {
    height: 40px;
    width: 60px;
    max-width: auto;
  }
  .symbol.symbol-lg-45px > img {
    width: 45px;
    height: 45px;
  }
  .symbol.symbol-lg-45px .symbol-label {
    width: 45px;
    height: 45px;
  }
  .symbol.symbol-lg-45px.symbol-fixed .symbol-label {
    width: 45px;
    height: 45px;
  }
  .symbol.symbol-lg-45px.symbol-fixed > img {
    width: 45px;
    height: 45px;
    max-width: auto;
  }
  .symbol.symbol-lg-45px.symbol-2by3 .symbol-label {
    height: 45px;
    width: 67.5px;
  }
  .symbol.symbol-lg-45px.symbol-2by3 > img {
    height: 45px;
    width: 67.5px;
    max-width: auto;
  }
  .symbol.symbol-lg-50px > img {
    width: 50px;
    height: 50px;
  }
  .symbol.symbol-lg-50px .symbol-label {
    width: 50px;
    height: 50px;
  }
  .symbol.symbol-lg-50px.symbol-fixed .symbol-label {
    width: 50px;
    height: 50px;
  }
  .symbol.symbol-lg-50px.symbol-fixed > img {
    width: 50px;
    height: 50px;
    max-width: auto;
  }
  .symbol.symbol-lg-50px.symbol-2by3 .symbol-label {
    height: 50px;
    width: 75px;
  }
  .symbol.symbol-lg-50px.symbol-2by3 > img {
    height: 50px;
    width: 75px;
    max-width: auto;
  }
  .symbol.symbol-lg-55px > img {
    width: 55px;
    height: 55px;
  }
  .symbol.symbol-lg-55px .symbol-label {
    width: 55px;
    height: 55px;
  }
  .symbol.symbol-lg-55px.symbol-fixed .symbol-label {
    width: 55px;
    height: 55px;
  }
  .symbol.symbol-lg-55px.symbol-fixed > img {
    width: 55px;
    height: 55px;
    max-width: auto;
  }
  .symbol.symbol-lg-55px.symbol-2by3 .symbol-label {
    height: 55px;
    width: 82.5px;
  }
  .symbol.symbol-lg-55px.symbol-2by3 > img {
    height: 55px;
    width: 82.5px;
    max-width: auto;
  }
  .symbol.symbol-lg-60px > img {
    width: 60px;
    height: 60px;
  }
  .symbol.symbol-lg-60px .symbol-label {
    width: 60px;
    height: 60px;
  }
  .symbol.symbol-lg-60px.symbol-fixed .symbol-label {
    width: 60px;
    height: 60px;
  }
  .symbol.symbol-lg-60px.symbol-fixed > img {
    width: 60px;
    height: 60px;
    max-width: auto;
  }
  .symbol.symbol-lg-60px.symbol-2by3 .symbol-label {
    height: 60px;
    width: 90px;
  }
  .symbol.symbol-lg-60px.symbol-2by3 > img {
    height: 60px;
    width: 90px;
    max-width: auto;
  }
  .symbol.symbol-lg-65px > img {
    width: 65px;
    height: 65px;
  }
  .symbol.symbol-lg-65px .symbol-label {
    width: 65px;
    height: 65px;
  }
  .symbol.symbol-lg-65px.symbol-fixed .symbol-label {
    width: 65px;
    height: 65px;
  }
  .symbol.symbol-lg-65px.symbol-fixed > img {
    width: 65px;
    height: 65px;
    max-width: auto;
  }
  .symbol.symbol-lg-65px.symbol-2by3 .symbol-label {
    height: 65px;
    width: 97.5px;
  }
  .symbol.symbol-lg-65px.symbol-2by3 > img {
    height: 65px;
    width: 97.5px;
    max-width: auto;
  }
  .symbol.symbol-lg-70px > img {
    width: 70px;
    height: 70px;
  }
  .symbol.symbol-lg-70px .symbol-label {
    width: 70px;
    height: 70px;
  }
  .symbol.symbol-lg-70px.symbol-fixed .symbol-label {
    width: 70px;
    height: 70px;
  }
  .symbol.symbol-lg-70px.symbol-fixed > img {
    width: 70px;
    height: 70px;
    max-width: auto;
  }
  .symbol.symbol-lg-70px.symbol-2by3 .symbol-label {
    height: 70px;
    width: 105px;
  }
  .symbol.symbol-lg-70px.symbol-2by3 > img {
    height: 70px;
    width: 105px;
    max-width: auto;
  }
  .symbol.symbol-lg-75px > img {
    width: 75px;
    height: 75px;
  }
  .symbol.symbol-lg-75px .symbol-label {
    width: 75px;
    height: 75px;
  }
  .symbol.symbol-lg-75px.symbol-fixed .symbol-label {
    width: 75px;
    height: 75px;
  }
  .symbol.symbol-lg-75px.symbol-fixed > img {
    width: 75px;
    height: 75px;
    max-width: auto;
  }
  .symbol.symbol-lg-75px.symbol-2by3 .symbol-label {
    height: 75px;
    width: 112.5px;
  }
  .symbol.symbol-lg-75px.symbol-2by3 > img {
    height: 75px;
    width: 112.5px;
    max-width: auto;
  }
  .symbol.symbol-lg-100px > img {
    width: 100px;
    height: 100px;
  }
  .symbol.symbol-lg-100px .symbol-label {
    width: 100px;
    height: 100px;
  }
  .symbol.symbol-lg-100px.symbol-fixed .symbol-label {
    width: 100px;
    height: 100px;
  }
  .symbol.symbol-lg-100px.symbol-fixed > img {
    width: 100px;
    height: 100px;
    max-width: auto;
  }
  .symbol.symbol-lg-100px.symbol-2by3 .symbol-label {
    height: 100px;
    width: 150px;
  }
  .symbol.symbol-lg-100px.symbol-2by3 > img {
    height: 100px;
    width: 150px;
    max-width: auto;
  }
  .symbol.symbol-lg-125px > img {
    width: 125px;
    height: 125px;
  }
  .symbol.symbol-lg-125px .symbol-label {
    width: 125px;
    height: 125px;
  }
  .symbol.symbol-lg-125px.symbol-fixed .symbol-label {
    width: 125px;
    height: 125px;
  }
  .symbol.symbol-lg-125px.symbol-fixed > img {
    width: 125px;
    height: 125px;
    max-width: auto;
  }
  .symbol.symbol-lg-125px.symbol-2by3 .symbol-label {
    height: 125px;
    width: 187.5px;
  }
  .symbol.symbol-lg-125px.symbol-2by3 > img {
    height: 125px;
    width: 187.5px;
    max-width: auto;
  }
  .symbol.symbol-lg-150px > img {
    width: 150px;
    height: 150px;
  }
  .symbol.symbol-lg-150px .symbol-label {
    width: 150px;
    height: 150px;
  }
  .symbol.symbol-lg-150px.symbol-fixed .symbol-label {
    width: 150px;
    height: 150px;
  }
  .symbol.symbol-lg-150px.symbol-fixed > img {
    width: 150px;
    height: 150px;
    max-width: auto;
  }
  .symbol.symbol-lg-150px.symbol-2by3 .symbol-label {
    height: 150px;
    width: 225px;
  }
  .symbol.symbol-lg-150px.symbol-2by3 > img {
    height: 150px;
    width: 225px;
    max-width: auto;
  }
  .symbol.symbol-lg-160px > img {
    width: 160px;
    height: 160px;
  }
  .symbol.symbol-lg-160px .symbol-label {
    width: 160px;
    height: 160px;
  }
  .symbol.symbol-lg-160px.symbol-fixed .symbol-label {
    width: 160px;
    height: 160px;
  }
  .symbol.symbol-lg-160px.symbol-fixed > img {
    width: 160px;
    height: 160px;
    max-width: auto;
  }
  .symbol.symbol-lg-160px.symbol-2by3 .symbol-label {
    height: 160px;
    width: 240px;
  }
  .symbol.symbol-lg-160px.symbol-2by3 > img {
    height: 160px;
    width: 240px;
    max-width: auto;
  }
  .symbol.symbol-lg-175px > img {
    width: 175px;
    height: 175px;
  }
  .symbol.symbol-lg-175px .symbol-label {
    width: 175px;
    height: 175px;
  }
  .symbol.symbol-lg-175px.symbol-fixed .symbol-label {
    width: 175px;
    height: 175px;
  }
  .symbol.symbol-lg-175px.symbol-fixed > img {
    width: 175px;
    height: 175px;
    max-width: auto;
  }
  .symbol.symbol-lg-175px.symbol-2by3 .symbol-label {
    height: 175px;
    width: 262.5px;
  }
  .symbol.symbol-lg-175px.symbol-2by3 > img {
    height: 175px;
    width: 262.5px;
    max-width: auto;
  }
  .symbol.symbol-lg-200px > img {
    width: 200px;
    height: 200px;
  }
  .symbol.symbol-lg-200px .symbol-label {
    width: 200px;
    height: 200px;
  }
  .symbol.symbol-lg-200px.symbol-fixed .symbol-label {
    width: 200px;
    height: 200px;
  }
  .symbol.symbol-lg-200px.symbol-fixed > img {
    width: 200px;
    height: 200px;
    max-width: auto;
  }
  .symbol.symbol-lg-200px.symbol-2by3 .symbol-label {
    height: 200px;
    width: 300px;
  }
  .symbol.symbol-lg-200px.symbol-2by3 > img {
    height: 200px;
    width: 300px;
    max-width: auto;
  }
}
@media (min-width: 1200px) {
  .symbol.symbol-xl-20px > img {
    width: 20px;
    height: 20px;
  }
  .symbol.symbol-xl-20px .symbol-label {
    width: 20px;
    height: 20px;
  }
  .symbol.symbol-xl-20px.symbol-fixed .symbol-label {
    width: 20px;
    height: 20px;
  }
  .symbol.symbol-xl-20px.symbol-fixed > img {
    width: 20px;
    height: 20px;
    max-width: auto;
  }
  .symbol.symbol-xl-20px.symbol-2by3 .symbol-label {
    height: 20px;
    width: 30px;
  }
  .symbol.symbol-xl-20px.symbol-2by3 > img {
    height: 20px;
    width: 30px;
    max-width: auto;
  }
  .symbol.symbol-xl-25px > img {
    width: 25px;
    height: 25px;
  }
  .symbol.symbol-xl-25px .symbol-label {
    width: 25px;
    height: 25px;
  }
  .symbol.symbol-xl-25px.symbol-fixed .symbol-label {
    width: 25px;
    height: 25px;
  }
  .symbol.symbol-xl-25px.symbol-fixed > img {
    width: 25px;
    height: 25px;
    max-width: auto;
  }
  .symbol.symbol-xl-25px.symbol-2by3 .symbol-label {
    height: 25px;
    width: 37.5px;
  }
  .symbol.symbol-xl-25px.symbol-2by3 > img {
    height: 25px;
    width: 37.5px;
    max-width: auto;
  }
  .symbol.symbol-xl-30px > img {
    width: 30px;
    height: 30px;
  }
  .symbol.symbol-xl-30px .symbol-label {
    width: 30px;
    height: 30px;
  }
  .symbol.symbol-xl-30px.symbol-fixed .symbol-label {
    width: 30px;
    height: 30px;
  }
  .symbol.symbol-xl-30px.symbol-fixed > img {
    width: 30px;
    height: 30px;
    max-width: auto;
  }
  .symbol.symbol-xl-30px.symbol-2by3 .symbol-label {
    height: 30px;
    width: 45px;
  }
  .symbol.symbol-xl-30px.symbol-2by3 > img {
    height: 30px;
    width: 45px;
    max-width: auto;
  }
  .symbol.symbol-xl-35px > img {
    width: 35px;
    height: 35px;
  }
  .symbol.symbol-xl-35px .symbol-label {
    width: 35px;
    height: 35px;
  }
  .symbol.symbol-xl-35px.symbol-fixed .symbol-label {
    width: 35px;
    height: 35px;
  }
  .symbol.symbol-xl-35px.symbol-fixed > img {
    width: 35px;
    height: 35px;
    max-width: auto;
  }
  .symbol.symbol-xl-35px.symbol-2by3 .symbol-label {
    height: 35px;
    width: 52.5px;
  }
  .symbol.symbol-xl-35px.symbol-2by3 > img {
    height: 35px;
    width: 52.5px;
    max-width: auto;
  }
  .symbol.symbol-xl-40px > img {
    width: 40px;
    height: 40px;
  }
  .symbol.symbol-xl-40px .symbol-label {
    width: 40px;
    height: 40px;
  }
  .symbol.symbol-xl-40px.symbol-fixed .symbol-label {
    width: 40px;
    height: 40px;
  }
  .symbol.symbol-xl-40px.symbol-fixed > img {
    width: 40px;
    height: 40px;
    max-width: auto;
  }
  .symbol.symbol-xl-40px.symbol-2by3 .symbol-label {
    height: 40px;
    width: 60px;
  }
  .symbol.symbol-xl-40px.symbol-2by3 > img {
    height: 40px;
    width: 60px;
    max-width: auto;
  }
  .symbol.symbol-xl-45px > img {
    width: 45px;
    height: 45px;
  }
  .symbol.symbol-xl-45px .symbol-label {
    width: 45px;
    height: 45px;
  }
  .symbol.symbol-xl-45px.symbol-fixed .symbol-label {
    width: 45px;
    height: 45px;
  }
  .symbol.symbol-xl-45px.symbol-fixed > img {
    width: 45px;
    height: 45px;
    max-width: auto;
  }
  .symbol.symbol-xl-45px.symbol-2by3 .symbol-label {
    height: 45px;
    width: 67.5px;
  }
  .symbol.symbol-xl-45px.symbol-2by3 > img {
    height: 45px;
    width: 67.5px;
    max-width: auto;
  }
  .symbol.symbol-xl-50px > img {
    width: 50px;
    height: 50px;
  }
  .symbol.symbol-xl-50px .symbol-label {
    width: 50px;
    height: 50px;
  }
  .symbol.symbol-xl-50px.symbol-fixed .symbol-label {
    width: 50px;
    height: 50px;
  }
  .symbol.symbol-xl-50px.symbol-fixed > img {
    width: 50px;
    height: 50px;
    max-width: auto;
  }
  .symbol.symbol-xl-50px.symbol-2by3 .symbol-label {
    height: 50px;
    width: 75px;
  }
  .symbol.symbol-xl-50px.symbol-2by3 > img {
    height: 50px;
    width: 75px;
    max-width: auto;
  }
  .symbol.symbol-xl-55px > img {
    width: 55px;
    height: 55px;
  }
  .symbol.symbol-xl-55px .symbol-label {
    width: 55px;
    height: 55px;
  }
  .symbol.symbol-xl-55px.symbol-fixed .symbol-label {
    width: 55px;
    height: 55px;
  }
  .symbol.symbol-xl-55px.symbol-fixed > img {
    width: 55px;
    height: 55px;
    max-width: auto;
  }
  .symbol.symbol-xl-55px.symbol-2by3 .symbol-label {
    height: 55px;
    width: 82.5px;
  }
  .symbol.symbol-xl-55px.symbol-2by3 > img {
    height: 55px;
    width: 82.5px;
    max-width: auto;
  }
  .symbol.symbol-xl-60px > img {
    width: 60px;
    height: 60px;
  }
  .symbol.symbol-xl-60px .symbol-label {
    width: 60px;
    height: 60px;
  }
  .symbol.symbol-xl-60px.symbol-fixed .symbol-label {
    width: 60px;
    height: 60px;
  }
  .symbol.symbol-xl-60px.symbol-fixed > img {
    width: 60px;
    height: 60px;
    max-width: auto;
  }
  .symbol.symbol-xl-60px.symbol-2by3 .symbol-label {
    height: 60px;
    width: 90px;
  }
  .symbol.symbol-xl-60px.symbol-2by3 > img {
    height: 60px;
    width: 90px;
    max-width: auto;
  }
  .symbol.symbol-xl-65px > img {
    width: 65px;
    height: 65px;
  }
  .symbol.symbol-xl-65px .symbol-label {
    width: 65px;
    height: 65px;
  }
  .symbol.symbol-xl-65px.symbol-fixed .symbol-label {
    width: 65px;
    height: 65px;
  }
  .symbol.symbol-xl-65px.symbol-fixed > img {
    width: 65px;
    height: 65px;
    max-width: auto;
  }
  .symbol.symbol-xl-65px.symbol-2by3 .symbol-label {
    height: 65px;
    width: 97.5px;
  }
  .symbol.symbol-xl-65px.symbol-2by3 > img {
    height: 65px;
    width: 97.5px;
    max-width: auto;
  }
  .symbol.symbol-xl-70px > img {
    width: 70px;
    height: 70px;
  }
  .symbol.symbol-xl-70px .symbol-label {
    width: 70px;
    height: 70px;
  }
  .symbol.symbol-xl-70px.symbol-fixed .symbol-label {
    width: 70px;
    height: 70px;
  }
  .symbol.symbol-xl-70px.symbol-fixed > img {
    width: 70px;
    height: 70px;
    max-width: auto;
  }
  .symbol.symbol-xl-70px.symbol-2by3 .symbol-label {
    height: 70px;
    width: 105px;
  }
  .symbol.symbol-xl-70px.symbol-2by3 > img {
    height: 70px;
    width: 105px;
    max-width: auto;
  }
  .symbol.symbol-xl-75px > img {
    width: 75px;
    height: 75px;
  }
  .symbol.symbol-xl-75px .symbol-label {
    width: 75px;
    height: 75px;
  }
  .symbol.symbol-xl-75px.symbol-fixed .symbol-label {
    width: 75px;
    height: 75px;
  }
  .symbol.symbol-xl-75px.symbol-fixed > img {
    width: 75px;
    height: 75px;
    max-width: auto;
  }
  .symbol.symbol-xl-75px.symbol-2by3 .symbol-label {
    height: 75px;
    width: 112.5px;
  }
  .symbol.symbol-xl-75px.symbol-2by3 > img {
    height: 75px;
    width: 112.5px;
    max-width: auto;
  }
  .symbol.symbol-xl-100px > img {
    width: 100px;
    height: 100px;
  }
  .symbol.symbol-xl-100px .symbol-label {
    width: 100px;
    height: 100px;
  }
  .symbol.symbol-xl-100px.symbol-fixed .symbol-label {
    width: 100px;
    height: 100px;
  }
  .symbol.symbol-xl-100px.symbol-fixed > img {
    width: 100px;
    height: 100px;
    max-width: auto;
  }
  .symbol.symbol-xl-100px.symbol-2by3 .symbol-label {
    height: 100px;
    width: 150px;
  }
  .symbol.symbol-xl-100px.symbol-2by3 > img {
    height: 100px;
    width: 150px;
    max-width: auto;
  }
  .symbol.symbol-xl-125px > img {
    width: 125px;
    height: 125px;
  }
  .symbol.symbol-xl-125px .symbol-label {
    width: 125px;
    height: 125px;
  }
  .symbol.symbol-xl-125px.symbol-fixed .symbol-label {
    width: 125px;
    height: 125px;
  }
  .symbol.symbol-xl-125px.symbol-fixed > img {
    width: 125px;
    height: 125px;
    max-width: auto;
  }
  .symbol.symbol-xl-125px.symbol-2by3 .symbol-label {
    height: 125px;
    width: 187.5px;
  }
  .symbol.symbol-xl-125px.symbol-2by3 > img {
    height: 125px;
    width: 187.5px;
    max-width: auto;
  }
  .symbol.symbol-xl-150px > img {
    width: 150px;
    height: 150px;
  }
  .symbol.symbol-xl-150px .symbol-label {
    width: 150px;
    height: 150px;
  }
  .symbol.symbol-xl-150px.symbol-fixed .symbol-label {
    width: 150px;
    height: 150px;
  }
  .symbol.symbol-xl-150px.symbol-fixed > img {
    width: 150px;
    height: 150px;
    max-width: auto;
  }
  .symbol.symbol-xl-150px.symbol-2by3 .symbol-label {
    height: 150px;
    width: 225px;
  }
  .symbol.symbol-xl-150px.symbol-2by3 > img {
    height: 150px;
    width: 225px;
    max-width: auto;
  }
  .symbol.symbol-xl-160px > img {
    width: 160px;
    height: 160px;
  }
  .symbol.symbol-xl-160px .symbol-label {
    width: 160px;
    height: 160px;
  }
  .symbol.symbol-xl-160px.symbol-fixed .symbol-label {
    width: 160px;
    height: 160px;
  }
  .symbol.symbol-xl-160px.symbol-fixed > img {
    width: 160px;
    height: 160px;
    max-width: auto;
  }
  .symbol.symbol-xl-160px.symbol-2by3 .symbol-label {
    height: 160px;
    width: 240px;
  }
  .symbol.symbol-xl-160px.symbol-2by3 > img {
    height: 160px;
    width: 240px;
    max-width: auto;
  }
  .symbol.symbol-xl-175px > img {
    width: 175px;
    height: 175px;
  }
  .symbol.symbol-xl-175px .symbol-label {
    width: 175px;
    height: 175px;
  }
  .symbol.symbol-xl-175px.symbol-fixed .symbol-label {
    width: 175px;
    height: 175px;
  }
  .symbol.symbol-xl-175px.symbol-fixed > img {
    width: 175px;
    height: 175px;
    max-width: auto;
  }
  .symbol.symbol-xl-175px.symbol-2by3 .symbol-label {
    height: 175px;
    width: 262.5px;
  }
  .symbol.symbol-xl-175px.symbol-2by3 > img {
    height: 175px;
    width: 262.5px;
    max-width: auto;
  }
  .symbol.symbol-xl-200px > img {
    width: 200px;
    height: 200px;
  }
  .symbol.symbol-xl-200px .symbol-label {
    width: 200px;
    height: 200px;
  }
  .symbol.symbol-xl-200px.symbol-fixed .symbol-label {
    width: 200px;
    height: 200px;
  }
  .symbol.symbol-xl-200px.symbol-fixed > img {
    width: 200px;
    height: 200px;
    max-width: auto;
  }
  .symbol.symbol-xl-200px.symbol-2by3 .symbol-label {
    height: 200px;
    width: 300px;
  }
  .symbol.symbol-xl-200px.symbol-2by3 > img {
    height: 200px;
    width: 300px;
    max-width: auto;
  }
}
@media (min-width: 1400px) {
  .symbol.symbol-xxl-20px > img {
    width: 20px;
    height: 20px;
  }
  .symbol.symbol-xxl-20px .symbol-label {
    width: 20px;
    height: 20px;
  }
  .symbol.symbol-xxl-20px.symbol-fixed .symbol-label {
    width: 20px;
    height: 20px;
  }
  .symbol.symbol-xxl-20px.symbol-fixed > img {
    width: 20px;
    height: 20px;
    max-width: auto;
  }
  .symbol.symbol-xxl-20px.symbol-2by3 .symbol-label {
    height: 20px;
    width: 30px;
  }
  .symbol.symbol-xxl-20px.symbol-2by3 > img {
    height: 20px;
    width: 30px;
    max-width: auto;
  }
  .symbol.symbol-xxl-25px > img {
    width: 25px;
    height: 25px;
  }
  .symbol.symbol-xxl-25px .symbol-label {
    width: 25px;
    height: 25px;
  }
  .symbol.symbol-xxl-25px.symbol-fixed .symbol-label {
    width: 25px;
    height: 25px;
  }
  .symbol.symbol-xxl-25px.symbol-fixed > img {
    width: 25px;
    height: 25px;
    max-width: auto;
  }
  .symbol.symbol-xxl-25px.symbol-2by3 .symbol-label {
    height: 25px;
    width: 37.5px;
  }
  .symbol.symbol-xxl-25px.symbol-2by3 > img {
    height: 25px;
    width: 37.5px;
    max-width: auto;
  }
  .symbol.symbol-xxl-30px > img {
    width: 30px;
    height: 30px;
  }
  .symbol.symbol-xxl-30px .symbol-label {
    width: 30px;
    height: 30px;
  }
  .symbol.symbol-xxl-30px.symbol-fixed .symbol-label {
    width: 30px;
    height: 30px;
  }
  .symbol.symbol-xxl-30px.symbol-fixed > img {
    width: 30px;
    height: 30px;
    max-width: auto;
  }
  .symbol.symbol-xxl-30px.symbol-2by3 .symbol-label {
    height: 30px;
    width: 45px;
  }
  .symbol.symbol-xxl-30px.symbol-2by3 > img {
    height: 30px;
    width: 45px;
    max-width: auto;
  }
  .symbol.symbol-xxl-35px > img {
    width: 35px;
    height: 35px;
  }
  .symbol.symbol-xxl-35px .symbol-label {
    width: 35px;
    height: 35px;
  }
  .symbol.symbol-xxl-35px.symbol-fixed .symbol-label {
    width: 35px;
    height: 35px;
  }
  .symbol.symbol-xxl-35px.symbol-fixed > img {
    width: 35px;
    height: 35px;
    max-width: auto;
  }
  .symbol.symbol-xxl-35px.symbol-2by3 .symbol-label {
    height: 35px;
    width: 52.5px;
  }
  .symbol.symbol-xxl-35px.symbol-2by3 > img {
    height: 35px;
    width: 52.5px;
    max-width: auto;
  }
  .symbol.symbol-xxl-40px > img {
    width: 40px;
    height: 40px;
  }
  .symbol.symbol-xxl-40px .symbol-label {
    width: 40px;
    height: 40px;
  }
  .symbol.symbol-xxl-40px.symbol-fixed .symbol-label {
    width: 40px;
    height: 40px;
  }
  .symbol.symbol-xxl-40px.symbol-fixed > img {
    width: 40px;
    height: 40px;
    max-width: auto;
  }
  .symbol.symbol-xxl-40px.symbol-2by3 .symbol-label {
    height: 40px;
    width: 60px;
  }
  .symbol.symbol-xxl-40px.symbol-2by3 > img {
    height: 40px;
    width: 60px;
    max-width: auto;
  }
  .symbol.symbol-xxl-45px > img {
    width: 45px;
    height: 45px;
  }
  .symbol.symbol-xxl-45px .symbol-label {
    width: 45px;
    height: 45px;
  }
  .symbol.symbol-xxl-45px.symbol-fixed .symbol-label {
    width: 45px;
    height: 45px;
  }
  .symbol.symbol-xxl-45px.symbol-fixed > img {
    width: 45px;
    height: 45px;
    max-width: auto;
  }
  .symbol.symbol-xxl-45px.symbol-2by3 .symbol-label {
    height: 45px;
    width: 67.5px;
  }
  .symbol.symbol-xxl-45px.symbol-2by3 > img {
    height: 45px;
    width: 67.5px;
    max-width: auto;
  }
  .symbol.symbol-xxl-50px > img {
    width: 50px;
    height: 50px;
  }
  .symbol.symbol-xxl-50px .symbol-label {
    width: 50px;
    height: 50px;
  }
  .symbol.symbol-xxl-50px.symbol-fixed .symbol-label {
    width: 50px;
    height: 50px;
  }
  .symbol.symbol-xxl-50px.symbol-fixed > img {
    width: 50px;
    height: 50px;
    max-width: auto;
  }
  .symbol.symbol-xxl-50px.symbol-2by3 .symbol-label {
    height: 50px;
    width: 75px;
  }
  .symbol.symbol-xxl-50px.symbol-2by3 > img {
    height: 50px;
    width: 75px;
    max-width: auto;
  }
  .symbol.symbol-xxl-55px > img {
    width: 55px;
    height: 55px;
  }
  .symbol.symbol-xxl-55px .symbol-label {
    width: 55px;
    height: 55px;
  }
  .symbol.symbol-xxl-55px.symbol-fixed .symbol-label {
    width: 55px;
    height: 55px;
  }
  .symbol.symbol-xxl-55px.symbol-fixed > img {
    width: 55px;
    height: 55px;
    max-width: auto;
  }
  .symbol.symbol-xxl-55px.symbol-2by3 .symbol-label {
    height: 55px;
    width: 82.5px;
  }
  .symbol.symbol-xxl-55px.symbol-2by3 > img {
    height: 55px;
    width: 82.5px;
    max-width: auto;
  }
  .symbol.symbol-xxl-60px > img {
    width: 60px;
    height: 60px;
  }
  .symbol.symbol-xxl-60px .symbol-label {
    width: 60px;
    height: 60px;
  }
  .symbol.symbol-xxl-60px.symbol-fixed .symbol-label {
    width: 60px;
    height: 60px;
  }
  .symbol.symbol-xxl-60px.symbol-fixed > img {
    width: 60px;
    height: 60px;
    max-width: auto;
  }
  .symbol.symbol-xxl-60px.symbol-2by3 .symbol-label {
    height: 60px;
    width: 90px;
  }
  .symbol.symbol-xxl-60px.symbol-2by3 > img {
    height: 60px;
    width: 90px;
    max-width: auto;
  }
  .symbol.symbol-xxl-65px > img {
    width: 65px;
    height: 65px;
  }
  .symbol.symbol-xxl-65px .symbol-label {
    width: 65px;
    height: 65px;
  }
  .symbol.symbol-xxl-65px.symbol-fixed .symbol-label {
    width: 65px;
    height: 65px;
  }
  .symbol.symbol-xxl-65px.symbol-fixed > img {
    width: 65px;
    height: 65px;
    max-width: auto;
  }
  .symbol.symbol-xxl-65px.symbol-2by3 .symbol-label {
    height: 65px;
    width: 97.5px;
  }
  .symbol.symbol-xxl-65px.symbol-2by3 > img {
    height: 65px;
    width: 97.5px;
    max-width: auto;
  }
  .symbol.symbol-xxl-70px > img {
    width: 70px;
    height: 70px;
  }
  .symbol.symbol-xxl-70px .symbol-label {
    width: 70px;
    height: 70px;
  }
  .symbol.symbol-xxl-70px.symbol-fixed .symbol-label {
    width: 70px;
    height: 70px;
  }
  .symbol.symbol-xxl-70px.symbol-fixed > img {
    width: 70px;
    height: 70px;
    max-width: auto;
  }
  .symbol.symbol-xxl-70px.symbol-2by3 .symbol-label {
    height: 70px;
    width: 105px;
  }
  .symbol.symbol-xxl-70px.symbol-2by3 > img {
    height: 70px;
    width: 105px;
    max-width: auto;
  }
  .symbol.symbol-xxl-75px > img {
    width: 75px;
    height: 75px;
  }
  .symbol.symbol-xxl-75px .symbol-label {
    width: 75px;
    height: 75px;
  }
  .symbol.symbol-xxl-75px.symbol-fixed .symbol-label {
    width: 75px;
    height: 75px;
  }
  .symbol.symbol-xxl-75px.symbol-fixed > img {
    width: 75px;
    height: 75px;
    max-width: auto;
  }
  .symbol.symbol-xxl-75px.symbol-2by3 .symbol-label {
    height: 75px;
    width: 112.5px;
  }
  .symbol.symbol-xxl-75px.symbol-2by3 > img {
    height: 75px;
    width: 112.5px;
    max-width: auto;
  }
  .symbol.symbol-xxl-100px > img {
    width: 100px;
    height: 100px;
  }
  .symbol.symbol-xxl-100px .symbol-label {
    width: 100px;
    height: 100px;
  }
  .symbol.symbol-xxl-100px.symbol-fixed .symbol-label {
    width: 100px;
    height: 100px;
  }
  .symbol.symbol-xxl-100px.symbol-fixed > img {
    width: 100px;
    height: 100px;
    max-width: auto;
  }
  .symbol.symbol-xxl-100px.symbol-2by3 .symbol-label {
    height: 100px;
    width: 150px;
  }
  .symbol.symbol-xxl-100px.symbol-2by3 > img {
    height: 100px;
    width: 150px;
    max-width: auto;
  }
  .symbol.symbol-xxl-125px > img {
    width: 125px;
    height: 125px;
  }
  .symbol.symbol-xxl-125px .symbol-label {
    width: 125px;
    height: 125px;
  }
  .symbol.symbol-xxl-125px.symbol-fixed .symbol-label {
    width: 125px;
    height: 125px;
  }
  .symbol.symbol-xxl-125px.symbol-fixed > img {
    width: 125px;
    height: 125px;
    max-width: auto;
  }
  .symbol.symbol-xxl-125px.symbol-2by3 .symbol-label {
    height: 125px;
    width: 187.5px;
  }
  .symbol.symbol-xxl-125px.symbol-2by3 > img {
    height: 125px;
    width: 187.5px;
    max-width: auto;
  }
  .symbol.symbol-xxl-150px > img {
    width: 150px;
    height: 150px;
  }
  .symbol.symbol-xxl-150px .symbol-label {
    width: 150px;
    height: 150px;
  }
  .symbol.symbol-xxl-150px.symbol-fixed .symbol-label {
    width: 150px;
    height: 150px;
  }
  .symbol.symbol-xxl-150px.symbol-fixed > img {
    width: 150px;
    height: 150px;
    max-width: auto;
  }
  .symbol.symbol-xxl-150px.symbol-2by3 .symbol-label {
    height: 150px;
    width: 225px;
  }
  .symbol.symbol-xxl-150px.symbol-2by3 > img {
    height: 150px;
    width: 225px;
    max-width: auto;
  }
  .symbol.symbol-xxl-160px > img {
    width: 160px;
    height: 160px;
  }
  .symbol.symbol-xxl-160px .symbol-label {
    width: 160px;
    height: 160px;
  }
  .symbol.symbol-xxl-160px.symbol-fixed .symbol-label {
    width: 160px;
    height: 160px;
  }
  .symbol.symbol-xxl-160px.symbol-fixed > img {
    width: 160px;
    height: 160px;
    max-width: auto;
  }
  .symbol.symbol-xxl-160px.symbol-2by3 .symbol-label {
    height: 160px;
    width: 240px;
  }
  .symbol.symbol-xxl-160px.symbol-2by3 > img {
    height: 160px;
    width: 240px;
    max-width: auto;
  }
  .symbol.symbol-xxl-175px > img {
    width: 175px;
    height: 175px;
  }
  .symbol.symbol-xxl-175px .symbol-label {
    width: 175px;
    height: 175px;
  }
  .symbol.symbol-xxl-175px.symbol-fixed .symbol-label {
    width: 175px;
    height: 175px;
  }
  .symbol.symbol-xxl-175px.symbol-fixed > img {
    width: 175px;
    height: 175px;
    max-width: auto;
  }
  .symbol.symbol-xxl-175px.symbol-2by3 .symbol-label {
    height: 175px;
    width: 262.5px;
  }
  .symbol.symbol-xxl-175px.symbol-2by3 > img {
    height: 175px;
    width: 262.5px;
    max-width: auto;
  }
  .symbol.symbol-xxl-200px > img {
    width: 200px;
    height: 200px;
  }
  .symbol.symbol-xxl-200px .symbol-label {
    width: 200px;
    height: 200px;
  }
  .symbol.symbol-xxl-200px.symbol-fixed .symbol-label {
    width: 200px;
    height: 200px;
  }
  .symbol.symbol-xxl-200px.symbol-fixed > img {
    width: 200px;
    height: 200px;
    max-width: auto;
  }
  .symbol.symbol-xxl-200px.symbol-2by3 .symbol-label {
    height: 200px;
    width: 300px;
  }
  .symbol.symbol-xxl-200px.symbol-2by3 > img {
    height: 200px;
    width: 300px;
    max-width: auto;
  }
}

.symbol-group {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-left: 10px;
}
.symbol-group .symbol {
  position: relative;
  z-index: 0;
  margin-left: -10px;
  border: 2px solid #ffffff;
  transition: all 0.3s ease;
}
.symbol-group .symbol:hover {
  transition: all 0.3s ease;
  z-index: 1;
}
.symbol-group.symbol-hover .symbol {
  cursor: pointer;
}

.pulse {
  position: relative;
}
.pulse.pulse-white .pulse-ring {
  border-color: #ffffff;
}
.pulse.pulse-light .pulse-ring {
  border-color: #F5F8FA;
}
.pulse.pulse-primary .pulse-ring {
  border-color: #009EF7;
}
.pulse.pulse-secondary .pulse-ring {
  border-color: #E4E6EF;
}
.pulse.pulse-success .pulse-ring {
  border-color: #50CD89;
}
.pulse.pulse-info .pulse-ring {
  border-color: #7239EA;
}
.pulse.pulse-warning .pulse-ring {
  border-color: #FFC700;
}
.pulse.pulse-danger .pulse-ring {
  border-color: #F1416C;
}
.pulse.pulse-dark .pulse-ring {
  border-color: #181C32;
}

.pulse-ring {
  display: block;
  border-radius: 40px;
  height: 40px;
  width: 40px;
  position: absolute;
  animation: animation-pulse 3.5s ease-out;
  animation-iteration-count: infinite;
  opacity: 0;
  border-width: 3px;
  border-style: solid;
  border-color: #A1A5B7;
}

@keyframes animation-pulse {
  0% {
    -webkit-transform: scale(0.1, 0.1);
    opacity: 0;
  }
  60% {
    -webkit-transform: scale(0.1, 0.1);
    opacity: 0;
  }
  65% {
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(1.2, 1.2);
    opacity: 0;
  }
}
.page-loading * {
  transition: none !important;
}

.page-loader {
  background: #fff;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  display: none;
}
.page-loading .page-loader {
  display: flex;
  justify-content: center;
  align-items: center;
}

.scrolltop {
  display: none;
  justify-content: center;
  align-items: center;
  width: 36px;
  height: 36px;
  position: fixed;
  bottom: 40px;
  right: 20px;
  cursor: pointer;
  z-index: 100;
  background-color: #009EF7;
  box-shadow: 0 0.5rem 1.5rem 0.5rem rgba(0, 0, 0, 0.075);
  opacity: 0;
  transition: color 0.2s ease, background-color 0.2s ease;
  border-radius: 0.475rem !important;
}
.scrolltop .svg-icon svg {
  height: 24px;
  width: 24px;
}
.scrolltop .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #ffffff;
}
.scrolltop .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.scrolltop > i {
  font-size: 1.3rem;
  color: #ffffff;
}
.scrolltop:hover > i {
  color: #ffffff;
}
.scrolltop:hover .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #ffffff;
}
.scrolltop:hover .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
[data-kt-scrolltop=on] .scrolltop {
  opacity: 0.3;
  animation: animation-scrolltop 0.4s ease-out 1;
  display: flex;
}
[data-kt-scrolltop=on] .scrolltop:hover {
  transition: color 0.2s ease, background-color 0.2s ease;
  opacity: 1;
}
@media (max-width: 991.98px) {
  .scrolltop {
    bottom: 30px;
    right: 15px;
    width: 30px;
    height: 30px;
  }
}

@keyframes animation-scrolltop {
  from {
    margin-bottom: -15px;
  }
  to {
    margin-bottom: 0;
  }
}
.svg-icon {
  line-height: 1;
}
.svg-icon svg {
  height: 1.15rem;
  width: 1.15rem;
}
.svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #A1A5B7;
}
.svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.svg-icon.svg-icon-white svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #ffffff;
}
.svg-icon.svg-icon-white svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.svg-icon.svg-icon-primary svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #009EF7;
}
.svg-icon.svg-icon-primary svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.svg-icon.svg-icon-secondary svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #E4E6EF;
}
.svg-icon.svg-icon-secondary svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.svg-icon.svg-icon-light svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #F5F8FA;
}
.svg-icon.svg-icon-light svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.svg-icon.svg-icon-success svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #50CD89;
}
.svg-icon.svg-icon-success svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.svg-icon.svg-icon-info svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #7239EA;
}
.svg-icon.svg-icon-info svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.svg-icon.svg-icon-warning svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #FFC700;
}
.svg-icon.svg-icon-warning svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.svg-icon.svg-icon-danger svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #F1416C;
}
.svg-icon.svg-icon-danger svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.svg-icon.svg-icon-dark svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #181C32;
}
.svg-icon.svg-icon-dark svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.svg-icon.svg-icon-muted svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #A1A5B7;
}
.svg-icon.svg-icon-muted svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.svg-icon.svg-icon-gray-100 svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #F5F8FA;
}
.svg-icon.svg-icon-gray-100 svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.svg-icon.svg-icon-gray-200 svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #EFF2F5;
}
.svg-icon.svg-icon-gray-200 svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.svg-icon.svg-icon-gray-300 svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #E4E6EF;
}
.svg-icon.svg-icon-gray-300 svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.svg-icon.svg-icon-gray-400 svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #B5B5C3;
}
.svg-icon.svg-icon-gray-400 svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.svg-icon.svg-icon-gray-500 svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #A1A5B7;
}
.svg-icon.svg-icon-gray-500 svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.svg-icon.svg-icon-gray-600 svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #7E8299;
}
.svg-icon.svg-icon-gray-600 svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.svg-icon.svg-icon-gray-700 svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #5E6278;
}
.svg-icon.svg-icon-gray-700 svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.svg-icon.svg-icon-gray-800 svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #3F4254;
}
.svg-icon.svg-icon-gray-800 svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.svg-icon.svg-icon-gray-900 svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #181C32;
}
.svg-icon.svg-icon-gray-900 svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.svg-icon.svg-icon-1 svg {
  height: 1.75rem !important;
  width: 1.75rem !important;
}
.svg-icon.svg-icon-2 svg {
  height: 1.5rem !important;
  width: 1.5rem !important;
}
.svg-icon.svg-icon-3 svg {
  height: 1.35rem !important;
  width: 1.35rem !important;
}
.svg-icon.svg-icon-4 svg {
  height: 1.25rem !important;
  width: 1.25rem !important;
}
.svg-icon.svg-icon-5 svg {
  height: 1.15rem !important;
  width: 1.15rem !important;
}
.svg-icon.svg-icon-6 svg {
  height: 1.075rem !important;
  width: 1.075rem !important;
}
.svg-icon.svg-icon-7 svg {
  height: 0.95rem !important;
  width: 0.95rem !important;
}
.svg-icon.svg-icon-8 svg {
  height: 0.85rem !important;
  width: 0.85rem !important;
}
.svg-icon.svg-icon-9 svg {
  height: 0.75rem !important;
  width: 0.75rem !important;
}
.svg-icon.svg-icon-10 svg {
  height: 0.5rem !important;
  width: 0.5rem !important;
}
.svg-icon.svg-icon-base svg {
  height: 1rem !important;
  width: 1rem !important;
}
.svg-icon.svg-icon-fluid svg {
  height: 100% !important;
  width: 100% !important;
}
.svg-icon.svg-icon-2x svg {
  height: 2rem !important;
  width: 2rem !important;
}
.svg-icon.svg-icon-2qx svg {
  height: 2.25rem !important;
  width: 2.25rem !important;
}
.svg-icon.svg-icon-2hx svg {
  height: 2.5rem !important;
  width: 2.5rem !important;
}
.svg-icon.svg-icon-2tx svg {
  height: 2.75rem !important;
  width: 2.75rem !important;
}
.svg-icon.svg-icon-3x svg {
  height: 3rem !important;
  width: 3rem !important;
}
.svg-icon.svg-icon-3qx svg {
  height: 3.25rem !important;
  width: 3.25rem !important;
}
.svg-icon.svg-icon-3hx svg {
  height: 3.5rem !important;
  width: 3.5rem !important;
}
.svg-icon.svg-icon-3tx svg {
  height: 3.75rem !important;
  width: 3.75rem !important;
}
.svg-icon.svg-icon-4x svg {
  height: 4rem !important;
  width: 4rem !important;
}
.svg-icon.svg-icon-4qx svg {
  height: 4.25rem !important;
  width: 4.25rem !important;
}
.svg-icon.svg-icon-4hx svg {
  height: 4.5rem !important;
  width: 4.5rem !important;
}
.svg-icon.svg-icon-4tx svg {
  height: 4.75rem !important;
  width: 4.75rem !important;
}
.svg-icon.svg-icon-5x svg {
  height: 5rem !important;
  width: 5rem !important;
}
.svg-icon.svg-icon-5qx svg {
  height: 5.25rem !important;
  width: 5.25rem !important;
}
.svg-icon.svg-icon-5hx svg {
  height: 5.5rem !important;
  width: 5.5rem !important;
}
.svg-icon.svg-icon-5tx svg {
  height: 5.75rem !important;
  width: 5.75rem !important;
}
@media (min-width: 576px) {
  .svg-icon.svg-icon-sm-1 svg {
    height: 1.75rem !important;
    width: 1.75rem !important;
  }
  .svg-icon.svg-icon-sm-2 svg {
    height: 1.5rem !important;
    width: 1.5rem !important;
  }
  .svg-icon.svg-icon-sm-3 svg {
    height: 1.35rem !important;
    width: 1.35rem !important;
  }
  .svg-icon.svg-icon-sm-4 svg {
    height: 1.25rem !important;
    width: 1.25rem !important;
  }
  .svg-icon.svg-icon-sm-5 svg {
    height: 1.15rem !important;
    width: 1.15rem !important;
  }
  .svg-icon.svg-icon-sm-6 svg {
    height: 1.075rem !important;
    width: 1.075rem !important;
  }
  .svg-icon.svg-icon-sm-7 svg {
    height: 0.95rem !important;
    width: 0.95rem !important;
  }
  .svg-icon.svg-icon-sm-8 svg {
    height: 0.85rem !important;
    width: 0.85rem !important;
  }
  .svg-icon.svg-icon-sm-9 svg {
    height: 0.75rem !important;
    width: 0.75rem !important;
  }
  .svg-icon.svg-icon-sm-10 svg {
    height: 0.5rem !important;
    width: 0.5rem !important;
  }
  .svg-icon.svg-icon-sm-base svg {
    height: 1rem !important;
    width: 1rem !important;
  }
  .svg-icon.svg-icon-sm-fluid svg {
    height: 100% !important;
    width: 100% !important;
  }
  .svg-icon.svg-icon-sm-2x svg {
    height: 2rem !important;
    width: 2rem !important;
  }
  .svg-icon.svg-icon-sm-2qx svg {
    height: 2.25rem !important;
    width: 2.25rem !important;
  }
  .svg-icon.svg-icon-sm-2hx svg {
    height: 2.5rem !important;
    width: 2.5rem !important;
  }
  .svg-icon.svg-icon-sm-2tx svg {
    height: 2.75rem !important;
    width: 2.75rem !important;
  }
  .svg-icon.svg-icon-sm-3x svg {
    height: 3rem !important;
    width: 3rem !important;
  }
  .svg-icon.svg-icon-sm-3qx svg {
    height: 3.25rem !important;
    width: 3.25rem !important;
  }
  .svg-icon.svg-icon-sm-3hx svg {
    height: 3.5rem !important;
    width: 3.5rem !important;
  }
  .svg-icon.svg-icon-sm-3tx svg {
    height: 3.75rem !important;
    width: 3.75rem !important;
  }
  .svg-icon.svg-icon-sm-4x svg {
    height: 4rem !important;
    width: 4rem !important;
  }
  .svg-icon.svg-icon-sm-4qx svg {
    height: 4.25rem !important;
    width: 4.25rem !important;
  }
  .svg-icon.svg-icon-sm-4hx svg {
    height: 4.5rem !important;
    width: 4.5rem !important;
  }
  .svg-icon.svg-icon-sm-4tx svg {
    height: 4.75rem !important;
    width: 4.75rem !important;
  }
  .svg-icon.svg-icon-sm-5x svg {
    height: 5rem !important;
    width: 5rem !important;
  }
  .svg-icon.svg-icon-sm-5qx svg {
    height: 5.25rem !important;
    width: 5.25rem !important;
  }
  .svg-icon.svg-icon-sm-5hx svg {
    height: 5.5rem !important;
    width: 5.5rem !important;
  }
  .svg-icon.svg-icon-sm-5tx svg {
    height: 5.75rem !important;
    width: 5.75rem !important;
  }
}
@media (min-width: 768px) {
  .svg-icon.svg-icon-md-1 svg {
    height: 1.75rem !important;
    width: 1.75rem !important;
  }
  .svg-icon.svg-icon-md-2 svg {
    height: 1.5rem !important;
    width: 1.5rem !important;
  }
  .svg-icon.svg-icon-md-3 svg {
    height: 1.35rem !important;
    width: 1.35rem !important;
  }
  .svg-icon.svg-icon-md-4 svg {
    height: 1.25rem !important;
    width: 1.25rem !important;
  }
  .svg-icon.svg-icon-md-5 svg {
    height: 1.15rem !important;
    width: 1.15rem !important;
  }
  .svg-icon.svg-icon-md-6 svg {
    height: 1.075rem !important;
    width: 1.075rem !important;
  }
  .svg-icon.svg-icon-md-7 svg {
    height: 0.95rem !important;
    width: 0.95rem !important;
  }
  .svg-icon.svg-icon-md-8 svg {
    height: 0.85rem !important;
    width: 0.85rem !important;
  }
  .svg-icon.svg-icon-md-9 svg {
    height: 0.75rem !important;
    width: 0.75rem !important;
  }
  .svg-icon.svg-icon-md-10 svg {
    height: 0.5rem !important;
    width: 0.5rem !important;
  }
  .svg-icon.svg-icon-md-base svg {
    height: 1rem !important;
    width: 1rem !important;
  }
  .svg-icon.svg-icon-md-fluid svg {
    height: 100% !important;
    width: 100% !important;
  }
  .svg-icon.svg-icon-md-2x svg {
    height: 2rem !important;
    width: 2rem !important;
  }
  .svg-icon.svg-icon-md-2qx svg {
    height: 2.25rem !important;
    width: 2.25rem !important;
  }
  .svg-icon.svg-icon-md-2hx svg {
    height: 2.5rem !important;
    width: 2.5rem !important;
  }
  .svg-icon.svg-icon-md-2tx svg {
    height: 2.75rem !important;
    width: 2.75rem !important;
  }
  .svg-icon.svg-icon-md-3x svg {
    height: 3rem !important;
    width: 3rem !important;
  }
  .svg-icon.svg-icon-md-3qx svg {
    height: 3.25rem !important;
    width: 3.25rem !important;
  }
  .svg-icon.svg-icon-md-3hx svg {
    height: 3.5rem !important;
    width: 3.5rem !important;
  }
  .svg-icon.svg-icon-md-3tx svg {
    height: 3.75rem !important;
    width: 3.75rem !important;
  }
  .svg-icon.svg-icon-md-4x svg {
    height: 4rem !important;
    width: 4rem !important;
  }
  .svg-icon.svg-icon-md-4qx svg {
    height: 4.25rem !important;
    width: 4.25rem !important;
  }
  .svg-icon.svg-icon-md-4hx svg {
    height: 4.5rem !important;
    width: 4.5rem !important;
  }
  .svg-icon.svg-icon-md-4tx svg {
    height: 4.75rem !important;
    width: 4.75rem !important;
  }
  .svg-icon.svg-icon-md-5x svg {
    height: 5rem !important;
    width: 5rem !important;
  }
  .svg-icon.svg-icon-md-5qx svg {
    height: 5.25rem !important;
    width: 5.25rem !important;
  }
  .svg-icon.svg-icon-md-5hx svg {
    height: 5.5rem !important;
    width: 5.5rem !important;
  }
  .svg-icon.svg-icon-md-5tx svg {
    height: 5.75rem !important;
    width: 5.75rem !important;
  }
}
@media (min-width: 992px) {
  .svg-icon.svg-icon-lg-1 svg {
    height: 1.75rem !important;
    width: 1.75rem !important;
  }
  .svg-icon.svg-icon-lg-2 svg {
    height: 1.5rem !important;
    width: 1.5rem !important;
  }
  .svg-icon.svg-icon-lg-3 svg {
    height: 1.35rem !important;
    width: 1.35rem !important;
  }
  .svg-icon.svg-icon-lg-4 svg {
    height: 1.25rem !important;
    width: 1.25rem !important;
  }
  .svg-icon.svg-icon-lg-5 svg {
    height: 1.15rem !important;
    width: 1.15rem !important;
  }
  .svg-icon.svg-icon-lg-6 svg {
    height: 1.075rem !important;
    width: 1.075rem !important;
  }
  .svg-icon.svg-icon-lg-7 svg {
    height: 0.95rem !important;
    width: 0.95rem !important;
  }
  .svg-icon.svg-icon-lg-8 svg {
    height: 0.85rem !important;
    width: 0.85rem !important;
  }
  .svg-icon.svg-icon-lg-9 svg {
    height: 0.75rem !important;
    width: 0.75rem !important;
  }
  .svg-icon.svg-icon-lg-10 svg {
    height: 0.5rem !important;
    width: 0.5rem !important;
  }
  .svg-icon.svg-icon-lg-base svg {
    height: 1rem !important;
    width: 1rem !important;
  }
  .svg-icon.svg-icon-lg-fluid svg {
    height: 100% !important;
    width: 100% !important;
  }
  .svg-icon.svg-icon-lg-2x svg {
    height: 2rem !important;
    width: 2rem !important;
  }
  .svg-icon.svg-icon-lg-2qx svg {
    height: 2.25rem !important;
    width: 2.25rem !important;
  }
  .svg-icon.svg-icon-lg-2hx svg {
    height: 2.5rem !important;
    width: 2.5rem !important;
  }
  .svg-icon.svg-icon-lg-2tx svg {
    height: 2.75rem !important;
    width: 2.75rem !important;
  }
  .svg-icon.svg-icon-lg-3x svg {
    height: 3rem !important;
    width: 3rem !important;
  }
  .svg-icon.svg-icon-lg-3qx svg {
    height: 3.25rem !important;
    width: 3.25rem !important;
  }
  .svg-icon.svg-icon-lg-3hx svg {
    height: 3.5rem !important;
    width: 3.5rem !important;
  }
  .svg-icon.svg-icon-lg-3tx svg {
    height: 3.75rem !important;
    width: 3.75rem !important;
  }
  .svg-icon.svg-icon-lg-4x svg {
    height: 4rem !important;
    width: 4rem !important;
  }
  .svg-icon.svg-icon-lg-4qx svg {
    height: 4.25rem !important;
    width: 4.25rem !important;
  }
  .svg-icon.svg-icon-lg-4hx svg {
    height: 4.5rem !important;
    width: 4.5rem !important;
  }
  .svg-icon.svg-icon-lg-4tx svg {
    height: 4.75rem !important;
    width: 4.75rem !important;
  }
  .svg-icon.svg-icon-lg-5x svg {
    height: 5rem !important;
    width: 5rem !important;
  }
  .svg-icon.svg-icon-lg-5qx svg {
    height: 5.25rem !important;
    width: 5.25rem !important;
  }
  .svg-icon.svg-icon-lg-5hx svg {
    height: 5.5rem !important;
    width: 5.5rem !important;
  }
  .svg-icon.svg-icon-lg-5tx svg {
    height: 5.75rem !important;
    width: 5.75rem !important;
  }
}
@media (min-width: 1200px) {
  .svg-icon.svg-icon-xl-1 svg {
    height: 1.75rem !important;
    width: 1.75rem !important;
  }
  .svg-icon.svg-icon-xl-2 svg {
    height: 1.5rem !important;
    width: 1.5rem !important;
  }
  .svg-icon.svg-icon-xl-3 svg {
    height: 1.35rem !important;
    width: 1.35rem !important;
  }
  .svg-icon.svg-icon-xl-4 svg {
    height: 1.25rem !important;
    width: 1.25rem !important;
  }
  .svg-icon.svg-icon-xl-5 svg {
    height: 1.15rem !important;
    width: 1.15rem !important;
  }
  .svg-icon.svg-icon-xl-6 svg {
    height: 1.075rem !important;
    width: 1.075rem !important;
  }
  .svg-icon.svg-icon-xl-7 svg {
    height: 0.95rem !important;
    width: 0.95rem !important;
  }
  .svg-icon.svg-icon-xl-8 svg {
    height: 0.85rem !important;
    width: 0.85rem !important;
  }
  .svg-icon.svg-icon-xl-9 svg {
    height: 0.75rem !important;
    width: 0.75rem !important;
  }
  .svg-icon.svg-icon-xl-10 svg {
    height: 0.5rem !important;
    width: 0.5rem !important;
  }
  .svg-icon.svg-icon-xl-base svg {
    height: 1rem !important;
    width: 1rem !important;
  }
  .svg-icon.svg-icon-xl-fluid svg {
    height: 100% !important;
    width: 100% !important;
  }
  .svg-icon.svg-icon-xl-2x svg {
    height: 2rem !important;
    width: 2rem !important;
  }
  .svg-icon.svg-icon-xl-2qx svg {
    height: 2.25rem !important;
    width: 2.25rem !important;
  }
  .svg-icon.svg-icon-xl-2hx svg {
    height: 2.5rem !important;
    width: 2.5rem !important;
  }
  .svg-icon.svg-icon-xl-2tx svg {
    height: 2.75rem !important;
    width: 2.75rem !important;
  }
  .svg-icon.svg-icon-xl-3x svg {
    height: 3rem !important;
    width: 3rem !important;
  }
  .svg-icon.svg-icon-xl-3qx svg {
    height: 3.25rem !important;
    width: 3.25rem !important;
  }
  .svg-icon.svg-icon-xl-3hx svg {
    height: 3.5rem !important;
    width: 3.5rem !important;
  }
  .svg-icon.svg-icon-xl-3tx svg {
    height: 3.75rem !important;
    width: 3.75rem !important;
  }
  .svg-icon.svg-icon-xl-4x svg {
    height: 4rem !important;
    width: 4rem !important;
  }
  .svg-icon.svg-icon-xl-4qx svg {
    height: 4.25rem !important;
    width: 4.25rem !important;
  }
  .svg-icon.svg-icon-xl-4hx svg {
    height: 4.5rem !important;
    width: 4.5rem !important;
  }
  .svg-icon.svg-icon-xl-4tx svg {
    height: 4.75rem !important;
    width: 4.75rem !important;
  }
  .svg-icon.svg-icon-xl-5x svg {
    height: 5rem !important;
    width: 5rem !important;
  }
  .svg-icon.svg-icon-xl-5qx svg {
    height: 5.25rem !important;
    width: 5.25rem !important;
  }
  .svg-icon.svg-icon-xl-5hx svg {
    height: 5.5rem !important;
    width: 5.5rem !important;
  }
  .svg-icon.svg-icon-xl-5tx svg {
    height: 5.75rem !important;
    width: 5.75rem !important;
  }
}
@media (min-width: 1400px) {
  .svg-icon.svg-icon-xxl-1 svg {
    height: 1.75rem !important;
    width: 1.75rem !important;
  }
  .svg-icon.svg-icon-xxl-2 svg {
    height: 1.5rem !important;
    width: 1.5rem !important;
  }
  .svg-icon.svg-icon-xxl-3 svg {
    height: 1.35rem !important;
    width: 1.35rem !important;
  }
  .svg-icon.svg-icon-xxl-4 svg {
    height: 1.25rem !important;
    width: 1.25rem !important;
  }
  .svg-icon.svg-icon-xxl-5 svg {
    height: 1.15rem !important;
    width: 1.15rem !important;
  }
  .svg-icon.svg-icon-xxl-6 svg {
    height: 1.075rem !important;
    width: 1.075rem !important;
  }
  .svg-icon.svg-icon-xxl-7 svg {
    height: 0.95rem !important;
    width: 0.95rem !important;
  }
  .svg-icon.svg-icon-xxl-8 svg {
    height: 0.85rem !important;
    width: 0.85rem !important;
  }
  .svg-icon.svg-icon-xxl-9 svg {
    height: 0.75rem !important;
    width: 0.75rem !important;
  }
  .svg-icon.svg-icon-xxl-10 svg {
    height: 0.5rem !important;
    width: 0.5rem !important;
  }
  .svg-icon.svg-icon-xxl-base svg {
    height: 1rem !important;
    width: 1rem !important;
  }
  .svg-icon.svg-icon-xxl-fluid svg {
    height: 100% !important;
    width: 100% !important;
  }
  .svg-icon.svg-icon-xxl-2x svg {
    height: 2rem !important;
    width: 2rem !important;
  }
  .svg-icon.svg-icon-xxl-2qx svg {
    height: 2.25rem !important;
    width: 2.25rem !important;
  }
  .svg-icon.svg-icon-xxl-2hx svg {
    height: 2.5rem !important;
    width: 2.5rem !important;
  }
  .svg-icon.svg-icon-xxl-2tx svg {
    height: 2.75rem !important;
    width: 2.75rem !important;
  }
  .svg-icon.svg-icon-xxl-3x svg {
    height: 3rem !important;
    width: 3rem !important;
  }
  .svg-icon.svg-icon-xxl-3qx svg {
    height: 3.25rem !important;
    width: 3.25rem !important;
  }
  .svg-icon.svg-icon-xxl-3hx svg {
    height: 3.5rem !important;
    width: 3.5rem !important;
  }
  .svg-icon.svg-icon-xxl-3tx svg {
    height: 3.75rem !important;
    width: 3.75rem !important;
  }
  .svg-icon.svg-icon-xxl-4x svg {
    height: 4rem !important;
    width: 4rem !important;
  }
  .svg-icon.svg-icon-xxl-4qx svg {
    height: 4.25rem !important;
    width: 4.25rem !important;
  }
  .svg-icon.svg-icon-xxl-4hx svg {
    height: 4.5rem !important;
    width: 4.5rem !important;
  }
  .svg-icon.svg-icon-xxl-4tx svg {
    height: 4.75rem !important;
    width: 4.75rem !important;
  }
  .svg-icon.svg-icon-xxl-5x svg {
    height: 5rem !important;
    width: 5rem !important;
  }
  .svg-icon.svg-icon-xxl-5qx svg {
    height: 5.25rem !important;
    width: 5.25rem !important;
  }
  .svg-icon.svg-icon-xxl-5hx svg {
    height: 5.5rem !important;
    width: 5.5rem !important;
  }
  .svg-icon.svg-icon-xxl-5tx svg {
    height: 5.75rem !important;
    width: 5.75rem !important;
  }
}

.timeline .timeline-item {
  position: relative;
  padding: 0;
  margin: 0;
  display: flex;
  align-items: flex-start;
}
.timeline .timeline-item:last-child .timeline-line {
  bottom: 100%;
}
.timeline .timeline-line {
  display: block;
  content: " ";
  justify-content: center;
  position: absolute;
  z-index: 0;
  left: 0;
  top: 0;
  bottom: 0;
  transform: translate(50%);
  border-left-width: 1px;
  border-left-style: dashed;
  border-left-color: #E4E6EF;
}
.timeline .timeline-icon {
  z-index: 1;
  flex-shrink: 0;
  margin-right: 1rem;
}
.timeline .timeline-content {
  width: 100%;
  overflow: auto;
  margin-bottom: 1.5rem;
}
.timeline.timeline-center .timeline-item {
  align-items: center;
}
.timeline.timeline-center .timeline-item:first-child .timeline-line {
  top: 50%;
}
.timeline.timeline-center .timeline-item:last-child .timeline-line {
  bottom: 50%;
}

.overlay {
  position: relative;
}
.overlay .overlay-layer {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.05);
  transition: all 0.3s ease;
  opacity: 0;
}
.overlay.overlay-show .overlay-layer, .overlay.overlay-block .overlay-layer, .overlay:hover .overlay-layer {
  transition: all 0.3s ease;
  opacity: 1;
}
.overlay.overlay-block {
  cursor: wait;
}

.bullet {
  display: inline-block;
  background-color: #B5B5C3;
  border-radius: 6px;
  width: 8px;
  height: 4px;
  flex-shrink: 0;
}

.bullet-dot {
  width: 4px;
  height: 4px;
  border-radius: 100% !important;
}

.bullet-vertical {
  width: 4px;
  height: 8px;
}

.bullet-line {
  width: 5px;
  height: 1px;
  border-radius: none;
}

.drawer {
  display: flex !important;
  overflow: auto;
  z-index: 110;
  position: fixed;
  top: 0;
  bottom: 0;
  background-color: #ffffff;
  transition: transform 0.3s ease-in-out;
}
.drawer.drawer-start {
  left: 0;
  transform: translateX(-100%);
}
.drawer.drawer-end {
  right: 0;
  transform: translateX(100%);
}
.drawer.drawer-on {
  transform: none;
  box-shadow: 0px 1px 9px -3px rgba(0, 0, 0, 0.05);
  transition: transform 0.3s ease-in-out;
}

.drawer-overlay {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  overflow: hidden;
  z-index: 109;
  background-color: rgba(0, 0, 0, 0.1);
  animation: animation-drawer-fade-in 0.3s ease-in-out 1;
}

[data-kt-drawer=true] {
  display: none;
}

@keyframes animation-drawer-fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@media (max-width: 991.98px) {
  body[data-kt-drawer=on] {
    overflow: hidden;
  }
}
.badge.badge-circle, .badge.badge-square {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 1.75rem;
  min-width: 1.75rem;
  padding: 0 0.1rem;
}
.badge.badge-circle {
  border-radius: 50%;
  padding: 0;
  min-width: unset;
  width: 1.75rem;
}

.badge-white {
  color: #7E8299;
  background-color: #ffffff;
}

.badge-light-white {
  color: #ffffff;
}

.badge-light {
  color: #7E8299;
  background-color: #F5F8FA;
}

.badge-light-light {
  color: #F5F8FA;
}

.badge-primary {
  color: #FFFFFF;
  background-color: #009EF7;
}

.badge-light-primary {
  color: #009EF7;
  background-color: #ECF8FF;
}

.badge-secondary {
  color: #3F4254;
  background-color: #E4E6EF;
}

.badge-light-secondary {
  color: #E4E6EF;
}

.badge-success {
  color: #FFFFFF;
  background-color: #50CD89;
}

.badge-light-success {
  color: #50CD89;
  background-color: #E8FFF3;
}

.badge-info {
  color: #FFFFFF;
  background-color: #7239EA;
}

.badge-light-info {
  color: #7239EA;
  background-color: #F8F5FF;
}

.badge-warning {
  color: #FFFFFF;
  background-color: #FFC700;
}

.badge-light-warning {
  color: #FFC700;
  background-color: #FFF8DD;
}

.badge-danger {
  color: #FFFFFF;
  background-color: #F1416C;
}

.badge-light-danger {
  color: #F1416C;
  background-color: #FFF5F8;
}

.badge-dark {
  color: #ffffff;
  background-color: #181C32;
}

.badge-light-dark {
  color: #181C32;
  background-color: #EFF2F5;
}

.indicator-progress {
  display: none;
}
[data-kt-indicator=on] > .indicator-progress {
  display: inline-block;
}

[data-kt-indicator=on] > .indicator-label {
  display: none;
}

.rotate {
  display: inline-flex;
  align-items: center;
}

.rotate-90 {
  transition: transform 0.3s ease;
  -webkit-backface-visibility: hidden;
}
.collapsible:not(.collapsed) > .rotate-90, .active > .rotate-90 {
  transform: rotateZ(90deg) /*rtl:ignore*/;
  transition: transform 0.3s ease;
}
[direction=rtl] .collapsible:not(.collapsed) > .rotate-90, [direction=rtl] .active > .rotate-90 {
  transform: rotateZ(-90deg) /*rtl:ignore*/;
}

.rotate-n90 {
  transition: transform 0.3s ease;
  -webkit-backface-visibility: hidden;
}
.collapsible:not(.collapsed) > .rotate-n90, .active > .rotate-n90 {
  transform: rotateZ(-90deg) /*rtl:ignore*/;
  transition: transform 0.3s ease;
}
[direction=rtl] .collapsible:not(.collapsed) > .rotate-n90, [direction=rtl] .active > .rotate-n90 {
  transform: rotateZ(90deg) /*rtl:ignore*/;
}

.rotate-180 {
  transition: transform 0.3s ease;
  -webkit-backface-visibility: hidden;
}
.collapsible:not(.collapsed) > .rotate-180, .active > .rotate-180 {
  transform: rotateZ(180deg) /*rtl:ignore*/;
  transition: transform 0.3s ease;
}
[direction=rtl] .collapsible:not(.collapsed) > .rotate-180, [direction=rtl] .active > .rotate-180 {
  transform: rotateZ(-180deg) /*rtl:ignore*/;
}

.rotate-n180 {
  transition: transform 0.3s ease;
  -webkit-backface-visibility: hidden;
}
.collapsible:not(.collapsed) > .rotate-n180, .active > .rotate-n180 {
  transform: rotateZ(-180deg) /*rtl:ignore*/;
  transition: transform 0.3s ease;
}
[direction=rtl] .collapsible:not(.collapsed) > .rotate-n180, [direction=rtl] .active > .rotate-n180 {
  transform: rotateZ(180deg) /*rtl:ignore*/;
}

.rotate-270 {
  transition: transform 0.3s ease;
  -webkit-backface-visibility: hidden;
}
.collapsible:not(.collapsed) > .rotate-270, .active > .rotate-270 {
  transform: rotateZ(270deg) /*rtl:ignore*/;
  transition: transform 0.3s ease;
}
[direction=rtl] .collapsible:not(.collapsed) > .rotate-270, [direction=rtl] .active > .rotate-270 {
  transform: rotateZ(-270deg) /*rtl:ignore*/;
}

.rotate-n270 {
  transition: transform 0.3s ease;
  -webkit-backface-visibility: hidden;
}
.collapsible:not(.collapsed) > .rotate-n270, .active > .rotate-n270 {
  transform: rotateZ(-270deg) /*rtl:ignore*/;
  transition: transform 0.3s ease;
}
[direction=rtl] .collapsible:not(.collapsed) > .rotate-n270, [direction=rtl] .active > .rotate-n270 {
  transform: rotateZ(270deg) /*rtl:ignore*/;
}

@media (min-width: 992px) {
  span,
ol,
ul,
pre,
div {
    scrollbar-width: thin;
    scrollbar-color: #EFF2F5 transparent;
  }
  span::-webkit-scrollbar,
ol::-webkit-scrollbar,
ul::-webkit-scrollbar,
pre::-webkit-scrollbar,
div::-webkit-scrollbar {
    width: 0.4rem;
    height: 0.4rem;
  }
  span::-webkit-scrollbar-thumb,
ol::-webkit-scrollbar-thumb,
ul::-webkit-scrollbar-thumb,
pre::-webkit-scrollbar-thumb,
div::-webkit-scrollbar-thumb {
    background-color: #EFF2F5;
  }
}
.scroll {
  overflow: scroll;
  position: relative;
}
@media (max-width: 991.98px) {
  .scroll {
    overflow: auto;
  }
}

.scroll-x {
  overflow-x: scroll;
  position: relative;
}
@media (max-width: 991.98px) {
  .scroll-x {
    overflow-x: auto;
  }
}

.scroll-y {
  overflow-y: scroll;
  position: relative;
}
@media (max-width: 991.98px) {
  .scroll-y {
    overflow-y: auto;
  }
}

.hover-scroll {
  position: relative;
}
@media (min-width: 992px) {
  .hover-scroll {
    overflow: hidden;
    border-right: 0.4rem solid transparent;
    border-bottom: 0.4rem solid transparent;
    margin-right: -0.4rem;
    margin-bottom: -0.4rem;
  }
  .hover-scroll:hover {
    overflow: scroll;
    border-right: 0;
    border-bottom: 0;
  }
  @-moz-document url-prefix() {
    .hover-scroll {
      overflow: scroll;
      position: relative;
      border-right: 0;
      border-bottom: 0;
    }
  }
}
@media (max-width: 991.98px) {
  .hover-scroll {
    overflow: auto;
  }
}

.hover-scroll-y {
  position: relative;
}
@media (min-width: 992px) {
  .hover-scroll-y {
    overflow-y: hidden;
    border-right: 0.4rem solid transparent;
    margin-right: -0.4rem;
  }
  .hover-scroll-y:hover {
    overflow-y: scroll;
    border-right: 0;
  }
  @-moz-document url-prefix() {
    .hover-scroll-y {
      overflow-y: scroll;
      position: relative;
      border-right: 0;
    }
  }
}
@media (max-width: 991.98px) {
  .hover-scroll-y {
    overflow-y: auto;
  }
}

.hover-scroll-x {
  position: relative;
}
@media (min-width: 992px) {
  .hover-scroll-x {
    overflow-x: hidden;
    border-bottom: 0.4rem solid transparent;
  }
  .hover-scroll-x:hover {
    overflow-x: scroll;
    border-bottom: 0;
  }
  @-moz-document url-prefix() {
    .hover-scroll-x {
      overflow-x: scroll;
      position: relative;
      border-bottom: 0;
    }
  }
}
@media (max-width: 991.98px) {
  .hover-scroll-x {
    overflow-x: auto;
  }
}

.hover-scroll-overlay-y {
  overflow-y: hidden;
  position: relative;
  --scrollbar-space: 0.25rem;
}
.hover-scroll-overlay-y::-webkit-scrollbar {
  width: calc(0.4rem + var(--scrollbar-space));
}
.hover-scroll-overlay-y::-webkit-scrollbar-thumb {
  background-clip: content-box;
  border-right: var(--scrollbar-space) solid transparent;
}
.hover-scroll-overlay-y:hover {
  overflow-y: overlay;
}
@-moz-document url-prefix() {
  .hover-scroll-overlay-y {
    overflow-y: scroll;
    position: relative;
  }
}

.rating {
  display: flex;
  align-items: center;
}

.rating-input {
  position: absolute !important;
  left: -9999px !important;
}
.rating-input[disabled] {
  display: none;
}

.rating-label {
  padding: 0;
  margin: 0;
}
.rating-label > i {
  line-height: 1;
  color: #B5B5C3;
}
.rating-label > .svg-icon {
  line-height: 1;
}
.rating-label > .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #B5B5C3;
}
.rating-label > .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}

label.rating-label {
  cursor: pointer;
}

div.rating-label.checked > i,
label.rating-label > i {
  color: #FFAD0F;
}
div.rating-label.checked > .svg-icon svg [fill]:not(.permanent):not(g),
label.rating-label > .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #FFAD0F;
}
div.rating-label.checked > .svg-icon svg:hover [fill]:not(.permanent):not(g),
label.rating-label > .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}

.rating-input:checked ~ .rating-label > i {
  color: #B5B5C3;
}

.rating-input:checked ~ .rating-label > .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #B5B5C3;
}
.rating-input:checked ~ .rating-label > .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}

.rating:hover label.rating-label > i {
  color: #FFAD0F;
}

.rating:hover label.rating-label > .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #FFAD0F;
}
.rating:hover label.rating-label > .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}

label.rating-label:hover ~ .rating-label > i {
  color: #B5B5C3;
}

label.rating-label:hover ~ .rating-label > .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #B5B5C3;
}
label.rating-label:hover ~ .rating-label > .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}

.stepper [data-kt-stepper-element=info],
.stepper [data-kt-stepper-element=content] {
  display: none;
}
.stepper [data-kt-stepper-element=info].current,
.stepper [data-kt-stepper-element=content].current {
  display: flex;
}
.stepper .stepper-item[data-kt-stepper-action=step] {
  cursor: pointer;
}
.stepper [data-kt-stepper-action=previous] {
  display: none;
}
.stepper [data-kt-stepper-action=next] {
  display: inline-block;
}
.stepper [data-kt-stepper-action=submit] {
  display: none;
}
.stepper.first [data-kt-stepper-action=previous] {
  display: none;
}
.stepper.first [data-kt-stepper-action=next] {
  display: inline-block;
}
.stepper.first [data-kt-stepper-action=submit] {
  display: none;
}
.stepper.between [data-kt-stepper-action=previous] {
  display: inline-block;
}
.stepper.between [data-kt-stepper-action=next] {
  display: inline-block;
}
.stepper.between [data-kt-stepper-action=submit] {
  display: none;
}
.stepper.last [data-kt-stepper-action=previous] {
  display: inline-block;
}
.stepper.last [data-kt-stepper-action=next] {
  display: none;
}
.stepper.last [data-kt-stepper-action=submit] {
  display: inline-block;
}

.stepper.stepper-pills .stepper-nav {
  display: flex;
}
.stepper.stepper-pills .stepper-item {
  display: flex;
  align-items: center;
  transition: color 0.2s ease, background-color 0.2s ease;
}
.stepper.stepper-pills .stepper-item .stepper-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  transition: color 0.2s ease, background-color 0.2s ease;
  width: 40px;
  height: 40px;
  border-radius: 0.475rem;
  background-color: #ECF8FF;
  margin-right: 1.5rem;
}
.stepper.stepper-pills .stepper-item .stepper-icon .stepper-check {
  display: none;
  font-size: 1rem;
}
.stepper.stepper-pills .stepper-item .stepper-icon .stepper-number {
  font-weight: 600;
  color: #009EF7 !important;
  font-size: 1.25rem;
}
.stepper.stepper-pills .stepper-item .stepper-label {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.stepper.stepper-pills .stepper-item .stepper-label .stepper-title {
  color: #3F4254;
  font-weight: 600;
  font-size: 1.25rem;
  margin-bottom: 0.3rem;
}
.stepper.stepper-pills .stepper-item .stepper-label .stepper-desc {
  color: #A1A5B7;
}
.stepper.stepper-pills .stepper-item.current {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.stepper.stepper-pills .stepper-item.current .stepper-icon {
  transition: color 0.2s ease, background-color 0.2s ease;
  background-color: #009EF7;
}
.stepper.stepper-pills .stepper-item.current .stepper-icon .stepper-check {
  color: #009EF7;
  display: none;
}
.stepper.stepper-pills .stepper-item.current .stepper-icon .stepper-number {
  color: #ffffff !important;
  font-size: 1.35rem;
}
.stepper.stepper-pills .stepper-item.current .stepper-label .stepper-title {
  color: #7E8299;
}
.stepper.stepper-pills .stepper-item.current .stepper-label .stepper-desc {
  color: #B5B5C3;
}
.stepper.stepper-pills .stepper-item.current:last-child .stepper-icon, .stepper.stepper-pills .stepper-item.completed .stepper-icon {
  transition: color 0.2s ease, background-color 0.2s ease;
  background-color: #ECF8FF;
}
.stepper.stepper-pills .stepper-item.current:last-child .stepper-icon .stepper-check, .stepper.stepper-pills .stepper-item.completed .stepper-icon .stepper-check {
  color: #009EF7 !important;
  display: inline-block;
}
.stepper.stepper-pills .stepper-item.current:last-child .stepper-icon .stepper-number, .stepper.stepper-pills .stepper-item.completed .stepper-icon .stepper-number {
  display: none;
}
.stepper.stepper-pills .stepper-item.current:last-child .stepper-label .stepper-title, .stepper.stepper-pills .stepper-item.completed .stepper-label .stepper-title {
  color: #A1A5B7;
}
.stepper.stepper-pills .stepper-item.current:last-child .stepper-label .stepper-desc, .stepper.stepper-pills .stepper-item.completed .stepper-label .stepper-desc {
  color: #B5B5C3;
}
.stepper.stepper-pills.stepper-column .stepper-nav {
  flex-direction: column;
}
.stepper.stepper-pills.stepper-column .stepper-item {
  position: relative;
  padding: 0;
  margin: 0;
  padding-bottom: 2.5rem;
}
.stepper.stepper-pills.stepper-column .stepper-icon {
  z-index: 1;
}
.stepper.stepper-pills.stepper-column .stepper-line {
  display: block;
  content: " ";
  justify-content: center;
  position: absolute;
  z-index: 0;
  left: 0;
  top: 0;
  bottom: 0;
  width: 40px;
  transform: translate(50%);
  border-left-width: 1px;
  border-left-style: dashed;
  border-left-color: #E4E6EF;
}
.stepper.stepper-pills.stepper-column .stepper-item:first-child .stepper-line {
  top: 50%;
}
.stepper.stepper-pills.stepper-column .stepper-item:last-child .stepper-line {
  bottom: 50%;
}

.toggle.collapsible:not(.collapsed) .toggle-off, .toggle.active .toggle-off {
  display: none;
}
.toggle.collapsible.collapsed .toggle-on, .toggle:not(.collapsible):not(.active) .toggle-on {
  display: none;
}

.stepper.stepper-links .stepper-nav {
  display: flex;
  margin: 0 auto;
  padding-top: 4.25rem;
  overflow: auto;
}
.stepper.stepper-links .stepper-nav .stepper-item {
  margin-right: 3rem;
  position: relative;
  flex-shrink: 0;
}
.stepper.stepper-links .stepper-nav .stepper-item:last-child {
  margin-right: 0;
}
.stepper.stepper-links .stepper-nav .stepper-item:after {
  content: " ";
  position: absolute;
  top: 2.3rem;
  left: 0;
  height: 2px;
  width: 100%;
  background-color: transparent;
  transition: color 0.2s ease, background-color 0.2s ease;
}
.stepper.stepper-links .stepper-nav .stepper-item .stepper-title {
  color: #181C32;
  font-weight: 600;
  font-size: 1.25rem;
}
.stepper.stepper-links .stepper-nav .stepper-item.current {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.stepper.stepper-links .stepper-nav .stepper-item.current .stepper-title {
  color: #009EF7;
}
.stepper.stepper-links .stepper-nav .stepper-item.current:after {
  background-color: #009EF7;
}
.stepper.stepper-links .stepper-nav .stepper-item.completed .stepper-title {
  color: #B5B5C3;
}

.timeline-label {
  position: relative;
}
.timeline-label:before {
  content: "";
  position: absolute;
  left: 51px;
  width: 3px;
  top: 0;
  bottom: 0;
  background-color: #EFF2F5;
}
.timeline-label .timeline-item {
  display: flex;
  align-items: flex-start;
  position: relative;
  margin-bottom: 1.7rem;
}
.timeline-label .timeline-item:last-child {
  margin-bottom: 0;
}
.timeline-label .timeline-label {
  width: 50px;
  flex-shrink: 0;
  position: relative;
  color: #3F4254;
}
.timeline-label .timeline-badge {
  flex-shrink: 0;
  background: #ffffff;
  width: 1rem;
  height: 1rem;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  position: relative;
  margin-top: 1px;
  margin-left: -0.5rem;
  padding: 3px !important;
  border: 6px solid #ffffff !important;
}
.timeline-label .timeline-badge span {
  display: block;
  border-radius: 100%;
  width: 6px;
  height: 6px;
  background-color: #EFF2F5;
}
.timeline-label .timeline-content {
  flex-grow: 1;
}

.placeholder-white-opacity-75::placeholder {
  color: rgba(255, 255, 255, 0.75);
}
.placeholder-white-opacity-75::-moz-placeholder {
  color: rgba(255, 255, 255, 0.75);
  opacity: 1;
}

.item-border-hover {
  border: 1px solid #fff;
  border-radius: 6px;
}
.item-border-hover:hover {
  border: 1px solid #EFF2F5;
  cursor: pointer;
  transition: color 0.2s ease, background-color 0.2s ease;
  background-color: rgba(245, 248, 250, 0.3);
}
.item-border-hover:hover .label {
  background-color: #00A3FF !important;
  transition: color 0.2s ease, background-color 0.2s ease;
  color: #fff;
}
.item-border-hover .label {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
}

.noUi-target.noUi-target-success .noUi-connects {
  background: #E8FFF3;
}
.noUi-target.noUi-target-success .noUi-connects .noUi-connect {
  background: #50CD89;
}
.noUi-target.noUi-target-success .noUi-handle {
  border: 1px solid #50CD89;
  box-shadow: 0 3px 6px -3px rgba(80, 205, 137, 0.7);
  background: #50CD89;
}

html {
  font-family: sans-serif;
  text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

html,
body {
  height: 100%;
  margin: 0px;
  padding: 0px;
  font-size: 13px !important;
  font-weight: 400;
  font-family: Poppins, Helvetica, "sans-serif";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
@media (max-width: 991.98px) {
  html,
body {
    font-size: 13px !important;
  }
}
@media (max-width: 767.98px) {
  html,
body {
    font-size: 12px !important;
  }
}
html a:hover,
html a:active,
html a:focus,
body a:hover,
body a:active,
body a:focus {
  text-decoration: none !important;
}

body {
  display: flex;
  flex-direction: column;
  color: #181C32;
}

router-outlet {
  display: none;
}

canvas {
  user-select: none;
}

@media print {
  .print-content-only {
    padding: 0 !important;
    background: none !important;
  }
  .print-content-only .wrapper,
.print-content-only .page,
.print-content-only .content,
.print-content-only .container,
.print-content-only .container-fluid,
.print-content-only .container-sm,
.print-content-only .container-md,
.print-content-only .container-lg,
.print-content-only .container-xl,
.print-content-only .container-xxl {
    background: none !important;
    padding: 0 !important;
    margin: 0 !important;
  }
  .print-content-only .aside,
.print-content-only .sidebar,
.print-content-only .sticky-toolbar,
.print-content-only .scrolltop,
.print-content-only .header,
.print-content-only .footer,
.print-content-only .toolbar,
.print-content-only .offcanvas,
.print-content-only .btn {
    display: none !important;
  }
}
@media (min-width: 992px) {
  .container,
.container-fluid,
.container-sm,
.container-md,
.container-lg,
.container-xl,
.container-xxl {
    padding: 0 30px;
  }

  .wrapper {
    transition: padding-left 0.3s ease, margin-right 0.3s ease;
  }
  .aside-enabled.aside-fixed .wrapper {
    transition: padding-left 0.3s ease;
    padding-left: 265px;
  }
  .aside-enabled.aside-fixed[data-kt-aside-minimize=on] .wrapper {
    transition: padding-left 0.3s ease;
    padding-left: 75px;
  }
  .header-fixed .wrapper {
    padding-top: 65px;
  }
  .header-fixed.toolbar-fixed .wrapper {
    padding-top: calc(65px + var(--kt-toolbar-height));
  }
}
@media (max-width: 991.98px) {
  .container,
.container-fluid,
.container-sm,
.container-md,
.container-lg,
.container-xl,
.container-xxl {
    max-width: none;
    padding: 0 15px;
  }

  .header-tablet-and-mobile-fixed .wrapper {
    padding-top: 55px;
  }
  .header-tablet-and-mobile-fixed.toolbar-tablet-and-mobile-fixed .wrapper {
    padding-top: calc(55px + var(--kt-toolbar-height-tablet-and-mobile));
  }
}
@media (min-width: 992px) {
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    z-index: 2;
    height: 65px;
    transition: left 0.3s ease;
  }
  .header-fixed .header {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 100;
    background-color: #ffffff;
    box-shadow: 0px 10px 30px 0px rgba(82, 63, 105, 0.05);
    height: 65px;
    padding: 0;
  }
  .header-fixed.toolbar-fixed .header {
    box-shadow: none;
  }
  .aside-enabled.aside-fixed.header-fixed .header {
    left: 265px;
    transition: left 0.3s ease;
  }
  .aside-enabled.aside-fixed.header-fixed[data-kt-aside-minimize=on] .header {
    left: 75px;
    transition: left 0.3s ease;
  }
  .header .header-menu {
    display: flex;
  }
}
@media (max-width: 991.98px) {
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 55px;
    position: relative;
    z-index: 3;
  }
  .header-tablet-and-mobile-fixed .header {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 100;
    height: 55px;
    min-height: 55px;
    background-color: #ffffff;
    box-shadow: 0px 10px 30px 0px rgba(82, 63, 105, 0.05);
  }
  .header-tablet-and-mobile-fixed.toolbar-tablet-and-mobile-fixed .header {
    box-shadow: none;
  }
  .header .header-menu {
    display: none;
    z-index: 99;
  }
}
@media (min-width: 992px) {
  .toolbar {
    display: flex;
    align-items: center;
  }
  .toolbar-fixed .toolbar {
    height: var(--kt-toolbar-height);
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 99;
    background-color: #ffffff;
    box-shadow: 0px 10px 30px 0px rgba(82, 63, 105, 0.05);
  }
  .toolbar-enabled:not(.toolbar-fixed) .toolbar {
    padding-bottom: 30px;
  }
  .header-fixed.toolbar-fixed .toolbar {
    top: 65px;
    border-top: 1px solid #EFF2F5;
  }
  .aside-enabled.aside-fixed.toolbar-fixed .toolbar {
    left: 265px;
    transition: left 0.3s ease;
  }
  .aside-enabled.aside-fixed.toolbar-fixed[data-kt-aside-minimize=on] .toolbar {
    left: 75px;
    transition: left 0.3s ease;
  }
}
@media (max-width: 991.98px) {
  .toolbar {
    display: flex;
    align-items: center;
  }
  .toolbar-tablet-and-mobile-fixed .toolbar {
    height: var(--kt-toolbar-height-tablet-and-mobile);
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 99;
    background-color: #ffffff;
    box-shadow: 0px 10px 30px 0px rgba(82, 63, 105, 0.05);
  }
  .toolbar-enabled:not(.toolbar-tablet-and-mobile-fixed) .toolbar {
    padding-bottom: 15px;
  }
  .header-tablet-and-mobile-fixed.toolbar-tablet-and-mobile-fixed .toolbar {
    top: 55px;
    border-top: 1px solid #EFF2F5;
  }
}
.aside {
  display: flex;
  flex-direction: column;
  box-shadow: 0 0 28px 0 rgba(82, 63, 105, 0.05);
  padding: 0;
}
.aside.aside-dark {
  background-color: #1e1e2d;
}
.aside.aside-dark .aside-logo {
  background-color: #181824;
}
.aside.aside-dark .aside-toggle svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #494b74;
}
.aside.aside-dark .aside-toggle svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.aside.aside-dark .separator {
  border-bottom-color: #282a3d;
}
.aside.aside-light {
  background-color: #ffffff;
}
.aside.aside-light .separator {
  border-bottom-color: rgba(245, 248, 250, 0.5);
}

@media (min-width: 992px) {
  .aside {
    width: 265px;
    transition: width 0.3s ease;
  }
  .aside .aside-logo {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 65px;
    padding: 0 25px;
  }
  .aside .aside-menu {
    width: 265px;
  }
  .aside-fixed .aside {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    z-index: 101;
    overflow: hidden;
  }
  .aside-static .aside {
    position: relative;
    z-index: 1;
  }
  [data-kt-aside-minimize=on] .aside {
    width: 75px;
    transition: width 0.3s ease;
  }
  [data-kt-aside-minimize=on] .aside .aside-logo .logo-default {
    display: inline-block;
  }
  [data-kt-aside-minimize=on] .aside .aside-logo .logo-minimize {
    display: none;
  }
  [data-kt-aside-minimize=on] .aside.aside-hoverable:hover:not(.animating) {
    transition: width 0.3s ease;
    width: 265px;
    box-shadow: 5px 0px 10px rgba(70, 78, 95, 0.075);
  }
  [data-kt-aside-minimize=on] .aside:not(.aside-hoverable) .aside-logo, [data-kt-aside-minimize=on] .aside:not(:hover) .aside-logo {
    justify-content: center;
  }
  [data-kt-aside-minimize=on] .aside:not(.aside-hoverable) .aside-logo .logo, [data-kt-aside-minimize=on] .aside:not(:hover) .aside-logo .logo {
    display: none;
  }
  [data-kt-aside-minimize=on] .aside:not(.aside-hoverable) .aside-logo .logo-default, [data-kt-aside-minimize=on] .aside:not(:hover) .aside-logo .logo-default {
    display: none;
  }
  [data-kt-aside-minimize=on] .aside:not(.aside-hoverable) .aside-logo .logo-minimize, [data-kt-aside-minimize=on] .aside:not(:hover) .aside-logo .logo-minimize {
    display: inline-block;
  }
  [data-kt-aside-minimize=on] .aside:not(.aside-hoverable) .aside-menu .menu-content,
[data-kt-aside-minimize=on] .aside:not(.aside-hoverable) .aside-menu .menu-title, [data-kt-aside-minimize=on] .aside:not(:hover) .aside-menu .menu-content,
[data-kt-aside-minimize=on] .aside:not(:hover) .aside-menu .menu-title {
    opacity: 0;
    transition: opacity 0.3s ease;
  }
  [data-kt-aside-minimize=on] .aside:not(.aside-hoverable) .aside-menu .menu-item.show > .menu-sub, [data-kt-aside-minimize=on] .aside:not(:hover) .aside-menu .menu-item.show > .menu-sub {
    height: 0;
    overflow: hidden;
    transition: height 0.3s ease;
  }
}
@media (max-width: 991.98px) {
  .aside {
    display: none;
  }
  .aside .aside-logo {
    display: none;
  }
}
.aside-menu .menu .menu-item .menu-link,
.aside-menu .menu .menu-item .menu-content {
  padding-left: 25px;
  padding-right: 25px;
}
.aside-menu .menu .menu-sub:not([data-popper-placement]) > .menu-item > .menu-content,
.aside-menu .menu .menu-sub:not([data-popper-placement]) > .menu-item > .menu-link {
  padding-left: calc(0.75rem + 25px);
}
.aside-menu .menu .menu-sub:not([data-popper-placement]) > .menu-item > .menu-sub:not([data-popper-placement]) > .menu-item > .menu-content,
.aside-menu .menu .menu-sub:not([data-popper-placement]) > .menu-item > .menu-sub:not([data-popper-placement]) > .menu-item > .menu-link {
  padding-left: calc(1.5rem + 25px);
}
.aside-menu .menu .menu-sub:not([data-popper-placement]) > .menu-item > .menu-sub:not([data-popper-placement]) > .menu-item > .menu-sub:not([data-popper-placement]) > .menu-item > .menu-content,
.aside-menu .menu .menu-sub:not([data-popper-placement]) > .menu-item > .menu-sub:not([data-popper-placement]) > .menu-item > .menu-sub:not([data-popper-placement]) > .menu-item > .menu-link {
  padding-left: calc(2.25rem + 25px);
}
.aside-menu .menu .menu-sub:not([data-popper-placement]) > .menu-item > .menu-sub:not([data-popper-placement]) > .menu-item > .menu-sub:not([data-popper-placement]) > .menu-item > .menu-sub:not([data-popper-placement]) > .menu-item > .menu-content,
.aside-menu .menu .menu-sub:not([data-popper-placement]) > .menu-item > .menu-sub:not([data-popper-placement]) > .menu-item > .menu-sub:not([data-popper-placement]) > .menu-item > .menu-sub:not([data-popper-placement]) > .menu-item > .menu-link {
  padding-left: calc(3rem + 25px);
}
.menu-fit .aside-menu .menu .menu-sub:not([data-popper-placement]) > .menu-item > .menu-content,
.menu-fit .aside-menu .menu .menu-sub:not([data-popper-placement]) > .menu-item > .menu-link {
  padding-left: 0.75rem;
  padding-right: 0;
}
.menu-fit .aside-menu .menu .menu-sub:not([data-popper-placement]) > .menu-item > .menu-sub:not([data-popper-placement]) > .menu-item > .menu-content,
.menu-fit .aside-menu .menu .menu-sub:not([data-popper-placement]) > .menu-item > .menu-sub:not([data-popper-placement]) > .menu-item > .menu-link {
  padding-left: calc(1.5rem);
  padding-right: 0;
}
.menu-fit .aside-menu .menu .menu-sub:not([data-popper-placement]) > .menu-item > .menu-sub:not([data-popper-placement]) > .menu-item > .menu-sub:not([data-popper-placement]) > .menu-item > .menu-content,
.menu-fit .aside-menu .menu .menu-sub:not([data-popper-placement]) > .menu-item > .menu-sub:not([data-popper-placement]) > .menu-item > .menu-sub:not([data-popper-placement]) > .menu-item > .menu-link {
  padding-left: calc(2.25rem);
  padding-right: 0;
}
.menu-fit .aside-menu .menu .menu-sub:not([data-popper-placement]) > .menu-item > .menu-sub:not([data-popper-placement]) > .menu-item > .menu-sub:not([data-popper-placement]) > .menu-item > .menu-sub:not([data-popper-placement]) > .menu-item > .menu-content,
.menu-fit .aside-menu .menu .menu-sub:not([data-popper-placement]) > .menu-item > .menu-sub:not([data-popper-placement]) > .menu-item > .menu-sub:not([data-popper-placement]) > .menu-item > .menu-sub:not([data-popper-placement]) > .menu-item > .menu-link {
  padding-left: calc(3rem);
  padding-right: 0;
}
.aside-menu .menu-item {
  padding: 0;
}
.aside-menu .menu-item .menu-link {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}
.aside-menu .menu-item .menu-icon {
  justify-content: flex-start;
}

.aside-dark .hover-scroll-overlay-y {
  --scrollbar-space: 0.4rem;
  scrollbar-color: #3b3b64 transparent;
}
.aside-dark .hover-scroll-overlay-y::-webkit-scrollbar-thumb {
  background-color: #3b3b64;
}
.aside-dark .menu .menu-item .menu-section {
  color: #4c4e6f !important;
}
.aside-dark .menu .menu-item .menu-link {
  color: #9899ac;
}
.aside-dark .menu .menu-item .menu-link .menu-title {
  color: #9899ac;
}
.aside-dark .menu .menu-item .menu-link .menu-icon i {
  color: #494b74;
}
.aside-dark .menu .menu-item .menu-link .menu-icon .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #494b74;
}
.aside-dark .menu .menu-item .menu-link .menu-icon .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.aside-dark .menu .menu-item .menu-link .menu-bullet .bullet {
  background-color: #9899ac;
}
.aside-dark .menu .menu-item .menu-link .menu-arrow:after {
  background-repeat: no-repeat;
  background-position: center;
  background-color: transparent;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 9' fill='%239899ac'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M5.93537 4.57889C6.03839 4.77912 6.0191 5.0363 5.87137 5.21403L2.87153 8.82282C2.68598 9.04603 2.36951 9.06026 2.16468 8.8546C1.95985 8.64893 1.94422 8.30126 2.12977 8.07804L4.80594 4.85863L2.15586 1.93583C1.96104 1.72096 1.96165 1.37314 2.15722 1.15895C2.35279 0.944757 2.66927 0.945311 2.86409 1.16018L5.85194 4.45551C5.8859 4.49296 5.91371 4.53459 5.93537 4.57889Z'/%3e%3c/svg%3e");
}
.aside-dark .menu .menu-item.hover > .menu-link:not(.disabled):not(.active),
.aside-dark .menu .menu-item .menu-link:hover:not(.disabled):not(.active) {
  transition: color 0.2s ease, background-color 0.2s ease;
  background-color: #1b1b28;
  color: #ffffff;
}
.aside-dark .menu .menu-item.hover > .menu-link:not(.disabled):not(.active) .menu-title,
.aside-dark .menu .menu-item .menu-link:hover:not(.disabled):not(.active) .menu-title {
  color: #ffffff;
}
.aside-dark .menu .menu-item.hover > .menu-link:not(.disabled):not(.active) .menu-icon i,
.aside-dark .menu .menu-item .menu-link:hover:not(.disabled):not(.active) .menu-icon i {
  color: #009EF7;
}
.aside-dark .menu .menu-item.hover > .menu-link:not(.disabled):not(.active) .menu-icon .svg-icon svg [fill]:not(.permanent):not(g),
.aside-dark .menu .menu-item .menu-link:hover:not(.disabled):not(.active) .menu-icon .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #009EF7;
}
.aside-dark .menu .menu-item.hover > .menu-link:not(.disabled):not(.active) .menu-icon .svg-icon svg:hover [fill]:not(.permanent):not(g),
.aside-dark .menu .menu-item .menu-link:hover:not(.disabled):not(.active) .menu-icon .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.aside-dark .menu .menu-item.hover > .menu-link:not(.disabled):not(.active) .menu-bullet .bullet,
.aside-dark .menu .menu-item .menu-link:hover:not(.disabled):not(.active) .menu-bullet .bullet {
  background-color: #009EF7;
}
.aside-dark .menu .menu-item.hover > .menu-link:not(.disabled):not(.active) .menu-arrow:after,
.aside-dark .menu .menu-item .menu-link:hover:not(.disabled):not(.active) .menu-arrow:after {
  background-repeat: no-repeat;
  background-position: center;
  background-color: transparent;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 9' fill='%23ffffff'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M5.93537 4.57889C6.03839 4.77912 6.0191 5.0363 5.87137 5.21403L2.87153 8.82282C2.68598 9.04603 2.36951 9.06026 2.16468 8.8546C1.95985 8.64893 1.94422 8.30126 2.12977 8.07804L4.80594 4.85863L2.15586 1.93583C1.96104 1.72096 1.96165 1.37314 2.15722 1.15895C2.35279 0.944757 2.66927 0.945311 2.86409 1.16018L5.85194 4.45551C5.8859 4.49296 5.91371 4.53459 5.93537 4.57889Z'/%3e%3c/svg%3e");
}
.aside-dark .menu .menu-item.here > .menu-link, .aside-dark .menu .menu-item.show > .menu-link {
  transition: color 0.2s ease, background-color 0.2s ease;
  background-color: #1b1b28;
  color: #ffffff;
}
.aside-dark .menu .menu-item.here > .menu-link .menu-title, .aside-dark .menu .menu-item.show > .menu-link .menu-title {
  color: #ffffff;
}
.aside-dark .menu .menu-item.here > .menu-link .menu-icon i, .aside-dark .menu .menu-item.show > .menu-link .menu-icon i {
  color: #009EF7;
}
.aside-dark .menu .menu-item.here > .menu-link .menu-icon .svg-icon svg [fill]:not(.permanent):not(g), .aside-dark .menu .menu-item.show > .menu-link .menu-icon .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #009EF7;
}
.aside-dark .menu .menu-item.here > .menu-link .menu-icon .svg-icon svg:hover [fill]:not(.permanent):not(g), .aside-dark .menu .menu-item.show > .menu-link .menu-icon .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.aside-dark .menu .menu-item.here > .menu-link .menu-bullet .bullet, .aside-dark .menu .menu-item.show > .menu-link .menu-bullet .bullet {
  background-color: #009EF7;
}
.aside-dark .menu .menu-item.here > .menu-link .menu-arrow:after, .aside-dark .menu .menu-item.show > .menu-link .menu-arrow:after {
  background-repeat: no-repeat;
  background-position: center;
  background-color: transparent;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 9' fill='%23ffffff'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M5.93537 4.57889C6.03839 4.77912 6.0191 5.0363 5.87137 5.21403L2.87153 8.82282C2.68598 9.04603 2.36951 9.06026 2.16468 8.8546C1.95985 8.64893 1.94422 8.30126 2.12977 8.07804L4.80594 4.85863L2.15586 1.93583C1.96104 1.72096 1.96165 1.37314 2.15722 1.15895C2.35279 0.944757 2.66927 0.945311 2.86409 1.16018L5.85194 4.45551C5.8859 4.49296 5.91371 4.53459 5.93537 4.57889Z'/%3e%3c/svg%3e");
}
.aside-dark .menu .menu-item .menu-link.active {
  transition: color 0.2s ease, background-color 0.2s ease;
  background-color: #1b1b28;
  color: #ffffff;
}
.aside-dark .menu .menu-item .menu-link.active .menu-title {
  color: #ffffff;
}
.aside-dark .menu .menu-item .menu-link.active .menu-icon i {
  color: #009EF7;
}
.aside-dark .menu .menu-item .menu-link.active .menu-icon .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #009EF7;
}
.aside-dark .menu .menu-item .menu-link.active .menu-icon .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.aside-dark .menu .menu-item .menu-link.active .menu-bullet .bullet {
  background-color: #009EF7;
}
.aside-dark .menu .menu-item .menu-link.active .menu-arrow:after {
  background-repeat: no-repeat;
  background-position: center;
  background-color: transparent;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 9' fill='%23ffffff'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M5.93537 4.57889C6.03839 4.77912 6.0191 5.0363 5.87137 5.21403L2.87153 8.82282C2.68598 9.04603 2.36951 9.06026 2.16468 8.8546C1.95985 8.64893 1.94422 8.30126 2.12977 8.07804L4.80594 4.85863L2.15586 1.93583C1.96104 1.72096 1.96165 1.37314 2.15722 1.15895C2.35279 0.944757 2.66927 0.945311 2.86409 1.16018L5.85194 4.45551C5.8859 4.49296 5.91371 4.53459 5.93537 4.57889Z'/%3e%3c/svg%3e");
}

.aside-light .menu .menu-title {
  font-weight: 500;
}

.content {
  background-color: #F5F8FA;
}

@media (min-width: 992px) {
  .content {
    padding: 30px 0;
  }
}
@media (max-width: 991.98px) {
  .content {
    padding: 15px 0;
  }
}